import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { styled } from '@material-ui/core';
import LocaleKeys from 'Localization/LocaleKeys';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import { withFormik } from 'Components/formik/formikWrappers';
import FormikCollection from 'Components/inputs/CollectionManager/FormikCollection';
import { getInitialTime } from '../helpers';
import { subtractCurrentTimezoneOffset, addCurrentTimezoneOffset } from 'Helpers/DateTimeHelpers';
import { Checkbox as CheckboxRaw, TextField, DateTimeInput as DateTimeInputRaw } from 'Components/formik/fields';

const inputStyles = {
  margin: '0 5px',
  height: '100%',
};
const TextInput = styled(TextField)(inputStyles);
const Checkbox = styled(CheckboxRaw)(inputStyles);
const DateTimeInput = styled(DateTimeInputRaw)(inputStyles);

export class SpecialActivity extends React.Component {
  render() {
    return (
      <ColumnDisplayer>
        <FormikCollection
          label={T.translate(LocaleKeys.labels.specialActivity)}
          name={'specialEntries'}
          defaultValue={{
            from: subtractCurrentTimezoneOffset(getInitialTime(8)),
            to: subtractCurrentTimezoneOffset(getInitialTime(16)),
            isEnabled: true,
            description: '',
          }}
        >
          <Checkbox name="isEnabled" label={T.translate(LocaleKeys.labels.active)} id="special-activity-enabled" />
          <DateTimeInput
            label={T.translate(LocaleKeys.labels.from)}
            type="datetime"
            name="from"
            onChange={(e) => {
              e.target.value = subtractCurrentTimezoneOffset(e.target.value);
              this.props.onChange(e);
            }}
            convertValue={addCurrentTimezoneOffset}
            id="special-activity-from"
            format="HH:mm dd.MM.yyyy"
          />
          <DateTimeInput
            label={T.translate(LocaleKeys.labels.to)}
            type="datetime"
            name="to"
            onChange={(e) => {
              e.target.value = subtractCurrentTimezoneOffset(e.target.value);
              this.props.onChange(e);
            }}
            convertValue={addCurrentTimezoneOffset}
            id="special-activity-to"
            format="HH:mm dd.MM.yyyy"
          />
          <TextInput
            label={T.translate(LocaleKeys.labels.description)}
            name="description"
            id="special-activity-description"
          />
        </FormikCollection>
      </ColumnDisplayer>
    );
  }
}

SpecialActivity.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.object,
};

export default withFormik(SpecialActivity);
