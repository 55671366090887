import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { find } from '@nosferatu500/react-sortable-tree';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import Button from 'Components/Button';
import TextInput from 'Components/inputs/TextInput';

const NAME_MAX_LENGTH = 17;

const PopupDialog = ({ open, onCancel, handleAddCategory, handleEditItem, editName, treeData }) => {
  const [name, setName] = useState(editName);
  const [validates, setValidates] = useState(false);
  const changed = name !== editName;
  const isValid = name.length && validates && name.length <= NAME_MAX_LENGTH;

  useEffect(() => {
    if (name && changed) {
      setValidates(
        !find({
          getNodeKey: (node) => node.treeIndex,
          treeData,
          searchMethod: (vars) => vars.node.title.toLowerCase() === vars.searchQuery.title.toLowerCase(),
          searchQuery: { title: name },
        }).matches.length
      );
    }
    if (name === editName) {
      setValidates(true);
    }
    // eslint-disable-next-line
  }, [name]);

  return (
    <Dialog aria-describedby="alert-dialog-description" aria-labelledby="alert-dialog-title" fullWidth open={open}>
      <DialogTitle id="alert-dialog-title">
        {T.translate(editName ? LocaleKeys.labels.name : LocaleKeys.labels.categoryName)}
      </DialogTitle>
      <DialogContent>
        <TextInput
          label={T.translate(editName ? LocaleKeys.labels.name : LocaleKeys.labels.categoryName)}
          onChange={(e) => {
            setName(e.target.value);
          }}
          value={name}
          helperText={
            name.length
              ? name.length > NAME_MAX_LENGTH
                ? T.translate('nameCannotExceed17Characters')
                : !validates
                ? T.translate('categoryWithThisNameAlreadyExists')
                : null
              : T.translate('thisFieldIsRequired')
          }
          error={!isValid}
        />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          color="primary"
          onClick={() => {
            onCancel();
          }}
        >
          {T.translate(LocaleKeys.labels.cancel)}
        </Button>
        <Button
          autoFocus
          color="primary"
          disabled={!(changed && isValid)}
          onClick={() => {
            editName ? handleEditItem(name) : handleAddCategory(name);
            onCancel();
          }}
        >
          {T.translate(editName ? LocaleKeys.labels.save : LocaleKeys.labels.add)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PopupDialog.defaultProps = {
  editName: '',
  handleEditItem: null,
};

PopupDialog.propTypes = {
  editName: PropTypes.string,
  handleAddCategory: PropTypes.func.isRequired,
  handleEditItem: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  treeData: PropTypes.array.isRequired,
};

export default PopupDialog;
