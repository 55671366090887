import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import T from 'i18n-react';
import { compose } from 'redux';
import LocaleKeys from 'Localization/LocaleKeys';
import { Box } from '@material-ui/core';
import withPermissions from 'hocs/withPermissions';
import { UserStatuses } from 'Constants/UserStatuses';
import { Permissions } from 'Constants/permissions';
import Button from 'Components/Button';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import { putResendActivationLink, putResetPassword } from 'Api/users';
import TextOutput from 'Components/TextOutput';
import { withFormik } from 'Components/formik/formikWrappers';
import { Checkbox, TextField } from 'Components/formik/fields';

const CommonInfo = (props) => {
  const [disableResetPassword, setDisableResetPassword] = useState(false);
  const [disableResentActivationLink, setDisableResentActivationLink] = useState(false);

  const onResetPasswordClick = () => {
    putResetPassword(props.data.login)
      .then(() => {
        setDisableResetPassword(true);
      })
      .catch((error) => {
        Sentry.captureMessage(`onResetPasswordClick, error: ${error}`);
      });
  };

  const onResendActivationLinkClick = () => {
    putResendActivationLink(props.data.login)
      .then(() => {
        setDisableResentActivationLink(true);
      })
      .catch((error) => {
        Sentry.captureMessage(`onResendActivationLinkClick, error: ${error}`);
      });
  };

  const { userStatus } = props.data;
  const canEditUsers = props.permissions[Permissions.CanEditUsers];

  return (
    <ColumnDisplayer>
      <TextField
        name={'login'}
        label={T.translate(LocaleKeys.labels.login)}
        disabled={!props.allowLoginEdit}
        showPending
      />
      {[UserStatuses.ACTIVE, UserStatuses.BLOCKED, UserStatuses.LOCKED, UserStatuses.RESET_PASSWORD].includes(
        userStatus
      ) && (
        <Button
          id="users-resetPasswordButton"
          name={'resetPassword'}
          onClick={onResetPasswordClick}
          disabled={disableResetPassword || !canEditUsers}
        >
          {T.translate(LocaleKeys.labels.resetPassword)}
        </Button>
      )}
      {[UserStatuses.WAIT_FOR_ACTIVATION, UserStatuses.NO_ACTIVATED, UserStatuses.ACTIVATION_FAILED].includes(
        userStatus
      ) && (
        <Button
          id="users-resendActivationLinkButton"
          name={'resendActivationLink'}
          onClick={onResendActivationLinkClick}
          disabled={disableResentActivationLink || !canEditUsers}
        >
          {T.translate(LocaleKeys.labels.resendActivationLink)}
        </Button>
      )}
      {userStatus && (
        <Box display="flex" justifyContent="space-between">
          <Checkbox
            name={'enabled'}
            label={T.translate(LocaleKeys.labels.enabled)}
            disabled={![UserStatuses.ACTIVE, UserStatuses.BLOCKED].includes(userStatus)}
          />
          <TextOutput
            label={T.translate(LocaleKeys.labels.userStatus)}
            value={T.translate(`userStatus${userStatus}`)}
            margin="none"
          />
        </Box>
      )}
      <TextField name={'person.firstName'} label={T.translate(LocaleKeys.labels.firstName)} />
      <TextField name={'person.middleName'} label={T.translate(LocaleKeys.labels.middleName)} />
      <TextField name={'person.lastName'} label={T.translate(LocaleKeys.labels.lastName)} />
    </ColumnDisplayer>
  );
};

CommonInfo.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
  allowLoginEdit: PropTypes.bool,
};

export default compose(withPermissions([]), withFormik)(CommonInfo);
