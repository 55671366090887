import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, useField } from 'formik';
import { styled } from '@material-ui/core/styles';
import { Box, Fab, FormLabel, Radio, Tooltip, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import { useEnhancedFormContext } from 'Components/formik/formikWrappers';

const Line = styled('div')({
  marginBottom: '5px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});
const Base = styled('div')({
  padding: '10px',
  minWidth: '25%',
  width: 'auto',
  display: 'block',
});
const ErrorLine = styled('p')({
  color: '#f44336',
  marginLeft: 5,
  marginRight: 5,
});
const FabStyled = styled(Fab)({
  minHeight: '40px',
  height: '40px',
  width: '40px',
  minWidth: '40px',
  alignSelf: 'flex-start',
  marginTop: '10px',
});

export const CollectionHeader = ({ label, error, disableAddItem, readOnly, onAddClick }) => (
  <Line>
    <Typography>{label}</Typography>
    {error && !(error instanceof Array) && (
      <ErrorLine id={'collection-error-general'}> - {T.translate(error)}!</ErrorLine>
    )}
    {!disableAddItem && !readOnly && (
      <Tooltip title={T.translate(LocaleKeys.tooltips.addNewItem)}>
        <FabStyled onClick={onAddClick} id={'collection-add'}>
          <AddIcon />
        </FabStyled>
      </Tooltip>
    )}
  </Line>
);

CollectionHeader.propTypes = {
  onAddClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  disableAddItem: PropTypes.bool,
  readOnly: PropTypes.bool,
};
CollectionHeader.defaultProps = {
  error: undefined,
  disableAddItem: false,
  readOnly: false,
};

const FormikCollection = (props) => {
  const [field, meta] = useField(props.name);
  const children = props.children instanceof Array ? props.children : [props.children];
  const { canSaveChanges } = useEnhancedFormContext();
  const isReadOnly = props.readOnly || !canSaveChanges;

  return (
    <Base>
      <FieldArray
        name={props.name}
        render={({ push, remove, form }) => {
          const defaultChange = (index) => {
            const values = field.value;

            values.forEach((x, i) => {
              form.setFieldValue(`${props.name}.${i}.isDefault`, i === index);
            });
          };

          return (
            <>
              <CollectionHeader
                label={props.label}
                error={meta.error}
                onAddClick={() => {
                  push({
                    ...props.defaultValue,
                    isDefault: props.defaultRadio ? field.value.length === 0 : false,
                  });
                }}
                disableAddItem={props.disableAddItem}
                readOnly={isReadOnly}
              />
              {field.value.map((v, index) => (
                <Box key={index} display="flex">
                  <Line id={`collection-line-${index}`}>
                    {children.map((child, i) => {
                      return React.cloneElement(child, {
                        ...child.props,
                        name: `${props.name}.${index}.${child.props.name}`,
                        key: i,
                        style: { height: '100%', marginRight: 5, ...child.style },
                      });
                    })}
                    {props.defaultRadio && (
                      <>
                        <FormLabel component="label" onClick={() => defaultChange(index)}>
                          {T.translate(LocaleKeys.labels.default)}
                        </FormLabel>
                        <Radio
                          checked={v.isDefault}
                          onChange={() => defaultChange(index)}
                          name={`${props.name}.${index}.isDefault`}
                          disabled={props.disabled || isReadOnly}
                        />
                      </>
                    )}
                    {!props.disableRemoveItem && !isReadOnly && (
                      <FabStyled
                        onClick={() => {
                          v.isDefault && defaultChange(index === 0 ? 1 : 0);
                          remove(index);
                        }}
                        id={`collection-delete-${index}`}
                      >
                        <DeleteIcon />
                      </FabStyled>
                    )}
                  </Line>
                </Box>
              ))}
            </>
          );
        }}
      />
    </Base>
  );
};

FormikCollection.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disableAddItem: PropTypes.bool,
  disableRemoveItem: PropTypes.bool,
  readOnly: PropTypes.bool,
  defaultValue: PropTypes.object,
};
FormikCollection.defaultProps = {
  disableAddItem: false,
  disableRemoveItem: false,
  readOnly: false,
  defaultValue: {},
};

export default FormikCollection;
