import React, { useState, useEffect, useCallback } from 'react';
import { getCalendar, getHoliday, postCalendar } from 'Api/sites';

import SpecialActivity from './tabs/SpecialActivity';
import T from 'i18n-react';
import Vacation from './tabs/Vacation';
import WeeklyActivitySchedule from './tabs/WeeklyActivitySchedule';
import General from './tabs/General';
import Holiday from './tabs/Holiday';
import { compose } from 'redux';
import SaveOnLeave from 'Components/dialogs/SaveOnLeave';

import LocaleKeys from 'Localization/LocaleKeys';
import Button from 'Components/Button';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import { styled } from '@material-ui/core/styles';
import { CalendarTemplates } from 'routes/Routes';
import { CircularProgress } from '@material-ui/core';
import { removeProperty } from 'Helpers/ObjectHelper';
import { Formik } from 'formik';
import Tabs from 'Components/display/tabs/Tabs';
import CalendarTemplatesValidation from './CalendarTemplatesValidation';
import { convertWorkdays, convertVacations, convertSpecialEntries } from './helpers';

const ButtonStyled = styled(Button)({
  float: 'right',
  marginLeft: '10px',
});

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const getWorkDaysTemplate = () => {
  const workDays = [];

  for (let i = 0; i <= 6; i++) {
    workDays.push({
      operationDay: {
        isEnabled: false,
        timeEntries: [],
      },
      dayOfWeek: i,
      dayName: days[i],
    });
  }

  return workDays;
};

const New = ({ history, permissions }) => {
  const [calendarTemplate, setCalendarTemplate] = useState(null);
  const [holidayTemplate, setHolidayTemplate] = useState(null);

  const getCalendarTemplate = useCallback(() => {
    if (history.location.state) {
      const { id } = history.location.state;

      if (id) {
        getCalendar(id).then((calendarTemplateResponse) => {
          const calendar = calendarTemplateResponse.data;

          calendar.name = '';
          removeProperty(calendar, 'externalId');
          removeProperty(calendar, 'calendarId');
          calendar.workdays = convertWorkdays(calendar.workdays);
          calendar.vacations = convertVacations(calendar.vacations);
          calendar.specialEntries = convertSpecialEntries(calendar.specialEntries);
          setCalendarTemplate(calendar);
        });
      }
    } else {
      const calendar = {
        name: '',
        description: '',
        timeZone: '',
        accumulatePrivateDistance: false,
        sites: [],
        types: [],
        workdays: getWorkDaysTemplate(),
        specialEntries: [],
        vacations: [],
      };

      calendar.workdays = convertWorkdays(calendar.workdays);
      setCalendarTemplate(calendar);
    }
  }, [history.location]);

  useEffect(() => {
    getCalendarTemplate();
  }, [getCalendarTemplate]);

  const getHolidayTemplate = (id) => {
    getHoliday(id).then((holidayTemplateResponse) => {
      setHolidayTemplate(holidayTemplateResponse.data);
    });
  };

  const saveData = (values) => {
    const calendar = { ...values };

    if (calendar.workdays) {
      calendar.workdays = Object.values(calendar.workdays);
    }

    return postCalendar(calendar);
  };

  const redirect = () => {
    const url = CalendarTemplates.getUrl();

    history.push(url);
  };

  const onCancel = () => {
    redirect();
  };

  const onSubmit = (values, { setSubmitting }) => {
    saveData(values).then(() => {
      setSubmitting(false);
      redirect();
    });
  };

  if (!calendarTemplate) {
    return <CircularProgress />;
  }

  const canSaveChanges = permissions[Permissions.CanAddCalendarTemplates];

  return (
    <Formik
      initialValues={calendarTemplate}
      enableReinitialize
      validationSchema={CalendarTemplatesValidation}
      validateOnMount
      onSubmit={onSubmit}
    >
      {({ values, dirty, isValid, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Tabs label="calendarDetails" defaultTab={0}>
            <General label={LocaleKeys.labels.general} />
            <WeeklyActivitySchedule label={LocaleKeys.labels.weeklyActivitySchedule} />
            <SpecialActivity label={LocaleKeys.labels.specialActivity} />
            <Vacation label={LocaleKeys.labels.vacation} />
            <Holiday
              label={LocaleKeys.labels.holidayTemplate}
              holidayTemplateData={holidayTemplate}
              getHolidayTemplate={getHolidayTemplate}
            />
          </Tabs>
          <ButtonStyled onClick={onCancel} id="new-calendarTemplates-cancelButton">
            {T.translate(LocaleKeys.labels.cancel)}
          </ButtonStyled>
          {canSaveChanges && (
            <>
              <ButtonStyled
                disabled={!(dirty && isValid) || isSubmitting}
                type="submit"
                id="new-calendarTemplates-submitButton"
              >
                {T.translate(LocaleKeys.labels.save)}
              </ButtonStyled>
              <SaveOnLeave saveData={() => saveData(values)} dataChanged={dirty} validForm={isValid} />
            </>
          )}
        </form>
      )}
    </Formik>
  );
};

export default compose(
  withPermissions([
    Permissions.CanAccessCalendarTemplatesPage,
    Permissions.CanReadCalendarTemplates,
    Permissions.CanReadHolidaysTemplates,
    Permissions.CanAddCalendarTemplates,
  ])
)(New);
