import AccordionSummary from '@material-ui/core/AccordionSummary';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

export const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0,0,0,.03)',
    borderBottom: '1px solid rgba(0,0,0,.125)',
    marginBottom: '-1',
    minHeight: '56',
    '&$expanded': {
      minHeight: '56',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})((props) => <AccordionSummary {...props} />);

ExpansionPanelSummary.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  data: PropTypes.array,
};

export default ExpansionPanelSummary;
