import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Table from 'Components/display/table/Table';
import TableActionButton from 'Components/display/table/TableActionButton';
import { getMenus, deleteMenu } from 'Api/sites';
import DeleteDialog from 'Components/dialogs/DeleteDialog';
import { SiteConfigurationDetails, SiteConfigurationNew } from 'routes/Routes';
import LocaleKeys from 'Localization/LocaleKeys';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import SupportedLanguages from 'Localization/SupportedLanguages';
import { isDate } from 'helpers/DateTimeHelpers';

const convertDate = (date) => (isDate(date) ? format(new Date(date), 'dd-MM-yyyy HH:mm') : '');

const SiteConfiguration = ({ permissions }) => {
  const history = useHistory();
  const [entries, setEntries] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [itemToRemove, setItemToRemove] = useState(null);

  const fetchMenus = async () => {
    const response = await getMenus();

    if (response.data) {
      setEntries(response.data);
    }
  };

  useEffect(() => {
    fetchMenus();
  }, []);

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setItemToRemove(null);
  };

  const handleDeleteConfirm = () => {
    return deleteMenu(itemToRemove).then(() => {
      setDeleteDialogOpen(false);
      setItemToRemove(null);
      fetchMenus();
    });
  };

  const editEntry = (name) => {
    history.push(SiteConfigurationDetails.getUrl().replace(':name', name.replace(/\s/g, '-')));
  };

  const onDeleteClick = (id) => {
    setDeleteDialogOpen(true);
    setItemToRemove(id);
  };

  const getTableActionButtons = () => {
    const actionButtons = [];

    if (permissions[Permissions.CanEditSiteConfiguration]) {
      const editItemButton = (
        <TableActionButton
          key="editItemButton"
          clickparam="name"
          icon={<EditIcon />}
          onClick={editEntry}
          tooltipText={T.translate(LocaleKeys.tooltips.editItem)}
        />
      );

      actionButtons.push(editItemButton);
    }

    if (permissions[Permissions.CanRemoveSiteConfiguration]) {
      const deleteItemButton = (
        <TableActionButton
          key="deleteItemButton"
          clickparam="externalId"
          icon={<DeleteIcon />}
          onClick={onDeleteClick}
          tooltipText={T.translate(LocaleKeys.tooltips.deleteItem)}
        />
      );

      actionButtons.push(deleteItemButton);
    }

    return actionButtons;
  };

  const onAddClick = () => {
    history.push(SiteConfigurationNew.getUrl());
  };

  const tableData = entries?.data?.map((row) => ({
    externalId: row.externalId,
    name: row.name,
    users: row.users.join(', '),
    languages: Object.entries(SupportedLanguages.names)
      .filter(([key]) => row.languages.includes(key))
      .map(([, name]) => name)
      .join(', '),
    createdBy: row.createdBy,
    createdAt: convertDate(row.createdAt),
  }));

  return (
    <>
      {tableData && (
        <Table
          actionButtons={getTableActionButtons()}
          data={tableData}
          hideColumns={['externalId']}
          onAddClick={permissions[Permissions.CanAddSiteConfiguration] && onAddClick}
          onRowDoubleClick={(row) => editEntry(row.name)}
          tableName="configurations"
        />
      )}
      <DeleteDialog
        isOpen={deleteDialogOpen}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        disableConfirm={!itemToRemove}
        message={T.translate(LocaleKeys.messages.deleteSiteConfigurationDialogMessage)}
        title={T.translate(LocaleKeys.messages.deleteSiteConfigurationDialogTitle)}
      />
    </>
  );
};

SiteConfiguration.propTypes = {
  permissions: PropTypes.object.isRequired,
};

export default withPermissions([Permissions.CanAccessSiteConfigurationPage, Permissions.CanReadSiteConfiguration])(
  SiteConfiguration
);
