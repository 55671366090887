import { types } from './actions';

const initialState = {
  currentSite: null,
  currentSiteId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CURRENT_SITE:
      return {
        ...state,
        currentSite: action.payload.name,
        currentSiteId: action.payload.id,
      };
    default:
      return state;
  }
};
