import { CachedTileLayer } from '@yaga/leaflet-cached-tile-layer';

const baseURL = 'maps.ls.hereapi.com/maptile/2.1';
const size = 256;

export const LayerTypes = {
  BASE: 1,
  OVERLAY: 2,
};

const Layers = (token) => {
  return {
    baseLayer: new CachedTileLayer(
      `https://2.base.${baseURL}/BaseTile/newest/normal.day/{z}/{x}/{y}/${size}/png?${token}`,
      {
        maxZoom: 20,
        size: 256,
        layerKey: 'baseLayer',
        layerType: LayerTypes.BASE,
      }
    ),
    sateliteLayer: new CachedTileLayer(
      `https://2.aerial.${baseURL}/BaseTile/newest/satellite.day/{z}/{x}/{y}/${size}/png?${token}`,
      {
        maxZoom: 20,
        size: 256,
        layerKey: 'sateliteLayer',
        layerType: LayerTypes.BASE,
      }
    ),
    terrainLayer: new CachedTileLayer(
      `https://1.aerial.${baseURL}/BaseTile/newest/terrain.day/{z}/{x}/{y}/${size}/png?${token}`,
      {
        maxZoom: 20,
        size: 256,
        layerKey: 'terrainLayer',
        layerType: LayerTypes.BASE,
      }
    ),
    labelTile: new CachedTileLayer(
      `https://2.base.${baseURL}/LabelTile/newest/normal.day/{z}/{x}/{y}/${size}/png?${token}`,
      {
        maxZoom: 20,
        size: 256,
        layerKey: 'labelTile',
        layerType: LayerTypes.OVERLAY,
      }
    ),
    traficLayer: new CachedTileLayer(
      `https://1.traffic.${baseURL}/flowtile/newest/normal.day/{z}/{x}/{y}/${size}/png?${token}`,
      {
        maxZoom: 20,
        size: 256,
        layerKey: 'traficLayer',
        layerType: LayerTypes.OVERLAY,
      }
    ),
    truckRestriction: new CachedTileLayer(
      `https://1.base.${baseURL}/TruckOnlyTile/newest/normal.day/{z}/{x}/{y}/${size}/png?${token}`,
      {
        maxZoom: 20,
        size: 256,
        layerKey: 'truckRestriction',
        layerType: LayerTypes.OVERLAY,
      }
    ),
  };
};

export default Layers;
