import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { getMapAreas, deleteMapArea } from 'Api/maps';
import Table from 'Components/display/table/Table';
import { NewMapArea, MapDetails } from 'routes/Routes';
import { Permissions } from 'Constants/permissions';
import Loader from 'Components/Loader';
import TableActionButton from 'Components/display/table/TableActionButton';
import DeleteAffectingAlarmsDialog from 'Components/dialogs/DeleteAffectingAlarmsDialog';
import { getMapAreasMappingsUsedByBRE } from 'Api/devices';
import { compose } from 'redux';
import withPermissions from 'hocs/withPermissions';

const formatDataForTableView = (mapAreas) =>
  Object.values(mapAreas).map((mapArea) => ({
    id: mapArea.id,
    name: mapArea.name,
    active: mapArea.isEnabled || 'No',
  }));

class MapAreas extends React.Component {
  state = {
    data: [],
    isLoaded: false,
    mapArea: null,
    deleteMapAreaDialogOpen: false,
    itemToRemove: null,
    affectedAlarms: [],
  };

  componentDidMount = () => {
    this.setData();
  };

  setData = () => {
    getMapAreas().then(({ data }) => {
      this.setState({ data, isLoaded: true });
    });
  };

  rowDoubleClick = (id, clickedItem) => {
    const url = MapDetails.getUrl({
      name: clickedItem.name.replace(/\s/g, '-'),
    });

    this.props.history.push(url);
  };

  onAddClick = () => {
    const url = NewMapArea.getUrl();

    this.props.history.push(url);
  };

  onDeleteClick = (id) => {
    getMapAreasMappingsUsedByBRE(id).then((response) => {
      this.setState({
        deleteMapAreaDialogOpen: true,
        itemToRemove: id,
        affectedAlarms: response.data,
      });
    });
  };

  handleDeleteMapAreaConfirm = () => {
    return deleteMapArea(this.state.itemToRemove)
      .then(() => {
        this.setState({ deleteMapAreaDialogOpen: false, itemToRemove: null });
      })
      .finally(() => {
        this.setData();
      });
  };

  handleDeleteMapAreaCancel = () => {
    this.setState({ deleteMapAreaDialogOpen: false, itemToRemove: null });
  };

  getTableActionButtons = () => {
    const actionButtons = [];

    if (this.props.permissions[Permissions.CanEditMapArea]) {
      const editItemButton = (
        <TableActionButton
          key="editItemButton"
          onClick={this.rowDoubleClick}
          clickparam={'name'}
          icon={<EditIcon />}
          tooltipText={T.translate(LocaleKeys.tooltips.editMapArea)}
        />
      );

      actionButtons.push(editItemButton);
    }
    if (this.props.permissions[Permissions.CanRemoveMapArea]) {
      const deleteItemButton = (
        <TableActionButton
          key="deleteItemButton"
          onClick={this.onDeleteClick}
          clickparam={'id'}
          icon={<DeleteIcon />}
          tooltipText={T.translate(LocaleKeys.tooltips.deleteMapArea)}
        />
      );

      actionButtons.push(deleteItemButton);
    }

    return actionButtons;
  };

  render() {
    if (!this.state.isLoaded) {
      return <Loader />;
    }

    const tableData = formatDataForTableView(this.state.data);
    const actionButtons = this.getTableActionButtons();

    return (
      <div>
        <DeleteAffectingAlarmsDialog
          isOpen={this.state.deleteMapAreaDialogOpen}
          onConfirm={this.handleDeleteMapAreaConfirm}
          onCancel={this.handleDeleteMapAreaCancel}
          disableConfirm={!this.state.itemToRemove}
          title={LocaleKeys.messages.deleteMapAreaDialogTitle}
          message={LocaleKeys.messages.deleteMapAreaDialogMessage}
          affectedAlarms={this.state.affectedAlarms}
        />
        <Table
          onRowDoubleClick={this.rowDoubleClick}
          data={tableData}
          hideColumns={['id']}
          onAddClick={this.props.permissions[Permissions.CanAddMapArea] && this.onAddClick}
          actionButtons={actionButtons}
          tableName="mapAreas"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentSite: state.sites.currentSite,
});

MapAreas.propTypes = {
  history: PropTypes.object,
  currentSite: PropTypes.string,
  permissions: PropTypes.object,
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withPermissions([Permissions.CanAccessMapAreasPage, Permissions.CanReadMapAreas])
)(MapAreas);
