import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { IconButton } from '@material-ui/core';
import { Loop } from '@material-ui/icons';
import { connect } from 'react-redux';
import { update } from 'redux/assets/actions';
import { postCommandRefresh } from 'Api/devicesControl';
import { compose } from 'redux';
import { withRenderInterval } from 'hocs/withRenderInterval';
import CountdownTooltip, { countTimeUntilEnabled } from 'Components/CountdownTooltip';
import { TcpIpStatuses } from 'Constants/TcpIpStatuses';

const DISABLE_PERIOD = 60; // seconds

const mapDispatch = {
  update,
};

const disableRefresh = (lastRefreshedAt) => buttonDisabledTo(lastRefreshedAt) > 0;

const buttonDisabledTo = (lastRefreshedAt) => countTimeUntilEnabled(lastRefreshedAt, DISABLE_PERIOD);

const RefreshIconButton = ({ disabled, onClick }) => {
  return (
    <IconButton onClick={onClick} disabled={disabled}>
      <Loop />
    </IconButton>
  );
};

export const RefreshButton = compose(
  connect(null, mapDispatch),
  withRenderInterval(1000)
)(({ device, update }) => {
  const handleRefreshClick = (e) => {
    e.stopPropagation();
    postCommandRefresh(device.externalId).catch(Sentry.captureMessage);

    update({
      externalId: device.externalId,
      device: {
        ...device,
        lastRefreshedAt: new Date().toISOString(),
      },
    });
  };
  const disableIcon = disableRefresh(device.lastRefreshedAt);
  const disabledTo = buttonDisabledTo(device.lastRefreshedAt);

  return (
    <CountdownTooltip seconds={disabledTo}>
      <RefreshIconButton onClick={handleRefreshClick} disabled={disableIcon} />
    </CountdownTooltip>
  );
});

export const RefreshAllButton = compose(
  connect(null, mapDispatch),
  withRenderInterval(1000)
)(({ devices, update, forceRenderFlag }) => {
  const [disabledRefreshAll, setDisabledRefreshAll] = useState(false);
  const [connectedAssets, setConnectedAssets] = useState([]);
  const [disabledTo, setDisabledTo] = useState(0);

  useEffect(() => {
    const connected = devices.filter(
      (device) => device.tcpIpStatusIndicator === TcpIpStatuses.CONNECTED && device.isRefreshable
    );

    const disabled = connected.filter((device) => disableRefresh(device.lastRefreshedAt));
    const notDisabled = connected.filter((device) => !disableRefresh(device.lastRefreshedAt));

    if (notDisabled?.length) {
      setDisabledRefreshAll(false);
      setDisabledTo(0);
    } else {
      const minRefreshed = Math.min.apply(
        null,
        disabled.map((e) => e.lastRefreshedAt && +new Date(e.lastRefreshedAt))
      );

      setDisabledTo(countTimeUntilEnabled(minRefreshed, DISABLE_PERIOD));
    }

    setConnectedAssets(notDisabled);
  }, [devices, forceRenderFlag]);

  useEffect(() => {
    const disableAll = connectedAssets?.length === 0;

    setDisabledRefreshAll(disableAll);
  }, [connectedAssets]);

  const handleRefreshAllClick = () => {
    const date = new Date();

    if (connectedAssets.length > 0) {
      connectedAssets.forEach((asset) => {
        update({
          externalId: asset.externalId,
          device: {
            ...asset,
            lastRefreshedAt: date.toISOString(),
          },
        });
      });
      postCommandRefresh(connectedAssets.map((device) => device.externalId));
    }
  };

  return (
    <CountdownTooltip seconds={disabledTo}>
      <RefreshIconButton onClick={handleRefreshAllClick} disabled={disabledRefreshAll} />
    </CountdownTooltip>
  );
});
