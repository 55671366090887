import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import { TextField } from 'Components/formik/fields';

const RoleDescription = (props) => {
  return (
    <ColumnDisplayer>
      <TextField name="roleInfo.name" label={T.translate(LocaleKeys.labels.name)} showPending />
    </ColumnDisplayer>
  );
};

RoleDescription.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
};

export default RoleDescription;
