/**
 * @class L.Draw.TextMarker
 * @aka Draw.TextMarker
 * @inherits L.Draw.Marker
 */
L.Draw.TextMarker = L.Draw.Marker.extend({
  statics: {
    TYPE: 'textMarker',
  },

  // @method initialize(): void
  initialize: function (map, options) {
    this.type = L.Draw.TextMarker.TYPE;

    this._initialLabelText = L.drawLocal.draw.handlers.textMarker.tooltip.start;

    L.Draw.Feature.prototype.initialize.call(this, map, options);
  },
});
