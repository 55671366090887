import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import Button from 'Components/Button';
import TextInput from 'Components/inputs/TextInput';
import { categoryNameSchema } from './validationSchema';
import ValidationLocaleKeys from 'Localization/ValidationLocaleKeys';

const validateName = async (value) => {
  try {
    await categoryNameSchema.validate(value);
  } catch ({ message }) {
    return message;
  }

  return null;
};

const RenameCategoryDialog = ({ categories, categoryTempId, categoryName, onRenameCategory, ...props }) => {
  const [dialogConfirmed, setDialogConfirmed] = useState(false);
  const [name, setName] = useState(categoryName);
  const [error, setError] = useState();

  useEffect(() => {
    if (!!categories.find((c) => c.name.toLowerCase() === name.toLowerCase() && c.tempId !== categoryTempId)) {
      setError(ValidationLocaleKeys.categoryWithThisNameAlreadyExists);
    } else {
      validateName(name).then((err) => setError(err));
    }
    if (dialogConfirmed) {
      onRenameCategory(name);
      setDialogConfirmed(false);
      setName('');
    }
  }, [dialogConfirmed, name, onRenameCategory, categories, categoryTempId]);

  useEffect(() => {
    setName(categoryName);
  }, [categoryName]);

  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{T.translate(LocaleKeys.labels.categoryName)}</DialogTitle>
      <DialogContent>
        <TextInput
          value={name}
          label={T.translate(LocaleKeys.labels.categoryName)}
          onChange={(e) => {
            setName(e.target.value);
          }}
          valid={error}
          showPending
          validationerrorlocalekeys={error}
          id={'category-dialog-name'}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onCancel();
          }}
          autoFocus
        >
          {T.translate(LocaleKeys.labels.cancel)}
        </Button>
        <Button
          onClick={() => {
            setDialogConfirmed(true);
          }}
          autoFocus
          disabled={!!error}
        >
          {T.translate(LocaleKeys.labels.save)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RenameCategoryDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onRenameCategory: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  categoryName: PropTypes.string,
};

RenameCategoryDialog.defaultProps = {
  categoryName: '',
};

export default RenameCategoryDialog;
