import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import Table from 'Components/display/table/Table';
import { format } from 'date-fns';
import { getDeviceImeiHistory } from 'Api/devices';
import { withFormik } from 'Components/formik/formikWrappers';
import { isDate } from 'Helpers/DateTimeHelpers';
import { RequestConfigContext } from '../requestConfigContext';
import { CircularProgress } from '@material-ui/core';
import ErrorMessage from 'Components/display/ErrorMessage';

const convertDate = (date) => {
  if (!isDate(date)) {
    return '';
  }
  const dateObj = new Date(date);

  return format(dateObj, 'dd-MM-yyyy');
};

const formatDataForTableView = (imeiHistory) => {
  return Object.values(imeiHistory).map((imei) => ({
    ...imei,
    changeDate: convertDate(imei.changeDate),
  }));
};

const ImeiHistory = (props) => {
  const [imeiHistory, setImeiHistory] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const requestConfig = useContext(RequestConfigContext);

  useEffect(() => {
    setIsLoading(true);
    getDeviceImeiHistory(props.data.id, requestConfig)
      .then(({ data }) => {
        setImeiHistory(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <ErrorMessage
        primaryMessage={T.translate(LocaleKeys.messages.errorWhileFetchingData, {
          name: T.translate(LocaleKeys.labels.imeiHistory),
        })}
      />
    );
  }

  const imeiHistoryTableData = formatDataForTableView(imeiHistory);

  return <Table data={imeiHistoryTableData} hideColumns={['id']} />;
};

ImeiHistory.propTypes = {
  data: PropTypes.object,
};

export default withFormik(ImeiHistory);
