import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import { getOccurrenceTypes } from 'Api/devices';
import { withFormik } from 'Components/formik/formikWrappers';
import { AlarmOccurrenceTypes } from 'Constants/AlarmOccurrenceTypes';
import { Masks } from 'Components/inputs/MaskedTextInput';
import { Checkbox, DropdownWithApiCall, MaskedTextInput } from 'Components/formik/fields';

const SystemNotifications = ({ data, onChange, setFieldValue }) => (
  <ColumnDisplayer>
    <DropdownWithApiCall
      label={T.translate(LocaleKeys.labels.occurrenceType)}
      name="occurrenceType"
      apiCall={getOccurrenceTypes}
      displayField="name"
      valueField="value"
      translateLabel
      withoutEmpty
    />
    {data.occurrenceType === AlarmOccurrenceTypes.ONE_TIME && (
      <MaskedTextInput
        maskConfig={Masks.DaysHoursMinutesSeconds}
        label={T.translate(LocaleKeys.labels.timeSpan)}
        name="oneTimeAlarmTimePeriod"
      />
    )}
    {data.occurrenceType !== AlarmOccurrenceTypes.AUTO_HANDLE && (
      <Checkbox
        label={T.translate(LocaleKeys.labels.autoReadAfter)}
        name="isAutoRead"
        onChange={(e) => {
          onChange(e);
          if (!e.target.value) {
            setFieldValue('autoReadInMinutes', null);
          }
        }}
      />
    )}
    {data.occurrenceType !== AlarmOccurrenceTypes.AUTO_HANDLE && data.isAutoRead && (
      <MaskedTextInput
        label={T.translate(LocaleKeys.labels.timeSpan)}
        name="autoReadInMinutes"
        maskConfig={Masks.HoursMinutes}
      />
    )}
  </ColumnDisplayer>
);

SystemNotifications.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
  setFieldValue: PropTypes.func,
};

export default withFormik(SystemNotifications);
