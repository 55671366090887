import React, { useCallback, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import T from 'i18n-react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import LocaleKeys from 'Localization/LocaleKeys';
import Loader from 'Components/Loader';
import RedirectToDefaultSite from 'app/routing/RedirectToDefaultSite';
import { getLoggedUserDetails } from 'Api/users';
import { getSiteInfoByName } from 'Api/sites';
import { getMapAccessData, getMapAreasInfo } from 'Api/maps';
import PermissionManager from 'Auth/PermissionManager';
import { setCurrentUser } from 'redux/user/actions';
import { setCurrentSite } from 'redux/sites/actions';
import { setPermissions, setLoadingMapToken, loadMapTokenSuccess, loadMapTokenError } from 'redux/app/actions';
import { getLoadingMapToken, getMapToken, getMapTokenError } from 'redux/app/selectors';
import { setLoading as setLoadingMapAreas, loadMapAreasSuccess, loadMapAreasFail } from 'redux/mapOverlays/actions';
import { connect } from 'react-redux';
import AuthManager from 'Auth/AuthManager';
import { getUser } from 'redux/user/selectors';
import ErrorPage from 'app/pages/ErrorPage';
import { getCheckAccess } from 'Api/policyServer';
import { usePrevious } from 'hooks/usePrevious';
import { getIsLoadingMapAreas, getMapAreas, getMapAreasError } from 'redux/mapOverlays/selectors';

const Initialization = ({
  user,
  currentSiteId,
  currentSite,
  setCurrentSite,
  mapToken,
  loadingMapToken,
  mapTokenError,
  setLoadingMapToken,
  loadMapTokenSuccess,
  loadMapTokenError,
  setCurrentUser,
  setPermissions,
  permissions,
  mapAreas,
  mapAreasError,
  loadMapAreasSuccess,
  loadMapAreasFail,
  isLoadingMapAreas,
  ...props
}) => {
  const [userHasAccess, setUserHasAccess] = useState(null);
  const [isCheckingUserAccess, setIsCheckingUserAccess] = useState(false);
  const [error, setError] = useState();
  const previousSiteId = usePrevious(currentSiteId);
  const currentSiteIdChanged = previousSiteId !== currentSiteId;
  const siteName = props.match.params.siteName?.replace(/-/g, ' ');

  const checkAccess = useCallback(() => {
    setIsCheckingUserAccess(true);
    getCheckAccess()
      .then(({ data: userHasAccess }) => {
        setUserHasAccess(userHasAccess);
      })
      .catch((error) => {
        setError(error);
        const response = error?.response;
        const message = response?.data?.errorMessage;
        const status = response?.status;

        if (status === 403 && message === 'Bad ip address') {
          setUserHasAccess(false);
        }
      })
      .finally(() => {
        setIsCheckingUserAccess(false);
      });
  }, []);

  useEffect(() => {
    if (!AuthManager.userIsLogged()) {
      const redirectUrl = props.location.pathname;

      AuthManager.login(redirectUrl);

      return;
    }

    Sentry.setTag('siteName', siteName);
    setUserHasAccess(false);
    setIsCheckingUserAccess(true);
    Promise.all([
      getLoggedUserDetails(),
      PermissionManager.refreshPermission(),
      siteName && getSiteInfoByName(siteName),
    ])
      .then(([{ data: user }, { permissions }, siteResponse]) => {
        checkAccess();
        setCurrentUser(user);
        setPermissions(permissions);
        Sentry.setUser({
          id: user.id,
          username: user.login,
          email: user.emails[0].emailAddress,
        });
        if (siteName) {
          if (siteResponse) {
            setCurrentSite({
              id: siteResponse.data.id,
              name: siteResponse.data.siteName,
            });
          } else {
            setCurrentSite({
              id: null,
              name: null,
            });
          }
        }
      })
      .catch((error) => {
        setError(error);
        Sentry.captureMessage('Initialization error', {
          extra: { error: error.message },
        });
      });
  }, [siteName, checkAccess]);

  const areMapAreasInitialized = mapAreas || mapAreasError || isLoadingMapAreas;

  useEffect(() => {
    if (permissions && userHasAccess && currentSiteId && (!areMapAreasInitialized || currentSiteIdChanged)) {
      if (permissions.find((permission) => permission.code === 'CanReadMapAreas')?.value === 'true') {
        getMapAreasInfo({ enabled: true }).then(({ data: mapAreas }) => {
          if (mapAreas) {
            loadMapAreasSuccess({ mapAreas });
          }
        });
      }
    }
  }, [userHasAccess, permissions, areMapAreasInitialized, currentSiteId, currentSiteIdChanged]);

  const isMapTokenInitialized = mapToken || mapTokenError || loadingMapToken;

  useEffect(() => {
    if (user && userHasAccess && currentSiteId && (!isMapTokenInitialized || currentSiteIdChanged)) {
      setLoadingMapToken(true);
      getMapAccessData()
        .then(({ data }) => {
          loadMapTokenSuccess(data);
        })
        .catch((error) => {
          loadMapTokenError(error);
        });
    }
  }, [user, userHasAccess, isMapTokenInitialized, currentSiteId, currentSiteIdChanged]);

  if (error?.response.status === 404) {
    return (
      <ErrorPage
        code={404}
        message={T.translate(LocaleKeys.labels.thisSiteDoesntExist)}
        customAction={() => {
          props.history.push('/');
          window.location.reload();
        }}
        customButtonName={T.translate(LocaleKeys.labels.redirectToYourDefaultSite)}
      />
    );
  }

  if (!isCheckingUserAccess && !userHasAccess && error?.response.status === 403) {
    return (
      <ErrorPage
        code={403}
        message={T.translate(LocaleKeys.labels.accessDenied)}
        customAction={AuthManager.logout}
        customButtonName={T.translate(LocaleKeys.labels.signout)}
      />
    );
  }

  // is there a lack of any data loading is in progress
  if (isCheckingUserAccess || !user || !permissions) {
    return <Loader />;
  }

  if (!siteName) {
    return <RedirectToDefaultSite />;
  }

  if (!currentSite) {
    return <Loader />;
  }

  return props.children;
};

const mapStateToProps = (state) => ({
  currentSiteId: state.sites.currentSiteId,
  currentSite: state.sites.currentSite,
  user: getUser(state),
  permissions: state.app.permissions,
  mapToken: getMapToken(state),
  mapTokenError: getMapTokenError(state),
  loadingMapToken: getLoadingMapToken(state),
  mapAreas: getMapAreas(state),
  mapAreasError: getMapAreasError(state),
  isLoadingMapAreas: getIsLoadingMapAreas(state),
});

const mapDispatchToProps = {
  setCurrentUser,
  setCurrentSite,
  setPermissions,
  setLoadingMapToken,
  loadMapTokenSuccess,
  loadMapTokenError,
  setLoadingMapAreas,
  loadMapAreasSuccess,
  loadMapAreasFail,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Initialization);
