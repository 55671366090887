import React from 'react';
import { useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { setLastUserActivity } from 'redux/app/actions';

const LISTENING_EVENTS = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
];

export default function UserActivityMonitor() {
  const dispatch = useDispatch();
  const handleOnAction = () => dispatch(setLastUserActivity(new Date()));
  const handleOnActive = () => dispatch(setLastUserActivity(new Date()));

  useIdleTimer({
    onAction: handleOnAction,
    onActive: handleOnActive,
    events: LISTENING_EVENTS,
    throttle: 5000,
  });

  return <></>;
}
