import * as Yup from 'yup';
import { getHolidayExistsByName } from 'Api/sites';
import { isAlphanumericOrSpecialSymbol, isDateCorrect } from 'Validators/SharedValidators';

let lastValue;
let initialValue;

export const holidayTemplateNameExist = async (value, id) => {
  if (isAlphanumericOrSpecialSymbol([' '])(value)) {
    const result = lastValue?.value === value ? lastValue?.result : await getHolidayExistsByName(value);

    if (id) {
      if (!initialValue || id !== initialValue?.id) {
        initialValue = { value, id };
      }
    } else {
      initialValue = undefined;
    }

    lastValue = { value, result };

    return initialValue?.value === value || !result?.data;
  }

  return false;
};

const HolidayValidation = Yup.object().shape({
  entries: Yup.array().of(
    Yup.object()
      .shape({
        name: Yup.string().required('thisFieldIsRequired'),
        date: Yup.string().nullable().required('thisFieldIsRequired').test('isDateValid', 'invalidDate', isDateCorrect),
      })
      .uniqueInCollection('valueMustBeUniqueInCollection', 'date')
  ),

  year: Yup.number()
    .required('thisFieldIsRequired')
    .test('int', 'valueMustBeInteger', Number.isInteger)
    .min(1, 'valueMustBePositiveNumber'),

  name: Yup.string()
    .required('thisFieldIsRequired')
    .max(50, 'nameCannotExceed50Characters')
    .alphanumericOrSpecialSymbol([' '])
    .test('holidayTemplateNameExist', 'holidayWithThisNameAlreadyExists', function (value) {
      return holidayTemplateNameExist(value, this.parent.holidayId);
    }),
});

export default HolidayValidation;
