import React, { useState, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import DeviceButtons from './buttons/DeviceButtons';
import ButtonLibraryPreview from 'Components/buttonLibrary/ButtonLibraryPreview';
import ButtonDetails from 'Components/buttonLibrary/ButtonDetails';
import { buttonLibraryReducer, types } from 'Components/buttonLibrary/reducer/buttonLibraryReducer';
import { compareObjects } from 'Helpers/ObjectHelper';
import { useEnhancedFormContext, withFormik } from 'Components/formik/formikWrappers';
import { useFormikContext } from 'formik';

const Container = styled('div')({
  height: 'calc(100vh - 224px)',
  display: 'flex',
  overflow: 'hidden',
});

const Buttons = (props) => {
  const { initialValues } = useFormikContext();
  const [selectedButton, setSelectedButton] = useState(null);
  const [buttonLibrary, dispatch] = useReducer(buttonLibraryReducer);
  const { canSaveChanges } = useEnhancedFormContext();
  const isReadOnly = !canSaveChanges;

  useEffect(() => {
    dispatch({ type: types.INITIALIZE_STATE, library: props.data.deviceButtons });
  }, [props.data.deviceButtons]);

  const onDeleteButton = (categoryTempId, buttonTempId) => {
    dispatch({ type: types.DELETE_BUTTON, categoryTempId, buttonTempId });
    setSelectedButton(null);
  };

  const onSaveButton = (categoryTempId, button) => {
    dispatch({ type: types.SAVE_BUTTON, categoryTempId, button });
    setSelectedButton(null);
  };

  useEffect(() => {
    if (buttonLibrary && !compareObjects(buttonLibrary, props.data.deviceButtons)) {
      props.onChange({ target: { name: 'deviceButtons', value: buttonLibrary } });
    }
    // eslint-disable-next-line
  }, [buttonLibrary]);

  return (
    <Container>
      <Box width="40%" height="100%" display="flex" flexDirection="column" justifyContent="space-between">
        <Box maxHeight="90%" overflow="hidden">
          <ButtonLibraryPreview
            namespace={'deviceButtons'}
            initialButtonLibrary={initialValues.deviceButtons}
            buttonLibrary={props.data.deviceButtons}
            onSelectButton={(button) => setSelectedButton(button)}
            dispatch={dispatch}
            isReadOnly={isReadOnly}
          />
        </Box>
        <Box maxHeight="10%" borderTop="1px solid black">
          <DeviceButtons
            selectedLibraryId={props.data.buttonLibraryId}
            onChange={props.onChange}
            dispatch={dispatch}
            isReadOnly={isReadOnly}
          />
        </Box>
      </Box>
      <Box width="60%" height="100%" padding="15px" borderLeft="1px solid black">
        <ButtonDetails
          selectedButton={selectedButton}
          buttonLibrary={buttonLibrary}
          onCancel={() => setSelectedButton(null)}
          onSave={onSaveButton}
          onDelete={onDeleteButton}
          isReadOnly={isReadOnly}
        />
      </Box>
    </Container>
  );
};

Buttons.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
};

export default withFormik(Buttons);
