import Axios from 'axios';
import * as Sentry from '@sentry/react';

import Config from 'Config';
import AuthManager from 'Auth/AuthManager';
import store from 'redux/store';
import { enqueueSnackbar } from 'redux/notifications/actions';
import { LocalStorageKeys } from 'Constants/LocalStorageKeys';

export const ApiHeaders = {
  X_CORRELATION_ID: 'X-Correlation-ID',
  X_TENANT_ID: 'X-Tenant-ID',
  X_USER_LANGUAGE_CODE: 'X-UserLanguage-Code',
};
export const CancelToken = Axios.CancelToken;
export const isCancel = Axios.isCancel;

const axiosInstance = Axios.create({
  baseURL: Config.apiDefaultURL,
  timeout: Config.apiTimeout,
});

axiosInstance.interceptors.request.use(async (config) => {
  if (config.url.match('/identity')) {
    Sentry.captureMessage("temporary modify axiosInstance config rules: 'withCredentials: true'");
    config.withCredentials = true;
  }

  // Metadata
  config.metadata = { startTime: new Date() };

  // Authorization
  const user = await AuthManager.getUserAsync();
  const token = user && user.access_token;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.sid = user.profile.sid;
  }

  // Required APIs headers
  const currentSiteId = store.getState().sites.currentSiteId || store?.getState().user?.data?.tenantId;

  if (currentSiteId && !config.headers[ApiHeaders.X_TENANT_ID]) {
    config.headers[ApiHeaders.X_TENANT_ID] = currentSiteId;
  }

  config.headers[ApiHeaders.X_CORRELATION_ID] = `F/${Date.now()}`;
  config.headers[ApiHeaders.X_USER_LANGUAGE_CODE] = localStorage.getItem(LocalStorageKeys.USER_LANGUAGE) || 'en';

  return config;
});

axiosInstance.interceptors.response.use(
  function (response) {
    response.config.metadata.endTime = new Date();
    response.duration = response.config.metadata.endTime - response.config.metadata.startTime;

    return response;
  },
  function (error) {
    if (!isCancel(error)) {
      error.config.metadata.endTime = new Date();
      error.duration = error.config.metadata.endTime - error.config.metadata.startTime;
    }

    return Promise.reject(error);
  }
);

function handleError(error) {
  if (isCancel(error)) {
    // The request was cancel by user, rethrow to handle cancelation in component
    throw error;
  } else if (error.response) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    const message =
      error.response.data?.title || error.response.statusText || error.response.status === 500
        ? 'Unexpected error'
        : error.message;

    store.dispatch(
      enqueueSnackbar({
        message,
      })
    );
    Sentry.captureMessage(`Server responded with a status code that falls out of the range of 2xx`, {
      tags: {
        correlationId: error.config.headers[ApiHeaders.X_CORRELATION_ID],
        errorId: error.response.data?.ErrorId,
        'response.status': error.response.status,
      },
      extra: {
        method: error.config.method,
        params: error.config.params,
        url: error.config.url,
        status: error.response.status,
        message,
      },
    });

    if ([401, 403].includes(error.response.status)) {
      alert(`user will be logout - response code ${error.response.status}`);
      AuthManager.logout();
    }
    throw error;
  } else if (error.request) {
    // The request was made but no response was received
    Sentry.captureMessage('The request was made but no response was received', {
      tags: {
        correlationId: error.config.headers[ApiHeaders.X_CORRELATION_ID],
      },
      extra: {
        method: error.config.method,
        params: error.config.params,
        url: error.config.url,
        timeout: error.config.timeout,
        message: error.message,
      },
    });
    store.dispatch(
      enqueueSnackbar({
        message: error.message,
      })
    );
    throw error;
  } else {
    // Something happened in setting up the request that triggered an Error
    Sentry.captureMessage('Something happened in setting up the request that triggered an Error', {
      tags: {
        correlationId: error.config.headers[ApiHeaders.X_CORRELATION_ID],
      },
      extra: {
        error: error.toJSON(),
      },
    });
    throw error;
  }
}

const apiParamsSerializer = (params) =>
  Object.entries(params)
    .map(([key, value]) => {
      if (value !== 0 && !value) {
        return '';
      }

      if (Array.isArray(value)) {
        return value.map((arrayValue) => `${key}=${encodeURIComponent(arrayValue)}`).join('&');
      }

      return `${key}=${encodeURIComponent(value)}`;
    })
    .join('&');

class ApiClient {
  get = (url, config = {}) =>
    axiosInstance
      .get(url, {
        paramsSerializer: apiParamsSerializer,
        ...config,
      })
      .catch(handleError);

  getWithError = (url, params, headers) => {
    if (headers) {
      return axiosInstance.get(url, { params, paramsSerializer: apiParamsSerializer, headers });
    } else {
      return axiosInstance.get(url, { params, paramsSerializer: apiParamsSerializer });
    }
  };

  post = (url, data, config = {}) => {
    return axiosInstance.post(url, data, config).catch(handleError);
  };

  put = (url, data, config = {}) => {
    return axiosInstance.put(url, data, config).catch(handleError);
  };

  delete = (url, data, config = {}) => {
    return axiosInstance
      .delete(url, {
        data,
        ...config,
      })
      .catch(handleError);
  };
}

export default ApiClient;
