import Checkbox from 'Components/inputs/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import LocaleKeys from 'Localization/LocaleKeys';
import PropTypes from 'prop-types';
import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import T from 'i18n-react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  buttonCell: {
    padding: '0 25px',
  },
  textField: {
    minWidth: '200px',
  },
  toolTipBlock: {
    whiteSpace: 'nowrap',
  },
  iconButton: {
    opacity: 0,
  },
  headerCell: {
    paddingRight: '0px !important',
    paddingLeft: '5px !important',
    '&:hover .IconButton': {
      opacity: 1,
    },
  },
});

export class EnhancedTableHead extends React.Component {
  state = { filterColumns: [], searchColumn: '' };

  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  addFilterColumn = (property) => {
    if (!this.state.filterColumns.includes(property)) {
      this.setState({ filterColumns: [...this.state.filterColumns, property] });

      return;
    }
    this.setState({
      filterColumns: [
        ...this.state.filterColumns.filter((column) => {
          return column !== property;
        }),
      ],
    });
  };

  getTableCell = (tableKey, className, tableName, header, index) => (
    <TableCell key={`${tableKey}-${index}`} className={className} id={`table-${tableName}-headerRow-${index}`}>
      {header}
    </TableCell>
  );

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

    let i = 0;

    return (
      <TableHead>
        <TableRow>
          {this.props.selectionColumn ? (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                value={numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            </TableCell>
          ) : null}
          {this.props.keys.map((row) => {
            if ((this.props.hideColumns && this.props.hideColumns.indexOf(row) === -1) || !this.props.hideColumns) {
              i++;

              return (
                <TableCell
                  key={`${row}-${i}`}
                  className={this.props.classes.headerCell}
                  sortDirection={orderBy === row ? order : false}
                  id={`table-${this.props.tableName}-headerRow-${i}`}
                >
                  <div className={this.props.classes.toolTipBlock}>
                    {this.state.filterColumns.includes(row) ? (
                      <Tooltip title="Filter">
                        <TextField
                          onChange={this.props.setFilters(row)}
                          debounceTimer={0}
                          label={T.translate(LocaleKeys.labels[row])}
                          variant="outlined"
                          autoFocus
                          className={this.props.classes.textField}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Remove filter"
                                  onClick={() => this.props.removeFilters(row)}
                                  id={`table-${this.props.tableName}-headerRow-${i}-filter-clear`}
                                >
                                  <CloseIcon onClick={() => this.addFilterColumn(row)} />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          id={`table-${this.props.tableName}-headerRow-${i}-filter-input`}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Sort" placement={row.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
                        <TableSortLabel
                          active={orderBy === row}
                          direction={order}
                          onClick={this.createSortHandler(row)}
                        >
                          {T.translate(LocaleKeys.labels[row] || row)}
                        </TableSortLabel>
                      </Tooltip>
                    )}
                    {this.state.filterColumns.includes(row) ? null : (
                      <IconButton
                        className={`${this.props.classes.iconButton} IconButton`}
                        aria-label="Filter"
                        onClick={() => this.addFilterColumn(row)}
                      >
                        <SearchIcon />
                      </IconButton>
                    )}
                  </div>
                </TableCell>
              );
            }

            return false;
          })}

          {this.props.inheritedChildren &&
            this.props.inheritedChildren.map((child) => {
              i++;

              return this.getTableCell(
                child.props.tableKey,
                this.props.classes.buttonCell,
                this.props.tableName,
                child.props.header,
                i
              );
            })}

          {this.props.actionButtons &&
            this.props.actionButtons.map((child) => {
              i++;

              return this.getTableCell(
                child.props.tableKey,
                this.props.classes.buttonCell,
                this.props.tableName,
                child.props.header,
                i
              );
            })}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object,
  onSelectAllClick: PropTypes.func,
  hideColumns: PropTypes.array,
  keys: PropTypes.array,
  numSelected: PropTypes.number,
  rowCount: PropTypes.number,
  selectionColumn: PropTypes.bool,
  setFilters: PropTypes.func,
  onRequestSort: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  removeFilters: PropTypes.func,
  actionButtons: PropTypes.array,
  inheritedChildren: PropTypes.array,
};

export default withStyles(styles)(EnhancedTableHead);
