import React, { Component } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import { UserRolesDetails, NewUserRoles } from 'routes/Routes';
import { getRoles, deleteRole } from 'Api/policyServer';
import CopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Table from 'Components/display/table/Table';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import DeleteDialog from 'Components/dialogs/DeleteDialog';
import Loader from 'Components/Loader';
import TableActionButton from 'Components/display/table/TableActionButton';

const formatDataForTableView = (data) =>
  Object.values(data).map((row) => ({
    code: row.code,
    id: row.id,
    name: row.name,
    permissions: row.permissions.map((item) => ({
      item,
      name: T.translate(item.code),
    })),
  }));

class UserRoles extends Component {
  state = {
    isLoaded: false,
    roles: [],
    deleteRoleDialogOpen: false,
    roleCodeToRemove: null,
  };

  componentDidMount = () => {
    this.setData();
  };

  setData = async () => {
    try {
      this.setState({ isLoaded: false });
      const { data } = await getRoles();

      this.setState({ roles: data, isLoaded: true });
    } catch {
      alert('error during try get user roles');
    }
  };

  handleAddClick = () => {
    const url = NewUserRoles.getUrl();

    this.props.history.push(url);
  };

  handleCopyClick = (code) => {
    const url = NewUserRoles.getUrl();

    this.props.history.push(url, { baseOnRoleCode: code });
  };

  handleDeleteClick = (roleCode) => {
    this.setState({ deleteRoleDialogOpen: true, roleCodeToRemove: roleCode });
  };

  handleDeleteRoleConfirm = () => {
    return deleteRole(this.state.roleCodeToRemove)
      .then(() => {
        this.setState({ deleteRoleDialogOpen: false, roleCodeToRemove: null });
      })
      .finally(() => this.setData());
  };

  handleDeleteRoleCancel = () => {
    this.setState({ deleteRoleDialogOpen: false, roleCodeToRemove: null });
  };

  handleRowDoubleClick = (param, row) => {
    this.navigateToTemplateDetails(param);
  };

  handleEditClick = (param, row) => {
    this.navigateToTemplateDetails(param);
  };

  navigateToTemplateDetails = (name) => {
    const url = UserRolesDetails.getUrl({ name: name.replace(/\s/g, '-') });

    this.props.history.push(url);
  };

  getTableActionButtons = () => {
    const actionButtons = [];

    if (this.props.permissions[Permissions.CanEditUserRoles]) {
      const editItemButton = (
        <TableActionButton
          key="editItemButton"
          onClick={this.handleEditClick}
          clickparam={'name'}
          icon={<EditIcon />}
          tooltipText={T.translate(LocaleKeys.tooltips.editItem)}
        />
      );

      actionButtons.push(editItemButton);
    }
    if (this.props.permissions[Permissions.CanCopyUserRoles]) {
      const copyItemButton = (
        <TableActionButton
          key="copyUserButton"
          onClick={this.handleCopyClick}
          clickparam={'code'}
          icon={<CopyIcon />}
          tooltipText={T.translate(LocaleKeys.tooltips.copyItem)}
        />
      );

      actionButtons.push(copyItemButton);
    }
    if (this.props.permissions[Permissions.CanRemoveUserRoles]) {
      const deleteItemButton = (
        <TableActionButton
          key="deleteItemButton"
          onClick={this.handleDeleteClick}
          clickparam={'id'}
          icon={<DeleteIcon />}
          tooltipText={T.translate(LocaleKeys.tooltips.deleteItem)}
        />
      );

      actionButtons.push(deleteItemButton);
    }

    return actionButtons;
  };

  render() {
    if (!this.state.isLoaded) {
      return <Loader />;
    }

    const actionButtons = this.getTableActionButtons();
    const formattedData = formatDataForTableView(this.state.roles);

    return (
      <>
        <DeleteDialog
          isOpen={this.state.deleteRoleDialogOpen}
          onConfirm={this.handleDeleteRoleConfirm}
          onCancel={this.handleDeleteRoleCancel}
          disableConfirm={!this.state.roleCodeToRemove}
          title={T.translate(LocaleKeys.messages.deleteUserRoleDialogTitle)}
          message={T.translate(LocaleKeys.messages.deleteUserRoleDialogMessage)}
        />
        <Table
          data={formattedData}
          onRowDoubleClick={this.handleRowDoubleClick}
          redirect={'name'}
          onAddClick={this.props.permissions[Permissions.CanAddUserRoles] && this.handleAddClick}
          hideColumns={['code', 'id']}
          actionButtons={actionButtons}
          tableName="userRoles"
        />
      </>
    );
  }
}

UserRoles.propTypes = {
  history: PropTypes.object,
  permissions: PropTypes.object,
};

export default compose(
  withRouter,
  withPermissions([Permissions.CanAccessUserRolesPage, Permissions.CanReadUserRoles])
)(UserRoles);
