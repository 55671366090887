import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { styled } from '@material-ui/core';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import { MaskedTextInput } from 'Components/formik/fields';
import { Masks } from 'Components/inputs/MaskedTextInput';

const MaskedTextInputFieldStyled = styled(MaskedTextInput)({
  marginBottom: '10px !important',
  width: '100%',
});

const MessagingStatus = ({ onChange }) => {
  const handleIndicatorChange = (e) => {
    const { value, name } = e.target;

    if (!value || value.includes('_')) {
      return;
    }

    const toArray = value.split(' ');

    const minutes = +toArray[0].replace('h', '') * 60 + +toArray[1].replace('min', '');

    onChange({ target: { value: minutes, name: name } });
  };

  const minutesToTime = (minutes) => {
    const m = minutes % 60;
    const h = (minutes - m) / 60;

    return h.toString().padStart(2, '0') + m.toString().padStart(2, '0');
  };

  return (
    <ColumnDisplayer>
      <div style={{ borderBottom: 'solid', borderColor: '#70b603' }}>
        <MaskedTextInputFieldStyled
          key={'indicatorBefore'}
          maskConfig={Masks.HoursMinutes}
          label={T.translate(LocaleKeys.labels.before)}
          name="indicatorBefore"
          style={{ minWidth: '250px' }}
          onChange={handleIndicatorChange}
          convertValue={minutesToTime}
        />
      </div>
      <div style={{ borderBottom: 'solid', borderColor: '#f59a23' }}>
        <MaskedTextInputFieldStyled
          key={'indicatorTo'}
          maskConfig={Masks.HoursMinutes}
          label={T.translate(LocaleKeys.labels.to)}
          name="indicatorTo"
          style={{ minWidth: '250px' }}
          onChange={handleIndicatorChange}
          convertValue={minutesToTime}
        />
      </div>
      <div style={{ borderBottom: 'solid', borderColor: '#d9001b' }}>
        <MaskedTextInputFieldStyled
          key={'indicatorAfter'}
          maskConfig={Masks.HoursMinutes}
          label={T.translate(LocaleKeys.labels.after)}
          name="indicatorTo"
          style={{ minWidth: '250px' }}
          convertValue={minutesToTime}
          onChange={() => {}}
          disabled
        />
      </div>
    </ColumnDisplayer>
  );
};

MessagingStatus.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
};

export default MessagingStatus;
