import { connect } from 'react-redux';
import { makeGetVisibleMapAreasSelector } from 'redux/mapOverlays/selectors';

const makeMapStateToProps = () => {
  const getVisibleMapAreas = makeGetVisibleMapAreasSelector();

  return (state, props) => ({
    mapAreas: getVisibleMapAreas(state, props),
  });
};

export const withVisibleMapAreas = connect(makeMapStateToProps);
