import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as timeago from 'timeago.js';
import { LocalStorageKeys } from 'Constants/LocalStorageKeys';

const RelativeTime = ({ timestamp: timestampInSeconds, warning = 3600, alarm = 3600 * 2, showTimeAgo, ...props }) => {
  // just for refresh
  const [, setFlag] = useState(false);
  // prevent of setting time in future
  const timeAgoString = timeago.format(
    Math.min(timestampInSeconds * 1000, +new Date()),
    localStorage.getItem(LocalStorageKeys.USER_LANGUAGE)
  );
  const timeDiff = +new Date() / 1000 - timestampInSeconds;
  let color = '#71B603';
  let value = 0;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFlag((prev) => !prev);
    }, 45 * 1000); // 45 seconds

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  if (timestampInSeconds === -1) {
    return false;
  }
  if (timeDiff < warning) {
    value = (timeDiff / warning) * 100;
  } else if (timeDiff < alarm) {
    color = '#F59A24';
    value = ((timeDiff - warning) / (alarm - warning)) * 100;
  } else {
    color = '#D9041C';
    value = 100;
  }

  return (
    <>
      {showTimeAgo && timeAgoString}
      {React.cloneElement(props.children, { value, color })}
    </>
  );
};

RelativeTime.propTypes = {
  showTimeAgo: PropTypes.bool,
  alarm: PropTypes.number,
  warning: PropTypes.number,
  timestamp: PropTypes.number,
};

export default RelativeTime;
