import Button from 'Components/Button';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import LocaleKeys from 'Localization/LocaleKeys';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { getSiteGroupByName, putSiteGroup, postSiteGroup, getSites } from 'Api/sites';
import { getUsers } from 'Api/users';
import T from 'i18n-react';
import { SitesGroups } from 'routes/Routes';
import { compose } from 'redux';
import { styled, Paper, Box, CircularProgress } from '@material-ui/core';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import { withRouter } from 'react-router-dom';
import SaveOnLeave from 'Components/dialogs/SaveOnLeave';
import { clone } from 'Helpers/ObjectHelper';
import { FormikEnhanced } from 'Components/formik/formikWrappers';
import { MultiSelectWithApiCall, MultiSelectWithFilterWithApiCall, TextField } from 'Components/formik/fields';
import siteGroupsValidation from './SiteGroupsValidation';

const initialState = {
  name: '',
  description: '',
  users: [],
  sites: [],
};

const ButtonStyled = styled(Button)({
  float: 'right',
  marginLeft: '10px',
});

const Container = styled(Paper)({
  height: 'calc(100vh - 170px)',
  overflow: 'hidden',
  margin: 10,
});

const Details = (props) => {
  const [sitesGroup, setSitesGroup] = useState(null);

  useEffect(() => {
    const name = props.match.params?.name?.replace(/-/g, ' ');

    if (name) {
      getSiteGroupByName(name).then(({ data }) => {
        data.users = (data.users && data.users.map((u) => ({ label: u, value: u }))) || [];
        setSitesGroup(data);
      });
    } else {
      setSitesGroup(clone(initialState));
    }
  }, [props.match.params]);

  const isEditMode = () => !!props.match.params.name;

  const onSubmit = (values, { setSubmitting }) => {
    const url = SitesGroups.getUrl();

    const data = { ...values };

    if (data.users) {
      data.users = data.users.map((u) => u.value);
    }

    if (isEditMode()) {
      putSiteGroup(sitesGroup.groupId, data).then(() => {
        setSubmitting(false);
        props.history.push(url);
      });
    } else {
      postSiteGroup(data).then(() => {
        setSubmitting(false);
        props.history.push(url);
      });
    }
  };

  if (!sitesGroup) {
    return <CircularProgress />;
  }

  const canSaveChanges = isEditMode()
    ? !!props.permissions[Permissions.CanEditSiteGroups]
    : !!props.permissions[Permissions.CanAddSiteGroups];

  const getOption = (option) => ({ label: option.login, value: option.login });

  return (
    <FormikEnhanced
      initialValues={sitesGroup}
      enableReinitialize
      validationSchema={siteGroupsValidation}
      validateOnMount
      onSubmit={onSubmit}
      canSaveChanges={canSaveChanges}
    >
      {({ values, dirty, isValid, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Container>
            <ColumnDisplayer>
              <TextField label={T.translate(LocaleKeys.labels.siteGroup)} name="name" showPending />
              <MultiSelectWithApiCall
                displayField="name"
                apiCall={getSites}
                valueField="siteId"
                label={T.translate(LocaleKeys.labels.sites)}
                disabled={sitesGroup.allSites}
                name="sites"
              />
              <MultiSelectWithFilterWithApiCall
                name="users"
                label={T.translate(LocaleKeys.labels.users)}
                apiCall={getUsers}
                displayField="login"
                valueField="login"
                sortByDisplayField
                getOption={getOption}
                fullWidth
              />
              <TextField label={T.translate(LocaleKeys.labels.description)} name="description" />
            </ColumnDisplayer>
          </Container>
          <Box margin={'10px'}>
            <ButtonStyled
              onClick={() => {
                const url = SitesGroups.getUrl();

                props.history.push(url);
              }}
              id="siteGroups-submitButton"
            >
              {T.translate(LocaleKeys.labels.cancel)}
            </ButtonStyled>
            {canSaveChanges && (
              <ButtonStyled type="submit" disabled={!(dirty && isValid) || isSubmitting} id="siteGroups-submitButton">
                {T.translate(LocaleKeys.labels.save)}
              </ButtonStyled>
            )}
          </Box>
          {canSaveChanges && <SaveOnLeave saveData={() => onSubmit(values)} dataChanged={dirty} validForm={isValid} />}
        </form>
      )}
    </FormikEnhanced>
  );
};

Details.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
};

export const SitesGroupsDetails = compose(
  withRouter,
  withPermissions([
    Permissions.CanAccessSiteGroupsPage,
    Permissions.CanReadSiteGroups,
    Permissions.CanReadSites,
    Permissions.CanReadUsers,
  ])
)(Details);

export const SitesGroupsNew = compose(
  withRouter,
  withPermissions([
    Permissions.CanAccessSiteGroupsPage,
    Permissions.CanAddSiteGroups,
    Permissions.CanReadSites,
    Permissions.CanReadUsers,
  ])
)(Details);
