import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Box } from '@material-ui/core';
import Dropdown from 'Components/inputs/Dropdown';
import { getSites } from 'Api/sites';
import Button from 'Components/Button';
import { withRouter } from 'react-router-dom';
import { Root } from 'routes/Routes';
import Loader from 'Components/Loader';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { setCurrentSite } from 'redux/sites/actions';
import { getCheckAccess } from 'Api/policyServer';
import Message from 'Components/Message';
import * as Sentry from '@sentry/react';

const SwitchSiteDialog = ({ open, userTenantIds, currentSiteId, setCurrentSite, closeDialog, history }) => {
  const [sites, setSites] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [buttonOkActive, setButtonOkActive] = useState(true);
  const [selectedSite, setSelectedSite] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (open) {
      getSites()
        .then(({ data }) => {
          setSites(data.filter((site) => userTenantIds.includes(site.siteId)));
        })
        .catch(() => {
          setErrorMessage(T.translate(LocaleKeys.messages.errorDuringLoadData));
          setButtonOkActive(false);
        })
        .finally(() => {
          setIsLoaded(true);
        });
    }
  }, [open, userTenantIds]);

  const handleOK = () => {
    setCurrentSite({ id: selectedSite.id, name: selectedSite.name });
    const url = Root.getUrl({
      siteName: selectedSite.name.replace(/\s/g, '-'),
    });

    history.push(url);
    closeDialog();
    setIsLoaded(false);
    setErrorMessage(``);
    setSelectedSite(null);
    setButtonOkActive(false);
  };

  const handleClose = () => {
    closeDialog();
    setIsLoaded(false);
    setErrorMessage(``);
    setSelectedSite(null);
    setButtonOkActive(false);
  };

  const handleChange = async (event) => {
    const { value } = event.target;

    setSelectedSite(value);
    await checkAccess(value.siteId, value.name);
  };

  const checkAccess = (siteId, siteName) => {
    setButtonOkActive(false);
    setShowLoader(true);
    setErrorMessage(null);

    return getCheckAccess(siteId)
      .then(() => {
        setButtonOkActive(true);
      })
      .catch((error) => {
        if (error.response?.status === 403) {
          setErrorMessage(T.translate(LocaleKeys.messages.youDontHaveAccessToSelectedSite, { selectedSite: siteName }));
        } else {
          setErrorMessage(T.translate(LocaleKeys.messages.errorDuringLoadData));
          Sentry.captureMessage(`Switch site dialog, checkAccess error`, {
            extra: {
              method: error.config.method,
              params: error.config.params,
              url: error.config.url,
              status: error.response?.status,
              message: error.message,
              siteId,
            },
          });
        }
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  return (
    <Dialog open={open} fullWidth maxWidth={'sm'}>
      <DialogTitle>{T.translate(LocaleKeys.labels.switchSite)}</DialogTitle>
      {(isLoaded && (
        <>
          <DialogContent>
            <>
              <Dropdown
                label={T.translate(LocaleKeys.labels.switchSite)}
                data={sites}
                displayField="name"
                onChange={handleChange}
                value={selectedSite || sites.find((s) => s.siteId === currentSiteId)}
                fullWidth
                withoutEmpty
              />
              {showLoader && (
                <Box align="center" p={4}>
                  <CircularProgress />
                </Box>
              )}
              {errorMessage && <Message text={errorMessage} color="error" />}
            </>
          </DialogContent>
        </>
      )) || <Loader />}
      <DialogActions>
        <Button onClick={handleOK} disabled={!selectedSite || !buttonOkActive}>
          {T.translate(LocaleKeys.labels.ok)}
        </Button>
        <Button onClick={handleClose}>{T.translate(LocaleKeys.labels.cancel)}</Button>
      </DialogActions>
    </Dialog>
  );
};

const mapState = (state) => ({
  currentSiteId: state.sites.currentSiteId,
});

const mapDispatch = {
  setCurrentSite,
};

SwitchSiteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  userTenantIds: PropTypes.array.isRequired,
  currentSiteId: PropTypes.string,
};

export default compose(withRouter, connect(mapState, mapDispatch))(SwitchSiteDialog);
