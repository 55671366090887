import Accreditation from './userInformation/Accreditation';
import CommonInfo from './userInformation/CommonInfo';
import Communication from './userInformation/Communication';
import ExpansionSection from 'Components/display/ExpansionPanel/ExpansionSection';
import PropTypes from 'prop-types';
import LocaleKeys from 'Localization/LocaleKeys';
import React from 'react';

const UserInformation = (props) => (
  <ExpansionSection data={props.data} onChange={props.onChange}>
    <CommonInfo label={LocaleKeys.labels.commonInfo} allowLoginEdit={props.mode === 'new'} />
    <Communication label={LocaleKeys.labels.communication} />
    <Accreditation label={LocaleKeys.labels.accreditation} />
  </ExpansionSection>
);

UserInformation.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
  mode: PropTypes.string,
};

export default UserInformation;
