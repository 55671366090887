import React, { useState } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import Paper from '@material-ui/core/Paper';
import LeafletMapRaw from 'Components/LeafletMap';
import Button from 'Components/Button';
import SaveOnLeave from 'Components/dialogs/SaveOnLeave';
import General from './details/General';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { postMapArea } from 'Api/maps';
import { MapAreas } from 'routes/Routes';
import ExtendedMapDrawing from 'Components/LeafletMap/controls/ExtendedMapDrawing';
import CombinedInput from 'Components/LeafletMap/controls/CombinedInput';
import { Permissions } from 'Constants/permissions';
import withPermissions from 'hocs/withPermissions';
import { Formik } from 'formik';
import Tabs from 'Components/display/tabs/Tabs';
import { withField } from 'Components/formik/formikWrappers';
import MapAreasValidation from './MapAreasValidation';

const LeafletMap = withField(LeafletMapRaw);

const styles = (theme) => ({
  button: {
    float: 'right',
    marginLeft: '10px',
    marginTop: '5px',
  },
  paper: {
    padding: '15px',
    display: 'flex',
  },
  tabWrapper: {
    minWidth: '100%',
  },
  columnLeft: {
    flexBasis: '62%',
  },
  columnRight: {
    flexBasis: '100%',
    paddingLeft: '10px',
    height: 600,
  },
  drawing: {
    marginLeft: '10px',
    paddingLeft: '10px',
    float: 'right',
  },
});

const New = (props) => {
  const [isDrawingEnabled, setIsDrawingEnabled] = useState(false);

  const saveData = (values) => {
    return postMapArea(values);
  };

  const redirect = () => {
    const url = MapAreas.getUrl();

    props.history.push(url);
  };

  const onSubmit = (values, { setSubmitting }) => {
    saveData(values).finally(() => {
      setSubmitting(false);
      redirect();
    });
  };

  const onCancel = () => {
    redirect();
  };

  const { classes } = props;

  return (
    <Formik
      initialValues={{}}
      enableReinitialize
      validationSchema={MapAreasValidation}
      validateOnMount
      onSubmit={onSubmit}
    >
      {({ values, dirty, isValid, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Paper className={classes.paper}>
            <div className={classes.columnLeft}>
              <Tabs label="mapAreaDetails" defaultTab={0}>
                <General label={LocaleKeys.labels.description} />
              </Tabs>
            </div>
            <div className={classes.columnRight}>
              <LeafletMap
                className={classes.drawing}
                name={'area'}
                setSaveButtonState={setIsDrawingEnabled}
                controls={[
                  {
                    element: ExtendedMapDrawing,
                    position: 'thirdtopleft',
                  },
                  {
                    element: CombinedInput,
                    position: { marginTop: 10, marginLeft: 65 },
                  },
                ]}
                disableAreaClusteringAtZoom={0}
              />
            </div>
          </Paper>
          <Button className={classes.button} onClick={onCancel} id="new-mapAreas-cancelButton">
            {T.translate(LocaleKeys.labels.cancel)}
          </Button>
          <Button
            className={classes.button}
            type="submit"
            disabled={!(dirty && isValid) || isSubmitting || isDrawingEnabled}
            id="new-mapAreas-submitButton"
          >
            {T.translate(LocaleKeys.labels.save)}
          </Button>

          <SaveOnLeave
            saveData={() => this.saveData(values)}
            dataChanged={dirty}
            validForm={isValid && !isDrawingEnabled}
          />
        </form>
      )}
    </Formik>
  );
};

New.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  permissions: PropTypes.array,
};

export default compose(
  withPermissions([Permissions.CanAccessMapAreasPage, Permissions.CanReadMapAreas, Permissions.CanAddMapArea]),
  withStyles(styles)
)(New);
