import ButtonMaterial from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { CircularProgress, Tooltip } from '@material-ui/core';

export class Button extends React.Component {
  render() {
    return (
      <Tooltip onClick={this.props.onClick} title={this.props.tooltip || ''}>
        <ButtonMaterial
          className={this.props.className}
          variant={this.props.filled ? 'contained' : 'outlined'}
          size={this.props.size}
          color={this.props.color ? this.props.color : 'primary'}
          name={this.props.name}
          disabled={this.props.disabled}
          component={this.props.component}
          to={this.props.to}
          type={this.props.type}
          id={this.props.id}
        >
          {this.props.children}
          {this.props.showProgress && <CircularProgress size={20} />}
        </ButtonMaterial>
      </Tooltip>
    );
  }
}

Button.propTypes = {
  className: PropTypes.string,
  filled: PropTypes.bool,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element])),
  ]),
  tooltip: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  showProgress: PropTypes.bool,
};

export default Button;
