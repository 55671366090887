import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { Tooltip } from '@material-ui/core';

export const countTimeUntilEnabled = (date, countStart) => {
  if (!date) {
    return 0;
  }
  const timestamp = date && Math.round(+new Date(date) / 1000);

  return Math.floor(countStart - (timestamp && +new Date() / 1000 - timestamp));
};

const CountdownTooltip = ({ seconds, ...props }) => {
  const title =
    seconds > 0
      ? `${T.translate(LocaleKeys.labels.buttonWillBeEnabledAfter)} ${seconds} ${T.translate(LocaleKeys.labels.secs)}`
      : '';

  return (
    <Tooltip title={title} placement="right" {...props}>
      <div style={{ marginLeft: -4 }}>{props.children}</div>
    </Tooltip>
  );
};

CountdownTooltip.propTypes = {
  seconds: PropTypes.number,
};

export default CountdownTooltip;
