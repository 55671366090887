/**
 * @class L.Draw.HandDrawnPolygon
 * @aka Draw.HandDrawnPolygon
 * @inherits L.Draw.Polygon
 */
L.Draw.HandDrawnPolygon = L.Draw.Polygon.extend({
  statics: {
    TYPE: 'handDrawnPolygon',
  },

  // @method initialize(): void
  initialize: function (map, options) {
    L.Draw.Polyline.prototype.initialize.call(this, map, options);

    // Save the type so super can fire, need to do this as cannot do this.TYPE :(
    this.type = L.Draw.HandDrawnPolygon.TYPE;
  },

  _onMouseMove: function (e) {
    const newPos = this._map.mouseEventToLayerPoint(e.originalEvent);
    const latlng = this._map.layerPointToLatLng(newPos);

    // Save latlng
    this._currentLatLng = latlng;

    this._updateTooltip(latlng);

    // Update the guide line
    this._updateGuide(newPos);

    // Update the mouse marker position
    this._mouseMarker.setLatLng(latlng);

    L.DomEvent.preventDefault(e.originalEvent);

    if (e.originalEvent.buttons === 1) {
      this._map.dragging.disable();
      this.addVertex(latlng);
    } else {
      this._map.dragging.enable();
    }
  },
});
