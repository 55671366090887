import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LabelIcon from '@material-ui/icons/Label';
import { styled } from '@material-ui/core/styles';
import ActionButton from 'Components/ActionButton';
import { getButtonLibraryByAssetId } from 'Api/devices';
import { CancelToken, isCancel } from 'Api/ApiClient';
import ErrorMessage from './ErrorMessage';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';

const Section = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
});

const StyledAccordionSummary = styled(AccordionSummary)({
  textAlign: 'center',
});

const StyledLabelIcon = styled(LabelIcon)({
  color: '#2d2c54',
  paddingLeft: 16,
  transform: 'rotate(180deg)',
});

const renderCategoryButtons = (buttons, assetId, alarmId, commandButtons) => {
  return buttons
    .sort((a, b) => a.order - b.order)
    .map((button) => (
      <ActionButton
        key={button.id}
        alarmId={alarmId}
        assetId={assetId}
        backgroundColor={button.color}
        button={button}
        lastSentAt={commandButtons?.[button.externalId]?.lastSentAt}
        currentState={commandButtons?.[button.externalId]?.currentState || button.currentState || button.state}
      />
    ));
};

const ActionButtonsWidget = ({ asset, assetId, userSettings, updateUserSettings }) => {
  const [categories, setCategories] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [retryFlag, setRetryFlag] = useState(false);

  const fetchData = useCallback((assetId, cancelToken) => {
    setIsLoading(true);
    setError(null);
    setCategories(null);
    getButtonLibraryByAssetId(assetId, { cancelToken })
      .then(({ data: buttonLibrary }) => {
        setCategories(buttonLibrary.categories);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!isCancel(error)) {
          setError(error);
          setCategories(null);
          setIsLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    const source = CancelToken.source();

    if (assetId) {
      fetchData(assetId, source.token);
    } else {
      setCategories(null);
      setError(null);
    }

    return () => {
      if (assetId) {
        source.cancel('"assetdId" changed or component unmounted');
      }
    };
  }, [assetId, fetchData, retryFlag]);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <ErrorMessage
        primaryMessage={T.translate(LocaleKeys.messages.errorWhileFetchingData, {
          name: T.translate(LocaleKeys.labels.buttonsLibrary),
        })}
        secondaryMessage={T.translate(LocaleKeys.messages.clickRefreshToTryAgain)}
        onRetry={() => setRetryFlag((prev) => !prev)}
      />
    );
  }

  if (!asset || !categories) {
    return null;
  }

  const lastAlarmId = asset.lastAlarm?.uniqueId;

  return (
    <Box display="flex" flexDirection="column" width="100%">
      {categories.map((category) =>
        category.name === 'General' ? (
          <Section key={category.id}>
            {renderCategoryButtons(category.buttons, assetId, lastAlarmId, asset.commandButtons)}
          </Section>
        ) : (
          <Accordion
            key={category.id}
            expanded={!!userSettings.openedBtnCategoryIds.includes(category.id)}
            onChange={updateUserSettings && updateUserSettings(category.id)}
          >
            <StyledAccordionSummary expandIcon={<ExpandMore />}>
              <FormControlLabel control={<StyledLabelIcon />} label={category.name} />
            </StyledAccordionSummary>
            <AccordionDetails>
              <Box flexGrow={1}>
                {renderCategoryButtons(category.buttons, assetId, lastAlarmId, asset.commandButtons)}
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      )}
    </Box>
  );
};

ActionButtonsWidget.propTypes = {
  assetId: PropTypes.string,
  asset: PropTypes.object,
  userSettings: PropTypes.object,
  updateUserSettings: PropTypes.func,
};

export default ActionButtonsWidget;
