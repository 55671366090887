import * as Yup from 'yup';
import { getSiteExistsByName } from 'Api/sites';
import {
  isIpv4Address,
  isIpv4RangeAddress,
  isAlphanumericOrSpecialSymbol,
  linkRegExp,
} from 'Validators/SharedValidators';
import { IpWhiteListItemTypes } from 'Constants/IpWhiteListItemTypes';
import { WebhookTypes } from 'Constants/WebhookTypes';

let lastValue;
let initialValue;

export const siteNameExists = async (value, id) => {
  if (isAlphanumericOrSpecialSymbol([' '])(value)) {
    const result = lastValue?.value === value ? lastValue?.result : await getSiteExistsByName(value);

    if (id) {
      if (!initialValue || id !== initialValue?.id) {
        initialValue = { value, id };
      }
    } else {
      initialValue = undefined;
    }

    lastValue = { value, result };

    return initialValue?.value === value || !result?.data;
  }

  return false;
};

export const sitesValidation = (context) =>
  Yup.object().shape({
    general: Yup.object().shape({
      name: Yup.string()
        .required('thisFieldIsRequired')
        .max(50, 'nameCannotExceed50Characters')
        .alphanumericOrSpecialSymbol([' '])
        .test('siteNameExists', 'siteWithThisNameAlreadyExists', function (value) {
          return siteNameExists(value, context);
        }),
      description: Yup.string().max(200, 'nameCannotExceed200Characters').nullable(),
    }),
    securityRule: Yup.object().shape({
      concurrentLoginLimit: Yup.number()
        .required('thisFieldIsRequired')
        .test('int', 'valueMustBeInteger', Number.isInteger)
        .min(1, 'valueMustBePositiveNumber'),
      sessionDuration: Yup.number()
        .required('thisFieldIsRequired')
        .test('int', 'valueMustBeInteger', Number.isInteger)
        .min(5, 'valueMustBeGreaterThanOrEqualTo5'),
      minPasswordLength: Yup.number('valueMustBePositiveNumber')
        .required('thisFieldIsRequired')
        .test('int', 'valueMustBeInteger', Number.isInteger)
        .min(1, 'valueMustBePositiveNumber')
        .test('longerThanSumOfMinCharacters', 'longerThanSumOfMinCharacters', function (value) {
          const { minNumberOfDigits, minNumberOfLowercase, minNumberOfSpecialCharacters, minNumberOfUppercase } =
            this.parent;
          const min = minNumberOfDigits + minNumberOfLowercase + minNumberOfSpecialCharacters + minNumberOfUppercase;

          return value >= min;
        }),
      passwordChangeDuration: Yup.number().when('isForcePasswordChange', {
        is: true,
        then: Yup.number()
          .required('thisFieldIsRequired')
          .test('int', 'valueMustBeInteger', Number.isInteger)
          .min(1, 'valueMustBePositiveNumber'),
      }),
      reminderDuration: Yup.number()
        .when('isForcePasswordChange', {
          is: true,
          then: Yup.number()
            .required('thisFieldIsRequired')
            .test('int', 'valueMustBeInteger', Number.isInteger)
            .min(1, 'valueMustBePositiveNumber'),
        })
        .max(Yup.ref('passwordChangeDuration'), 'reminderShouldBeSmallerOrEqualThanChangeDuration'),
      minNumberOfLowercase: Yup.number()
        .required('thisFieldIsRequired')
        .test('int', 'valueMustBeInteger', Number.isInteger)
        .min(1, 'valueMustBePositiveNumber'),
      minNumberOfUppercase: Yup.number()
        .required('thisFieldIsRequired')
        .test('int', 'valueMustBeInteger', Number.isInteger)
        .min(1, 'valueMustBePositiveNumber'),
      minNumberOfDigits: Yup.number()
        .required('thisFieldIsRequired')
        .test('int', 'valueMustBeInteger', Number.isInteger)
        .min(1, 'valueMustBePositiveNumber'),
      minNumberOfSpecialCharacters: Yup.number()
        .required('thisFieldIsRequired')
        .test('int', 'valueMustBeInteger', Number.isInteger)
        .min(1, 'valueMustBePositiveNumber'),
      daysOfNotRepeatPassword: Yup.number()
        .required('thisFieldIsRequired')
        .test('int', 'valueMustBeInteger', Number.isInteger)
        .min(1, 'valueMustBePositiveNumber'),
      numberOfTrials: Yup.number().when('isBlockAccount', {
        is: true,
        then: Yup.number()
          .required('thisFieldIsRequired')
          .test('int', 'valueMustBeInteger', Number.isInteger)
          .min(1, 'valueMustBePositiveNumber'),
      }),
      policyBreachEmail: Yup.string()
        .email('incorrectEmailAddress')
        .when('isBlockAccount', {
          is: true,
          then: Yup.string().required('thisFieldIsRequired'),
        })
        .nullable(),
    }),
    webHooks: Yup.array().of(
      Yup.object()
        .shape({
          type: Yup.number().required('thisFieldIsRequired').oneOf(Object.values(WebhookTypes)),
          url: Yup.string()
            .required('thisFieldIsRequired')
            .max(200, 'nameCannotExceed200Characters')
            .matches(linkRegExp, 'urlMustBeInserted'),
          authUser: Yup.string().required('thisFieldIsRequired').max(50, 'nameCannotExceed50Characters'),
          authPassword: Yup.string().required('thisFieldIsRequired').max(50, 'nameCannotExceed50Characters'),
        })
        .uniqueInCollection('valueMustBeUniqueInCollection', 'type')
    ),
  });

export const isIpRangeValid = (ipAddressRange) => {
  const ipAddressRangeRegex = /^(((\d+\.)+)(\d+))-(\d+)$/;
  const ipAddressRangeGroups = ipAddressRangeRegex.exec(ipAddressRange);
  const ip1 = ipAddressRangeGroups[1];
  const ip2 = `${ipAddressRangeGroups[2]}${ipAddressRangeGroups[5]}`;

  const splitIp1 = ip1.split('.').map((x) => parseInt(x));
  const splitIp2 = ip2.split('.').map((x) => parseInt(x));
  const result = false;

  for (let i = 0; i < 4; i++) {
    if (splitIp2[i] > splitIp1[i]) {
      return true;
    } else if (splitIp2[i] < splitIp1[i]) {
      return false;
    }
  }

  return result;
};

export const IpWhiteListItemValidationSchema = Yup.object().shape({
  type: Yup.string().required('typeCannotBeEmpty'),
  ipAddress: Yup.string()
    .nullable()
    .when('type', {
      is: IpWhiteListItemTypes.SINGLE,
      then: Yup.string()
        .required('thisFieldIsRequired')
        .test('isIpv4Address', 'mustBeIpv4Format', (value) => isIpv4Address(value)),
    }),
  ipAddressRange: Yup.string()
    .nullable()
    .when('type', {
      is: IpWhiteListItemTypes.RANGE,
      then: Yup.string()
        .required('thisFieldIsRequired')
        .test('isIpv4RangeAddress', 'mustBeIpv4RangeFormat', (value) => isIpv4RangeAddress(value))
        .test('isRangeValid', 'mustBeGreaterThanStartIpRange', function (value) {
          return isIpv4RangeAddress(value) && isIpRangeValid(value);
        }),
    }),
});

export default sitesValidation;
