import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Typography, Radio, styled } from '@material-ui/core';
import Button from 'Components/Button';
import Dropdown from 'Components/inputs/Dropdown';

const DropdownStyled = styled(Dropdown)({
  margin: '10px',
  width: '35%',
});

const CONST = {
  DeleteCategoryAndButtons: '1',
  DeleteCategoryAndMoveButtons: '2',
};

const DeleteCategoryDialog = ({ categories, categoryTempId, ...props }) => {
  const [deleteDialogConfirmed, setDeleteDialogConfirmed] = useState(false);
  const [radioValue, setRadioValue] = useState(CONST.DeleteCategoryAndButtons);
  const [targetCategoryTempId, setTargetCategoryTempId] = useState(null);

  useEffect(() => {
    if (deleteDialogConfirmed) {
      radioValue === CONST.DeleteCategoryAndButtons
        ? props.onDeleteCategory(categoryTempId)
        : props.onDeleteCategoryAndMoveButtons(categoryTempId, targetCategoryTempId);
    }
    // eslint-disable-next-line
  }, [deleteDialogConfirmed]);

  const categoriesData = categories
    .filter((category) => category.tempId !== categoryTempId)
    .map(({ tempId, name }) => ({ tempId, name: name === 'General' ? T.translate(LocaleKeys.labels.general) : name }));

  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{T.translate(LocaleKeys.labels.deleteCategory)}</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" justifyContent="space-beetween">
          <Box display="flex" alignItems="center">
            <Radio
              checked={radioValue === CONST.DeleteCategoryAndButtons}
              onChange={(event) => {
                setRadioValue(event.target.value);
              }}
              value={CONST.DeleteCategoryAndButtons}
            />
            <Typography>{T.translate(LocaleKeys.labels.deleteCategoryAndButtons)}</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Radio
              checked={radioValue === CONST.DeleteCategoryAndMoveButtons}
              onChange={(event) => {
                setRadioValue(event.target.value);
              }}
              value={CONST.DeleteCategoryAndMoveButtons}
            />
            <Typography>{T.translate(LocaleKeys.labels.deleteCategoryAndMoveButtons)}</Typography>
            <DropdownStyled
              label={T.translate(LocaleKeys.labels.categoryName)}
              data={categoriesData}
              onChange={(event) => {
                setTargetCategoryTempId(event.target.value);
              }}
              value={targetCategoryTempId}
              displayField={'name'}
              valueField={'tempId'}
              withoutEmpty
              fullWidth
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onCancel();
          }}
          autoFocus
        >
          {T.translate(LocaleKeys.labels.cancel)}
        </Button>
        <Button
          onClick={() => {
            setDeleteDialogConfirmed(true);
          }}
          autoFocus
          disabled={radioValue === CONST.DeleteCategoryAndMoveButtons && targetCategoryTempId === null}
        >
          {T.translate(LocaleKeys.labels.delete)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteCategoryDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDeleteCategory: PropTypes.func.isRequired,
  onDeleteCategoryAndMoveButtons: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  categoryTempId: PropTypes.number.isRequired,
};

export default DeleteCategoryDialog;
