import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import * as Sentry from '@sentry/react';
import { styled } from '@material-ui/core/styles';
import { Dialog, Divider, Box, Button, DialogActions, Typography } from '@material-ui/core';
import LeafletMap from 'Components/LeafletMap';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { connect } from 'react-redux';
import {
  setLoadingAlarmsPage,
  loadAlarmsPageSuccess,
  readAlarms,
  addFeedAlarm,
  addFeedAlarms,
  forceSyncTotal,
} from 'redux/alarms/actions';
import { calculateIncrementTotalBy, getAlarmListsMerged } from 'redux/alarms';
import {
  getAlarmsNotRead,
  putAlarmsMarkManyAsRead,
  putAlarmsMarkAllAsRead,
  getAlarmsNotReadCount,
} from 'Api/notifications';
import ApiIconMarker from 'Components/ApiIconMarker';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import useDebounce from 'hooks/useDebounce';
import { isDate } from 'Helpers/DateTimeHelpers';
import ConfirmDialog from './ConfirmDialog';
import NotificationsTable from './NotificationsTable';
import Filters, { filterNotifications } from './Filters';
import { isChecked } from './utils';
import { compose } from 'redux';
import { withSignalR } from 'context/signalr';
import { getLogin } from 'redux/user/selectors';
import { CancelToken, isCancel } from 'Api/ApiClient';

const BULK_READ_LIMIT = 1000;

const Badge = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: 40,
  height: 40,
  borderRadius: 20,
  color: '#fff',
  backgroundColor: '#f50057',
  margin: '0 auto',
  padding: '0 5px 0 5px',
});

export const isRetryAllowed = (error) =>
  [
    LocaleKeys.messages.errorWhileProcessingRequest,
    LocaleKeys.messages.requestTookTooLongToComplete,
    LocaleKeys.messages.errorWhileFetchingNotifications,
  ].includes(error);

const areAllChecked = (notifications, checkedNotifications, total, checkAllAt) => {
  const newNotReadNotifications = notifications.filter(
    (notification) =>
      +new Date(notification.createdOn) > +checkAllAt && !checkedNotifications.includes(notification.uniqueId)
  );
  const oldNotReadNotifications = notifications.filter(
    (notification) =>
      +new Date(notification.createdOn) <= +checkAllAt && checkedNotifications.includes(notification.uniqueId)
  );
  const uncheckedCount = newNotReadNotifications.length + oldNotReadNotifications.length;

  return checkAllAt
    ? [!uncheckedCount, total - uncheckedCount]
    : [checkedNotifications.length === total, checkedNotifications.length];
};

const isRecipient = (notification, login) =>
  notification.users.length === 0 || notification.users.map((user) => user.toLowerCase()).includes(login.toLowerCase());

const NotificationDialog = ({
  open,
  onClose,
  readAlarms,
  addFeedAlarm,
  addFeedAlarms,
  setLoadingAlarmsPage,
  loadAlarmsPageSuccess,
  total,
  statusHistory,
  hasNextPage,
  setHasNextPage,
  notificationPopup,
  currentSiteId,
  login,
  hub,
  onRefresh,
  setError,
  forceSyncTotal,
}) => {
  const [query, setQuery] = useState('');
  const queryTrimmed = useDebounce(query.trim().toLowerCase());
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [onlyPopup, setOnlyPopup] = useState(false);
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const [checkedNotifications, setCheckedNotifications] = useState([]);
  const [checkAllAt, setCheckAllAt] = useState(null);
  const [filteredTotal, setFilteredTotal] = useState(0);
  const [isLoadingTable, setIsLoadingTable] = useState(0);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [onReadError, setOnReadError] = useState(null);
  const [fetchPageError, setFetchPageError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const prevState = useRef({
    currentSiteId,
    statusHistory,
    from,
    to,
    queryTrimmed,
    onlyPopup,
    open,
  });

  const filteredNotifications = useMemo(() => {
    return filterNotifications(statusHistory, { queryTrimmed, from, to, onlyPopup }).sort(
      (a, b) => +new Date(b.createdOn) - +new Date(a.createdOn)
    );
  }, [statusHistory, queryTrimmed, from, to, onlyPopup]);
  const isFilterApplied = !!from || !!to || queryTrimmed !== '' || onlyPopup;
  const currentTotal = isFilterApplied ? filteredTotal : total;

  useEffect(() => {
    prevState.current = {
      currentSiteId,
      statusHistory,
      filteredNotifications,
      from,
      to,
      queryTrimmed,
      onlyPopup,
      isFilterApplied,
      currentTotal,
      open,
    };
  });

  useEffect(() => {
    const handleOnAlarm = (newAlarm) => {
      const { statusHistory, from, to, queryTrimmed, onlyPopup, isFilterApplied, currentSiteId } = prevState.current;

      if (isRecipient(newAlarm, login) && currentSiteId === newAlarm.tenantId) {
        const prevAlarm = statusHistory.find((alarm) => alarm.uniqueId === newAlarm.uniqueId);
        const passFilters = !!filterNotifications([newAlarm], { from, to, queryTrimmed, onlyPopup });

        addFeedAlarm({ alarm: newAlarm });
        if (isFilterApplied && passFilters) {
          const newestCreatedOn = Math.max(...statusHistory.map((alarm) => +new Date(alarm.createdOn)));
          const incrementTotalBy = calculateIncrementTotalBy(prevAlarm, newAlarm, newestCreatedOn);

          if (incrementTotalBy !== 0) {
            setFilteredTotal((prev) => prev + incrementTotalBy);
          }
        }
      }
    };
    const handleOnAlarms = ({ alarms: newAlarms, readAlarms, tenantId, login: readBy }) => {
      if (tenantId === prevState.current.currentSiteId) {
        const { statusHistory, from, to, queryTrimmed, onlyPopup, isFilterApplied } = prevState.current;
        let alarms = [];

        if (login === readBy) {
          alarms = readAlarms;
        }
        alarms.push(...newAlarms.filter((alarm) => isRecipient(alarm, login)));
        const passFilters = filterNotifications(alarms, { from, to, queryTrimmed, onlyPopup });

        addFeedAlarms({ alarms });
        if (isFilterApplied && passFilters.length) {
          const { incrementTotalBy } = getAlarmListsMerged(statusHistory, passFilters);

          setFilteredTotal((prev) => prev + incrementTotalBy);
        }
      }
    };

    if (hub) {
      hub.on('Alarm', handleOnAlarm);
      hub.on('Alarms', handleOnAlarms);
      hub.onreconnecting((error) => {
        if (error && prevState.current.open) {
          setOnReadError(LocaleKeys.messages.signalRTryingToReconnect);
        } else if (error) {
          setError(LocaleKeys.messages.signalRTryingToReconnect);
        }
      });
      hub.onreconnected(() => {
        if (prevState.current.open) {
          setOnReadError(LocaleKeys.messages.signarRReconnected);
        } else {
          setError(LocaleKeys.messages.signarRReconnected);
        }
      });
    }

    return () => {
      if (hub) {
        hub.off('Alarm', handleOnAlarm);
        hub.off('Alarms', handleOnAlarms);
      }
    };
  }, [hub, login, readAlarms, addFeedAlarm, addFeedAlarms, setError]);

  const [allChecked, checkedCount] = areAllChecked(
    filteredNotifications,
    checkedNotifications,
    currentTotal,
    checkAllAt
  );

  const fetchPage = useCallback(
    (offSet) => {
      setLoadingAlarmsPage({ loadingPage: true });
      setFetchPageError(null);
      getAlarmsNotRead({
        OffSet: offSet.toString(),
        PageSize: 50,
        From: from,
        To: to,
        Search: queryTrimmed,
        ShowAsPopup: onlyPopup || undefined,
      })
        .then(({ data }) => {
          setHasNextPage(data.hasNextPage);
          loadAlarmsPageSuccess({
            alarms: data.alarms,
          });
        })
        .catch((error) => {
          setFetchPageError(error);
        });
    },
    [from, to, queryTrimmed, onlyPopup, setLoadingAlarmsPage, loadAlarmsPageSuccess]
  );

  const fetchData = useCallback(
    (offSet = 0, cancelToken) => {
      setLoadingAlarmsPage({ loadingPage: true });
      setIsLoadingTable(true);
      setFetchPageError(null);
      const params = {
        OffSet: offSet.toString(),
        PageSize: 50,
        From: from,
        To: to,
        Search: queryTrimmed,
        ShowAsPopup: onlyPopup || undefined,
      };

      Promise.all([getAlarmsNotRead(params, cancelToken), getAlarmsNotReadCount(params, cancelToken)])
        .then(([{ data }, { data: total }]) => {
          isFilterApplied && setFilteredTotal(total);
          setIsLoadingTable(false);
          setHasNextPage(data.hasNextPage);
          loadAlarmsPageSuccess({
            alarms: data.alarms,
          });
        })
        .catch((error) => {
          if (!isCancel(error)) {
            setIsLoadingTable(false);
            setFetchPageError(error);
          }
        });
    },
    [isFilterApplied, from, to, queryTrimmed, onlyPopup, setLoadingAlarmsPage, loadAlarmsPageSuccess]
  );

  const handleTableReload = useCallback(() => {
    fetchData(0, CancelToken.source().token);
  }, [fetchData]);

  useEffect(() => {
    if ((from && !isDate(from)) || (to && !isDate(to))) {
      return undefined;
    }
    const source = CancelToken.source();

    if (isFilterApplied) {
      fetchData(0, source.token);
    }

    return () => {
      source.cancel();
    };
  }, [isFilterApplied, from, to, fetchData]);

  useEffect(() => {
    const totalUnreadNotificaions = statusHistory.filter((notification) => !notification.isRead).length;
    const isOutOfSync = total < totalUnreadNotificaions;

    if (isOutOfSync) {
      forceSyncTotal();
      Sentry.captureMessage('Notifications out of sync.', {
        extra: {
          totalUnreadNotificaions,
          total,
          isOutOfSync,
        },
      });
    }
  }, [total, statusHistory, forceSyncTotal]);

  const handleReadSuccess = () => {
    setCheckedNotifications([]);
    setCheckAllAt(null);
    setConfirmDialogOpen(false);
    setOnReadError(null);
    setIsSubmitting(false);
  };

  const handleReadFail = (error) => {
    const errorMessage = error.response
      ? LocaleKeys.messages.errorWhileProcessingRequest
      : LocaleKeys.messages.requestTookTooLongToComplete;

    setOnReadError(errorMessage);
    setIsSubmitting(false);
  };

  const markManyAsRead = async () => {
    setIsSubmitting(true);
    if (checkAllAt) {
      return putAlarmsMarkAllAsRead({
        search: queryTrimmed,
        from,
        to,
        showAsPopup: onlyPopup || undefined,
        excepted: filteredNotifications
          .filter((notification) => !isChecked(notification, checkedNotifications, checkAllAt))
          .map(({ uniqueId }) => uniqueId),
      })
        .then(() => {
          handleReadSuccess();
        })
        .catch((error) => {
          handleReadFail(error);
        });
    } else {
      return putAlarmsMarkManyAsRead(checkedNotifications)
        .then(() => {
          handleReadSuccess();
        })
        .catch((error) => {
          handleReadFail(error);
        });
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth={'lg'} fullWidth>
        <Box height={'calc(40vh)'} minHeight={'calc(40vh)'} width="100%">
          <LeafletMap enableFitBounds={false} fitBoundsProp={selectedNotifications.join(',')}>
            <MarkerClusterGroup>
              {filteredNotifications.map(
                (n) =>
                  selectedNotifications.includes(n.uniqueId) && (
                    <ApiIconMarker
                      key={n.uniqueId}
                      position={n.location}
                      iconId={n.assetIconId}
                      color={n.assetColor}
                      tooltipText={`${n.assetName} - ${n.alarmName}`}
                    />
                  )
              )}
            </MarkerClusterGroup>
          </LeafletMap>
        </Box>
        <Divider />
        <Box display={'flex'} margin={1} justifyContent={'space-between'}>
          <Filters
            query={query}
            setQuery={setQuery}
            from={from}
            setFrom={setFrom}
            to={to}
            setTo={setTo}
            onlyPopup={onlyPopup}
            setOnlyPopup={setOnlyPopup}
            setCheckAllAt={setCheckAllAt}
          />
          <Box display="flex" alignItems={'center'}>
            <Badge>{total}</Badge>
            <Typography variant={'h6'}>{T.translate(LocaleKeys.labels.unread)}</Typography>
          </Box>
        </Box>
        <Divider />
        <NotificationsTable
          filteredNotifications={filteredNotifications}
          selectedNotifications={selectedNotifications}
          setSelectedNotifications={setSelectedNotifications}
          checkedNotifications={checkedNotifications}
          setCheckedNotifications={setCheckedNotifications}
          allChecked={allChecked}
          checkAllAt={checkAllAt}
          setCheckAllAt={setCheckAllAt}
          loadMoreItems={fetchPage}
          hasNextPage={hasNextPage}
          currentTotal={currentTotal}
          isLoading={isLoadingTable}
          error={fetchPageError}
          onReload={handleTableReload}
        />
        <Divider />
        <DialogActions>
          <Box padding={2}>
            <Box textAlign="right">
              <Button
                variant="contained"
                onClick={() => {
                  setQuery('');
                  setTo(null);
                  setFrom(null);
                  setOnlyPopup(false);
                  setCheckedNotifications([]);
                  setCheckAllAt(null);
                  onClose();
                }}
                style={{ marginRight: 10 }}
                disabled={notificationPopup}
                id={'notification-dialog-cancel'}
              >
                {T.translate(LocaleKeys.labels.cancel)}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => (checkedCount > 1 ? setConfirmDialogOpen(true) : markManyAsRead())}
                disabled={
                  isSubmitting || (checkAllAt ? checkedNotifications.length === total : !checkedNotifications.length)
                }
                id={'notification-dialog-ok'}
              >
                {`${T.translate(LocaleKeys.labels.ok)}, ${T.translate(LocaleKeys.labels.accepted)}`.toUpperCase()}
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={confirmDialogOpen}
        handleCancel={() => {
          setOnReadError(null);
          setConfirmDialogOpen(false);
        }}
        handleReadPromise={markManyAsRead}
        allowTryAgain={isRetryAllowed(onReadError)}
        onRefresh={onRefresh}
        error={onReadError}
        bulkReadLimit={checkedCount > BULK_READ_LIMIT ? BULK_READ_LIMIT : null}
      />
    </>
  );
};

const mapState = (state) => ({
  currentSiteId: state.sites.currentSiteId,
  total: state.alarms.total,
  login: getLogin(state),
});

const mapDispatchToProps = {
  readAlarms,
  setLoadingAlarmsPage,
  loadAlarmsPageSuccess,
  addFeedAlarm,
  addFeedAlarms,
  forceSyncTotal,
};

export default compose(connect(mapState, mapDispatchToProps), withSignalR)(NotificationDialog);
