import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getSiteName } from 'redux/user/selectors';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '65%',
    padding: theme.spacing(3, 2),
    margin: 'auto',
    marginTop: '10vh',
    backgroundColor: '#fafafa',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Roboto',
    color: '#333333',
  },
  title: {
    fontSize: '32px',
    fontWeight: '400',
  },
  number: {
    fontSize: '144px',
  },
  message: {
    fontWeight: '400',
    fontSize: '24px',
  },
  button: {
    minWidth: '177px',
    backgroundColor: '#333333',
    height: '33px',
    color: '#ffffff',
    marginTop: '15px',
  },
}));

const ErrorPage = (props) => {
  const classes = useStyles();

  const goToHomePage = () => {
    props.history.push(`/${props.match.params.siteName}`);
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h5" component="h3" className={classes.number}>
        {props.code}
      </Typography>
      <Typography component="p" className={classes.message}>
        {props.message}
      </Typography>

      <Button variant="contained" className={classes.button} onClick={props.customAction || goToHomePage}>
        {props.customButtonName || T.translate(LocaleKeys.labels.goToHomePage)}
      </Button>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  defaultSiteName: getSiteName(state),
});

ErrorPage.propTypes = {
  code: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  customButtonName: PropTypes.string,
  customAction: PropTypes.func,
};

export default compose(connect(mapStateToProps), withRouter)(ErrorPage);
