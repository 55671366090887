import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { styled } from '@material-ui/core/styles';
import { Warning, PriorityHigh, VolumeUp } from '@material-ui/icons';
import { Box, Checkbox, CircularProgress, DialogContent, TableRow, TableCell as Cell } from '@material-ui/core';
import DeviceIcon from 'Components/display/DeviceIcon';
import LinesEllipsis from 'Components/display/LinesEllipsis';
import { ALARM_COLORS } from '../Notifications';
import { groupAlarmReasons, isChecked } from './utils';
import { NotificationStatuses } from 'Constants/NotificationStatuses';
import ErrorMessage from 'Components/display/ErrorMessage';

const DialogContentStyled = styled(DialogContent)({
  padding: '0',
  height: 'calc(40vh)',
  overflow: 'hidden',
});

const RowContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
});

const RowStyled = styled(TableRow)({
  display: 'grid',
  gridTemplateColumns: '40px 70px 70px repeat(6, 180px)',
  cursor: 'pointer',
  maxWidth: 'fit-content',
  width: 1270,
});

const HeaderRow = styled(RowStyled)({
  backgroundColor: '#f5f5f5',
  textAlign: 'center',
  cursor: 'auto',
  height: 55,
});

const TableCell = styled(Cell)({
  width: '100%',
  padding: '5px 10px 5px 10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const WarningStyled = styled(Warning)({
  fill: ({ fill }) => fill,
  justifySelf: 'flex-end',
});

const PriorityHighStyled = styled(PriorityHigh)({
  fill: ({ fill }) => fill,
  visibility: ({ visibility }) => visibility,
  marginLeft: -10,
});

const VolumeUpStyled = styled(VolumeUp)({
  fill: ({ fill }) => fill,
  visibility: ({ visibility }) => visibility,
  marginLeft: -5,
});

const CheckboxStyled = styled(Checkbox)({
  visibility: ({ visibility }) => visibility,
});

export const getRow = ({ style, data, index }) => {
  const {
    filteredNotifications,
    selectedNotifications,
    setSelectedNotifications,
    checkedNotifications,
    setCheckedNotifications,
    isItemLoaded,
    checkAllAt,
  } = data;

  if (!isItemLoaded(index)) {
    return (
      <div
        style={{
          ...style,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const notification = filteredNotifications[index];
  const alarmReasons = groupAlarmReasons(notification.alarmReasons);
  const isSelected = selectedNotifications.includes(notification.uniqueId);
  const checked = isChecked(notification, checkedNotifications, checkAllAt);

  return (
    <RowStyled
      key={notification.uniqueId}
      style={style}
      selected={isSelected}
      onClick={() => {
        if (isSelected) {
          setSelectedNotifications(selectedNotifications.filter((uniqueId) => uniqueId !== notification.uniqueId));
        } else {
          setSelectedNotifications([...selectedNotifications, notification.uniqueId]);
        }
      }}
      hover
    >
      <TableCell align="center">
        <Checkbox
          checked={checked}
          onChange={() => {
            if (checkedNotifications.includes(notification.uniqueId)) {
              setCheckedNotifications(checkedNotifications.filter((uniqueId) => uniqueId !== notification.uniqueId));
            } else {
              setCheckedNotifications([...checkedNotifications, notification.uniqueId]);
            }
          }}
          onClick={(e) => e.stopPropagation()}
          disabled={notification.isRead}
        />
      </TableCell>
      <TableCell align="center">
        <Box display="flex">
          <DeviceIcon iconId={notification.assetIconId} color={notification.assetColor} />
          <PriorityHighStyled
            fill={ALARM_COLORS[notification.alarmType]}
            visibility={notification.showAsPopup ? 'visible' : 'hidden'}
            data-testid="popupIcon"
          />
        </Box>
      </TableCell>
      <TableCell align="center">
        <Box display="flex">
          <WarningStyled
            fill={ALARM_COLORS[notification.alarmType]}
            visibility={notification.alarmStatus === NotificationStatuses.CLEARED ? 'hidden' : 'visible'}
            data-testid="alarmStatusIcon"
          />
          <VolumeUpStyled visibility={notification.isSoundEnabled ? 'visible' : 'hidden'} data-testid="soundIcon" />
        </Box>
      </TableCell>
      <TableCell>
        <LinesEllipsis maxLine={3} showTooltip text={notification.assetName} />
      </TableCell>
      <TableCell>
        <LinesEllipsis maxLine={3} showTooltip text={notification.alarmName} />
      </TableCell>
      <TableCell align="center">{T.translate(notification.alarmStatus)}</TableCell>
      <TableCell>
        <LinesEllipsis maxLine={3} showTooltip text={alarmReasons} />
      </TableCell>
      <TableCell>{new Date(notification.createdOn).toLocaleString()}</TableCell>
      <TableCell>
        <div>{notification.location.latitude}</div>
        <div>{notification.location.longitude}</div>
      </TableCell>
    </RowStyled>
  );
};

const NotificationsTable = ({
  filteredNotifications,
  selectedNotifications,
  setSelectedNotifications,
  checkedNotifications,
  setCheckedNotifications,
  checkAllAt,
  setCheckAllAt,
  currentTotal,
  loadMoreItems,
  hasNextPage,
  allChecked,
  isLoading,
  error,
  onReload,
}) => {
  const offSet = filteredNotifications.length;
  const itemCount = hasNextPage ? offSet + 1 : offSet;

  const isItemLoaded = useCallback((index) => !hasNextPage || index < offSet, [hasNextPage, offSet]);

  const itemData = useMemo(
    () => ({
      filteredNotifications,
      selectedNotifications,
      setSelectedNotifications,
      checkedNotifications,
      setCheckedNotifications,
      isItemLoaded,
      checkAllAt,
    }),
    [
      filteredNotifications,
      selectedNotifications,
      setSelectedNotifications,
      checkedNotifications,
      setCheckedNotifications,
      isItemLoaded,
      checkAllAt,
    ]
  );

  return (
    <DialogContentStyled>
      <HeaderRow>
        <TableCell align="center">
          <CheckboxStyled
            checked={allChecked}
            onClick={(e) => {
              e.stopPropagation();
              setCheckAllAt(allChecked ? null : new Date(filteredNotifications[0].createdOn));
              setCheckedNotifications([]);
            }}
            visibility={filteredNotifications.length && currentTotal ? 'visible' : 'hidden'}
          />
        </TableCell>
        <TableCell>&nbsp;</TableCell>
        <TableCell>&nbsp;</TableCell>
        <TableCell align="center">{T.translate(LocaleKeys.labels.asset).toUpperCase()}</TableCell>
        <TableCell align="center">{T.translate(LocaleKeys.labels.alarmName).toUpperCase()}</TableCell>
        <TableCell align="center">{T.translate(LocaleKeys.labels.assetStatus).toUpperCase()}</TableCell>
        <TableCell align="center">{T.translate(LocaleKeys.labels.trigger).toUpperCase()}</TableCell>
        <TableCell align="center">{T.translate(LocaleKeys.labels.date).toUpperCase()}</TableCell>
        <TableCell align="center">{T.translate(LocaleKeys.labels.location).toUpperCase()}</TableCell>
      </HeaderRow>
      <AutoSizer>
        {({ width, height }) => {
          if (isLoading) {
            return (
              <RowContainer width={width} height={height}>
                <CircularProgress />
              </RowContainer>
            );
          }

          if (error) {
            return (
              <RowContainer width={width} height={height}>
                <ErrorMessage
                  primaryMessage={T.translate(LocaleKeys.messages.errorWhileFetchingNotifications)}
                  secondaryMessage={T.translate(LocaleKeys.messages.clickRefreshToTryAgain)}
                  onRetry={onReload}
                />
              </RowContainer>
            );
          }

          return (
            <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems}>
              {({ onItemsRendered, ref }) => (
                <List
                  ref={ref}
                  width={width}
                  height={height - 55}
                  itemSize={70}
                  itemCount={itemCount}
                  itemData={itemData}
                  onItemsRendered={onItemsRendered}
                >
                  {getRow}
                </List>
              )}
            </InfiniteLoader>
          );
        }}
      </AutoSizer>
    </DialogContentStyled>
  );
};

NotificationsTable.propTypes = {
  onLoadMoreItems: PropTypes.func,
};

export default NotificationsTable;
