import { createSelector } from 'reselect';

export const getMapAreas = (state) => state.mapOverlays.mapAreas;
export const getIsLoadingMapAreas = (state) => state.mapOverlays.isLoading;
export const getMapAreasError = (state) => state.mapOverlays.mapAreasError;

const EMPTY_ARRAY = [];
const getHiddenAreas = (_, ownProps) => ownProps.hiddenAreas || EMPTY_ARRAY;

export const makeGetVisibleMapAreasSelector = () => {
  return createSelector([getMapAreas, getHiddenAreas], (mapAreas, hiddenAreas) =>
    mapAreas?.filter((area) => !hiddenAreas.includes(area.id))
  );
};
