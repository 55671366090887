import React, { useState } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import {
  Box,
  styled,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Divider,
  CircularProgress,
  Typography,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import { Search } from '@material-ui/icons';

const ListContainer = styled('div')({
  height: '100%',
  width: ({ width }) => width,
  margin: 10,
  marginTop: 0,
});

const getRow = ({ style, data, index }) => {
  const { items, handleToggle, checked, readOnly, displayField, valueField } = data;
  const item = items[index];

  return (
    <ListItem
      key={item[valueField]}
      style={style}
      dense
      button
      onClick={() => handleToggle(item[valueField])}
      disabled={readOnly}
    >
      <ListItemIcon>
        <Checkbox edge="start" checked={checked?.includes(item[valueField])} tabIndex={-1} disableRipple />
      </ListItemIcon>
      <ListItemText primary={item[displayField]} />
    </ListItem>
  );
};

const stringContain = (searchedIn, query) => searchedIn && searchedIn.toLowerCase().includes(query);

const ListInput = ({
  items,
  selected,
  disabled,
  displayField,
  valueField,
  label,
  onChange,
  renderCustomRow,
  onCustomRowClick,
  customListItemDataFields,
  minSelected,
  showSelectAll,
  width,
}) => {
  const [query, setQuery] = useState('');
  const queryTrimmed = query.trim().toLowerCase();

  const toggleAll = () => {
    if (items.length === selected.length) {
      onChange([]);
    } else {
      onChange(items.map((item) => item[valueField]));
    }
  };

  const handleToggle = (id) => {
    const newSelected = [...selected];
    const currentIndex = newSelected.indexOf(id);

    if (currentIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    onChange(newSelected);
  };

  if (selected && items) {
    const filteredItems = items
      .filter((row) => stringContain(row[displayField], queryTrimmed))
      .sort((a, b) => a[displayField].localeCompare(b[displayField]));

    return (
      <>
        <Box display={'flex'} alignItems={'center'} margin={'10px'} width={width} justifyContent={'space-between'}>
          <Typography>{label}</Typography>
          <TextField
            variant="outlined"
            placeholder={T.translate(LocaleKeys.labels.search)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
        </Box>
        {showSelectAll && (
          <Box width={width} margin={'10px'} marginTop={'0px'}>
            <ListItem dense button onClick={toggleAll} disabled={disabled}>
              <ListItemIcon>
                <Checkbox edge="start" checked={selected?.length === items?.length} tabIndex={-1} disableRipple />
              </ListItemIcon>
              <ListItemText
                primary={T.translate(LocaleKeys.labels.selectAll)}
                secondary={`${selected.length} ${T.translate('itemsSelected')}`}
                secondaryTypographyProps={{
                  color: selected.length < minSelected ? 'error' : 'initial',
                }}
              />
            </ListItem>
            <Divider />
          </Box>
        )}
        <ListContainer width={width}>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <List
                  width={width}
                  height={showSelectAll ? height - 150 : height - 80}
                  itemSize={40}
                  itemCount={filteredItems?.length}
                  itemData={{
                    ...customListItemDataFields,
                    items: filteredItems,
                    checked: selected,
                    readOnly: disabled,
                    handleToggle,
                    onCustomRowClick,
                    displayField,
                    valueField,
                  }}
                >
                  {renderCustomRow || getRow}
                </List>
              );
            }}
          </AutoSizer>
        </ListContainer>
      </>
    );
  }

  return <CircularProgress />;
};

ListInput.defaultProps = {
  disabled: false,
  renderCustomRow: null,
  onCustomRowClick: () => {},
  customListItemDataFields: {},
  minSelected: 1,
  showSelectAll: true,
  width: undefined, // css default: 'auto'
};

ListInput.propTypes = {
  items: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  displayField: PropTypes.string.isRequired,
  valueField: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  renderCustomRow: PropTypes.func,
  onCustomRowClick: PropTypes.func,
  customListItemDataFields: PropTypes.object, // expands object and pass fields to renderCustomRow in 'data' prop
  minSelected: PropTypes.number,
  showSelectAll: PropTypes.bool,
  width: PropTypes.string, // if width not passed list will fill surrounding container width
};

export default ListInput;
