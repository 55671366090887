import React, { useState } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import Dropdown from 'Components/inputs/Dropdown';
import LocaleKeys from 'Localization/LocaleKeys';
import { LocalStorageKeys } from 'Constants/LocalStorageKeys';
import { useLocation, useHistory } from 'react-router';
import { translatePath } from 'routes/paths';

const SwitchUserLanguage = ({ open, closeDialog }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem(LocalStorageKeys.USER_LANGUAGE));
  const location = useLocation();
  const history = useHistory();

  const changeLanguage = (field) => {
    const prevLanguage = selectedLanguage;
    const language = field.target.value;

    setSelectedLanguage(language);
    localStorage.setItem(LocalStorageKeys.USER_LANGUAGE, language);

    history.replace(translatePath(location.pathname, language, prevLanguage));
    global.location.reload();
  };

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth={'sm'}>
      <DialogTitle>{T.translate('selectLanguage')}</DialogTitle>
      <DialogContent>
        <Dropdown
          data={[
            { name: 'English', code: 'en' },
            { name: 'German', code: 'de' },
          ]}
          displayField="name"
          fullWidth
          label={T.translate(LocaleKeys.labels.language)}
          onChange={changeLanguage}
          value={selectedLanguage}
          valueField="code"
          withoutEmpty
          translateLabel
        />
      </DialogContent>
    </Dialog>
  );
};

SwitchUserLanguage.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default SwitchUserLanguage;
