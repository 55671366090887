import * as Yup from 'yup';
import { areOverlapping, isDateCorrect, isAlphanumericOrSpecialSymbol } from 'Validators/SharedValidators';
import { getCalendarExistsByName } from 'Api/sites';

let lastValue;
let initialValue;

export const calendarNameExist = async (value, id) => {
  if (isAlphanumericOrSpecialSymbol([' '])(value)) {
    const result = lastValue?.value === value ? lastValue?.result : await getCalendarExistsByName(value);

    if (id) {
      if (!initialValue || id !== initialValue?.id) {
        initialValue = { value, id };
      }
    } else {
      initialValue = undefined;
    }

    lastValue = { value, result };

    return initialValue?.value === value || !result?.data;
  }

  return false;
};

function isTimeIntervalCorrect() {
  const item = this.parent;

  if (!isDateCorrect(item.from) || !isDateCorrect(item.to)) {
    return false;
  } else {
    return +new Date(item.from) < +new Date(item.to);
  }
}

function areTimeIntervalsOverlapping(value) {
  let result = true;
  const array = this.parent;
  const currentIndex = array.indexOf(value);

  if (!isDateCorrect(value.from) || !isDateCorrect(value.to)) {
    result = false;
  } else {
    result = !areOverlapping(new Date(value.from), new Date(value.to), array, currentIndex);
  }

  return result || this.createError({ path: `${this.path}.from` });
}

const workdaySchema = Yup.object({
  operationDay: Yup.object({
    timeEntries: Yup.array().of(
      Yup.object()
        .shape({
          from: Yup.string().nullable().required('thisFieldIsRequired'),
          to: Yup.string()
            .required('thisFieldIsRequired')
            .nullable()
            .test('isTimeIntervalCorrect', 'toShouldBeGreaterThanFrom', isTimeIntervalCorrect),
        })
        .test('areTimeIntervalsOverlapping', 'timesCannotOverlap', areTimeIntervalsOverlapping)
    ),
  }),
});

const CalendarTemplatesValidation = Yup.object().shape({
  name: Yup.string()
    .required('thisFieldIsRequired')
    .max(50, 'nameCannotExceed50Characters')
    .alphanumericOrSpecialSymbol([' '])
    .test('calendarNameExist', 'calendarWithThisNameAlreadyExists', function (value) {
      return calendarNameExist(value, this.parent.calendarId);
    }),
  description: Yup.string().max(200, 'nameCannotExceed200Characters'),
  timeZone: Yup.mixed().required('thisFieldIsRequired'),
  workdays: Yup.object()
    .shape({
      Monday: workdaySchema,
      Tuesday: workdaySchema,
      Wednesday: workdaySchema,
      Thursday: workdaySchema,
      Friday: workdaySchema,
      Saturday: workdaySchema,
      Sunday: workdaySchema,
    })
    .nullable(),
  specialEntries: Yup.array().of(
    Yup.object()
      .shape({
        from: Yup.string().nullable().required('thisFieldIsRequired'),
        to: Yup.string()
          .nullable()
          .required('thisFieldIsRequired')
          .test('isTimeIntervalCorrect', 'toShouldBeGreaterThanFrom', isTimeIntervalCorrect),
      })
      .test('areTimeIntervalsOverlapping', 'timesCannotOverlap', areTimeIntervalsOverlapping)
  ),
  vacations: Yup.array().of(
    Yup.object()
      .shape({
        from: Yup.string().nullable().required('thisFieldIsRequired'),
        to: Yup.string()
          .nullable()
          .required('thisFieldIsRequired')
          .test('isTimeIntervalCorrect', 'toShouldBeGreaterThanOrEqualToFrom', isTimeIntervalCorrect),
      })
      .test('areTimeIntervalsOverlapping', 'timesCannotOverlap', areTimeIntervalsOverlapping)
  ),
});

export default CalendarTemplatesValidation;
