import React from 'react';
import ReactDOM from 'react-dom';
import 'Validators/yupExtensions';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { serviceWorkerConfig } from 'serviceWorkerConfig';

import './styles/index.scss';

// if (process.env.NODE_ENV !== 'development') {
//  Sentry.init({
//    dsn: process.env.REACT_APP_SENTRY_DSN,
//    release: process.env.REACT_APP_VERSION.split('-')[0],
//    environment: window.location.host,
//  });
// }

if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');

  whyDidYouRender(React, {
    // trackAllPureComponents: true,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(serviceWorkerConfig);
