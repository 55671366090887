import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import DropdownRaw from 'Components/inputs/Dropdown';
import { withField, withFormik } from 'Components/formik/formikWrappers';
import FormikCollection from 'Components/inputs/CollectionManager/FormikCollection';
import { styled } from '@material-ui/core';
import { TextField as TextFieldRaw } from 'Components/formik/fields';

const TextField = styled(TextFieldRaw)({
  minWidth: 300,
  margin: 2,
});
const DropdownStyled = styled(DropdownRaw)({
  minWidth: 300,
  margin: 2,
});
const DropdownWithHiddenOptions = ({ data, hiddenOptions, ...props }) => {
  const options = data?.filter((item) => item === props.value || !hiddenOptions.includes(item));

  return <DropdownStyled {...props} data={options} />;
};
const DropdownWithHiddenOptionsField = withField(DropdownWithHiddenOptions);

const AnalogInputMappings = ({ data, dictionaries, analogInputsEnabled }) => {
  const usedInputValues = data.analogInputMappings?.map((input) => input.inputValue) || [];
  const analogInputsData = dictionaries.analogInputs;

  return (
    <FormikCollection
      label={T.translate(LocaleKeys.labels.analogInputMapping)}
      name={'analogInputMappings'}
      disableAddItem={!analogInputsEnabled || analogInputsData.length <= usedInputValues.length}
    >
      <TextField label={T.translate(LocaleKeys.labels.analogInputName)} name="name" fullWidth />
      <DropdownWithHiddenOptionsField
        label={T.translate(LocaleKeys.labels.analogInputValue)}
        name="inputValue"
        data={dictionaries.analogInputs}
        hiddenOptions={usedInputValues}
        translateLabel
        withoutEmpty
        fullWidth
      />
      <DropdownStyled
        name="variable"
        label={T.translate(LocaleKeys.labels.variablesList)}
        data={dictionaries.metricRelatedVariables}
        displayField="name"
        valueField="value"
        withoutEmpty
        fullWidth
        translateLabel={true}
        itemsDisabled
      />
    </FormikCollection>
  );
};

AnalogInputMappings.propTypes = {
  data: PropTypes.object,
  dictionaries: PropTypes.object,
  analogInputsEnabled: PropTypes.bool,
};

export default withFormik(AnalogInputMappings);
