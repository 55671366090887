import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { IconButton, styled } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import TooltipsLocaleKeys from 'Localization/TooltipsLocaleKeys';
import DeviceContext from 'app/pages/Dashboard/DeviceContext';

const ControlBar = styled('div')({
  backgroundClip: 'content-box',
  backgroundColor: 'white',
  border: '2px solid rgba(0,0,0,0.2)',
  borderRadius: '4px',
  visibility: ({ visibility }) => visibility,
});
const Control = styled(IconButton)({
  height: '30px',
  width: '30px',
  padding: '0',
  borderRadius: '0',
});

const UnselectAssets = (props) => {
  const { selectedAssetIds, clearSelectedAssetsIds } = useContext(DeviceContext);

  return (
    <ControlBar style={props.style} visibility={selectedAssetIds.length ? 'visible' : 'hidden'}>
      <Control
        onClick={(e) => {
          e.stopPropagation();
          clearSelectedAssetsIds();
        }}
        title={T.translate(TooltipsLocaleKeys.deselectAll)}
      >
        <Delete />
      </Control>
    </ControlBar>
  );
};

UnselectAssets.propTypes = {
  style: PropTypes.object,
};

export default UnselectAssets;
