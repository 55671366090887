import React, { useEffect, useState } from 'react';
import {
  getAlarms,
  deleteAlarm,
  getAlarmTypes,
  getAlarmStatusTypes,
  getValidityTypes,
  getDevicesBasicInformation,
} from 'Api/devices';
import TableApi from 'Components/display/TableApi/TableApi';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import { NewStatusAndAlarmDefinitions, StatusAndAlarmDefinitionsDetails } from 'routes/Routes';
import T from 'i18n-react';
import LabelsLocaleKeys from 'Localization/LabelsLocaleKeys';
import Loader from 'Components/Loader';
import { operators } from 'Api/utils';

const StatusAndAlarmDefinitions = ({ permissions }) => {
  const [dictionaries, setDictionaries] = useState();

  useEffect(() => {
    Promise.all([getAlarmTypes(), getAlarmStatusTypes(), getValidityTypes(), getDevicesBasicInformation()]).then(
      ([{ data: alarmTypes }, { data: alarmStatuses }, { data: validityTypes }, { data: assets }]) => {
        setDictionaries({
          alarmTypes,
          alarmStatuses,
          validityTypes,
          assets,
        });
      }
    );
  }, []);

  if (!dictionaries) {
    return <Loader />;
  }

  const COLUMNS = {
    name: {},
    'alarmType.name': {
      name: T.translate('alarmType'),
      format: (row) => T.translate(row.alarmType.name),
      filterOptions: dictionaries.alarmTypes.map(({ name }) => ({ label: T.translate(name), value: name })),
      filterOperator: operators.EQUALS,
    },
    'status.name': {
      name: T.translate('status'),
      format: (row) => T.translate(row.status.name),
      filterOptions: dictionaries.alarmStatuses.map(({ name }) => ({ label: T.translate(name), value: name })),
      filterOperator: operators.EQUALS,
    },
    'validityType.name': {
      name: T.translate('validityType'),
      format: (row) => T.translate(row.validityType.name),
      filterOptions: dictionaries.validityTypes.map(({ name }) => ({ label: T.translate(name), value: name })),
      filterOperator: operators.EQUALS,
    },
    showPopup: {
      disableFilter: true,
      format: (row) => T.translate(row.showPopup ? LabelsLocaleKeys.yes : LabelsLocaleKeys.no),
    },
    assetId: {
      name: T.translate('assignedAssets'),
      // disableSort: true,
      format: (row) => row.assignedAssets.map((asset) => asset.name).join(', '),
      filterOptions: dictionaries.assets.map(({ name, externalId }) => ({ label: name, value: externalId })),
      filterOperator: operators.EQUALS,
    },
  };

  return (
    <TableApi
      client={getAlarms}
      columns={COLUMNS}
      canAddNew={permissions[Permissions.CanAddAlarm]}
      newUrl={NewStatusAndAlarmDefinitions.getUrl()}
      buttons={{
        edit: permissions[Permissions.CanEditAlarm],
        delete: permissions[Permissions.CanRemoveAlarm],
      }}
      getEditUrl={StatusAndAlarmDefinitionsDetails.getUrl}
      deleteRequest={deleteAlarm}
      itemName="Alarm"
    />
  );
};

export default withPermissions([Permissions.CanReadAlarms, Permissions.CanReadSites, Permissions.CanReadDevices])(
  StatusAndAlarmDefinitions
);
