import React from 'react';
import PropTypes from 'prop-types';
import LocaleKeys from 'Localization/LocaleKeys';
import { withFormik } from 'Components/formik/formikWrappers';
import ExpansionSection from 'Components/display/ExpansionPanel/ExpansionSection';
import Other from './other/Other';
import HttpFeeds from './other/HttpFeeds';

const OtherConfiguration = (props) => (
  <ExpansionSection data={props.data} onChange={props.onChange}>
    <HttpFeeds label={LocaleKeys.labels.httpFeed} />
    <Other label={LocaleKeys.labels.other} />
  </ExpansionSection>
);

OtherConfiguration.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
};

export default withFormik(OtherConfiguration);
