import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListInput from './ListInput';

const ListInputWithApiCall = ({ apiCall, ...props }) => {
  const [items, setItems] = useState(null);
  const { displayField, sortByDisplayField } = props;

  useEffect(() => {
    apiCall().then((response) => {
      const data =
        sortByDisplayField && displayField && response.data.length && typeof response.data[0][displayField] === 'string'
          ? response.data.sort((a, b) =>
              a[displayField].localeCompare(b[displayField], undefined, { sensitivity: 'base' })
            )
          : response.data;

      setItems(data);
    });
  }, [apiCall, displayField, sortByDisplayField]);

  return items ? <ListInput items={items} {...props} /> : <CircularProgress />;
};

ListInputWithApiCall.propTypes = {
  apiCall: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  displayField: PropTypes.string.isRequired,
  sortByDisplayField: PropTypes.bool,
  valueField: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

ListInputWithApiCall.defaultProps = {
  sortByDisplayField: false,
  readOnly: false,
  disabled: false,
};

export default ListInputWithApiCall;
