import ApiClient from './ApiClient';

const apiClient = new ApiClient();
const SERVICE_PATH = '/devicecontrol/api/v1/';

const post = (path, data) => apiClient.post(SERVICE_PATH + path, data);

// Commands
export const postCommand = (command) => post(`commands`, command);
export const postCommandRefresh = (assetIds) =>
  post(`commands/refresh`, Array.isArray(assetIds) ? assetIds : [assetIds]);
