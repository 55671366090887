import React, { useState, useMemo, useCallback } from 'react';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import Checkbox from 'Components/inputs/Checkbox';
import { PermissionGroups } from 'Constants/PermissionGroups';
import {
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Box,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { useEnhancedFormContext, withFormik } from 'Components/formik/formikWrappers';

const StyledCell = styled(TableCell)({
  textTransform: 'uppercase',
});

const StyledCellSortable = styled(StyledCell)({
  cursor: 'pointer',
  userSelect: 'none',
});

const createGroups = () => {
  const groups = [];

  Object.keys(PermissionGroups).forEach((key) => {
    groups.push({
      name: T.translate(key),
      permissions: PermissionGroups[key].map((code) => ({
        code,
        name: T.translate(code),
        description: T.translate(`${code}_Description`),
      })),
    });
  });

  return groups;
};

const Privileges = (props) => {
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState('asc');
  const [permissionGroups] = useState(createGroups());
  const { canSaveChanges } = useEnhancedFormContext();
  const isReadOnly = !canSaveChanges;

  const handlePermissionValueChange = (permission, event) => {
    const checkboxValue = event.target.value;

    let newPermissions = [...props.data.roleInfo.permissions];
    let permToChange = newPermissions.find((perm) => perm.code === permission.code);

    if (!permToChange) {
      permToChange = { code: permission.code };
      newPermissions.push(permToChange);
    }
    permToChange.value = checkboxValue.toString().toLowerCase();
    newPermissions = newPermissions.filter((perm) => perm.value !== 'false');

    props.setFieldValue('roleInfo.permissions', newPermissions);
  };

  const getUserValueForPermision = (permission) => {
    const { roleInfo } = props.data;

    const userPerm = roleInfo.permissions.find((perm) => permission.code === perm.code);
    let result = false;

    if (userPerm) {
      result = (userPerm.value && userPerm.value.toLowerCase() === 'true') || false;
    }

    return result;
  };

  const getFilteredAndSortedGroupsCallback = useCallback(
    (generatedGroups) => {
      const filterdGroups =
        search !== ''
          ? generatedGroups.filter(
              (group) =>
                group.name?.toLowerCase()?.includes(search) ||
                group.permissions.find((permission) => permission.name.toLowerCase()?.includes(search))
            )
          : generatedGroups;

      return order === 'asc'
        ? filterdGroups.sort((a, b) => (a.name > b.name ? 1 : -1))
        : filterdGroups.sort((a, b) => (a.name < b.name ? 1 : -1));
    },
    [search, order]
  );

  const filteredAndSortedGroups = useMemo(
    () => getFilteredAndSortedGroupsCallback(permissionGroups),
    [permissionGroups, getFilteredAndSortedGroupsCallback]
  );

  return (
    <Box width={'100%'}>
      <TextField
        name="search"
        label={T.translate(LocaleKeys.labels.search)}
        variant="outlined"
        placeholder={T.translate(LocaleKeys.labels.search)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        style={{ width: '400px' }}
        onChange={(e) => setSearch(e.target.value?.toLowerCase())}
      />
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledCellSortable onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}>
                {T.translate(LocaleKeys.labels.name)}
              </StyledCellSortable>
              <StyledCell>{T.translate(LocaleKeys.labels.permissions)}</StyledCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAndSortedGroups.map((permissionGroup) => (
              <TableRow key={permissionGroup.name}>
                <TableCell>{permissionGroup.name}</TableCell>
                <TableCell>
                  {permissionGroup.permissions?.map(
                    (permission) =>
                      permission.name.toLowerCase().includes(search.toLowerCase()) && (
                        <Box key={permission.code} display="flex" alignItems="center">
                          <Checkbox
                            value={getUserValueForPermision(permission)}
                            onChange={(event) => handlePermissionValueChange(permission, event)}
                            name={permission.code}
                            readOnly={isReadOnly}
                          />
                          <div>
                            <strong>{permission.name}</strong> ({permission.description})
                          </div>
                        </Box>
                      )
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default withFormik(Privileges);
