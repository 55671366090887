const SupportedLanguages = {
  names: {
    en: 'English',
    de: 'German',
  },
  codes: {
    en: 'en',
    de: 'de',
  },
};

export default SupportedLanguages;
