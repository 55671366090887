import '../draw/handler/Draw.IconMarker';
import '../draw/handler/Draw.TextMarker';
import '../draw/handler/Draw.HandDrawnPolygon';
import '../draw/handler/Draw.HandDrawnPolyline';

L.DrawToolbar.include({
  options: {
    iconMarker: {},
    textMarker: {},
    polyline: {},
    handDrawPolyline: {},
    polygon: {},
    handDrawnPolygon: {},
    rectangle: {},
    circle: {},
    marker: {},
    circlemarker: {},
  },

  // @method getModeHandlers(): object
  // Get mode handlers information
  getModeHandlers: function (map) {
    return [
      {
        enabled: this.options.iconMarker,
        handler: new L.Draw.IconMarker(map, this.options.iconMarker),
        title: L.drawLocal.draw.toolbar.buttons.iconMarker,
      },
      {
        enabled: this.options.textMarker,
        handler: new L.Draw.TextMarker(map, this.options.textMarker),
        title: L.drawLocal.draw.toolbar.buttons.textMarker,
      },
      {
        enabled: this.options.polyline,
        handler: new L.Draw.Polyline(map, this.options.polyline),
        title: L.drawLocal.draw.toolbar.buttons.polyline,
      },
      {
        enabled: this.options.handDrawPolyline,
        handler: new L.Draw.HandDrawnPolyline(map, this.options.handDrawPolyline),
        title: L.drawLocal.draw.toolbar.buttons.polyline,
      },
      {
        enabled: this.options.polygon,
        handler: new L.Draw.Polygon(map, this.options.polygon),
        title: L.drawLocal.draw.toolbar.buttons.polygon,
      },
      {
        enabled: this.options.handDrawnPolygon,
        handler: new L.Draw.HandDrawnPolygon(map, this.options.handDrawnPolygon),
        title: L.drawLocal.draw.toolbar.buttons.polygon,
      },
      {
        enabled: this.options.rectangle,
        handler: new L.Draw.Rectangle(map, this.options.rectangle),
        title: L.drawLocal.draw.toolbar.buttons.rectangle,
      },
      {
        enabled: this.options.circle,
        handler: new L.Draw.Circle(map, this.options.circle),
        title: L.drawLocal.draw.toolbar.buttons.circle,
      },
      {
        enabled: this.options.marker,
        handler: new L.Draw.Marker(map, this.options.marker),
        title: L.drawLocal.draw.toolbar.buttons.marker,
      },
      {
        enabled: this.options.circlemarker,
        handler: new L.Draw.CircleMarker(map, this.options.circlemarker),
        title: L.drawLocal.draw.toolbar.buttons.circlemarker,
      },
    ];
  },
});
