import * as Sentry from '@sentry/react';
import { getLoggedUserPermission } from 'Api/policyServer';

let cachedPermission = null;

class PermissionManager {
  constructor() {
    if (!PermissionManager.instance) {
      PermissionManager.instance = this;
    }

    return PermissionManager.instance;
  }

  hasPermission = async (permissionCode) => {
    if (!permissionCode) {
      return true;
    }
    const { data } = await getLoggedUserPermission();

    cachedPermission = data;
    const perm = data.permissions.find((x) => x.code === permissionCode);

    return !!(perm && perm.value === 'true');
  };

  hasPermissionFromCache = (permissionCode) => {
    if (cachedPermission === null) {
      Sentry.captureMessage('permission cache is null');

      return false;
    }
    const perm = cachedPermission.permissions.find((x) => x.code === permissionCode);

    return perm && perm.value === 'true';
  };

  refreshPermission = async () => {
    const { data } = await getLoggedUserPermission();

    cachedPermission = data;

    return data;
  };
}

const instance = new PermissionManager();

Object.freeze(instance);

export default instance;
