const ValidationLocaleKeys = {
  valueMustBePositiveNumber: 'valueMustBePositiveNumber',
  valueMustBeGreaterThanOrEqualTo5: 'valueMustBeGreaterThanOrEqualTo5',
  valueMustBeNumber: 'valueMustBeNumber',
  beforeShouldBeSmallerThanTo: 'beforeShouldBeSmallerThanTo',
  nameCannotExceed17Characters: 'nameCannotExceed17Characters',
  nameCannotExceed20Characters: 'nameCannotExceed20Characters',
  nameCannotExceed25Characters: 'nameCannotExceed25Characters',
  nameCannotExceed40Characters: 'nameCannotExceed40Characters',
  nameCannotExceed50Characters: 'nameCannotExceed50Characters',
  nameCannotExceed200Characters: 'nameCannotExceed200Characters',
  nameCannotExceed500Characters: 'nameCannotExceed500Characters',
  thisFieldIsRequired: 'thisFieldIsRequired',
  imeiNotValidLength: 'imeiNotValidLength',
  nameShouldBeAlphanumeric: 'nameShouldBeAlphanumeric',
  nameShouldBeAlphanumericNoSpaceAllowed: 'nameShouldBeAlphanumericNoSpaceAllowed',
  imeiNotValidFormat: 'imeiNotValidFormat',
  toShouldBeGreaterThanBefore: 'toShouldBeGreaterThanBefore',
  toShouldBeGreaterThanFrom: 'toShouldBeGreaterThanFrom',
  activeToShouldBeGreaterThanStartFrom: 'activeToShouldBeGreaterThanStartFrom',
  toShouldBeGreaterThanOrEqualToFrom: 'toShouldBeGreaterThanOrEqualToFrom',
  afterShouldBeGreaterThanOrEqualToTo: 'afterShouldBeGreaterThanOrEqualToTo',
  keywordStringCannotBeEmpty: 'keywordStringCannotBeEmpty',
  typeCannotBeEmpty: 'typeCannotBeEmpty',
  selecteAtLeastOne: 'selecteAtLeastOne',
  incorrectEmailAddress: 'incorrectEmailAddress',
  emailAddressIsRequired: 'emailAddressIsRequired',
  valueMustBeUniqueInCollection: 'valueMustBeUniqueInCollection',
  pleaseSelectDriver: 'pleaseSelectDriver',
  timesCannotOverlap: 'timesCannotOverlap',
  timeIntervalMustBeGreaterThanAnHour: 'timeIntervalMustBeGreaterThanAnHour',
  siteWithThisNameAlreadyExists: 'siteWithThisNameAlreadyExists',
  siteGroupWithThisNameAlreadyExists: 'siteGroupWithThisNameAlreadyExists',
  deviceWithThisNameAlreadyExists: 'deviceWithThisNameAlreadyExists',
  alarmWithThisNameAlreadyExists: 'alarmWithThisNameAlreadyExists',
  categoryWithThisNameAlreadyExists: 'categoryWithThisNameAlreadyExists',
  userWithThisLoginAlreadyExists: 'userWithThisLoginAlreadyExists',
  emailIsAlreadyInUse: 'emailIsAlreadyInUse',
  setDeviceDefaultLocalizationOnMap: 'setDeviceDefaultLocalizationOnMap',
  timeConditionRequiresOtherConditions: 'timeConditionRequiresOtherConditions',
  deviceWithThisImeiAlreadyExists: 'deviceWithThisImeiAlreadyExists',
  deviceWithThisCodeAlreadyExists: 'deviceWithThisCodeAlreadyExists',
  devicesGroupWithThisNameAlreadyExists: 'devicesGroupWithThisNameAlreadyExists',
  libraryWithThisNameAlreadyExists: 'libraryWithThisNameAlreadyExists',
  calendarWithThisNameAlreadyExists: 'calendarWithThisNameAlreadyExists',
  holidayWithThisNameAlreadyExists: 'holidayWithThisNameAlreadyExists',
  mapAreaWithThisNameAlreadyExists: 'mapAreaWithThisNameAlreadyExists',
  roleWithThisNameAlreadyExists: 'roleWithThisNameAlreadyExists',
  singleTimePeriodInConditionsAllowed: 'singleTimePeriodInConditionsAllowed',
  minTextLength: 'minTextLength',
  reasonCommentIsRequired: 'reasonCommentIsRequired',
  urlMustBeInserted: 'urlMustBeInserted',
  incorrectLink: 'incorrectLink',
  mustBeIpv4Format: 'mustBeIpv4Format',
  mustBeIpv4RangeFormat: 'mustBeIpv4RangeFormat',
  mustBeGreaterThanStartIpRange: 'mustBeGreaterThanStartIpRange',
  onlyIntegersAreAllowed: 'onlyIntegersAreAllowed',
  onlyFutureDatesAreAllowed: 'onlyFutureDatesAreAllowed',
  reminderShouldBeSmallerOrEqualThanChangeDuration: 'reminderShouldBeSmallerOrEqualThanChangeDuration',
  deviceIdOrImeiIsRequired: 'deviceIdOrImeiIsRequired',
  mapAreaDoesNotContainCorrectElements: 'mapAreaDoesNotContainCorrectElements',
  incorrectPhoneNumber: 'incorrectPhoneNumber',
  minPhoneNumberLength: 'minPhoneNumberLength',
  valueMustBeInteger: 'valueMustBeInteger',
  longerThanSumOfMinCharacters: 'longerThanSumOfMinCharacters',
  dateToCannotBeEarlierThanDateFrom: 'dateToCannotBeEarlierThanDateFrom',
  invalidDate: 'invalidDate',
  invalidValue: 'invalidValue',
  thisFieldMustHaveAtLeast1Item: 'thisFieldMustHaveAtLeast1Item',
  commandsFieldMustHaveAtLeast1Item: 'commandsFieldMustHaveAtLeast1Item',
};

export default ValidationLocaleKeys;
