import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Fab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  fab: {
    width: '36px',
    height: '36px',
  },
});

const TableActionButton = (props) => {
  return (
    <Tooltip title={props.tooltipText}>
      {/* this div is added because we need to display tooltips for disabled icons */}
      <div>
        <Fab
          className={props.classes.fab}
          onClick={props.onClick}
          disabled={!!props.disabled}
          aria-label={props['aria-label']}
        >
          {props.icon}
        </Fab>
      </div>
    </Tooltip>
  );
};

TableActionButton.propTypes = {
  onClick: PropTypes.func,
  tooltipText: PropTypes.string,
  icon: PropTypes.object,
};

export default withStyles(styles)(TableActionButton);
