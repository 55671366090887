import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import LocaleKeys from 'Localization/LocaleKeys';
import PropTypes from 'prop-types';
import React from 'react';
import T from 'i18n-react';
import { withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { withFormik } from 'Components/formik/formikWrappers';
import { Checkbox, TextField } from 'Components/formik/fields';

const styles = (theme) => ({
  textField: {
    minWidth: '70%',
  },
});

const CommonInfo = ({ data, classes }) => {
  return (
    <ColumnDisplayer>
      <TextField className={classes.textField} label={T.translate(LocaleKeys.labels.name)} name="name" showPending />
      <TextField className={classes.textField} label={T.translate(LocaleKeys.labels.externalId)} name="id" disabled />
      <Checkbox color="primary" name="isEnabled" label={T.translate(LocaleKeys.labels.active)} />
    </ColumnDisplayer>
  );
};

CommonInfo.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  onChange: PropTypes.func,
};

export default compose(withStyles(styles), withFormik)(CommonInfo);
