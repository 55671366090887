import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { compose } from 'redux';
import { styled, Paper, Box } from '@material-ui/core';
import Button from 'Components/Button';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import NotificationPreview from 'Components/display/notification/NotificationPreview';
import { getSites, putNotification, getNotificationByExternalId } from 'Api/sites';
import { SiteNotifications } from 'routes/Routes';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import SaveOnLeave from 'Components/dialogs/SaveOnLeave';
import ListInputWithApiCall from 'Components/inputs/ListInputWithApiCall';
import Loader from 'Components/Loader';
import { FormikEnhanced } from 'Components/formik/formikWrappers';
import { Checkbox, DateTimeInput, TextField } from 'Components/formik/fields';
import SiteNotificationsValidation from './SiteNotificationsValidation';

const ButtonStyled = styled(Button)({
  float: 'right',
  marginLeft: '10px',
});

const Container = styled('div')({
  height: 'calc(100vh - 170px)',
  overflow: 'hidden',
  margin: 10,
});

const BoxStyled = styled(Box)({
  width: '25%',
  margin: '10px 4% !important',
  display: 'flex',
});

const Details = (props) => {
  const [notification, setNotification] = useState();
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    const { externalId } = props.match.params;

    if (externalId) {
      getNotificationByExternalId(externalId).then((response) => {
        setNotification(response.data);
      });
    } else {
      const url = SiteNotifications.getUrl();

      props.history.push(url);
    }
  }, [props.history, props.match.params]);

  const onSubmit = (values, { setSubmitting }) => {
    putNotification(values.notificationId, values).then(() => {
      setSubmitting(false);
      onCancel();
    });
  };

  const onCancel = () => {
    const url = SiteNotifications.getUrl();

    props.history.push(url);
  };

  if (!notification) {
    return <Loader />;
  }

  const canSaveChanges =
    !!props.permissions[Permissions.CanEditSiteNotifications] && +new Date(notification.startFrom) >= Date.now();

  return (
    <FormikEnhanced
      initialValues={notification}
      enableReinitialize
      validationSchema={SiteNotificationsValidation}
      validateOnMount={canSaveChanges}
      validateOnBlur={canSaveChanges}
      validateOnChange={canSaveChanges}
      onSubmit={onSubmit}
      canSaveChanges={canSaveChanges}
    >
      {({ values, dirty, isValid, isSubmitting, handleSubmit, handleChange }) => (
        <>
          <NotificationPreview
            open={showPreview}
            onClose={() => setShowPreview(false)}
            content={values.content}
            link={values.content2}
            startDate={values.startFrom}
          />
          <form onSubmit={handleSubmit}>
            <Paper>
              <Box display="flex" flexWrap="nowrap">
                <Box width={'75%'}>
                  <Box display="flex">
                    <BoxStyled>
                      <TextField
                        label={T.translate(LocaleKeys.labels.content)}
                        name="content"
                        multiline
                        rows="4"
                        fullWidth
                      />
                    </BoxStyled>
                    <BoxStyled>
                      <TextField label={T.translate(LocaleKeys.labels.linkToMoreContent)} name="content2" fullWidth />
                    </BoxStyled>
                  </Box>
                  <ColumnDisplayer>
                    <DateTimeInput
                      label={T.translate(LocaleKeys.labels.startFrom)}
                      type="datetime"
                      name="startFrom"
                      format="HH:mm dd.MM.yyyy"
                    />
                    {!values.displayAlwaysOnFeed && (
                      <DateTimeInput
                        label={T.translate(LocaleKeys.labels.activeTo)}
                        type="datetime"
                        name="activeTo"
                        disabled={values.displayAlwaysOnFeed}
                        format="HH:mm dd.MM.yyyy"
                      />
                    )}
                    <Checkbox
                      color="primary"
                      name="displayAlwaysOnFeed"
                      label={T.translate(LocaleKeys.labels.displayAlwaysOnFeed)}
                    />
                  </ColumnDisplayer>
                </Box>

                <Box>
                  <Container>
                    <ListInputWithApiCall
                      name="sites"
                      label={T.translate(LocaleKeys.labels.sites).toUpperCase()}
                      apiCall={getSites}
                      selected={values.sites}
                      onChange={(value) => handleChange({ target: { value, name: 'sites' } })}
                      displayField="name"
                      valueField="siteId"
                      disabled={!canSaveChanges}
                      width={'350px'}
                    />
                  </Container>
                </Box>
              </Box>
            </Paper>
            <ButtonStyled onClick={onCancel} id="siteNotifications-cancelButton">
              {T.translate(LocaleKeys.labels.cancel)}
            </ButtonStyled>
            {canSaveChanges && (
              <ButtonStyled
                type="submit"
                disabled={!(dirty && isValid) || isSubmitting}
                id="siteNotifications-submitButton"
              >
                {T.translate(LocaleKeys.labels.save)}
              </ButtonStyled>
            )}
            <ButtonStyled onClick={() => setShowPreview(true)} id="siteNotifications-previewButton">
              {T.translate(LocaleKeys.labels.preview)}
            </ButtonStyled>

            {canSaveChanges && <SaveOnLeave saveData={handleSubmit} dataChanged={dirty} validForm={isValid} />}
          </form>
        </>
      )}
    </FormikEnhanced>
  );
};

Details.propTypes = {
  history: PropTypes.object,
  permissions: PropTypes.object,
};

export default compose(
  withPermissions([
    Permissions.CanAccessSiteNotificationsPage,
    Permissions.CanReadSites,
    Permissions.CanReadSiteNotifications,
  ])
)(Details);
