import React from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DeleteDialog from 'Components/dialogs/DeleteDialog';
import LocaleKeys from 'Localization/LocaleKeys';
import { getSites, deleteSite } from 'Api/sites';
import T from 'i18n-react';
import Table from 'Components/display/table/Table';
import Loader from 'Components/Loader';
import { Permissions } from 'Constants/permissions';
import { withRouter } from 'react-router-dom';
import { NewSite, SiteDetails } from 'routes/Routes';
import TableActionButton from 'Components/display/table/TableActionButton';
import { compose } from 'redux';
import withPermissions from 'hocs/withPermissions';

const formatDataForTableView = (sites) =>
  Object.values(sites).map((site) => ({
    siteId: site.siteId,
    name: site.name,
    description: site.description,
    activeUserNo: site.activeUserNo,
    deviceNo: site.deviceNo,
    siteGroup: site.siteGroup,
    isAdmin: site.isAdmin,
    isActive: site.isActive,
    customerFrom: site.customerFrom,
  }));

class Sites extends React.Component {
  state = {
    data: [],
    isLoaded: false,
    deleteSiteDialogOpen: false,
    itemToRemove: null,
  };

  componentDidMount = () => {
    this.setData();
  };

  setData = () => {
    getSites()
      .then((response) => {
        this.setState((prevState) => ({ ...prevState, data: response.data }));
      })
      .then(() => {
        this.setState((prevState) => ({ ...prevState, isLoaded: true }));
      });
  };

  rowDoubleClick = (name) => {
    const url = SiteDetails.getUrl({
      name: name.replace(/\s/g, '-'),
    });

    this.props.history.push(url);
  };

  onAddClick = () => {
    const url = NewSite.getUrl();

    this.props.history.push(url);
  };

  showDeleteDialog = (id) => {
    this.setState({ deleteSiteDialogOpen: true, itemToRemove: id });
  };

  handleDeleteSiteCancel = () => {
    this.setState({ deleteSiteDialogOpen: false, itemToRemove: null });
  };

  handleDeleteSiteConfirm = () => {
    return deleteSite(this.state.itemToRemove)
      .then(() => {
        this.setState({ deleteSiteDialogOpen: false, itemToRemove: null });
      })
      .finally(() => {
        this.setData();
      });
  };

  getTableActionButtons = () => {
    const actionButtons = [];

    if (this.props.permissions[Permissions.CanEditSites]) {
      const editItemButton = (
        <TableActionButton
          key="editItemButton"
          onClick={this.rowDoubleClick}
          clickparam={'name'}
          icon={<EditIcon />}
          tooltipText={T.translate(LocaleKeys.tooltips.editSite)}
        />
      );

      actionButtons.push(editItemButton);
    }
    if (this.props.permissions[Permissions.CanRemoveSites]) {
      const deleteItemButton = (
        <TableActionButton
          key="deleteItemButton"
          onClick={this.showDeleteDialog}
          clickparam={'siteId'}
          icon={<DeleteIcon />}
          tooltipText={T.translate(LocaleKeys.tooltips.deleteSite)}
          hiddenwhenparam={'isAdmin'}
        />
      );

      actionButtons.push(deleteItemButton);
    }

    return actionButtons;
  };

  render() {
    if (!this.state.isLoaded) {
      return <Loader />;
    }

    const tableData = formatDataForTableView(this.state.data);
    const actionButtons = this.getTableActionButtons();

    return (
      <>
        <DeleteDialog
          isOpen={this.state.deleteSiteDialogOpen}
          onConfirm={this.handleDeleteSiteConfirm}
          onCancel={this.handleDeleteSiteCancel}
          disableConfirm={!this.state.itemToRemove}
          title={T.translate(LocaleKeys.messages.deleteSiteDialogTitle)}
          message={T.translate(LocaleKeys.messages.deleteSiteDialogMessage)}
        />
        <Table
          data={tableData}
          onRowDoubleClick={this.rowDoubleClick}
          redirect={'name'}
          onAddClick={this.props.permissions[Permissions.CanAddSites] && this.onAddClick}
          hideColumns={['siteId']}
          actionButtons={actionButtons}
          tableName={'sites'}
        />
      </>
    );
  }
}

Sites.propTypes = {
  history: PropTypes.object,
  permissions: PropTypes.object,
};

export default compose(withPermissions([Permissions.CanReadSites]), withRouter)(Sites);
