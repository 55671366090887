import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
const Notificator = ({ history, variant }) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (history && history.length) {
      const newNotification = history[history.length - 1];

      enqueueSnackbar(newNotification.message, {
        variant,
        ...newNotification,
      });
    }
  }, [history, variant, enqueueSnackbar]);

  return null;
};

Notificator.defaultProps = {
  variant: 'error',
};

Notificator.propTypes = {
  history: PropTypes.array.isRequired,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
};

const mapState = (state) => ({
  history: state.notifications.history,
  variant: state.notifications.variant,
});

export default connect(mapState)(Notificator);
