import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import Consts from 'Constants';
import { Permissions } from 'Constants/permissions';
import { getUserByLogin, putUser, postActiveAccount } from 'Api/users';
import { getUser as getPolicyUser, putUserRoles } from 'Api/policyServer';
import Button from 'Components/Button';
import UserInformation from './tabs/UserInformation';
import Roles from './tabs/Roles';
import ResourceLimitations from './tabs/ResourceLimitations';
import { compose } from 'redux';
import { styled } from '@material-ui/core';
import { Users } from 'routes/Routes';
import withPermissions from 'hocs/withPermissions';
import Loader from 'Components/Loader';
import { getDevicesGroupsByLogin, putDevicesGroupsSubscribeToUser } from 'Api/devices';
import { userValidation } from './UserValidation';
import Tabs from 'Components/display/tabs/Tabs';
import SaveOnLeave from 'Components/dialogs/SaveOnLeave';
import { FormikEnhanced } from 'Components/formik/formikWrappers';

const ButtonStyled = styled(Button)({
  float: 'right',
  marginLeft: '10px',
});

const ActionsPanel = styled('div')({
  textAlign: 'right',
  marginBottom: '10px',
  marginTop: '10px',
});

const Details = (props) => {
  const [user, setUser] = useState();
  const [selectedDevicesGroups, setSelectedDeviceGroups] = useState([]);
  const [selectedDevicesGroupsChanged, setSelectedDevicesGroupsChanged] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [initialUserRoles, setInitialUserRoles] = useState([]);
  const validationCache = useRef({
    login: null,
    defaultEmail: null,
  });
  const isUserRolesDirty = initialUserRoles.sort().toString() !== userRoles.sort().toString();
  const canReadDevicesGroups = props.permissions[Permissions.CanReadDevicesGroups];
  const canReadUserRoles = props.permissions[Permissions.CanReadDevicesGroups];

  const fetchDataCallback = useCallback(() => {
    const { userLogin } = props.match.params;

    getUserByLogin(userLogin).then(({ data }) => {
      setUser(data);
    });
    if (canReadDevicesGroups) {
      getDevicesGroupsByLogin(userLogin).then(({ data }) => {
        setSelectedDeviceGroups(data.deviceGroups.map((group) => group.externalId));
        setSelectedDevicesGroupsChanged(false);
      });
    }
    if (canReadUserRoles) {
      getPolicyUser(userLogin).then(({ data }) => {
        const userRoles = data.roles.map((role) => role.code);

        setInitialUserRoles(userRoles);
        setUserRoles(userRoles);
      });
    }
  }, [props.match.params, canReadDevicesGroups, canReadUserRoles]);

  useEffect(() => {
    fetchDataCallback();
  }, [fetchDataCallback]);

  const saveUserInfo = async (values) => {
    if (selectedDevicesGroupsChanged && props.permissions[Permissions.CanEditDevicesGroup]) {
      await putDevicesGroupsSubscribeToUser(user.login, selectedDevicesGroups);
      setSelectedDevicesGroupsChanged(false);
    }
    if (isUserRolesDirty && props.permissions[Permissions.CanEditUserRoles]) {
      saveUserRoles();
    }
    await putUser(user.id, values);
  };

  const saveUserRoles = async () => {
    await putUserRoles(user.login, userRoles);
    setUserRoles(userRoles);
    setInitialUserRoles(userRoles);
  };

  const redirect = () => {
    const url = Users.getUrl();

    props.history.push(url);
  };

  const onSaveClick = async (values, setSubmitting) => {
    setSubmitting(true);
    saveUserInfo(values)
      .then(() => {
        setSubmitting(false);
        redirect();
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  const onSaveAndActiveClick = async (values, setSubmitting) => {
    setSubmitting(true);
    saveUserInfo(values)
      .then(async () => {
        await postActiveAccount(user.login);
        setSubmitting(false);
        redirect();
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  const onSwitchTabConfirmSave = (values, setSubmitting) => {
    setSubmitting(true);
    saveUserInfo(values).then(() => {
      fetchDataCallback();
      setSubmitting(false);
    });
  };

  const onSwitchTabContinueWithoutSave = (dirty, resetForm) => {
    if (dirty) {
      fetchDataCallback();
      resetForm();
    }
  };

  const onCancelClick = () => {
    redirect();
  };

  if (!user) {
    return <Loader />;
  }

  const canSaveChanges = !!props.permissions[Permissions.CanEditUsers];

  return (
    <FormikEnhanced
      initialValues={user}
      enableReinitialize
      validationSchema={userValidation(user, validationCache.current)}
      validateOnMount
      canSaveChanges={canSaveChanges}
    >
      {({ values, dirty, isValid, isValidating, isSubmitting, setSubmitting, resetForm }) => {
        const isDirty = dirty || selectedDevicesGroupsChanged || isUserRolesDirty;

        return (
          <>
            <Tabs
              label="userDetails"
              saveChange={() => onSwitchTabConfirmSave(values, setSubmitting)}
              setData={() => onSwitchTabContinueWithoutSave(isDirty, resetForm)}
              showConfirmationDialog={isDirty && canSaveChanges}
              validForm={isValid}
            >
              <UserInformation label={LocaleKeys.labels.userInformation} />
              <Roles
                label={LocaleKeys.labels.roles}
                disabled={!props.permissions[Permissions.CanReadUserRoles]}
                setUserRoles={setUserRoles}
                userRoles={userRoles}
                onCancel={onCancelClick}
              />
              <ResourceLimitations
                label={LocaleKeys.labels.resourceManagement}
                selectedDevicesGroups={selectedDevicesGroups}
                setSelectedDevicesGroups={(selected) => {
                  setSelectedDeviceGroups(selected);
                  setSelectedDevicesGroupsChanged(true);
                }}
                disabled={!props.permissions[Permissions.CanReadDevicesGroups]}
              />
            </Tabs>
            <ActionsPanel>
              <ButtonStyled onClick={onCancelClick} id="users-cancelButton">
                {T.translate(LocaleKeys.labels.cancel)}
              </ButtonStyled>

              {canSaveChanges && (
                <>
                  <ButtonStyled
                    onClick={() => onSaveClick(values, setSubmitting)}
                    disabled={!(isDirty && isValid) || isSubmitting || isValidating}
                    id="users-saveButton"
                  >
                    {T.translate(LocaleKeys.labels.save)}
                  </ButtonStyled>

                  {user.userStatus === Consts.UserStatuses.NEW && (
                    <ButtonStyled
                      onClick={() => onSaveAndActiveClick(values, setSubmitting)}
                      disabled={!isValid || isSubmitting || isValidating}
                    >
                      {!!isDirty
                        ? T.translate(LocaleKeys.labels.saveAndActivate)
                        : T.translate(LocaleKeys.labels.activate)}
                    </ButtonStyled>
                  )}
                  <SaveOnLeave
                    saveData={() => onSaveClick(values, setSubmitting)}
                    dataChanged={isDirty}
                    validForm={isValid}
                  />
                </>
              )}
            </ActionsPanel>
          </>
        );
      }}
    </FormikEnhanced>
  );
};

Details.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  permissions: PropTypes.object,
};

export default compose(withPermissions([Permissions.CanAccessUsersPage, Permissions.CanReadUsers]))(Details);
