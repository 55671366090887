import React, { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import store from 'redux/store';
import * as Routes from 'routes/Routes';
import Callback from 'app/pages/sites/account/Callback';
import SilentRefreshCallback from 'app/pages/sites/account/SilentRefreshCallback';
import ContentRouter from './app/routing/ContentRouter';
import SnackbarProviderWithCloseButton from './app/layout/SnackbarProviderWithCloseButton';
import Initialization from 'Initialization';
import RefreshSessionDialog from 'app/layout/RefreshSession';
import { SignalRProvider } from 'context/signalr';
import { DeviceStatusProvider } from 'context/deviceStatusConnection';
import UserActivityMonitor from 'Auth/UserActivityMonitor';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from 'theme';
import './localization';

const App = () => {
  return (
    <StrictMode>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <UserActivityMonitor />
          <SnackbarProviderWithCloseButton>
            <BrowserRouter>
              <Switch>
                {/* oidc-login-callback NotAuthorize */}
                <Route path={Routes.Callback.getFullPath()} component={Callback} />
                <Route path={Routes.SilentRefreshCallback.getFullPath()} component={SilentRefreshCallback} />
                <Route path={`${Routes.Root.getFullPath()}?`}>
                  <Initialization>
                    <RefreshSessionDialog />
                    <SignalRProvider>
                      <DeviceStatusProvider>
                        <ContentRouter />
                      </DeviceStatusProvider>
                    </SignalRProvider>
                  </Initialization>
                </Route>
              </Switch>
            </BrowserRouter>
          </SnackbarProviderWithCloseButton>
        </Provider>
      </ThemeProvider>
    </StrictMode>
  );
};

export default App;
