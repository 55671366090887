import { LocalStorageKeys } from 'Constants/LocalStorageKeys';
import { translatePathSegment } from './paths';

const removeDoubleSlash = (path) => {
  return path.replace(/\/+/g, '/');
};

class RouteInfo {
  constructor(config) {
    const userLanguage = localStorage.getItem(LocalStorageKeys.USER_LANGUAGE);

    this._path = translatePathSegment(config.path, userLanguage);
    this._parentRoute = translatePathSegment(config.parentRoute, userLanguage) || null;
    this._params = config.params || null;
  }

  getFullPath = (params) => {
    let fullPath = '';

    if (this._parentRoute) {
      fullPath += this._parentRoute.getFullPath(params);
    }
    fullPath += '/';
    fullPath += this._path;

    const fullParams = {
      ...this._params,
    };

    for (const key in params) {
      if (fullParams[key]) {
        fullParams[key] = params[key];
      }
    }

    const paramsValues = Object.values(fullParams);

    if (paramsValues.length) {
      fullPath = `${fullPath}/${paramsValues.join('/')}`;
    }

    return removeDoubleSlash(fullPath);
  };

  getUrl = (params) => {
    const rawSiteName = window.location.pathname.split('/')[1];
    const siteName = decodeURI(rawSiteName).replace(/ /g, '-');

    return this.getFullPath(params).replace(':siteName', siteName);
  };
}

export default RouteInfo;
