import React, { useState, useEffect, useRef } from 'react';
import { Box, Button } from '@material-ui/core';
import { InfoOutlined, Lens, ExpandMore, ExpandLess } from '@material-ui/icons';
import { styled } from '@material-ui/core/styles';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import Content from 'Components/display/notification/Content';

const IconStyled = styled(InfoOutlined)({
  fontSize: 30,
});
const ReadedIcon = styled(Lens)({
  fontSize: 10,
});
const ButtonRead = styled(Button)(({ disabled }) => ({
  height: 22,
  minWidth: 22,
  margin: 0,
  marginTop: 6,
  marginLeft: 16,
  opacity: disabled ? 0.4 : 1,
}));

const ExpandedContent = styled('div')({
  lineHeight: '1.5em',
  overflowWrap: 'break-word',
  width: 400,
});

const isPasswordNotification = (content) => ['PasswordAlreadyExpired', 'PasswordHasBeenReset'].includes(content);

const getNotificationContent = ({ content, content2, login }) => {
  if (content === 'PasswordAlreadyExpired') {
    return `${T.translate(LocaleKeys.messages.yourPasswordIsSoonToExpireIn, {
      days: content2,
    })}. ${T.translate(LocaleKeys.messages.pleaseChangeItOtherwiseYouWillNotBeAbleToLogIn)}.`;
  } else if (content === 'PasswordHasBeenReset') {
    return `${T.translate(LocaleKeys.messages.passwordHasBeenResetForUser, {
      login,
    })}.`;
  } else if (content.startsWith('Account activation email sent for user:')) {
    return T.translate(LocaleKeys.messages.accountActivationEmailSentForUser, { login });
  } else if (content.startsWith('Account activation awaiting for user:')) {
    return T.translate(LocaleKeys.messages.accountActivationAwaitingForUser, { login });
  } else if (content.startsWith('Account activated for user:')) {
    return T.translate(LocaleKeys.messages.accountActivatedForUser, { login });
  } else if (content.startsWith("Account hasn't been activated for user:")) {
    return T.translate(LocaleKeys.messages.accountHasNotBeenActivatedForUser, { login });
  }

  return content;
};

export const Notification = (props) => {
  const { notification, onClickRead, style, index, setSelectedIndex, selectedIndex } = props;
  const divRef = useRef(null);
  const [showReadMore, setShowReadMore] = useState(false);

  useEffect(() => {
    if (
      !showReadMore &&
      divRef.current.scrollHeight > divRef.current.offsetHeight &&
      divRef.current.scrollHeight > 50
    ) {
      setShowReadMore(true);
    }
  }, [showReadMore]);

  const getRow = () => {
    const content = getNotificationContent(notification);

    return index !== selectedIndex ? (
      <Content ref={divRef} bgcolor={notification.isRead ? '#F2F2F2' : 'white'}>
        {content}
      </Content>
    ) : (
      <ExpandedContent ref={divRef}>{content}</ExpandedContent>
    );
  };

  return (
    <Box
      display="flex"
      padding="12px 24px"
      marginTop="10px"
      bgcolor={notification.isRead ? '#F2F2F2' : 'white'}
      style={{ ...style }}
    >
      <Box>
        <IconStyled />
      </Box>
      <Box paddingLeft="24px" textAlign="justify" flexGrow={1}>
        <Box display="flex" justifyContent="space-between" fontSize={12} paddingBottom="10px">
          <strong>
            {(notification.messageType === 2
              ? T.translate(LocaleKeys.labels.systemNotification)
              : T.translate(LocaleKeys.labels.siteNotification)
            ).toUpperCase()}
          </strong>
          <span>{new Date(notification.from).toLocaleString()}</span>
        </Box>
        <div>
          <div>{getRow()}</div>
          <Box display="flex" flexDirection="row" paddingLeft="201px">
            {index !== selectedIndex ? (
              showReadMore && (
                <ExpandMore
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setSelectedIndex(index);
                  }}
                />
              )
            ) : (
              <ExpandLess
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectedIndex(-1);
                }}
              />
            )}
            {notification.content2 && !isPasswordNotification(notification.content) && (
              <Box paddingLeft="30px" marginRight="0">
                <strong>
                  <a
                    href={
                      notification.content2.startsWith('http://') || notification.content2.startsWith('https://')
                        ? notification.content2
                        : `//${notification.content2}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {T.translate(LocaleKeys.labels.linkToMoreContent)}
                  </a>
                </strong>
              </Box>
            )}
          </Box>
        </div>
      </Box>
      <Box>
        <ButtonRead
          size="small"
          disabled={notification.isRead}
          onClick={() => {
            onClickRead();
          }}
        >
          <ReadedIcon color="primary" />
        </ButtonRead>
      </Box>
    </Box>
  );
};

export default Notification;
