import ApiClient from './ApiClient';

const apiClient = new ApiClient();
const SERVICE_PATH = '/policyserver/api/';

const get = (path, params) => apiClient.get(SERVICE_PATH + path, { params });
const post = (path, data) => apiClient.post(SERVICE_PATH + path, data);
const put = (path, data) => apiClient.put(SERVICE_PATH + path, data);
const deleteRequest = (path, data) => apiClient.delete(SERVICE_PATH + path, data);
const getWithError = (path, data, headers) => apiClient.getWithError(SERVICE_PATH + path, data, headers);

// Role
export const getRoles = () => get('Role');
export const getRole = (code) => get(`Role/${code}`);
export const getRoleByName = (name) => get(`Role/GetByName/${name}`);
export const postRole = (data) => post('Role', data);
export const putRole = (data) => put(`Role/${data.id}`, data);
export const deleteRole = (code) => deleteRequest(`Role/${code}`);
export const getRoleExistsByName = (name) => get(`Role/name/${name}/exists`);

// Permission
export const getPermissions = () => get('Permission');

// User
export const getUser = (login) => get(`User/${login}`);
export const putUserRoles = (login, codes) => put(`User/${login}/roles`, codes);

// IpWhiteList
export const getIpWhiteListDetails = (tenantId) => get(`IpWhiteList/${tenantId}/details`);
export const getIpWhiteListByTenantId = (tenantId) => get(`IpWhiteList/ByTenantId/${tenantId}`);
export const deleteIpWhiteList = (id) => deleteRequest(`IpWhiteList/${id}`);
export const postIpWhiteAddress = (ipWhiteListItem) => post('IpWhiteList', ipWhiteListItem);
export const putIpWhiteAddress = (itemId, ipWhiteListItem) => put(`IpWhiteList/${itemId}`, ipWhiteListItem);

// Types
export const getIpAddressTypes = () => get(`Types/WhiteListItemType`);

// HealthCheck
export const getCheckAccess = (tenantID) => {
  let headers;

  if (tenantID) {
    headers = { 'X-Tenant-ID': tenantID };
  }

  return getWithError('HealthCheck/CheckAccess', null, headers);
};

// User permissions
export const getLoggedUserPermission = () => get('UserPermissions/LoggedUserPermission');
