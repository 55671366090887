import * as Yup from 'yup';
import { VehicleRunningTypes } from 'Constants/VehicleRunningTypes';
import { getDeviceExistsByName, getDeviceExistsByDeviceCode, getDeviceExistsByImei } from 'Api/devices';
import { phoneNumberVectorRegExp, isAlphanumericOrSpecialSymbol, linkRegExp } from 'Validators/SharedValidators';
import { validationSchema as buttonsValidationSchema } from 'Components/buttonLibrary/validationSchema';

let lastName;

export const deviceNameExist = async (value, initialValue) => {
  if (isAlphanumericOrSpecialSymbol([' '])(value)) {
    const result = lastName?.value === value ? lastName?.result : await getDeviceExistsByName(value);

    lastName = { value, result };

    return initialValue === value || !result?.data;
  }

  return false;
};

let lastCode;

export const deviceCodeExist = async (value, initialValue) => {
  if (isAlphanumericOrSpecialSymbol([' '])(value)) {
    const result = lastCode?.value === value ? lastCode?.result : await getDeviceExistsByDeviceCode(value);

    lastCode = { value, result };

    return initialValue === value || !result?.data;
  }

  return false;
};

let lastImei;

export const imeiExist = async (value, initialValue) => {
  if (isAlphanumericOrSpecialSymbol([' '])(value)) {
    const result = lastImei?.value === value ? lastImei?.result : await getDeviceExistsByImei(value);

    lastImei = { value, result };

    return initialValue === value || !result?.data;
  }

  return false;
};

const validationSchema = (initialValues) =>
  Yup.object().shape({
    // General
    name: Yup.string()
      .required('thisFieldIsRequired')
      .max(50, 'nameCannotExceed50Characters')
      .alphanumericOrSpecialSymbol([' '])
      .test('deviceNameExist', 'deviceWithThisNameAlreadyExists', function (value) {
        return deviceNameExist(value, initialValues.name);
      }),
    deviceCode: Yup.string()
      .nullable()
      .optional()
      .test('requiredIfNoImei', 'deviceIdOrImeiIsRequired', function (value) {
        return !this.parent.imei ? value && value.toString() !== '' : true;
      })
      .matches(/^[A-Za-z0-9]+$/, 'nameShouldBeAlphanumeric')
      .max(50, 'nameCannotExceed50Characters')
      .test('deviceCodeExist', 'deviceWithThisCodeAlreadyExists', function (value) {
        return deviceCodeExist(value, initialValues.deviceCode);
      }),
    imei: Yup.string()
      .nullable()
      .optional()
      .test('requiredIfNoDeviceCode', 'deviceIdOrImeiIsRequired', function (value) {
        return !this.parent.deviceCode ? value && value.toString() !== '' : true;
      })
      .matches(/^[0-9]+$/, 'imeiNotValidFormat')
      .min(15, 'imeiNotValidLength')
      .max(15, 'imeiNotValidLength')
      .test('imeiExist', 'deviceWithThisImeiAlreadyExists', function (value) {
        return imeiExist(value, initialValues.imei);
      }),
    description: Yup.string().nullable().optional().max(200, 'nameCannotExceed200Characters'),
    customer: Yup.string().nullable().optional().max(200, 'nameCannotExceed200Characters'),
    remark: Yup.string().nullable().optional().max(200, 'nameCannotExceed200Characters'),
    phone: Yup.string()
      .nullable()
      .optional()
      .min(8, 'minPhoneNumberLength')
      .matches(phoneNumberVectorRegExp, 'incorrectPhoneNumber'),
    frameRelatedPeriod: Yup.number()
      .integer('onlyIntegersAreAllowed')
      .positive('valueMustBePositiveNumber')
      .when('useForIndefiniteTime', {
        is: false,
        then: Yup.number().required('thisFieldIsRequired'),
      }),
    indicatorBefore: Yup.number().lessThan(Yup.ref('indicatorTo'), 'beforeShouldBeSmallerThanTo'),
    indicatorTo: Yup.number().moreThan(Yup.ref('indicatorBefore'), 'toShouldBeGreaterThanBefore'),
    // Other Configuration
    httpFeeds: Yup.array().of(
      Yup.object()
        .shape({
          name: Yup.string().required('thisFieldIsRequired').max(50, 'nameCannotExceed50Characters'),
          description: Yup.string().nullable().optional().max(200, 'nameCannotExceed200Characters'),
          url: Yup.string()
            .required('thisFieldIsRequired')
            .max(200, 'nameCannotExceed200Characters')
            .matches(linkRegExp, 'urlMustBeInserted'),
        })
        .uniqueInCollection('valueMustBeUniqueInCollection', 'name')
    ),
    vehicle: Yup.object().shape({
      vehicleRunningValue: Yup.number().when('vehicleRunningType', {
        is: VehicleRunningTypes.IGNITION_IS_ON,
        then: Yup.number().optional(),
        otherwise: Yup.number()
          .required('thisFieldIsRequired')
          .positive('valueMustBePositiveNumber')
          .test('int', 'valueMustBeInteger', Number.isInteger),
      }),
    }),
    // Location
    latitude: Yup.number()
      .nullable()
      .when('useDefault', {
        is: true,
        then: Yup.number().required('thisFieldIsRequired'),
        otherwise: Yup.number().nullable().optional(),
      }),
    longitude: Yup.number()
      .nullable()
      .when('useDefault', {
        is: true,
        then: Yup.number().required('thisFieldIsRequired'),
        otherwise: Yup.number().nullable().optional(),
      }),
    // Mappings
    mappings: Yup.object()
      .shape({
        id: Yup.number().when('driverId', {
          is: (val) => !!val,
          then: Yup.number().required('thisFieldIsRequired'),
        }),
        digitalInputMappings: Yup.array().of(
          Yup.object()
            .shape({
              name: Yup.string().required('thisFieldIsRequired').max(50, 'nameCannotExceed50Characters'),
              inputValue: Yup.string().required('thisFieldIsRequired'),
            })
            .uniqueInCollection('valueMustBeUniqueInCollection', 'name')
        ),
        analogInputMappings: Yup.array().of(
          Yup.object()
            .shape({
              name: Yup.string().required('thisFieldIsRequired').max(50, 'nameCannotExceed50Characters'),
              inputValue: Yup.string().required('thisFieldIsRequired'),
            })
            .uniqueInCollection('valueMustBeUniqueInCollection', 'name')
        ),
        keywordMappings: Yup.array().of(
          Yup.object()
            .shape({
              name: Yup.string().required('thisFieldIsRequired').max(50, 'nameCannotExceed50Characters'),
              inputValue: Yup.string().required('thisFieldIsRequired'),
            })
            .uniqueInCollection('valueMustBeUniqueInCollection', 'name')
        ),
      })
      .nullable()
      .default(null),
    // Buttons
    deviceButtons: buttonsValidationSchema(initialValues.deviceButtons).optional().nullable(),
  });

export default validationSchema;
