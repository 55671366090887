import React from 'react';
import PropTypes from 'prop-types';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import { Dialog, DialogContent, DialogActions, styled } from '@material-ui/core';
import { Formik } from 'formik';
import Button from 'Components/Button';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import { Checkbox, DropdownWithApiCall, TextField } from 'Components/formik/fields';
import { getIpAddressTypes } from 'Api/policyServer';
import { IpWhiteListItemValidationSchema } from '../../SitesValidation';
import { IpWhiteListItemTypes } from 'Constants/IpWhiteListItemTypes';

const CustomRow = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  padding: '0 4%',
  justifyContent: 'space-between',
});

const IpWhiteListItemDialog = ({ onSaveDialog, onCancel, initialValues, ...props }) => {
  return (
    <Dialog
      open={props.open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={IpWhiteListItemValidationSchema}
        validateOnMount
      >
        {({ values, dirty, isValid }) => (
          <>
            <DialogContent>
              <ColumnDisplayer>
                <DropdownWithApiCall
                  label={T.translate(LocaleKeys.labels.type)}
                  name={'type'}
                  apiCall={getIpAddressTypes}
                  displayField="name"
                  valueField="name"
                  withoutEmpty={true}
                  translateLabel
                />
                <TextField name={'remarks'} label={T.translate(LocaleKeys.labels.remarks)} />
                <Checkbox name={'enabled'} label={T.translate(LocaleKeys.labels.enabled)} />
              </ColumnDisplayer>
              <CustomRow>
                {values.type === IpWhiteListItemTypes.SINGLE && (
                  <TextField name={'ipAddress'} label={T.translate(LocaleKeys.labels.ipAddress)} />
                )}
                {values.type === IpWhiteListItemTypes.RANGE && (
                  <TextField
                    name={'ipAddressRange'}
                    label={T.translate(LocaleKeys.labels.ipAddressRange)}
                    placeholder="10.0.0.1-100"
                  />
                )}
              </CustomRow>
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancel} id="ipWhiteList-cancelButton">
                {T.translate(LocaleKeys.labels.cancel)}
              </Button>
              <Button
                onClick={() => {
                  onSaveDialog(values);
                }}
                autoFocus
                disabled={!(dirty && isValid)}
                id="ipWhiteList-submitButton"
              >
                {T.translate(LocaleKeys.labels.save)}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

IpWhiteListItemDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSaveDialog: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  open: PropTypes.bool,
};

export default IpWhiteListItemDialog;
