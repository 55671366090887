import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import ErrorMessage from 'Components/display/ErrorMessage';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { setLoadingMapToken, loadMapTokenSuccess, loadMapTokenError } from 'redux/app/actions';
import { getLoadingMapToken, getMapToken, getMapTokenError } from 'redux/app/selectors';
import { compose } from 'redux';
import { getMapAccessData } from 'Api/maps';

const withMapToken =
  (WrappedComponent) =>
  ({
    mapToken,
    mapTokenError,
    isLoadingMapToken,
    setLoadingMapToken,
    loadMapTokenSuccess,
    loadMapTokenError,
    ...props
  }) => {
    const fetchMapToken = () => {
      setLoadingMapToken(true);
      getMapAccessData()
        .then(({ data }) => {
          loadMapTokenSuccess(data);
        })
        .catch((error) => {
          loadMapTokenError(error);
        });
    };

    if (isLoadingMapToken) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      );
    }

    if (mapTokenError) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <ErrorMessage
            primaryMessage={T.translate(LocaleKeys.messages.errorWhileFetchingData, {
              name: T.translate(LocaleKeys.labels.mapToken),
            })}
            onRetry={fetchMapToken}
          />
        </Box>
      );
    }

    return <WrappedComponent {...props} mapToken={mapToken} />;
  };

const mapStateToProps = (state) => ({
  mapToken: getMapToken(state),
  mapTokenError: getMapTokenError(state),
  isLoadingMapToken: getLoadingMapToken(state),
});

const mapDispatchToProps = {
  setLoadingMapToken,
  loadMapTokenSuccess,
  loadMapTokenError,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withMapToken);
