import * as Yup from 'yup';
import { getMapAreaExistsByName } from 'Api/maps';
import { isAlphanumericOrSpecialSymbol } from 'Validators/SharedValidators';

let lastValue;
let initialValue;

export const mapAreaNameExist = async (value, id) => {
  if (isAlphanumericOrSpecialSymbol([' '])(value)) {
    const result = lastValue?.value === value ? lastValue?.result : await getMapAreaExistsByName(value);

    if (id) {
      if (!initialValue || id !== initialValue?.id) {
        initialValue = { value, id };
      }
    } else {
      initialValue = undefined;
    }

    lastValue = { value, result };

    return initialValue?.value === value || !result?.data;
  }

  return false;
};

const MapAreasValidation = Yup.object().shape({
  name: Yup.string()
    .required('thisFieldIsRequired')
    .max(50, 'nameCannotExceed50Characters')
    .alphanumericOrSpecialSymbol([' '])
    .test('mapAreaNameExist', 'mapAreaWithThisNameAlreadyExists', function (value) {
      return mapAreaNameExist(value, this.parent.id);
    }),
  description: Yup.string().max(200, 'nameCannotExceed200Characters'),
  area: Yup.string()
    .required('thisFieldIsRequired')
    .matches(/("type":"Point")|("type":"LineString")|("type":"Polygon")/g, 'mapAreaDoesNotContainCorrectElements'),
});

export default MapAreasValidation;
