import Accordion from '@material-ui/core/Accordion';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

export const AccordionStyled = withStyles({
  root: {
    border: '1px solid rgba(0,0,0,.125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  expanded: {
    margin: 'auto !important',
  },
})(Accordion);

class ExpansionPanel extends React.Component {
  state = {
    expanded: true,
  };

  render() {
    return (
      <AccordionStyled
        square
        expanded={this.state.expanded}
        onChange={() => {
          this.setState({ expanded: !this.state.expanded });
        }}
      >
        {this.props.children}
      </AccordionStyled>
    );
  }
}

ExpansionPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  ]),
};

export default ExpansionPanel;
