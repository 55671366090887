export const getInitialTime = (hour) => {
  const time = new Date();

  time.setHours(hour);
  time.setMinutes(0);
  time.setSeconds(0);
  time.setMilliseconds(0);

  return time.toISOString();
};

const getTimeOnly = (datetime) => {
  const dateObj = new Date(datetime);

  dateObj.setUTCFullYear(0, 0, 1);
  dateObj.setUTCSeconds(0);
  dateObj.setMilliseconds(0);

  return dateObj;
};

export const getDateOnly = (datetime) => {
  const dateObj = new Date(datetime);

  dateObj.setUTCHours(0);
  dateObj.setUTCSeconds(0);
  dateObj.setMilliseconds(0);

  return dateObj;
};

export const convertVacations = (vacations) => {
  return vacations.sort((a, b) => +getDateOnly(a.from) - getDateOnly(b.from));
};

export const convertSpecialEntries = (specialEntries) => {
  return specialEntries.sort((a, b) => +new Date(a.from) - new Date(b.from));
};

const sortTimeEntries = (timeEntries) => {
  timeEntries.sort((a, b) => +getTimeOnly(a.from) - getTimeOnly(b.from));
};

const groupWorkDays = (obj, item) => {
  obj[item.dayName] = item;
  sortTimeEntries(item.operationDay.timeEntries);

  return obj;
};

export const convertWorkdays = (workdays) => {
  return workdays.sort((a, b) => a.dayOfWeek - b.dayOfWeek).reduce(groupWorkDays, {});
};
