import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const ListItemIconStyled = styled(ListItemIcon)({
  '&& img': {
    height: 20,
  },
});

class CollapseItem extends React.Component {
  state = {
    open: false,
  };

  handleClick = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  render() {
    return [
      <ListItem key={this.props.label} button onClick={this.handleClick}>
        <ListItemIconStyled>
          <img src={this.props.icon} alt="" />
        </ListItemIconStyled>
        <ListItemText primary={this.props.label} />
        {this.state.open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>,
      <Collapse key={`${this.props.label}-collapse`} in={this.state.open} timeout="auto" unmountOnExit>
        <List component="div">{this.props.children}</List>
      </Collapse>,
    ];
  }
}

CollapseItem.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  ]),
};

export default CollapseItem;
