import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { getDevices, deleteDevice, getDeviceAffectedAlarms, getServiceStatuses, getTcpIpStatuses } from 'Api/devices';
import TableApi, { DeleteButton } from 'Components/display/TableApi/TableApi';
import { getSites } from 'Api/sites';
import { NewDeviceConfiguration, DeviceConfigurationDetails } from 'routes/Routes';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import LocaleKeys from 'Localization/LocaleKeys';
import DeleteAffectingAlarmsDialog from 'Components/dialogs/DeleteAffectingAlarmsDialog';
import Loader from 'Components/Loader';
import { operators } from 'Api/utils';
import { ApiHeaders } from 'Api/ApiClient';

const convertDate = (date) => (date ? new Date(date).toLocaleString() : '');

const findSiteName = (sites, siteId) => sites.find((site) => site.siteId === siteId)?.name;

const DevicesConfiguration = ({ permissions, location }) => {
  const [sites, setSites] = useState([]);
  const [affectedAlarms, setAffectedAlarms] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [itemToRemove, setItemToRemove] = useState(null);
  const [dictionaries, setDictionaries] = useState();
  const tableApiRef = useRef();

  useEffect(() => {
    Promise.all([getSites(), getServiceStatuses(), getTcpIpStatuses()]).then(
      ([sitesResponse, serviceStatusesResponse, tcpIpStatusesResponse]) => {
        setSites(sitesResponse.data);
        setDictionaries({
          serviceStatuses: serviceStatusesResponse.data,
          tcpIpStatuses: tcpIpStatusesResponse.data,
        });
      }
    );
  }, []);

  const handleDeleteClick = (id, externalId, tenantId) => {
    getDeviceAffectedAlarms(externalId, { headers: { [ApiHeaders.X_TENANT_ID]: tenantId } }).then(({ data }) => {
      const affectedAlarms = data.map((alarm) => ({
        ...alarm,
        alarmToRemove: !alarm.onlyAssetToRemove,
      }));

      setDeleteDialogOpen(true);
      setItemToRemove(id);
      setAffectedAlarms(affectedAlarms);
    });
  };

  const handleDeleteDeviceCancel = () => {
    setDeleteDialogOpen(false);
    setItemToRemove(null);
  };

  const handleDeleteDeviceConfirm = () => {
    return tableApiRef.current.deleteClick(itemToRemove).then(() => {
      setDeleteDialogOpen(false);
      setItemToRemove(null);
    });
  };

  if (!dictionaries) {
    return <Loader />;
  }

  const COLUMNS = {
    name: {},
    imei: {},
    customer: {},
    phone: {},
    description: {},
    remark: {},
    serviceStatus: {
      filterOptions: dictionaries.serviceStatuses.map(({ name }) => ({ label: T.translate(name), value: name })),
      format: (row) => T.translate(row.serviceStatus),
      filterOperator: operators.EQUALS,
    },
    tcpIpStatusIndicator: {
      filterOptions: dictionaries.tcpIpStatuses.map(({ name }) => ({ label: T.translate(name), value: name })),
      format: (row) => T.translate(row.tcpIpStatusIndicator),
      filterOperator: operators.EQUALS,
    },
    deviceTime: {
      name: T.translate(LocaleKeys.labels.lastPing),
      format: (row) => convertDate(row.deviceTime),
      disableFilter: true,
    },
    deviceDriverName: {
      format: (row) => T.translate(row.deviceDriverName),
    },
    siteName: {
      format: (row) => `${findSiteName(sites, row.tenantId)}`,
      disableSort: true,
      disableFilter: true,
    },
    edit: null, // placeholder for default edit column to display before delete
    delete: {
      name: ' ',
      disableSort: true,
      disableFilter: true,
      // eslint-disable-next-line
      format: ({ id, externalId, tenantId }) =>
        !!permissions[Permissions.CanRemoveDevices] && (
          <DeleteButton onClick={() => handleDeleteClick(id, externalId, tenantId)} />
        ),
    },
  };

  return (
    <>
      <TableApi
        ref={tableApiRef}
        client={getDevices}
        columns={COLUMNS}
        sites={sites}
        canAddNew={permissions[Permissions.CanAddDevices]}
        newUrl={NewDeviceConfiguration.getUrl()}
        buttons={{
          edit: permissions[Permissions.CanEditDevices],
        }}
        getEditUrl={DeviceConfigurationDetails.getUrl}
        deleteRequest={deleteDevice}
        itemName="Device"
        selectedSiteId={location?.state?.selectedSiteId}
      />
      <DeleteAffectingAlarmsDialog
        isOpen={deleteDialogOpen}
        onConfirm={handleDeleteDeviceConfirm}
        onCancel={handleDeleteDeviceCancel}
        disableConfirm={!itemToRemove}
        affectedAlarms={affectedAlarms}
        title={T.translate(LocaleKeys.messages.deleteDeviceDialogTitle)}
        message={T.translate(LocaleKeys.messages.deleteDeviceDialogMessage)}
      />
    </>
  );
};

DevicesConfiguration.propTypes = {
  permissions: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.shape({
      selectedSiteId: PropTypes.string,
    }),
  }),
};

export default compose(
  withPermissions([Permissions.CanReadSites, Permissions.CanReadDevices]),
  withRouter
)(DevicesConfiguration);
