import L from 'leaflet';

export const convertType = (type) => {
  switch (type) {
    case 'LineString':
      return 'corridor';
    case 'Polygon':
      return 'polygon';
    case 'Point':
      return 'circle';
    case 'Marker':
      return 'marker';
    default:
      return type;
  }
};

export const drawClusterableGeoJson = (items, layerGroup, popup) => {
  if (items) {
    items.forEach((item) => {
      const type = convertType(item.type);
      const factory = typeof L.Clusterable[type] === 'function' ? L.Clusterable[type] : L[type];

      factory(item.points || [item.lat, item.lng], {
        ...item.style,
        radius: item.radius || undefined,
        icon: item.icon && item.icon.html ? L.divIcon(item.icon) : L.icon(item.icon),
      })
        .bindPopup(popup, { closeButton: false })
        .addTo(layerGroup);
    });
  }
};
