import Check from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import React from 'react';

class Checkbox extends React.Component {
  onChange = () => {
    const value = !this.toBoolean(this.props.value);

    this.props.onChange({ target: { value: value, name: this.props.name } });
  };

  toBoolean = (value) => {
    return value === 'true' || value === true;
  };

  render() {
    const checkbox = (
      <Check
        onChange={this.onChange}
        checked={this.toBoolean(this.props.value)}
        value={this.props.value && this.props.value.toString()}
        name={this.props.name}
        color="primary"
        disabled={this.props.disabled || this.props.readOnly}
        className={this.props.innerClassName}
        id={`checkbox-${this.props.name}`}
      />
    );

    return this.props.label ? (
      <FormControlLabel className={this.props.className} control={checkbox} label={this.props.label} />
    ) : (
      checkbox
    );
  }
}

Checkbox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
};

Checkbox.defaultProps = {
  disabled: false,
  readOnly: false,
};

export default Checkbox;
