import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { Box, Typography, Accordion, AccordionDetails, AccordionSummary, AccordionActions } from '@material-ui/core';
import { useDrop } from 'react-dnd';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import DeleteCategoryDialog from './DeleteCategoryDialog';
import RenameCategoryDialog from './RenameCategoryDialog';

const GENERAL = 'General';

const LineFiller = styled('div')({
  height: 15,
  borderBottom: '1px solid LightGray',
  marginLeft: 20,
  flexGrow: 1,
});

const StyledAccordionDetails = styled(AccordionDetails)({
  alignItems: 'center',
  flexWrap: 'wrap',
});

const CategoryPreviewPanel = ({ category, isReadOnly, ...props }) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'BUTTON',
    drop: (item) => {
      if (item.categoryTempId !== category.tempId) {
        props.onMoveToCategory(item.categoryTempId, category.tempId, item.index);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  const onRenameCategory = (categoryName) => {
    props.onChange({ target: { name: 'name', value: categoryName } });
    setRenameDialogOpen(false);
  };

  return (
    <Box marginTop="5px" border="1px solid LightGray">
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box width="100%" display="flex">
            <Typography>
              {category.name === GENERAL ? T.translate(LocaleKeys.labels.general) : category.name}
            </Typography>
            <LineFiller />
          </Box>
        </AccordionSummary>
        <StyledAccordionDetails
          ref={drop}
          style={{
            backgroundColor: canDrop && (isOver ? '#f3f9f4' : '#ebf2f9'),
            border: canDrop && (isOver ? '2px dashed #14872d' : '2px dashed #3c74af'),
          }}
        >
          {props.children}
        </StyledAccordionDetails>
        <Divider />
        {!isReadOnly && (
          <AccordionActions>
            {category.name !== GENERAL && [
              <Button
                key={`delete-${category.tempId}`}
                size="small"
                onClick={() => {
                  setDeleteDialogOpen(true);
                }}
              >
                {T.translate(LocaleKeys.labels.deleteCategory)}
              </Button>,
              <Button
                key={`rename-${category.tempId}`}
                size="small"
                onClick={() => {
                  setRenameDialogOpen(true);
                }}
              >
                {T.translate(LocaleKeys.labels.renameCategory)}
              </Button>,
            ]}
            <Button key={`addButton-${category.tempId}`} size="small" color="primary" onClick={props.onAddButton}>
              {T.translate(LocaleKeys.labels.addButton)}
            </Button>
          </AccordionActions>
        )}
      </Accordion>
      <DeleteCategoryDialog
        open={deleteDialogOpen}
        onCancel={() => {
          setDeleteDialogOpen(false);
        }}
        onDeleteCategory={props.onDeleteCategory}
        onDeleteCategoryAndMoveButtons={props.onDeleteCategoryAndMoveButtons}
        categoryTempId={category && category.tempId}
        categories={props.categories}
      />
      <RenameCategoryDialog
        open={renameDialogOpen}
        onCancel={() => {
          setRenameDialogOpen(false);
        }}
        onRenameCategory={onRenameCategory}
        categoryTempId={category.tempId}
        categories={props.categories}
        categoryName={category.name}
      />
    </Box>
  );
};

CategoryPreviewPanel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))]),
  onDeleteCategory: PropTypes.func.isRequired,
  onDeleteCategoryAndMoveButtons: PropTypes.func.isRequired,
  onAddButton: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onMoveToCategory: PropTypes.func.isRequired,
  category: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  isReadOnly: PropTypes.bool,
};

export default CategoryPreviewPanel;
