import ApiClient from './ApiClient';
import { getSieveParams } from './utils';

const apiClient = new ApiClient();
const SERVICE_PATH = '/devicestore/api/';

const get = (path, params, config = {}) => apiClient.get(SERVICE_PATH + path, { params, ...config });
const post = (path, data, config = {}) => apiClient.post(SERVICE_PATH + path, data, config);
const put = (path, data, config = {}) => apiClient.put(SERVICE_PATH + path, data, config);
const deleteRequest = (path, config = {}) => apiClient.delete(SERVICE_PATH + path, null, config);

// Alarms
export const getAlarm = (id) => get(`alarms/${id}`);
export const getAlarmByName = (name) => get(`alarms/alarmbyname/${name}`);
export const deleteAlarm = (id) => deleteRequest(`alarms/${id}`);
export const getAlarmByExternalId = (externalId) => get(`alarms/byexternalid/${externalId}`);
export const getAlarms = (page, pageSize, sorts, filters, search, tenantId) => {
  return get(`alarms`, getSieveParams(page, pageSize, sorts, filters, search, tenantId));
};
export const postAlarm = (data) => post(`alarms`, data);
export const putAlarm = (id, data) => put(`alarms/${id}`, data);
export const getAlarmExistsByName = (alarmName) => get(`alarms/${alarmName}/exists`);

// Button Libraries
export const getButtonLibrary = (id, config = {}) => get(`buttonlibraries/${id}`, null, config);
export const getButtonLibraryByName = (name) => get(`buttonlibraries/byname/${name}`);
export const deleteButtonLibrary = (id, config = {}) => deleteRequest(`buttonlibraries/${id}`, config);
export const getButtonLibraries = (config = {}) => get(`buttonlibraries`, null, config);
export const postButtonLibrary = (data, config = {}) => post(`buttonlibraries`, data, config);
export const putButtonLibrary = (id, data, config = {}) => put(`buttonlibraries/${id}`, data, config);
export const getButtonLibraryExistsByName = (libraryName) => get(`buttonlibraries/${libraryName}/exists`);
export const getButtonLibraryByAssetId = (assetId, config = {}) =>
  get(`buttonlibraries/byassetid/${assetId}`, null, config);

// Device Groups
export const getDevicesGroups = () => get(`devicegroups`);
export const postDevicesGroup = (data) => post(`devicegroups`, data);
export const getDevicesGroup = (externalId) => get(`devicegroups/${externalId}`);
export const getDevicesGroupByName = (name) => get(`devicegroups/name/${name}`);
export const putDevicesGroup = (externalId, data) => put(`devicegroups/${externalId}`, data);
export const deleteDevicesGroup = (externalId) => deleteRequest(`devicegroups/${externalId}`);
export const getDevicesGroupExistsByName = (name) => get(`devicegroups/${name}/exists`);
export const putDevicesGroupsSubscribeToUser = (login, deviceGroupsExternalIds) =>
  put(`devicegroups/subscribeto/${login}`, deviceGroupsExternalIds);
export const getDevicesGroupsByLogin = (login) => get(`devicegroups/bylogin/${login}`);

// Device Libraries
export const postDeviceLibraryAssignFrom = (deviceId, libraryId, library, config = {}) =>
  post(`devices/${deviceId}/devicelibraries/${libraryId}/assignfrom`, library, config);
export const putDeviceLibrary = (deviceId, libraryId, library, config = {}) =>
  put(`devices/${deviceId}/devicelibraries/${libraryId}`, library, config);
export const getBrokenAlarmsForDevice = (deviceId, libraryId, config = {}) =>
  get(`devices/${deviceId}/devicelibraries/${libraryId}/brokenalarms`, null, config);
export const postBrokenAlarmsForDevice = (deviceId, libraryId, library, config = {}) =>
  post(`devices/${deviceId}/devicelibraries/${libraryId}/brokenalarms`, library, config);

// Devices
export const getDevices = (page, pageSize, sorts, filters, search, tenantId) => {
  return get(`devices`, getSieveParams(page, pageSize, sorts, filters, search, tenantId));
};
export const postDevice = (data) => post(`devices`, data);
export const getDeviceImeiHistory = (deviceId, config = {}) =>
  get(`devices/getimeihistoryby/${deviceId}`, null, config);
export const getDevice = (externalId, config = {}) => get(`devices/external/${externalId}`, null, config);
export const getDeviceByName = (name, config = {}) => get(`devices/devicebyname/${name}`, null, config);
export const putDevice = (id, data, config = {}) => put(`devices/${id}`, data, config);
export const deleteDevice = (id, config = {}) => deleteRequest(`devices/${id}`, config);
export const getDevicesDashboard = (config = {}) => get(`devices/dashboard`, null, config);
export const putDevicesSubscribeToSite = (deviceIds, siteId) => put(`devices/subscribetosite/${siteId}`, deviceIds);
export const putDevicesUnsubscribeFromSite = (deviceIds, siteId) =>
  put(`devices/unsubscribefromsite/${siteId}`, deviceIds);
export const getCanManageAllDevices = (tenantId) => get(`devices/canManageAll/${tenantId}`);
export const getDevicesBasicInformation = () => get(`devices/getdevicesbasicinformation`);
export const getDevicesBasicInformationBySite = (siteId) => get(`devices/basicinformationbysite/${siteId}`);
export const getDevicesPerLibrary = (queryParams) => get(`devices/devicesperlibrary`, queryParams);
export const postDevicesForMappings = (data) => post(`devices/devicesformappings`, data);
export const getDevicesForAlarm = (alarmId) => get(`devices/devicesForAlarm/${alarmId}`);
export const getDevicesForSites = (siteId) => get(`devices/getforsites/${siteId}`);
export const getDevicesBySite = (siteId) => get(`devices/bysite/${siteId}`);
export const getDeviceExistsByName = (deviceName) => get(`devices/${deviceName}/exists`);
export const getDeviceExistsByImei = (imei) => get(`devices/imei/${imei}/exists`);
export const getDeviceExistsByDeviceCode = (code) => get(`devices/devicecode/${code}/exists`);
export const getDeviceAffectedAlarms = (id, config = {}) => get(`devices/${id}/affectedalarms`, null, config);

// Dictionaries
export const getIdentyficationModes = () => get(`dictionaries/identificationmode`);
export const getServiceMeterModes = () => get(`dictionaries/servicemetermode`);
export const getDistanceMeterModes = () => get(`dictionaries/distancemetermode`);
export const getAnalogSources = () => get(`dictionaries/analogsource`);
export const getDigitalSources = () => get(`dictionaries/digitalsource`);
export const getDaysOfWeek = () => get(`dictionaries/daysofweek`);
export const getEventKeywordVariables = () => get(`dictionaries/eventkeywordvariable`);
export const getMapAreaVariables = () => get(`dictionaries/mapareavariable`);
export const getMetricVariables = () => get(`dictionaries/metricvariable`);
export const getDayVariable = () => get(`dictionaries/dayvariable`);
export const getTimePeriodVariables = () => get(`dictionaries/timeperiodvariable`);
export const getSystemMappingTypes = () => get(`dictionaries/systemmappingtype`);
export const getVariableTypes = () => get(`dictionaries/variabletype`);
export const getInputMappingTypes = () => get(`dictionaries/inputmappingtype`);
export const getOccurrenceTypes = () => get(`dictionaries/occurrencetype`);
export const getAlarmTypes = () => get(`dictionaries/alarmtype`);
export const getFormulaTypes = () => get(`dictionaries/formulatype`);
export const getValidityTypes = () => get(`dictionaries/validitytype`);
export const getResolveAlarmModes = () => get(`dictionaries/resolvealarmmode`);
export const getDigitalMappingStates = () => get(`dictionaries/digitalmappingstate`);
export const getAlarmStatusTypes = () => get(`dictionaries/alarmstatus`);
export const getVehicleRunningTypes = (id) => get(`dictionaries/vehiclerunningtype/${id}`);
export const getServiceStatuses = () => get(`dictionaries/servicestatus`);
export const getTcpIpStatuses = () => get(`dictionaries/tcpipstatustype`);

// Drivers
export const getDrivers = (config = {}) => get(`drivers`, null, config);
export const getDriver = (id) => get(`drivers/${id}`);
export const getDriverMappings = (id) => get(`drivers/${id}/mappings`);
export const getDriverMappingsInfo = (id, config = {}) => get(`drivers/${id}/mappingsinfo`, null, config);

// Libraries
export const getDriverLibraries = (driverId) => get(`drivers/${driverId}/libraries`);
export const postDriverLibrary = (driverId, data) => post(`drivers/${driverId}/libraries`, data);
export const getDriverLibrary = (driverId, id, config = {}) => get(`drivers/${driverId}/libraries/${id}`, null, config);
export const putDriverLibrary = (driverId, id, data) => put(`drivers/${driverId}/libraries/${id}`, data);
export const deleteDriverLibrary = (driverId, id) => deleteRequest(`drivers/${driverId}/libraries/${id}`);
export const getDriverLibraryExistsByName = (driverId, libraryName) =>
  get(`drivers/${driverId}/libraries/${libraryName}/exists`);

// Library Collections
export const getLibraries = () => get(`libraries`);
export const getLibrary = (id) => get(`libraries/${id}`);
export const getLibrariesByDevice = (deviceId, config = {}) =>
  get(`libraries/librariesbydevice/${deviceId}`, null, config);
export const getLibraryByName = (name) => get(`libraries/byname/${name}`);
export const getLibraryMappingsProjection = (externalIds) => get(`libraries/mappingsprojection/${externalIds}`);
export const postLibraryForAlarm = (data) => post(`libraries/libraryforalarm`, data);
export const deleteLibraryForAlarm = (id) => deleteRequest(`libraries/libraryforalarm/${id}`);
export const getLibraryTemplates = (config = {}) => get(`libraries/templates`, null, config);

// Resources
export const getResources = () => get(`resources`);
export const postResource = (data, config = {}) => post(`resources`, data, config);
export const getResource = (id, config = {}) => get(`resources/${id}`, null, config);

// System Mappings
export const getTimeRelatedMappings = () => get(`systemmappings/timerelated`);
export const getCalendarsMappingsUsedByBRE = (id) => get(`systemmappings/calendars/${id}/usedbybre`);
export const getMapAreasMappingsUsedByBRE = (id) => get(`systemmappings/mapareas/${id}/usedbybre`);
