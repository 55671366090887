import React, { useState, useEffect } from 'react';
import T from 'i18n-react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import SortableTree, {
  insertNode,
  removeNode,
  changeNodeAtPath,
  getVisibleNodeCount,
} from '@nosferatu500/react-sortable-tree';
import LocaleKeys from 'Localization/LocaleKeys';
import {
  Paper,
  Box,
  TextField,
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  Link,
  IconButton,
  FormHelperText,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { styled } from '@material-ui/core/styles';
import Button from 'Components/Button';
import { Formik } from 'formik';
import { TextField as TextFieldFormik } from 'Components/formik/fields';
import SupportedLanguages from 'Localization/SupportedLanguages';
import SaveOnLeave from 'Components/dialogs/SaveOnLeave';
import { clone, compareObjects } from 'Helpers/ObjectHelper';
import PopupDialog from '../PopupDialog';
import '@nosferatu500/react-sortable-tree/style.css';

const FormLabelStyled = styled(FormLabel)({
  marginBottom: 20,
  textTransform: 'uppercase',
});
const ButtonStyled = styled(Button)({
  float: 'right',
  marginLeft: '10px',
});

export const View = ({ initialData, users, onSubmit, canSaveChanges, validationSchema }) => {
  const history = useHistory();
  const [data, setData] = useState(clone(initialData));
  const [usersFilter, setUsersFilter] = useState('');
  const [editLang, setEditLang] = useState(null);
  const [treeData, setTreeData] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [tempFormValues, setTempFormValues] = useState(null);
  const [editNodeDetails, setEditNodeDetails] = useState(null);
  const availableUsers = users.filter((u) => !u.isAssigned || data.users.includes(u.login));

  useEffect(() => {
    if (data.items[editLang]) {
      setTreeData(data.items[editLang]);
    }
    // eslint-disable-next-line
  }, [editLang]);

  const handleAddCategory = (name) => {
    const updatedTree = insertNode({
      treeData,
      depth: 0,
      minimumTreeIndex: 0,
      newNode: { title: name, children: [] },
    }).treeData;

    updateTreeData(updatedTree, tempFormValues);
  };

  const handleRemoveNode = (rowInfo, formValues) => {
    const updatedTree = removeNode({
      treeData,
      path: rowInfo.path,
      getNodeKey: (node) => node.treeIndex,
    }).treeData;

    updateTreeData(updatedTree, formValues);
  };

  const handleEditNode = (rowInfo, formValues) => {
    setEditNodeDetails(rowInfo);
    setTempFormValues(formValues);
    setIsPopupVisible(true);
  };

  const editNode = (name) => {
    const updatedTree = changeNodeAtPath({
      treeData,
      path: editNodeDetails.path,
      newNode: { ...editNodeDetails.node, title: name },
      getNodeKey: (node) => node.treeIndex,
    });

    setEditNodeDetails(null);
    updateTreeData(updatedTree, tempFormValues);
  };

  const updateTreeData = (updatedTree, formValues) => {
    setTreeData(updatedTree);
    const updatedFormValues = { ...formValues };

    updatedFormValues.items[editLang] = updatedTree;
    setData(updatedFormValues);
  };

  const renderButtons = (rowInfo, values) => {
    const buttons = [
      <IconButton key="edit" type="button" onClick={() => handleEditNode(rowInfo, values)}>
        <EditIcon />
      </IconButton>,
    ];

    if (!rowInfo.node.page && !rowInfo.node.children.length) {
      buttons.push(
        <IconButton key="remove" type="button" onClick={() => handleRemoveNode(rowInfo, values)}>
          <DeleteIcon />
        </IconButton>
      );
    }

    return { buttons };
  };

  const nodeCount = treeData ? getVisibleNodeCount({ treeData }) : 0;

  return (
    <Formik
      initialValues={data}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
    >
      {({ values, errors, isValid, isValidating, isSubmitting, setFieldValue, handleSubmit }) => {
        const dirty = !compareObjects(initialData, values);

        return (
          <form onSubmit={handleSubmit}>
            <Paper>
              <Box padding={2}>
                <TextFieldFormik
                  name="name"
                  variant="outlined"
                  label={T.translate('templateName')}
                  helperText={T.translate(errors.name)}
                />
              </Box>
              <Box padding={2}>
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <FormControl error={errors?.languages}>
                      <FormLabelStyled>{T.translate(LocaleKeys.labels.languages)}</FormLabelStyled>
                      <FormGroup>
                        {Object.entries(SupportedLanguages.names).map(([key, name]) => (
                          <FormControlLabel
                            key={key}
                            control={<Checkbox />}
                            label={T.translate(name)}
                            checked={values.languages.includes(key)}
                            onChange={(e) => {
                              if (editLang === key) {
                                setEditLang(null);
                              }
                              if (e.target.checked) {
                                setFieldValue('languages', [...values.languages, key]);
                              } else {
                                setFieldValue(
                                  'languages',
                                  values.languages.filter((l) => l !== key)
                                );
                              }
                            }}
                          />
                        ))}
                      </FormGroup>
                      <FormHelperText>
                        {errors?.languages && T.translate(LocaleKeys.validation[errors.languages])}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <FormLabelStyled>{T.translate(LocaleKeys.labels.assignToUser)}</FormLabelStyled>

                      <TextField
                        variant="outlined"
                        value={usersFilter}
                        size="small"
                        onChange={(e) => {
                          setUsersFilter(e.target.value);
                        }}
                        style={{
                          marginBottom: 20,
                        }}
                      />
                      <FormGroup>
                        {usersFilter === '' && availableUsers.length > 0 && (
                          <FormControlLabel
                            control={<Checkbox />}
                            label={T.translate('allUsers')}
                            checked={!availableUsers.filter((u) => !values.users.includes(u.login)).length}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setFieldValue(
                                  'users',
                                  availableUsers.map((user) => user.login)
                                );
                              } else {
                                setFieldValue('users', []);
                              }
                            }}
                            disabled={!availableUsers.length}
                          />
                        )}
                        {users
                          .filter((user) => user.login.toLowerCase().includes(usersFilter.toLowerCase()))
                          .map(({ login, isAssigned }) => (
                            <FormControlLabel
                              key={login}
                              control={<Checkbox />}
                              label={login}
                              disabled={initialData.users.includes(login) ? false : isAssigned}
                              checked={values.users.includes(login)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setFieldValue('users', [...values.users, login]);
                                } else {
                                  setFieldValue(
                                    'users',
                                    values.users.filter((n) => n !== login)
                                  );
                                }
                              }}
                            />
                          ))}
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={8} style={{ paddingBottom: '100px' }}>
                    <Grid container spacing={1} justify="space-between">
                      <Grid item xs>
                        <FormLabelStyled>{T.translate(LocaleKeys.labels.menu)}</FormLabelStyled>
                      </Grid>
                      <Grid item xs>
                        {editLang && (
                          <Link
                            onClick={() => {
                              setEditNodeDetails(null);
                              setTempFormValues(values);
                              setIsPopupVisible(true);
                            }}
                          >
                            + {T.translate(LocaleKeys.labels.addCategory)}
                          </Link>
                        )}
                      </Grid>
                      <Grid item xs>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          style={{
                            marginTop: -16,
                          }}
                        >
                          <InputLabel>{T.translate(LocaleKeys.labels.language)}</InputLabel>
                          <Select
                            label={T.translate(LocaleKeys.labels.languages)}
                            value={editLang || ''}
                            onChange={(e) => {
                              const lang = e.target.value;

                              if (!values.items[lang]) {
                                setFieldValue('items', {
                                  ...values.items,
                                  [lang]: Object.values(values.items)[0],
                                });
                              }
                              setEditLang(lang);
                            }}
                          >
                            {Object.entries(SupportedLanguages.names)
                              .filter(([key]) => values.languages.includes(key))
                              .map(([key, name]) => (
                                <MenuItem key={key} value={key}>
                                  {T.translate(name)}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    {values.items[editLang] && treeData && (
                      <SortableTree
                        style={{ height: nodeCount * 70 }}
                        canDrop={(node) => (node.node.page ? !!node.nextParent : !node.nextParent)}
                        canNodeHaveChildren={(node) => !node.page}
                        maxDepth={2}
                        onChange={(data) => {
                          updateTreeData(data, values);
                          setFieldValue('items', {
                            ...values.items,
                            [editLang]: data,
                          });
                        }}
                        treeData={treeData}
                        generateNodeProps={(rowInfo) => renderButtons(rowInfo, values)}
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Paper>

            <Box mt={2}>
              <ButtonStyled type="button" onClick={() => history.goBack()}>
                {T.translate(LocaleKeys.labels.cancel)}
              </ButtonStyled>
              {canSaveChanges && (
                <ButtonStyled type="submit" disabled={!(dirty && isValid) || isValidating || isSubmitting}>
                  {T.translate(LocaleKeys.labels.save)}
                </ButtonStyled>
              )}
            </Box>
            {isPopupVisible && (
              <PopupDialog
                open={isPopupVisible}
                onCancel={() => setIsPopupVisible(false)}
                handleAddCategory={handleAddCategory}
                editName={editNodeDetails?.node.title}
                handleEditItem={editNode}
                treeData={treeData}
              />
            )}
            <SaveOnLeave dataChanged={dirty} saveData={() => onSubmit(values)} validForm={isValid} />
          </form>
        );
      }}
    </Formik>
  );
};

View.defaultProps = {
  initialData: null,
};

View.propTypes = {
  canSaveChanges: PropTypes.bool,
  initialData: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  validationSchema: PropTypes.object.isRequired,
};
