import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { connect } from 'react-redux';
import { HttpTransportType, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import Config from 'Config';
import AuthManager from 'Auth/AuthManager';
import { update } from 'redux/assets/actions';
import PermissionManager from 'Auth/PermissionManager';
import { Permissions } from 'Constants/permissions';

const SignalRContext = React.createContext();

const URL = `${Config.apiDefaultURL}/notificationservice/hub/notifications`;

const captureMessage = (message, captureContext) => {
  Sentry.captureMessage(message, {
    ...captureContext,
    tags: { library: 'signalr', ...captureContext.tags },
    extra: { url: URL, ...captureContext.extra },
  });
};

const Provider = ({ currentSiteId, login, update, children }) => {
  const [hubConnection, setConnection] = useState(null);
  const prevState = useRef({
    currentSiteId,
    login,
  });

  useEffect(() => {
    prevState.current.currentSiteId = currentSiteId;
    prevState.current.login = login;
  }, [currentSiteId, login]);

  const handleOnAlarm = useCallback(
    (newNotification) => {
      const {
        assetId,
        alarmName,
        createdOn,
        alarmType,
        alarmImageId,
        assetIconId,
        assetColor,
        isAlarmResolved,
        uniqueId,
        isActive,
        alarmStatus,
      } = newNotification;

      update({
        externalId: assetId,
        device: {
          lastMessage: {
            createdAt: createdOn,
            message: alarmName,
            alarmType,
          },
          lastAlarm: {
            assetIconId,
            alarmImageId,
            isAlarmResolved,
            assetColor,
            uniqueId,
            isActive,
            alarmStatus,
            createdOn,
            alarmName,
            alarmType,
          },
        },
      });
    },
    [update]
  );

  useEffect(() => {
    if (currentSiteId) {
      const hubConnection = new HubConnectionBuilder()
        .withUrl(URL, {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
          accessTokenFactory: () => AuthManager.getUser().access_token,
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Warning)
        .build();

      hubConnection.onclose((error) => {
        if (error) {
          captureMessage('hubConnection onclose error', {
            extra: {
              message: error.message,
              callback: 'onclose',
            },
          });
        }
      });

      hubConnection.onreconnected(() => {
        hubConnection.invoke('SubscribeToSite', prevState.current.currentSiteId).catch((error) => {
          captureMessage('hubConnection.invoke("SubscribeToSite") error', {
            extra: {
              message: error.message,
              callback: 'onreconnected',
              currentSiteId: prevState.current.currentSiteId,
            },
          });
        });
        hubConnection
          .invoke('SubscribeToUser', prevState.current.currentSiteId, prevState.current.login)
          .catch((error) => {
            captureMessage('hubConnection.invoke("SubscribeToUser") error', {
              extra: {
                message: error.message,
                callback: 'onreconnected',
                currentSiteId: prevState.current.currentSiteId,
              },
            });
          });
      });

      hubConnection
        .start()
        .then(() => {
          hubConnection.on('Alarm', handleOnAlarm);
          hubConnection.on('Alarms', ({ alarms }) => {
            const assetsAlarmMap = new Map();

            alarms.forEach((alarm) => {
              if (
                !assetsAlarmMap.has(alarm.assetId) ||
                +new Date(alarm.createdOn) > +new Date(assetsAlarmMap.get(alarm.assetId).createdOn)
              ) {
                assetsAlarmMap.set(alarm.assetId, alarm);
              }
            });
            Array.from(assetsAlarmMap.values()).forEach((alarm) => {
              handleOnAlarm(alarm);
            });
          });
          setConnection(hubConnection);
        })
        .catch((error) => {
          captureMessage('hubConnection.start()', {
            extra: { message: error.message, currentSiteId },
          });
        });
    }

    return () => {
      if (hubConnection) {
        hubConnection.stop().catch((error) => {
          captureMessage('hubConnection.stop()', {
            extra: { message: error.message, currentSiteId },
          });
        });
      }
    };
  }, [handleOnAlarm, currentSiteId]);

  useEffect(() => {
    const canReadAlarmNotifications = PermissionManager.hasPermissionFromCache(Permissions.CanReadAlarmNotifications);

    if (currentSiteId && hubConnection?.connectionState === HubConnectionState.Connected && canReadAlarmNotifications) {
      hubConnection.invoke('SubscribeToSite', currentSiteId).catch((error) => {
        captureMessage('hubConnection.invoke("SubscribeToSite") error', {
          extra: {
            message: error.message,
            callback: 'useEffect',
            currentSiteId,
          },
        });
      });
      hubConnection.invoke('SubscribeToUser', currentSiteId, login).catch((error) => {
        captureMessage('hubConnection.invoke("SubscribeToUser") error', {
          extra: {
            message: error.message,
            callback: 'useEffect',
            currentSiteId,
          },
        });
      });
    }

    return () => {
      if (currentSiteId && hubConnection?.connectionState === HubConnectionState.Connected) {
        hubConnection.invoke('UnsubscribeFromSite', currentSiteId).catch((error) => {
          captureMessage('hubConnection.invoke("UnsubscribeFromSite") error', {
            extra: {
              message: error.message,
              callback: 'useEffect return',
              currentSiteId,
            },
          });
        });
        hubConnection.invoke('UnsubscribeFromUser', currentSiteId, login).catch((error) => {
          captureMessage('hubConnection.invoke("UnsubscribeFromUser") error', {
            extra: {
              message: error.message,
              callback: 'useEffect return',
              currentSiteId,
            },
          });
        });
      }
    };
  }, [hubConnection, currentSiteId, login]);

  return (
    <SignalRContext.Provider
      value={{
        hub: hubConnection,
        invoke: (...args) => {
          if (hubConnection?.connection?.connectionState === HubConnectionState.Connected) {
            return hubConnection.invoke(...args).catch((error) => {
              captureMessage(`hubConnection.invoke(${args[0]}) error`, {
                extra: {
                  message: error.message,
                  callback: 'invoke',
                  args,
                },
              });
            });
          }

          return Promise.resolve();
        },
      }}
    >
      {children}
    </SignalRContext.Provider>
  );
};

const mapState = (state) => ({
  currentSiteId: state.sites.currentSiteId,
  login: state.user.data.login,
});
const mapDispatch = {
  update,
};

export const SignalRProvider = connect(mapState, mapDispatch)(Provider);

export const withSignalR = (Component) => {
  const ComponentWithSignalR = (props, ref) => (
    <SignalRContext.Consumer>
      {(signalRProps) => <Component {...signalRProps} {...props} ref={ref} />}
    </SignalRContext.Consumer>
  );

  return React.forwardRef(ComponentWithSignalR);
};
