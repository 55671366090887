import React, { useState, useContext, useMemo } from 'react';
import LeafletMap from 'Components/LeafletMap';
import { withConfigurationStoreLayers } from 'Components/LeafletMap/hocs/withConfigurationStoreLayers';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDrag, useDrop } from 'react-dnd';
import DeviceContext from './DeviceContext';
import { useConfigurationStore } from 'hooks/useConfigurationStore';
import ApiIconMarker from 'Components/ApiIconMarker';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import AssetNames from 'Components/LeafletMap/controls/AssetNames';

const LeafletMapWithConfigurationStore = withConfigurationStoreLayers(LeafletMap);

const MiniMap = ({ classes, id, index, removeMap, allDevices }) => {
  const [assetIds, setAssetIds, resetAssetsIds] = useConfigurationStore(`Minimap-${id}`, []);
  const [showAssetNames, setShowAssetNames] = useConfigurationStore(`Minimap-${id}-showAssetNames`, false);
  const [center, setCenter] = useState([51.505, -0.09]);
  const { draggedDevice } = useContext(DeviceContext);

  // Keeping this for future
  const [, drag] = useDrag({
    item: { type: 'minimap' },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: 'dashboard',
    drop: (e) => dropOnMiniMap(e),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const filteredDevices = useMemo(
    () =>
      allDevices.filter((device) => {
        if (!assetIds.includes(device.externalId)) {
          return false;
        }
        if (!device.position) {
          return false;
        }

        return true;
      }),
    [allDevices, assetIds]
  );

  const dropOnMiniMap = (e) => {
    setAssetIds([...assetIds, e.device.externalId]);
    setCenter({
      lat: e.device.position.latitude,
      lng: e.device.position.longitude,
    });
  };

  const resetMap = () => {
    resetAssetsIds();
  };

  const onMouseUp = (_) => {
    draggedDevice && dropOnMiniMap({ device: draggedDevice });
  };

  return (
    <div ref={drop} className={classes.minimap}>
      <div ref={drag} className={classes.mapHeader}>
        Map {index + 1}
        <div>
          {assetIds.length > 0 && (
            <IconButton
              aria-label="refresh"
              className={classes.clear}
              size="small"
              onClick={resetMap}
              id={`minimap-${index}-refresh`}
            >
              <DeleteIcon />
            </IconButton>
          )}
          <IconButton
            aria-label="delete"
            className={classes.clear}
            size="small"
            onClick={() => removeMap(id)}
            id={`minimap-${index}-delete`}
          >
            <ClearIcon />
          </IconButton>
        </div>
      </div>
      <div style={{ height: '100%', width: '100%', position: 'relative' }}>
        <LeafletMapWithConfigurationStore
          configurationStoreKey={`Minimap-${id}-layers`}
          className={classes.map}
          center={center}
          onMouseUp={onMouseUp}
          enableFitBounds={false}
          fitBoundsProp={JSON.stringify(filteredDevices)}
          controls={[
            {
              element: AssetNames,
              position: { bottom: 100, right: 10 },
              props: {
                value: showAssetNames,
                onClick: setShowAssetNames,
              },
            },
          ]}
        >
          <MarkerClusterGroup>
            {filteredDevices.map((device) => (
              <ApiIconMarker
                key={device.externalId}
                position={device.position}
                iconId={device.iconId}
                color={device.color}
                tooltipText={device.name}
                isAssetMarker
              />
            ))}
          </MarkerClusterGroup>
        </LeafletMapWithConfigurationStore>
      </div>
    </div>
  );
};

export default MiniMap;
