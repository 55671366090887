import React from 'react';
import LocaleKeys from 'Localization/LocaleKeys';
import ExpansionSection from 'Components/display/ExpansionPanel/ExpansionSection';
import SystemNotifications from './SystemNotifications';
import EmailNotifications from './EmailNotifications';
import Sound from './Sound';

const NotificationSettings = (props) => (
  <ExpansionSection>
    <SystemNotifications label={LocaleKeys.labels.systemNotification} />
    <EmailNotifications label={LocaleKeys.labels.emailNotifications} />
    <Sound label={LocaleKeys.labels.sound} />
  </ExpansionSection>
);

export default NotificationSettings;
