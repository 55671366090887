/**
 * @class L.Edit.TextMarker
 * @aka Edit.TextMarker
 */
L.Edit.TextMarker = L.Edit.Marker.extend({
  // @method initialize(): void
  initialize: function (marker, options) {
    this._marker = marker;
    L.setOptions(this, options);
  },
});

L.Marker.addInitHook(function () {
  if (L.Edit.TextMarker) {
    this.editing = new L.Edit.TextMarker(this);

    if (this.options.editable) {
      this.editing.enable();
    }
  }
});
