import React from 'react';
import { connect } from 'react-redux';
import T from 'i18n-react';
import Message from 'Components/Message';
import LocaleKeys from 'Localization/LocaleKeys';

const hasPermission = (permissions, code) => {
  const perm = permissions.find((x) => x.code === code);

  return perm && perm.value === 'true';
};

const withPermissions = (requiredCodes) => (Component) => {
  const WithPermissions = (props) => {
    const { permissions } = props;
    const missingPermissions = requiredCodes.filter((code) => !hasPermission(permissions, code));

    if (missingPermissions.length) {
      return (
        <Message
          text={
            <>
              <h2>{T.translate(LocaleKeys.messages.requiredPermission)}:</h2>
              {missingPermissions.map((permission) => (
                <p key={permission}>{T.translate(permission)}</p>
              ))}
            </>
          }
        />
      );
    }

    const permissionsPropEntries = permissions.map((permission) => [permission.code, permission.value === 'true']);
    const permissionsProp = Object.fromEntries(permissionsPropEntries);

    return <Component {...props} permissions={permissionsProp} />;
  };
  const mapStateToProps = (state) => ({
    permissions: state.app.permissions,
  });

  return connect(mapStateToProps)(WithPermissions);
};

export default withPermissions;
