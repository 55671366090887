import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import { withFormik } from 'Components/formik/formikWrappers';
import { Checkbox, TextField } from 'Components/formik/fields';

const Passwords = (props) => {
  return (
    <ColumnDisplayer>
      <Checkbox
        name={'securityRule.isForcePasswordChange'}
        label={T.translate(LocaleKeys.labels.forcePasswordChange)}
      />
      {props.data.securityRule && props.data.securityRule.isForcePasswordChange && (
        <TextField
          label={T.translate(LocaleKeys.labels.passwordChangeDuration)}
          name={'securityRule.passwordChangeDuration'}
          type={'number'}
        />
      )}
      {props.data.securityRule && props.data.securityRule.isForcePasswordChange && (
        <TextField
          label={T.translate(LocaleKeys.labels.reminderDuration)}
          name={'securityRule.reminderDuration'}
          type={'number'}
        />
      )}
      <TextField
        label={T.translate(LocaleKeys.labels.minimumPasswordLength)}
        name={'securityRule.minPasswordLength'}
        type={'number'}
      />
      <TextField
        label={T.translate(LocaleKeys.labels.minimumNumberOfLowercase)}
        name={'securityRule.minNumberOfLowercase'}
        type={'number'}
      />
      <TextField
        label={T.translate(LocaleKeys.labels.minimumNumberOfUppercase)}
        name={'securityRule.minNumberOfUppercase'}
        type={'number'}
      />
      <TextField
        label={T.translate(LocaleKeys.labels.minimumNumberOfDigits)}
        name={'securityRule.minNumberOfDigits'}
        type={'number'}
      />
      <TextField
        label={T.translate(LocaleKeys.labels.minimumNumberOfSpecialCharacters)}
        name={'securityRule.minNumberOfSpecialCharacters'}
        type={'number'}
      />
      <TextField
        label={T.translate(LocaleKeys.labels.numberOfDaysWithoutRepetition)}
        name={'securityRule.daysOfNotRepeatPassword'}
        type={'number'}
      />
      <Checkbox
        name={'securityRule.isBlockAccount'}
        label={T.translate(LocaleKeys.labels.blockAccount)}
        color="primary"
      />
      {props.data.securityRule && props.data.securityRule.isBlockAccount && (
        <TextField
          label={T.translate(LocaleKeys.labels.numberOfTrials)}
          name={'securityRule.numberOfTrials'}
          type={'number'}
        />
      )}
      {props.data.securityRule && props.data.securityRule.isBlockAccount && (
        <TextField label={T.translate(LocaleKeys.labels.policyBreachEmail)} name={'securityRule.policyBreachEmail'} />
      )}
    </ColumnDisplayer>
  );
};

Passwords.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
};

export default withFormik(Passwords);
