import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { Permissions } from 'Constants/permissions';
import { compose } from 'redux';
import withPermissions from 'hocs/withPermissions';
import { getDevicesGroups, getDevicesBasicInformation } from 'Api/devices';
import ListInput from 'Components/inputs/ListInput';
import { Box, ListItem, ListItemIcon, Checkbox, ListItemText, IconButton, CircularProgress } from '@material-ui/core';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import { withRouter } from 'react-router-dom';
import { Visibility } from '@material-ui/icons';
import { useEnhancedFormContext } from 'Components/formik/formikWrappers';

const Container = styled(Box)({
  height: 'calc(100vh - 240px)',
  display: 'flex',
  overflow: 'hidden',
  margin: 10,
});

const getDeviceGroupRow = ({ style, data, index }) => {
  const { items, handleToggle, checked, readOnly, displayField, valueField, onCustomRowClick, selectedForPreview } =
    data;
  const item = items[index];

  return (
    <ListItem
      key={item[valueField]}
      style={style}
      dense
      button
      onClick={() => handleToggle(item[valueField])}
      disabled={readOnly}
    >
      <ListItemIcon>
        <Checkbox edge="start" checked={checked?.includes(item[valueField])} tabIndex={-1} disableRipple />
      </ListItemIcon>
      <ListItemText primary={item[displayField]} />
      <ListItemIcon>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onCustomRowClick(item[valueField]);
          }}
        >
          <Visibility color={selectedForPreview === item[valueField] ? 'primary' : 'action'} />
        </IconButton>
      </ListItemIcon>
    </ListItem>
  );
};

const getDeviceRow = ({ style, data, index }) => {
  const { items, displayField, valueField } = data;
  const item = items[index];

  return (
    <ListItem key={item[valueField]} style={style} button dense>
      <ListItemText primary={item[displayField]} />
    </ListItem>
  );
};

const ResourceLimitations = (props) => {
  const [deviceGroups, setDeviceGroups] = useState(null);
  const [devices, setDevices] = useState(null);
  const [previewGroup, setPreviewGroup] = useState(null);
  const { canSaveChanges } = useEnhancedFormContext();

  useEffect(() => {
    getDevicesGroups().then(({ data }) => {
      setDeviceGroups(data.deviceGroups);
    });
    getDevicesBasicInformation().then(({ data }) => {
      setDevices(data);
    });
  }, []);

  const customListItemDataFields = useMemo(
    () => ({
      selectedForPreview: previewGroup?.externalId,
    }),
    [previewGroup]
  );

  if (!deviceGroups || !devices) {
    return <CircularProgress />;
  }

  const canSaveDevicesGroup = props.permissions[Permissions.CanEditDevicesGroup];
  const isReadOnly = !canSaveChanges || !canSaveDevicesGroup;
  const devicesForPreview = previewGroup
    ? devices.filter((device) => previewGroup.devices.includes(device.externalId))
    : [];

  return (
    <Container>
      <Box height={'100%'} width={'50%'}>
        <ListInput
          selected={props.selectedDevicesGroups}
          items={deviceGroups}
          onChange={(selected) => {
            if (!isReadOnly) {
              props.setSelectedDevicesGroups(selected);
            }
          }}
          valueField="externalId"
          displayField="name"
          label={T.translate(LocaleKeys.labels.devicesGroups).toUpperCase()}
          renderCustomRow={getDeviceGroupRow}
          onCustomRowClick={(externalId) => {
            if (previewGroup?.externalId === externalId) {
              setPreviewGroup(null);
            } else {
              setPreviewGroup(deviceGroups.find((group) => group.externalId === externalId));
            }
          }}
          customListItemDataFields={customListItemDataFields}
          minSelected={0}
        />
      </Box>
      <Box borderLeft={'1px solid lightgrey'} paddingLeft={1}>
        <ListInput
          selected={[]}
          items={devicesForPreview}
          onChange={() => {}}
          valueField="externalId"
          displayField="name"
          label={T.translate(LocaleKeys.labels.devices).toUpperCase()}
          renderCustomRow={getDeviceRow}
          minSelected={0}
          showSelectAll={false}
          width={'350px'}
        />
      </Box>
    </Container>
  );
};

ResourceLimitations.propTypes = {
  selectedDevicesGroups: PropTypes.array,
  setSelectedDevicesGroups: PropTypes.func,
  permissions: PropTypes.object,
};

export default compose(
  withPermissions([Permissions.CanReadDevicesGroups, Permissions.CanReadDevices]),
  withRouter
)(ResourceLimitations);
