import ExpansionPanel from './ExpansionPanel';
import ExpansionPanelDetails from './ExpansionPanelDetails';
import ExpansionPanelSummary from './ExpansionPanelSummary';
import PropTypes from 'prop-types';
import React from 'react';
import T from 'i18n-react';
import Typography from '@material-ui/core/Typography';

class ExpansionSection extends React.Component {
  render() {
    const children = this.props.children instanceof Array ? this.props.children : [this.props.children];

    return (
      <div>
        {children.map((child) => {
          return (
            child && (
              <ExpansionPanel key={child.props.label} id={`expansionPanel-${child.props.label}`}>
                <ExpansionPanelSummary id={`expansionPanelHeader-${child.props.label}`}>
                  <Typography>{T.translate(child.props.label)}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails id={`expansionPanelContent-${child.props.label}`}>
                  {React.cloneElement(child, {
                    onChange: this.props.onChange,
                    data: this.props.data,
                  })}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )
          );
        })}
      </div>
    );
  }
}

ExpansionSection.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  ]),
};

export default ExpansionSection;
