import React, { Component } from 'react';
import { connect } from 'react-redux';

import UserMenu from './userInfoContainer/UserMenu';
import { getUser } from 'redux/user/selectors';
import SiteNotifications from './userInfoContainer/SiteNotifications';
import PermissionManager from 'Auth/PermissionManager';
import { Permissions } from 'Constants/permissions';

class UserInfoContainer extends Component {
  render() {
    const { user } = this.props;
    const canAccessSystemNotificationFeed = PermissionManager.hasPermissionFromCache(
      Permissions.CanReadSystemNotifications
    );

    return (
      <div className="user-info-container">
        {canAccessSystemNotificationFeed && <SiteNotifications />}
        <UserMenu userInfo={user} />
      </div>
    );
  }
}
const mapState = (state) => ({
  user: getUser(state),
});

export default connect(mapState)(UserInfoContainer);
