import React from 'react';
import PropTypes from 'prop-types';
import LocaleKeys from 'Localization/LocaleKeys';
import CommonInfo from './general/CommonInfo';
import ExpansionSection from 'Components/display/ExpansionPanel/ExpansionSection';
import FrameRetention from './general/FrameRetention';
import Graphics from './general/Graphics';
import MessagingStatus from './general/MessagingStatus';
import { withFormik } from 'Components/formik/formikWrappers';

const General = (props) => (
  <ExpansionSection data={props.data} onChange={props.onChange}>
    <CommonInfo label={LocaleKeys.labels.commonInfo} />
    <Graphics label={LocaleKeys.labels.graphics} />
    <MessagingStatus label={LocaleKeys.labels.messagingStatus} />
    <FrameRetention label={LocaleKeys.labels.frameRetention} />
  </ExpansionSection>
);

General.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
};

export default withFormik(General);
