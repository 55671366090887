import * as Yup from 'yup';
import { AlarmValidityTypes } from 'Constants/AlarmValidityTypes';
import { AlarmOccurrenceTypes } from 'Constants/AlarmOccurrenceTypes';
import { InputMappingTypes } from 'Constants/InputMappingTypes';
import { VariableTypes } from 'Constants/VariableTypes';
import { isDateCorrect, isNotEmpty } from 'Validators/SharedValidators';
import { operators } from './consts';
import { convertTimePeriodToSeconds } from './helpers';
import { getAlarmExistsByName } from 'Api/devices';

const alarmNameSchema = Yup.string()
  .required('thisFieldIsRequired')
  .max(50, 'nameCannotExceed50Characters')
  .alphanumericOrSpecialSymbol([' ']);

export const alarmNameExist = async (value, initialValue, validationCache) => {
  if (!(await alarmNameSchema.isValid(value))) {
    return false;
  }

  const result =
    validationCache.name?.value === value ? validationCache.name?.result : await getAlarmExistsByName(value);

  validationCache.name = { value, result };

  return initialValue === value || !result?.data;
};

const getTime = (value) => {
  if (isDateCorrect(value)) {
    const date = new Date(value);

    return +new Date(0, 0, 0, date.getHours(), date.getMinutes(), 0);
  }

  return 0;
};

const isMaskedInputCompleted = (value, requiredNoDigits) => {
  return value && value.replace(/[^\d]/g, '').length === requiredNoDigits;
};

const formulaSchema = Yup.object().shape({
  sections: Yup.array().of(
    Yup.object().shape({
      conditions: Yup.array().of(
        Yup.object()
          .shape({
            inputMappingType: Yup.number().required('thisFieldIsRequired'),
            systemMappingType: Yup.number().when('inputMappingType', {
              is: InputMappingTypes.SYSTEM,
              then: Yup.number().required('thisFieldIsRequired'),
              otherwise: Yup.number().nullable(),
            }),
            inputMappingExternalId: Yup.string().nullable().required('thisFieldIsRequired'),
            operatorId: Yup.number().nullable().required('thisFieldIsRequired'),
            'targetValues[0]': Yup.mixed().test('required', 'thisFieldIsRequired', function () {
              const { operatorType, targetValues } = this.parent;

              if (operatorType !== VariableTypes.EVENT_KEYWORD && operatorType !== VariableTypes.MAP_AREA) {
                if (operatorType === VariableTypes.TIME_PERIOD) {
                  return isMaskedInputCompleted(targetValues[0], 6);
                }

                return isNotEmpty(targetValues[0]);
              }

              return true;
            }),
            'targetValues[1]': Yup.mixed().test('required', 'thisFieldIsRequired', function () {
              const { operatorType, operatorId, targetValues } = this.parent;
              const from = targetValues[0];
              const to = targetValues[1];

              if (
                ([operators.METRIC_BETWEEN, operators.METRIC_OUTSIDE_OF].includes(operatorId) &&
                  [VariableTypes.METRIC].includes(operatorType)) ||
                (operatorType === VariableTypes.TIME_PERIOD && operatorId === operators.TIME_PERIOD_BETWEEN) ||
                (operatorType === VariableTypes.DAYS &&
                  [operators.DAY_BETWEEN, operators.DAY_OUTSIDE_OF].includes(operatorId)) ||
                (operatorType === VariableTypes.TIME &&
                  [operators.METRIC_BETWEEN, operators.METRIC_OUTSIDE_OF].includes(operatorId))
              ) {
                if (operatorType === VariableTypes.TIME_PERIOD && !isMaskedInputCompleted(to, 6)) {
                  return false;
                }
                if (!isNotEmpty(to)) {
                  return false;
                }

                if (
                  (operatorType === VariableTypes.TIME &&
                    [operators.METRIC_BETWEEN, operators.METRIC_OUTSIDE_OF].includes(operatorId) &&
                    getTime(from) >= getTime(to)) ||
                  (operatorType === VariableTypes.TIME_PERIOD &&
                    operatorId === operators.TIME_PERIOD_BETWEEN &&
                    convertTimePeriodToSeconds(from) >= convertTimePeriodToSeconds(to)) ||
                  +from >= +to
                ) {
                  return this.createError({
                    path: this.path,
                    message: 'toShouldBeGreaterThanFrom',
                  });
                }
              }

              return true;
            }),
          })
          .test('timeCondidions', 'timeConditionRequiresOtherConditions', function () {
            const timeConditions = this.parent.filter(
              (condition) => condition.inputMappingType === InputMappingTypes.TIME
            );

            if (this.parent.length === timeConditions.length) {
              return this.createError({
                path: `${this.path}.inputMappingType`,
                message: 'timeConditionRequiresOtherConditions',
              });
            }

            return true;
          })
          .test('timePeriodCondidion', 'singleTimePeriodInConditionsAllowed', function (value) {
            const timePeriodConditions = this.parent.filter(
              (condition) => condition.operatorType === VariableTypes.TIME_PERIOD
            );

            if (value.operatorType === VariableTypes.TIME_PERIOD && timePeriodConditions.length > 1) {
              return this.createError({
                path: `${this.path}.inputMappingExternalId`,
                message: 'singleTimePeriodInConditionsAllowed',
              });
            }

            return true;
          })
      ),
    })
  ),
});

const validationSchema = (initialValues, validationCache) =>
  Yup.object().shape({
    // CommonInfo
    name: alarmNameSchema.test('alarmNameExists', 'alarmWithThisNameAlreadyExists', function (value) {
      return alarmNameExist(value, initialValues.name, validationCache);
    }),
    alarmType: Yup.number().required('thisFieldIsRequired'),
    description: Yup.string().nullable().optional().max(200, 'nameCannotExceed200Characters'),
    status: Yup.number().required('thisFieldIsRequired'),
    // AssetAssigment
    assignedAssets: Yup.array().min(1, 'thisFieldMustHaveAtLeast1Item'),
    // Formula
    startFormulas: Yup.array().required('thisFieldIsRequired').min(1, 'thisFieldIsRequired').of(formulaSchema),
    endFormulas: Yup.array().when('isEndFormulaActive', {
      is: true,
      then: Yup.array().required('thisFieldIsRequired').min(1, 'thisFieldIsRequired').of(formulaSchema),
      otherwise: Yup.array().optional().of(formulaSchema),
    }),
    // Validity
    validFrom: Yup.string()
      .nullable()
      .when('validityType', {
        is: AlarmValidityTypes.CUSTOM,
        then: Yup.string()
          .required('thisFieldIsRequired')
          .test('invalidDate', 'invalidDate', function (val) {
            return isDateCorrect(val);
          }),
      }),
    validTo: Yup.string()
      .nullable()
      .when('validityType', {
        is: AlarmValidityTypes.CUSTOM,
        then: Yup.string()
          .required('thisFieldIsRequired')
          .test('invalidDate', 'invalidDate', function (val) {
            return isDateCorrect(val);
          })
          .test('isValidToGreatherThanValidFrom', 'dateToCannotBeEarlierThanDateFrom', function () {
            if (isDateCorrect(this.parent.validTo) && isDateCorrect(this.parent.validFrom)) {
              return +new Date(this.parent.validTo) > +new Date(this.parent.validFrom);
            }

            return false;
          }),
      }),
    timeZone: Yup.string()
      .nullable()
      .when('validityType', {
        is: AlarmValidityTypes.CUSTOM,
        then: Yup.string().required('thisFieldIsRequired'),
      }),
    calendarTemplateId: Yup.string()
      .nullable()
      .when('validityType', {
        is: AlarmValidityTypes.CALLENDAR_TEMPLATE,
        then: Yup.string().required('thisFieldIsRequired'),
      }),
    // NotificationSettings
    oneTimeAlarmTimePeriod: Yup.string()
      .nullable()
      .when('occurrenceType', {
        is: AlarmOccurrenceTypes.ONE_TIME,
        then: Yup.string()
          .required('thisFieldIsRequired')
          .transform((value) => value && value.replace(/[^\d]/g, ''))
          .test('invalidValue', 'invalidValue', function (val) {
            return val?.length === 8;
          })
          .test('valueMustBePositiveNumber', 'valueMustBePositiveNumber', function (val) {
            return val && parseInt(val) > 0;
          }),
      }),
    autoReadInMinutes: Yup.string()
      .nullable()
      .when('isAutoRead', {
        is: true,
        then: Yup.string()
          .required('thisFieldIsRequired')
          .transform((value) => value && value.replace(/[^\d]/g, ''))
          .test('invalidValue', 'invalidValue', function (val) {
            return val?.length === 4;
          }),
      }),
    emails: Yup.array()
      .optional()
      .of(
        Yup.object()
          .shape({
            email: Yup.string().email('incorrectEmailAddress').required('thisFieldIsRequired'),
          })
          .uniqueInCollection('valueMustBeUniqueInCollection', 'email')
      ),
    // AutomaticCommands
    commands: Yup.array()
      .of(
        Yup.object().shape({
          commandName: Yup.string().required('thisFieldIsRequired').max(50, 'nameCannotExceed50Characters'),
          commandText: Yup.string().required('thisFieldIsRequired').max(200, 'nameCannotExceed200Characters'),
          delay: Yup.string()
            .required('thisFieldIsRequired')
            .transform((value) => value && value.replace(/[^\d]/g, ''))
            .test('invalidValue', 'invalidValue', function (val) {
              return val?.length === 6;
            }),
        })
      )
      .when('sendCommandsOnOccur', {
        is: true,
        then: Yup.array().min(1, 'commandsFieldMustHaveAtLeast1Item'),
      }),
  });

export default validationSchema;
