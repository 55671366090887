import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import { styled } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import { ActionButtonPreview } from 'Components/ActionButton';
import Button from 'Components/Button';
import { clone } from 'Helpers/ObjectHelper';
import { buttonDetailsSchema } from './validationSchema';
import { FormikEnhanced } from 'Components/formik/formikWrappers';
import FormikCollection from 'Components/inputs/CollectionManager/FormikCollection';
import { Checkbox, Dropdown, RadioInput, TextField } from 'Components/formik/fields';

const DropdownField = styled(Dropdown)({
  margin: '16px 0 8px 0',
});
const RadioField = styled(RadioInput)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
});

const colors = { 1: '#3F51B5', 2: '#883FB5', 3: '#B53F3F' };
const sizeWidths = { small: '100px', medium: '150px', large: '250px' };
const priorities = [
  { value: 1, name: 'normal' },
  { value: 2, name: 'priority' },
  { value: 3, name: 'highPriority' },
];
const sizes = [
  { value: 1, name: 'small' },
  { value: 2, name: 'medium' },
  { value: 3, name: 'large' },
];
const types = [
  { value: 1, label: 'Single' },
  { value: 2, label: 'Multi' },
];
const commandTypes = [
  { value: 1, label: 'Tcp' },
  { value: 2, label: 'Url' },
];
const BUTTON_TYPE_SINGLE = 1;
const BUTTON_TYPE_MULTI = 2;
const COMMAND_TYPE_URL = 2;
const CLICK_REASONS_MAX = 3;

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
});

const Section = styled(Box)({
  flexGrow: 1,
  overflow: 'auto',
});

const Row = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flex: '1 1',
});

const Column = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: 20,
});

const ColorBox = styled('div')({
  width: (props) => props.size || 90,
  height: 26,
  margin: '0 5px',
  backgroundColor: (props) => props.color,
});

const ColorSelectionItem = ({ color, label }) => (
  <Box display="flex" alignItems="center">
    <ColorBox color={colors[color]} />
    <Typography>{T.translate(label)}</Typography>
  </Box>
);

const getColorSelections = (priorities) => {
  return priorities.map((item) => ({
    value: item.value,
    labelItem: <ColorSelectionItem label={item.name} color={item.value} />,
  }));
};

const SizeSelectionItem = ({ color, label, size }) => (
  <Box display="flex" alignItems="center">
    <ColorBox color={colors[color]} size={size} />
    <Typography>{T.translate(label)}</Typography>
  </Box>
);

const getSizeSelections = (sizes, color) => {
  return sizes.map((item) => ({
    value: item.value,
    labelItem: <SizeSelectionItem label={item.name} color={color} size={sizeWidths[item.name]} />,
  }));
};

const getCommandLabel = (buttonType, stateNumber = 1) => {
  let label = `${T.translate(LocaleKeys.labels.command)}`;

  if (buttonType === BUTTON_TYPE_MULTI) {
    label += ` (${T.translate(LocaleKeys.labels.state)} ${stateNumber})`;
  }

  return label;
};

const TypographyStyled = styled(Typography)({
  margin: '20px',
});

const ButtonDetails = ({ selectedButton, isReadOnly, ...props }) => {
  const [button, setButton] = useState();

  useEffect(() => {
    setButton(clone(selectedButton));
  }, [selectedButton]);

  if (!button) {
    return <TypographyStyled>{T.translate(LocaleKeys.labels.noButtonSelected)}</TypographyStyled>;
  }

  const colorsSelection = getColorSelections(priorities);

  return (
    <FormikEnhanced
      initialValues={button}
      enableReinitialize
      validationSchema={buttonDetailsSchema}
      validateOnMount
      canSaveChanges={!isReadOnly}
    >
      {({ values, dirty, isValid, setFieldValue, setValues }) => {
        const sizesSelection = getSizeSelections(sizes, values.color);

        return (
          <Container>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" alignItems="center">
                <TypographyStyled>{T.translate(LocaleKeys.labels.commandType)}:</TypographyStyled>
                <RadioField
                  name="buttonCommandType"
                  values={commandTypes}
                  onChange={(e) => {
                    const buttonCommandType = parseInt(e.target.value);
                    const type = buttonCommandType === COMMAND_TYPE_URL ? BUTTON_TYPE_SINGLE : button.type;

                    setValues({
                      ...values,
                      buttonCommandType,
                      type,
                      offCommandText: '',
                      offCommand: '',
                    });
                  }}
                  translateValue
                />
              </Box>
              <ActionButtonPreview key={values.tempId} onClick={() => {}} button={{ ...values }} />
            </Box>
            {values.buttonCommandType !== COMMAND_TYPE_URL && (
              <Box display="flex" alignItems="center">
                <TypographyStyled>{T.translate(LocaleKeys.labels.configureButton)}:</TypographyStyled>
                <RadioField
                  name="type"
                  values={types}
                  onChange={(e) => {
                    const type = parseInt(e.target.value);

                    if (type === BUTTON_TYPE_SINGLE) {
                      setFieldValue('offCommandText', '');
                      setFieldValue('offCommand', '');
                    }

                    setFieldValue('type', type);
                  }}
                  translateValue
                />
              </Box>
            )}
            <Section>
              <Row>
                <Column>
                  <TextField label={T.translate(LocaleKeys.labels.name)} name="onCommandText" fullWidth />
                  <TextField
                    label={
                      values.buttonCommandType === COMMAND_TYPE_URL
                        ? T.translate(LocaleKeys.labels.url).toUpperCase()
                        : getCommandLabel(values.type)
                    }
                    name="onCommand"
                    multiline
                    fullWidth
                  />
                  {values.type === BUTTON_TYPE_MULTI && (
                    <TextField
                      key="offCommandText"
                      label={T.translate(LocaleKeys.labels.name)}
                      name="offCommandText"
                      fullWidth
                    />
                  )}
                  {values.type === BUTTON_TYPE_MULTI && (
                    <TextField
                      key="offCommand"
                      label={getCommandLabel(values.type, 2)}
                      name="offCommand"
                      multiline
                      fullWidth
                    />
                  )}
                  <Checkbox label={T.translate(LocaleKeys.labels.sensitiveButton)} name="isSensitive" />
                </Column>
                <Column>
                  <DropdownField
                    label={T.translate(LocaleKeys.labels.color)}
                    name="color"
                    data={colorsSelection}
                    displayField="labelItem"
                    valueField="value"
                    withoutEmpty
                    fullWidth
                  />
                  <DropdownField
                    label={T.translate(LocaleKeys.labels.size)}
                    name="size"
                    data={sizesSelection}
                    displayField="labelItem"
                    valueField="value"
                    withoutEmpty
                    fullWidth
                  />
                  <Checkbox label={T.translate(LocaleKeys.labels.commentRequired)} name="isReasonRequired" />
                  {values.isReasonRequired && (
                    <FormikCollection
                      label={T.translate(LocaleKeys.labels.reasonComments)}
                      name="clickReasons"
                      value={values.clickReasons}
                      disableAddItem={values.clickReasons?.length >= CLICK_REASONS_MAX}
                    >
                      <TextField label={T.translate(LocaleKeys.labels.reasonComment)} name="reason" fullWidth />
                    </FormikCollection>
                  )}
                </Column>
              </Row>
            </Section>
            <Box display="flex" justifyContent="flex-end" margin="20px">
              {!isReadOnly && (
                <Button onClick={() => props.onDelete(values.categoryTempId, values.tempId)}>
                  {T.translate(LocaleKeys.labels.delete)}
                </Button>
              )}
              <Button color="primary" onClick={props.onCancel}>
                {T.translate(LocaleKeys.labels.cancel)}
              </Button>
              {!isReadOnly && (
                <Button
                  color="primary"
                  onClick={() => props.onSave(values.categoryTempId, values)}
                  disabled={!(dirty && isValid)}
                >
                  {T.translate(LocaleKeys.labels.save)}
                </Button>
              )}
            </Box>
          </Container>
        );
      }}
    </FormikEnhanced>
  );
};

ButtonDetails.propTypes = {
  selectedButton: PropTypes.object,
  buttonLibrary: PropTypes.object,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

export default ButtonDetails;
