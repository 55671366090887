import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { Box, styled } from '@material-ui/core';
import FormikCollection from 'Components/inputs/CollectionManager/FormikCollection';
import { TextField } from 'Components/formik/fields';

const TextFieldStyled = styled(TextField)({
  minWidth: 300,
});

const EventKeywords = (props) => {
  if (!props.data.mappings?.keywordMappings) {
    return <div>{T.translate(LocaleKeys.messages.noDeviceLibrarySelected)}</div>;
  }
  if (!props.data.mappings?.keywordMappings) {
    return <div>{T.translate(LocaleKeys.messages.noInputsMappingsForSelectedDriver)}</div>;
  }

  return (
    <Box margin={'10px 3%'}>
      <FormikCollection
        label={T.translate(LocaleKeys.labels.keywordNames)}
        name="mappings.keywordMappings"
        readOnly={props.data.brokenAlarmsLoading}
      >
        <TextFieldStyled
          label={T.translate(LocaleKeys.labels.keywordName)}
          name="name"
          fullWidth
          readOnly={props.data.brokenAlarmsLoading}
        />
        <TextFieldStyled
          label={T.translate(LocaleKeys.labels.keywordString)}
          name="inputValue"
          fullWidth
          readOnly={props.data.brokenAlarmsLoading}
        />
      </FormikCollection>
    </Box>
  );
};

EventKeywords.propTypes = {
  data: PropTypes.object,
  brokenAlarmsLoading: PropTypes.bool,
};

export default EventKeywords;
