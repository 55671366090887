import { types } from './actions';

const initialState = {
  history: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_NOTIFICATION:
      return {
        ...state,
        history: state.history.concat(action.notification),
        variant: action.notification.variant,
      };
    default:
      return state;
  }
};
