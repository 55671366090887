import React, { useRef, memo, useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Paper, styled } from '@material-ui/core';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List, areEqual } from 'react-window';
import * as configurationStore from 'Api/configurationStore';
import AssetRow, { Header } from './AssetRow';
import ContextMenus from './ContextMenus';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';

const Wrapper = styled(({ displayBackToDashboardButton, ...props }) => <Paper {...props} />)({
  maxHeight: 'calc(100vh - 224px)',
  height: (props) => (props.displayBackToDashboardButton ? 'calc(100vh - 390px)' : '100%'),
  width: '100%',
  overflow: 'hidden',
});
const Scroller = styled('div')({
  height: '100%',
});

const RowMemoized = memo(AssetRow, areEqual);

const getRow = ({ style, data, index }) => {
  const {
    sortedDevices,
    selectedAssetIds,
    setSelectedAssetIds,
    options,
    handleRightClick,
    favClick,
    selectDeviceGeoPos,
    deviceTrail,
    setGeoPosMenuActive,
    setMenuPos,
    favDevices,
  } = data;
  const device = sortedDevices[index];

  return (
    <div
      style={{
        ...style,
        width: 840,
        maxWidth: 'fit-content',
      }}
    >
      <RowMemoized
        device={device}
        selectedAssetIds={selectedAssetIds}
        setSelectedAssetIds={setSelectedAssetIds}
        options={options}
        handleRightClick={handleRightClick}
        favClick={favClick}
        selectDeviceGeoPos={selectDeviceGeoPos}
        deviceTrail={deviceTrail}
        setGeoPosMenuActive={setGeoPosMenuActive}
        setMenuPos={setMenuPos}
        favDevices={favDevices}
      />
    </div>
  );
};

export const getSortedAssets = (assets, favDevices, selectedAssetIds) => {
  const sortPredicate = (a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' });
  const favouriteAndSelected = assets
    .filter((device) => favDevices.includes(device.externalId) && selectedAssetIds.includes(device.externalId))
    .sort(sortPredicate);
  const favourite = assets
    .filter((device) => favDevices.includes(device.externalId) && !selectedAssetIds.includes(device.externalId))
    .sort(sortPredicate);
  const selected = assets
    .filter((device) => !favDevices.includes(device.externalId) && selectedAssetIds.includes(device.externalId))
    .sort(sortPredicate);
  const other = assets
    .filter((device) => !favDevices.includes(device.externalId) && !selectedAssetIds.includes(device.externalId))
    .sort(sortPredicate);

  return favouriteAndSelected.concat(favourite, selected, other);
};

const AssetList = ({
  options,
  devices,
  deviceTrail,
  selectedAssetIds,
  setSelectedAssetIds,
  selectDeviceGeoPos,
  error,
  displayBackToDashboardButton,
}) => {
  const [favDevices, setFavDevices] = useState([]);
  const [menuPos, setMenuPos] = useState({ top: 0, left: 0 });
  const [menuActive, setMenuActive] = useState(false);
  const [geoPosMenuActive, setGeoPosMenuActive] = useState(false);
  const headerRef = useRef(null);

  useEffect(() => {
    configurationStore.getValue(`${options.sidebarStateName}-FavDevices`).then((response) => {
      setFavDevices(response.data || []);
    });
  }, [options.sidebarStateName]);

  const handleRightClick = useCallback(
    (e, assetId) => {
      e.preventDefault();
      setMenuPos({ top: e.pageY, left: e.pageX });
      setMenuActive(assetId);
    },
    [setMenuPos, setMenuActive]
  );

  const favClick = useCallback(
    (e, assetId) => {
      e.stopPropagation();
      setFavDevices((prevFavDevices) => {
        let newFavDevices;

        if (prevFavDevices.includes(assetId)) {
          // remove
          newFavDevices = prevFavDevices.filter((i) => i !== assetId);
        } else {
          // add - will remove not existing assetIds in fav array
          newFavDevices = devices.map((d) => d.externalId).filter((i) => prevFavDevices.includes(i) || i === assetId);
        }

        configurationStore.putValueCreateOrUpdate(`${options.sidebarStateName}-FavDevices`, {
          value: JSON.stringify(newFavDevices),
        });

        return newFavDevices;
      });
    },
    [devices, setFavDevices, options.sidebarStateName]
  );

  const sortedDevices = useMemo(
    () => getSortedAssets(devices, favDevices, selectedAssetIds),
    [devices, favDevices, selectedAssetIds]
  );

  const itemData = useMemo(
    () => ({
      sortedDevices,
      selectedAssetIds,
      setSelectedAssetIds,
      options,
      handleRightClick,
      favClick,
      selectDeviceGeoPos,
      deviceTrail,
      setGeoPosMenuActive,
      setMenuPos,
      favDevices,
    }),
    [
      deviceTrail,
      favClick,
      favDevices,
      handleRightClick,
      options,
      selectDeviceGeoPos,
      selectedAssetIds,
      setSelectedAssetIds,
      sortedDevices,
    ]
  );

  return (
    <Wrapper displayBackToDashboardButton={displayBackToDashboardButton}>
      {error && (
        <Box align="center" p={3}>
          {T.translate(LocaleKeys.messages.errorWhileFetchingData, { name: T.translate(LocaleKeys.labels.devices) })}
        </Box>
      )}
      {!!devices.length && (
        <Scroller>
          <Header ref={headerRef} options={options} devices={devices} />
          <AutoSizer>
            {({ height, width }) => (
              <List
                width={width}
                height={height - 50}
                itemSize={50}
                itemCount={devices.length}
                itemData={itemData}
                outerRef={(ref) => {
                  if (ref) {
                    ref.addEventListener('scroll', (e) => {
                      headerRef.current.style.left = `-${e.target.scrollLeft}px`;
                    });
                  }
                }}
              >
                {getRow}
              </List>
            )}
          </AutoSizer>
        </Scroller>
      )}
      <ContextMenus
        top={menuPos.top}
        left={menuPos.left}
        contextMenuItems={options.contextMenuItems}
        menuActive={menuActive}
        setMenuActive={setMenuActive}
        geoPosMenuActive={geoPosMenuActive}
        setGeoPosMenuActive={setGeoPosMenuActive}
        favDevices={favDevices}
        favClick={favClick}
        deviceTrail={deviceTrail}
        selectDeviceGeoPos={selectDeviceGeoPos}
      />
    </Wrapper>
  );
};

AssetList.propTypes = {
  options: PropTypes.object,
  devices: PropTypes.array,
  deviceTrail: PropTypes.array,
  selectedAssetIds: PropTypes.array,
  setSelectedAssetIds: PropTypes.func,
  selectDeviceGeoPos: PropTypes.func,
  error: PropTypes.string,
  displayBackToDashboardButton: PropTypes.bool,
};

export default AssetList;
