import { UserStatuses } from './UserStatuses';
import { LocalStorageKeys } from './LocalStorageKeys';
import { SessionStorageKeys } from './SessionStorageKeys';
import { Permissions } from './permissions';
import { AlarmOccurrenceTypes } from './AlarmOccurrenceTypes';
import { AlarmStatuses } from './AlarmStatuses';
import { AlarmTypes } from './AlarmTypes';
import { AlarmValidityTypes } from './AlarmValidityTypes';
import { InputMappingTypes } from './InputMappingTypes';
import { VariableTypes } from './VariableTypes';
import { VehicleRunningTypes } from './VehicleRunningTypes';
import { IpWhiteListItemTypes } from './IpWhiteListItemTypes';
import { ResolveAlarmModes } from './ResolveAlarmModes';
import { DaysOfWeek } from './DaysOfWeek';
import { MapAreaVariables } from './MapAreaVariables';
import { MetricVariables } from './MetricVariables';
import { EventKeywordVariables } from './EventKeywordVariables';
import { DayVariables } from './DayVariables';
import { TimePeriodVariables } from './TimePeriodVariables';
import { DigitalMappingStates } from './DigitalMappingStates';

const Consts = {
  AlarmOccurenceTypes: AlarmOccurrenceTypes,
  AlarmStatuses: AlarmStatuses,
  AlarmTypes: AlarmTypes,
  AlarmValidityTypes: AlarmValidityTypes,
  DaysOfWeek: DaysOfWeek,
  DayVariables: DayVariables,
  DigitalMappingStates: DigitalMappingStates,
  EventKeywordVariables: EventKeywordVariables,
  InputMappingTypes: InputMappingTypes,
  IpWhiteListItemTypes: IpWhiteListItemTypes,
  LocalStorageKeys: LocalStorageKeys,
  MapAreaVariables: MapAreaVariables,
  MetricVariables: MetricVariables,
  Permissions: Permissions,
  ResolveAlarmModes: ResolveAlarmModes,
  SessionStorageKeys: SessionStorageKeys,
  TimePeriodVariables: TimePeriodVariables,
  UserStatuses: UserStatuses,
  VariableTypes: VariableTypes,
  VehicleRunningTypes: VehicleRunningTypes,
};

export default Consts;
