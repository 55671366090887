import * as Yup from 'yup';
import { isFutureDate, isDateCorrect, linkRegExp } from 'Validators/SharedValidators';

const SiteNotificationsValidation = Yup.object().shape({
  content: Yup.string().required('thisFieldIsRequired').max(500, 'nameCannotExceed500Characters'),
  content2: Yup.string().optional().nullable().matches(linkRegExp, 'urlMustBeInserted'),
  startFrom: Yup.string()
    .required('thisFieldIsRequired')
    .nullable()
    .test('is-future-date', 'onlyFutureDatesAreAllowed', function (value) {
      return isFutureDate(value);
    }),
  activeTo: Yup.string()
    .nullable()
    .when('displayAlwaysOnFeed', {
      is: false,
      then: Yup.string()
        .required('thisFieldIsRequired')
        .test('is-future-date', 'onlyFutureDatesAreAllowed', function (value) {
          return isFutureDate(value);
        })
        .test('is-greater-than-startForm', 'activeToShouldBeGreaterThanStartFrom', function (value) {
          const { startFrom } = this.parent;

          return isDateCorrect(startFrom) && isDateCorrect(value) && +new Date(startFrom) < +new Date(value);
        }),
    }),
  sites: Yup.array().min(1, 'selecteAtLeastOne'),
});

export default SiteNotificationsValidation;
