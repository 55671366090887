import * as Yup from 'yup';
import { getDevicesGroupExistsByName } from 'Api/devices';
import { isAlphanumericOrSpecialSymbol } from 'Validators/SharedValidators';

let lastValue;
let initialValue;

export const deviceGroupNameExist = async (value, id) => {
  if (isAlphanumericOrSpecialSymbol([' '])(value)) {
    const result = lastValue?.value === value ? lastValue?.result : await getDevicesGroupExistsByName(value);

    if (id) {
      if (!initialValue || id !== initialValue?.id) {
        initialValue = { value, id };
      }
    } else {
      initialValue = undefined;
    }

    lastValue = { value, result };

    return initialValue?.value === value || !result?.data;
  }

  return false;
};

const DevicesGroupValidators = Yup.object().shape({
  name: Yup.string()
    .required('thisFieldIsRequired')
    .max(50, 'nameCannotExceed50Characters')
    .alphanumericOrSpecialSymbol([' '])
    .test('deviceGroupNameExist', 'devicesGroupWithThisNameAlreadyExists', function (value) {
      return deviceGroupNameExist(value, this.parent.externalId);
    }),
  devices: Yup.array().min(1, 'thisFieldIsRequired'),
});

export default DevicesGroupValidators;
