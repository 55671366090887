import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from 'Components/inputs/Checkbox';
import { getCanManageAllDevices, getDevicesForSites } from 'Api/devices';
import Table from 'Components/display/table/Table';
import Loader from 'Components/Loader';
import { compose } from 'redux';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import { useEnhancedFormContext, withFormik } from 'Components/formik/formikWrappers';
import { Box, Typography } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';

const Warning = ({ message }) => (
  <Box display="flex" alignItems="center" margin="20px">
    <ErrorOutline color="error" style={{ fontSize: 30 }} />
    <Typography>{message}</Typography>
  </Box>
);
const getTooltipTitle = (usedInAlarms, canManageAllDevices, canEditDevices) => {
  if (!canManageAllDevices) {
    return T.translate(LocaleKeys.messages.youAreLimitedByAssignedDeviceGroups);
  } else if (!canEditDevices) {
    return `${T.translate(LocaleKeys.messages.requiredPermission)}: ${T.translate(Permissions.CanReadDevices)}`;
  } else if (usedInAlarms) {
    return T.translate(LocaleKeys.tooltips.deviceWithAlarmsCannotBeMovedToOtherSite);
  } else {
    return '';
  }
};

export const DevicesAssignment = ({ onChange, data, permissions }) => {
  const [assigned, setAssigned] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [devices, setDevices] = useState(null);
  const [originallyAssigned, setOriginallyAssigned] = useState([]);
  const [canManageAllDevices, setCanManageAllDevices] = useState(false);
  const { canSaveChanges } = useEnhancedFormContext();
  const isReadOnly = !canSaveChanges;
  const siteId = data.siteId;

  useEffect(() => {
    Promise.all([getDevicesForSites(siteId), getCanManageAllDevices(siteId)]).then(
      ([{ data }, { data: canManageAllDevices }]) => {
        const assignedAndAvailable = data.filter((device) => device.tenantId === null || device.tenantId === siteId);
        const assignedToSite = data.filter((device) => device.tenantId === siteId);
        const originallyAssignedToSite = [...assignedToSite];

        setDevices(assignedAndAvailable);
        setOriginallyAssigned(originallyAssignedToSite);
        setAssigned(assignedToSite);
        setCanManageAllDevices(canManageAllDevices);
        setIsLoaded(true);
      }
    );
  }, [siteId]);

  const getDevicesById = useCallback(
    (ids) => {
      const devicesFiltered = devices.filter((el) => ids.map((x) => x.id).includes(el.id));

      return devicesFiltered.map((el) => ({
        id: el.id,
        deviceId: el.code,
        deviceName: el.name,
        description: el.description,
        imei: el.imei,
        externalId: el.externalId,
      }));
    },
    [devices]
  );

  const deviceSelected = (id) => {
    return assigned.some((s) => parseInt(s.id) === id);
  };

  const assignedChange = (event) => {
    if (!devices) {
      return;
    }

    const id = event.target.name;
    const newAssigned = [...assigned];
    const deviceOnList = newAssigned.find((x) => x.id === id);

    if (!deviceOnList) {
      newAssigned.push({ id });
    } else {
      newAssigned.splice(newAssigned.indexOf(deviceOnList), 1);
    }

    const newlyAssigned = newAssigned.filter((x) => !originallyAssigned.map((z) => z.id).includes(x.id));
    const newlyUnassigned = originallyAssigned.filter((x) => !newAssigned.map((z) => z.id).includes(x.id));

    onChange({ target: { name: 'assignedDevices', value: getDevicesById(newlyAssigned) } });
    onChange({ target: { name: 'unAssignedDevices', value: getDevicesById(newlyUnassigned) } });

    setAssigned(newAssigned);
  };

  if (!devices || !assigned || !isLoaded) {
    return <Loader />;
  }

  const formatDataForTableView = (devices) =>
    Object.values(devices).map((device) => ({
      deviceId: device.code,
      deviceName: device.name,
      description: device.description,
      deviceDriver: device.deviceDriverName,
      imei: device.imei,
      phoneNumber: device.phone,
      location: device.location,
      assign: (
        <Tooltip title={getTooltipTitle(device.usedInAlarms, canManageAllDevices, permissions.CanEditDevices)}>
          <span>
            <Checkbox
              key={device.id}
              keyId={device.id}
              value={deviceSelected(device.id)}
              name={device.id}
              onChange={assignedChange}
              id={device.id}
              disabled={isReadOnly || !canManageAllDevices || !permissions.CanEditDevices || device.usedInAlarms}
            />
          </span>
        </Tooltip>
      ),
    }));

  const formattedTableData = formatDataForTableView(devices);

  return (
    <>
      {!canManageAllDevices && (
        <Warning message={T.translate(LocaleKeys.messages.youAreLimitedByAssignedDeviceGroups)} />
      )}
      <Table data={formattedTableData} />
    </>
  );
};

DevicesAssignment.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
  permissions: PropTypes.object,
};

export default compose(withPermissions([Permissions.CanReadDevices]), withFormik)(DevicesAssignment);
