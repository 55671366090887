import * as Redux from 'redux';

import app from './app';
import user from './user';
import sites from './sites';
import notifications from './notifications';
import assets from './assets';
import mapOverlays from './mapOverlays';
import alarms from './alarms';

const appReducer = Redux.combineReducers({
  app,
  user,
  sites,
  notifications,
  assets,
  alarms,
  mapOverlays,
});

export default appReducer;
