import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { styled } from '@material-ui/core';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import { withFormik } from 'Components/formik/formikWrappers';
import FormikCollection from 'Components/inputs/CollectionManager/FormikCollection';
import { getInitialTime, getDateOnly } from '../helpers';
import { subtractCurrentTimezoneOffset, addCurrentTimezoneOffset } from 'Helpers/DateTimeHelpers';
import { isDateCorrect } from 'Validators/SharedValidators';
import { TextField, DateTimeInput as DateTimeInputRaw, Checkbox as CheckboxRaw } from 'Components/formik/fields';

const inputStyles = {
  margin: '0 5px',
  height: '100%',
};
const Checkbox = styled(CheckboxRaw)(inputStyles);
const DateTimeInput = styled(DateTimeInputRaw)(inputStyles);
const TextInput = styled(TextField)(inputStyles);

export const convertValue = (date, hours) => {
  let value = date;

  if (isDateCorrect(value)) {
    value = subtractCurrentTimezoneOffset(value);
    value = getDateOnly(value);
    value.setUTCHours(hours);
  }

  return value;
};

export const Vacation = (props) => {
  return (
    <ColumnDisplayer>
      <FormikCollection
        name={'vacations'}
        label={T.translate(LocaleKeys.labels.vacation)}
        defaultValue={{
          from: subtractCurrentTimezoneOffset(getInitialTime(8)),
          to: subtractCurrentTimezoneOffset(getInitialTime(16)),
          isEnabled: true,
        }}
      >
        <Checkbox name="isEnabled" label={T.translate(LocaleKeys.labels.isEnabled)} />
        <DateTimeInput
          label={T.translate(LocaleKeys.labels.from)}
          type="date"
          name="from"
          onChange={(e) => {
            e.target.value = convertValue(e.target.value, 8);
            props.onChange(e);
          }}
          convertValue={addCurrentTimezoneOffset}
          format="dd.MM.yyyy"
        />
        <DateTimeInput
          label={T.translate(LocaleKeys.labels.to)}
          type="date"
          name="to"
          onChange={(e) => {
            e.target.value = convertValue(e.target.value, 16);
            props.onChange(e);
          }}
          convertValue={addCurrentTimezoneOffset}
          format="dd.MM.yyyy"
        />
        <TextInput label={T.translate(LocaleKeys.labels.description)} name="description" />
      </FormikCollection>
    </ColumnDisplayer>
  );
};

Vacation.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.object,
};

export default withFormik(Vacation);
