import ApiClient from './ApiClient';

const apiClient = new ApiClient();
const SERVICE_PATH = '/siteconfigurator/api/';

const get = (path, params) => apiClient.get(SERVICE_PATH + path, { params });
const post = (path, data) => apiClient.post(SERVICE_PATH + path, data);
const put = (path, data) => apiClient.put(SERVICE_PATH + path, data);
const deleteRequest = (path, data) => apiClient.delete(SERVICE_PATH + path, data);

// Sites
export const getSiteInfoByName = (name) => get(`sites/name/${name}/info`);
export const getSites = () => get('sites');
export const getSite = (siteId) => get(`sites/${siteId}`);
export const getSiteByName = (siteName) => get(`sites/name/${siteName}`);
export const postSite = (data) => post('sites', data);
export const putSite = (data) => put(`sites/${data.siteId}`, data);
export const deleteSite = (siteId) => deleteRequest(`sites/${siteId}`);
export const deleteSites = (ids) => deleteRequest(`sites`, ids);
export const getSiteExistsByName = (siteName) => get(`sites/name/${siteName}/exists`);
export const getLoggedUserSite = () => get('sites/loggedusersite');

// Pages
export const getPages = () => get('pages');
export const getSitePages = (siteId) => get(`sites/${siteId}/pages`);
export const postSitePages = (siteId, data) => post(`sites/${siteId}/pages`, data);

// Dictionaries
export const getReportTypes = () => get('dictionaries/reportstype');
export const getTimeZones = (languageCode) => get(`dictionaries/timezones/${languageCode}`);

// Site groups
export const getSiteGroups = () => get('sitegroups');
export const getSiteGroup = (id) => get(`sitegroups/${id}`);
export const getSiteGroupByName = (name) => get(`sitegroups/name/${name}`);
export const putSiteGroup = (id, data) => put(`sitegroups/${id}`, data);
export const postSiteGroup = (data) => post('sitegroups', data);
export const deleteSiteGroup = (id) => deleteRequest(`sitegroups/${id}`);
export const getSiteGroupExistsByName = (siteGroupName) => get(`sitegroups/name/${siteGroupName}/exists`);

// Notifications
export const getNotifications = () => get('notifications');
export const getNotification = (id) => get(`notifications/${id}`);
export const getNotificationByExternalId = (externalId) => get(`notifications/externalid/${externalId}`);
export const putNotification = (id, data) => put(`notifications/${id}`, data);
export const postNotification = (data) => post('notifications', data);
export const deleteNotification = (id) => deleteRequest(`notifications/${id}`);

// Maps
export const getSiteMaps = (siteId) => get(`sites/${siteId}/maps`);
export const putSiteMaps = (siteId, data) => put(`sites/${siteId}/maps`, data);

// Calendars
export const getCalendars = () => get('calendars');
export const getCalendar = (id) => get(`calendars/${id}`);
export const getCalendarByName = (name) => get(`calendars/name/${name}`);
export const postCalendar = (data) => post('calendars', data);
export const putCalendar = (id, data) => put(`calendars/${id}`, data);
export const deleteCalendar = (id) => deleteRequest(`calendars/${id}`);
export const deleteCalendars = (data) => deleteRequest('calendars', data);
export const getCalendarExistsByName = (calendarTemplateName) => get(`calendars/name/${calendarTemplateName}/exists`);
export const getCalendarsLinkedToObject = (linkedObjectType) => get(`calendars/linkedtoobject/${linkedObjectType}`);
export const getCalendarsByHolidayId = (holidayId) => get(`calendars/byHolidayId/${holidayId}`);

// Holidays
export const getHolidays = () => get('holidays');
export const getHoliday = (id) => get(`holidays/${id}`);
export const getHolidayByName = (name) => get(`holidays/name/${name}`);
export const postHoliday = (data) => post('holidays', data);
export const putHoliday = (id, data) => put(`holidays/${id}`, data);
export const deleteHoliday = (id) => deleteRequest(`holidays/${id}`);
export const deleteHolidays = (data) => deleteRequest('holidays', data);
export const getHolidayExistsByName = (holidayTemplateName) => get(`holidays/name/${holidayTemplateName}/exists`);

// Menus
export const getMenus = () => get('menus');
export const deleteMenu = (id) => deleteRequest(`menus/${id}`);
export const getMenuUsers = () => get('menus/users');
export const getMenuTemplate = () => get('menus/template');
export const getMenusName = (name) => get(`menus/name/${name}`);
export const postMenu = (data) => post('menus', data);
export const putMenu = (id, data) => put(`menus/${id}`, data);
export const getMenuByLogin = () => get('menus/bylogin');
export const menuTemplateExists = (name) => get(`menus/name/${name}/exists`);
