import React, { useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { connect } from 'react-redux';
import { HttpTransportType, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import Config from 'Config';
import AuthManager from 'Auth/AuthManager';
import { update, updatePosition, setAssetCommandButton } from 'redux/assets/actions';
import { getReverseGeocodeHERE } from 'Api/maps';
import { getMapToken } from 'redux/app/selectors';
import { Permissions } from 'Constants/permissions';

const DeviceStatusContext = React.createContext();

const URL = `${Config.apiDefaultURL}/notificationservice/hub/devicestatus`;

const captureMessage = (message, captureContext) => {
  Sentry.captureMessage(message, {
    ...captureContext,
    tags: { library: 'signalr', ...captureContext.tags },
    extra: { url: URL, ...captureContext.extra },
  });
};

const Provider = ({
  currentSiteId,
  mapToken,
  permissions,
  devices,
  update,
  updatePosition,
  setAssetCommandButton,
  children,
}) => {
  const [hubConnection, setHubConnection] = useState(null);
  const prevState = useRef({
    devices,
    mapToken,
    currentSiteId,
  });
  const canReadDevices =
    permissions.find((permission) => permission.code === Permissions.CanReadDevices)?.value === 'true';

  useEffect(() => {
    prevState.current.devices = devices;
    prevState.current.mapToken = mapToken;
    prevState.current.currentSiteId = currentSiteId;
  }, [devices, mapToken, currentSiteId]);

  useEffect(() => {
    const hubConnection = new HubConnectionBuilder()
      .withUrl(URL, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => AuthManager.getUser().access_token,
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Warning)
      .build();

    hubConnection.onclose((error) => {
      if (error) {
        captureMessage('hubConnection onclose error', {
          extra: {
            message: error.message,
            callback: 'onclose',
          },
        });
      }
    });

    hubConnection.onreconnected(() => {
      hubConnection.invoke('SubscribeToSite', prevState.current.currentSiteId).catch((error) => {
        captureMessage('hubConnection.invoke("SubscribeToSite") error', {
          extra: {
            message: error.message,
            callback: 'onreconnected',
            currentSiteId: prevState.current.currentSiteId,
          },
        });
      });
    });

    hubConnection
      .start()
      .then(() => {
        hubConnection.on('DeviceLocation', async ({ assetId, latitude, longitude, timestamp }) => {
          const device = prevState.current.devices.find((d) => d.externalId === assetId);
          const position = device?.position;

          timestamp = +new Date(timestamp);
          latitude = latitude?.toString();
          longitude = longitude?.toString();

          if (position?.latitude !== latitude || position?.longitude !== longitude) {
            getReverseGeocodeHERE(latitude, longitude, prevState.current.mapToken)
              .then((response) => {
                updatePosition({
                  externalId: assetId,
                  position: {
                    latitude,
                    longitude,
                    timestamp,
                    address: response.items[0]?.title,
                    error: null,
                  },
                });
              })
              .catch((error) => {
                updatePosition({
                  externalId: assetId,
                  position: {
                    latitude,
                    longitude,
                    timestamp,
                    address: null,
                    error,
                  },
                });
              });
          }
        });
        hubConnection.on('DeviceConnectivity', ({ assetId, status }) => {
          const device = prevState.current.devices.find((d) => d.externalId === assetId);

          if (device?.tcpIpStatusIndicator !== status) {
            update({
              externalId: assetId,
              device: {
                tcpIpStatusIndicator: status,
              },
            });
          }
        });
        hubConnection.on('DeviceMovement', ({ assetId, isParked, speed, unit }) => {
          const device = prevState.current.devices.find((d) => d.externalId === assetId);
          const speedMode = device?.speedMode;

          if (
            speedMode?.isParked !== isParked ||
            // rest api can return speed value in string
            +speedMode?.value !== speed
          ) {
            update({
              externalId: assetId,
              device: {
                speedMode: {
                  isParked,
                  value: speed,
                  unit: unit,
                },
              },
            });
          }
        });
        hubConnection.on('DeviceStatus', ({ assetId, timestamp, lastRefreshedAt }) => {
          const lastAt = Math.round(+new Date(timestamp) / 1000);
          const device = prevState.current.devices.find((d) => d.externalId === assetId);

          const currentTimestamp = device?.statusMode?.lastAt;
          const currentLastRefreshed = device?.lastRefreshedAt;

          if (device && currentTimestamp !== lastAt) {
            update({
              externalId: assetId,
              device: {
                statusMode: {
                  lastAt: timestamp ? Math.round(+new Date(timestamp) / 1000) : currentTimestamp,
                },
                lastRefreshedAt: lastRefreshedAt || currentLastRefreshed,
              },
            });
          }
        });
        hubConnection.on('DeviceCommandButton', ({ assetId, buttonId, buttonState }) => {
          const device = prevState.current.devices.find((d) => d.externalId === assetId);

          if (device) {
            setAssetCommandButton({
              assetId,
              buttonId,
              currentState: buttonState,
              lastSentAt: null,
            });
          }
        });

        setHubConnection(hubConnection);
      })
      .catch((error) => {
        captureMessage('hubConnection.start()', {
          extra: { message: error.message, currentSiteId },
        });
      });
  }, [update, updatePosition, setAssetCommandButton]);

  useEffect(() => {
    if (currentSiteId && hubConnection?.connectionState === HubConnectionState.Connected && canReadDevices) {
      hubConnection.invoke('SubscribeToSite', currentSiteId).catch((error) => {
        captureMessage('hubConnection.invoke("SubscribeToSite") error', {
          extra: {
            message: error.message,
            callback: 'useEffect',
            currentSiteId,
          },
        });
      });
    }

    return () => {
      if (currentSiteId && hubConnection?.connectionState === HubConnectionState.Connected && canReadDevices) {
        hubConnection.invoke('UnsubscribeFromSite', currentSiteId).catch((error) => {
          captureMessage('hubConnection.invoke("UnsubscribeFromSite") error', {
            extra: {
              message: error.message,
              callback: 'useEffect return',
              currentSiteId,
            },
          });
        });
      }
    };
  }, [hubConnection, currentSiteId, canReadDevices]);

  return (
    <DeviceStatusContext.Provider
      value={{
        deviceStatusHubConnection: hubConnection,
      }}
    >
      {children}
    </DeviceStatusContext.Provider>
  );
};

const mapState = (state) => ({
  currentSiteId: state.sites.currentSiteId,
  currentSite: state.sites.currentSite,
  permissions: state.app.permissions,
  mapToken: getMapToken(state),
  devices: state.assets.devices,
});
const mapDispatch = {
  update,
  updatePosition,
  setAssetCommandButton,
};

export const DeviceStatusProvider = connect(mapState, mapDispatch)(Provider);

export const withDeviceStatusHubConnection = (Component) => {
  const ComponentWithSignalR = (props, ref) => (
    <DeviceStatusContext.Consumer>
      {(signalRProps) => <Component {...signalRProps} {...props} ref={ref} />}
    </DeviceStatusContext.Consumer>
  );

  return React.forwardRef(ComponentWithSignalR);
};
