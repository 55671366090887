import React, { Component } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';

import Privileges from './tabs/Privileges';
import { getRole, getPermissions, postRole } from 'Api/policyServer';
import SaveOnLeave from 'Components/dialogs/SaveOnLeave';
import Button from 'Components/Button';
import { UserRoles } from 'routes/Routes';
import ActionPanel from 'Components/display/ActionPanel';
import RoleDescription from './tabs/RoleDescription';
import ExpansionSection from 'Components/display/ExpansionPanel/ExpansionSection';
import Loader from 'Components/Loader';
import { compose } from 'redux';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import { Formik } from 'formik';
import Tabs from 'Components/display/tabs/Tabs';
import { UserRolesValidation } from './UserRolesValidation';

class New extends Component {
  state = {
    permissions: [],
    roleInfo: { name: '', permissions: [] },
    permissionIsLoaded: false,
    bassedRoleInfoIsLoaded: false,
  };

  allDataIsReady = () => {
    return this.state.permissionIsLoaded && this.state.bassedRoleInfoIsLoaded;
  };

  componentDidMount = async () => {
    this.loadPermissionDictionary();

    const locationState = this.props.history.location.state;

    if (locationState && locationState.baseOnRoleCode) {
      this.copyPropsFromRoleCode(locationState.baseOnRoleCode);
    } else {
      this.setState({ bassedRoleInfoIsLoaded: true });
    }
  };

  copyPropsFromRoleCode = (code) => {
    getRole(code).then(({ data: bassedRole }) => {
      const newRole = {
        ...bassedRole,
        name: '',
      };

      this.setState({
        roleInfo: newRole,
        bassedRoleInfoIsLoaded: true,
      });
    });
  };

  loadPermissionDictionary = () => {
    getPermissions()
      .then(({ data }) => {
        return data;
      })
      .catch(() => {
        alert('error during try get permissions');
      })
      .then((permissions) => {
        this.setState({
          permissions,
          permissionIsLoaded: true,
        });
      })
      .catch(() => {
        alert('error during create view');
      });
  };

  redirect = () => {
    const url = UserRoles.getUrl();

    this.props.history.push(url);
  };

  onCancel = () => {
    this.redirect();
  };

  saveData = (values) => {
    const newRole = {
      name: values.roleInfo.name,
      permissions: values.roleInfo.permissions,
    };

    return postRole(newRole);
  };

  onSubmit = (values, { setSubmitting }) => {
    this.saveData(values).finally(() => {
      setSubmitting(false);
      this.redirect();
    });
  };

  render() {
    const data = {
      permissions: this.state.permissions,
      roleInfo: this.state.roleInfo,
    };

    if (!this.allDataIsReady()) {
      return <Loader />;
    }

    return (
      <Formik
        initialValues={data}
        enableReinitialize
        validationSchema={UserRolesValidation}
        validateOnMount
        onSubmit={this.onSubmit}
      >
        {({ values, dirty, isValid, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Tabs label="userRoleTemplateDetails" defaultTab={0}>
              <ExpansionSection label={LocaleKeys.labels.commonInfo}>
                <RoleDescription label={LocaleKeys.labels.roleDescription} />
                <Privileges label={LocaleKeys.labels.privileges} />
              </ExpansionSection>
            </Tabs>
            <ActionPanel>
              <Button type="submit" disabled={!(dirty && isValid) || isSubmitting} id="new-userRoles-submitButton">
                {T.translate(LocaleKeys.labels.save)}
              </Button>
              <Button onClick={this.onCancel} id="new-userRoles-cancelButton">
                {T.translate(LocaleKeys.labels.cancel)}
              </Button>
            </ActionPanel>

            <SaveOnLeave saveData={() => this.saveData(values)} dataChanged={dirty} validForm={isValid} />
          </form>
        )}
      </Formik>
    );
  }
}

New.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  permissions: PropTypes.object,
};

export default compose(
  withPermissions([Permissions.CanAccessUserRolesPage, Permissions.CanReadUserRoles, Permissions.CanAddUserRoles])
)(New);
