import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import assetsPaths from 'Assets/assetsPaths';
import { NotificationStatuses } from 'Constants/NotificationStatuses';
const soundPath = assetsPaths.audio.alarm;

export const getNotificationsMap = (notifications) => {
  const notificationsMap = {};

  notifications.forEach((notification) => {
    const key = [notification.assetId, notification.alarmId];
    const value = {
      alarmStatus: notification.alarmStatus,
      isRead: notification.isRead,
      createdOn: notification.createdOn,
    };

    if (notificationsMap[key]) {
      notificationsMap[key].push(value);
    } else {
      notificationsMap[key] = [value];
    }
  });

  return notificationsMap;
};

export const shouldPlayNotification = ({ alarmStatus, isRead }) => {
  return alarmStatus === NotificationStatuses.START && !isRead;
};

export const shouldPlaySound = (notificationsMap) => {
  return !!Object.values(notificationsMap).find((notifications) => shouldPlayNotification(notifications[0]));
};

const SoundPlayer = ({ notifications }) => {
  const [audio, setAudio] = useState();
  const [forceRenderFlag, setForceRenderFlag] = useState(false);

  const playSoundMemo = useMemo(() => shouldPlaySound(getNotificationsMap(notifications)), [notifications]);

  useEffect(() => {
    const audio = new Audio(soundPath);

    audio.loop = true;
    setAudio(audio);

    return () => {
      audio.pause();
      audio.remove();
    };
  }, []);

  useEffect(() => {
    let timeoutHandler = null;

    if (audio && playSoundMemo) {
      audio.play().catch(() => {
        // retry until user will interact with the document before .play()
        timeoutHandler = setTimeout(() => setForceRenderFlag((prev) => !prev), 3000);
      });
    } else if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }

    return () => {
      clearTimeout(timeoutHandler);
    };
  }, [audio, playSoundMemo, forceRenderFlag]);

  return null;
};

SoundPlayer.propTypes = {
  notifications: PropTypes.array,
};

export default SoundPlayer;
