import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import MultiSelect from './MultiSelect';

const MultiSelectWithApiCall = ({ apiCall, ...props }) => {
  const [items, setItems] = useState();

  useEffect(() => {
    let ignore = false;

    async function fetchData() {
      const result = await apiCall();

      if (!ignore) {
        setItems(result.data);
      }
    }
    fetchData();

    return () => {
      ignore = true;
    };
  }, [apiCall]);

  return (
    <>
      {!items && <CircularProgress />}
      <MultiSelect {...props} items={items} disabled={props.disabled || !items} />
    </>
  );
};

MultiSelectWithApiCall.propTypes = {
  ...MultiSelect.propTypes,
  apiCall: PropTypes.func.isRequired,
};

MultiSelectWithApiCall.defaultProps = {
  ...MultiSelect.defaultProps,
};

export default MultiSelectWithApiCall;
