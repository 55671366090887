import React from 'react';
import LocaleKeys from 'Localization/LocaleKeys';
import ExpansionSection from 'Components/display/ExpansionPanel/ExpansionSection';
import ServiceStatus from './service/ServiceStatus';

const Service = (props) => (
  <ExpansionSection>
    <ServiceStatus label={LocaleKeys.labels.serviceStatus} />
  </ExpansionSection>
);

export default Service;
