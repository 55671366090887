import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { getMenuTemplate, postMenu, getMenuUsers } from 'Api/sites';
import { View } from './View';
import { SiteConfiguration } from 'routes/Routes';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import Loader from 'Components/Loader';
import { validationSchema } from './validationSchema';

const New = ({ history, permissions }) => {
  const [data, setData] = useState(null);
  const [users, setUsers] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [menuTemplate, menuUsers] = await Promise.all([getMenuTemplate(), getMenuUsers()]);

        if (menuTemplate.data && menuUsers.data) {
          const data = menuTemplate.data;

          Object.keys(data.items).forEach((lang) => {
            data.items[lang] = JSON.parse(data.items[lang]);
          });
          setData({ ...data, name: '' });
          setUsers(menuUsers.data);
        }
      } catch (e) {
        Sentry.captureMessage(e.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const onSubmitData = async (data) => {
    Object.keys(data.items).forEach((lang) => {
      if (typeof data.items[lang] !== 'string') {
        data.items[lang] = JSON.stringify(data.items[lang]);
      }
    });
    await postMenu(data);
    history.push(SiteConfiguration.getUrl());
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!data || !users) {
    return null;
  }

  return (
    <View
      initialData={data}
      users={users}
      canSaveChanges={permissions[Permissions.CanAddSiteConfiguration]}
      validationSchema={validationSchema(data)}
      onSubmit={onSubmitData}
    />
  );
};

export default withPermissions([
  Permissions.CanReadUsers,
  Permissions.CanReadSiteConfiguration,
  Permissions.CanAddSiteConfiguration,
])(New);
