import RoutesLocaleKeys from 'Localization/RoutesLocaleKeys';
import { Permissions } from './permissions';

export const PermissionGroups = {
  [RoutesLocaleKeys.sites]: [
    Permissions.CanAccessSitesPage,
    Permissions.CanReadSites,
    Permissions.CanAddSites,
    Permissions.CanEditSites,
    Permissions.CanRemoveSites,
  ],
  [RoutesLocaleKeys.users]: [
    Permissions.CanAccessUsersPage,
    Permissions.CanReadUsers,
    Permissions.CanAddUsers,
    Permissions.CanEditUsers,
    Permissions.CanRemoveUsers,
    Permissions.CanCopyUsers,
  ],
  [RoutesLocaleKeys.userRoles]: [
    Permissions.CanAccessUserRolesPage,
    Permissions.CanReadUserRoles,
    Permissions.CanAddUserRoles,
    Permissions.CanEditUserRoles,
    Permissions.CanRemoveUserRoles,
    Permissions.CanCopyUserRoles,
  ],
  [RoutesLocaleKeys.mapAreas]: [
    Permissions.CanAccessMapAreasPage,
    Permissions.CanReadMapAreas,
    Permissions.CanAddMapArea,
    Permissions.CanEditMapArea,
    Permissions.CanRemoveMapArea,
  ],
  [RoutesLocaleKeys.devicesConfiguration]: [
    Permissions.CanAccessDevicesPage,
    Permissions.CanReadDevices,
    Permissions.CanAddDevices,
    Permissions.CanEditDevices,
    Permissions.CanRemoveDevices,
  ],
  [RoutesLocaleKeys.libraryManagement]: [
    Permissions.CanAccessLibraryManagementPage,
    Permissions.CanReadLibraries,
    Permissions.CanAddLibraries,
    Permissions.CanEditLibraries,
    Permissions.CanRemoveLibraries,
    Permissions.CanCopyLibraries,
  ],
  [RoutesLocaleKeys.deviceDrivers]: [Permissions.CanReadDeviceDrivers],
  [RoutesLocaleKeys.dashboard]: [Permissions.CanAccessDashboardPage],
  [RoutesLocaleKeys.calendarTemplates]: [
    Permissions.CanAccessCalendarTemplatesPage,
    Permissions.CanReadCalendarTemplates,
    Permissions.CanAddCalendarTemplates,
    Permissions.CanEditCalendarTemplates,
    Permissions.CanRemoveCalendarTemplates,
    Permissions.CanCopyCalendarTemplate,
  ],
  [RoutesLocaleKeys.holidayTemplates]: [
    Permissions.CanAccessHolidaysTemplatesPage,
    Permissions.CanReadHolidaysTemplates,
    Permissions.CanAddHolidaysTemplates,
    Permissions.CanEditHolidaysTemplates,
    Permissions.CanRemoveHolidaysTemplates,
    Permissions.CanCopyHolidaysTemplate,
  ],
  [RoutesLocaleKeys.buttonsLibrary]: [
    Permissions.CanAccessButtonsLibraryPage,
    Permissions.CanReadSensitiveButtons,
    Permissions.CanReadNonSensitiveButtons,
    Permissions.CanAddButtonsLibrary,
    Permissions.CanEditButtonsLibrary,
    Permissions.CanRemoveButtonsLibrary,
    Permissions.CanCopyButtonsLibrary,
  ],
  [RoutesLocaleKeys.alarmDefinitions]: [
    Permissions.CanAccessAlarmDefinitionsPage,
    Permissions.CanReadAlarms,
    Permissions.CanAddAlarm,
    Permissions.CanEditAlarm,
    Permissions.CanRemoveAlarm,
    Permissions.CanReadAlarmNotifications,
  ],
  [RoutesLocaleKeys.alarmTracking]: [Permissions.CanAccessAlarmTrackingPage],
  [RoutesLocaleKeys.devicesGroups]: [
    Permissions.CanAccessDevicesGroupsPage,
    Permissions.CanReadDevicesGroups,
    Permissions.CanAddDevicesGroup,
    Permissions.CanEditDevicesGroup,
    Permissions.CanCopyDevicesGroup,
    Permissions.CanRemoveDevicesGroup,
  ],
  [RoutesLocaleKeys.technicalArchives]: [
    Permissions.CanAccessTechnicalArchivesPage,
    Permissions.CanReadTechnicalArchives,
  ],
  [RoutesLocaleKeys.siteGroups]: [
    Permissions.CanAccessSiteGroupsPage,
    Permissions.CanReadSiteGroups,
    Permissions.CanAddSiteGroups,
    Permissions.CanEditSiteGroups,
    Permissions.CanRemoveSiteGroups,
  ],
  [RoutesLocaleKeys.siteNotifications]: [
    Permissions.CanAccessSiteNotificationsPage,
    Permissions.CanReadSiteNotifications,
    Permissions.CanAddSiteNotifications,
    Permissions.CanEditSiteNotifications,
    Permissions.CanCopySiteNotifications,
    Permissions.CanRemoveSiteNotifications,
    Permissions.CanReadSystemNotifications,
  ],
  [RoutesLocaleKeys.technicalArchiveReport]: [
    Permissions.CanAccessTechnicalArchiveReportPage,
    Permissions.CanReadTechnicalArchiveReport,
    Permissions.CanExportArchiveReportToCsv,
    Permissions.CanExportArchiveReportToPdf,
  ],
  [RoutesLocaleKeys.siteConfiguration]: [
    Permissions.CanAccessSiteConfigurationPage,
    Permissions.CanReadSiteConfiguration,
    Permissions.CanAddSiteConfiguration,
    Permissions.CanEditSiteConfiguration,
    Permissions.CanRemoveSiteConfiguration,
  ],
};
