import React from 'react';
import PropTypes from 'prop-types';
import { getNotifications, deleteNotification } from 'Api/sites';
import Table from 'Components/display/table/Table';
import { withRouter } from 'react-router-dom';
import { SiteNotificationDetails, NewSiteNotification } from 'routes/Routes';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import DeleteDialog from 'Components/dialogs/DeleteDialog';
import { compose } from 'redux';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import Loader from 'Components/Loader';
import { Edit, Delete, FileCopy } from '@material-ui/icons';
import TableActionButton from 'Components/display/table/TableActionButton';
import { isDate } from 'Helpers/DateTimeHelpers';

const convertDate = (date) => (isDate(date) ? new Date(date).toLocaleString() : '');

const formatDataForTableView = (siteNotications) =>
  Object.values(siteNotications).map((siteNotification) => ({
    id: siteNotification.id,
    createdOn: convertDate(siteNotification.createdOn),
    startFrom: convertDate(siteNotification.startFrom),
    externalId: siteNotification.externalId,
    startFromRaw: siteNotification.startFrom,
    activeTo: siteNotification.displayAlwaysOnFeed ? '-' : convertDate(siteNotification.activeTo),
    createdBy: siteNotification.createdBy,
    content: siteNotification.content,
    sitesCount: siteNotification.sitesCount || '-',
    sites: siteNotification.allSites
      ? T.translate(LocaleKeys.labels.all).toUpperCase()
      : siteNotification.sites.join(', '),
  }));

export class SiteNotifications extends React.Component {
  state = {
    data: [],
    isLoaded: false,
    deleteNotificationDialogOpen: false,
    itemToRemove: null,
  };

  componentDidMount = () => {
    this.getInitialData();
  };

  getInitialData = () => {
    getNotifications()
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          data: response.data,
          isLoaded: false,
        }));
      })
      .then(() => {
        this.setState((prevState) => ({ ...prevState, isLoaded: true }));
      });
  };

  showDeleteDialog = (id) => {
    this.setState({ deleteNotificationDialogOpen: true, itemToRemove: id });
  };

  handleDeleteNotificationCancel = () => {
    this.setState({ deleteNotificationDialogOpen: false, itemToRemove: null });
  };

  handleDeleteNotificationConfirm = () => {
    return deleteNotification(this.state.itemToRemove).then(() => {
      this.setState({ deleteNotificationDialogOpen: false, itemToRemove: null });
      this.getInitialData();
    });
  };

  rowDoubleClick = (externalId) => {
    const url = SiteNotificationDetails.getUrl({
      externalId: externalId,
    });

    this.props.history.push(url);
  };

  onAddClick = () => {
    const url = NewSiteNotification.getUrl();

    this.props.history.push(url);
  };

  handleCopyClick = (id) => {
    const url = NewSiteNotification.getUrl();

    this.props.history.push(url, { id });
  };

  getTableActionButtons = () => {
    const actionButtons = [];

    if (this.props.permissions[Permissions.CanEditSiteNotifications]) {
      const editItemButton = (
        <TableActionButton
          key="editItemButton"
          onClick={this.rowDoubleClick}
          clickparam={'externalId'}
          icon={<Edit />}
          tooltipText={T.translate(LocaleKeys.tooltips.editItem)}
          disabledTooltipText={T.translate(LocaleKeys.tooltips.thisSiteNotificationCannotBeEdited)}
          name="edit"
        />
      );

      actionButtons.push(editItemButton);
    }
    if (this.props.permissions[Permissions.CanCopySiteNotifications]) {
      const copyItemButton = (
        <TableActionButton
          key="copyItemButton"
          onClick={this.handleCopyClick}
          clickparam={'id'}
          icon={<FileCopy />}
          tooltipText={T.translate(LocaleKeys.tooltips.copyItem)}
          name="copy"
        />
      );

      actionButtons.push(copyItemButton);
    }
    if (this.props.permissions[Permissions.CanRemoveSiteNotifications]) {
      const deleteItemButton = (
        <TableActionButton
          key="deleteItemButton"
          onClick={this.showDeleteDialog}
          clickparam={'id'}
          icon={<Delete />}
          tooltipText={T.translate(LocaleKeys.tooltips.deleteItem)}
          name="delete"
        />
      );

      actionButtons.push(deleteItemButton);
    }

    return actionButtons;
  };

  render() {
    const { isLoaded, data } = this.state;

    if (!isLoaded) {
      return <Loader />;
    }

    const tableData = formatDataForTableView(data);
    const actionButtons = this.getTableActionButtons();

    return (
      <>
        <DeleteDialog
          isOpen={this.state.deleteNotificationDialogOpen}
          onConfirm={this.handleDeleteNotificationConfirm}
          onCancel={this.handleDeleteNotificationCancel}
          disableConfirm={!this.state.itemToRemove}
          title={T.translate(LocaleKeys.messages.deleteSiteNotificationDialogTitle)}
          message={T.translate(LocaleKeys.messages.deleteSiteNotificationDialogMessage)}
        />
        <Table
          data={tableData}
          onRowDoubleClick={(id, row) => this.rowDoubleClick(row.externalId)}
          disableCondition={(rowData) => {
            return { edit: new Date(rowData.startFromRaw) < new Date() };
          }}
          onAddClick={this.props.permissions[Permissions.CanAddSiteNotifications] && this.onAddClick}
          redirect={'id'}
          hideColumns={['id', 'startFromRaw', 'externalId']}
          actionButtons={actionButtons}
          tableName={'siteNotifications'}
        />
      </>
    );
  }
}

SiteNotifications.propTypes = {
  history: PropTypes.object,
  permissions: PropTypes.object,
};

export default compose(
  withPermissions([Permissions.CanAccessSiteNotificationsPage, Permissions.CanReadSiteNotifications]),
  withRouter
)(SiteNotifications);
