import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, getIn } from 'formik';
import { Box, Typography, styled } from '@material-ui/core';
import Section from './Section';

const TypographyStyled = styled(Typography)({
  margin: 10,
});

const Formula = (props) => {
  return (
    <FieldArray name={`${props.name}.sections`}>
      {({ name, form, push, remove }) => {
        const sections = getIn(form.values, name);

        return (
          <Box display="flex" flexDirection="column" width="100%" marginRight="10px">
            {sections.map((section, index) => {
              const isLastSection = sections.length - 1 === index;

              return (
                <React.Fragment key={`${name}.${index}`}>
                  <Section
                    name={`${name}.${index}`}
                    dictionaries={props.dictionaries}
                    inputMappings={props.inputMappings}
                    addSection={() => push({ conditions: [{ targetValues: [] }] })}
                    removeSection={() => remove(index)}
                    isLastSection={isLastSection}
                    isFirstSection={index === 0}
                  />
                  {!isLastSection && <TypographyStyled>OR</TypographyStyled>}
                </React.Fragment>
              );
            })}
          </Box>
        );
      }}
    </FieldArray>
  );
};

Formula.propTypes = {
  name: PropTypes.string,
  dictionaries: PropTypes.object,
  inputMappings: PropTypes.array,
};

export default Formula;
