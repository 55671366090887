import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import { styled, CircularProgress } from '@material-ui/core';
import { Edit, FileCopy, Delete } from '@material-ui/icons';
import Table from 'Components/display/table/Table';
import TableActionButton from 'Components/display/table/TableActionButton';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import DeleteDialog from 'Components/dialogs/DeleteDialog';
import { DevicesGroupsDetails, DevicesGroupsNew } from 'routes/Routes';
import { getDevicesBasicInformation, getDevicesGroups, deleteDevicesGroup } from 'Api/devices';
import { isDate } from 'Helpers/DateTimeHelpers';

const TableStyled = styled(Table)({
  padding: 20,
});

const formatDataForTableView = (devicesGroups, devices) =>
  Object.values(devicesGroups).map((group) => ({
    externalId: group.externalId,
    name: group.name,
    devices: formatDevicesCell(group.devices, devices),
    createdAt: isDate(group.createdAt) ? new Date(group.createdAt).toLocaleString() : '',
    author: group.createdBy,
  }));

const formatDevicesCell = (assignedDevices, devices) => {
  return assignedDevices
    .map((externalId) => devices.find((device) => device.externalId === externalId)?.name)
    .join(', ');
};

const DevicesGroups = ({ permissions, ...props }) => {
  const [devicesGroups, setDevicesGroups] = useState(null);
  const [devices, setDevices] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [itemToHandle, setItemToHandle] = useState(null);

  useEffect(() => {
    Promise.all([getDevicesGroups(), getDevicesBasicInformation()]).then(([devicesGroupsResponse, devicesResponse]) => {
      setDevicesGroups(devicesGroupsResponse.data?.deviceGroups);
      setDevices(devicesResponse.data);
    });
  }, []);

  if (!devices || !devicesGroups) {
    return <CircularProgress />;
  }

  const onEditClick = (name) => {
    const url = DevicesGroupsDetails.getUrl({ name: name.replace(/\s/g, '-') });

    props.history.push(url);
  };

  const onAddClick = () => {
    const url = DevicesGroupsNew.getUrl();

    props.history.push(url);
  };

  const getTableActionButtons = () => {
    const actionButtons = [];

    if (permissions[Permissions.CanEditDevicesGroup]) {
      const editItemButton = (
        <TableActionButton
          key="editItemButton"
          onClick={onEditClick}
          clickparam={'name'}
          icon={<Edit />}
          tooltipText={T.translate(LocaleKeys.tooltips.editItem)}
        />
      );

      actionButtons.push(editItemButton);
    }

    if (permissions[Permissions.CanCopyDevicesGroup]) {
      const copyItemButton = (
        <TableActionButton
          key="copyItemButton"
          onClick={(basedOn) => {
            const url = DevicesGroupsNew.getUrl();

            props.history.push(url, { basedOn });
          }}
          clickparam={'name'}
          icon={<FileCopy />}
          tooltipText={T.translate(LocaleKeys.tooltips.copyItem)}
        />
      );

      actionButtons.push(copyItemButton);
    }
    if (permissions[Permissions.CanRemoveDevicesGroup]) {
      const deleteItemButton = (
        <TableActionButton
          key="deleteItemButton"
          onClick={(externalId) => {
            setDialogOpen(true);
            setItemToHandle(externalId);
          }}
          clickparam={'externalId'}
          icon={<Delete />}
          tooltipText={T.translate(LocaleKeys.tooltips.deleteItem)}
        />
      );

      actionButtons.push(deleteItemButton);
    }

    return actionButtons;
  };

  const formattedTableData = formatDataForTableView(devicesGroups, devices);
  const actionButtons = getTableActionButtons();

  return (
    <>
      <TableStyled
        data={formattedTableData}
        onRowDoubleClick={(id, row) => onEditClick(row.name)}
        onAddClick={permissions[Permissions.CanAddDevicesGroup] && onAddClick}
        redirect={'externalId'}
        hideColumns={['externalId']}
        actionButtons={actionButtons}
        tableName="devicesGroups"
      />
      <DeleteDialog
        isOpen={dialogOpen}
        onConfirm={() => {
          return deleteDevicesGroup(itemToHandle).then(() => {
            setDevicesGroups((prev) => prev.filter((group) => group.externalId !== itemToHandle));
            setItemToHandle(null);
            setDialogOpen(false);
          });
        }}
        onCancel={() => {
          setItemToHandle(null);
          setDialogOpen(false);
        }}
        disableConfirm={!itemToHandle}
        title={T.translate(LocaleKeys.messages.deleteDevicesGroupDialogTitle)}
        message={T.translate(LocaleKeys.messages.deleteDevicesGroupDialogMessage)}
      />
    </>
  );
};

DevicesGroups.propTypes = {
  history: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  withPermissions([
    Permissions.CanAccessDevicesGroupsPage,
    Permissions.CanReadDevicesGroups,
    Permissions.CanReadDevices,
  ])
)(DevicesGroups);
