import React, { useRef, useEffect } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { Close, ArrowDropDown } from '@material-ui/icons/';
import { TextField, InputAdornment, styled, Box, IconButton, Tooltip } from '@material-ui/core';
import * as configurationStore from 'Api/configurationStore';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import PropTypes from 'prop-types';

const Dropdown = styled('div')({
  maxHeight: '600px',
  border: '0.5px solid #9e9e9c',
  borderTop: 'none',
  borderRadius: 7,
  width: '100%',
  position: 'absolute',
  backgroundColor: 'white',
  zIndex: 1000,
  overflow: 'auto',
});

const CloseIcon = styled(Close)({
  paddingTop: 5,
  color: '#9e9e9c',
  cursor: 'pointer',
});

const AddLabel = styled('div')({
  color: '#3f51b5',
  cursor: 'pointer',
});

const MenuItemStyled = styled(MenuItem)({
  color: '#636363',
  width: '100%',
  zIndex: 100,
});

const TextFieldStyled = styled(TextField)({
  maxLength: '50',
});

const sortEntries = (entries) => {
  return entries.sort((a, b) => (a.name < b.name ? -1 : 1));
};

export const Filters = ({ template, setTemplate, onChange, type, style }) => {
  const [templates, setTemplates] = React.useState([]);
  const [optionsOpen, setOptionsOpen] = React.useState(false);

  useEffect(() => {
    configurationStore.getValue(`${type}-Filters`).then(({ data }) => {
      if (Array.isArray(data)) {
        setTemplates(sortEntries(data));
      }
    });
  }, [type]);

  const useOutsideHandler = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target) && event.target?.name !== 'filterInput') {
          setOptionsOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const getOption = (option) => {
    return <Box width="85%">{option.name.length > 20 ? `${option.name.substring(0, 20)}...` : option.name}</Box>;
  };

  const wrapperRef = useRef(null);

  useOutsideHandler(wrapperRef);

  const hideAddLabel = () => {
    if (!template.name) {
      return true;
    }

    return !!templates.find((t) => t.name === template.name);
  };

  const handleDelete = (e, option) => {
    const newTemplates = templates.filter((t) => t.name !== option.name);

    configurationStore.putValueCreateOrUpdate(`${type}-Filters`, { value: JSON.stringify(newTemplates) }).then(() => {
      setTemplates(newTemplates);
    });
    e.stopPropagation();
  };

  const handleAdd = (e) => {
    const newTemplates = [...templates, template];

    configurationStore.putValueCreateOrUpdate(`${type}-Filters`, { value: JSON.stringify(newTemplates) }).then(() => {
      setTemplates(sortEntries(newTemplates));
    });
    e.stopPropagation();
  };

  const handleSave = (e) => {
    const newTemplates = templates.filter((t) => t.name !== template.name);

    newTemplates.push(template);

    configurationStore.putValueCreateOrUpdate(`${type}-Filters`, { value: JSON.stringify(newTemplates) }).then(() => {
      setTemplates(sortEntries(newTemplates));
    });
    e.stopPropagation();
  };

  return (
    <Box flexDirection="column" position="relative" style={style}>
      <TextFieldStyled
        name="filterInput"
        value={template.name}
        label={T.translate(LocaleKeys.labels.saveOpenCriteria)}
        variant="outlined"
        onChange={onChange}
        fullWidth
        placeholder={T.translate(LocaleKeys.labels.enterCriteriaName)}
        onClick={() => setOptionsOpen((prev) => !prev)}
        autoComplete="off"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {!hideAddLabel() ? (
                <AddLabel name="filterInput" onClick={handleAdd}>
                  {T.translate(LocaleKeys.labels.add)}
                </AddLabel>
              ) : (
                template.name && (
                  <AddLabel name="filterInput" onClick={handleSave}>
                    {T.translate(LocaleKeys.labels.save)}
                  </AddLabel>
                )
              )}
              <ArrowDropDown name="filterInput" style={{ cursor: 'pointer', color: '#757575' }} />
            </InputAdornment>
          ),
        }}
      />

      {optionsOpen && templates.length > 0 && (
        <Dropdown ref={wrapperRef}>
          {templates.map((option) => (
            <Box key={option.name} width="100%" display="flex">
              <MenuItemStyled
                value={option.name}
                onClick={() => {
                  setTemplate(option);
                  setOptionsOpen(false);
                }}
              >
                {option.name.length > 20 ? (
                  <Tooltip title={option.name} placement="left-start">
                    {getOption(option)}
                  </Tooltip>
                ) : (
                  getOption(option)
                )}
                <Box width="15%">
                  <IconButton onClick={(e) => handleDelete(e, option)}>
                    <CloseIcon onClick={(e) => handleDelete(e, option)} />
                  </IconButton>
                </Box>
              </MenuItemStyled>
            </Box>
          ))}
        </Dropdown>
      )}
    </Box>
  );
};

Filters.propTypes = {
  template: PropTypes.object.isRequired,
  setTemplate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default Filters;
