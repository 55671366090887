import RouteInfo from './RouteInfo';
import { AppRoutes } from './paths';

export const Callback = new RouteInfo({
  path: AppRoutes.CALLBACK,
});

export const SilentRefreshCallback = new RouteInfo({
  path: AppRoutes.SILENT_REFRESH_CALLBACK,
});

// ROOT
export const Root = new RouteInfo({
  path: AppRoutes.ROOT,
  params: {
    siteName: ':siteName',
  },
});

export const Dashboard = new RouteInfo({
  path: AppRoutes.DASHBOARD,
  parentRoute: Root,
});

// ACCOUNT

export const Account = new RouteInfo({
  path: AppRoutes.ACCOUNT,
  parentRoute: Root,
});

export const ForgotPassword = new RouteInfo({
  path: AppRoutes.FORGOT_PASSWORD,
  parentRoute: Account,
});

export const SetNewPassword = new RouteInfo({
  path: AppRoutes.NEW_PASSWORD,
  parentRoute: Account,
});

// HOME

export const Home = new RouteInfo({
  path: AppRoutes.HOME,
  parentRoute: Root,
});

// Sites

export const Sites = new RouteInfo({
  path: AppRoutes.SITES,
  parentRoute: Root,
});

export const SiteDetails = new RouteInfo({
  path: AppRoutes.EDIT,
  parentRoute: Sites,
  params: {
    name: ':name',
  },
});

export const NewSite = new RouteInfo({
  path: AppRoutes.NEW,
  parentRoute: Sites,
});

// Site Group

export const SitesGroups = new RouteInfo({
  path: AppRoutes.SITES_GROUPS,
  parentRoute: Root,
});

export const SitesGroupsNew = new RouteInfo({
  path: AppRoutes.NEW,
  parentRoute: SitesGroups,
});

export const SitesGroupsDetails = new RouteInfo({
  path: AppRoutes.EDIT,
  parentRoute: SitesGroups,
  params: {
    name: ':name',
  },
});

// Site Notifications

export const SiteNotifications = new RouteInfo({
  path: AppRoutes.SITE_NOTIFICATIONS,
  parentRoute: Root,
});

export const SiteNotificationDetails = new RouteInfo({
  path: AppRoutes.EDIT,
  parentRoute: SiteNotifications,
  params: {
    externalId: ':externalId',
  },
});

export const NewSiteNotification = new RouteInfo({
  path: AppRoutes.NEW,
  parentRoute: SiteNotifications,
});

// Users
export const Users = new RouteInfo({
  path: AppRoutes.USERS,
  parentRoute: Root,
});

export const UsersDetails = new RouteInfo({
  path: AppRoutes.EDIT,
  parentRoute: Users,
  params: {
    userLogin: ':userLogin',
  },
});

export const NewUser = new RouteInfo({
  path: AppRoutes.NEW,
  parentRoute: Users,
});

// UserRoles

export const UserRoles = new RouteInfo({
  path: AppRoutes.USER_ROLES,
  parentRoute: Root,
});

export const UserRolesDetails = new RouteInfo({
  path: AppRoutes.EDIT,
  parentRoute: UserRoles,
  params: {
    name: ':name',
  },
});

export const NewUserRoles = new RouteInfo({
  path: AppRoutes.NEW,
  parentRoute: UserRoles,
});

// Devices
export const DevicesConfiguration = new RouteInfo({
  path: AppRoutes.DEVICES_CONFIGURATION,
  parentRoute: Root,
});

export const DeviceConfigurationDetails = new RouteInfo({
  path: AppRoutes.EDIT,
  parentRoute: DevicesConfiguration,
  params: {
    name: ':name',
  },
});

export const NewDeviceConfiguration = new RouteInfo({
  path: AppRoutes.NEW,
  parentRoute: DevicesConfiguration,
});

// Tracking

export const MapAreas = new RouteInfo({
  path: AppRoutes.MAP_AREAS,
  parentRoute: Root,
});

export const NewMapArea = new RouteInfo({
  path: AppRoutes.NEW,
  parentRoute: MapAreas,
});

export const MapDetails = new RouteInfo({
  path: AppRoutes.EDIT,
  parentRoute: MapAreas,
  params: {
    name: ':name',
  },
});

export const StatusAndAlarmDefinitions = new RouteInfo({
  path: AppRoutes.ALARMS_DEFINITIONS,
  parentRoute: Root,
});

export const StatusAndAlarmDefinitionsDetails = new RouteInfo({
  path: AppRoutes.EDIT,
  parentRoute: StatusAndAlarmDefinitions,
  params: {
    name: ':name',
  },
});

export const NewStatusAndAlarmDefinitions = new RouteInfo({
  path: AppRoutes.NEW,
  parentRoute: StatusAndAlarmDefinitions,
});

// Alarm tracking
export const AlarmTracking = new RouteInfo({
  path: AppRoutes.ALARM_TRACKING,
  parentRoute: Root,
});

// Calendar templates

export const CalendarTemplates = new RouteInfo({
  path: AppRoutes.CALENDAR_TEMPLATES,
  parentRoute: Root,
});

export const CalendarDetails = new RouteInfo({
  path: AppRoutes.EDIT,
  parentRoute: CalendarTemplates,
  params: {
    name: ':name',
  },
});

export const CalendarNew = new RouteInfo({
  path: AppRoutes.NEW,
  parentRoute: CalendarTemplates,
});

// Holiday templates
export const HolidayTemplates = new RouteInfo({
  path: AppRoutes.HOLIDAY_TEMPLATES,
  parentRoute: Root,
});

export const HolidayDetails = new RouteInfo({
  path: AppRoutes.EDIT,
  parentRoute: HolidayTemplates,
  params: {
    name: ':name',
  },
});

export const HolidayNew = new RouteInfo({
  path: AppRoutes.NEW,
  parentRoute: HolidayTemplates,
});

// Library Management
export const LibraryManagement = new RouteInfo({
  path: AppRoutes.LIBRARY_MANAGEMENT,
  parentRoute: Root,
});

export const LibraryNew = new RouteInfo({
  path: AppRoutes.NEW,
  parentRoute: LibraryManagement,
});

export const LibraryDetails = new RouteInfo({
  path: AppRoutes.EDIT,
  parentRoute: LibraryManagement,
  params: {
    name: ':name',
  },
});

// Buttons Library
export const ButtonsLibrary = new RouteInfo({
  path: AppRoutes.BUTTONS_LIBRARY,
  parentRoute: Root,
});

export const ButtonsLibraryDetails = new RouteInfo({
  parentRoute: ButtonsLibrary,
  path: AppRoutes.EDIT,
  params: {
    name: ':name',
  },
});

export const ButtonsLibraryNew = new RouteInfo({
  parentRoute: ButtonsLibrary,
  path: AppRoutes.NEW,
});

// Technical Archive
export const TechnicalArchive = new RouteInfo({
  path: AppRoutes.TECHNICAL_ARCHIVE,
  parentRoute: Root,
});

// Devices Groups
export const DevicesGroups = new RouteInfo({
  path: AppRoutes.DEVICES_GROUPS,
  parentRoute: Root,
});

export const DevicesGroupsNew = new RouteInfo({
  path: AppRoutes.NEW,
  parentRoute: DevicesGroups,
});

export const DevicesGroupsDetails = new RouteInfo({
  path: AppRoutes.EDIT,
  parentRoute: DevicesGroups,
  params: {
    name: ':name',
  },
});

export const Archive = new RouteInfo({
  path: AppRoutes.ARCHIVE,
  parentRoute: Root,
});

export const SiteConfiguration = new RouteInfo({
  path: AppRoutes.SITE_CONFIGURATION,
  parentRoute: Root,
});

export const SiteConfigurationNew = new RouteInfo({
  path: AppRoutes.NEW,
  parentRoute: SiteConfiguration,
});

export const SiteConfigurationDetails = new RouteInfo({
  path: AppRoutes.EDIT,
  parentRoute: SiteConfiguration,
  params: {
    name: ':name',
  },
});
