import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { getMenusName, getMenuUsers, putMenu } from 'Api/sites';
import { SiteConfiguration } from 'routes/Routes';
import { View } from './View';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import Loader from 'Components/Loader';
import { validationSchema } from './validationSchema';

const Edit = ({
  match: {
    params: { name },
  },
  history,
  permissions,
}) => {
  const [data, setData] = useState(null);
  const [users, setUsers] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [menusName, menuUsers] = await Promise.all([getMenusName(name.replace(/-/g, ' ')), getMenuUsers()]);

        if (menusName.data && menuUsers.data) {
          const data = menusName.data;

          Object.keys(data.items).forEach((lang) => {
            data.items[lang] = JSON.parse(data.items[lang]);
          });
          setData(data);
          setUsers(menuUsers.data);
        }
      } catch (e) {
        Sentry.captureMessage(e.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [name]);

  const onSubmitData = async (data) => {
    Object.keys(data.items).forEach((lang) => {
      if (typeof data.items[lang] !== 'string') {
        data.items[lang] = JSON.stringify(data.items[lang]);
      }
    });
    await putMenu(data.externalId, data);
    history.push(SiteConfiguration.getUrl());
  };

  if (isLoading) {
    return <Loader />;
  }
  if (!data || !users) {
    return null;
  }

  return (
    <View
      initialData={data}
      users={users}
      canSaveChanges={permissions[Permissions.CanEditSiteConfiguration]}
      validationSchema={validationSchema(data)}
      onSubmit={onSubmitData}
    />
  );
};

Edit.propTypes = {
  name: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

export default withPermissions([Permissions.CanReadSiteConfiguration, Permissions.CanReadUsers])(Edit);
