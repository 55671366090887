import React, { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { getValue, putValueCreateOrUpdate } from 'Api/configurationStore';
import { LayerTypes } from 'Components/LeafletMap/Layers';
import { initialLayersConfigDefaultValue } from 'Components/LeafletMap/index';

export const withConfigurationStoreLayers =
  (WrappedComponent) =>
  ({ configurationStoreKey, ...props }) => {
    const [initialLayersConfig, setInitialLayersConfig] = useState(initialLayersConfigDefaultValue);
    const [isLoading, setIsLoading] = useState(false);

    const addEventHandlers = (e) => {
      const map = e.target;

      map.on('baselayerchange', (event) => {
        const { layerKey, layerType } = event.layer.options;

        if (layerKey && layerType === LayerTypes.BASE) {
          setInitialLayersConfig((prev) => {
            const value = { ...prev, base: layerKey };

            putValueCreateOrUpdate(configurationStoreKey, { value: JSON.stringify(value) });

            return value;
          });
        }
      });
      map.on('overlayadd', (event) => {
        const { layerKey, layerType } = event.layer.options;

        if (layerKey && layerType === LayerTypes.OVERLAY) {
          setInitialLayersConfig((prev) => {
            const value = {
              ...prev,
              overlays: [...prev.overlays, layerKey],
            };

            putValueCreateOrUpdate(configurationStoreKey, { value: JSON.stringify(value) });

            return value;
          });
        }
      });
      map.on('overlayremove', (event) => {
        const { layerKey, layerType } = event.layer.options;

        if (layerKey && layerType === LayerTypes.OVERLAY) {
          setInitialLayersConfig((prev) => {
            const value = {
              ...prev,
              overlays: prev.overlays.filter((o) => o !== layerKey),
            };

            putValueCreateOrUpdate(configurationStoreKey, { value: JSON.stringify(value) });

            return value;
          });
        }
      });
    };

    useEffect(() => {
      if (!configurationStoreKey || typeof configurationStoreKey !== 'string') {
        throw new Error(
          `Invalid configurationStoreKey property passed to withConfigurationStoreLayers(${WrappedComponent.displayName})`
        );
      }

      setIsLoading(true);
      getValue(configurationStoreKey)
        .then((response) => {
          if (response.data) {
            setInitialLayersConfig(response.data);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, [configurationStoreKey]);

    if (isLoading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      );
    }

    return <WrappedComponent {...props} onWhenReady={addEventHandlers} initialLayersConfig={initialLayersConfig} />;
  };
