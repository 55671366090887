import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Loader from 'Components/Loader';
import { getLoggedUserSite } from 'Api/sites';
import { withRouter } from 'react-router-dom';

class RedirectToDefaultSite extends Component {
  state = {
    errorMessage: '',
  };

  async componentDidMount() {
    if (this.props.currentSite) {
      this.props.history.push(this.props.currentSite);

      return;
    }
    try {
      const { data: siteData } = await getLoggedUserSite();

      if (siteData && siteData.general) {
        this.props.history.push(`/${siteData.general.name.replace(/\s/g, '-')}`);
      } else {
        Sentry.captureMessage('can not be determined page url');
        alert('Can not be determined page url');
      }
    } catch (error) {
      Sentry.captureMessage(error);
      alert('Error during try get user info');
    }
  }

  render() {
    return <Loader />;
  }
}

const mapStateToProps = ({ sites: { currentSite } }) => ({ currentSite });

export default compose(withRouter, connect(mapStateToProps))(RedirectToDefaultSite);
