import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { styled, Box } from '@material-ui/core';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import { withFormik } from 'Components/formik/formikWrappers';
import FormikCollection from 'Components/inputs/CollectionManager/FormikCollection';
import { getInitialTime } from '../helpers';
import { subtractCurrentTimezoneOffset, addCurrentTimezoneOffset } from 'Helpers/DateTimeHelpers';
import { Checkbox as CheckboxRaw, DateTimeInput as DateTimeInputRaw } from 'Components/formik/fields';

const inputStyles = {
  margin: '0 5px',
  height: '100%',
};
const Checkbox = styled(CheckboxRaw)(inputStyles);
const DateTimeInput = styled(DateTimeInputRaw)(inputStyles);

export const WeeklyActivitySchedule = ({ data, onChange }) => {
  return (
    data && (
      <>
        {Object.keys(data.workdays).map((day) => {
          return (
            <React.Fragment key={day}>
              <ColumnDisplayer>
                <Checkbox
                  label={T.translate(day)}
                  name={`workdays.${day}.operationDay.isEnabled`}
                  value={day.operationDay && day.operationDay.isEnabled}
                />
              </ColumnDisplayer>
              <Box margin={'10px 4%'}>
                <ColumnDisplayer>
                  <FormikCollection
                    name={`workdays.${day}.operationDay.timeEntries`}
                    label={T.translate(LocaleKeys.labels.weeklyActivitySchedule)}
                    defaultValue={{
                      from: subtractCurrentTimezoneOffset(getInitialTime(8)),
                      to: subtractCurrentTimezoneOffset(getInitialTime(16)),
                    }}
                  >
                    <DateTimeInput
                      label={T.translate(LocaleKeys.labels.from)}
                      type="time"
                      name="from"
                      onChange={(e) => {
                        e.target.value = subtractCurrentTimezoneOffset(e.target.value);
                        onChange(e);
                      }}
                      convertValue={addCurrentTimezoneOffset}
                    />
                    <DateTimeInput
                      label={T.translate(LocaleKeys.labels.to)}
                      type="time"
                      name="to"
                      onChange={(e) => {
                        e.target.value = subtractCurrentTimezoneOffset(e.target.value);
                        onChange(e);
                      }}
                      convertValue={addCurrentTimezoneOffset}
                    />
                  </FormikCollection>
                </ColumnDisplayer>
              </Box>
            </React.Fragment>
          );
        })}
      </>
    )
  );
};

WeeklyActivitySchedule.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
};

export default withFormik(WeeklyActivitySchedule);
