import React, { useState, useEffect, Fragment } from 'react';
import { styled } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  TextField,
  InputAdornment,
  TableFooter,
  CircularProgress,
  Paper,
  TablePagination,
  TableSortLabel,
} from '@material-ui/core';
import DateTimeInput from 'Components/inputs/DateTime';
import { Search as SearchIcon } from '@material-ui/icons';
import LabelsLocaleKeys from 'Localization/LabelsLocaleKeys';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import { getDevicesBasicInformationBySite } from 'Api/devices';
import { getSites } from 'Api/sites';
import { getArchive } from 'Api/stateManagement';
import Dropdown from 'Components/inputs/Dropdown';
import DropdownWithApiCall from 'Components/inputs/DropdownWithApiCall';
import withPermissions from 'hocs/withPermissions';
import { CommandStatuses } from 'Constants/CommandStatuses';
import { Permissions } from 'Constants/permissions';
import Filters from './TechnicalArchiveFilters';
import DropdownWithFilter from 'Components/inputs/DropdownWithFilter';
import { Formik, useField, useFormikContext } from 'formik';
import TechnicalArchiveValidation from './TechnicalArchiveValidation';
import { roundCoordinate } from '../../reports/archive';

const COMMAND_STATUSES = {
  [CommandStatuses.BEING_SENT]: LabelsLocaleKeys.beingSent,
  [CommandStatuses.SENT]: LabelsLocaleKeys.sent,
  [CommandStatuses.DELIVERED]: LabelsLocaleKeys.delivered,
  [CommandStatuses.UNDELIVERED]: LabelsLocaleKeys.undelivered,
  [CommandStatuses.FAILED]: LabelsLocaleKeys.failed,
};

const TableRowStyled = styled(TableRow)(({ style }) => ({
  backgroundColor: 'white',
  '&:nth-child(odd)': {
    backgroundColor: '#f2f2f2',
  },
  '&:hover': {
    backgroundColor: '#e5f1d5',
  },
  '& td': style,
}));

const DatePickerStyled = styled(DateTimeInput)({
  width: '290px',
});

const DropdownWithApiCallStyled = styled(DropdownWithApiCall)({
  width: '250px',
});

const DropdownStyled = styled(Dropdown)(({ style }) => ({
  width: '250px',
  ...style,
}));

const DropdownWithFilterStyled = styled(DropdownWithFilter)(({ style }) => ({
  width: '250px',
  ...style,
}));

const messageDividerStyles = {
  color: '#b1aab1',
  display: 'flex',
  justifyContent: 'space-around',
  marginTop: '30px',
  textTransform: 'uppercase',
};

const incomingMessageStyles = {
  textAlign: 'right',
  width: '33%',
};

const outgoingMessageStyles = {
  textAlign: 'center',
  width: '15%',
};

const Loading = () => (
  <Box align="center" p={4}>
    <CircularProgress />
  </Box>
);

const messageTypes = {
  SHOW_ALL: 'showAll',
  SHOW_TRANSLATED_MESSAGE: 'showTranslatedMessage',
  SHOW_RAW_MESSAGE: 'showRawMessage',
};

const messages = [messageTypes.SHOW_ALL, messageTypes.SHOW_TRANSLATED_MESSAGE, messageTypes.SHOW_RAW_MESSAGE];

const PaperStyled = styled(Paper)({
  paddingLeft: '24px',
  paddingRight: '24px',
  paddingTop: '24px',
  paddingBottom: '24px',
});

const TableStyled = styled(Table)({
  marginTop: '10px',
});

const TableHeaderCell = styled(TableCell)({
  fontWeight: 'bold',
  borderBottom: 'none',
  borderLeftStyle: 'solid',
  borderLeft: '1px',
  borderLeftColor: 'white',
});

const TableCellStyled = styled(TableCell)({
  borderBottom: 'none',
  borderLeftStyle: 'solid',
  borderLeft: '1px',
  borderLeftColor: 'white',
});

const borderRight = {
  borderRight: '2px solid #cccccc',
  borderBottom: 'none',
};

const Row = ({ style = {}, ...props }) => (
  <TableRowStyled style={style}>
    <TableCellStyled align="center">{props.name}</TableCellStyled>
    <TableCellStyled align="center">{props.imei}</TableCellStyled>
    <TableCellStyled align="center">{props.date}</TableCellStyled>
    <TableCellStyled align="center" style={props.template.direction === 0 ? borderRight : null}>
      {props.time}
    </TableCellStyled>
    {props.template.direction !== 2 && (
      <>
        <TableCellStyled align="center">{props.latitude}</TableCellStyled>
        <TableCellStyled align="center">{props.longitude}</TableCellStyled>
        <TableCellStyled align="center">{props.eventKeyword}</TableCellStyled>
        <TableCellStyled align="center" style={props.template.direction === 0 ? borderRight : null}>
          {props.speed}
        </TableCellStyled>
      </>
    )}
    {props.template.direction !== 1 && (
      <>
        <TableCellStyled align="center">{props?.commandName || '-'}</TableCellStyled>
        <TableCellStyled align="center">{props.command}</TableCellStyled>
        <TableCellStyled align="center">{props.login}</TableCellStyled>
        <TableCellStyled align="center">{props.reason}</TableCellStyled>
        <TableCellStyled align="center">{props.isDelivered}</TableCellStyled>
      </>
    )}
  </TableRowStyled>
);

const getDate = (timestamp) => {
  const date = new Date(timestamp);

  return date.toLocaleDateString();
};

const getTime = (timestamp) => {
  const date = new Date(timestamp);

  return date.toLocaleTimeString();
};

const skipRow = (entry, search) => {
  let skip = true;

  if (search === '') {
    return false;
  }

  const SearchProperties = ['name', 'imei', 'keyword', 'srcKeyword', 'command', 'login', 'reason'];
  const DictionaryProperties = { commandStatus: COMMAND_STATUSES };
  const TranslateProperties = ['commandStatus'];
  const preparedEntry = Object.fromEntries(
    Object.entries(entry)
      .map(([key, value]) => {
        if (!SearchProperties.includes(key)) {
          return null;
        }
        if (value && Object.keys(DictionaryProperties).includes(key)) {
          value = DictionaryProperties[key][value];
        }
        if (value && TranslateProperties.includes(key)) {
          value = T.translate(value);
        }

        return [key, value];
      })
      .filter((entry) => !!entry)
  );

  Object.values(preparedEntry).forEach((value) => {
    if (value) {
      if (typeof value !== 'string') {
        value = toString(value);
      }
      if (value.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
        skip = false;
      }
    }
  });

  return skip;
};

const getFromDate = () => {
  const date = new Date();

  date.setHours(0, 0, 0);

  return date.toISOString();
};

const DatePickerStyledWithField = (props) => {
  const [field, meta] = useField(props.name);

  return (
    <DatePickerStyled
      {...props}
      onChange={(event) => {
        field.onChange(event);
        props.onChange(event);
      }}
      value={field.value}
      validationerrorlocalekeys={meta.error}
      valid={meta.error}
    />
  );
};

const AutoSubmit = () => {
  const [prevValues, setPrevValues] = useState({});
  const { values, submitForm } = useFormikContext();

  useEffect(() => {
    const newValues = { ...values };

    // ignore template properties not used as api params
    delete newValues.name;
    if (JSON.stringify(newValues) !== JSON.stringify(prevValues)) {
      setPrevValues(newValues);
      submitForm();
    }
  }, [values, submitForm, prevValues]);

  return null;
};

const initialPagination = {
  page: 0,
  next: null,
  prev: null,
  total: 0,
  change: null,
};

let timeout = null;

const TechnicalArchive = () => {
  const [entries, setEntries] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const [orderBy, setOrderBy] = useState('-');
  const [pagination, setPagination] = useState(initialPagination);

  const resetPagination = () => {
    setPagination(initialPagination);
  };

  const [template, setTemplate] = useState({
    device: null,
    site: null,
    search: '',
    from: getFromDate(),
    to: new Date().toISOString(),
    name: '',
    messageType: messageTypes.SHOW_TRANSLATED_MESSAGE,
    direction: 0,
    commandStatus: null,
  });

  const directionTypes = [
    { name: T.translate(LabelsLocaleKeys.showAllMessages), id: 0 },
    { name: T.translate(LabelsLocaleKeys.showIncomingMessages), id: 1 },
    { name: T.translate(LabelsLocaleKeys.showOutgoingMessages), id: 2 },
  ];

  const fetchArchives = () => {
    getArchive({
      tenantId: template.site,
      assetId: template.device.assetId,
      startDate: template.from,
      endDate: template.to,
      pageSize: rowsPerPage,
      pageIndex: pagination.page,
      orderBy,
      type: template.direction === 0 ? null : template.direction,
      commandStatus: template.commandStatus,
      search: template.search,
    }).then((response) => {
      if (response?.data) {
        setEntries(response.data.entries || []);
        setPagination({
          ...pagination,
          total: response.data.total,
          next: response.data.next,
          prev: response.data.previous,
        });
        setLoading(false);
      }
    });
  };

  const getArchives = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    if (template.to && template.from && template.device && template.site) {
      setLoading(true);
      timeout = setTimeout(fetchArchives, 500);
    } else {
      setEntries([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getArchives();
    // eslint-disable-next-line
  }, [rowsPerPage, pagination.change, pagination.page, orderBy]);

  useEffect(() => {
    if (template.site) {
      setDevices(null);
      getDevicesBasicInformationBySite(template.site).then((response) => {
        response?.data && setDevices(response.data);
      });
    }
  }, [template.site]);

  const changeSortOrder = () => {
    setOrderBy(orderBy === '-' ? '+' : '-');
  };

  const selectedOrderBy = orderBy === '-' ? 'desc' : 'asc';

  return (
    <Box display="flex" flexDirection="column" maxHeight="316px">
      <PaperStyled>
        <Box display="flex" justifyContent="space-around" style={{ zIndex: 100 }}>
          <Formik
            initialValues={template}
            enableReinitialize
            validationSchema={TechnicalArchiveValidation}
            validateOnMount
            onSubmit={() => {
              getArchives();
            }}
          >
            {({ errors, handleChange }) => (
              <>
                <AutoSubmit />
                <DropdownWithApiCallStyled
                  margin={'normal'}
                  label={T.translate(LocaleKeys.labels.sites)}
                  apiCall={getSites}
                  value={template.site}
                  onChange={(e) => {
                    setTemplate((prev) => ({
                      ...prev,
                      site: e.target.value,
                      device: null,
                    }));
                    resetPagination();
                    handleChange(e);
                  }}
                  displayField="name"
                  valueField="siteId"
                  name="sites"
                  withoutEmpty
                />
                <DropdownWithFilterStyled
                  margin={'normal'}
                  label={T.translate(LocaleKeys.labels.devices)}
                  value={
                    template.device
                      ? {
                          label: template.device.name,
                          value: template.device.assetId,
                        }
                      : null
                  }
                  onChange={(e) => {
                    const selectedDevice = e.target.value;

                    setTemplate((prev) => ({
                      ...prev,
                      device: selectedDevice
                        ? {
                            name: selectedDevice.label,
                            assetId: selectedDevice.value,
                          }
                        : selectedDevice,
                    }));
                    resetPagination();
                    handleChange(e);
                  }}
                  name="devices"
                  data={devices?.map((d) => ({ value: d.externalId, label: d.name })) || []}
                  withoutEmpty
                  disabled={!template.site}
                  fullWidth
                />
                <DatePickerStyledWithField
                  name="from"
                  format="HH:mm dd.MM.yyyy"
                  label={T.translate(LabelsLocaleKeys.from).toUpperCase()}
                  type="datetime"
                  value={template.from}
                  errors={errors?.from}
                  onChange={(e) => {
                    if (String(e.target.value) !== 'Invalid Date') {
                      setTemplate((prev) => ({
                        ...prev,
                        from: e.target.value,
                      }));
                      resetPagination();
                    }
                    handleChange(e);
                  }}
                />
                <DatePickerStyledWithField
                  name="to"
                  format="HH:mm dd.MM.yyyy"
                  label={T.translate(LabelsLocaleKeys.to).toUpperCase()}
                  type="datetime"
                  value={template.to}
                  errors={errors?.to}
                  onChange={(e) => {
                    if (String(e.target.value) !== 'Invalid Date') {
                      setTemplate((prev) => ({
                        ...prev,
                        to: e.target.value,
                      }));
                      resetPagination();
                    }
                    handleChange(e);
                  }}
                />
                <DropdownStyled
                  margin={'normal'}
                  label={T.translate(LabelsLocaleKeys.messageType).toUpperCase()}
                  data={directionTypes}
                  onChange={(e) => {
                    setTemplate((prev) => ({
                      ...prev,
                      direction: e.target.value,
                      messageType: [0, 2].includes(e.target.value)
                        ? messageTypes.SHOW_TRANSLATED_MESSAGE
                        : prev.messageType,
                      commandStatus: null,
                    }));
                    resetPagination();
                    handleChange(e);
                  }}
                  name="directionTypes"
                  withoutEmpty
                  displayField="name"
                  valueField="id"
                  value={template.direction}
                />
                {[0, 2].includes(template.direction) ? (
                  <DropdownStyled
                    margin={'normal'}
                    label={T.translate(LabelsLocaleKeys.deliveryStatus)}
                    data={Object.entries(COMMAND_STATUSES).map(([value, name]) => ({ value, name }))}
                    onChange={(e) => {
                      setTemplate((prev) => ({
                        ...prev,
                        commandStatus: e.target.value,
                      }));
                      handleChange(e);
                    }}
                    name="commandStatus"
                    value={template.commandStatus}
                    displayField="name"
                    valueField="value"
                    translateLabel
                  />
                ) : (
                  <DropdownStyled
                    margin={'normal'}
                    label={T.translate(LocaleKeys.labels.viewType)}
                    data={messages}
                    onChange={(e) => {
                      setTemplate((prev) => ({
                        ...prev,
                        messageType: e.target.value,
                      }));
                      handleChange(e);
                    }}
                    name="messageType"
                    withoutEmpty
                    value={template.messageType || messageTypes.SHOW_TRANSLATED_MESSAGE}
                    translateLabel
                  />
                )}
                <TextField
                  name="search"
                  label={T.translate(LabelsLocaleKeys.search)}
                  variant="outlined"
                  placeholder="Search"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    const text = e.target;

                    if (!text) {
                      return;
                    }
                    setTemplate((prev) => ({
                      ...prev,
                      search: text.value,
                    }));
                    handleChange(e);
                  }}
                  value={template.search}
                />
                <Filters
                  template={template}
                  onChange={(e) => {
                    const text = e.target;

                    if (!text) {
                      return;
                    }
                    setTemplate((prev) => ({
                      ...prev,
                      name: text.value,
                    }));
                  }}
                  setTemplate={(t) => {
                    resetPagination();
                    setTemplate(t);
                  }}
                  type="TechnicalArchive"
                />
              </>
            )}
          </Formik>
        </Box>
        <div style={messageDividerStyles}>
          {template.direction === 0 && [
            <p key="a" style={incomingMessageStyles}>
              {T.translate(LabelsLocaleKeys.incomingMessages)}
            </p>,
            <p key="b" style={outgoingMessageStyles}>
              {T.translate(LabelsLocaleKeys.outgoingMessages)}
            </p>,
          ]}
        </div>
        {loading ? (
          <Loading />
        ) : (
          entries &&
          template.device && (
            <TableStyled>
              <TableHead>
                <TableRow>
                  <TableHeaderCell align="center">{T.translate(LabelsLocaleKeys.device).toUpperCase()}</TableHeaderCell>
                  <TableHeaderCell align="center">
                    {T.translate(LabelsLocaleKeys.deviceId).toUpperCase()}
                  </TableHeaderCell>
                  <TableHeaderCell align="center">
                    <TableSortLabel
                      active
                      direction={selectedOrderBy}
                      onClick={(e) => {
                        changeSortOrder();
                      }}
                    >
                      {T.translate(LabelsLocaleKeys.date).toUpperCase()}
                    </TableSortLabel>
                  </TableHeaderCell>
                  <TableHeaderCell align="center" style={template.direction === 0 ? borderRight : null}>
                    <TableSortLabel
                      active
                      direction={selectedOrderBy}
                      onClick={(e) => {
                        changeSortOrder();
                      }}
                    >
                      {T.translate(LabelsLocaleKeys.time).toUpperCase()}
                    </TableSortLabel>
                  </TableHeaderCell>
                  {template.direction !== 2 && (
                    <>
                      <TableHeaderCell align="center">
                        {T.translate(LabelsLocaleKeys.latitude).toUpperCase()}
                      </TableHeaderCell>
                      <TableHeaderCell align="center">
                        {T.translate(LabelsLocaleKeys.longitude).toUpperCase()}
                      </TableHeaderCell>
                      <TableHeaderCell align="center">
                        {T.translate(LabelsLocaleKeys.eventKeyword).toUpperCase()}
                      </TableHeaderCell>
                      <TableHeaderCell align="center" style={template.direction === 0 ? borderRight : null}>
                        {`${T.translate(LabelsLocaleKeys.speed)} ${
                          template.messageType === messageTypes.SHOW_ALL
                            ? ''
                            : template.messageType === messageTypes.SHOW_TRANSLATED_MESSAGE
                            ? '[KM/H]'
                            : '[KNOTS]'
                        }`.toUpperCase()}
                      </TableHeaderCell>
                    </>
                  )}
                  {template.direction !== 1 && (
                    <>
                      <TableHeaderCell align="center">
                        {T.translate(LabelsLocaleKeys.name).toUpperCase()}
                      </TableHeaderCell>
                      <TableHeaderCell align="center">
                        {T.translate(LabelsLocaleKeys.command).toUpperCase()}
                      </TableHeaderCell>
                      <TableHeaderCell align="center">
                        {T.translate(LabelsLocaleKeys.login).toUpperCase()}
                      </TableHeaderCell>
                      <TableHeaderCell align="center">
                        {T.translate(LabelsLocaleKeys.reason).toUpperCase()}
                      </TableHeaderCell>
                      <TableHeaderCell align="center">
                        {T.translate(LabelsLocaleKeys.deliveryStatus).toUpperCase()}
                      </TableHeaderCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {entries.map((entry) => {
                  if (skipRow(entry, template.search)) {
                    return null;
                  }
                  const isOutgoingMessage = entry.type === 2;

                  return (
                    <Fragment key={entry.timestamp}>
                      {(template.messageType === messageTypes.SHOW_ALL ||
                        template.messageType === messageTypes.SHOW_TRANSLATED_MESSAGE) && (
                        <Row
                          name={template.device?.name}
                          imei={entry.imei}
                          date={getDate(entry.timestamp)}
                          time={getTime(entry.timestamp)}
                          commandName={entry.name}
                          latitude={roundCoordinate(entry.fullLatitude) || '-'}
                          longitude={roundCoordinate(entry.fullLongitude) || '-'}
                          eventKeyword={entry.keyword || '-'}
                          speed={entry.speed !== null ? entry.speed : '-'}
                          command={isOutgoingMessage ? entry.command : '-'}
                          login={isOutgoingMessage ? entry.login : '-'}
                          reason={entry.reason || '-'}
                          isDelivered={isOutgoingMessage ? T.translate(COMMAND_STATUSES[entry.commandStatus]) : '-'}
                          template={template}
                        />
                      )}
                      {(template.messageType === messageTypes.SHOW_ALL ||
                        template.messageType === messageTypes.SHOW_RAW_MESSAGE) && (
                        <Row
                          style={{
                            color: '#aaa',
                          }}
                          name={template.messageType === messageTypes.SHOW_RAW_MESSAGE ? template.device.name : ''}
                          imei={template.messageType === messageTypes.SHOW_RAW_MESSAGE ? entry.imei : ''}
                          date={entry.srcDate}
                          time={entry.srcTime}
                          latitude={`${entry.srcLatitude || ''} ${entry.srcLatitudeDirection || ''}`}
                          longitude={`${entry.srcLongitude || ''} ${entry.srcLongitudeDirection || ''}`}
                          eventKeyword={entry.srcKeyword}
                          speed={entry.srcSpeed !== null ? entry.srcSpeed : '-'}
                          template={template}
                        />
                      )}
                    </Fragment>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[50, 100, 250]}
                    count={pagination.total}
                    rowsPerPage={rowsPerPage}
                    page={pagination.page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                    }}
                    onChangePage={(e, newPage) => {
                      setPagination({
                        ...pagination,
                        change: newPage > pagination.page ? pagination.next : pagination.prev,
                        page: newPage,
                      });
                    }}
                    onChangeRowsPerPage={(e) => {
                      setRowsPerPage(e.target.value);
                      setPagination({ ...pagination, page: 0 });
                    }}
                  />
                </TableRow>
              </TableFooter>
            </TableStyled>
          )
        )}
      </PaperStyled>
    </Box>
  );
};

export default withPermissions([
  Permissions.CanAccessTechnicalArchivesPage,
  Permissions.CanReadTechnicalArchives,
  Permissions.CanReadSites,
  Permissions.CanReadDevices,
])(TechnicalArchive);
