import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, List, ListItem, ListItemAvatar, ListItemText, styled } from '@material-ui/core';
import { Warning as WarningIcon, Clear, Block, Delete } from '@material-ui/icons';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';

const Warning = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: 10,
  position: 'sticky',
  top: '-10px',
  backgroundColor: 'white',
  zIndex: 10,
});

const AlarmsList = ({ alarms }) => (
  <List>
    {alarms.map((alarm) => (
      <AlarmListItem {...alarm} key={alarm.id} />
    ))}
  </List>
);

const AlarmListItem = ({ name, onlyAssetToRemove, alarmToRemove }) => (
  <ListItem alignItems="center">
    <ListItemAvatar>
      {alarmToRemove ? (
        <Delete color="error" style={{ fontSize: 30 }} />
      ) : onlyAssetToRemove ? (
        <Clear color="error" style={{ fontSize: 30 }} />
      ) : (
        <Block color="error" style={{ fontSize: 30 }} />
      )}
    </ListItemAvatar>
    <ListItemText
      primary={name}
      secondary={T.translate(
        alarmToRemove
          ? LocaleKeys.labels.deleteAlarm
          : onlyAssetToRemove
          ? LocaleKeys.labels.unassignAssetFromAlarm
          : LocaleKeys.labels.deactivateAlarm
      )}
    />
  </ListItem>
);

const BrokenAlarmsWarning = ({ message, alarms }) => (
  <>
    <Warning>
      <WarningIcon color="error" style={{ fontSize: 30, marginRight: 10 }} />
      <Typography>{message}</Typography>
    </Warning>
    <AlarmsList alarms={alarms} />
  </>
);

BrokenAlarmsWarning.propTypes = {
  message: PropTypes.string.isRequired,
  alarms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      alarmToRemove: PropTypes.bool,
      onlyAssetToRemove: PropTypes.bool,
    })
  ).isRequired,
};

export default BrokenAlarmsWarning;
