import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip, CircularProgress } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { postCommand } from 'Api/devicesControl';
import { postCommandUrl } from 'Api/notifications';
import ReasonsDialog from './ReasonsDialog';
import { setAssetCommandButton } from 'redux/assets/actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import CountdownTooltip, { countTimeUntilEnabled } from 'Components/CountdownTooltip';
import { withRenderInterval } from 'hocs/withRenderInterval';

const colors = {
  1: '#3f51b5',
  2: '#883fb5',
  3: '#b53f3f',
};
const sizes = { 1: '100px', 2: '150px', 3: '250px' };
const CONST = {
  STATE_ON: 1,
  STATE_OFF: 2,
  TYPE_MULTI: 2,
  COMMAND_TYPE_TCP: 1,
  COMMAND_TYPE_URL: 2,
};
const RESPONSE_TIMEOUT = 30; // seconds

const Toggler = styled('div')(({ state, theme }) => ({
  height: 6,
  width: '100%',
  background: '#ccc',
  position: 'absolute',
  bottom: 0,
  borderRadius: '0 0 7px 7px',
  overflow: 'hidden',
  '& div': {
    height: '100%',
    width: '50%',
    background: theme.palette.secondary.main,
    float: state === CONST.STATE_ON ? 'right' : 'left',
  },
}));

const CustomizedButton = styled(Button)(({ style, theme }) => ({
  backgroundColor: colors[style.color],
  color: theme.palette.getContrastText(colors[style.color]),
  maxWidth: style.size === 3 ? '100%' : sizes[style.size],
  minWidth: sizes[style.size],
  margin: style.margin || theme.spacing(1),
  borderRadius: 7,
  '&:hover': {
    background: colors[style.color],
    opacity: style.disableClick ? 1 : 0.7,
  },
  height: style.height,
  cursor: style.disableClick ? 'default' : 'pointer',
  whiteSpace: 'nowrap',
}));

const formatButtonLabel = (text) => {
  const lowerSharpS = 'ß';
  const upperSharpS = 'ẞ';

  return text?.replace(lowerSharpS, upperSharpS);
};

const ButtonLabelStyled = styled('div')(({ style }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: sizes[style.size] - 10,
  minWidth: sizes[style.size] - 10,
}));

export const ActionButtonPreview = ({ button, onClick }) => {
  const buttonLabel = button.state === CONST.STATE_ON ? button.onCommandText : button.offCommandText;
  const formattedLabel = formatButtonLabel(buttonLabel);
  const [showTooltip, setShowTooltip] = useState(false);
  const divRef = useRef(null);
  const arrow = button.buttonCommandType === CONST.COMMAND_TYPE_URL && <span>&rarr;</span>;

  const toggle = button.type === CONST.TYPE_MULTI && (
    <Toggler state={button.state}>
      <div />
    </Toggler>
  );

  useEffect(() => {
    if (divRef.current.scrollWidth > divRef.current.clientWidth) {
      setShowTooltip(true);
    }
  }, []);

  return (
    <CustomizedButton style={button} onClick={onClick} disableRipple={button.disableClick}>
      {button.ellipsis ? (
        <Tooltip title={formattedLabel} placement="right" disableHoverListener={showTooltip === false}>
          <ButtonLabelStyled ref={divRef} style={button}>
            {formattedLabel}
          </ButtonLabelStyled>
        </Tooltip>
      ) : (
        <div ref={divRef}>{formattedLabel}</div>
      )}
      {arrow}
      {toggle}
    </CustomizedButton>
  );
};

ActionButtonPreview.propTypes = {
  button: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

ActionButtonPreview.defaultProps = {
  onClick: () => {},
};

const isDisabled = (lastSentAt) => {
  return countTimeUntilEnabled(lastSentAt, RESPONSE_TIMEOUT) > 0;
};

const getButtonValues = (button, state) => {
  return {
    command: state === CONST.STATE_ON ? button.onCommand : button.offCommand,
    commandName: state === CONST.STATE_ON ? button.onCommandText : button.offCommandText,
    label: state === CONST.STATE_ON ? button.onCommandText : button.offCommandText,
  };
};

const getNextButtonState = (button, state) => {
  if (button.type === CONST.TYPE_MULTI) {
    return state === CONST.STATE_ON ? CONST.STATE_OFF : CONST.STATE_ON;
  }

  return state;
};

const ActionButton = ({ alarmId, assetId, currentState, lastSentAt, setAssetCommandButton, ...props }) => {
  const [button, setButton] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isSubmittingCommand, setIsSubmittingCommand] = useState(false);
  const { label, command, commandName } = getButtonValues(button || {}, currentState);

  useEffect(() => {
    setButton(props.button);
  }, [props.button]);

  const submitCommand = async (reasonTexts) => {
    setIsSubmittingCommand(true);
    if (button.buttonCommandType === CONST.COMMAND_TYPE_URL) {
      const url = button.onCommand.startsWith('http') ? button.onCommand : `https://${button.onCommand}`;

      return postCommandUrl({
        url,
        commandName,
        alarmId,
        assetId,
        color: button.color,
        reasonTexts,
      }).then(() => {
        window.open(url, '_blank');
      });
    } else {
      return postCommand({
        command,
        commandName,
        alarmId,
        assetId,
        buttonId: button.externalId,
        buttonState: getNextButtonState(button, currentState),
        color: button.color,
        reasonTexts,
      });
    }
  };

  const handleSubmit = async (reasonTexts = []) => {
    if (button.buttonCommandType === CONST.COMMAND_TYPE_TCP) {
      setAssetCommandButton({
        assetId,
        buttonId: button.externalId,
        currentState,
        lastSentAt: new Date().toISOString(),
      });
    }

    return submitCommand(reasonTexts).finally(() => {
      setIsSubmittingCommand(false);
    });
  };

  const handleDialogSubmit = (values, { setSubmitting }) => {
    const { checkedReasons, otherComment } = values;
    const reasonTexts = [...checkedReasons];

    if (otherComment?.length) {
      reasonTexts.push(otherComment);
    }
    handleSubmit(reasonTexts).then(() => {
      setSubmitting(false);
      setDialogOpen(false);
    });
  };

  const handleCancel = () => {
    setDialogOpen(false);
  };

  if (!button) {
    return <CircularProgress />;
  }

  return (
    <>
      <CountdownTooltip seconds={countTimeUntilEnabled(lastSentAt, RESPONSE_TIMEOUT)}>
        <CustomizedButton
          style={button}
          disabled={isDisabled(lastSentAt) || isSubmittingCommand}
          onClick={() => {
            if (button.isReasonRequired) {
              setDialogOpen(true);
            } else {
              handleSubmit();
            }
          }}
        >
          {formatButtonLabel(label)}
          {button.buttonCommandType === CONST.COMMAND_TYPE_URL && <span>&rarr;</span>}
          {button.type === CONST.TYPE_MULTI && (
            <Toggler state={currentState}>
              <div />
            </Toggler>
          )}
        </CustomizedButton>
      </CountdownTooltip>
      {button.isReasonRequired && (
        <ReasonsDialog
          open={dialogOpen}
          clickReasons={button.clickReasons}
          onSubmit={handleDialogSubmit}
          onCancel={handleCancel}
        />
      )}
    </>
  );
};

const mapDispatch = {
  setAssetCommandButton,
};

ActionButton.propTypes = {
  button: PropTypes.object.isRequired,
  alarmId: PropTypes.string,
  assetId: PropTypes.string,
  backgroundColor: PropTypes.number,
  currentState: PropTypes.number,
  lastSentAt: PropTypes.string,
};

ActionButton.defaultProps = {
  alarmId: null,
  assetId: null,
  backgroundColor: 1,
};

export default compose(withRenderInterval(1000), connect(null, mapDispatch))(ActionButton);
