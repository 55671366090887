import React, { useState, useReducer, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import { styled } from '@material-ui/core/styles';
import { ButtonsLibrary as Library, ButtonsLibraryDetails, ButtonsLibraryNew } from 'routes/Routes';
import { Paper, Box, CircularProgress } from '@material-ui/core';
import ButtonLibraries from 'app/pages/sites/administration/buttonsLibrary/ButtonLibriaries';
import ButtonLibraryPreview from 'Components/buttonLibrary/ButtonLibraryPreview';
import ButtonDetails from 'Components/buttonLibrary/ButtonDetails';
import { buttonLibraryReducer, types } from 'Components/buttonLibrary/reducer/buttonLibraryReducer';
import Button from 'Components/Button';
import {
  getButtonLibraries,
  postButtonLibrary,
  getButtonLibraryByName,
  putButtonLibrary,
  getButtonLibrary,
} from 'Api/devices';
import { Permissions } from 'Constants/permissions';
import withPermissions from 'hocs/withPermissions';
import { removeProperty, compareObjects } from 'Helpers/ObjectHelper';
import { Form } from 'formik';
import { validationSchema } from 'Components/buttonLibrary/validationSchema';
import SaveOnLeave from 'Components/dialogs/SaveOnLeave';
import { FormikEnhanced } from 'Components/formik/formikWrappers';

const Container = styled(Paper)({
  height: 'calc(100vh - 110px)',
  display: 'flex',
  overflow: 'hidden',
});

const AssideLeft = styled(Box)({
  width: '55%',
  height: '100%',
  marginRight: '5px',
  borderRight: '2px solid #D3D3D3',
});

const FormStyled = styled(Form)({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: '1',
  minHeight: '0',
  width: '45%',
  padding: '5px',
});

const isDirty = (obj1, obj2) => {
  return !compareObjects(obj1, obj2);
};

const ButtonsLibrary = ({ permissions }) => {
  const history = useHistory();
  const params = useParams();
  const [buttonLibraries, setButtonLibraries] = useState(null);
  const [selectedButton, setSelectedButton] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // initial values only to compare changes
  const [initialButtonLibrary, initialButtonLibraryDispatch] = useReducer(buttonLibraryReducer);
  const [buttonLibrary, dispatch] = useReducer(buttonLibraryReducer);
  const isEditMode = buttonLibrary?.id;

  useEffect(() => {
    getButtonLibraries().then((response) => {
      const templateLibraries = response.data.filter((library) => library.isTemplate);

      setButtonLibraries(templateLibraries);
    });
  }, []);

  useEffect(() => {
    initialButtonLibraryDispatch({ type: types.INITIALIZE_STATE, library: null });
    dispatch({ type: types.INITIALIZE_STATE, library: null });
    setSelectedButton(null);

    if (history.location.state?.id) {
      setIsLoading(true);
      getButtonLibrary(history.location.state?.id).then(({ data }) => {
        initialButtonLibraryDispatch({ type: types.INITIALIZE_STATE, library: data });
        dispatch({ type: types.INITIALIZE_STATE, library: data });
        setIsLoading(false);
      });
    } else if (params.name) {
      setIsLoading(true);
      getButtonLibraryByName(params.name.replace(/-/g, ' ')).then(({ data }) => {
        initialButtonLibraryDispatch({ type: types.INITIALIZE_STATE, library: data });
        dispatch({ type: types.INITIALIZE_STATE, library: data });
        setIsLoading(false);
      });
    } else if (history.location.pathname === ButtonsLibraryNew.getUrl()) {
      const baseOnButtonLibraryId = history.location.state?.baseOnButtonLibraryId;

      if (baseOnButtonLibraryId) {
        loadLibraryCopy(baseOnButtonLibraryId);
      } else {
        initialButtonLibraryDispatch({ type: types.CREATE_LIBRARY });
        dispatch({ type: types.CREATE_LIBRARY });
      }
    }
  }, [params, history]);

  const redirect = (selectedButtonLibraryName, selectedButtonLibraryId) => {
    const url = selectedButtonLibraryName
      ? ButtonsLibraryDetails.getUrl({
          name: selectedButtonLibraryName.replace(/\s/g, '-'),
        })
      : Library.getUrl();

    history.push(url, { id: selectedButtonLibraryId });
  };

  const onSelectLibrary = (selectedId) => {
    let selectedName;

    if (selectedId && selectedId === buttonLibrary?.id) {
      selectedName = buttonLibrary.name;
    } else if (selectedId) {
      selectedName = buttonLibraries.find((library) => library.id === selectedId).name;
    }
    redirect(selectedName, selectedId);
  };

  const loadLibraryCopy = (selectedButtonLibraryId) => {
    setSelectedButton(null);
    initialButtonLibraryDispatch({ type: types.INITIALIZE_STATE, library: null });
    dispatch({ type: types.INITIALIZE_STATE, library: null });
    setIsLoading(true);
    getButtonLibrary(selectedButtonLibraryId).then(({ data }) => {
      const library = data;

      removeProperty(library, 'id');
      removeProperty(library, 'externalId');
      removeProperty(library, 'buttonCategoryId');
      library.templateId = selectedButtonLibraryId;
      library.isTemplate = true;
      library.author = null;
      library.name = '';

      initialButtonLibraryDispatch({ type: types.INITIALIZE_STATE, library });
      dispatch({ type: types.INITIALIZE_STATE, library });
      setIsLoading(false);
    });
  };

  const onAddLibrary = () => {
    const url = ButtonsLibraryNew.getUrl();

    history.push(url);
  };

  const onCopyLibrary = (baseOnButtonLibraryId) => {
    const url = ButtonsLibraryNew.getUrl();

    history.push(url, { baseOnButtonLibraryId });
  };

  const onDeleteButton = (categoryTempId, buttonTempId) => {
    dispatch({ type: types.DELETE_BUTTON, categoryTempId, buttonTempId });
    setSelectedButton(null);
  };

  const onSaveButton = (categoryTempId, button) => {
    dispatch({ type: types.SAVE_BUTTON, categoryTempId, button });
    setSelectedButton(null);
  };

  const saveData = async (values, setSubmitting) => {
    let updatedLibraries;

    if (isEditMode) {
      await putButtonLibrary(values.id, values);
      updatedLibraries = buttonLibraries.map((library) => {
        return library.id === values.id ? values : library;
      });
    } else {
      const response = await postButtonLibrary(values);

      updatedLibraries = [...buttonLibraries, response.data];
    }
    setButtonLibraries(updatedLibraries);
    setSubmitting(false);
  };

  const onSubmit = async (values, { setSubmitting }) => {
    await saveData(values, setSubmitting);
    redirect();
  };

  const canEditButtonsLibrary =
    !!permissions[Permissions.CanEditButtonsLibrary] &&
    !!permissions[Permissions.CanReadSensitiveButtons] &&
    !!permissions[Permissions.CanReadNonSensitiveButtons];
  const canSaveChanges = isEditMode ? canEditButtonsLibrary : permissions[Permissions.CanAddButtonsLibrary];
  const isReadOnly = !canSaveChanges;

  return (
    <Container>
      <AssideLeft>
        <Box height="40%" overflow="auto">
          <ButtonLibraries
            buttonLibraries={buttonLibraries}
            setButtonLibraries={setButtonLibraries}
            onSelectLibrary={onSelectLibrary}
            onCopyLibrary={onCopyLibrary}
            onAddLibrary={onAddLibrary}
          />
        </Box>
        <Box marginTop="5px" height="60%" overflow="auto" borderTop="2px solid #D3D3D3" padding="2px">
          <ButtonDetails
            selectedButton={selectedButton}
            buttonLibrary={buttonLibrary}
            onCancel={() => setSelectedButton(null)}
            onSave={onSaveButton}
            onDelete={onDeleteButton}
            isReadOnly={isReadOnly}
          />
        </Box>
      </AssideLeft>
      <FormikEnhanced
        initialValues={buttonLibrary}
        enableReinitialize
        validationSchema={validationSchema(initialButtonLibrary)}
        validateOnMount
        onSubmit={onSubmit}
        canSaveChanges={canSaveChanges}
      >
        {({ values, isValid, isSubmitting, setSubmitting, validateForm }) => {
          const dirty = isDirty(initialButtonLibrary, values);

          return (
            <FormStyled>
              <Box display="flex" flexDirection="column" flexGrow="1" minHeight="0" padding="2px">
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <ButtonLibraryPreview
                    initialButtonLibrary={initialButtonLibrary}
                    buttonLibrary={values}
                    onSelectButton={(button) => setSelectedButton(button)}
                    dispatch={dispatch}
                    validateForm={validateForm}
                    isReadOnly={isReadOnly}
                  />
                )}
              </Box>
              {buttonLibrary && (
                <Box display="flex" justifyContent="flex-end" margin="5px">
                  <Button onClick={() => redirect(null)} id="buttonsLibrary-cancelButton">
                    {T.translate(LocaleKeys.labels.cancel)}
                  </Button>
                  {canSaveChanges && (
                    <>
                      <Button
                        disabled={!(dirty && isValid) || isSubmitting}
                        type="submit"
                        id="buttonsLibrary-submitButton"
                      >
                        {T.translate(LocaleKeys.labels.save)}
                      </Button>
                      <SaveOnLeave
                        saveData={() => saveData(values, setSubmitting)}
                        dataChanged={dirty}
                        validForm={isValid}
                        withoutSaving={() => redirect(null)}
                      />
                    </>
                  )}
                </Box>
              )}
            </FormStyled>
          );
        }}
      </FormikEnhanced>
    </Container>
  );
};

export default withPermissions([Permissions.CanReadNonSensitiveButtons])(ButtonsLibrary);
