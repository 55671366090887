import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import LocaleKeys from 'Localization/LocaleKeys';
import PropTypes from 'prop-types';
import React from 'react';
import T from 'i18n-react';
import { withFormik } from 'Components/formik/formikWrappers';
import { TextField } from 'Components/formik/fields';

const Logins = (props) => {
  return (
    <ColumnDisplayer>
      <TextField
        label={T.translate(LocaleKeys.labels.concurrentLoginLimit)}
        name={'securityRule.concurrentLoginLimit'}
        type={'number'}
      />
      <TextField
        label={T.translate(LocaleKeys.labels.sessionDuration)}
        name={'securityRule.sessionDuration'}
        type={'number'}
      />
    </ColumnDisplayer>
  );
};

Logins.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
};

export default withFormik(Logins);
