import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { Search } from '@material-ui/icons';
import { Box, TextField, InputAdornment, styled } from '@material-ui/core';
import DateTimeInput from 'Components/inputs/DateTime';
import { isDate } from 'Helpers/DateTimeHelpers';
import Checkbox from 'Components/inputs/Checkbox';

const TextFieldStyled = styled(TextField)({
  marginRight: 8,
});
const DateTimeInputStyled = styled(DateTimeInput)({
  marginRight: 8,
});
const CheckboxStyled = styled(Checkbox)({
  marginRight: 8,
});

const stringContain = (searchedIn, query) => searchedIn?.toLowerCase().includes(query.toLowerCase());

export const filterNotifications = (notifications, { queryTrimmed, from, to, onlyPopup }) => {
  if (!notifications) {
    return [];
  }

  return notifications
    .filter((notification) => !notification.isRead)
    .filter((notification) => (onlyPopup ? notification.showAsPopup : true))
    .filter(
      ({ assetName, alarmName }) => stringContain(assetName, queryTrimmed) || stringContain(alarmName, queryTrimmed)
    )
    .filter(({ createdOn }) => {
      let result = true;

      if (from && isDate(from)) {
        result = result && +new Date(createdOn) >= +new Date(from);
      }
      if (to && isDate(to)) {
        result = result && +new Date(createdOn) <= +new Date(to);
      }

      return result;
    });
};

const Filters = ({ query, setQuery, from, setFrom, to, setTo, onlyPopup, setOnlyPopup, setCheckAllAt }) => {
  return (
    <Box display="flex" alignItems="center">
      <TextFieldStyled
        variant="outlined"
        placeholder={T.translate('search')}
        name="notificationSearch"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          ),
        }}
        value={query}
        onChange={(e) => {
          setQuery(e.target.value);
          setCheckAllAt(null);
        }}
      />
      <DateTimeInputStyled
        label={T.translate('from').toUpperCase()}
        format="HH:mm dd.MM.yyyy"
        type="datetime"
        name="from"
        value={from}
        onChange={(e) => {
          setFrom(e.target.value);
          setCheckAllAt(null);
        }}
      />
      <DateTimeInputStyled
        label={T.translate('to').toUpperCase()}
        format="HH:mm dd.MM.yyyy"
        type="datetime"
        name="to"
        value={to}
        onChange={(e) => {
          setTo(e.target.value);
          setCheckAllAt(null);
        }}
      />
      <CheckboxStyled
        label={T.translate('onlyPopup')}
        name="onlyPopup"
        value={onlyPopup}
        onChange={(e) => {
          setOnlyPopup(e.target.value);
          setCheckAllAt(null);
        }}
      />
    </Box>
  );
};

Filters.propTypes = {
  query: PropTypes.string,
  setQuery: PropTypes.func,
  from: PropTypes.string,
  setFrom: PropTypes.func,
  to: PropTypes.string,
  setTo: PropTypes.func,
  onlyPopup: PropTypes.bool,
  setOnlyPopup: PropTypes.func,
  setCheckAllAt: PropTypes.func,
};

export default Filters;
