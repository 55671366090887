import L from 'leaflet';
import './corridor';

function iconCreateFunctionDefault(cluster) {
  const clusteredAreasCount = cluster.getAllChildMarkers().filter((m) => m._countsWhenClustered).length;

  if (!clusteredAreasCount) {
    return L.divIcon({ opacity: 0, iconSize: L.point(0, 0, true) });
  }

  return L.divIcon({
    html: `<div><span>${clusteredAreasCount}</span></div>`,
    className: 'area-cluster',
    iconSize: L.point(30, 30, true),
  });
}

L.areaClusterGroup = function (options) {
  return L.markerClusterGroup({
    iconCreateFunction: iconCreateFunctionDefault,
    spiderfyOnMaxZoom: false,
    removeOutsideVisibleBounds: false,
    ...options,
  });
};

// Clusterable vector layers extensions
L.Clusterable = {};

L.Clusterable.Polygon = L.Polygon.extend({
  initialize: function (bounds, options) {
    L.Polygon.prototype.initialize.call(this, bounds, options);
    this._latlng = this.getBounds().getCenter();
    this._countsWhenClustered = true;
  },

  getLatLng: function () {
    return this._latlng;
  },

  // dummy method.
  setLatLng: function () {},
});

L.Clusterable.polygon = function (points, options) {
  return new L.Clusterable.Polygon(points, options);
};

// Used for 'Corridor' route markers to count corridor and route markers as single area after cluster
L.UnclusterableMarker = L.Marker.extend({
  getLatLng: null,
  setLatLng: null,
});

L.unclusterableMarker = function (latLng, options) {
  return new L.UnclusterableMarker(latLng, options);
};

L.Clusterable.Corridor = L.Corridor.extend({
  _latlng: null,
  _routeMarkers: null,
  _countsWhenClustered: true,

  initialize: function (bounds, options) {
    L.Corridor.prototype.initialize.call(this, bounds, options);
    const latLngs = this.getLatLngs();

    this._routeMarkers = [L.unclusterableMarker(latLngs[0]), L.unclusterableMarker(latLngs[latLngs.length - 1])];
    this._latlng = this.getBounds().getCenter();
  },

  getLatLng: function () {
    return this._latlng;
  },

  // dummy method.
  setLatLng: function () {},

  onAdd: function (map) {
    L.Corridor.prototype.onAdd.call(this, map);
    // add route markers
    this._routeMarkers.forEach((marker) => marker.addTo(map));
  },

  onRemove: function (map) {
    L.Corridor.prototype.onRemove.call(this, map);
    // remove route markers
    this._routeMarkers.forEach((marker) => marker.remove(map));
  },
});

L.Clusterable.corridor = function (latlngs, options) {
  return new L.Clusterable.Corridor(latlngs, options);
};

L.Clusterable.Circle = L.Circle.extend({
  _countsWhenClustered: true,
});

L.Clusterable.circle = function (points, options) {
  return new L.Clusterable.Circle(points, options);
};
