import React from 'react';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import { getServiceStatuses } from 'Api/devices';
import { DropdownWithApiCall } from 'Components/formik/fields';

const ServiceStatus = (props) => (
  <ColumnDisplayer>
    <DropdownWithApiCall
      label={T.translate(LocaleKeys.labels.status)}
      apiCall={getServiceStatuses}
      name="serviceStatus"
      withoutEmpty
      valueField="value"
      displayField="name"
      translateLabel
      fullWidth
    />
  </ColumnDisplayer>
);

export default ServiceStatus;
