import LocaleKeys from 'Localization/LocaleKeys';
import PropTypes from 'prop-types';
import React from 'react';
import T from 'i18n-react';
import TextInput from 'Components/inputs/TextInput';
import DateTimeInput from 'Components/inputs/DateTime';
import { getHolidays } from 'Api/sites';
import { styled } from '@material-ui/core/styles';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import { withFormik } from 'Components/formik/formikWrappers';
import { DropdownWithApiCall } from 'Components/formik/fields';

const DropdownWithApiCallStyled = styled(DropdownWithApiCall)({
  width: '400px',
});

const Holiday = ({ onChange, holidayTemplateData, getHolidayTemplate }) => {
  return (
    <>
      <ColumnDisplayer>
        <DropdownWithApiCallStyled
          name="holidayTemplateId"
          apiCall={getHolidays}
          onChange={(e) => {
            onChange(e);
            getHolidayTemplate(e.target.value);
          }}
          displayField="name"
          label={T.translate(LocaleKeys.labels.holidayTemplate)}
          valueField="holidayId"
          withoutEmpty
        />
      </ColumnDisplayer>
      {holidayTemplateData && (
        <>
          <ColumnDisplayer>
            <TextInput
              label={T.translate(LocaleKeys.labels.name)}
              name="name"
              value={holidayTemplateData.name}
              InputProps={{ readOnly: true }}
            />
            <TextInput
              label={T.translate(LocaleKeys.labels.description)}
              name="description"
              value={holidayTemplateData.description}
              InputProps={{ readOnly: true }}
            />
            <TextInput
              label={T.translate(LocaleKeys.labels.year)}
              name="year"
              type="number"
              value={holidayTemplateData.year}
              InputProps={{ readOnly: true }}
            />
          </ColumnDisplayer>

          {holidayTemplateData.entries.map((val, index) => {
            return (
              <ColumnDisplayer key={`holidayTemplateData.entries.${index}`}>
                <TextInput
                  key={`holidayTemplateData.entries.${index}.name`}
                  name={`holidayTemplateData.entries.${index}.name`}
                  label={T.translate(LocaleKeys.labels.name)}
                  value={val.name}
                  InputProps={{ readOnly: true }}
                />
                <DateTimeInput
                  key={`holidayTemplateData.entries.${index}.date`}
                  name={`holidayTemplateData.entries.${index}.date`}
                  label={T.translate(LocaleKeys.labels.date)}
                  type="date"
                  value={val.date}
                  format="dd.MM.yyyy"
                  disabled={true}
                  readOnly
                />
              </ColumnDisplayer>
            );
          })}
        </>
      )}
    </>
  );
};

Holiday.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.object,
  holidayTemplateData: PropTypes.object,
  getHolidayTemplate: PropTypes.func,
};

export default withFormik(Holiday);
