import ExpansionSection from 'Components/display/ExpansionPanel/ExpansionSection';
import LocaleKeys from 'Localization/LocaleKeys';
import Logins from './loginSettings/Logins';
import Passwords from './loginSettings/Passwords';
import PropTypes from 'prop-types';
import React from 'react';

export const LoginSettings = (props) => (
  <ExpansionSection data={props.data} onChange={props.onChange}>
    <Logins label={LocaleKeys.labels.logins} />
    <Passwords label={LocaleKeys.labels.passwords} />
  </ExpansionSection>
);

LoginSettings.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
};

export default LoginSettings;
