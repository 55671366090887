import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, Box, Button } from '@material-ui/core';
import { InfoOutlined, Lens, ExpandMore, ExpandLess } from '@material-ui/icons';
import { styled } from '@material-ui/core/styles';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import Content from './Content';

const Wrapper = styled('div')({
  maxHeight: 600,
  width: 560,
});

const IconStyled = styled(InfoOutlined)({
  fontSize: 30,
});

const ReadedIcon = styled(Lens)({
  fontSize: 10,
});

const ButtonRead = styled(Button)(({ disabled }) => ({
  height: 22,
  minWidth: 22,
  margin: 0,
  marginTop: 6,
  marginLeft: 16,
  opacity: disabled ? 0.4 : 1,
}));

const ExpandedContent = styled('div')({
  lineHeight: '1.5em',
  overflowWrap: 'break-word',
  width: 400,
});

const Notification = ({ content }) => {
  const [expanded, setExpanded] = useState(false);
  const divRef = useRef(null);
  const [showReadMore, setShowReadMore] = useState(false);

  useEffect(() => {
    if (
      !showReadMore &&
      divRef.current.scrollHeight > divRef.current.offsetHeight &&
      divRef.current.scrollHeight > 50
    ) {
      setShowReadMore(true);
    }
  }, [showReadMore]);

  return (
    <Box display="flex" padding="12px 24px" bgcolor={'transparent'}>
      <IconStyled />
      <Box paddingLeft="24px" textAlign="justify" flexGrow={1}>
        <Box display="flex" justifyContent="space-between" fontSize={12}>
          <strong>{T.translate(LocaleKeys.labels.siteNotification)}</strong>
          <span>{content.date.toLocaleString()}</span>
        </Box>
        <Box width={400} overflow="hidden">
          {expanded ? (
            <ExpandedContent ref={divRef}>{content.message}</ExpandedContent>
          ) : (
            <Content ref={divRef} bgcolor={'white'}>
              {content.message}
            </Content>
          )}
          <Box display="flex" flexDirection="row" paddingLeft="201px">
            {showReadMore &&
              (!expanded ? (
                <ExpandMore
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setExpanded(true);
                  }}
                />
              ) : (
                <ExpandLess
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setExpanded(false);
                  }}
                />
              ))}
            {content.link && (
              <Box paddingLeft="30px" marginRight="0">
                <strong>
                  <a
                    href={
                      content.link.startsWith('http://') || content.link.startsWith('https://')
                        ? content.link
                        : `//${content.link}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {T.translate(LocaleKeys.labels.linkToMoreContent)}
                  </a>
                </strong>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <ButtonRead size="small" disabled={false}>
        <ReadedIcon color="primary" />
      </ButtonRead>
    </Box>
  );
};

const NotificationPreview = (props) => {
  const date = new Date(props.startDate);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{T.translate(LocaleKeys.labels.notificationPreview)}</DialogTitle>
      <Wrapper>
        <Notification content={{ message: props.content, link: props.link, date }} />
      </Wrapper>
    </Dialog>
  );
};

NotificationPreview.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  link: PropTypes.string,
  content: PropTypes.string,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

export default NotificationPreview;
