import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { CircularProgress, Box } from '@material-ui/core';
import ExpansionSection from 'Components/display/ExpansionPanel/ExpansionSection';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import {
  getVariableTypes,
  getDaysOfWeek,
  getMapAreaVariables,
  getMetricVariables,
  getEventKeywordVariables,
  getInputMappingTypes,
  getSystemMappingTypes,
  getDayVariable,
  getTimePeriodVariables,
  getDigitalMappingStates,
} from 'Api/devices';
import FormulaArray from './FormulaArray';
import { withFormik } from 'Components/formik/formikWrappers';
import { Checkbox } from 'Components/formik/fields';

const START_FORMULA = 1;
const END_FORMULA = 2;

const digitalRelatedOperatorsData = [{ value: 7, name: 'EqualTo' }];

const Formulas = ({ inputMappings, isLoadingAlarmLibrary }) => {
  const [dictionaries, setDictionaries] = useState();

  useEffect(() => {
    Promise.all([
      getInputMappingTypes(),
      getSystemMappingTypes(),
      getVariableTypes(),
      getDaysOfWeek(),
      getMapAreaVariables(),
      getMetricVariables(),
      getEventKeywordVariables(),
      getDayVariable(),
      getTimePeriodVariables(),
      getDigitalMappingStates(),
    ]).then(
      ([
        inputMappingTypesResponse,
        systemMappingTypesResponse,
        variableTypesResponse,
        daysOfWeekResponse,
        mapAreaRelatedResponse,
        metricRelatedResponse,
        eventKeywordsRelatedResponse,
        daysRelatedResponse,
        timePeriodRelatedResponse,
        digitalMappingStatesResponse,
      ]) => {
        setDictionaries({
          inputMappingTypes: inputMappingTypesResponse.data,
          systemMappingTypes: systemMappingTypesResponse.data,
          variableTypes: variableTypesResponse.data,
          daysOfWeek: daysOfWeekResponse.data,
          DaysRelated: daysRelatedResponse.data,
          MapAreaRelated: mapAreaRelatedResponse.data,
          MetricRelated: metricRelatedResponse.data,
          TimeRelated: metricRelatedResponse.data,
          TimePeriodRelated: timePeriodRelatedResponse.data,
          EventKeywordRelated: eventKeywordsRelatedResponse.data,
          DigitalRelated: digitalRelatedOperatorsData,
          digitalMappingStates: digitalMappingStatesResponse.data,
        });
      }
    );
  }, []);

  if (!dictionaries || isLoadingAlarmLibrary) {
    return <CircularProgress />;
  }

  return (
    <ExpansionSection>
      <Box label={T.translate(LocaleKeys.labels.startFormula)} width="100%">
        <FormulaArray
          name={'startFormulas'}
          dictionaries={dictionaries}
          inputMappings={inputMappings}
          formulaType={START_FORMULA}
        />
      </Box>
      <Box label={T.translate(LocaleKeys.labels.endFormula)} width="100%">
        <div>
          <Checkbox label={T.translate(LocaleKeys.labels.isEndFormulaActive)} name="isEndFormulaActive" />
        </div>
        <FormulaArray
          name={'endFormulas'}
          dictionaries={dictionaries}
          inputMappings={inputMappings}
          formulaType={END_FORMULA}
        />
      </Box>
    </ExpansionSection>
  );
};

const withAlarmLibraryUpdate =
  (Component) =>
  ({ alarmLibrary, fetchLibraryForAlarm, ...props }) => {
    useEffect(() => {
      fetchLibraryForAlarm(props.data, alarmLibrary, props.setFieldValue);

      // eslint-disable-next-line
    }, []);

    return <Component {...props} />;
  };

export const FormulasNew = compose(withFormik, withAlarmLibraryUpdate)(Formulas);

Formulas.propTypes = {
  data: PropTypes.object,
  setFieldValue: PropTypes.func,
  isLoadingAlarmLibrary: PropTypes.bool,
};

Formulas.defaultProps = {
  isLoadingAlarmLibrary: false,
};

export default withFormik(Formulas);
