import React from 'react';
import CommonInfo from './CommonInfo';
import ExpansionSection from 'Components/display/ExpansionPanel/ExpansionSection';
import LocaleKeys from 'Localization/LocaleKeys';
import Webhooks from './Webhooks';

const Definitions = (props) => (
  <ExpansionSection>
    <CommonInfo label={LocaleKeys.labels.commonInfo} />
    <Webhooks label={LocaleKeys.labels.webhooks} />
  </ExpansionSection>
);

export default Definitions;
