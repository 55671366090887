import React from 'react';
import { SnackbarProvider } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Notificator from '../../redux/notifications/Notificator';

const SnackbarProviderWithCloseButton = ({ children }) => {
  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  const closeButton = (key) => (
    <IconButton size="small" color="inherit" onClick={onClickDismiss(key)}>
      <CloseIcon />
    </IconButton>
  );

  return (
    <SnackbarProvider ref={notistackRef} maxSnack={3} action={(key) => closeButton(key)}>
      <Notificator />
      {children}
    </SnackbarProvider>
  );
};

export default SnackbarProviderWithCloseButton;
