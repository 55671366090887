import React from 'react';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import FormikCollection from 'Components/inputs/CollectionManager/FormikCollection';
import { TextField } from 'Components/formik/fields';

const EmailNotifications = (props) => (
  <ColumnDisplayer>
    <FormikCollection name="emails" label={T.translate(LocaleKeys.labels.emails)}>
      <TextField name="email" label={T.translate(LocaleKeys.labels.email)} fullWidth />
    </FormikCollection>
  </ColumnDisplayer>
);

export default EmailNotifications;
