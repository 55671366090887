import React, { useState, useCallback } from 'react';
import { Box } from '@material-ui/core';
import DeviceSidebarView from './DeviceSidebarView';
import MapWithDragableMarkers from './MapWithDragableMarkers';
import MapSidebar from './MapSidebar';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DeviceContext from './DeviceContext';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import * as configurationStore from 'Api/configurationStore';
import { useConfigurationStore } from 'hooks/useConfigurationStore';

const OPTIONS = {
  multiple: true,
  sidebarStateName: 'Dashboard',
  trail: true,
};

const Dashboard = () => {
  const [selectedAssetIds, setSelectedAssetIds] = useState([]);
  const [draggedFromSidebar, setDraggedFromSidebar] = useState([]);
  const [draggedFromMap, setDraggedFromMap] = useState([]);
  const [draggedDevice, setDraggedDevice] = useState();
  const [drawerState, setDrawerState] = useState();
  const [trackingMode, setTrackingMode] = useConfigurationStore(`${OPTIONS.sidebarStateName}-trackingMode`, true);

  const getContent = useCallback(
    (devices, selectedAssetIds, deviceTrail, trackingMode) => (
      <Box display="flex" flexGrow={1} height="calc(100vh - 110px)">
        <MapWithDragableMarkers
          devices={devices}
          draggedFromSidebar={draggedFromSidebar}
          setDraggedFromMap={setDraggedFromMap}
          selectedAssetIds={selectedAssetIds}
          setDraggedDevice={setDraggedDevice}
          draggedDevice={draggedDevice}
          deviceTrail={deviceTrail}
          trackingMode={trackingMode}
          setTrackingMode={setTrackingMode}
        />
        <MapSidebar
          draggedFromMap={draggedFromMap}
          setDraggedFromSidebar={setDraggedFromSidebar}
          allDevices={devices}
          setDrawerState={setDrawerState}
          drawerState={drawerState}
        />
      </Box>
    ),
    [
      draggedFromSidebar,
      setDraggedFromMap,
      setDraggedDevice,
      draggedDevice,
      drawerState,
      draggedFromMap,
      setTrackingMode,
    ]
  );

  const clearSelectedAssetsIds = () => {
    const selectedAssetsIds = [];

    configurationStore.putValueCreateOrUpdate(`${OPTIONS.sidebarStateName}-SelectedDevices`, {
      value: JSON.stringify(selectedAssetsIds),
    });
    setSelectedAssetIds(selectedAssetsIds);
  };

  const deviceContextValue = {
    draggedDevice,
    selectedAssetIds,
    clearSelectedAssetsIds,
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <DeviceContext.Provider value={deviceContextValue}>
        <Box display="flex">
          <DeviceSidebarView
            selectedAssetIds={selectedAssetIds}
            setSelectedAssetIds={setSelectedAssetIds}
            trackingMode={trackingMode}
            options={OPTIONS}
          >
            {getContent}
          </DeviceSidebarView>
        </Box>
      </DeviceContext.Provider>
    </DndProvider>
  );
};

export default withPermissions([Permissions.CanReadDevices])(Dashboard);
