import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { Box, InputAdornment } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import LabelsLocaleKeys from 'Localization/LabelsLocaleKeys';
import T from 'i18n-react';
import Checkbox from 'Components/inputs/Checkbox';
import DateTimeInput from 'Components/inputs/DateTime';
import TextInput from 'Components/inputs/TextInput';

const StyledDatePicker = styled(DateTimeInput)({
  width: '200px',
});

const Filter = ({ setHistoryEntries, disabled, setPage, settings, onSettingsChange }) => {
  return (
    <Box display="flex" justifyContent="space-around">
      <TextInput
        label={T.translate('search')}
        margin={'none'}
        disabled={disabled}
        value={settings.search}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={(e) => {
          const updatedSettings = { ...settings };

          updatedSettings.search = e.target.value;
          onSettingsChange(updatedSettings);
          setPage(0);
        }}
      />
      <StyledDatePicker
        label={T.translate(LabelsLocaleKeys.from).toUpperCase()}
        type="datetime"
        name="from"
        value={settings.from}
        format="HH:mm dd.MM.yyyy"
        disabled={disabled}
        onChange={(e) => {
          if (String(e.target.value) !== 'Invalid Date') {
            const updatedSettings = {
              ...settings,
              from: e.target.value && new Date(e.target.value).toUTCString(),
            };

            onSettingsChange(updatedSettings);
            setPage(0);
          }
        }}
      />
      <StyledDatePicker
        label={T.translate(LabelsLocaleKeys.to).toUpperCase()}
        type="datetime"
        name="to"
        format="HH:mm dd.MM.yyyy"
        disabled={disabled}
        value={settings.to}
        onChange={(e) => {
          if (String(e.target.value) !== 'Invalid Date') {
            const updatedSettings = {
              ...settings,
              to: e.target.value && new Date(e.target.value).toUTCString(),
            };

            onSettingsChange(updatedSettings);
            setPage(0);
          }
        }}
      />
      <Checkbox
        value={settings.activeChecked}
        disabled={disabled}
        label={T.translate(LabelsLocaleKeys.activeOnly).toUpperCase()}
        onChange={(e) => {
          setHistoryEntries([]);
          const updatedSettings = { ...settings };

          updatedSettings.activeChecked = e.target.value;
          onSettingsChange(updatedSettings);
          setPage(0);
        }}
      />
      <Checkbox
        value={settings.inChecked}
        disabled={disabled}
        label={T.translate(LabelsLocaleKeys.in).toUpperCase()}
        onChange={(e) => {
          setHistoryEntries([]);
          const updatedSettings = { ...settings };

          updatedSettings.inChecked = e.target.value;
          onSettingsChange(updatedSettings);
          setPage(0);
        }}
      />
      <Checkbox
        value={settings.outChecked}
        disabled={disabled}
        label={T.translate(LabelsLocaleKeys.out).toUpperCase()}
        onChange={(e) => {
          const updatedSettings = { ...settings };

          updatedSettings.outChecked = e.target.value;
          onSettingsChange(updatedSettings);
          setPage(0);
        }}
      />
    </Box>
  );
};

Filter.propTypes = {
  setHistoryEntries: PropTypes.func,
  disabled: PropTypes.bool,
  setPage: PropTypes.func,
  settings: PropTypes.object,
  onSettingsChange: PropTypes.func,
};

export default Filter;
