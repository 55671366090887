import React from 'react';
import { styled } from '@material-ui/core/styles';
import { DirectionsCar } from '@material-ui/icons';

const IconCircle = styled(({ size, ...props }) => <div {...props} />)({
  margin: (props) => props.margin || '0 auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: (props) => (props.size === 'large' ? 80 : 40),
  height: (props) => (props.size === 'large' ? 80 : 40),
  borderRadius: '50%',
  color: '#fff',
  backgroundColor: (props) => props.color,
});
const Icon = ({ color = '#000', ...props }) => (
  <IconCircle color={color && color.includes('#') ? color : '#000'} {...props}>
    <DirectionsCar />
  </IconCircle>
);

export default Icon;
