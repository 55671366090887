import React from 'react';
import PropTypes from 'prop-types';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import { Box, Typography } from '@material-ui/core';
import { withFormik } from 'Components/formik/formikWrappers';
import { AlarmStatusImage, Checkbox } from 'Components/formik/fields';

const DashboardConfiguration = ({ data }) => (
  <>
    <ColumnDisplayer>
      <Checkbox label={T.translate(LocaleKeys.labels.threatBoard)} name="displayOnThreatBoard" />
    </ColumnDisplayer>
    <ColumnDisplayer>
      <Typography>{T.translate(LocaleKeys.labels.imagesForAssetsStatus)}</Typography>
    </ColumnDisplayer>
    <Box display="flex" marginLeft="20%">
      <Box>
        <AlarmStatusImage
          name="imageOn"
          id="image_on_upload"
          image={data.imageOn?.value}
          label={T.translate(LocaleKeys.labels.alarmStart)}
          color="#d9001b"
          imageId={data.imageOnId}
        />
      </Box>
      <Box>
        <AlarmStatusImage
          id="image_off_upload"
          name="imageOff"
          image={data.imageOff?.value}
          label={T.translate(LocaleKeys.labels.alarmStop)}
          color="#02a7f0"
          imageId={data.imageOffId}
        />
      </Box>
    </Box>
  </>
);

DashboardConfiguration.propTypes = {
  data: PropTypes.object,
};

export default withFormik(DashboardConfiguration);
