import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import SaveOnLeave from 'Components/dialogs/SaveOnLeave';
import Tabs from 'Components/display/tabs/Tabs';
import Button from 'Components/Button';
import Definitions from './tabs/Definitions';
import DashboardConfiguration from './tabs/DashboardConfiguration';
import AssetAssignment from './tabs/AssetAssignment';
import Formulas from './tabs/Formulas';
import Handling from './tabs/Handling';
import NotificationSettings from './tabs/NotificationSettings';
import Validity from './tabs/Validity';
import { styled } from '@material-ui/core/styles';
import { compose } from 'redux';
import { StatusAndAlarmDefinitions, StatusAndAlarmDefinitionsDetails } from 'routes/Routes';
import { Permissions } from 'Constants/permissions';
import withPermissions from 'hocs/withPermissions';
import AutomaticCommands from './tabs/AutomaticCommands';
import {
  getAlarmByName,
  getAlarm,
  putAlarm,
  getDevicesForAlarm,
  getTimeRelatedMappings,
  getLibrary,
} from 'Api/devices';
import { getMapAreasSystemMappings } from 'Api/maps';
import { CircularProgress } from '@material-ui/core';
import {
  addResource,
  convertSecondsToTimePeriodMaskDays,
  convertCommandsDelayToPeriodMask,
  prepareAlarmData,
  mapInputsMappings,
  getLibraryInputMappings,
  convertFormulas,
  convertMinutesToHoursMinutesMask,
  mapWebhooks,
} from './helpers';
import validationSchema from './validationSchema';
import { AlarmStatuses } from 'Constants/AlarmStatuses';
import { FormikEnhanced } from 'Components/formik/formikWrappers';

const ButtonStyled = styled(Button)({
  float: 'right',
  marginLeft: '10px',
});

const Details = (props) => {
  const [alarm, setAlarm] = useState();
  const [assets, setAssets] = useState();
  const [isLoadingAssets, setIsLoadingAssets] = useState(false);
  const [alarmLibrary, setAlarmLibrary] = useState();
  const [systemInputs, setSystemInputs] = useState();
  const validationCache = useRef({
    name: null,
  });
  const fetchDataCallback = useCallback(
    (alarmId) => {
      const { name } = props.match.params;

      (!alarmId ? getAlarmByName(name.replace(/-/g, ' ')) : getAlarm(alarmId)).then(async (alarmResponse) => {
        if (name !== alarmResponse.data.name) {
          const url = StatusAndAlarmDefinitionsDetails.getUrl({
            name: alarmResponse.data.name.replace(/\s/g, '-'),
          });

          window.history.replaceState(null, null, url);
        }

        const data = alarmResponse.data;
        const alarmId = alarmResponse.data.id;

        const today = new Date();
        const tommorow = new Date();

        tommorow.setDate(today.getDate() + 1);
        data.validFrom = data.validFrom || today.toISOString();
        data.validTo = data.validTo || tommorow.toISOString();

        if (data.commands.length) {
          convertCommandsDelayToPeriodMask(data.commands);
        }
        if (data.emails.length) {
          data.emails = data.emails.map((email) => ({ email }));
        }

        data.oneTimeAlarmTimePeriod = convertSecondsToTimePeriodMaskDays(data.oneTimeAlarmTimePeriod);

        data.autoReadInMinutes = convertMinutesToHoursMinutesMask(data.autoReadInMinutes);

        if (data.assignedUsers) {
          data.assignedUsers = data.assignedUsers.map((u) => ({ label: u, value: u }));
        }
        if (data.startFormulas.length) {
          data.startFormulas = convertFormulas(data.startFormulas);
        }
        if (data.endFormulas.length) {
          data.endFormulas = convertFormulas(data.endFormulas);
        }

        data.webhooks = mapWebhooks(data.webhooks);

        setAlarm(data);
        setIsLoadingAssets(true);
        getDevicesForAlarm(alarmId).then((response) => {
          const assets = response.data.map((device) => ({
            id: device.id,
            externalId: device.externalId,
            name: device.name,
            disabled: !device.isAvailable,
          }));

          setAssets(assets);
          setIsLoadingAssets(false);
        });

        if (data.deviceLibraryId) {
          getLibrary(data.deviceLibraryId).then(({ data }) => {
            setAlarmLibrary(data);
          });
        } else {
          setAlarmLibrary(null);
        }
      });
    },
    [props.match]
  );

  useEffect(() => {
    fetchDataCallback();
    Promise.all([getMapAreasSystemMappings(), getTimeRelatedMappings()]).then(
      ([mapAreasMappingsResponse, timeRelatedMappingsResponse]) => {
        const mapAreasInputs = mapInputsMappings(mapAreasMappingsResponse.data);
        const timeRelatedInputs = mapInputsMappings(timeRelatedMappingsResponse.data);

        setSystemInputs([...mapAreasInputs, ...timeRelatedInputs]);
      }
    );
  }, [fetchDataCallback]);

  const saveData = async (values) => {
    if (values.imageOnId === 0 && values.imageOn) {
      await addResource(values, 'imageOn', 'imageOnId');
    }

    if (values.imageOffId === 0 && values.imageOff) {
      await addResource(values, 'imageOff', 'imageOffId');
    }

    const alarmData = prepareAlarmData(values);

    return putAlarm(alarmData.id, alarmData);
  };

  const redirect = () => {
    const url = StatusAndAlarmDefinitions.getUrl();

    props.history.push(url);
  };

  const onSubmit = (values, { setSubmitting }) => {
    saveData(values).finally(() => {
      setSubmitting(false);
      redirect();
    });
  };

  const onCancel = () => {
    redirect();
  };

  const onSwitchTabConfirmSave = async (values, setSubmitting) => {
    setSubmitting(true);
    saveData(values).then(() => {
      fetchDataCallback(alarm.id);
      setSubmitting(false);
    });
  };

  const onSwitchTabContinueWithoutSave = (dirty) => {
    if (dirty) {
      fetchDataCallback(alarm.id);
    }
  };

  if (!alarm || !assets || !systemInputs) {
    return <CircularProgress />;
  }

  const devicesInputs = getLibraryInputMappings(alarmLibrary);
  const inputMappings = systemInputs.concat(devicesInputs);
  const canSaveChanges =
    !!props.permissions[Permissions.CanEditAlarm] && alarm.status !== AlarmStatuses.FORCEFULLY_DEACTIVATED;

  return (
    <FormikEnhanced
      initialValues={alarm}
      enableReinitialize
      validationSchema={validationSchema(alarm, validationCache.current)}
      validateOnMount
      onSubmit={onSubmit}
      canSaveChanges={canSaveChanges}
    >
      {({ values, isValid, isSubmitting, dirty, handleSubmit, setSubmitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Tabs
              label="AlarmDefinition"
              saveChange={() => onSwitchTabConfirmSave(values, setSubmitting)}
              setData={() => onSwitchTabContinueWithoutSave(dirty)}
              showConfirmationDialog={dirty && canSaveChanges}
              validForm={isValid}
            >
              <Definitions label={LocaleKeys.labels.definition} />
              <AssetAssignment
                label={LocaleKeys.labels.assetAssignment}
                assets={assets}
                isLoadingAssets={isLoadingAssets}
                inputMappings={inputMappings}
                systemInputs={systemInputs}
              />
              <Formulas
                label={LocaleKeys.labels.formula}
                alarmLibrary={alarmLibrary}
                setAlarmLibrary={setAlarmLibrary}
                inputMappings={inputMappings}
              />
              <DashboardConfiguration label={LocaleKeys.labels.dashboardConfiguration} />
              <Validity label={LocaleKeys.labels.validity} />
              <NotificationSettings label={LocaleKeys.labels.notificationSettings} />
              <Handling label={LocaleKeys.labels.handling} />
              <AutomaticCommands label={LocaleKeys.labels.automaticCommands} />
            </Tabs>

            <ButtonStyled onClick={onCancel}>{T.translate(LocaleKeys.labels.cancel)}</ButtonStyled>
            {canSaveChanges && [
              <ButtonStyled
                key={'SubmitButton'}
                disabled={!(dirty && isValid) || isSubmitting}
                type="submit"
                showProgress={isSubmitting}
              >
                {T.translate(LocaleKeys.labels.save)}
              </ButtonStyled>,

              <SaveOnLeave
                key={'SaveOnLeave'}
                saveData={() => saveData(values)}
                dataChanged={dirty}
                validForm={isValid}
              />,
            ]}
          </form>
        );
      }}
    </FormikEnhanced>
  );
};

Details.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  permissions: PropTypes.object,
};

export default compose(
  withPermissions([
    Permissions.CanReadAlarms,
    Permissions.CanReadDevices,
    Permissions.CanReadCalendarTemplates,
    Permissions.CanReadMapAreas,
    Permissions.CanReadUsers,
    Permissions.CanAddLibraries,
    Permissions.CanReadLibraries,
  ])
)(Details);
