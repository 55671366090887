// based on: http://api.dna-dev.tru.io/devicestore/api/dictionaries/daysofweek

export const DaysOfWeek = {
  SUNDAY: 1,
  MONDAY: 2,
  TUESDAY: 3,
  WEDNESDAY: 4,
  THURSDAY: 5,
  FRIDAY: 6,
  SATURDAY: 7,
};
