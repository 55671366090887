import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import Button from 'Components/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContent } from '@material-ui/core';
import BrokenAlarmsWarning from 'Components/display/BrokenAlarmsWarning';

const AlarmsAffectedFormLeaveDialog = ({
  open,
  onConfirm,
  onClose,
  onContinueWithoutSaving,
  isValid,
  affectedAlarms,
  error,
  isSubmitting,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {T.translate(
        isValid ? LocaleKeys.messages.onLeaveValidFormMessage : LocaleKeys.messages.onLeaveInvalidFormMessage
      )}
    </DialogTitle>
    <DialogContent>
      {!!error && T.translate(LocaleKeys.messages.pleaseReloadAndTryAgain)}
      {!!affectedAlarms?.length && (
        <BrokenAlarmsWarning
          message={T.translate(LocaleKeys.messages.brokenAlarmsDialogTitle)}
          alarms={affectedAlarms}
        />
      )}
    </DialogContent>
    <DialogActions>
      {!error && isValid && (
        <Button onClick={onConfirm} color="primary" id="saveOnLeave-save" disabled={isSubmitting}>
          {T.translate(LocaleKeys.labels.save)}
        </Button>
      )}
      <Button onClick={onContinueWithoutSaving} color="primary" autoFocus id="saveOnLeave-continueButton">
        {T.translate(isValid ? LocaleKeys.labels.doNotSave : LocaleKeys.labels.yes)}
      </Button>
      <Button onClick={onClose} color="primary" autoFocus id="saveOnLeave-cancelButton">
        {T.translate(LocaleKeys.labels.cancel)}
      </Button>
    </DialogActions>
  </Dialog>
);

AlarmsAffectedFormLeaveDialog.propTypes = {
  isValid: PropTypes.bool,
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  onContinueWithoutSaving: PropTypes.func,
  isSubmitting: PropTypes.bool,
  affectedAlarms: PropTypes.array,
  error: PropTypes.object,
};

export default AlarmsAffectedFormLeaveDialog;
