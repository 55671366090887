import React from 'react';
import PropTypes from 'prop-types';
import { Paper, MenuItem, MenuList, styled } from '@material-ui/core';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import RadioInput from 'Components/inputs/RadioInput';

// Done with styles instead of material propsed anchorReference anchorPosition due to problems with that implementation
const ContextMenu = styled(Paper)(({ top, left }) => ({
  position: 'absolute',
  zIndex: 410,
  top,
  left,
}));

const TimePeriodContextMenu = styled(ContextMenu)({
  width: 190,
  paddingLeft: 10,
});

const ContextMenus = ({
  top,
  left,
  contextMenuItems,
  menuActive,
  setMenuActive,
  geoPosMenuActive,
  setGeoPosMenuActive,
  selectDeviceGeoPos,
  favDevices,
  favClick,
  deviceTrail,
}) => {
  const onMouseLeave = (e) => {
    setMenuActive(false);
    setGeoPosMenuActive(false);
  };

  const radioChange = (e) => {
    selectDeviceGeoPos(geoPosMenuActive, e.target.value);
    onMouseLeave();
  };

  return (
    <>
      {geoPosMenuActive && (
        <TimePeriodContextMenu top={top} left={left} onMouseLeave={onMouseLeave}>
          <RadioInput
            name={'timePeriodInMinutes'}
            onChange={radioChange}
            values={[
              {
                value: '15',
                label: T.translate(LocaleKeys.labels.last15Minutes),
              },
              {
                value: '60',
                label: T.translate(LocaleKeys.labels.lastHour),
              },
            ]}
            value={deviceTrail.filter((d) => d.externalId === geoPosMenuActive)[0]?.selectedTimePeriod || '15'}
          />
        </TimePeriodContextMenu>
      )}
      {menuActive && (
        <ContextMenu top={top} left={left} onMouseLeave={onMouseLeave}>
          <MenuList>
            {contextMenuItems?.map((menuItem) => (
              <MenuItem
                key={menuItem.label}
                onClick={() => {
                  menuItem.function(menuActive);
                  setMenuActive(false);
                }}
              >
                {menuItem.label}
              </MenuItem>
            ))}
            <MenuItem onClick={(e) => favClick(e, menuActive)}>
              {T.translate(
                favDevices.includes(menuActive)
                  ? LocaleKeys.labels.removeFromFavourites
                  : LocaleKeys.labels.addToFavourites
              )}
            </MenuItem>
          </MenuList>
        </ContextMenu>
      )}
    </>
  );
};

ContextMenus.propTypes = {
  favDevices: PropTypes.array,
  setMenuActive: PropTypes.func,
  favClick: PropTypes.func,
};

export default ContextMenus;
