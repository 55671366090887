import { createTypes, async, actionCreator } from 'redux-action-creator';

export const types = createTypes(
  [
    'SET_LOADING',
    'ADD_THREATBOARD_ALARM',
    ...async('LOAD_THREATBOARD_ALARMS'),
    'SET_THREATBOARD_ALARMS',
    'ADD_THREATBOARD_DEVICE',
    'DELETE_THREATBOARD_DEVICE',
    'SET_THREATBOARD_DEVICES',
    ...async('LOAD'),
    'UPDATE',
    'UPDATE_POSITION',
    'SET_GEO_POINTS',
    'SET_COMMAND_BUTTON',
  ],
  'ASSETS'
);

export const setLoading = actionCreator(types.SET_LOADING, 'loading');

export const addThreatboardAlarm = actionCreator(types.ADD_THREATBOARD_ALARM, 'alarm');

export const loadThreatBoardAlarmsSuccess = actionCreator(types.LOAD_THREATBOARD_ALARMS_SUCCESS, 'alarms');

export const loadThreatBoardAlarmsFail = actionCreator(types.LOAD_THREATBOARD_ALARMS_FAIL, 'error');

export const setThreatBoardAlarms = actionCreator(types.SET_THREATBOARD_ALARMS, 'alarms');

export const addThreatboardDevice = (assetId) => ({
  type: types.ADD_THREATBOARD_DEVICE,
  assetId,
});

export const deleteThreatboardDevice = (assetId) => ({
  type: types.DELETE_THREATBOARD_DEVICE,
  assetId,
});

export const setThreatsboardDevice = (devices) => ({
  type: types.SET_THREATBOARD_DEVICES,
  devices,
});

export const loadSuccess = actionCreator(types.LOAD_SUCCESS, 'devices');

export const loadFail = actionCreator(types.LOAD_FAIL, 'error');

export const update = actionCreator(types.UPDATE, 'externalId', 'device');

export const updatePosition = actionCreator(types.UPDATE_POSITION, 'externalId', 'position');

export const setGeoPoints = actionCreator(types.SET_GEO_POINTS, 'externalId', 'geoPoints');

export const setAssetCommandButton = actionCreator(
  types.SET_COMMAND_BUTTON,
  'assetId',
  'buttonId',
  'currentState',
  'lastSentAt'
);
