import * as Yup from 'yup';
import { menuTemplateExists } from 'Api/sites';

const nameValidationSchema = Yup.string()
  .required('thisFieldIsRequired')
  .alphanumericOrSpecialSymbol([' '])
  .max(50, 'nameCannotExceed50Characters');

export const validationSchema = (initialValues) =>
  Yup.object().shape({
    name: nameValidationSchema.test(
      'checkForDuplicateName',
      'siteConfigurationWithThisNameAlreadyExists',
      async (val) => {
        if (val === initialValues.name) {
          return true;
        }
        if (await nameValidationSchema.isValid(val)) {
          const response = await menuTemplateExists(val);

          return !response.data;
        }

        return false;
      }
    ),
    languages: Yup.array().required('selecteAtLeastOne'),
  });
