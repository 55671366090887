import React from 'react';
import PropTypes from 'prop-types';
import { Refresh, ErrorOutlineRounded } from '@material-ui/icons';
import { IconButton, List, ListItemIcon, ListItemText, ListItem, Box } from '@material-ui/core';

const ErrorMessage = ({ primaryMessage, secondaryMessage, onRetry }) => {
  return (
    <Box margin="0 auto">
      <List dense>
        <ListItem>
          <ListItemIcon>
            <ErrorOutlineRounded color="error" />
          </ListItemIcon>
          <ListItemText
            primary={primaryMessage}
            secondary={secondaryMessage}
            secondaryTypographyProps={{
              color: 'initial',
            }}
          />
          {!!onRetry && (
            <ListItemIcon>
              <IconButton style={{ width: 40, height: 40 }} onClick={onRetry}>
                <Refresh />
              </IconButton>
            </ListItemIcon>
          )}
        </ListItem>
      </List>
    </Box>
  );
};

ErrorMessage.propTypes = {
  primaryMessage: PropTypes.string,
  secondaryMessage: PropTypes.string,
  onRetry: PropTypes.func,
};

export default ErrorMessage;
