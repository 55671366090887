import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { compose } from 'redux';
import { CircularProgress, Box, styled, Paper, Divider } from '@material-ui/core';
import { FormikEnhanced } from 'Components/formik/formikWrappers';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import Button from 'Components/Button';
import { getDevicesBasicInformation, postDevicesGroup, getDevicesGroupByName, putDevicesGroup } from 'Api/devices';
import { DevicesGroups } from 'routes/Routes';
import SaveOnLeave from 'Components/dialogs/SaveOnLeave';
import { clone } from 'Helpers/ObjectHelper';
import ListInput from 'Components/inputs/ListInput';
import DevicesGroupValidators from './DevicesGroupValidators';
import { TextField } from 'Components/formik/fields';

const initialState = {
  name: '',
  devices: [],
};

const Container = styled(Paper)({
  height: 'calc(100vh - 170px)',
  overflow: 'hidden',
  margin: 10,
});

const ButtonStyled = styled(Button)({
  float: 'right',
  marginLeft: '10px',
});

const Details = (props) => {
  const [devicesGroup, setDevicesGroup] = useState(null);
  const [devices, setDevices] = useState(null);

  useEffect(() => {
    getDevicesBasicInformation().then(({ data }) => {
      setDevices(data);
    });
  }, []);

  useEffect(() => {
    const name = props.match.params?.name?.replace(/-/g, ' ');
    const locationState = props.history.location.state;

    if (name || locationState?.basedOn) {
      getDevicesGroupByName(name || locationState.basedOn.replace(/-/g, ' ')).then(({ data }) => {
        if (!name) {
          setDevicesGroup({
            ...clone(initialState),
            devices: data.devices,
          });
        } else {
          setDevicesGroup(data);
        }
      });
    } else {
      setDevicesGroup(clone(initialState));
    }
  }, [props.history.location.state, props.match.params]);

  const isEditMode = () => !!props.match.params.name;

  const redirect = () => {
    const url = DevicesGroups.getUrl();

    props.history.push(url);
  };

  const onSubmit = (values, { setSubmitting }) => {
    if (isEditMode()) {
      putDevicesGroup(values.externalId, values).then(() => {
        setSubmitting(false);
        redirect();
      });
    } else {
      postDevicesGroup(values).then(() => {
        setSubmitting(false);
        redirect();
      });
    }
  };

  if (!devicesGroup || !devices) {
    return <CircularProgress />;
  }

  const canSaveChanges = isEditMode()
    ? !!props.permissions[Permissions.CanEditDevicesGroup]
    : !!props.permissions[Permissions.CanAddDevicesGroup];

  return (
    <FormikEnhanced
      initialValues={devicesGroup}
      enableReinitialize
      validationSchema={DevicesGroupValidators}
      validateOnMount
      onSubmit={onSubmit}
      canSaveChanges={canSaveChanges}
    >
      {({ values, dirty, isValid, isSubmitting, handleSubmit, handleChange }) => (
        <form onSubmit={handleSubmit}>
          <Container>
            <Box width={'450px'} margin={'10px'}>
              <TextField label={T.translate(LocaleKeys.labels.groupName)} name="name" showPending />
            </Box>
            <Divider />
            <Box height={'calc(100% - 120px)'}>
              <ListInput
                selected={values?.devices}
                items={devices}
                disabled={!canSaveChanges}
                onChange={(value) => handleChange({ target: { value, name: 'devices' } })}
                valueField="externalId"
                displayField="name"
                label={T.translate(LocaleKeys.labels.devices).toUpperCase()}
                width={'450px'}
              />
            </Box>
          </Container>
          <Box margin={'10px'}>
            <ButtonStyled onClick={redirect} id="devicesGroups-cancelButton">
              {T.translate(LocaleKeys.labels.cancel)}
            </ButtonStyled>
            {canSaveChanges && (
              <ButtonStyled
                type="submit"
                disabled={!(dirty && isValid) || isSubmitting}
                id="devicesGroups-submitButton"
              >
                {T.translate(LocaleKeys.labels.save)}
              </ButtonStyled>
            )}
          </Box>
          {canSaveChanges && <SaveOnLeave saveData={onSubmit} dataChanged={dirty} validForm={isValid} />}
        </form>
      )}
    </FormikEnhanced>
  );
};

Details.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
};

export const DevicesGroupsNew = compose(
  withRouter,
  withPermissions([
    Permissions.CanAccessDevicesGroupsPage,
    Permissions.CanReadDevicesGroups,
    Permissions.CanAddDevicesGroup,
    Permissions.CanReadDevices,
  ])
)(Details);

export const DevicesGroupsDetails = compose(
  withRouter,
  withPermissions([
    Permissions.CanAccessDevicesGroupsPage,
    Permissions.CanReadDevicesGroups,
    Permissions.CanReadDevices,
  ])
)(Details);
