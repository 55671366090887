import React from 'react';
import PropTypes from 'prop-types';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import { getCalendarsLinkedToObject, getTimeZones } from 'Api/sites';
import { getValidityTypes } from 'Api/devices';
import { withFormik } from 'Components/formik/formikWrappers';
import { AlarmValidityTypes } from 'Constants/AlarmValidityTypes';
import { subtractCurrentTimezoneOffset, addCurrentTimezoneOffset } from 'Helpers/DateTimeHelpers';
import { DateTimeInput, DropdownWithApiCall } from 'Components/formik/fields';

const Validity = ({ data, onChange }) => (
  <>
    <ColumnDisplayer>
      <DropdownWithApiCall
        key="validityType"
        label={T.translate(LocaleKeys.labels.validity)}
        name="validityType"
        apiCall={getValidityTypes}
        displayField="name"
        valueField="value"
        translateLabel
        withoutEmpty
      />
    </ColumnDisplayer>
    {data.validityType === AlarmValidityTypes.CUSTOM && (
      <ColumnDisplayer>
        <DropdownWithApiCall
          label={T.translate(LocaleKeys.labels.timezones)}
          name="timeZone"
          apiCall={() => getTimeZones('en-GB')}
          displayField="displayName"
          valueField="key"
          withoutEmpty
        />
        <DateTimeInput
          label={T.translate(LocaleKeys.labels.from)}
          name="validFrom"
          onChange={(e) => {
            e.target.value = subtractCurrentTimezoneOffset(e.target.value);
            onChange(e);
          }}
          convertValue={addCurrentTimezoneOffset}
          type="datetime"
          format="HH:mm dd.MM.yyyy"
        />
        <DateTimeInput
          label={T.translate(LocaleKeys.labels.to)}
          name="validTo"
          onChange={(e) => {
            e.target.value = subtractCurrentTimezoneOffset(e.target.value);
            onChange(e);
          }}
          convertValue={addCurrentTimezoneOffset}
          type="datetime"
          format="HH:mm dd.MM.yyyy"
        />
      </ColumnDisplayer>
    )}
    {data.validityType === AlarmValidityTypes.CALLENDAR_TEMPLATE && (
      <ColumnDisplayer>
        <DropdownWithApiCall
          label={T.translate(LocaleKeys.labels.calendarTemplates)}
          name="calendarTemplateId"
          displayField="name"
          withoutEmpty
          apiCall={() => getCalendarsLinkedToObject(1)}
          valueField="id"
        />
      </ColumnDisplayer>
    )}
  </>
);

Validity.propTypes = {
  data: PropTypes.object,
};

export default withFormik(Validity);
