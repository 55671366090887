import React from 'react';
import PropTypes from 'prop-types';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import Button from 'Components/Button';
import BrokenAlarmsWarning from 'Components/display/BrokenAlarmsWarning';

const SaveDialog = ({ affectedAlarms, isSubmitting, ...props }) => (
  <Dialog
    open={props.open}
    aria-labelledby={'alert-dialog-title'}
    aria-describedby={'alert-dialog-description'}
    fullWidth
  >
    <DialogTitle id="alert-dialog-title">
      {!!props.error
        ? T.translate(LocaleKeys.messages.errorWhileFetchingData, {
            name: T.translate(LocaleKeys.labels.affectedAlarms),
          })
        : T.translate(LocaleKeys.messages.mappingsChange)}
    </DialogTitle>
    <DialogContent>
      {!!props.error && T.translate(LocaleKeys.messages.pleaseReloadAndTryAgain)}
      {affectedAlarms?.length && (
        <BrokenAlarmsWarning
          message={T.translate(LocaleKeys.messages.brokenAlarmsDialogTitle)}
          alarms={affectedAlarms}
        />
      )}
    </DialogContent>
    <DialogActions>
      {!!props.error ? (
        <Button onClick={props.handleReload} autoFocus>
          {T.translate(LocaleKeys.labels.reload)}
        </Button>
      ) : (
        <>
          <Button onClick={props.handleCancel} autoFocus>
            {T.translate(LocaleKeys.labels.cancel)}
          </Button>
          <Button onClick={props.handleSubmit} autoFocus disabled={isSubmitting} showProgress={isSubmitting}>
            {T.translate(LocaleKeys.labels.save)}
          </Button>
        </>
      )}
    </DialogActions>
  </Dialog>
);

SaveDialog.propTypes = {
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  handleReload: PropTypes.func,
  open: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  affectedAlarms: PropTypes.array,
};

export default SaveDialog;
