import React from 'react';
import PropTypes from 'prop-types';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import BrokenAlarmsWarning from 'Components/display/BrokenAlarmsWarning';
import DeleteDialog from './DeleteDialog';

const DeleteAffectingAlarmsDialog = ({ affectedAlarms, message, ...props }) => (
  <DeleteDialog
    content={
      !!affectedAlarms.length ? (
        <BrokenAlarmsWarning
          message={T.translate(LocaleKeys.messages.belowAlarmsWillBeAffectedByThisChange)}
          alarms={affectedAlarms}
        />
      ) : (
        T.translate(message)
      )
    }
    {...props}
  />
);

DeleteAffectingAlarmsDialog.propTypes = {
  message: PropTypes.string.isRequired,
  affectedAlarms: PropTypes.array,
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  disableConfirm: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.element,
};

export default DeleteAffectingAlarmsDialog;
