import React, { useState, useEffect, useCallback, useRef, forwardRef, useImperativeHandle, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DeleteDialog from 'Components/dialogs/DeleteDialog';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import withRouterAndRef from 'hocs/withRouterAndRef';
import Loader from 'Components/Loader';
import TableActionButton from 'Components/display/table/TableActionButton';
import TableView from './TableView';
import { InputAdornment, IconButton, Box } from '@material-ui/core';
import TextInput from 'Components/inputs/TextInput';
import CloseIcon from '@material-ui/icons/Close';
import Dropdown from 'Components/inputs/Dropdown';
import { styled } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { compareObjects } from 'Helpers/ObjectHelper';
import { ApiHeaders } from 'Api/ApiClient';

const isEmpty = (filter) => (Array.isArray(filter) ? filter.length === 0 : filter === '');

const DropdownStyled = styled(Dropdown)({
  margin: 16,
  minWidth: 200,
});

const FabStyled = styled(Fab)({
  marginTop: 14,
  marginLeft: 10,
});

const EditButton = ({ onClick }) => (
  <TableActionButton icon={<EditIcon />} tooltipText={T.translate(LocaleKeys.tooltips.editItem)} onClick={onClick} />
);

export const DeleteButton = ({ onClick }) => (
  <TableActionButton
    icon={<DeleteIcon />}
    tooltipText={T.translate(LocaleKeys.tooltips.deleteItem)}
    onClick={onClick}
    style={{ marginLeft: '10px' }}
  />
);

let timeout = null;

export const TableApi = forwardRef(
  ({ client, columns, sites, newUrl, buttons, history, getEditUrl, deleteRequest, itemName, ...props }, ref) => {
    const [data, setData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [itemToRemove, setItemToRemove] = useState(null);
    const [selectedSiteName] = useState(props.currentSite);
    const [selectedSiteId, setSelectedSiteId] = useState(props.selectedSiteId || props.currentSiteId);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState({});
    const [filters, setFilters] = useState({});
    const [search, setSearch] = useState('');
    const prevState = useRef({
      page,
      rowsPerPage,
      order,
      filters,
    });

    const fetchData = useCallback(
      (resetPage) => {
        setIsLoaded(false);
        client(
          page + 1,
          rowsPerPage,
          order.key ? `${order.direction === 'desc' ? '-' : ''}${order.key}` : 'id',
          filters,
          search,
          selectedSiteId
        ).then((response) => {
          setData(response.data);
          setIsLoaded(true);
          if (resetPage) {
            setPage(0);
          }
        });
      },
      [client, page, rowsPerPage, order, filters, search, selectedSiteId]
    );

    useEffect(() => {
      if (timeout) {
        clearTimeout(timeout);
      }
      // check if should be delayed
      const pageChanged = page !== prevState.current.page;
      let delayed = !pageChanged && rowsPerPage === prevState.current.rowsPerPage && order === prevState.current.order;
      const searchChangedToEmpty = !!prevState.current.search && !search;
      const filtersKeysChanged = Object.keys(filters).filter(
        (key) => !compareObjects(prevState.current.filters[key], filters[key])
      );
      const filtersKeysAddedEmpty = filtersKeysChanged.filter(
        (key) => isEmpty(filters[key]?.value) && prevState.current.filters[key] === undefined
      );
      const filtersKeysDeletedEmpty = Object.keys(prevState.current.filters).filter(
        (key) => isEmpty(prevState.current.filters[key]?.value) && filters[key] === undefined
      );
      const filtersKeysDeleted = Object.keys(prevState.current.filters).length > Object.keys(filters).length;

      if (filtersKeysAddedEmpty.length || filtersKeysDeletedEmpty.length) {
        return;
      }

      if (searchChangedToEmpty || filtersKeysDeleted) {
        delayed = false;
      }

      if (delayed) {
        timeout = setTimeout(fetchData.bind(this, !pageChanged), 2000);
      } else {
        fetchData();
      }
      // eslint-disable-next-line
    }, [fetchData]);

    useEffect(() => {
      prevState.current.page = page;
      prevState.current.rowsPerPage = rowsPerPage;
      prevState.current.order = order;
      prevState.current.search = search;
      prevState.current.filters = filters;
    });

    const deleteHandleClose = () => {
      setDeleteDialogOpen(false);
      setItemToRemove(null);
    };

    const handleDeleteConfirm = () => {
      return deleteClick(itemToRemove).then(() => {
        setDeleteDialogOpen(false);
        setItemToRemove(null);
      });
    };

    const deleteClick = (deviceId) => {
      if (selectedSiteId === props.currentSiteId || selectedSiteId === '') {
        return deleteRequest(deviceId).then(() => {
          fetchData();
        });
      } else {
        return deleteRequest(deviceId, { headers: { [ApiHeaders.X_TENANT_ID]: selectedSiteId } }).then(() => {
          fetchData();
        });
      }
    };

    useImperativeHandle(ref, () => ({
      deleteClick,
    }));

    const handleEditClickCallback = useCallback(
      (id, name) => async () => {
        const params = {
          redirectSiteId: props.currentSiteId,
          redirectSiteName: props.currentSite,
          redirectSelectedSiteId: selectedSiteId,
        };

        if (selectedSiteId === props.currentSiteId || selectedSiteId === '') {
          const url = getEditUrl({
            id: id,
            name,
          });

          history.push(url, params);
        } else {
          const url = getEditUrl({
            id: id,
            siteName: selectedSiteName.replace(/\s/g, '-'),
            name,
          });

          history.push(url, params);
        }
      },
      [selectedSiteId, getEditUrl, history, props.currentSite, selectedSiteName, props.currentSiteId]
    );

    const columnsMemo = useMemo(() => {
      const memo = { ...columns };

      if (!memo.edit) {
        memo.edit = {
          name: ' ',
          disableSort: true,
          disableFilter: true,
          // eslint-disable-next-line
          format: ({ id, name }) =>
            !!buttons.edit && <EditButton onClick={handleEditClickCallback(id, name.replace(/\s/g, '-'))} />,
        };
      }
      if (!memo.delete) {
        memo.delete = {
          name: ' ',
          disableSort: true,
          disableFilter: true,
          // eslint-disable-next-line
          format: ({ id, externalId }) =>
            !!buttons.delete && (
              <DeleteButton
                onClick={() => {
                  setDeleteDialogOpen(true);
                  setItemToRemove(id || externalId);
                }}
              />
            ),
        };
      }

      return memo;
    }, [columns, buttons.delete, buttons.edit, handleEditClickCallback]);

    if (!isLoaded) {
      return <Loader />;
    }

    return (
      <>
        <Box display="flex" justifyContent="flex-start" mb={2}>
          <TextInput
            label={T.translate(LocaleKeys.labels['filter'])}
            autoFocus
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setSearch('');
                    }}
                    disabled={!search}
                  >
                    <CloseIcon style={{ visibility: !!search ? 'visible' : 'hidden' }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {!!sites && (
            <DropdownStyled
              label={T.translate(LocaleKeys.labels.sites)}
              data={sites}
              displayField="name"
              valueField="siteId"
              value={selectedSiteId}
              onChange={(e) => {
                setSelectedSiteId(e.target.value);
              }}
              withoutEmpty
            />
          )}
          {props.canAddNew && selectedSiteId === props.currentSiteId && (
            <Tooltip
              title={T.translate(LocaleKeys.tooltips.addNewItem)}
              onClick={() => {
                history.push(newUrl);
              }}
            >
              <FabStyled>
                <AddIcon />
              </FabStyled>
            </Tooltip>
          )}
        </Box>
        <TableView
          columns={columnsMemo}
          rows={data.data}
          total={data.total}
          rowsPerPage={rowsPerPage}
          page={page}
          order={order}
          filters={filters}
          setPage={setPage}
          setRowsPerPage={(rows) => {
            setRowsPerPage(rows);
            setPage(0);
          }}
          setOrder={(order) => {
            setOrder(order);
            setPage(0);
          }}
          setFilters={(filters) => {
            setFilters(filters);
            setPage(0);
          }}
          handleDoubleClick={handleEditClickCallback}
        />
        <DeleteDialog
          isOpen={deleteDialogOpen}
          onConfirm={handleDeleteConfirm}
          onCancel={deleteHandleClose}
          disableConfirm={!itemToRemove}
          title={T.translate(LocaleKeys.messages[`delete${itemName}DialogTitle`])}
          message={T.translate(LocaleKeys.messages[`delete${itemName}DialogMessage`])}
        />
      </>
    );
  }
);

const mapStateToProps = (state) => ({
  currentSite: state.sites.currentSite,
  currentSiteId: state.sites.currentSiteId,
});

TableApi.propTypes = {
  history: PropTypes.object,
  currentSite: PropTypes.string,
  currentSiteId: PropTypes.string,
  canAddNew: PropTypes.bool,
  itemName: PropTypes.string,
  selectedSiteId: PropTypes.string,
};

TableApi.defaultProps = {
  canAddNew: false,
};

export default compose(withRouterAndRef, connect(mapStateToProps, null, null, { forwardRef: true }))(TableApi);
