import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import { FormHelperText, styled } from '@material-ui/core';

const InputLabelStyled = styled(InputLabel)({
  backgroundColor: 'white',
  paddingRight: '5px',
});

const Dropdown = ({ data, name, value, valid, ...props }) => (
  <>
    <FormControl
      className={props.className}
      variant="outlined"
      error={valid && valid !== 'true'}
      fullWidth={props.fullWidth}
      disabled={!data}
      style={props.style}
    >
      <InputLabelStyled htmlFor={`outlined-dropdown-${name}`}>{props.label}</InputLabelStyled>
      <Select
        value={!data ? '' : value || (value === 0 ? '0' : '')}
        onChange={(event) => {
          if (event.target.value !== value) {
            props.onChange(event);
          }
        }}
        input={<OutlinedInput labelWidth={0} name={name} id={`outlined-dropdown-${name}`} />}
        readOnly={props.readOnly}
      >
        {!props.withoutEmpty && (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        )}
        {data?.map((n, index) => {
          const rawLabel = props.displayField ? n[props.displayField] : n;
          const label = props.translateLabel ? T.translate(rawLabel) : rawLabel;
          const itemValue =
            props.displayField && props.valueField ? n[props.valueField] : props.displayField && n.id ? n.id : n;

          return (
            <MenuItem key={index} value={itemValue} disabled={props.itemsDisabled}>
              <em> {label} </em>
            </MenuItem>
          );
        })}
      </Select>
      {valid && valid !== 'true' && (
        <FormHelperText>{T.translate(LocaleKeys.validation[props.validationerrorlocalekeys])}</FormHelperText>
      )}
    </FormControl>
  </>
);

Dropdown.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.array,
  label: PropTypes.string,
  translateLabel: PropTypes.bool,
  displayField: PropTypes.string,
  valueField: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
  fullWidth: PropTypes.bool,
  none: PropTypes.bool,
  withoutEmpty: PropTypes.bool,
  itemsDisabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

Dropdown.defaultProps = {
  translateLabel: false,
  readOnly: false,
};

export default Dropdown;
