import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import ErrorPage from 'app/pages/ErrorPage';
import { withRouter } from 'react-router-dom';

class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  handleBackToDashboardClick = () => {
    this.props.history.push('/');
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      return <ErrorPage code={418} message="" customAction={this.handleBackToDashboardClick} />;
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
