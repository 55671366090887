import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { styled, FormLabel, Fab, Radio, Tooltip, Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { FieldArray, useFormikContext } from 'formik';
import { withFormik, useEnhancedFormContext } from 'Components/formik/formikWrappers';
import { getPhoneTypes } from 'Api/users';
import AddIcon from '@material-ui/icons/Add';
import {
  Dropdown as DropdownRaw,
  PhoneInput as PhoneInputRaw,
  TextField as TextFieldRaw,
} from 'Components/formik/fields';

const TextField = styled(TextFieldRaw)({
  width: 200,
  margin: 2,
  height: '100%',
});
const PhoneInput = styled(PhoneInputRaw)({
  maxWidth: 350,
  margin: 2,
  height: '100%',
});
const Dropdown = styled(DropdownRaw)({
  width: 130,
  margin: 2,
  height: '100%',
});
const EmailTextField = styled(TextFieldRaw)({
  width: 275,
  margin: 2,
  height: '100%',
});

const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: '10px 4%',
});
const Baseline = styled('div')({
  display: 'flex',
  alignItems: 'baseline',
  padding: 5,
  marginRight: 5,
});
const Center = styled(Baseline)({
  alignItems: 'center',
});
const FabStyled = styled(Fab)({
  minHeight: '40px',
  height: '40px',
  width: '40px',
  minWidth: '40px',
  margin: '10px',
});
const ErrorLine = styled('p')({
  color: '#f44336',
  marginLeft: 5,
  marginRight: 5,
});
const HeaderLine = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});
const Label = styled('div')({
  marginRight: '10px',
  marginTop: '10px',
});

export const CollectionHeader = ({ label, error, disableAddItem, readOnly, onAddClick }) => (
  <HeaderLine>
    <Label>{label}</Label>
    {error && !(error instanceof Array) && <ErrorLine> - {T.translate(error)}!</ErrorLine>}
    {!disableAddItem && !readOnly && (
      <Tooltip title={T.translate(LocaleKeys.tooltips.addNewItem)}>
        <FabStyled onClick={onAddClick}>
          <AddIcon />
        </FabStyled>
      </Tooltip>
    )}
  </HeaderLine>
);

const Communication = (props) => {
  const { values, errors, setFieldValue } = useFormikContext();
  const { canSaveChanges } = useEnhancedFormContext();
  const isReadOnly = props.readOnly || !canSaveChanges;
  const emailsValue = values.emails;
  const emailsError = errors.emails;
  const phonesValue = values.phones;
  const phonesError = errors.phones;
  const [phoneTypes, setPhoneTypes] = useState([{}]);

  useEffect(() => {
    getPhoneTypes().then(({ data }) => {
      setPhoneTypes(data);
    });
  }, []);

  const defaultChange = (index) => {
    emailsValue.forEach((x, i) => {
      setFieldValue(`emails.${i}.isDefault`, i === index);
    });
  };

  return (
    <Box display="flex" width="100%">
      <Column>
        <FieldArray
          name={'emails'}
          render={({ push, remove }) => (
            <>
              <CollectionHeader
                label={T.translate(LocaleKeys.labels.emails)}
                error={emailsError}
                onAddClick={() => {
                  push({
                    userId: values.id,
                    isDefault: emailsValue.length === 0,
                  });
                }}
                readOnly={isReadOnly}
              />
              {emailsValue.map((v, index) => (
                <Box key={index} display="flex">
                  <Baseline>
                    <EmailTextField
                      label={T.translate(LocaleKeys.labels.email)}
                      name={`emails.${index}.emailAddress`}
                      showPending={v.isDefault}
                    />
                    <TextField label={T.translate(LocaleKeys.labels.remarks)} name={`emails.${index}.remark`} />
                    <FormLabel component="label" onClick={() => defaultChange(index)}>
                      <Radio
                        checked={v.isDefault}
                        onChange={() => defaultChange(index)}
                        name={`emails.${index}.isDefault`}
                      />
                      {T.translate(LocaleKeys.labels.default)}
                    </FormLabel>
                  </Baseline>
                  {!isReadOnly && (
                    <FabStyled
                      onClick={() => {
                        if (v.isDefault && emailsValue.length > 1) {
                          defaultChange(index === 0 ? 1 : 0);
                        }
                        remove(index);
                      }}
                    >
                      <DeleteIcon />
                    </FabStyled>
                  )}
                </Box>
              ))}
            </>
          )}
        />
      </Column>

      <Column>
        <FieldArray
          name={'phones'}
          label={T.translate(LocaleKeys.labels.phones)}
          render={({ push, remove }) => (
            <>
              <CollectionHeader
                label={T.translate(LocaleKeys.labels.phones)}
                error={phonesError}
                onAddClick={() => {
                  push({
                    userId: values.id,
                    phoneNumber: '+49',
                    phoneType: 'Landline',
                  });
                }}
                readOnly={isReadOnly}
              />
              {phonesValue.map((v, index) => (
                <Box key={index} display="flex">
                  <Center>
                    <Dropdown
                      margin={'none'}
                      label={T.translate(LocaleKeys.labels.phoneType)}
                      name={`phones.${index}.phoneType`}
                      data={phoneTypes}
                      displayField="name"
                      valueField="name"
                      withoutEmpty
                      translateLabel
                    />
                    <PhoneInput
                      name={`phones.${index}.phoneNumber`}
                      label={T.translate(LocaleKeys.labels.phoneNumber)}
                    />
                    <TextField label={T.translate(LocaleKeys.labels.remarks)} name={`phones.${index}.remark`} />
                  </Center>
                  {!isReadOnly && (
                    <FabStyled
                      onClick={() => {
                        remove(index);
                      }}
                    >
                      <DeleteIcon />
                    </FabStyled>
                  )}
                </Box>
              ))}
            </>
          )}
        />
      </Column>
    </Box>
  );
};

Communication.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
};

export default withFormik(Communication);
