import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import LocaleKeys from 'Localization/LocaleKeys';
import { Marker } from 'react-leaflet';
import LeafletMap from 'Components/LeafletMap';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import T from 'i18n-react';
import { Box, Typography, styled } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getObjectLocation } from 'Api/stateManagement';
import SearchInput from 'Components/LeafletMap/controls/SearchInput';
import { RequestConfigContext } from '../../requestConfigContext';
import { RadioInput } from 'Components/formik/fields';

const modeValues = [
  { value: false, label: 'deviceSendsLocation' },
  { value: true, label: 'useDefaultLocation' },
];

const CustomValidationMessage = styled(Typography)({
  marign: 5,
  color: '#f44336',
});

const LocationMode = ({ data, devices, ...props }) => {
  const [date, setDate] = useState();
  const [latitude, setLatitude] = useState(51.505);
  const [longitude, setLongitude] = useState(-0.09);
  const requestConfig = useContext(RequestConfigContext);

  useEffect(() => {
    getObjectLocation([data.externalId], requestConfig).then((response) => {
      if (response.data && response.data.length) {
        const { latitude, longitude } = response.data[0].properties;

        setDeviceLocation(latitude, longitude);
      }
    });
  }, [data.externalId]);

  useEffect(() => {
    const device = devices.find((device) => device.externalId === data.externalId);

    if (device?.position) {
      setDeviceLocation(device.position.latitude, device.position.longitude);
    }
  }, [devices, data.externalId]);

  const setDeviceLocation = (latitude, longitude) => {
    const date = new Date().toLocaleString();

    setDate(date);
    setLatitude(latitude);
    setLongitude(longitude);
  };

  const onChange = (event) => {
    const { name } = event.target;
    let { value } = event.target;

    if (name === 'useDefault') {
      value = value === 'true';
    }
    props.onChange({ target: { name, value } });
  };

  const onMapClick = (e) => {
    props.onChange({
      target: {
        name: 'latitude',
        value: e.latlng.lat,
      },
    });
    props.onChange({
      target: {
        name: 'longitude',
        value: e.latlng.lng,
      },
    });
  };

  return (
    <ColumnDisplayer>
      <Box display="flex" flexDirection="column">
        <RadioInput
          label={T.translate(LocaleKeys.labels.mode)}
          name="useDefault"
          values={modeValues}
          onChange={onChange}
          translateValue
          row
        />
        {data.useDefault && (data.latitude === null || data.longitude === null) && (
          <CustomValidationMessage>
            {T.translate(LocaleKeys.validation.setDeviceDefaultLocalizationOnMap)}
          </CustomValidationMessage>
        )}
      </Box>
      <div>
        <div>
          <div>{`${T.translate(LocaleKeys.labels.lastReceivedDate)}: ${date}`}</div>
          <Box width={500} height={400}>
            {data.useDefault ? (
              <LeafletMap
                name={'area'}
                // onMarkerChange={onMarkerChange}
                onClick={onMapClick}
                center={data.latitude ? [data.latitude, data.longitude] : [latitude, longitude]}
                controls={[
                  {
                    element: SearchInput,
                    position: 'topsecondleft',
                  },
                ]}
              >
                {data.longitude && <Marker position={[data.latitude, data.longitude]} />}
              </LeafletMap>
            ) : (
              <>
                <LeafletMap
                  name={'area-search'}
                  center={[latitude, longitude]}
                  controls={[
                    {
                      element: SearchInput,
                      position: 'topsecondleft',
                    },
                  ]}
                >
                  {longitude && <Marker position={[latitude, longitude]} />}
                </LeafletMap>
              </>
            )}
          </Box>
        </div>
      </div>
    </ColumnDisplayer>
  );
};

const mapStateToProps = (state) => ({
  currentSiteId: state.sites.currentSiteId,
  devices: state.assets.devices,
});

LocationMode.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
};

export default compose(connect(mapStateToProps))(LocationMode);
