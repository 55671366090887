import React from 'react';
import { FormControlLabel, Radio, RadioGroup, FormLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import T from 'i18n-react';

const RadioInput = (props) => (
  <>
    {props.label && <FormLabel>{props.label}</FormLabel>}
    <RadioGroup
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      className={props.className}
      row={props.row}
      id={`radioInput-${props.name}`}
    >
      {props.values.map((v, index) => {
        const label = v instanceof Object ? v.label : v;
        const value = v instanceof Object ? v.value : v;
        const disabled = props.disabled || (props.readOnly && value !== props.value);

        return (
          <FormControlLabel
            key={index}
            value={value}
            control={<Radio disabled={disabled} id={`radioInput-${props.name}-${index}`} />}
            label={props.translateValue ? T.translate(label) : label}
          />
        );
      })}
    </RadioGroup>
  </>
);

RadioInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  // if value is not a object of value and label, value will be used as label
  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number])),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  // if true, label will be translated and handled like a translate key
  translateValue: PropTypes.bool,
  className: PropTypes.string,
  row: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default RadioInput;
