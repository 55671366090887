export const BROADCAST = 'broadcast';
export const CLOSE_PORT = 'close_port';
export const CLOSE_TAB = 'close_tab';
export const INIT_MASTER = 'init_master';
export const INIT_SLAVE = 'init_slave';
export const LAST_USER_ACTIVITY_CHANGED = 'last_user_activity_changed';
export const LOGOUT_CLICK = 'logout_click';
export const ONCONNECT_RESULT = 'onconnect_result';
export const PING = 'ping';
export const PONG = 'pong';
export const REFRESH_SESSION_REQUEST = 'refresh_session_request';
export const SESSION_EXPIRED = 'session_expired';
export const SESSION_EXPIRING = 'session_expiring';
export const SESSION_REFRESHED = 'session_refreshed';
export const SESSION_REFRESHING = 'session_refreshing';
export const SWITCH_TO_MASTER = 'switch_to_master';
export const UPDATE_SESSION = 'update_session';
export const TAB_CONNECTED_COUNT = 'tab_connected_count';
