import * as Sentry from '@sentry/react';
import * as identityServer from 'Api/identityServer';
import store from 'redux/store';
import * as datefns from 'date-fns';
import { getLastUserActivityTime } from 'redux/app/selectors';
import AuthManager from '../AuthManager';
import { setLastUserActivity } from 'redux/app/actions';
import * as SessionMsg from './SessionMessageTypes';
import * as SessionConfig from './SessionConfig';

const _sessionExpiringCallbacks = [];
const _sessionExpiredCallbacks = [];

let _sessionExpiringEventTimeout;
let _sessionExpiredEventTimeout;
let _currentSessionId;
let _sessionSharedWorker;
let _lastUserActivityTime = null;
let _serverTimeOffset = 0;

export default class MasterSessionManager {
  sessionDetails = null;

  constructor(sessionSharedWorker, serverTimeOffset = 0) {
    _sessionSharedWorker = sessionSharedWorker;
    _serverTimeOffset = serverTimeOffset;
    _sessionSharedWorker.port.addEventListener('message', this.sharedWorkerMessageHandler);
  }

  sharedWorkerMessageHandler = async (e) => {
    const receivedMessageType = e.data[0];

    switch (receivedMessageType) {
      case SessionMsg.REFRESH_SESSION_REQUEST:
        await this.refreshSessionNow();
        _sessionSharedWorker.port.postMessage([SessionMsg.SESSION_REFRESHED]);
        break;

      case SessionMsg.LAST_USER_ACTIVITY_CHANGED:
        const receivedLastUserActivityTime = e.data[1];

        if (_lastUserActivityTime < receivedLastUserActivityTime) {
          _lastUserActivityTime = receivedLastUserActivityTime;
          store.dispatch(setLastUserActivity(receivedLastUserActivityTime));
        }
        break;
      default:
        break;
    }
  };

  setCurrentSessionId = (sessionId) => {
    _currentSessionId = sessionId;
  };

  setServerTimeOffset = (serverTimeOffset) => {
    _serverTimeOffset = serverTimeOffset;
    if (this.sessionDetails) {
      this.runSessionExpiringObserver();
      this.runSessionExpiredObserver();
    }
  };

  updateSession = async () => {
    try {
      const result = await identityServer.getSession(_currentSessionId);

      this.sessionDetails = result.data;
      this.runSessionExpiringObserver();
      this.runSessionExpiredObserver();
    } catch (error) {
      Sentry.captureMessage('error during try get session info', {
        extra: { error },
      });
    }
  };

  refreshSession = async (sessionId, lastActiveDate) => {
    try {
      const lastActive = lastActiveDate ? new Date(+new Date(lastActiveDate) + _serverTimeOffset).toISOString() : null;

      await identityServer.postSessionRefresh(sessionId, { lastActive });
      await this.updateSession();
      _sessionSharedWorker.port.postMessage([SessionMsg.UPDATE_SESSION, this.sessionDetails]);
    } catch (error) {
      Sentry.captureMessage('error during try refresh session', {
        extra: { error },
      });
    }
  };

  refreshSessionNow = async () => {
    const user = await AuthManager.getUser();

    await this.refreshSession(user.profile.sid, null);
  };

  getTimeToSessionExpire = () => {
    const expireDateTime = datefns.parseJSON(this.sessionDetails.expireDateTime);
    const timeToSessionExpire = expireDateTime - (Date.now() + _serverTimeOffset);

    return timeToSessionExpire - SessionConfig.EXPIRED_TIME_OFFSET;
  };

  runSessionExpiringObserver() {
    const timeToSessionExpire = this.getTimeToSessionExpire();
    const callbackTimeout = timeToSessionExpire - SessionConfig.EXPIRING_NOTIFICATION_TIME;

    clearTimeout(_sessionExpiringEventTimeout);
    _sessionExpiringEventTimeout = setTimeout(this.sessionExpiring, callbackTimeout);
  }

  sessionExpiring = async () => {
    const user = await AuthManager.getUser();
    const lastUserActivityTime = getLastUserActivityTime(store.getState());

    if (lastUserActivityTime) {
      await this.refreshSession(user.profile.sid, lastUserActivityTime);
    }

    const timeToSessionExpire = this.getTimeToSessionExpire();

    const shouldPublishExpiringEvent = timeToSessionExpire < SessionConfig.EXPIRING_NOTIFICATION_TIME;

    if (shouldPublishExpiringEvent) {
      clearTimeout(_sessionExpiringEventTimeout);
      this.publishSessionExpiringCallbacks();
    }
  };

  publishSessionExpiringCallbacks = () => {
    _sessionSharedWorker.port.postMessage([SessionMsg.SESSION_EXPIRING]);
    _sessionExpiringCallbacks.forEach((callback) => {
      callback();
    });
  };

  runSessionExpiredObserver() {
    const timeToSessionExpired = this.getTimeToSessionExpire();

    clearTimeout(_sessionExpiredEventTimeout);
    _sessionExpiredEventTimeout = setTimeout(
      this.sessionExpired,
      timeToSessionExpired + SessionConfig.EXPIRED_EVENT_OFFSET
    );

    window._sessionExpiredEventTimeout = _sessionExpiredEventTimeout;
  }

  sessionExpired = () => {
    this.publishSessionExpiredCallbacks();
  };

  publishSessionExpiredCallbacks = () => {
    _sessionExpiredCallbacks.forEach((callback) => {
      callback();
    });
    _sessionSharedWorker.port.postMessage([SessionMsg.SESSION_EXPIRED]);
    AuthManager.logout();
  };

  events = {
    addSessionExpiring(callback) {
      _sessionExpiringCallbacks.push(callback);
    },
    removeSessionExpiring(callback) {
      _sessionExpiringCallbacks.filter((c) => c !== callback);
    },

    addSessionExpired(callback) {
      _sessionExpiredCallbacks.push(callback);
    },
    removeSessionExpired(callback) {
      _sessionExpiredCallbacks.filter((c) => c !== callback);
    },
  };
}
