import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Lens } from '@material-ui/icons';
import { styled } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core';
import Button from 'Components/Button';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import FileUploadWithSizeValidation from 'Components/FileUploadWithSizeValidation';
import { getResource } from 'Api/devices';

const Loading = () => (
  <Box align="center" p={4}>
    <CircularProgress />
  </Box>
);

const LensColored = styled(({ color, ...props }) => <Lens {...props} />)({
  fill: ({ color }) => color,
  height: '30px',
  width: '30px',
  marginBottom: '20px',
});

const StatusLabel = styled('div')({
  color: '#7f7f7f',
  marginLeft: '5px',
  marginBottom: '20px',
});

const ImagePlaceholder = styled('div')({
  width: '400px',
  lineHeight: '300px',
  height: '299px',
  backgroundColor: '#f2f2f2',
  color: '#aaaaaa',
  textAlign: 'center',
  marginLeft: '35px',
  verticalAlign: 'middle',
  marginTop: '10px',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '32px',
});

const AlarmImageStyled = styled(({ image, ...props }) => <div {...props} />)({
  width: 400,
  height: 300,
  marginLeft: '35px',
  verticalAlign: 'middle',
  marginTop: '10px',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '32px',
  background: ({ image }) => `url(${image}) no-repeat center/contain;`,
});

const AlarmImage = ({ imageId, image }) => {
  const [loading, setLoading] = useState(false);
  const [resource, setResource] = useState(image);

  useEffect(() => {
    if (!resource && imageId) {
      setLoading(true);
      getResource(imageId).then((response) => {
        if (response?.data) {
          setResource(`data:${response.data.mimeType};base64,${response.data.value}`);
        }

        setLoading(false);
      });
    }
  }, [resource, imageId]);

  if (loading) {
    return <Loading />;
  }

  return <AlarmImageStyled image={resource} />;
};

const StyledBox = styled(Box)({
  float: 'right',
  padding: '32px',
});

const IMAGE_MAX_SIZE = 1024 * 1024;

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const AlarmStatusImage = ({ id, image, color, label, onChange, maxSize, name, imageId, readOnly }) => {
  return (
    <>
      <Box display="flex" minHeight="0" alignItems="center" paddingRight={10}>
        <LensColored color={color} />
        <StatusLabel>{label}</StatusLabel>
      </Box>
      {!!image || imageId ? (
        <AlarmImage imageId={imageId} image={image} />
      ) : (
        <ImagePlaceholder>{T.translate(LocaleKeys.labels.loadYourImage)}</ImagePlaceholder>
      )}
      <StyledBox>
        {image || imageId ? (
          <Button
            disabled={readOnly}
            onClick={() => {
              onChange({
                target: {
                  name: name,
                  value: null,
                },
              });
              onChange({
                target: {
                  name: `${name}Id`,
                  value: 0,
                },
              });
            }}
          >
            {T.translate(LocaleKeys.labels.removeImage)}
          </Button>
        ) : (
          <FileUploadWithSizeValidation
            id={id}
            label={T.translate(LocaleKeys.labels.uploadImage)}
            maxSize={maxSize}
            accept="image/png, image/jpg, image/gif, image/jpeg"
            onChange={(file) => {
              if (file) {
                toBase64(file).then((image) => {
                  onChange({
                    target: {
                      name: name,
                      value: {
                        name: file.name,
                        value: image,
                        type: 1,
                        mimeType: file.type,
                      },
                    },
                  });
                });

                onChange({
                  target: { name: `${name}Id`, value: 0 },
                });
              }
            }}
            readOnly={readOnly}
          />
        )}
      </StyledBox>
    </>
  );
};

AlarmStatusImage.propTypes = {
  id: PropTypes.string,
  image: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  maxSize: PropTypes.number,
  name: PropTypes.string,
};

AlarmStatusImage.defaultProps = {
  maxSize: IMAGE_MAX_SIZE,
};

export default AlarmStatusImage;
