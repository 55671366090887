import React from 'react';
import T from 'i18n-react';
import { Box, styled } from '@material-ui/core';
import LocaleKeys from 'Localization/LocaleKeys';
import { withField } from 'Components/formik/formikWrappers';
import FormikCollection from 'Components/inputs/CollectionManager/FormikCollection';
import { TextField } from 'Components/formik/fields';

const Collection = withField(FormikCollection);

const TextInputStyled = styled(TextField)({
  margin: 5,
});

const Accreditation = () => (
  <Box margin={'10px 4% !important'}>
    <Collection label={T.translate(LocaleKeys.labels.RFIDCard)} name={'cards'} defaultButton={true}>
      <TextInputStyled name={'code'} label={T.translate(LocaleKeys.labels.id)} />
      <TextInputStyled name={'name'} label={T.translate(LocaleKeys.labels.name)} />
      <TextInputStyled name={'remark'} label={T.translate(LocaleKeys.labels.remarks)} />
    </Collection>
  </Box>
);

export default Accreditation;
