import React from 'react';
import { styled } from '@material-ui/core/styles';
import Icon from 'app/pages/Dashboard/Icon';
import PropTypes from 'prop-types';
import Config from 'Config';

const IconImage = styled(({ size, alt, ...props }) => <img alt={alt} {...props} />)({
  width: (props) => (props.size === 'large' ? 80 : 40),
  height: (props) => (props.size === 'large' ? 80 : 40),
  background: ({ image }) => `url(${image}) no-repeat center/cover`,
  borderRadius: '50%',
});

export const DeviceIcon = ({ iconId, icon, color }) => {
  return iconId ? (
    <IconImage src={`${Config.apiDefaultURL}/devicestore/api/resources/${iconId}/image`} />
  ) : icon ? (
    <IconImage image={icon.value} />
  ) : (
    <Icon color={color} />
  );
};

DeviceIcon.propTypes = {
  iconId: PropTypes.number,
  icon: PropTypes.object,
  color: PropTypes.string,
};

export default DeviceIcon;
