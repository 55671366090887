import React, { useMemo } from 'react';
import { ApiHeaders } from 'Api/ApiClient';

export const RequestConfigContext = React.createContext({});

export const withRequestConfigProvider = (Component) => {
  const ComponentWithRequestConfig = (props, ref) => {
    const requestConfig = useMemo(() => {
      return {
        headers: { [ApiHeaders.X_TENANT_ID]: props.location.state?.redirectSelectedSiteId },
      };
    }, []);

    return (
      <RequestConfigContext.Provider value={requestConfig}>
        <Component {...props} ref={ref} />
      </RequestConfigContext.Provider>
    );
  };

  return React.forwardRef(ComponentWithRequestConfig);
};
