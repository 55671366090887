import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import Button from 'Components/Button';
import Definitions from './tabs/Definitions';
import { AssetAssignmentNew as AssetAssignment } from './tabs/AssetAssignment';
import { FormulasNew as Formulas } from './tabs/Formulas';
import DashboardConfiguration from './tabs/DashboardConfiguration';
import Validity from './tabs/Validity';
import NotificationSettings from './tabs/NotificationSettings';
import Handling from './tabs/Handling';
import AutomaticCommands from './tabs/AutomaticCommands';
import { styled } from '@material-ui/core/styles';
import { compose } from 'redux';
import { StatusAndAlarmDefinitions } from 'routes/Routes';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import Tabs from 'Components/display/tabs/Tabs';
import { Formik } from 'formik';
import { CircularProgress } from '@material-ui/core';
import validationSchema from './validationSchema';
import { AlarmTypes } from 'Constants/AlarmTypes';
import { AlarmStatuses } from 'Constants/AlarmStatuses';
import { AlarmValidityTypes } from 'Constants/AlarmValidityTypes';
import { AlarmOccurrenceTypes } from 'Constants/AlarmOccurrenceTypes';
import {
  addResource,
  prepareAlarmData,
  mapInputsMappings,
  getLibraryInputMappings,
  replaceAlarmLibrary,
  mapUsedInputs,
  getDefaultWebhooks,
} from './helpers';
import {
  postAlarm,
  getDevicesPerLibrary,
  getTimeRelatedMappings,
  deleteLibraryForAlarm,
  postDevicesForMappings,
  postLibraryForAlarm,
} from 'Api/devices';
import { getMapAreasSystemMappings } from 'Api/maps';
import SaveOnLeave from 'Components/dialogs/SaveOnLeave';

const ButtonStyled = styled(Button)({
  float: 'right',
  marginLeft: '10px',
});

const assetsMapper = (device) => ({
  id: device.id,
  externalId: device.externalId,
  name: device.name,
  disabled: !device.isAvailable,
});

const areArraysUqual = (arr1, arr2) => arr1.sort().toString() === arr2.sort().toString();
const deleteInternalLibrary = (alarmLibrary) => {
  if (alarmLibrary?.isInternal) {
    deleteLibraryForAlarm(alarmLibrary.id);
  }
};

const New = (props) => {
  const [alarm, setAlarm] = useState({
    alarmType: AlarmTypes.ALARM,
    status: AlarmStatuses.ACTIVE,
    validityType: AlarmValidityTypes.ALWAYS,
    occurrenceType: AlarmOccurrenceTypes.CONTINUOUS,
    validFrom: new Date().toISOString(),
    // one day in the future to give initial validation
    validTo: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(),
    autoReadInMinutes: '01h 00min',
    assignedAssets: [],
    assignedUsers: [],
    startFormulas: [],
    endFormulas: [],
    emails: [],
    commands: [],
    webhooks: getDefaultWebhooks(),
  });
  const [assets, setAssets] = useState();
  const [isLoadingAssets, setIsLoadingAssets] = useState(true);
  const [alarmLibrary, setAlarmLibrary] = useState(null);
  const [isLoadingAlarmLibrary, setIsLoadingAlarmLibrary] = useState(null);
  const [systemInputs, setSystemInputs] = useState();
  const [libraryAssets, setLibraryAssets] = useState([]);
  const validationCache = useRef({
    name: null,
  });

  useEffect(() => {
    Promise.all([
      getMapAreasSystemMappings(),
      getTimeRelatedMappings(),
      getDevicesPerLibrary({ deviceLibraryId: null }),
    ]).then(([mapAreasMappingsResponse, timeRelatedMappingsResponse, getDevicesPerLibraryResponse]) => {
      const mapAreasInputs = mapInputsMappings(mapAreasMappingsResponse.data);
      const timeRelatedInputs = mapInputsMappings(timeRelatedMappingsResponse.data);
      const assets = getDevicesPerLibraryResponse.data.map(assetsMapper);

      setSystemInputs([...mapAreasInputs, ...timeRelatedInputs]);
      setAssets(assets);
      setIsLoadingAssets(false);
    });
  }, []);

  const fetchAssetsCallback = useCallback(
    (alarm) => {
      const { deviceLibraryId } = alarm;
      const mappings = mapUsedInputs(alarmLibrary, alarm);

      if (!deviceLibraryId) {
        setIsLoadingAssets(true);
        getDevicesPerLibrary({ deviceLibraryId }).then((response) => {
          const assets = response.data.map(assetsMapper);

          setAssets(assets);
          setIsLoadingAssets(false);
        });
      } else if (mappings) {
        setIsLoadingAssets(true);
        postDevicesForMappings(mappings).then((response) => {
          const assets = response.data.map(assetsMapper);

          setAssets(assets);
          setIsLoadingAssets(false);
        });
      }
    },
    [alarmLibrary]
  );

  const fetchLibraryForAlarmCallback = useCallback(
    (alarm, alarmLibrary, setFieldValue) => {
      if (areArraysUqual(alarm.assignedAssets, libraryAssets)) {
        return;
      } else if (alarm.assignedAssets.length === 0) {
        setFieldValue('deviceLibraryId', null, false);
        setAlarmLibrary(null);
        setLibraryAssets(alarm.assignedAssets);

        return;
      }

      setIsLoadingAlarmLibrary(true);
      setLibraryAssets(alarm.assignedAssets);
      postLibraryForAlarm({ deviceExternalIds: alarm.assignedAssets, deviceLibraryId: alarmLibrary?.id }).then(
        ({ data: newLibrary }) => {
          if (!!newLibrary) {
            const newAlarm = replaceAlarmLibrary(alarmLibrary, newLibrary, alarm);

            setAlarmLibrary(newLibrary);
            setAlarm(newAlarm);
            setIsLoadingAlarmLibrary(false);
          } else {
            setFieldValue('deviceLibraryId', null, false);
            setAlarmLibrary(null);
            setIsLoadingAlarmLibrary(false);
          }
        }
      );
    },
    [libraryAssets]
  );

  const redirect = () => {
    const url = StatusAndAlarmDefinitions.getUrl();

    props.history.push(url);
  };

  const onCancel = () => {
    redirect();
  };

  const saveData = async (values) => {
    if (values.imageOnId === 0 && values.imageOn) {
      await addResource(values, 'imageOn', 'imageOnId');
    }

    if (values.imageOffId === 0 && values.imageOff) {
      await addResource(values, 'imageOff', 'imageOffId');
    }

    let alarm = prepareAlarmData(values);

    if (!areArraysUqual(values.assignedAssets, libraryAssets)) {
      const { data: newLibrary } = await postLibraryForAlarm({
        deviceExternalIds: alarm.assignedAssets,
        deviceLibraryId: alarmLibrary?.id,
      });

      alarm = replaceAlarmLibrary(alarmLibrary, newLibrary, alarm);
    }

    return postAlarm(alarm);
  };

  const onSubmit = (values, { setSubmitting }) => {
    saveData(values).finally(() => {
      setSubmitting(false);
      redirect();
    });
  };

  if (!assets || !systemInputs) {
    return <CircularProgress />;
  }

  const devicesInputs = getLibraryInputMappings(alarmLibrary);
  const inputMappings = systemInputs.concat(devicesInputs);

  return (
    <Formik
      initialValues={alarm}
      enableReinitialize
      validationSchema={validationSchema(alarm, validationCache.current)}
      validateOnMount
      onSubmit={onSubmit}
    >
      {({ values, isValid, isSubmitting, handleSubmit }) => {
        // dirty always true on new form, because of form reinitialization when mappings change on tabs switch
        const dirty = true;

        return (
          <form onSubmit={handleSubmit}>
            <Tabs label="AlarmDetails" defaultTab={0}>
              <Definitions label={LocaleKeys.labels.definition} />
              <AssetAssignment
                label={LocaleKeys.labels.assetAssignment}
                assets={assets}
                inputMappings={inputMappings}
                systemInputs={systemInputs}
                fetchAssets={fetchAssetsCallback}
                isLoadingAssets={isLoadingAssets}
              />
              <Formulas
                label={LocaleKeys.labels.formula}
                alarmLibrary={alarmLibrary}
                inputMappings={inputMappings}
                fetchLibraryForAlarm={fetchLibraryForAlarmCallback}
                isLoadingAlarmLibrary={isLoadingAlarmLibrary}
              />
              <DashboardConfiguration label={LocaleKeys.labels.dashboardConfiguration} />
              <Validity label={LocaleKeys.labels.validity} />
              <NotificationSettings label={LocaleKeys.labels.notificationSettings} />
              <Handling label={LocaleKeys.labels.handling} />
              <AutomaticCommands label={LocaleKeys.labels.automaticCommands} />
            </Tabs>

            <ButtonStyled onClick={onCancel} id="new-alarmDefinitions-cancelButton">
              {T.translate(LocaleKeys.labels.cancel)}
            </ButtonStyled>
            <ButtonStyled
              disabled={!(dirty && isValid) || isSubmitting}
              type="submit"
              id="new-alarmDefinitions-submitButton"
              showProgress={isSubmitting}
            >
              {T.translate(LocaleKeys.labels.save)}
            </ButtonStyled>

            <SaveOnLeave
              saveData={() => saveData(values)}
              withoutSaving={() => deleteInternalLibrary(alarmLibrary)}
              dataChanged={dirty}
              validForm={isValid}
            />
          </form>
        );
      }}
    </Formik>
  );
};

New.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default compose(
  withPermissions([
    Permissions.CanReadAlarms,
    Permissions.CanAddAlarm,
    Permissions.CanReadDevices,
    Permissions.CanReadCalendarTemplates,
    Permissions.CanReadMapAreas,
    Permissions.CanReadUsers,
    Permissions.CanAddLibraries,
    Permissions.CanReadLibraries,
  ])
)(New);
