import ApiClient from './ApiClient';

const apiClient = new ApiClient();
const version = 'v1';
const SERVICE_PATH = `/statemanagement/api/${version}/`;

const get = (path, params, config = {}) => apiClient.get(SERVICE_PATH + path, { params, ...config });

// Objects
export const getObjectLocation = (ids, config = {}) => get(`Objects/objectslocation/${ids}`, null, config);

// Archive
export const getArchive = (params) => get('Archive', params);
export const getArchiveReport = (params) => get('Archive/Reports', params);
export const getGeoPoints = (params) => get(`Archive/GeoPoints`, params);
