import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { withFormik } from 'Components/formik/formikWrappers';
import { styled, Box } from '@material-ui/core';
import { InputMappingTypes } from 'Constants/InputMappingTypes';
import { useField } from 'formik';
import { VariableTypes } from 'Constants/VariableTypes';
import { Masks } from 'Components/inputs/MaskedTextInput';
import DropdownWithFilter from 'Components/inputs/DropdownWithFilter';
import { operators } from '../../consts';
import { DateTimeInput, Dropdown, MaskedTextInput, TextField } from 'Components/formik/fields';

const inputStyles = {
  margin: '0 5px',
  height: '100%',
  minWidth: 200,
  maxWidth: 225,
};

const DropdownField = styled(Dropdown)(inputStyles);
const TextInputField = styled(TextField)(inputStyles);
const MaskedTextInputField = styled(MaskedTextInput)(inputStyles);
const DateTimeInputField = styled(DateTimeInput)(inputStyles);
const DropdownWithFilterStyled = styled(DropdownWithFilter)(inputStyles);
const InputMappingNameField = ({ name, data, onChange, ...props }) => {
  const fieldName = `${name}.inputMappingExternalId`;
  const [field, meta] = useField(fieldName);
  const [, , operatorTypeHelpers] = useField(`${name}.operatorType`);

  return (
    <DropdownWithFilterStyled
      {...props}
      name={fieldName}
      data={data.map((item) => ({
        label: item.name,
        value: item.externalId,
        variableType: item.variableType,
      }))}
      onChange={(e) => {
        const { name, value } = e.target;
        const inputExternalId = value?.value;
        const selectedInput = data.find((input) => input.externalId === inputExternalId);

        operatorTypeHelpers.setValue(selectedInput?.variableType);
        onChange({ target: { name, value: inputExternalId } });
      }}
      value={{
        label: data.find((input) => input.externalId === field.value)?.name,
        value: field.value,
      }}
      validationerrorlocalekeys={meta.error}
      valid={!meta.error}
      fullWidth
    />
  );
};

const ConditionStyled = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: 5,
  marginRight: 5,
});

export const initialConditionClear = (fieldName, name, setFieldValue) => {
  switch (fieldName) {
    case `${name}.inputMappingType`:
      setFieldValue(`${name}.systemMappingType`, null);
      setFieldValue(`${name}.operatorType`, null);
    // fallsthrough

    case `${name}.systemMappingType`:
      setFieldValue(`${name}.inputMappingExternalId`, null);
    // fallsthrough

    case `${name}.inputMappingExternalId`:
      setFieldValue(`${name}.operatorId`, null);
    // fallsthrough

    case `${name}.operatorId`:
      setFieldValue(`${name}.targetValues`, [null, null]);
      break;

    default:
      break;
  }
};

const TargetValuesArrayField = ({ name, operatorType, operatorId, dictionaries }) => {
  const getFirstInputlabel = (isRange) =>
    isRange ? T.translate(LocaleKeys.labels.from) : T.translate(LocaleKeys.labels.value);

  switch (operatorType) {
    case VariableTypes.TIME:
      const isTimeRange = [operators.METRIC_BETWEEN, operators.METRIC_OUTSIDE_OF].includes(operatorId);

      return (
        <>
          <DateTimeInputField name={`${name}.targetValues.0`} type="time" label={getFirstInputlabel(isTimeRange)} />
          {isTimeRange && (
            <DateTimeInputField name={`${name}.targetValues.1`} type="time" label={T.translate(LocaleKeys.labels.to)} />
          )}
        </>
      );

    case VariableTypes.METRIC:
      const isMetricRange = [operators.METRIC_BETWEEN, operators.METRIC_OUTSIDE_OF].includes(operatorId);

      return (
        <>
          <TextInputField name={`${name}.targetValues.0`} type="number" label={getFirstInputlabel(isMetricRange)} />
          {isMetricRange && (
            <TextInputField name={`${name}.targetValues.1`} type="number" label={T.translate(LocaleKeys.labels.to)} />
          )}
        </>
      );

    case VariableTypes.DAYS:
      const isDayRange = [operators.DAY_BETWEEN, operators.DAY_OUTSIDE_OF].includes(operatorId);

      return (
        <>
          <DropdownField
            name={`${name}.targetValues.0`}
            data={dictionaries.daysOfWeek}
            displayField="name"
            valueField="value"
            withoutEmpty
            label={getFirstInputlabel(isDayRange)}
          />
          {isDayRange && (
            <DropdownField
              name={`${name}.targetValues.1`}
              data={dictionaries.daysOfWeek}
              displayField="name"
              valueField="value"
              withoutEmpty
              label={T.translate(LocaleKeys.labels.to)}
            />
          )}
        </>
      );

    case VariableTypes.TIME_PERIOD:
      const isTimePeriodRange = operatorId === operators.TIME_PERIOD_BETWEEN;

      return (
        <>
          <MaskedTextInputField
            name={`${name}.targetValues.0`}
            maskConfig={Masks.HoursMinutesSeconds}
            label={getFirstInputlabel(isTimePeriodRange)}
          />
          {operatorId === operators.TIME_PERIOD_BETWEEN && (
            <MaskedTextInputField
              name={`${name}.targetValues.1`}
              maskConfig={Masks.HoursMinutesSeconds}
              label={T.translate(LocaleKeys.labels.to)}
            />
          )}
        </>
      );

    case VariableTypes.DIGITAL:
      return (
        <DropdownField
          name={`${name}.targetValues.0`}
          data={dictionaries.digitalMappingStates}
          displayField="name"
          valueField="value"
          withoutEmpty
          label={T.translate(LocaleKeys.labels.value)}
        />
      );

    default:
      return null;
  }
};

const Condition = ({ name, dictionaries, inputMappings, ...props }) => {
  const [field] = useField(name);
  const condition = field.value;
  const availableMappingTypes = [...new Set(inputMappings.map((input) => input.inputMappingType))];
  const inputMappingTypes = dictionaries.inputMappingTypes.filter((type) => availableMappingTypes.includes(type.value));
  const selectedInput = inputMappings.find((input) => input.externalId === condition.inputMappingExternalId) || {};
  const operatorType = dictionaries.variableTypes.find((s) => parseInt(s.value) === selectedInput.variableType);
  const operatorIdData = operatorType ? dictionaries[operatorType.name] : [];

  const onChange = (e) => {
    props.onChange(e);
    initialConditionClear(e.target.name, name, props.setFieldValue);
  };

  return (
    <ConditionStyled>
      <DropdownField
        name={`${name}.inputMappingType`}
        label={T.translate(LocaleKeys.labels.inputMappingType)}
        onChange={onChange}
        withoutEmpty
        data={inputMappingTypes}
        displayField="name"
        valueField="value"
        translateLabel
      />
      {condition.inputMappingType === InputMappingTypes.SYSTEM && (
        <DropdownField
          name={`${name}.systemMappingType`}
          label={T.translate(LocaleKeys.labels.systemMappingType)}
          onChange={onChange}
          withoutEmpty
          data={dictionaries?.systemMappingTypes}
          displayField="name"
          valueField="value"
          translateLabel
        />
      )}
      <InputMappingNameField
        name={name}
        label={T.translate(LocaleKeys.labels.inputName)}
        onChange={onChange}
        withoutEmpty
        data={inputMappings.filter((el) => el.inputMappingType === condition.inputMappingType)}
        displayField="name"
        valueField="externalId"
        translateLabel
      />
      <DropdownField
        name={`${name}.operatorId`}
        onChange={onChange}
        label={T.translate(LocaleKeys.labels.operator)}
        withoutEmpty
        data={operatorIdData}
        displayField="name"
        valueField="value"
        translateLabel
      />
      <TargetValuesArrayField
        name={name}
        operatorType={condition.operatorType}
        operatorId={condition.operatorId}
        dictionaries={dictionaries}
      />
    </ConditionStyled>
  );
};

Condition.propTypes = {
  name: PropTypes.string,
  dictionaries: PropTypes.object,
  inputMappings: PropTypes.array,
};

export default withFormik(Condition);
