import React from 'react';
import PropTypes from 'prop-types';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import CopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LocaleKeys from 'Localization/LocaleKeys';
import { deleteHoliday, getHolidays, getCalendarsByHolidayId } from 'Api/sites';
import T from 'i18n-react';
import Table from 'Components/display/table/Table';
import { withRouter } from 'react-router-dom';
import { HolidayDetails, HolidayNew } from 'routes/Routes';
import TableActionButton from 'Components/display/table/TableActionButton';
import Loader from 'Components/Loader';
import { compose } from 'redux';
import DeleteDialog from 'Components/dialogs/DeleteDialog';
import { Typography, List, ListItem, ListItemAvatar, ListItemText, styled, Box } from '@material-ui/core';
import { Warning as WarningIcon, Clear } from '@material-ui/icons';

const Warning = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: 10,
  position: 'sticky',
  top: 0,
  backgroundColor: 'white',
  zIndex: 10,
});

const Content = ({ calendars }) => {
  if (calendars.length > 0) {
    return (
      <>
        <Warning>
          <WarningIcon color="error" style={{ fontSize: 30, marginRight: 10 }} />
          <Typography>{T.translate(LocaleKeys.messages.belowCalendarsWillBeAffectedByThisChange)}</Typography>
        </Warning>
        <CalendarsList calendars={calendars} />
      </>
    );
  }

  return T.translate(LocaleKeys.messages.deleteHolidayDialogMessage);
};

const CalendarsList = ({ calendars }) => (
  <List>
    {calendars.map((calendar) => (
      <CalendarListItem {...calendar} key={calendar.id} />
    ))}
  </List>
);

const CalendarListItem = ({ name }) => (
  <ListItem alignItems="center">
    <ListItemAvatar>
      <Clear color="error" style={{ fontSize: 30 }} />
    </ListItemAvatar>
    <ListItemText primary={name} secondary={T.translate(LocaleKeys.labels.unassignHolidayTemplateFromCalendar)} />
  </ListItem>
);

export class HolidaysTemplates extends React.Component {
  state = {
    data: [],
    isLoaded: false,
    deleteHolidayDialogOpen: false,
    holidayTemplateToDeleteId: null,
    affectedCalendars: [],
  };

  componentDidMount = () => {
    this.setData();
  };

  setData = () => {
    this.setState((prevState) => ({ ...prevState, isLoaded: false }));
    getHolidays().then(({ data }) => {
      this.setState({ data, isLoaded: true });
    });
  };

  handleCopyClick = (name) => {
    const url = HolidayNew.getUrl();
    const baseOnHolidayTemplate = name.replace(/\s/g, '-');

    this.props.history.push(url, { baseOnHolidayTemplate });
  };

  handleDeleteHolidayConfirm = () => {
    return deleteHoliday(this.state.holidayTemplateToDeleteId)
      .then(() => {
        this.setState({
          deleteHolidayDialogOpen: false,
          holidayTemplateToDeleteId: null,
        });
      })
      .finally(() => {
        this.setData();
      });
  };

  handleDeleteHolidayCancel = () => {
    this.setState({
      deleteHolidayDialogOpen: false,
      holidayTemplateToDeleteId: null,
    });
  };

  handleDeleteClick = (id) => {
    getCalendarsByHolidayId(id).then((response) => {
      this.setState({
        deleteHolidayDialogOpen: true,
        holidayTemplateToDeleteId: id,
        affectedCalendars: response.data,
      });
    });
  };

  onEditClick = (name) => {
    const url = HolidayDetails.getUrl({
      name: name.replace(/\s/g, '-'),
    });

    this.props.history.push(url);
  };

  onAddClick = () => {
    const url = HolidayNew.getUrl();

    this.props.history.push(url);
  };

  getTableActionButtons = () => {
    const actionButtons = [];

    if (this.props.permissions[Permissions.CanEditHolidaysTemplates]) {
      const editItemButton = (
        <TableActionButton
          key="editItemButton"
          onClick={this.onEditClick}
          icon={<EditIcon />}
          tooltipText={T.translate(LocaleKeys.tooltips.editItem)}
          clickparam={'name'}
        />
      );

      actionButtons.push(editItemButton);
    }
    if (this.props.permissions[Permissions.CanCopyHolidaysTemplate]) {
      const copyItemButton = (
        <TableActionButton
          key="copyButton"
          onClick={(id, row) => this.handleCopyClick(row.name)}
          clickparam={'holidayId'}
          icon={<CopyIcon />}
          tooltipText={T.translate(LocaleKeys.tooltips.copyHolidayTemplate)}
        />
      );

      actionButtons.push(copyItemButton);
    }
    if (this.props.permissions[Permissions.CanRemoveHolidaysTemplates]) {
      const deleteItemButton = (
        <TableActionButton
          key="deleteItemButton"
          onClick={this.handleDeleteClick}
          clickparam={'holidayId'}
          icon={<DeleteIcon />}
          tooltipText={T.translate(LocaleKeys.tooltips.deleteItem)}
        />
      );

      actionButtons.push(deleteItemButton);
    }

    return actionButtons;
  };

  render() {
    if (!this.state.isLoaded) {
      return <Loader />;
    }

    return (
      <>
        <DeleteDialog
          isOpen={this.state.deleteHolidayDialogOpen}
          onConfirm={this.handleDeleteHolidayConfirm}
          onCancel={this.handleDeleteHolidayCancel}
          disableConfirm={!this.state.holidayTemplateToDeleteId}
          title={T.translate(LocaleKeys.messages.deleteHolidayDialogTitle)}
          content={<Content calendars={this.state.affectedCalendars} />}
        />
        <Table
          data={this.state.data}
          onAddClick={this.props.permissions[Permissions.CanAddHolidaysTemplates] && this.onAddClick}
          onRowDoubleClick={(id, row) => this.onEditClick(row.name)}
          redirect={'holidayId'}
          hideColumns={['holidayId', 'isLockedForEdit', 'changeKey', 'tenantId', 'entries', 'lockedByUser']}
          actionButtons={this.getTableActionButtons()}
          tableName="holidaysTemplates"
        />
      </>
    );
  }
}

HolidaysTemplates.propTypes = {
  history: PropTypes.object,
  permissions: PropTypes.object,
};

export default compose(
  withRouter,
  withPermissions([Permissions.CanAccessHolidaysTemplatesPage, Permissions.CanReadHolidaysTemplates])
)(HolidaysTemplates);
