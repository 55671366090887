import * as Yup from 'yup';
import { getButtonLibraryExistsByName } from 'Api/devices';
import { isAlphanumericOrSpecialSymbol, linkRegExp } from 'Validators/SharedValidators';

const TYPE_MULTI = 2;

let lastValue;

export const buttonaLibraryNameExist = async (value, initialValue) => {
  if (isAlphanumericOrSpecialSymbol([' ', '_', ':'])(value)) {
    const result = lastValue?.value === value ? lastValue?.result : await getButtonLibraryExistsByName(value);

    lastValue = { value, result };

    return initialValue === value || !result?.data;
  }

  return false;
};

export const categoryNameSchema = Yup.string()
  .required('thisFieldIsRequired')
  .max(50, 'nameCannotExceed50Characters')
  .alphanumericOrSpecialSymbol([' ']);

export const buttonDetailsSchema = Yup.object().shape({
  onCommand: Yup.string()
    .required('thisFieldIsRequired')
    .max(200, 'nameCannotExceed200Characters')
    .when('buttonCommandType', {
      is: 2,
      then: Yup.string().matches(linkRegExp, 'urlMustBeInserted'),
    }),
  onCommandText: Yup.string()
    .required('thisFieldIsRequired')
    .max(40, 'nameCannotExceed40Characters')
    .alphanumericOrSpecialSymbol([' ']),
  offCommand: Yup.mixed().when('type', {
    is: TYPE_MULTI,
    then: Yup.string().required('thisFieldIsRequired').max(200, 'nameCannotExceed200Characters'),
    otherwise: Yup.string().nullable().optional().max(200, 'nameCannotExceed200Characters'),
  }),
  offCommandText: Yup.mixed().when('type', {
    is: TYPE_MULTI,
    then: Yup.string()
      .required('thisFieldIsRequired')
      .max(40, 'nameCannotExceed40Characters')
      .alphanumericOrSpecialSymbol([' ']),
    otherwise: Yup.string()
      .nullable()
      .optional()
      .max(40, 'nameCannotExceed40Characters')
      .alphanumericOrSpecialSymbol([' ']),
  }),
  clickReasons: Yup.array().of(
    Yup.object().shape({
      reason: Yup.string().required('thisFieldIsRequired'),
    })
  ),
});

export const validationSchema = (initialValues) =>
  Yup.object().shape({
    name: Yup.string()
      .required('thisFieldIsRequired')
      .max(50, 'nameCannotExceed50Characters')
      .alphanumericOrSpecialSymbol([' ', '_', ':'])
      .test('buttonaLibraryNameExist', 'libraryWithThisNameAlreadyExists', function (value) {
        return buttonaLibraryNameExist(value, initialValues?.name);
      }),
    categories: Yup.array().of(
      Yup.object()
        .shape({
          name: categoryNameSchema,
          buttons: Yup.array().of(buttonDetailsSchema),
        })
        .uniqueInCollection('valueMustBeUniqueInCollection', 'name')
    ),
  });
