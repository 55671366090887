import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import Table from 'Components/display/table/Table';
import TableActionButton from 'Components/display/table/TableActionButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { getIpWhiteListByTenantId, postIpWhiteAddress, deleteIpWhiteList, putIpWhiteAddress } from 'Api/policyServer';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import IpWhiteListItemDialog from './IpWhiteListItemDialog';
import Loader from 'Components/Loader';
import GenericDialog from 'Components/dialogs/GenericDialog';
import { connect } from 'react-redux';
import Message from 'Components/Message';
import { IpWhiteListItemTypes } from 'Constants/IpWhiteListItemTypes';
import { isDate } from 'Helpers/DateTimeHelpers';

const formatDataForTableView = (ipWhiteList) =>
  Object.values(ipWhiteList).map((ipItem) => ({
    ipAddress:
      ipItem.type === IpWhiteListItemTypes.SINGLE
        ? ipItem.ipAddress
        : `${ipItem.startRangeAddress} - ${ipItem.endRangeAddress}`,
    type: T.translate(ipItem.type),
    enabled: ipItem.enabled,
    remarks: ipItem.remarks,
    createdAt: isDate(ipItem.createdDate) ? new Date(ipItem.createdDate).toLocaleString() : '',
    createdBy: ipItem.createdBy,
    id: ipItem.id,
  }));

const generateApiWhiteListApiModel = (ipWhiteListItem) => {
  const ipAddressRangeRegex = /^(((\d+\.)+)(\d+))-(\d+)$/;
  let { ipAddressRange, ...ipWhiteListApiModel } = { ...ipWhiteListItem };

  if (ipWhiteListItem.type === IpWhiteListItemTypes.RANGE) {
    const ipAddressRangeGroups = ipAddressRangeRegex.exec(ipAddressRange);
    const startRangeAddress = ipAddressRangeGroups[1];
    const endRangeAddress = `${ipAddressRangeGroups[2]}${ipAddressRangeGroups[5]}`;

    ipWhiteListApiModel = { ...ipWhiteListApiModel, startRangeAddress, endRangeAddress };
  }

  return ipWhiteListApiModel;
};

export const ipWhiteListItemDefaultValues = { enabled: true, type: IpWhiteListItemTypes.SINGLE };

export class IpWhiteList extends React.Component {
  state = {
    ipWhiteList: [],
    itemDialogVisible: false,
    isLoading: true,
    deleteWhiteListItemDialogVisible: false,
    alternativeMessage: null,
    editItem: {},
    type: null,
  };

  itemIdToRemove = null;

  componentDidMount = async () => {
    const allowIpWhiteListModify = this.props.identityUser.profile?.AllowIpWhiteListModify;

    if (allowIpWhiteListModify && allowIpWhiteListModify === 'true') {
      await this.refreshWhiteListItems();
      this.setState({ isLoading: false });
    } else {
      this.setState({
        alternativeMessage: T.translate(LocaleKeys.messages.dontHavePermissionToModifyIpWhiteList),
      });
    }
  };

  addClick = () => {
    this.setState({
      itemDialogVisible: true,
      editItem: { ...ipWhiteListItemDefaultValues },
    });
  };

  refreshWhiteListItems = () => {
    this.setState({ isLoading: true });

    const siteId = this.props.value.siteId;

    return getIpWhiteListByTenantId(siteId).then(({ data }) => {
      this.setState({ ipWhiteList: data, isLoading: false });
    });
  };

  confirmIpItemDialog = (ipWhiteListItem) => {
    if (ipWhiteListItem.id) {
      this.updateIpWhiteListItem(ipWhiteListItem);
    } else {
      this.createNewIpWhiteListItem(ipWhiteListItem);
    }
  };

  updateIpWhiteListItem = (ipWhiteListItem) => {
    const ipWhiteListApiModel = generateApiWhiteListApiModel(ipWhiteListItem);

    putIpWhiteAddress(ipWhiteListItem.id, ipWhiteListApiModel).then(async () => {
      await this.refreshWhiteListItems();
      this.setState({ itemDialogVisible: false, editItem: {} });
    });
  };

  createNewIpWhiteListItem(ipWhiteListItem) {
    const tenantId = this.props.value.siteId;
    const ipWhiteListApiModel = generateApiWhiteListApiModel(ipWhiteListItem);

    postIpWhiteAddress({ ...ipWhiteListApiModel, tenantId }).then(async () => {
      await this.refreshWhiteListItems();
      this.setState({ itemDialogVisible: false, editItem: {} });
    });
  }

  deleteDialogOnConfirm = () => {
    this.deleteIpAddress(this.itemIdToRemove);
    this.ipAddressToRemove = null;
    this.setState({ deleteWhiteListItemDialogVisible: false });
  };

  deleteDialogOnCancel = () => {
    this.itemIdToRemove = null;
    this.setState({ deleteWhiteListItemDialogVisible: false });
  };

  editClick = (id) => {
    const editItem = this.state.ipWhiteList.find((item) => item.id === id);

    if (editItem.startRangeAddress && editItem.endRangeAddress) {
      editItem.ipAddressRange = `${editItem.startRangeAddress}-${editItem.endRangeAddress?.split('.')[3]}`;
    }

    this.setState({ itemDialogVisible: true, editItem: { ...editItem } });
  };

  deleteClick = (id) => {
    this.itemIdToRemove = id;
    this.setState({ deleteWhiteListItemDialogVisible: true });
  };

  deleteIpAddress = (id) => {
    deleteIpWhiteList(id).then(() => {
      this.refreshWhiteListItems();
    });
  };

  render() {
    if (this.state.alternativeMessage) {
      return <Message text={this.state.alternativeMessage} />;
    }

    if (this.state.isLoading) {
      return <Loader />;
    }

    const editItemButton = (
      <TableActionButton
        key="editItemButton"
        aria-label="Edit"
        onClick={this.editClick}
        clickparam={'id'}
        icon={<EditIcon />}
        tooltipText={T.translate(LocaleKeys.tooltips.editItem)}
      />
    );

    const deleteItemButton = (
      <TableActionButton
        key="deleteItemButton"
        aria-label="Delete"
        onClick={this.deleteClick}
        clickparam={'id'}
        icon={<DeleteIcon />}
        tooltipText={T.translate(LocaleKeys.tooltips.deleteItem)}
      />
    );

    const actionButtons = [editItemButton, deleteItemButton];

    return (
      <>
        <GenericDialog
          dialogOpen={this.state.deleteWhiteListItemDialogVisible}
          handleOk={this.deleteDialogOnConfirm}
          handleCancel={this.deleteDialogOnCancel}
          title={LocaleKeys.messages.deleteWhiteIpAddressDialogTitle}
          message={LocaleKeys.messages.deleteWhiteIpAddressDialogMessage}
        />

        <IpWhiteListItemDialog
          open={this.state.itemDialogVisible}
          onCancel={() => {
            this.setState({ itemDialogVisible: false, editItem: {} });
          }}
          onSaveDialog={(...params) => this.confirmIpItemDialog(...params)}
          categories={[]}
          initialValues={this.state.editItem}
        />

        <Table
          data={formatDataForTableView(this.state.ipWhiteList)}
          onAddClick={this.addClick}
          onRowDoubleClick={this.editClick}
          actionButtons={actionButtons}
          redirect={'id'}
          hideColumns={['id']}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  identityUser: state.app.identityUser,
});

IpWhiteList.propTypes = {
  identityUser: PropTypes.object,
  history: PropTypes.object,
  value: PropTypes.object,
};

export default compose(withRouter, connect(mapStateToProps))(IpWhiteList);
