import ApiClient from './ApiClient';

const apiClient = new ApiClient();
const SERVICE_PATH = '/configurationstore/api/';

const get = (path, params) => apiClient.get(SERVICE_PATH + path, { params });
const put = (path, data) => apiClient.put(SERVICE_PATH + path, data);
const deleteRequest = (path) => apiClient.delete(SERVICE_PATH + path);

// Values
export const getValue = (key) => get(`values/${key}`);
export const putValueCreateOrUpdate = (key, data) => put(`values/createorupdate/${key}`, data);
export const deleteValue = (key) => deleteRequest(`values/${key}`);
export const deleteValueIfExists = (key) => deleteRequest(`values/deleteifexist/${key}`);
