import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import Dropdown from 'Components/inputs/Dropdown';
import { TextField } from 'Components/formik/fields';
import FormikCollection from 'Components/inputs/CollectionManager/FormikCollection';
import { styled } from '@material-ui/core';

const TextFieldStyled = styled(TextField)({
  minWidth: 300,
  margin: 2,
});
const DropdownStyled = styled(Dropdown)({
  minWidth: 300,
  margin: 2,
});

const EventKeywordMapping = ({ dictionaries, keywordsEnabled }) => {
  return (
    <FormikCollection
      label={T.translate(LocaleKeys.labels.eventKeywordsMapping)}
      name={'keywordMappings'}
      disableAddItem={!keywordsEnabled}
    >
      <TextFieldStyled label={T.translate(LocaleKeys.labels.keywordName)} name="name" fullWidth />
      <TextFieldStyled label={T.translate(LocaleKeys.labels.keywordString)} name="inputValue" fullWidth />
      <DropdownStyled
        name="variable"
        label={T.translate(LocaleKeys.labels.variablesList)}
        data={dictionaries.eventKeywordsRelatedVariables}
        displayField="name"
        valueField="value"
        withoutEmpty
        fullWidth
        translateLabel={true}
        itemsDisabled
      />
    </FormikCollection>
  );
};

EventKeywordMapping.propTypes = {
  dictionaries: PropTypes.object,
  keywordsEnabled: PropTypes.bool,
};

export default EventKeywordMapping;
