import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import { getDrivers, getLibraryTemplates } from 'Api/devices';
import { Box, styled, CircularProgress } from '@material-ui/core';
import BrokenAlarmsWarning from 'Components/display/BrokenAlarmsWarning';
import { withFormik } from 'Components/formik/formikWrappers';
import { VehicleRunningTypes, VehicleRunningDefaultValue } from 'Constants/VehicleRunningTypes';
import { RequestConfigContext } from '../../requestConfigContext';
import { Dropdown } from 'Components/formik/fields';

export const prepareLibraryName = (name, param) => {
  const libraryName = name.substring(0, name.lastIndexOf('_'));
  const timestamp = parseInt(name.substring(name.lastIndexOf('_') + 1));
  const dateObj = new Date(timestamp);
  const datetime = dateObj.toLocaleString();

  return `${libraryName} (${datetime}) (${param})`;
};

const prepareDeviceLibraries = (libraries) => {
  const result = [];
  const currentLibrary = libraries.find((item) => item.isCurrentlySelected);

  if (currentLibrary) {
    result.push({
      id: currentLibrary.id,
      name: prepareLibraryName(currentLibrary.name, T.translate(LocaleKeys.labels.assigned)),
      driverId: currentLibrary.driverId,
    });
  }

  const previouslibraries = libraries
    .filter((item) => !item.isCurrentlySelected)
    .map((item) => ({
      id: item.id,
      name: prepareLibraryName(item.name, T.translate(LocaleKeys.labels.previous)),
      driverId: item.driverId,
    }));

  return [...result, ...previouslibraries];
};

const WarningBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  margin: '10px 4% !important',
});

const DeviceDriver = ({ data, onChange, brokenAlarms, isLoadingBrokenAlarms, setFieldValue, ...props }) => {
  const [libraryTemplates, setLibraryTemplates] = useState();
  const [drivers, setDrivers] = useState();
  const requestConfig = useContext(RequestConfigContext);

  useEffect(() => {
    getLibraryTemplates(requestConfig).then((response) => {
      const libraryTemplates = response?.data.map((item) => ({
        id: item.id,
        name: item.name,
        driverId: item.driverId,
      }));

      setLibraryTemplates(libraryTemplates);
    });
    getDrivers().then(({ data }) => {
      setDrivers(data);
    });
  }, []);

  if (!data || !libraryTemplates || !drivers) {
    return <CircularProgress />;
  }

  const deviceLibrariesData = prepareDeviceLibraries(props.deviceLibraries);
  const librariesData = [...libraryTemplates, ...deviceLibrariesData].filter(
    (library) => library.driverId === data.mappings?.driverId
  );

  return (
    <ColumnDisplayer>
      <Dropdown
        data={drivers}
        label={T.translate(LocaleKeys.labels.deviceDriver)}
        name="mappings.driverId"
        onChange={(e) => {
          const driverId = e.target.value;

          onChange({ target: { name: 'mappings', value: { driverId } } });
          props.fetchDriverMappingsInfo(driverId);
          setFieldValue('vehicle.vehicleRunningValue', VehicleRunningDefaultValue, false);
          setFieldValue('vehicle.vehicleRunningType', VehicleRunningTypes.SPEED_GREATER_OR_EQUAL, false);
        }}
        displayField="name"
        valueField="id"
        withoutEmpty
        readOnly={isLoadingBrokenAlarms}
        translateLabel
      />
      <Dropdown
        label={T.translate(LocaleKeys.labels.libraryName)}
        name="mappings.id"
        onChange={(e) => {
          onChange(e);
          props.onMappingsLibraryChange(data.mappings.driverId, e.target.value, onChange);
        }}
        data={librariesData}
        displayField="name"
        valueField="id"
        readOnly={!data.mappings?.driverId || isLoadingBrokenAlarms}
        withoutEmpty
      />
      {(!!brokenAlarms?.length || !!isLoadingBrokenAlarms) && (
        <WarningBox>
          {!!isLoadingBrokenAlarms ? (
            <CircularProgress />
          ) : (
            <Box maxHeight={'300px'} overflow={'auto'}>
              <BrokenAlarmsWarning
                message={T.translate(LocaleKeys.messages.brokenAlarmsDialogTitle)}
                alarms={brokenAlarms}
              />
            </Box>
          )}
        </WarningBox>
      )}
    </ColumnDisplayer>
  );
};

DeviceDriver.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
  deviceLibraries: PropTypes.array,
  brokenAlarms: PropTypes.array,
  isLoadingBrokenAlarms: PropTypes.bool,
  onMappingsLibraryChange: PropTypes.func,
  fetchDriverMappingsInfo: PropTypes.func,
};

export default withFormik(DeviceDriver);
