import React, { useEffect, useState } from 'react';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import { CircularProgress } from '@material-ui/core';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import { getAlarmStatusTypes, getAlarmTypes } from 'Api/devices';
import { useFormikContext } from 'formik';
import { AlarmStatuses } from 'Constants/AlarmStatuses';
import { Dropdown, DropdownWithApiCall, TextField } from 'Components/formik/fields';

const CommonInfo = (props) => {
  const { values } = useFormikContext();
  const [alarmStatusTypes, setAlarmStatusTypes] = useState();

  useEffect(() => {
    getAlarmStatusTypes().then((response) => {
      setAlarmStatusTypes(response.data);
    });
  }, []);

  if (!alarmStatusTypes) {
    return <CircularProgress />;
  }

  const statusData =
    values.status !== AlarmStatuses.FORCEFULLY_DEACTIVATED
      ? alarmStatusTypes.filter((item) => item.value !== AlarmStatuses.FORCEFULLY_DEACTIVATED)
      : alarmStatusTypes;

  return (
    <ColumnDisplayer>
      <DropdownWithApiCall
        label={T.translate(LocaleKeys.labels.type)}
        name="alarmType"
        apiCall={getAlarmTypes}
        displayField="name"
        valueField="value"
        withoutEmpty
      />
      <TextField label={T.translate(LocaleKeys.labels.name)} name="name" showPending />
      <TextField label={T.translate(LocaleKeys.labels.externalId)} name="externalId" disabled />
      <TextField label={T.translate(LocaleKeys.labels.desc)} name="description" />
      <Dropdown
        label={T.translate(LocaleKeys.labels.status)}
        name="status"
        data={statusData}
        displayField="name"
        valueField="value"
        withoutEmpty
        translateLabel
        itemsDisabled={values?.status === AlarmStatuses.FORCEFULLY_DEACTIVATED}
      />
    </ColumnDisplayer>
  );
};

export default CommonInfo;
