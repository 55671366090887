import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ActionButtonPreview } from 'Components/ActionButton';
import { useDrag, useDrop } from 'react-dnd';
import { Box } from '@material-ui/core';

const DraggableButton = ({ button, onClick, onMoveButton, index, categoryTempId, isReadOnly, ...props }) => {
  const ref = useRef(null);
  const [{ isDragging }, drag] = useDrag({
    item: { type: 'BUTTON', index, categoryTempId: button.categoryTempId },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  const [, drop] = useDrop({
    accept: 'BUTTON',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      if (button.categoryTempId !== item.categoryTempId) {
        return;
      }
      const dragIndex = item.index;
      let hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }

      onMoveButton(dragIndex, hoverIndex, button.categoryTempId);
      item.index = hoverIndex;
      hoverIndex = dragIndex;
    },
  });

  useEffect(() => {
    if (!isReadOnly) {
      drag(drop(ref));
    }
  }, [isReadOnly]);

  return (
    <Box
      ref={ref}
      style={{
        opacity: isDragging ? 0 : 1,
      }}
    >
      <ActionButtonPreview {...props} button={button} onClick={onClick} />
    </Box>
  );
};

DraggableButton.defaultProps = {
  onClick: () => {},
};

DraggableButton.propTypes = {
  button: PropTypes.object.isRequired,
  onMoveButton: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

export default DraggableButton;
