import React, { useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import T from 'i18n-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import LocaleKeys from 'Localization/LocaleKeys';
import { FormHelperText, Box } from '@material-ui/core';

const MultiselectWithFilter = ({
  data,
  name,
  value,
  label,
  valid,
  itemsDisabled,
  translateLabel,
  getOptionDisabled,
  showProgress,
  sortByDisplayField,
  className,
  ...props
}) => {
  const onChange = useCallback(
    (e, selected, reason) => {
      props.onChange({ target: { name, value: selected } }, reason);
    },
    [name, props]
  );

  const getOptionLabel = (option) => {
    const rawLabel = option.label;

    return translateLabel ? T.translate(rawLabel) : rawLabel;
  };

  const getSelectedOption = (option, selected) => {
    if (!selected) {
      return false;
    }

    return selected.label === option.label;
  };

  const itemsSelected = (value && value.length) || 0;

  return (
    <Autocomplete
      multiple
      options={sortByDisplayField ? data && data.sort((a, b) => a.label.localeCompare(b.label)) : data}
      getOptionLabel={getOptionLabel}
      name={name}
      className={className}
      renderInput={(params) => {
        if ((!data || data.length < 1) && showProgress) {
          return <CircularProgress />;
        }

        return (
          <Box flexDirection="column" width="100%">
            <TextField
              {...params}
              label={label}
              variant="outlined"
              fullWidth={props.fullWidth}
              error={valid && valid !== 'true'}
              helperText={
                valid && valid !== 'true' && T.translate(LocaleKeys.validation[props.validationerrorlocalekeys])
              }
              InputProps={{
                ...params.InputProps,
                ...props.InputProps,
              }}
            />
            {props.showHelperText && (
              <FormHelperText>
                {!valid && itemsSelected > 0 && itemsSelected + T.translate('itemsSelected')}
              </FormHelperText>
            )}
          </Box>
        );
      }}
      onChange={onChange}
      getOptionDisabled={getOptionDisabled}
      disabled={itemsDisabled}
      value={value}
      getOptionSelected={getSelectedOption}
      disableClearable={props.disableClearable}
      forcePopupIcon={props.forcePopupIcon}
    />
  );
};

MultiselectWithFilter.propTypes = {
  data: PropTypes.array,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.arrayOf(PropTypes.object)]),
  label: PropTypes.string,
  valid: PropTypes.string,
  itemsDisabled: PropTypes.bool,
  translateLabel: PropTypes.bool,
  getOptionDisabled: PropTypes.func,
  onChange: PropTypes.func,
  sortByDisplayField: PropTypes.bool,
  disableClearable: PropTypes.bool,
  forcePopupIcon: PropTypes.oneOf(['auto', true, false]),
  showHelperText: PropTypes.bool,
};

MultiselectWithFilter.defaultProps = {
  forcePopupIcon: 'auto',
  showHelperText: true,
};

export default MultiselectWithFilter;
