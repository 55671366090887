import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { styled } from '@material-ui/core';

const Chips = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  overflowY: 'auto',
  maxHeight: 115,
});

const ChipStyled = styled(Chip)({
  margin: 2,
});

const InputLabelStyled = styled(InputLabel)({
  backgroundColor: 'white',
  paddingRight: '5px',
});

const getSortedItems = (items, sortByDisplayField, displayField) => {
  if (sortByDisplayField && displayField && items?.length && typeof items[0][displayField] === 'string') {
    return items.sort((a, b) => a[displayField].localeCompare(b[displayField], undefined, { sensitivity: 'base' }));
  } else {
    return items;
  }
};

const MultiSelect = (props) => {
  const itemClick = (name) => {
    const data = props.value || [];
    const value = [...data];

    if (value.indexOf(name) > -1) {
      value.splice(value.indexOf(name), 1);
    } else {
      value.push(name);
    }
    props.onChange({ target: { value, name: props.name } });
  };

  const getSelectedItemsDisplayValues = () => {
    const displayValue = props.valueField ? props.valueField : 'id';
    const selectedItems =
      props.items && props.value ? props.items.filter((item) => props.value.includes(item[displayValue])) : [];

    return selectedItems.map((item) => item[props.displayField]);
  };

  const { items, sortByDisplayField, displayField } = props;

  const error = props.valid;
  const displayValues = displayField ? getSelectedItemsDisplayValues() : props.value;
  const sortedItems = getSortedItems(items, sortByDisplayField, displayField);

  return (
    <FormControl className={props.className} variant="outlined" error={props.valid && props.valid !== 'true'}>
      <InputLabelStyled htmlFor={`select-multiple-chip-${props.name}}`}>{props.label}</InputLabelStyled>
      <Select
        multiple
        value={displayValues || []}
        disabled={props.disabled}
        input={
          <OutlinedInput labelWidth={0} id={`select-multiple-chip-${props.name}}`} fullWidth {...props.InputProps} />
        }
        renderValue={(selected) => (
          <Chips>
            {selected.map((value) => (
              <ChipStyled key={value} label={props.translate ? T.translate(value) : value} />
            ))}
          </Chips>
        )}
        MenuProps={{
          PaperProps: {
            style: {
              minWidth: 250,
            },
          },
        }}
        readOnly={props.readOnly}
      >
        {sortedItems?.map((item, index) => {
          const rawLabel = displayField ? item[displayField] : item;
          const label = props.translate ? T.translate(rawLabel) : rawLabel;
          const value =
            displayField && props.valueField ? item[props.valueField] : displayField && item.id ? item.id : item;

          return (
            <MenuItem key={index} onClick={() => itemClick(value)}>
              <Checkbox checked={props.value?.indexOf(value) > -1} />
              <ListItemText primary={label} />
            </MenuItem>
          );
        })}
      </Select>
      {error && <FormHelperText>{T.translate(LocaleKeys.validation[props.validationerrorlocalekeys])}</FormHelperText>}
      {!error && props.showHelperText && (
        <FormHelperText>
          {props.helperText || (props.value ? props.value.length : 0) + T.translate('itemsSelected')}
        </FormHelperText>
      )}
    </FormControl>
  );
};

MultiSelect.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.array,
  valueField: PropTypes.string,
  displayField: PropTypes.string,
  className: PropTypes.string,
  translate: PropTypes.bool,
  readOnly: PropTypes.bool,
  sortByDisplayField: PropTypes.bool,
  helperText: PropTypes.string,
  showHelperText: PropTypes.bool,
};

MultiSelect.defaultProps = {
  translate: false,
  readOnly: false,
  sortByDisplayField: false,
  showHelperText: true,
};

export default MultiSelect;
