import React from 'react';
import store from 'redux/store';
import { enqueueSnackbar } from 'redux/notifications/actions';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { IconButton } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

const ReloadAction = ({ key, onClick }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton
      size="small"
      color="inherit"
      onClick={() => {
        onClick(key);
        closeSnackbar(key);
      }}
    >
      <Refresh />
    </IconButton>
  );
};

const updateServiceWorker = (registration) => {
  const registrationWaiting = registration.waiting;

  if (registrationWaiting) {
    registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
    registrationWaiting.addEventListener('statechange', (e) => {
      if (e.target.state === 'activated') {
        window.location.reload();
      }
    });
  }
};

const pushUpdateNotification = (registration) => {
  store.dispatch(
    enqueueSnackbar({
      variant: 'info',
      message: T.translate(LocaleKeys.messages.newVersionAvailablePleaseCloseAllTabsAndReload),
      persist: true,
      action: (key) => <ReloadAction key={key} onClick={() => updateServiceWorker(registration)} />,
    })
  );
};

export const serviceWorkerConfig = {
  onInit: (registration) => {
    if (registration.waiting) {
      pushUpdateNotification(registration);
    }
  },
  onUpdate: (registration) => {
    pushUpdateNotification(registration);
  },
  checkForUpdateIntervalMs: 1000 * 60,
};
