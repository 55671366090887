import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { styled } from '@material-ui/core';

const ListStyled = styled(List)({
  width: '100%',
  maxHeight: '300px',
  overflowY: 'scroll',
});

const AutoSuggest = (props) => {
  return props.open ? (
    <ListStyled component="nav" aria-label="auto-suggest-list">
      {props.suggestions.map((s) => {
        return (
          <ListItem key={s} button onMouseDown={(e) => props.suggestionClick(s, e)}>
            <ListItemText primary={s} />
          </ListItem>
        );
      })}
    </ListStyled>
  ) : null;
};

AutoSuggest.propTypes = {
  open: PropTypes.bool,
  suggestions: PropTypes.array,
  suggestionClick: PropTypes.func,
};

export default AutoSuggest;
