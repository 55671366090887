import '../leaflet-draw-extension/draw/DrawToolbar';
import '../leaflet-draw-extension/edit/EditToolbar';
import { translateLeafletDraw } from 'Localization';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';

const locales = translateLeafletDraw();

locales.draw.toolbar.buttons.iconMarker = T.translate(LocaleKeys.labels.drawIcon);
locales.draw.toolbar.buttons.textMarker = T.translate(LocaleKeys.labels.addText);
locales.draw.handlers.iconMarker = { tooltip: { start: T.translate(LocaleKeys.labels.clickMapToPlaceMarker) } };
locales.draw.handlers.textMarker = { tooltip: { start: T.translate(LocaleKeys.labels.clickMapToPlaceMarker) } };

export const DrawLayerTypes = {
  Circle: 'circle',
  CircleMarker: 'circlemarker',
  HandDrawnPolygon: 'handDrawnPolygon',
  HandDrawnPolyline: 'handDrawnPolyline',
  IconMarker: 'iconMarker',
  Marker: 'marker',
  Polygon: 'polygon',
  Polyline: 'polyline',
  Rectangle: 'rectangle',
  TextMarker: 'textMarker',
};
