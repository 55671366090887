import React from 'react';
import { styled } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  TableFooter,
  TablePagination,
  CircularProgress,
  Chip,
} from '@material-ui/core';
import { Loop } from '@material-ui/icons';
import LabelsLocaleKeys from 'Localization/LabelsLocaleKeys';
import T from 'i18n-react';
import LeafletMap from 'Components/LeafletMap';
import ApiIconMarker from 'Components/ApiIconMarker';
import CloseIcon from '@material-ui/icons/Close';
import RoomIcon from '@material-ui/icons/Room';
import { ActionButtonPreview } from 'Components/ActionButton';
import PropTypes from 'prop-types';

const colors = {
  in: '#71b604',
  out: '#4cd1d1',
};

const MESSAGE_TYPE_ALARM = 1;
const MESSAGE_TYPE_TCP = 2;
const MESSAGE_TYPE_URL = 3;
const COMMAND_TYPE_TCP = 1;
const COMMAND_TYPE_URL = 2;

const Label = styled(({ color, ...props }) => <Chip {...props} />)(({ color, width }) => ({
  backgroundColor: color,
  color: '#fff',
  width: width || '50px',
  borderRadius: 7,
}));

const getHistoryEntryKey = (entry) =>
  `${entry.messageType}${entry.messageType === 1 ? entry.uniqueId : entry.commandExternalId}`;

const getButtonCommandType = (messageType) => {
  if (messageType === MESSAGE_TYPE_TCP) {
    return COMMAND_TYPE_TCP;
  }
  if (messageType === MESSAGE_TYPE_URL) {
    return COMMAND_TYPE_URL;
  }

  return 0;
};

const HistoryEntryCommandButton = ({ label, buttonCommandType, color }) => {
  const button = {
    buttonCommandType,
    onCommandText: label,
    color,
    state: 1, // CommandOn
    size: 2, // Medium
    margin: '0',
    height: '24px',
    ellipsis: true,
    disableClick: true,
  };

  return <ActionButtonPreview button={button} />;
};

const StyledTableRow = styled(TableRow)(({ even, disabled }) => ({
  backgroundColor: even === 'even' ? '#f9f9f9' : 'white',
  '&:hover': {
    backgroundColor: '#e5f1d5',
    disabled: disabled,
  },
}));

const TableCellStyled = styled(TableCell)({
  textAlign: 'left',
});

const TableCellWithMap = styled(TableCellStyled)({
  position: 'relative',
});

const Placeholder = styled('div')({
  fontSize: 18,
  textAlign: 'center',
  marginTop: 10,
});

const mapContainer = {
  background: '#ff0000',
  display: 'block',
  height: '200px',
  left: 0,
  position: 'absolute',
  width: '300px',
  zIndex: 5,
  border: '1px solid #000',
};

const addressContainer = {
  alignItems: 'center',
  background: '#fff',
  bottom: 0,
  display: 'flex',
  margin: 0,
  minHeight: '40px',
  padding: '5px',
  position: 'absolute',
  width: '100%',
  zIndex: 1000,
};

const closeMapPreviewBtn = {
  cursor: 'pointer',
  margin: 0,
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: 400,
};

const addressCell = {
  display: 'flex',
  alignItems: 'center',
};

const Loading = () => (
  <Box align="center" p={4}>
    <CircularProgress />
  </Box>
);

const HistoryView = ({
  setPage,
  page,
  setRowsPerPage,
  rowsPerPage,
  count,
  setSelectedRow,
  device,
  loading,
  entries,
  selectedRow,
  fetchPage,
}) => {
  return loading ? (
    <Loading />
  ) : device?.externalId && entries && entries.length ? (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell align="center">{T.translate(LabelsLocaleKeys.inOut).toUpperCase()}</TableCell>
          <TableCell align="center">{T.translate(LabelsLocaleKeys.eventName).toUpperCase()}</TableCell>
          <TableCell align="center">{T.translate(LabelsLocaleKeys.login).toUpperCase()}</TableCell>
          <TableCell align="center">{T.translate(LabelsLocaleKeys.date).toUpperCase()}</TableCell>
          <TableCell align="center">{T.translate(LabelsLocaleKeys.localization).toUpperCase()}</TableCell>
          <TableCell align="center">{T.translate(LabelsLocaleKeys.action).toUpperCase()}</TableCell>
          <TableCell align="center">{T.translate(LabelsLocaleKeys.device).toUpperCase()}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {entries.map((entry, index) => {
          const isAlarm = entry.messageType === MESSAGE_TYPE_ALARM;
          const rowSelected = selectedRow === index;

          return (
            <StyledTableRow key={getHistoryEntryKey(entry)} even={index % 2 ? 'odd' : 'even'}>
              <TableCellStyled align="center">
                {!isAlarm ? (
                  <Label label={T.translate('out')} size="small" color={colors.out} />
                ) : (
                  <Label label={T.translate('in')} size="small" color={colors.in} />
                )}
              </TableCellStyled>
              <TableCellStyled align="center">
                {`${entry.alarmName || ''} ${T.translate(entry.alarmMessage || '')}` || ''}
              </TableCellStyled>
              <TableCellStyled align="center">{entry.login || ''}</TableCellStyled>
              <TableCellStyled align="center">{new Date(entry.createdOn).toLocaleString()}</TableCellStyled>
              <TableCellWithMap align="center">
                {isAlarm
                  ? (
                      <p style={addressCell}>
                        <span>{entry.address}</span>
                        <RoomIcon
                          onClick={entry.location ? () => setSelectedRow(rowSelected ? null : index) : null}
                          style={entry.location && { cursor: 'pointer' }}
                        />
                      </p>
                    ) || `${entry.location.latitude || ''} ${entry.location.longitude || ''}`
                  : ''}
                {rowSelected && (
                  <div style={mapContainer}>
                    <span onClick={(e) => e.stopPropagation()}>
                      <LeafletMap
                        noLayers
                        maxZoom={20}
                        enableFitBounds
                        center={[entry.location?.latitude, entry.location?.longitude]}
                      >
                        <ApiIconMarker
                          position={entry.location}
                          tooltipText={entry.assetName}
                          iconId={device?.iconId}
                          color={device?.color}
                        />
                      </LeafletMap>
                    </span>
                    <p style={addressContainer}>{entry.address}</p>
                    <p style={closeMapPreviewBtn} onClick={() => setSelectedRow(null)}>
                      <CloseIcon />
                    </p>
                  </div>
                )}
              </TableCellWithMap>
              <TableCellStyled align="center">
                {!isAlarm &&
                  (entry.buttonColor !== 0 ? (
                    <HistoryEntryCommandButton
                      label={entry.commandName}
                      color={entry.buttonColor}
                      buttonCommandType={getButtonCommandType(entry.messageType)}
                    />
                  ) : entry.commandName === 'Refresh' ? (
                    <Loop />
                  ) : (
                    entry.commandName
                  ))}
              </TableCellStyled>
              <TableCellStyled align="center">{device.name}</TableCellStyled>
            </StyledTableRow>
          );
        })}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
            }}
            onChangePage={(e, newPage) => {
              setPage(newPage);
              setSelectedRow(null);
              fetchPage(newPage, rowsPerPage);
            }}
            onChangeRowsPerPage={(e) => {
              const rowsPerPage = e.target.value;

              setRowsPerPage(rowsPerPage);
              fetchPage(page, rowsPerPage);
            }}
          />
        </TableRow>
      </TableFooter>
    </Table>
  ) : (
    <Placeholder>
      {device?.externalId ? T.translate(LabelsLocaleKeys.noResults) : T.translate(LabelsLocaleKeys.pleaseSelectDevice)}
    </Placeholder>
  );
};

HistoryView.propTypes = {
  setPage: PropTypes.func,
  page: PropTypes.number,
  setRowsPerPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  count: PropTypes.number,
  setSelectedRow: PropTypes.func,
  device: PropTypes.object,
  loading: PropTypes.bool,
  entries: PropTypes.array,
  selectedRow: PropTypes.number,
  fetchPage: PropTypes.func,
};

export default HistoryView;
