import React, { useState, useEffect } from 'react';

export const withRenderInterval =
  (interval) =>
  (WrappedComponent) =>
  ({ ...props }) => {
    const [flag, setFlag] = useState(false);

    useEffect(() => {
      const intervalId = setInterval(() => {
        setFlag((prev) => !prev);
      }, interval);

      return () => {
        clearInterval(intervalId);
      };
    }, []);

    return <WrappedComponent forceRenderFlag={flag} {...props} />;
  };
