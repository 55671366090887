import React, { Component } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { Permissions } from 'Constants/permissions';
import Tabs from 'Components/display/tabs/Tabs';
import Privileges from './tabs/Privileges';
import { getRoleByName, putRole, getPermissions } from 'Api/policyServer';
import SaveOnLeave from 'Components/dialogs/SaveOnLeave';
import Button from 'Components/Button';
import { UserRoles } from 'routes/Routes';
import ActionPanel from 'Components/display/ActionPanel';
import RoleDescription from './tabs/RoleDescription';
import ExpansionSection from 'Components/display/ExpansionPanel/ExpansionSection';
import Loader from 'Components/Loader';
import { compose } from 'redux';
import withPermissions from 'hocs/withPermissions';
import { UserRolesValidation } from './UserRolesValidation';
import { FormikEnhanced } from 'Components/formik/formikWrappers';

class Details extends Component {
  state = {
    permissions: null,
    roleInfo: { name: '', code: '', permissions: [] },
    permissionIsLoaded: false,
    roleInfoIsLoaded: false,
  };

  allDataIsReady = () => {
    return this.state.permissionIsLoaded && this.state.roleInfoIsLoaded;
  };

  componentDidMount = async () => {
    this.loadPermissionDictionary();
    this.getCurrentRoleInfo();
  };

  getCurrentRoleInfo = () => {
    const { name } = this.props.match.params;

    getRoleByName(name.replace(/-/g, ' '))
      .then(({ data }) => {
        return data;
      })
      .catch(() => {
        alert('error during try get role info');
      })
      .then((roleInfo) => {
        this.setState({
          roleInfo,
          roleInfoIsLoaded: true,
        });
      })
      .catch(() => {
        alert('error during create view');
      });
  };

  loadPermissionDictionary = () => {
    getPermissions()
      .then(({ data }) => {
        return data;
      })
      .catch(() => {
        alert('error during try get permissions');
      })
      .then((permissions) => {
        this.setState({
          permissions,
          permissionIsLoaded: true,
        });
      })
      .catch(() => {
        alert('error during create view');
      });
  };

  redirect = () => {
    const url = UserRoles.getUrl();

    this.props.history.push(url);
  };

  onCancel = () => {
    this.redirect();
  };

  saveData = (values) => {
    const newRole = {
      name: values.roleInfo.name,
      code: values.roleInfo.code,
      permissions: values.roleInfo.permissions,
      id: values.roleInfo.id,
    };

    return putRole(newRole);
  };

  onSubmit = (values, { setSubmitting }) => {
    this.saveData(values).finally(() => {
      setSubmitting(false);
      this.redirect();
    });
  };

  onSwitchTabConfirmSave = (values, setSubmitting) => {
    setSubmitting(true);
    this.saveData(values).then(() => {
      this.getCurrentRoleInfo();
      setSubmitting(false);
    });
  };

  onSwitchTabContinueWithoutSave = (dirty) => {
    if (dirty) {
      this.getCurrentRoleInfo();
    }
  };

  render() {
    const data = {
      permissions: this.state.permissions,
      roleInfo: this.state.roleInfo,
    };
    const canSaveChanges = !!this.props.permissions[Permissions.CanEditUserRoles];

    if (!this.allDataIsReady()) {
      return <Loader />;
    }

    return (
      <FormikEnhanced
        initialValues={data}
        enableReinitialize
        validationSchema={UserRolesValidation}
        validateOnMount
        onSubmit={this.onSubmit}
        canSaveChanges={canSaveChanges}
      >
        {({ values, dirty, isValid, isSubmitting, setSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Tabs
              label="userRoleTemplateDetails"
              saveChange={() => this.onSwitchTabConfirmSave(values, setSubmitting)}
              setData={() => this.onSwitchTabContinueWithoutSave(dirty)}
              showConfirmationDialog={dirty && canSaveChanges}
              validForm={isValid}
            >
              <ExpansionSection label={LocaleKeys.labels.commonInfo}>
                <RoleDescription label={LocaleKeys.labels.roleDescription} />
                <Privileges label={LocaleKeys.labels.privileges} />
              </ExpansionSection>
            </Tabs>
            <ActionPanel>
              {canSaveChanges && (
                <>
                  <Button type="submit" disabled={!(dirty && isValid) || isSubmitting} id="userRolessubmitButton">
                    {T.translate(LocaleKeys.labels.save)}
                  </Button>
                  <SaveOnLeave saveData={() => this.saveData(values)} dataChanged={dirty} validForm={isValid} />
                </>
              )}
              <Button onClick={this.onCancel} id="userRoles-cancelButton">
                {T.translate(LocaleKeys.labels.cancel)}
              </Button>
            </ActionPanel>
          </form>
        )}
      </FormikEnhanced>
    );
  }
}

Details.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  permissions: PropTypes.object,
};

export default compose(withPermissions([Permissions.CanAccessUserRolesPage, Permissions.CanReadUserRoles]))(Details);
