const ValuesLocaleKeys = {
  userStatusNew: 'userStatusNew',
  userStatusWaitForActivation: 'userStatusWaitForActivation',
  userStatusActivationFailed: 'userStatusActivationFailed',
  userStatusNoActivated: 'userStatusNoActivated',
  userStatusActive: 'userStatusActive',
  userStatusLocked: 'userStatusLocked',
  userStatusBlocked: 'userStatusBlocked',
  userStatusRemoved: 'userStatusRemoved',
  webhookTypeLISA: 'webhookTypeLISA',
};

export default ValuesLocaleKeys;
