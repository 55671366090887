import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import Item from './Item';
import * as configurationStore from 'Api/configurationStore';
import { getAlarmsHistoryThreatboard } from 'Api/notifications';
import {
  addThreatboardAlarm,
  deleteThreatboardDevice,
  setThreatsboardDevice,
  loadThreatBoardAlarmsSuccess,
  loadThreatBoardAlarmsFail,
  setThreatBoardAlarms,
} from 'redux/assets/actions';
import { withSignalR } from 'context/signalr';
import { CancelToken, isCancel } from 'Api/ApiClient';

const ThreatBoard = ({
  hub,
  onClickAlarm,
  onClickDevice,
  threatboardAlarms,
  addThreatboardAlarm,
  loadThreatBoardAlarmsSuccess,
  loadThreatBoardAlarmsFail,
  threatboardDevices,
  deleteThreatboardDevice,
  setThreatsboardDevice,
  setThreatBoardAlarms,
}) => {
  useEffect(() => {
    const source = CancelToken.source();

    getAlarmsHistoryThreatboard({ cancelToken: source.token })
      .then(({ data: { alarms } }) => {
        loadThreatBoardAlarmsSuccess({ alarms });
      })
      .catch((error) => {
        if (!isCancel(error)) {
          loadThreatBoardAlarmsFail({ error });
        }
      });

    return () => {
      setThreatBoardAlarms({ alarms: [] });
      source.cancel();
    };
  }, [loadThreatBoardAlarmsSuccess, loadThreatBoardAlarmsFail, setThreatBoardAlarms]);

  useEffect(() => {
    if (hub) {
      const handleOnAlarm = (alarm) => {
        if (alarm.showOnThreatBoard) {
          addThreatboardAlarm({ alarm });
        }
      };

      hub.on('Alarm', handleOnAlarm);
    }

    return () => {
      if (hub) {
        hub.off('Alarm', handleOnAlarm);
      }
    };
  }, [hub, addThreatboardAlarm]);
  useEffect(() => {
    configurationStore.getValue('Threatboard').then((response) => {
      if (Array.isArray(response.data)) {
        setThreatsboardDevice(response.data || []);
      }
    });
  }, [setThreatsboardDevice]);
  useEffect(() => {
    if (threatboardDevices) {
      configurationStore.putValueCreateOrUpdate('Threatboard', {
        value: JSON.stringify(threatboardDevices),
      });
    }
  }, [threatboardDevices]);

  const unresolvedThreatboardAlarms = threatboardAlarms.filter((alarm) => !alarm.isAlarmResolved);

  return (
    <Box overflow="scroll" maxWidth={'calc(100vw - 100px)'}>
      <Box height={100} display="flex">
        {unresolvedThreatboardAlarms.map((alarm) => (
          <Item
            key={`${alarm.alarmId}.${alarm.assetId}`}
            alarm={alarm}
            onClick={() => {
              onClickAlarm(alarm);
            }}
          />
        ))}
        {threatboardDevices.map((assetId) => (
          <Item
            key={assetId}
            assetId={assetId}
            onClick={() => {
              onClickDevice(assetId);
            }}
            onClickClose={() => {
              deleteThreatboardDevice(assetId);
            }}
          />
        ))}
        {unresolvedThreatboardAlarms.length === 0 && threatboardDevices.length === 0 && (
          <Box color="#ccc" display="flex" justifyContent="center" alignItems="center" margin="0 auto 40px">
            {T.translate(LocaleKeys.messages.overHereYouWillSeeThreatBoardItems)}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const mapState = (state) => ({
  threatboardAlarms: state.assets.threatboardAlarms,
  threatboardDevices: state.assets.threatboardDevices,
});
const mapDispatch = {
  addThreatboardAlarm,
  loadThreatBoardAlarmsSuccess,
  loadThreatBoardAlarmsFail,
  setThreatBoardAlarms,
  deleteThreatboardDevice,
  setThreatsboardDevice,
};

export default compose(withSignalR, connect(mapState, mapDispatch))(ThreatBoard);
