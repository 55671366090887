import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { compose } from 'redux';
import { Formik } from 'formik';
import { Paper, Box, styled } from '@material-ui/core';
import Button from 'Components/Button';
import CheckboxRaw from 'Components/inputs/Checkbox';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import DateTimeInputRaw from 'Components/inputs/DateTime';
import NotificationPreview from 'Components/display/notification/NotificationPreview';
import { getSites, getNotification, postNotification } from 'Api/sites';
import TextInputRaw from 'Components/inputs/TextInput';
import { SiteNotifications } from 'routes/Routes';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import SaveOnLeave from 'Components/dialogs/SaveOnLeave';
import ListInputWithApiCall from 'Components/inputs/ListInputWithApiCall';
import { removeProperty } from 'Helpers/ObjectHelper';
import { withField } from 'Components/formik/formikWrappers';
import SiteNotificationsValidation from './SiteNotificationsValidation';
import Loader from 'Components/Loader';

const TextInput = withField(TextInputRaw);
const Checkbox = withField(CheckboxRaw);
const DateTimeInput = withField(DateTimeInputRaw);

const ButtonStyled = styled(Button)({
  float: 'right',
  marginLeft: '10px',
});

const Container = styled('div')({
  height: 'calc(100vh - 170px)',
  overflow: 'hidden',
  margin: 10,
});

const BoxStyled = styled(Box)({
  width: '25%',
  margin: '10px 4% !important',
  display: 'flex',
});

const convertDate = (date) => {
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
};

const New = ({ history }) => {
  const [notification, setNotification] = useState();
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (history.location.state) {
      const { id } = history.location.state;

      if (id) {
        getNotification(id).then((response) => {
          const siteNotification = response?.data;

          removeProperty(siteNotification, 'notificationId');
          setNotification(siteNotification);
        });
      }
    } else {
      setNotification({
        startFrom: convertDate(new Date(Date.now() + 60 * 1000)),
        // one hour in the future to give initial validation
        activeTo: convertDate(new Date(Date.now() + 60 * 60 * 1000)),
        sites: [],
        displayAlwaysOnFeed: false,
      });
    }
  }, [history]);

  const redirect = () => {
    const url = SiteNotifications.getUrl();

    history.push(url);
  };

  const onSubmit = (values, { setSubmitting }) => {
    postNotification(values).then(() => {
      setSubmitting(false);
      redirect();
    });
  };

  const onCancel = () => {
    redirect();
  };

  const onPreviewClick = () => {
    setShowPreview(true);
  };

  if (!notification) {
    return <Loader />;
  }

  return (
    <Formik
      initialValues={notification}
      enableReinitialize
      validationSchema={SiteNotificationsValidation}
      validateOnMount
      onSubmit={onSubmit}
    >
      {({ values, dirty, isValid, isSubmitting, handleSubmit, handleChange }) => (
        <>
          <NotificationPreview
            open={showPreview}
            onClose={() => setShowPreview(false)}
            content={values.content}
            link={values.content2}
            startDate={values.startFrom}
          />
          <form onSubmit={handleSubmit}>
            <Paper>
              <Box display="flex" flexWrap="nowrap">
                <Box width={'75%'}>
                  <Box display="flex">
                    <BoxStyled>
                      <TextInput
                        label={T.translate(LocaleKeys.labels.content)}
                        name="content"
                        multiline
                        rows="4"
                        fullWidth
                      />
                    </BoxStyled>
                    <BoxStyled>
                      <TextInput label={T.translate(LocaleKeys.labels.linkToMoreContent)} name="content2" fullWidth />
                    </BoxStyled>
                  </Box>
                  <ColumnDisplayer>
                    <DateTimeInput
                      label={T.translate(LocaleKeys.labels.startFrom)}
                      type="datetime"
                      name="startFrom"
                      format="HH:mm dd.MM.yyyy"
                    />
                    {!values.displayAlwaysOnFeed && (
                      <DateTimeInput
                        label={T.translate(LocaleKeys.labels.activeTo)}
                        type="datetime"
                        name="activeTo"
                        disabled={values.displayAlwaysOnFeed}
                        format="HH:mm dd.MM.yyyy"
                      />
                    )}
                    <Checkbox
                      color="primary"
                      name="displayAlwaysOnFeed"
                      label={T.translate(LocaleKeys.labels.displayAlwaysOnFeed)}
                    />
                  </ColumnDisplayer>
                </Box>

                <Box>
                  <Container>
                    <ListInputWithApiCall
                      displayField="name"
                      apiCall={getSites}
                      valueField="siteId"
                      selected={values.sites}
                      label={T.translate(LocaleKeys.labels.sites).toUpperCase()}
                      name="sites"
                      onChange={(value) => handleChange({ target: { value, name: 'sites' } })}
                      width={'350px'}
                    />
                  </Container>
                </Box>
              </Box>
            </Paper>
            <ButtonStyled onClick={onCancel} id="new-siteNotifications-cancelButton">
              {T.translate(LocaleKeys.labels.cancel)}
            </ButtonStyled>
            <ButtonStyled
              type="submit"
              disabled={!(dirty && isValid) || isSubmitting}
              id="new-siteNotifications-submitButton"
            >
              {T.translate(LocaleKeys.labels.save)}
            </ButtonStyled>
            <ButtonStyled onClick={onPreviewClick} id="new-siteNotifications-previewButton">
              {T.translate(LocaleKeys.labels.preview)}
            </ButtonStyled>

            <SaveOnLeave saveData={handleSubmit} dataChanged={dirty} validForm={isValid} />
          </form>
        </>
      )}
    </Formik>
  );
};

New.propTypes = {
  data: PropTypes.object,
  history: PropTypes.object,
  permissions: PropTypes.object,
};

export default compose(
  withPermissions([
    Permissions.CanAccessSiteNotificationsPage,
    Permissions.CanReadSites,
    Permissions.CanAddSiteNotifications,
  ])
)(New);
