import React from 'react';
import PropTypes from 'prop-types';
import { styled, Tooltip } from '@material-ui/core';

const LineClamp = styled('div')(({ width, lines }) => ({
  display: '-webkit-box',
  width,
  lineClamp: lines,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordBreak: 'normal',
}));

const LinesEllipsis = ({ text, maxLine, showTooltip, width }) => {
  return showTooltip ? (
    <Tooltip title={text}>
      <LineClamp width={width} lines={maxLine}>
        {text}
      </LineClamp>
    </Tooltip>
  ) : (
    <LineClamp width={width} lines={maxLine}>
      {text}
    </LineClamp>
  );
};

LinesEllipsis.propTypes = {
  maxLine: PropTypes.number,
  width: PropTypes.string,
  text: PropTypes.string,
  showTooltip: PropTypes.bool,
};

LinesEllipsis.defaultProps = {
  maxLine: 1,
  text: '',
  showTooltip: false,
};

export default LinesEllipsis;
