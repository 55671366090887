import PropTypes from 'prop-types';
import React from 'react';
import T from 'i18n-react';
import { styled } from '@material-ui/core/styles';
import FileUploadButton from './FileUploadButton';
import LocaleKeys from 'Localization/LocaleKeys';

const Info = styled('div')({
  color: '#7f7f7f',
  textAlign: 'right',
  fontSize: '12px',
  marginBottom: -17,
});

const FileUploadWithInfo = (props) => (
  <FileUploadButton {...props}>
    <Info>{`${T.translate(LocaleKeys.labels.maxSizeIs) + props.maxSize / 1024} KB ${props.extensions || ''}`}</Info>
  </FileUploadButton>
);

export default FileUploadWithInfo;

FileUploadWithInfo.propTypes = {
  label: PropTypes.string,
  maxSize: PropTypes.number,
  id: PropTypes.string,
  extensions: PropTypes.string,
};
