import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { Search as SearchIcon, Lens } from '@material-ui/icons';
import {
  Box,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  IconButton,
  Button,
  styled,
} from '@material-ui/core';
import { TcpIpStatuses } from 'Constants/TcpIpStatuses';

const stringContain = (searchedIn, query) => searchedIn?.toLowerCase().includes(query.toLowerCase());

export const filterDevices = (devices, { query, active, filter }) => {
  const queryTrimmed = query.trim();

  if (!devices) {
    return [];
  }

  return devices
    .filter(({ name, description }) => stringContain(name, queryTrimmed) || stringContain(description, queryTrimmed))
    .filter((device) => (active ? device.tcpIpStatusIndicator === TcpIpStatuses.CONNECTED : true))
    .filter((device) => {
      if (filter === 'all') {
        return true;
      }
      if (device.statusMode === null) {
        return false;
      }
      const { lastAt, warning, alarm } = device.statusMode;
      const timestampDiff = +new Date() / 1000 - lastAt;

      if (filter === 'normal') {
        return timestampDiff < warning;
      }
      if (filter === 'warning') {
        return timestampDiff > warning && timestampDiff < alarm;
      }

      return timestampDiff > alarm;
    });
};

const Search = (props) => (
  <Box mb={2} bgcolor="background.paper">
    <TextField
      variant="outlined"
      placeholder={T.translate(LocaleKeys.labels.search)}
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  </Box>
);

const LensColored = styled(({ color, ...props }) => <Lens {...props} />)({
  fill: (props) => props.color,
});

const Filters = ({ active, setActive, query, setQuery, filter, setFilter }) => (
  <>
    <Search
      value={query}
      onChange={(e) => {
        setQuery(e.target.value);
      }}
    />
    <Box display="flex" justifyContent="space-around">
      <Box textAlign="center">
        <FormControlLabel
          control={
            <Checkbox
              color="default"
              name={'activeOnly'}
              checked={active}
              onChange={() => {
                setActive(!active);
              }}
            />
          }
          label={T.translate('activeOnly')}
        />
      </Box>
      <Box
        style={{
          marginTop: -4,
        }}
      >
        <Button
          style={{
            opacity: filter === 'all' ? 1 : 0.5,
          }}
          onClick={() => {
            setFilter('all');
          }}
          id={'sidebar-all'}
        >
          {T.translate('all')}
        </Button>
        <IconButton
          style={{
            opacity: filter === 'normal' ? 1 : 0.5,
          }}
          onClick={() => {
            setFilter('normal');
          }}
          id={'sidebar-normal'}
        >
          <LensColored color="#71B603" />
        </IconButton>
        <IconButton
          style={{
            opacity: filter === 'warning' ? 1 : 0.5,
          }}
          onClick={() => {
            setFilter('warning');
          }}
          id={'sidebar-warning'}
        >
          <LensColored color="#F59A24" />
        </IconButton>
        <IconButton
          style={{
            opacity: filter === 'alarm' ? 1 : 0.5,
          }}
          onClick={() => {
            setFilter('alarm');
          }}
          id={'sidebar-alarm'}
        >
          <LensColored color="#D9041C" />
        </IconButton>
      </Box>
    </Box>
  </>
);

Filters.propTypes = {
  active: PropTypes.bool,
  setActive: PropTypes.func,
  query: PropTypes.string,
  setQuery: PropTypes.func,
  filter: PropTypes.string,
  setFilter: PropTypes.func,
};

export default Filters;
