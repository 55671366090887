import { clone } from 'Helpers/ObjectHelper';

const defaultButton = {
  size: 2,
  color: 1,
  onCommand: '',
  onCommandText: '',
  offCommand: '',
  offCommandText: '',
  type: 1,
  state: 1,
  isSensitive: false,
  isReasonRequired: false,
  buttonCommandType: 1,
  clickReasons: [],
};

const defaultCategory = {
  name: '',
  buttons: [],
};

const defaultLibrary = {
  name: '',
  categories: [
    {
      ...defaultCategory,
      name: 'General',
      tempId: Date.now(),
    },
  ],
  isTemplate: true,
};

export const types = {
  CREATE_BUTTON: 'CREATE_BUTTON',
  SAVE_BUTTON: 'SAVE_BUTTON',
  DELETE_BUTTON: 'DELETE_BUTTON',
  MOVE_BUTTON: 'MOVE_BUTTON',
  MOVE_TO_CATEGORY: 'MOVE_TO_CATEGORY',

  CREATE_LIBRARY: 'CREATE_LIBRARY',
  CHANGE_LIBRARY: 'CHANGE_LIBRARY',

  CREATE_CATEGORY: 'CREATE_CATEGORY',
  CHANGE_CATEGORY: 'CHANGE_CATEGORY',
  DELETE_CATEGORY: 'DELETE_CATEGORY',
  DELETE_CATEGORY_AND_MOVE_BUTTONS: 'DELETE_CATEGORY_AND_MOVE_BUTTONS',

  INITIALIZE_STATE: 'INITIALIZE_STATE',
};

export const initializeButtonLibrary = (library) => {
  if (library) {
    return {
      ...library,
      categories: library.categories.map((category, categoryIndex) => ({
        ...category,
        tempId: category.id || categoryIndex,
        buttons: category.buttons.map((button, buttonIndex) => ({
          ...button,
          tempId: button.id || buttonIndex,
          categoryTempId: category.id || categoryIndex,
        })),
      })),
    };
  } else {
    return null;
  }
};

export const buttonLibraryReducer = (library, action) => {
  switch (action.type) {
    case types.CREATE_BUTTON:
      return {
        ...library,
        categories: library.categories.map((category) => {
          if (category.tempId === action.categoryTempId) {
            return {
              ...category,
              buttons: [
                ...category.buttons,
                {
                  ...defaultButton,
                  tempId: Date.now(),
                  buttonCategoryId: category.id,
                  categoryTempId: category.tempId,
                },
              ],
            };
          }

          return category;
        }),
      };

    case types.SAVE_BUTTON:
      return {
        ...library,
        categories: library.categories.map((category) => {
          if (category.tempId === action.categoryTempId) {
            return {
              ...category,
              buttons: category.buttons.map((button) => {
                if (button.tempId === action.button.tempId) {
                  return {
                    ...action.button,
                  };
                }

                return button;
              }),
            };
          }

          return category;
        }),
      };

    case types.DELETE_BUTTON:
      return {
        ...library,
        categories: library.categories.map((category) => {
          if (category.tempId === action.categoryTempId) {
            return {
              ...category,
              buttons: category.buttons.filter((button) => button.tempId !== action.buttonTempId),
            };
          }

          return category;
        }),
      };

    case types.CREATE_LIBRARY:
      return {
        ...defaultLibrary,
      };

    case types.CHANGE_LIBRARY:
      return {
        ...library,
        [action.key]: action.value,
      };

    case types.CREATE_CATEGORY:
      return {
        ...library,
        categories: [
          ...library.categories,
          {
            ...clone(defaultCategory),
            name: action.categoryName,
            tempId: Date.now(),
          },
        ],
      };

    case types.CHANGE_CATEGORY:
      return {
        ...library,
        categories: library.categories.map((category) => {
          if (category.tempId === action.categoryTempId) {
            return {
              ...category,
              [action.key]: action.value,
            };
          }

          return category;
        }),
      };

    case types.DELETE_CATEGORY:
      return {
        ...library,
        categories: library.categories.filter((category) => category.tempId !== action.categoryTempId),
      };

    case types.DELETE_CATEGORY_AND_MOVE_BUTTONS: {
      const buttonsToMove = library.categories
        .find((category) => category.tempId === action.categoryTempId)
        .buttons.map((button) => ({
          ...button,
          id: undefined,
          externalId: undefined,
          clickReasons: button.clickReasons.map((reason) => ({
            ...reason,
            id: undefined,
          })),
        }));

      const categories = library.categories.filter((category) => category.tempId !== action.categoryTempId);

      return {
        ...library,
        categories: categories.map((category) => {
          if (category.tempId === action.targetCategoryTempId) {
            return {
              ...category,
              buttons: [...category.buttons, ...buttonsToMove].map((button, index) => ({
                ...button,
                buttonCategoryId: category.id,
                categoryTempId: category.tempId,
                order: index,
              })),
            };
          }

          return category;
        }),
      };
    }

    case types.MOVE_TO_CATEGORY:
      const currentCategory = library.categories.find((category) => category.tempId === action.currentCategoryTempId);
      const movedButton = clone(currentCategory.buttons[action.dragIndex]);

      return {
        ...library,
        categories: library.categories.map((category) => {
          if (category.tempId === action.currentCategoryTempId) {
            return {
              ...category,
              buttons: category.buttons.filter((button) => button.tempId !== movedButton.tempId),
            };
          }

          if (category.tempId === action.targetCategoryTempId) {
            return {
              ...category,
              buttons: [
                ...category.buttons,
                {
                  ...movedButton,
                  buttonCategoryId: category.id,
                  categoryTempId: category.tempId,
                  order: category.buttons.length,
                  id: undefined,
                  externalId: undefined,
                  clickReasons: movedButton.clickReasons.map((reason) => ({
                    ...reason,
                    id: undefined,
                  })),
                },
              ],
            };
          }

          return category;
        }),
      };

    case types.MOVE_BUTTON:
      return {
        ...library,
        categories: library.categories.map((category) => {
          if (category.tempId === action.categoryTempId) {
            const dragButton = clone(category.buttons[action.dragIndex]);
            const buttons = clone(category.buttons);

            buttons.splice(action.dragIndex, 1);
            buttons.splice(action.dropIndex, 0, dragButton);

            return {
              ...category,
              buttons: buttons.map((button, index) => ({ ...button, order: index })),
            };
          }

          return category;
        }),
      };

    case types.INITIALIZE_STATE:
      return initializeButtonLibrary(action.library);

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
