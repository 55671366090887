import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { muiPickersLocale } from 'Localization';
import { isDate } from 'Helpers/DateTimeHelpers';

export const formatTime = (date, minutes) => {
  if (minutes !== undefined) {
    const hours = Math.floor(minutes / 60);
    const modMinutes = minutes % 60;

    date = new Date(null, null, null, hours, modMinutes);
  }

  return date;
};

export const prepareDate = (date, formattedDate, minutes, type, name) => {
  if (isDate(date)) {
    if (type === 'time' && minutes !== undefined) {
      date = formattedDate.toString().split(':');
      date = parseInt(date[0]) * 60 + parseInt(date[1]);
    }
    if (type === 'date' || type === 'datetime') {
      date = date && date.toISOString();
    }
  }

  return { target: { value: date, name: name } };
};

class DateTimeInput extends React.Component {
  onChange = (date, formattedDate) => {
    this.props.onChange(prepareDate(date, formattedDate, this.props.minutes, this.props.type, this.props.name));
  };

  selection = () => {
    const { label, value, format, readOnly, className, valid, disabled, fullWidth, convertValue, type, style } =
      this.props;

    const keyboardPickerProps = {
      value: convertValue ? convertValue(value) : value,
      onChange: this.onChange,
      inputVariant: 'outlined',
      label: label,
      ampm: false,
      className: className,
      InputProps: {
        ...this.props.InputProps,
        readOnly,
        'data-testid': `datetime-input-for-${this.props.name}`,
      },
      disabled: disabled,
      error: valid && valid !== 'true',
      helperText: valid && valid !== 'true' && T.translate(LocaleKeys.validation[this.props.validationerrorlocalekeys]),
      readOnly: readOnly,
      format: format,
      style: style,
      cancelLabel: T.translate(LocaleKeys.labels.cancel),
      okLabel: T.translate(LocaleKeys.labels.ok),
    };

    switch (type) {
      case 'date':
        return <KeyboardDatePicker {...keyboardPickerProps} />;
      case 'time':
        return (
          <KeyboardTimePicker
            value={convertValue ? convertValue(value) : formatTime(this.props.value, this.props.minutes)}
            onChange={this.onChange}
            inputVariant="outlined"
            label={label}
            ampm={false}
            className={className}
            InputProps={{
              ...this.props.InputProps,
              readOnly,
              'data-testid': `datetime-input-for-${this.props.name}`,
            }}
            disabled={disabled}
            error={valid && valid !== 'true'}
            helperText={
              valid && valid !== 'true' && T.translate(LocaleKeys.validation[this.props.validationerrorlocalekeys])
            }
            readOnly={readOnly}
            format={format}
            cancelLabel={T.translate(LocaleKeys.labels.cancel)}
            okLabel={T.translate(LocaleKeys.labels.ok)}
          />
        );
      case 'datetime':
      default:
        return <KeyboardDateTimePicker fullWidth={fullWidth} {...keyboardPickerProps} />;
    }
  };

  render() {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={muiPickersLocale}>
        {this.selection()}
      </MuiPickersUtilsProvider>
    );
  }
}

DateTimeInput.defaultProps = {
  fullWidth: false,
  convertValue: null,
};

DateTimeInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string, PropTypes.func]),
  onChange: PropTypes.func,
  type: PropTypes.oneOf(['datetime', 'time', 'date']),
  className: PropTypes.string,
  name: PropTypes.string,
  minutes: PropTypes.number,
  InputProps: PropTypes.object,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  format: PropTypes.string,
  fullWidth: PropTypes.bool,
  convertValue: PropTypes.func,
};

export default DateTimeInput;
