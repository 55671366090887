import * as Sentry from '@sentry/react';
import Config from 'Config';
import Consts from 'consts';
import { UserManager } from 'oidc-client';
import { setCurrentIdentityUser } from 'redux/app/actions';
import store from 'redux/store';

// along with docs (https://github.com/IdentityModel/oidc-client-js/wiki) not all option keys are in snake case

const _userLogoutEventCallbackList = [];

const settings = {
  authority: Config.authSettings.authority,
  client_id: Config.authSettings.clientId,
  redirect_uri: Config.authSettings.redirectUri,
  silent_redirect_uri: Config.authSettings.silentRedirectUri,
  post_logout_redirect_uri: Config.authSettings.postLogoutRedirectUri,
  response_type: 'token id_token',
  scope: 'openid gateway backendapi identity',
  accessTokenExpiringNotificationTime: 600,
};

const mgr = new UserManager(settings);
let _user = null;

class AuthManager {
  static login = (redirectURL) => {
    mgr.clearStaleState();
    localStorage.setItem(Consts.LocalStorageKeys.LOGIN_SUCCESS_REDIRECT, redirectURL);
    mgr.signinRedirect();
  };

  static refreshSession = async () => {
    try {
      const newUser = await mgr.signinSilent();

      _user = newUser;
      store.dispatch(setCurrentIdentityUser(newUser));
    } catch (error) {
      Sentry.captureMessage(`UserManager.signinSilent error`, {
        extra: { error },
      });
    }
  };

  static silentRefreshSessionCallback = () => {
    return mgr.signinSilentCallback().catch((error) => {
      Sentry.captureMessage(`Login callback error.`, {
        extra: { error },
      });
      throw error;
    });
  };

  static loginCallbackHandler = (history) => {
    return mgr
      .signinRedirectCallback()
      .then((user) => {
        _user = user;
        store.dispatch(setCurrentIdentityUser(user));

        const redirectUrlRaw = localStorage.getItem(Consts.LocalStorageKeys.LOGIN_SUCCESS_REDIRECT);

        const redirectUrl = redirectUrlRaw.replace(/ /g, '-');

        localStorage.removeItem(Consts.LocalStorageKeys.LOGIN_SUCCESS_REDIRECT);
        if (redirectUrl) {
          history.push(redirectUrl);
        } else {
          history.push('/');
        }
      })
      .catch((error) => {
        Sentry.captureMessage(`Login callback error.`, {
          extra: { error },
        });
        throw error;
      });
  };

  static clearState = () => {
    mgr.removeUser();
    mgr.clearStaleState();
  };

  static logout = () => {
    if (_user) {
      this._fireUserLogoutCallbacks();
      _user = null;
      mgr.clearStaleState();
      mgr.signoutRedirect();
    } else {
      Sentry.captureMessage('User is not logged');
    }
  };

  static signoutCallbackHandler = () => {
    mgr
      .signoutRedirectCallback()
      .catch((error) => {
        Sentry.captureMessage(`Logout callback error`, {
          extra: { error },
        });
      })
      .finally(() => {
        window.history.push('/');
      });
  };

  static checkIfUserIsLoggedAsync = () => {
    return mgr.getUser().then((user) => {
      return user != null;
    });
  };

  static userIsLogged() {
    return _user != null;
  }

  static getUserAsync = () => {
    return mgr.getUser();
  };

  static get events() {
    return mgr.events;
  }

  static getUser = () => {
    return _user;
  };

  static addUserLogoutCallback = (callback) => {
    _userLogoutEventCallbackList.push(callback);
  };

  static _fireUserLogoutCallbacks = () => {
    _userLogoutEventCallbackList.forEach((callback) => {
      callback();
    });
  };
}

AuthManager.events.addAccessTokenExpiring(() => {
  AuthManager.refreshSession();
});

AuthManager.events.addAccessTokenExpired(() => {
  AuthManager.logout();
});

export default AuthManager;
