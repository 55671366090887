import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  additionalActionsPanel: {
    textAlign: 'right',
    marginBottom: '10px',
    marginTop: '10px',
    '& button': {
      marginLeft: '6px',
    },
  },
});

const ActionPanel = (props) => {
  return <div className={props.classes.additionalActionsPanel}>{props.children}</div>;
};

export default withStyles(styles)(ActionPanel);
