import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core';
import { SketchPicker } from 'react-color';

const Swatch = styled('div')({
  padding: '5px',
  background: '#fff',
  borderRadius: '1px',
  boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
  display: 'inline-block',
  cursor: 'pointer',
});

const ColorBox = styled('div')(({ format, color }) => ({
  width: '36px',
  height: '14px',
  borderRadius: '2px',
  background: format === 'hex' ? color : `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
}));

const PopOver = styled('div')({
  position: 'absolute',
  zIndex: '2',
});

const Cover = styled('div')({
  position: 'fixed',
  top: '0px',
  right: '0px',
  bottom: '0px',
  left: '0px',
});

const ColorSquare = (props) => {
  const [pickerOpen, setPickerOpen] = useState(false);

  const handleClick = () => {
    if (!props.readOnly) {
      setPickerOpen((prev) => !prev);
    }
  };

  const handleClose = () => {
    setPickerOpen(false);
  };

  const handleChange = (color) => {
    props.onChange({
      target: { name: props.name, value: color[props.format] },
    });
  };

  return (
    <div>
      <Swatch data-testid="ColorSquare" onClick={handleClick}>
        <ColorBox format={props.format} color={props.color} />
      </Swatch>
      {pickerOpen && (
        <PopOver>
          <Cover onClick={handleClose} data-testid="ColorPicker" />
          <SketchPicker color={props.color} onChange={handleChange} />
        </PopOver>
      )}
    </div>
  );
};

ColorSquare.propTypes = {
  color: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.number), PropTypes.string]),
  onChange: PropTypes.func,
  name: PropTypes.string,
  format: PropTypes.oneOf(['hex', 'hsl', 'hsv', 'rgb', 'oldHue']),
  readOnly: PropTypes.bool,
};

export default ColorSquare;
