import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { IconButton, styled } from '@material-ui/core';
import { LocationOnOutlined } from '@material-ui/icons';
import TooltipsLocaleKeys from 'Localization/TooltipsLocaleKeys';
import { deleteValueIfExists, getValue, putValueCreateOrUpdate } from 'Api/configurationStore';

const ControlBar = styled('div')({
  backgroundClip: 'content-box',
  backgroundColor: 'white',
  border: '2px solid rgba(0,0,0,0.2)',
  borderRadius: '4px',
});
const Control = styled(IconButton)({
  height: '30px',
  width: '30px',
  padding: '0',
  borderRadius: '0',
});
const TrackingIcon = styled(LocationOnOutlined)({
  fill: ({ fill }) => fill,
});

const boundsToJSON = (bounds) => {
  const northEast = bounds.getNorthEast();
  const southWest = bounds.getSouthWest();

  return [
    [northEast.lat, northEast.lng],
    [southWest.lat, southWest.lng],
  ];
};

const TrackingMode = ({ trackingMode, setTrackingMode, map, configurationStoreKey, ...props }) => {
  const saveBoundsTimout = useRef();
  const stateRef = useRef({ trackingMode, map, isMounted: false });

  stateRef.current.trackingMode = trackingMode;
  stateRef.current.map = map;

  useEffect(() => {
    stateRef.current.isMounted = true;

    return () => {
      stateRef.current.isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (map && configurationStoreKey && !trackingMode) {
      getValue(configurationStoreKey).then(({ data, status }) => {
        if (status === 200 && stateRef.current.isMounted) {
          map.fitBounds(data);
        }
      });
    }
  }, [map, configurationStoreKey, trackingMode]);

  useEffect(() => {
    if (map && configurationStoreKey) {
      map.on('zoomend moveend', (event) => {
        clearTimeout(saveBoundsTimout.current);
        if (!stateRef.current.trackingMode) {
          const bounds = event.target.getBounds();

          saveBoundsTimout.current = setTimeout(() => {
            putValueCreateOrUpdate(configurationStoreKey, { value: JSON.stringify(boundsToJSON(bounds)) });
          }, 1000);
        }
      });
    }
  }, [map, configurationStoreKey]);

  const handleClick = (e) => {
    e.stopPropagation();
    const prevTrackingMode = trackingMode;

    if (prevTrackingMode && configurationStoreKey) {
      const bounds = map.getBounds();

      putValueCreateOrUpdate(configurationStoreKey, { value: JSON.stringify(boundsToJSON(bounds)) });
    } else {
      deleteValueIfExists(configurationStoreKey);
    }
    setTrackingMode(!prevTrackingMode);
  };

  return (
    <ControlBar style={props.style}>
      <Control
        onClick={handleClick}
        title={T.translate(trackingMode ? TooltipsLocaleKeys.disableTracking : TooltipsLocaleKeys.enableTracking)}
      >
        <TrackingIcon fill={trackingMode ? 'black' : 'gray'} />
      </Control>
    </ControlBar>
  );
};

TrackingMode.propTypes = {
  trackingMode: PropTypes.bool,
  setTrackingMode: PropTypes.func.isRequired,
};

export default TrackingMode;
