import React from 'react';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import { Box, styled } from '@material-ui/core';
import { Masks } from 'Components/inputs/MaskedTextInput';
import FormikCollection from 'Components/inputs/CollectionManager/FormikCollection';
import { Checkbox, MaskedTextInput as MaskedTextInputRaw, TextField } from 'Components/formik/fields';

const inputStyles = {
  minWidth: 200,
  margin: 5,
};
const TextInput = styled(TextField)(inputStyles);
const MaskedTextInput = styled(MaskedTextInputRaw)(inputStyles);

const AutomaticCommands = (props) => (
  <Box width={'70%'} margin={'10px 4% !important'}>
    <Checkbox label={T.translate(LocaleKeys.labels.sendAutomaticCommands)} name="sendCommandsOnOccur" />
    <FormikCollection label={T.translate(LocaleKeys.labels.automaticCommands)} name={'commands'}>
      <TextInput label={T.translate(LocaleKeys.labels.name)} name={'commandName'} />
      <TextInput label={T.translate(LocaleKeys.labels.command)} name={'commandText'} fullWidth />
      <MaskedTextInput
        label={T.translate(LocaleKeys.labels.timeLapseToTrigger)}
        name="delay"
        maskConfig={Masks.HoursMinutesSeconds}
      />
    </FormikCollection>
  </Box>
);

export default AutomaticCommands;
