import * as Yup from 'yup';
import { getRoleExistsByName } from 'Api/policyServer';
import { isAlphanumericOrSpecialSymbol } from 'Validators/SharedValidators';

let lastValue;
let initialValue;

export const userRoleNameExists = async (value, id) => {
  if (isAlphanumericOrSpecialSymbol([' '])(value)) {
    const result = lastValue?.value === value ? lastValue?.result : await getRoleExistsByName(value);

    if (id) {
      if (!initialValue || id !== initialValue?.id) {
        initialValue = { value, id };
      }
    } else {
      initialValue = undefined;
    }

    lastValue = { value, result };

    return initialValue?.value === value || !result?.data;
  }

  return false;
};

export const UserRolesValidation = Yup.object().shape({
  roleInfo: Yup.object().shape({
    name: Yup.string()
      .required('thisFieldIsRequired')
      .max(50, 'nameCannotExceed50Characters')
      .alphanumericOrSpecialSymbol([' '])
      .test('userRoleNameExists', 'roleWithThisNameAlreadyExists', function (value) {
        return userRoleNameExists(value, this.parent.id);
      }),
    permissions: Yup.array().required(),
  }),
});

export default UserRolesValidation;
