import React, { Component } from 'react';
import PermissionManager from 'Auth/PermissionManager';
import Consts from 'consts/index';
import { withRouter } from 'react-router-dom';
import { Dashboard } from 'routes/Routes';
import Loader from 'Components/Loader';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';

class HomePage extends Component {
  state = {
    isReady: false,
  };

  componentDidMount = async () => {
    const hasDashboardPermission = await PermissionManager.hasPermissionFromCache(
      Consts.Permissions.CanAccessDashboardPage
    );

    if (hasDashboardPermission) {
      this.props.history.push(Dashboard.getUrl());

      return;
    }

    this.setState({ isReady: true });
  };

  render() {
    if (!this.state.isReady) {
      return <Loader />;
    } else {
      return <div>{`${T.translate(LocaleKeys.messages.welcomeToTheVector)}!`}</div>;
    }
  }
}

export default withRouter(HomePage);
