import React, { useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

const getSelectedOption = (option, selected) => {
  if (!selected) {
    return false;
  }

  return option.value === selected.value;
};

const DropdownWithFilter = ({
  data,
  name,
  value,
  label,
  valid,
  itemsDisabled,
  translateLabel,
  getOptionDisabled,
  showProgress,
  className,
  ...props
}) => {
  const onChange = useCallback(
    (e, selected) => {
      props.onChange({
        target: {
          name: name,
          value: selected,
        },
      });
    },
    [name, props]
  );

  const getOptionLabel = (option) => {
    const rawLabel = option.label;

    return translateLabel ? T.translate(rawLabel) : rawLabel;
  };

  return (
    <Autocomplete
      name={name}
      options={data}
      getOptionLabel={getOptionLabel}
      value={value}
      className={className}
      renderInput={(params) => {
        if ((!data || data.length < 1) && showProgress) {
          return <CircularProgress />;
        }

        return (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth={props.fullWidth}
            error={valid === false}
            helperText={valid === false && T.translate(LocaleKeys.validation[props.validationerrorlocalekeys])}
          />
        );
      }}
      onChange={onChange}
      getOptionSelected={getSelectedOption}
      getOptionDisabled={getOptionDisabled}
      disabled={itemsDisabled}
      noOptionsText={T.translate(LocaleKeys.labels.noOptions)}
    />
  );
};

DropdownWithFilter.propTypes = {
  data: PropTypes.array,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  valid: PropTypes.bool,
  itemsDisabled: PropTypes.bool,
  translateLabel: PropTypes.bool,
  getOptionDisabled: PropTypes.func,
  onChange: PropTypes.func,
};

export default DropdownWithFilter;
