import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ColorSquare from 'Components/inputs/ColorSquare';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import { Fab, Badge, Modal, Paper } from '@material-ui/core';
import { Close as DeleteIcon, ZoomIn } from '@material-ui/icons';
import GenericDialog from 'Components/dialogs/GenericDialog';
import { styled } from '@material-ui/core/styles';
import FileUploadWithSizeValidation from 'Components/FileUploadWithSizeValidation';
import DeviceIcon from 'Components/display/DeviceIcon';
import { useEnhancedFormContext } from 'Components/formik/formikWrappers';

const ImagePlaceholder = styled('div')({
  width: '60px',
  lineHeight: '60px',
  height: '60px',
  backgroundColor: '#f2f2f2',
  color: '#aaaaaa',
  textAlign: 'center',
  marginLeft: '35px',
  verticalAlign: 'middle',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '32px',
});

const ColorSquareStyled = styled(ColorSquare)({
  verticalAlign: 'middle',
});

const BadgeStyled = styled(Badge)({
  right: -3,
  padding: '0 4px',
  color: 'none',
});

const ZoomInIcon = styled(ZoomIn)({
  cursor: 'pointer',
});

const DeviceImage = styled(({ image, ...props }) => <div {...props} />)({
  width: 60,
  height: 60,
  verticalAlign: 'middle',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '32px',
  background: ({ image }) => `url(${image}) no-repeat center/contain`,
});

const ModalStyled = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const imageMaxSize = 1024 * 1024; // default size, 1 MB for now, later should be taken from site configurator
const iconMaxSize = 50 * 1024; // for now default max size for icon is 50 KB

const Graphics = (props) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
  const { canSaveChanges } = useEnhancedFormContext();
  const isReadOnly = !canSaveChanges;

  const onChange = (file, name) => {
    if (file === null) {
      props.onChange({
        target: {
          name: 'image',
          value: null,
        },
      });

      props.onChange({
        target: {
          name: 'imageId',
          value: 0,
        },
      });

      return;
    }

    toBase64(file).then((image) => {
      props.onChange({
        target: {
          name: name,
          value: {
            name: file.name,
            value: image,
            type: 1,
            mimeType: file.type,
          },
        },
      });
    });

    props.onChange({
      target: { name: name === 'icon' ? 'resourceId' : 'imageId', value: 0 },
    });
  };

  return (
    <>
      <ColumnDisplayer>
        <FormControlLabel
          control={<ColorSquareStyled color={props.data.color || '#000000'} format="hex" readOnly={isReadOnly} />}
          label={T.translate(LocaleKeys.labels.color)}
          name="color"
          onChange={props.onChange}
        />
        <div>
          {props.data.icon || props.data.resourceId ? (
            <>
              <DeviceIcon iconId={props.data.resourceId} icon={props.data.icon} />
              <Fab
                onClick={() => {
                  props.onChange({
                    target: { name: 'icon', value: null },
                  });
                  props.onChange({
                    target: { name: 'resourceId', value: 0 },
                  });
                }}
                disabled={isReadOnly}
              >
                <DeleteIcon />
              </Fab>
            </>
          ) : (
            <>
              <DeviceIcon color={props.data.color} />
              <FileUploadWithSizeValidation
                id="icon-upload"
                label={T.translate(LocaleKeys.labels.uploadIcon)}
                accept="image/png, image/jpg, image/gif, image/jpeg"
                extensions="(JPG, JPEG, GIF, PNG)"
                maxSize={iconMaxSize}
                onChange={(file) => {
                  onChange(file, 'icon');
                }}
                readOnly={isReadOnly}
              />
            </>
          )}
        </div>
        <div>
          {!!props.data.image && (
            <BadgeStyled
              onClick={() => {
                setImagePreviewOpen(true);
              }}
              badgeContent={<ZoomInIcon />}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <DeviceImage image={props.data.image.value} />
            </BadgeStyled>
          )}
          {!props.data.image && <ImagePlaceholder>{T.translate(LocaleKeys.labels.load)}</ImagePlaceholder>}
          {!props.data.image && (
            <FileUploadWithSizeValidation
              id="image-upload"
              maxSize={imageMaxSize}
              label={T.translate(LocaleKeys.labels.uploadImage)}
              name="image-upload"
              accept="image/png, image/jpg, image/gif, image/jpeg"
              extensions="(JPG, JPEG, GIF, PNG)"
              onChange={(file) => {
                onChange(file, 'image');
              }}
              readOnly={isReadOnly}
            />
          )}
          {!!props.data.image && (
            <Fab
              onClick={() => {
                props.onChange({
                  target: { name: 'image', value: null },
                });
                props.onChange({
                  target: { name: 'imageId', value: 0 },
                });
              }}
              disabled={isReadOnly}
            >
              <DeleteIcon />
            </Fab>
          )}
        </div>
      </ColumnDisplayer>
      <GenericDialog
        dialogOpen={confirmationDialogOpen}
        handleOk={() => {
          setConfirmationDialogOpen(false);
        }}
        title={T.translate(LocaleKeys.messages.maximumImageSizeExceeded)}
        message={T.translate(LocaleKeys.messages.maximumImageSizeIs1Mb)}
        okLabel={T.translate(LocaleKeys.labels.ok)}
      />
      <ModalStyled
        open={imagePreviewOpen}
        onClose={() => {
          setImagePreviewOpen(false);
        }}
      >
        {/* Using <Paper> because some pictures can be transparrent */}
        <Paper>
          <img src={props.data.image?.value} alt="" />
        </Paper>
      </ModalStyled>
    </>
  );
};

Graphics.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
};

export default Graphics;
