import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import CategoryPreviewPanel from 'Components/buttonLibrary/CategoryPreviewPanel';
import { types } from './reducer/buttonLibraryReducer';
import DraggableActionButton from './DraggableButton';

const Container = styled(Box)(({ height }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: '1',
  height,
  overflow: 'auto',
  margin: '5px',
}));

const ButtonLibraryCategories = ({ buttonLibrary, dispatch, onSelectButton, containerHeight, isReadOnly }) => {
  const onAddButton = (categoryTempId) => {
    dispatch({ type: types.CREATE_BUTTON, categoryTempId });
  };

  const onCategoryChange = (event, categoryTempId) => {
    dispatch({ type: types.CHANGE_CATEGORY, key: event.target.name, value: event.target.value, categoryTempId });
  };

  const onDeleteCategory = (categoryTempId) => {
    dispatch({ type: types.DELETE_CATEGORY, categoryTempId });
  };

  const onDeleteCategoryAndMoveButtons = (categoryTempId, targetCategoryTempId) => {
    dispatch({ type: types.DELETE_CATEGORY_AND_MOVE_BUTTONS, categoryTempId, targetCategoryTempId });
  };

  const onMoveButton = (dragIndex, dropIndex, categoryTempId) => {
    dispatch({ type: types.MOVE_BUTTON, dragIndex, dropIndex, categoryTempId });
  };

  const onMoveToCategory = (currentCategoryTempId, targetCategoryTempId, dragIndex) => {
    dispatch({ type: types.MOVE_TO_CATEGORY, currentCategoryTempId, targetCategoryTempId, dragIndex });
  };

  const categoryList =
    buttonLibrary && buttonLibrary.categories.map((category) => ({ tempId: category.tempId, name: category.name }));

  return (
    <Container height={containerHeight}>
      {buttonLibrary &&
        buttonLibrary.categories &&
        buttonLibrary.categories.map((category) => (
          <CategoryPreviewPanel
            key={category.tempId}
            category={category}
            onChange={(event) => onCategoryChange(event, category.tempId)}
            onAddButton={() => onAddButton(category.tempId)}
            onDeleteCategory={() => onDeleteCategory(category.tempId)}
            onDeleteCategoryAndMoveButtons={onDeleteCategoryAndMoveButtons}
            onMoveToCategory={onMoveToCategory}
            categories={categoryList}
            isReadOnly={isReadOnly}
          >
            {category.buttons &&
              category.buttons
                .sort((a, b) => a.order - b.order)
                .map((button, index) => (
                  <DraggableActionButton
                    key={button.tempId}
                    button={button}
                    onClick={() => onSelectButton(button)}
                    onMoveButton={onMoveButton}
                    index={index}
                    isReadOnly={isReadOnly}
                  />
                ))}
          </CategoryPreviewPanel>
        ))}
    </Container>
  );
};

ButtonLibraryCategories.defaultProps = {
  containerHeight: 'calc(100vh - 325px)',
  isReadOnly: false,
};

ButtonLibraryCategories.propTypes = {
  buttonLibrary: PropTypes.object.isRequired,
  onSelectButton: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  containerHeight: PropTypes.string,
  isReadOnly: PropTypes.bool,
};

export default ButtonLibraryCategories;
