import { postButtonLibrary } from 'Api/devices';
import { removeProperty, clone } from 'Helpers/ObjectHelper';
import { format } from 'date-fns';

export const addTimeStampSuffix = (text) => {
  const timeStamp = format(new Date(), 'ddMMyyyy hh:mm:ss');

  return `${text}_${timeStamp}`;
};

export const copyButtonLibraryFromTemplate = async (deviceButtons, config = {}) => {
  const newButtonLibrary = clone(deviceButtons);

  removeProperty(newButtonLibrary, 'id');
  removeProperty(newButtonLibrary, 'externalId');
  removeProperty(newButtonLibrary, 'buttonCategoryId');
  newButtonLibrary.templateId = deviceButtons.id;
  newButtonLibrary.isTemplate = false;

  return postButtonLibrary(newButtonLibrary, config);
};
