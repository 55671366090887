import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import AuthManager from 'Auth/AuthManager';
import PropTypes from 'prop-types';
import Loader from 'Components/Loader';

export default class Callback extends Component {
  componentDidMount = async () => {
    await AuthManager.loginCallbackHandler(this.props.history).catch((error) => {
      const message = error.toString();

      Sentry.captureMessage(`Error during login process: ${message}`);
      this.props.history.push('/');
    });
  };

  render() {
    return <Loader />;
  }
}

Callback.propTypes = {
  history: PropTypes.object,
};
