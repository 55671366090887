import ApiClient from './ApiClient';

const apiClient = new ApiClient();
const SERVICE_PATH = '/notificationstore/api/v1/';
const TIMEOUT = 90 * 1000;

const get = (path, params, config = {}) => apiClient.get(SERVICE_PATH + path, { params, timeout: TIMEOUT, ...config });
const put = (path, data) => apiClient.put(SERVICE_PATH + path, data, { timeout: TIMEOUT });
const post = (path, data) => apiClient.post(SERVICE_PATH + path, data, { timeout: TIMEOUT });

// Alarms
export const getAlarmLastByAssetId = (assetId, params, config) =>
  get(`alarms/lastbyassetid/${assetId}`, params, config);
export const getAlarmsHistory = (params, config) => get('alarms/history', params, config);
export const getAlarmsHistoryThreatboard = (config) => get('alarms/history/threatboard', null, config);
export const getAlarmsNotRead = (params, config) => get('alarms/notread', params, config);
export const getAlarmsNotReadCount = (params, config) => get('alarms/notread/count', params, config);
export const putAlarmsMarkManyAsRead = (uniqueIds) => put('alarms/markedmanyasread', uniqueIds);
export const putAlarmsMarkAllAsRead = (params) => put('alarms/markedselectedasread', params);

// Commands
export const postCommandUrl = (command) => post('commands/url', command);

// Systems
export const getSystemNotifications = (params) => get('systems', params);
export const getSystemNotificationsCount = (params) => get('systems/count', params);
export const putSystemNotificationMarkAsRead = (uniqueId) => put(`systems/${uniqueId}/markasread`);
