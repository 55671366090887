import React, { useState, useEffect, useRef } from 'react';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { compose } from 'redux';
import { styled } from '@material-ui/core';
import Button from 'Components/Button';
import LocaleKeys from 'Localization/LocaleKeys';
import { putUserRoles, getUser as getPolicyUser } from 'Api/policyServer';
import UserInformation from './tabs/UserInformation';

import { postUser, getUser, postActiveAccount } from 'Api/users';
import { Users } from 'routes/Routes';
import { clone } from 'Helpers/ObjectHelper';
import { Formik } from 'formik';
import { Permissions } from 'Constants/permissions';
import withPermissions from 'hocs/withPermissions';
import Tabs from 'Components/display/tabs/Tabs';
import { userValidation } from './UserValidation';
import Loader from 'Components/Loader';
import { getDevicesGroupsByLogin, putDevicesGroupsSubscribeToUser } from 'Api/devices';
import SaveOnLeave from 'Components/dialogs/SaveOnLeave';

const ButtonStyled = styled(Button)({
  float: 'right',
  marginLeft: '10px',
});

const ActionsPanel = styled('div')({
  textAlign: 'right',
  marginBottom: '10px',
  marginTop: '10px',
});

const initialUserData = {
  cards: [],
  emails: [],
  id: undefined,
  imageId: undefined,
  lastActive: undefined,
  login: undefined,
  person: undefined,
  phones: [],
  refUserId: undefined,
  tenantId: undefined,
  userStatus: undefined,
};

const New = (props) => {
  const [user, setUser] = useState();
  const [userRoles, setUserRoles] = useState();
  const [selectedDevicesGroups, setSelectedDeviceGroups] = useState(null);
  const validationCache = useRef({
    login: null,
    defaultEmail: null,
  });

  useEffect(() => {
    const copyPropsFromUserId = async (userId) => {
      try {
        const copiedUser = await getUser(userId);
        const newUser = {
          ...copiedUser.data,
          cards: [],
          emails: [],
          id: undefined,
          imageId: undefined,
          lastActive: undefined,
          login: undefined,
          person: undefined,
          phones: [],
          refUserId: undefined,
          tenantId: undefined,
          userStatus: undefined,
        };

        setUser(newUser);

        if (props.permissions[Permissions.CanReadUserRoles]) {
          const userRoles = await getPolicyUser(copiedUser.data.login);

          setUserRoles(userRoles.data.roles.map((role) => role.code));
        }

        if (props.permissions[Permissions.CanReadDevicesGroups]) {
          const userDevicesGroupsResponse = await getDevicesGroupsByLogin(copiedUser.data.login);

          setSelectedDeviceGroups(userDevicesGroupsResponse.data.devicesGroups);
        }
      } catch {
        Sentry.captureMessage('error while copying user data');
      }
    };

    const baseOnUserId = props.history.location?.state?.baseOnUserId;

    if (baseOnUserId) {
      copyPropsFromUserId(baseOnUserId);
    } else {
      setUser(clone(initialUserData));
    }
  }, [props.history.location, props.permissions]);

  const saveUserInfo = async (values) => {
    const copyRoles = props.history.location?.state?.baseOnUserId;

    const createdUser = await postUser(values);

    if (copyRoles && props.permissions[Permissions.CanEditUserRoles]) {
      await putUserRoles(createdUser.data.login, userRoles);
    }
    if (selectedDevicesGroups && props.permissions[Permissions.CanEditDevicesGroup]) {
      await putDevicesGroupsSubscribeToUser(createdUser.data.login, selectedDevicesGroups);
    }
  };

  const redirect = () => {
    const url = Users.getUrl();

    props.history.push(url);
  };

  const onSaveClick = async (values, setSubmitting) => {
    setSubmitting(true);
    saveUserInfo(values)
      .then(() => {
        redirect();
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  const onCancelClick = () => {
    redirect();
  };

  const onSaveAndActiveClick = async (values, setSubmitting) => {
    setSubmitting(true);
    saveUserInfo(values)
      .then(async () => {
        await postActiveAccount(values.login);
        setSubmitting(false);
        redirect();
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  if (!user) {
    return <Loader />;
  }

  return (
    <Formik
      initialValues={user}
      enableReinitialize
      validationSchema={userValidation(user, validationCache.current)}
      validateOnMount
    >
      {({ values, dirty, isValid, isValidating, isSubmitting, setSubmitting }) => (
        <>
          <Tabs label="userDetails" defaultTab={0}>
            <UserInformation label={LocaleKeys.labels.userInformation} mode={'new'} />
          </Tabs>
          <ActionsPanel>
            <ButtonStyled onClick={onCancelClick} id="new-users-cancelButton">
              {T.translate(LocaleKeys.labels.cancel)}
            </ButtonStyled>
            <ButtonStyled
              disabled={!(dirty && isValid) || isSubmitting || isValidating}
              onClick={() => onSaveClick(values, setSubmitting)}
              id="new-users-saveButton"
            >
              {T.translate(LocaleKeys.labels.save)}
            </ButtonStyled>
            <ButtonStyled
              disabled={!(dirty && isValid) || isSubmitting || isValidating}
              onClick={() => onSaveAndActiveClick(values, setSubmitting)}
              id="new-users-saveAndActiveButton"
            >
              {T.translate(LocaleKeys.labels.saveAndActivate)}
            </ButtonStyled>
          </ActionsPanel>
          <SaveOnLeave saveData={() => onSaveClick(values, setSubmitting)} dataChanged={dirty} validForm={isValid} />
        </>
      )}
    </Formik>
  );
};

New.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  permissions: PropTypes.object,
};

export default compose(
  withPermissions([Permissions.CanAccessUsersPage, Permissions.CanReadUsers, Permissions.CanAddUsers])
)(New);
