import { LocalStorageKeys } from 'Constants/LocalStorageKeys';
import ApiClient from './ApiClient';

const apiClient = new ApiClient();
const SERVICE_PATH = '/mapprovider/api/';
const DEFAULT_PROVIDER_NAME = 'here';

const get = (path, params) => apiClient.get(SERVICE_PATH + path, { params });
const post = (path, data) => apiClient.post(SERVICE_PATH + path, data);
const put = (path, data) => apiClient.put(SERVICE_PATH + path, data);
const deleteRequest = (path) => apiClient.delete(SERVICE_PATH + path);

// Areas
export const getMapAreas = () => get(`areas`);
export const getMapAreasInfo = (params) => get(`areas/info`, params);
export const postMapArea = (data) => post(`areas`, data);
export const getMapAreaByName = (name) => get(`areas/getmaparea/${name}`);
export const getMapArea = (id) => get(`areas/${id}`);
export const putMapArea = (id, data) => put(`areas/${id}`, data);
export const deleteMapArea = (id) => deleteRequest(`areas/${id}`);
export const postMapAreaCopy = (id, sites) => post(`areas/copymaparea?mapAreaId=${id}`, sites);
export const getMapAreaExistsByName = (mapAreaName) => get(`areas/name/${mapAreaName}/exists`);

// Maps
export const getMapGeocoding = (searchBy, providerName = DEFAULT_PROVIDER_NAME) =>
  get(`maps/geocoding`, {
    SearchBy: searchBy,
    ProviderName: providerName,
  });

// System Mappings
export const getMapAreasSystemMappings = () => get(`systemmappings/mapareas`);

// not the newest here API, temporary use 'getReverseGeocodeHERE' instead
export const getMapReverseGeocoding = (latitude, longitude, providerName = DEFAULT_PROVIDER_NAME) =>
  get(`maps/reversegeocoding`, {
    Latitude: latitude,
    Longitude: longitude,
    ProviderName: providerName,
  });
export const getMapRoute = (
  waypoints,
  alternativesNumber,
  language,
  findBestWay,
  providerName = DEFAULT_PROVIDER_NAME
) =>
  get(`maps/route`, {
    Waypoints: waypoints,
    AlternativesNumber: alternativesNumber,
    Language: language,
    FindBestWay: findBestWay,
    ProviderName: providerName,
  });
export const getMapSearchSuggestions = (query, providerName = DEFAULT_PROVIDER_NAME) =>
  get(`maps/searchplace`, {
    Query: query,
    ProviderName: providerName,
  });
export const getMapAccessData = (providerName = DEFAULT_PROVIDER_NAME) => get(`maps/accessData/${providerName}`);

// Providers
export const getProviders = () => get(`providers`);
export const getReverseGeocodingTypes = () => get(`providers/reversegeocodingtype`);

// HERE API
function chunkArray(myArray, chunkSize) {
  const results = [];

  while (myArray.length) {
    results.push(myArray.splice(0, chunkSize));
  }

  return results;
}

export const getBatchReverseGeocodeHERE = async (coords, mapToken) => {
  const multiReverseGeocodeBaseURL = 'https://reverse.geocoder.ls.hereapi.com/6.2/multi-reversegeocode.json';
  const query = {
    mode: 'retrieveAddresses',
    gen: '9',
    language: localStorage.getItem(LocalStorageKeys.USER_LANGUAGE) || 'en',
    addressattributes: 'aln,ctr,sta,cty,cit,dis,sdi,str,hnr,pst,add',
  };
  const maxAddressess = 300;
  const batches = Math.ceil(coords.length / maxAddressess);
  const coordArray = chunkArray(coords, maxAddressess);
  const results = [];

  for (let i = 0; i < batches; i++) {
    const res = await (
      await fetch(`${multiReverseGeocodeBaseURL}?${new URLSearchParams(query).toString()}&${mapToken}`, {
        method: 'POST',
        body: coordArray[i].join('\n'),
      })
    ).json();

    if (res.Response) {
      results.push(...res.Response.Item);
    }
  }

  return results;
};

export const getReverseGeocodeHERE = async (lat, long, mapToken) => {
  const reverseGeocodeBaseURL = `https://revgeocode.search.hereapi.com/v1/revgeocode`;
  const reverseGeocodeURL = (query, mapToken) =>
    `${reverseGeocodeBaseURL}?${new URLSearchParams(query).toString()}&${mapToken}`;
  const radius = 50;
  const query = {
    lang: localStorage.getItem(LocalStorageKeys.USER_LANGUAGE) || 'en',
    in: `circle:${lat},${long};r=${radius}`,
  };

  const res = await (
    await fetch(reverseGeocodeURL(query, mapToken), {
      method: 'GET',
    })
  ).json();

  return Promise.resolve(res);
};
