import React, { useState, useEffect } from 'react';
import {
  Box,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { ExpandMore } from '@material-ui/icons';
import { Link, withRouter } from 'react-router-dom';
import { NewUserRoles, UserRolesDetails } from 'routes/Routes';
import { styled } from '@material-ui/core/styles';
import { getRoles } from 'Api/policyServer';
import { Permissions } from 'Constants/permissions';
import { PermissionGroups } from 'Constants/PermissionGroups';
import { compose } from 'redux';
import withPermissions from 'hocs/withPermissions';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import Search from './Search';

const NewTemplateLink = styled(Link)({
  flex: 1,
  textAlign: 'center',
  padding: '16px 0',
  textTransform: 'uppercase',
});

const groupPermissions = (permissions) => {
  const groups = {};
  const codes = permissions.map((p) => p.code);

  Object.keys(PermissionGroups).forEach((key) => {
    groups[key] = PermissionGroups[key].filter((code) => codes.includes(code));
  });

  return groups;
};

const Roles = (props) => {
  const [roles, setRoles] = useState(null);
  const [rolesSelected, setRolesSelected] = useState([]);
  const [rolesFilter, setRolesFilter] = useState('');
  const [templateFilter, setTemplateFilter] = useState('');

  useEffect(() => {
    getRoles().then(({ data }) => {
      setRoles(data);
    });
  }, []);

  return (
    <>
      <Box display="flex">
        <Box flexGrow={1} p={2}>
          <Box mb={2}>
            <Search
              label={T.translate(LocaleKeys.labels.userRoles)}
              value={rolesFilter}
              onChange={(e) => {
                setRolesFilter(e.target.value);
              }}
            />
          </Box>
          {roles &&
            props.userRoles &&
            roles
              .filter((r) => props.userRoles.includes(r.code))
              .filter((r) => r.name.toLowerCase().includes(rolesFilter.toLowerCase()))
              .map((role) => {
                const groupedPermissions = groupPermissions(role.permissions);
                const groups = Object.keys(groupedPermissions);

                return (
                  <ExpansionPanel key={role.code}>
                    <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                      <Typography>{role.name}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Box flexGrow={1}>
                        {groups
                          .filter((group) => groupedPermissions[group].length)
                          .map((group) => (
                            <div key={group}>
                              <Typography variant="h6">{T.translate(group)}</Typography>
                              {groupedPermissions[group].map((permission) => (
                                <div key={permission}>
                                  <strong>{T.translate(permission)}</strong> ({T.translate(`${permission}_Description`)}
                                  )
                                </div>
                              ))}
                            </div>
                          ))}

                        {props.permissions[Permissions.CanEditUserRoles] && (
                          <Box textAlign="right" mt={2}>
                            <Button
                              onClick={() => {
                                props.setUserRoles(props.userRoles.filter((r) => r !== role.code));
                              }}
                            >
                              {T.translate(LocaleKeys.labels.delete)}
                            </Button>
                            <Link to={UserRolesDetails.getUrl({ name: role.name })}>
                              <Button>{T.translate(LocaleKeys.labels.edit)}</Button>
                            </Link>
                          </Box>
                        )}
                      </Box>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                );
              })}
        </Box>
        <Box flexGrow={1} p={2} maxWidth={500}>
          <Box display="flex" mb={2}>
            <Search
              label="TEMPLATES"
              value={templateFilter}
              onChange={(e) => {
                setTemplateFilter(e.target.value);
              }}
            />
            {props.permissions[Permissions.CanAddUserRoles] && (
              <NewTemplateLink to={NewUserRoles.getUrl()}>{T.translate('createNewTemplate')}</NewTemplateLink>
            )}
          </Box>
          <Table size="small">
            <TableBody>
              {roles &&
                roles
                  .filter((r) => r.name.toLowerCase().includes(templateFilter.toLowerCase()))
                  .map((role) => (
                    <TableRow key={role.code}>
                      <TableCell>
                        <Checkbox
                          checked={rolesSelected.includes(role.code)}
                          onChange={() => {
                            const index = rolesSelected.indexOf(role.code);

                            if (index === -1) {
                              setRolesSelected([...rolesSelected, role.code]);
                            } else {
                              setRolesSelected(rolesSelected.filter((r) => r !== role.code));
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell>{role.name}</TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
          <Box mt={2}>
            {props.permissions[Permissions.CanEditUserRoles] && (
              <Button
                onClick={() => {
                  const newRoles = Array.from(new Set([...props.userRoles, ...rolesSelected].flat()));

                  props.setUserRoles(newRoles);
                  setRolesSelected([]);
                }}
              >
                {T.translate('assignRoleToUser')}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default compose(withPermissions([Permissions.CanReadUserRoles]), withRouter)(Roles);
