import React, { forwardRef } from 'react';
import { withRouter } from 'react-router-dom';

const withRouterAndRef = (WrappedComponent) => {
  const InnerComponentWithRef = ({ forwardRef, ...rest }) => {
    return <WrappedComponent {...rest} ref={forwardRef} />;
  };
  const ComponentWithRouter = withRouter(InnerComponentWithRef, { withRef: true });
  const ComponentWithRouterAndRef = forwardRef((props, ref) => <ComponentWithRouter {...props} forwardRef={ref} />);

  ComponentWithRouterAndRef.displayName = WrappedComponent.displayName;

  return ComponentWithRouterAndRef;
};

export default withRouterAndRef;
