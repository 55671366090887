const escape = (text) => text.replace(/([,|])/g, '\\$1');

export const operators = {
  EQUALS: '==',
  NOT_EQUALS: '!=',
  GREATER_THAN: '>',
  LESS_THAN: '<',
  GREATER_THAN_OR_EQUAL_TO: '>=',
  LESS_THAN_OR_EQUAL_TO: '<=',
  CONTAINS: '@=',
  STARTS_WITH: '_=',
  DOES_NOT_CONTAINS: '!@=',
  DOES_NOT_STARTS_WITH: '!_=',
  CASE_INSENSITIVE_CONTAINS: '@=*',
  CASE_INSENSITIVE_STARTS_WITH: '_=*',
  CASE_INSENSITIVE_EQUALS: '==*',
  CASE_INSENSITIVE_NOT_EQUALS: '!=*',
  CASE_INSENSITIVE_DOES_NOT_CONTAINS: '!@=*',
  CASE_INSENSITIVE_DOES_NOT_STARTS_WITH: '!_=*',
};
const defaultOperator = operators.CASE_INSENSITIVE_CONTAINS;

// Filters model can be found at https://github.com/Biarity/Sieve
export const getSieveParams = (page, pageSize, sorts, filters, search, tenantId) => {
  const filtersEntries = Object.entries(filters);

  if (search) {
    filtersEntries.push([
      'search',
      {
        value: search,
        operator: defaultOperator,
      },
    ]);
  }

  const filtersArray = filtersEntries.map(
    ([key, { value, operator }]) =>
      `${key}${operator || defaultOperator}${
        Array.isArray(value) ? value.map((v) => escape(v)).join('|') : escape(value)
      }`
  );
  const filtersString = filtersArray.join(',');

  return { page, pageSize, sorts, filters: filtersString, tenantId };
};
