import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LabelsLocaleKeys from 'Localization/LabelsLocaleKeys';
import LocaleKeys from 'Localization/LocaleKeys';
import {
  Grid,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  styled,
  Divider,
  Card,
  CardHeader,
  FormHelperText,
  FormControl,
} from '@material-ui/core';
import TextInput from './TextInput';

const not = (a, b) => {
  return a.filter((value) => b.indexOf(value) === -1);
};

const intersection = (a, b) => {
  return a.filter((value) => b.indexOf(value) !== -1);
};

const union = (a, b) => {
  return [...a, ...not(b, a)];
};

const FormControlStyled = styled(FormControl)({
  maxHeight: '100%',
});
const CardStyled = styled(Card)(({ valid }) => ({
  maxHeight: '100%',
  minWidth: '300px',
  overflow: 'auto',
  border: valid ? 'none' : '1px solid red',
}));
const FormHelperTextStyled = styled(FormHelperText)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}));
const GridItem = styled(Grid)({
  maxHeight: '100%',
});
const GridItemCentered = styled(Grid)({
  alignSelf: 'center',
});

const CustomList = ({
  items,
  title,
  valid,
  filterText,
  setFilterText,
  displayField,
  valueField,
  readOnly,
  disabled,
  numberOfChecked,
  checked,
  filter = false,
  ...props
}) => {
  const filterItems = (item) => {
    if (!filterText || filterText === '') {
      return true;
    }

    return item[displayField].toLowerCase().includes(filterText.toLowerCase());
  };

  return (
    <FormControlStyled error={!valid}>
      <CardStyled valid={valid}>
        <CardHeader
          avatar={
            <Checkbox
              onClick={props.handleToggleAll(items)}
              checked={!!items.length && numberOfChecked(items) === items.length}
              indeterminate={!!numberOfChecked(items) && numberOfChecked(items) !== items.length}
              disabled={items.length === 0 || props.readOnly || props.disabled}
              inputProps={{ 'aria-label': 'all items selected' }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${items.length} ${T.translate(LocaleKeys.labels.selected)}`}
        />
        <FormHelperTextStyled>
          {!valid && T.translate(LocaleKeys.validation[props.validationerrorlocalekeys])}
        </FormHelperTextStyled>
        {filter && (
          <TextInput
            fullWidth
            label={T.translate(LabelsLocaleKeys.search)}
            value={filterText}
            onChange={(e) => {
              setFilterText(e.target.value);
            }}
          />
        )}
        <Divider />
        <List dense component="div" role="list">
          {items
            .filter(filterItems)
            .sort((a, b) => a.disabled - b.disabled || a[displayField] - b[displayField])
            .map((item) => {
              const value = item[valueField];
              const label = item[displayField];
              const labelId = `transfer-list-item-${value}-label`;

              return (
                <ListItem
                  key={value}
                  role="listitem"
                  button
                  onClick={props.handleToggle(item)}
                  disabled={item.disabled || readOnly || disabled}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(item) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={label} />
                </ListItem>
              );
            })}
          <ListItem />
        </List>
      </CardStyled>
    </FormControlStyled>
  );
};

const TransferList = ({ options, value, onChange, name, valueField, displayField, readOnly, disabled, ...props }) => {
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState(options.filter((o) => !value.includes(o[valueField])));
  const [right, setRight] = useState(options.filter((o) => value.includes(o[valueField])));
  const [leftFilter, setLeftFilter] = useState('');

  useEffect(() => {
    setLeft(options.filter((o) => !value.includes(o[valueField])));
    setRight(options.filter((o) => value.includes(o[valueField])));
  }, [options, value, valueField]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    const enabledItems = items.filter((item) => !item.disabled);

    if (numberOfChecked(enabledItems) === enabledItems.length) {
      setChecked(not(checked, enabledItems));
    } else {
      setChecked(union(checked, enabledItems));
    }
  };

  const handleCheckedRight = () => {
    const newRight = right.concat(leftChecked);
    const value = newRight.map((item) => item[valueField]);

    onChange({ target: { name, value } });
    setRight(newRight);
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    const newRight = not(right, rightChecked);
    const value = newRight.map((item) => item[valueField]);

    onChange({ target: { name, value } });
    setLeft(left.concat(rightChecked));
    setRight(newRight);
    setChecked(not(checked, rightChecked));
  };

  const isValid = props.valid === 'true';
  const customListProps = {
    displayField,
    valueField,
    handleToggle,
    readOnly,
    disabled,
    numberOfChecked,
    checked,
    handleToggleAll,
  };

  return (
    <Grid container spacing={2} justify="center">
      <GridItem item>
        <CustomList
          items={left}
          title={props.optionsLabel}
          valid
          filter
          filterText={leftFilter}
          setFilterText={setLeftFilter}
          {...customListProps}
        />
      </GridItem>
      <GridItemCentered item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </GridItemCentered>
      <GridItem item>
        <CustomList
          items={right}
          title={props.valueLabel}
          valid={isValid}
          validationerrorlocalekeys={props.validationerrorlocalekeys}
          {...customListProps}
        />
      </GridItem>
    </Grid>
  );
};

TransferList.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  optionsLabel: PropTypes.string,
  valueLabel: PropTypes.string,
  valueField: PropTypes.string,
  displayField: PropTypes.string,
  valid: PropTypes.string,
  validationerrorlocalekeys: PropTypes.string,
};

TransferList.defaultProps = {
  options: [],
  value: [],
  onChange: () => {},
  optionsLabel: 'Choices',
  valueLabel: 'Chosen',
  valueField: null,
  disabled: null,
  valid: 'true',
};

export default TransferList;
