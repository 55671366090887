import { Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

export const Content = styled(Box)({
  width: 400,
  overflow: 'hidden',
  position: 'relative',
  lineHeight: '1.5em',
  maxHeight: '4.5em',
  textAlign: 'justify',
  marginRight: '-1em',
  paddingRight: '1em',
  marginBottom: '0.5em',
  overflowWrap: 'break-word',
  '&&:before': {
    content: '"..."',
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  '&&:after': {
    content: '""',
    position: 'absolute',
    right: 0,
    width: '1em',
    height: '1em',
    marginTop: '0.2em',
    backgroundColor: ({ bgcolor }) => bgcolor,
  },
});

export default Content;
