import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'Components/display/table/Table';
import { CircularProgress } from '@material-ui/core';
import { getButtonLibrary, deleteButtonLibrary } from 'Api/devices';
import DeleteIcon from '@material-ui/icons/Delete';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import GenericDialog from 'Components/dialogs/GenericDialog';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import TableActionButton from 'Components/display/table/TableActionButton';
import { addTimeStampSuffix } from '../../../helpers';
import { RequestConfigContext } from '../../requestConfigContext';

const formatDataForTableView = (libraries) =>
  Object.values(libraries).map((library) => ({
    id: library.id,
    libraryName: library.name,
    isActive: !library.isTemplate,
    isTemplate: library.isTemplate,
    author: library.author,
  }));

const ButtonLibrariesTable = ({ buttonLibraries, setButtonLibraries, permissions, isReadOnly, ...props }) => {
  const [buttonLibraryIdToHandle, setButtonLibraryIdToHandle] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const requestConfig = useContext(RequestConfigContext);

  const onEditButtonLibrary = (selectedButtonLibraryId) => {
    if (selectedButtonLibraryId && !isReadOnly) {
      getButtonLibrary(selectedButtonLibraryId, requestConfig).then((response) => {
        const buttonLibrary = response.data;

        if (buttonLibrary.isTemplate) {
          buttonLibrary.name = addTimeStampSuffix(buttonLibrary.name);
        }
        props.onSelectLibrary(buttonLibrary);
      });
    }
  };

  if (!buttonLibraries) {
    return <CircularProgress />;
  }

  const formattedTableData = formatDataForTableView(buttonLibraries);

  const assignItemButton = (
    <TableActionButton
      key="assignItemButton"
      onClick={onEditButtonLibrary}
      clickparam={'id'}
      icon={<AssignmentReturnedIcon />}
      tooltipText={T.translate(LocaleKeys.tooltips.assignItem)}
      disabled={isReadOnly}
    />
  );

  const deleteItemButton = (
    <TableActionButton
      key="deleteItemButton"
      onClick={(id) => {
        setDeleteDialogOpen(true);
        setButtonLibraryIdToHandle(id);
      }}
      clickparam={'id'}
      icon={<DeleteIcon />}
      tooltipText={T.translate(LocaleKeys.tooltips.deleteItem)}
      hiddenwhenparam={'isTemplate'}
      disabled={isReadOnly}
    />
  );

  const actionButtons = [];

  if (permissions[Permissions.CanRemoveButtonsLibrary]) {
    actionButtons.push(deleteItemButton);
  }
  if (permissions[Permissions.CanEditDevices]) {
    actionButtons.push(assignItemButton);
  }

  return (
    <>
      <Table
        data={formattedTableData}
        onRowDoubleClick={onEditButtonLibrary}
        redirect={'id'}
        hideColumns={['id', 'isTemplate']}
        actionButtons={actionButtons}
      />
      <GenericDialog
        dialogOpen={deleteDialogOpen}
        handleOk={() => {
          deleteButtonLibrary(buttonLibraryIdToHandle, requestConfig).then(() => {
            const updatedLibraries = buttonLibraries.filter((library) => library.id !== buttonLibraryIdToHandle);

            props.onSelectLibrary(null);
            setButtonLibraries(updatedLibraries);
            setDeleteDialogOpen(false);
          });
        }}
        handleCancel={() => {
          setButtonLibraryIdToHandle(null);
          setDeleteDialogOpen(false);
        }}
        title={T.translate(LocaleKeys.messages.deleteButtonsLibraryDialogTitle)}
        message={T.translate(LocaleKeys.messages.deleteButtonsLibraryDialogMessage)}
      />
    </>
  );
};

ButtonLibrariesTable.propTypes = {
  buttonLibraries: PropTypes.array,
  setButtonLibraries: PropTypes.func.isRequired,
  onSelectLibrary: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  permissions: PropTypes.object,
};

ButtonLibrariesTable.defaultProps = {
  buttonLibraries: null,
};

export default withPermissions([])(ButtonLibrariesTable);
