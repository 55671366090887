export const isObject = (item) => {
  return item && typeof item === 'object' && !Array.isArray(item);
};

export const deepMergeObjects = (target, ...sources) => {
  if (!sources.length) {
    return target;
  }
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }
        deepMergeObjects(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return deepMergeObjects(target, ...sources);
};

export const createNestedObject = (objectPath, value) => {
  let object = {};

  if (objectPath.length) {
    object[objectPath[objectPath.length - 1]] = value;

    for (let i = objectPath.length - 2; i >= 0; i--) {
      const temp = object;

      object = {};
      object[objectPath[i]] = temp;
    }
  }

  return object;
};

export const compareObjects = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const clone = (obj) => {
  let copy;

  if (null == obj || 'object' != typeof obj) {
    return obj;
  }

  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());

    return copy;
  }

  if (obj instanceof Array) {
    copy = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = clone(obj[i]);
    }

    return copy;
  }

  if (obj instanceof Object) {
    copy = {};
    for (const attr in obj) {
      if (obj.hasOwnProperty(attr)) {
        copy[attr] = clone(obj[attr]);
      }
    }

    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
};

export const getDataFromNestedPath = (object, path) => {
  if (path === '') {
    return object;
  } else {
    const objectPath = path.split('.');
    let data = object;

    objectPath.forEach((path) => {
      if (data) {
        data = data[path];
      }
    });

    return data;
  }
};

export const removeProperty = (obj, propName) => {
  Object.keys(obj).forEach((key) => {
    if (key === propName) {
      delete obj[propName];
    } else if (obj[key] && typeof obj[key] === 'object') {
      removeProperty(obj[key], propName);
    }
  });
};
