import T from 'i18n-react';
import translationsEN from './translations/en.json';
import translationsDE from './translations/de.json';
import drawLocales from 'leaflet-draw-locales';
import { createMuiTheme } from '@material-ui/core/styles';
import { enUS, deDE } from '@material-ui/core/locale';
import enDateFnsLocale from 'date-fns/locale/en-US';
import deDateFnsLocale from 'date-fns/locale/de';
import { LocalStorageKeys } from 'Constants/LocalStorageKeys';
import deTimeAgoLocale from 'timeago.js/lib/lang/de';
import { register } from 'timeago.js';

const mergedDEtranslations = Object.assign({}, translationsEN, translationsDE);

const userLang = localStorage.getItem(LocalStorageKeys.USER_LANGUAGE);

if (!userLang) {
  localStorage.setItem(LocalStorageKeys.USER_LANGUAGE, 'en');
}

T.setTexts((userLang || 'en') === 'en' ? translationsEN : mergedDEtranslations);

// Automatically defines Leaflet.draw to the specified language
export const translateLeafletDraw = () => drawLocales(userLang || 'en');

// Use this theme to configure the locale text globally for all Material-UI components
export const defaultTheme = createMuiTheme((userLang || 'en') === 'en' ? enUS : deDE);

// Material-UI pickers
export const muiPickersLocale = (userLang || 'en') === 'en' ? enDateFnsLocale : deDateFnsLocale;

// Register 'de' locales to use with timeago.js
register('de', deTimeAgoLocale);
