import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const Panel = styled(Accordion)(({ margin }) => ({
  margin,
  borderRadius: '10px 10px 0 0',
  height: '100%',
  width: '100%',
}));

Panel.defaultProps = {
  margin: '0 auto',
};

const Summary = styled(AccordionSummary)({
  backgroundColor: '#3f51b5',
  color: 'white',
  borderRadius: '10px 10px 0 0',
  minHeight: '47px !important',
  maxHeight: '47px',
  '&.Mui-expanded': {
    minHeight: '47px',
  },
});

const ExpandMoreIconStyled = styled(ExpandMoreIcon)({
  color: 'white',
});

const Content = styled(({ maxHeight, ...props }) => <AccordionDetails {...props} />)(
  ({ padding, height, width, overflow, maxHeight }) => ({
    height,
    maxHeight,
    width,
    padding,
    overflow,
  })
);

Content.defaultProps = {
  padding: '10px',
  height: '100%',
  width: '100%',
  maxHeight: '100%',
};

const WidgetPanel = ({
  title,
  children,
  expandable,
  defaultExpanded,
  contentPadding,
  height,
  width,
  margin,
  overflow,
  maxHeight,
  onExpandChange,
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const setExpandChange = (expandState) => {
    setExpanded(expandState);
    onExpandChange && onExpandChange(expandState);
  };

  useEffect(() => {
    setExpanded(defaultExpanded);
  }, [defaultExpanded]);

  return (
    <Panel expanded={expanded} onChange={() => expandable && setExpandChange(!expanded)} margin={margin}>
      <Summary expandIcon={expandable && <ExpandMoreIconStyled />}>
        <Typography>{title}</Typography>
      </Summary>
      <Content padding={contentPadding} height={height} width={width} overflow={overflow} maxHeight={maxHeight}>
        {children}
      </Content>
    </Panel>
  );
};

export default WidgetPanel;

WidgetPanel.defaultProps = {
  contentPadding: '10px',
  defaultExpanded: true,
  expandable: false,
  height: '100%',
  onExpandChange: undefined,
  width: '100%',
};

WidgetPanel.propTypes = {
  contentPadding: PropTypes.string,
  defaultExpanded: PropTypes.bool,
  expandable: PropTypes.bool,
  height: PropTypes.string,
  onExpandChange: PropTypes.func,
  title: PropTypes.string.isRequired,
  width: PropTypes.string,
};
