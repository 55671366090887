import React, { Component } from 'react';
import AuthManager from 'Auth/AuthManager';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import ErrorPage from 'app/pages/ErrorPage';
import { Typography } from '@material-ui/core';

export default class SilentRefreshCallback extends Component {
  state = {
    errorMessage: null,
  };

  componentDidMount() {
    AuthManager.silentRefreshSessionCallback().catch((error) => {
      const message = error.toString();

      this.setState({ errorMessage: message });
    });
  }

  render() {
    if (this.state.errorMessage) {
      return (
        <>
          <ErrorPage code={'401'} message={T.translate(LocaleKeys.messages.errorWhileRefreshSession)} />
          <br />
          <Typography align="center" component="p">
            {this.state.errorMessage}
          </Typography>
        </>
      );
    } else {
      return <div>{T.translate(LocaleKeys.messages.loginProcess)}</div>;
    }
  }
}
