import ApiClient from './ApiClient';

const apiClient = new ApiClient();
const version = 'v1';
const SERVICE_PATH = `/usermanagement/api/${version}/`;

const get = (path, params) => apiClient.get(SERVICE_PATH + path, { params });
const post = (path, data) => apiClient.post(SERVICE_PATH + path, data);
const put = (path, data) => apiClient.put(SERVICE_PATH + path, data);
const deleteRequest = (path, data) => apiClient.delete(SERVICE_PATH + path, data);

// Users
export const getUsers = () => get('users');
export const getLoggedUserDetails = () => get('users/loggeduserdetails');
export const getUser = (id) => get(`users/${id}`);
export const getUserByLogin = (login) => get(`users/login/${login}/details`);
export const getUserExistsByLogin = (login) => get(`users/login/${login}/exists`);
export const putUser = (id, data) => put(`users/${id}`, data);
export const postUser = (data) => post(`users`, data);
export const postActiveAccount = (login) => post(`users/${login}/activateaccount`);
export const deleteUsers = (users) => deleteRequest('users', users);
export const deleteUser = (id) => deleteRequest(`users/${id}`);
export const putResetPassword = (login) => put(`users/resetPassword?login=${login}`);
export const putResendActivationLink = (login) => put(`users/resendactivationlink?login=${login}`);
export const getDefaultEmailExists = (email) => get(`users/defaultemail/${encodeURIComponent(email)}/exists`);

// Types
export const getStatusTypes = () => get('types/ustatus');
export const getPhoneTypes = () => get('types/phtype');
export const getCardTypes = () => get('types/cardtype');
