import AutoSuggest from '../../AutoSuggest';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import { getMapGeocoding, getMapSearchSuggestions, getMapRoute } from 'Api/maps';
import Paper from '@material-ui/core/Paper';
import React, { useState } from 'react';
import L from 'leaflet';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { styled } from '@material-ui/core/styles';
import T from 'i18n-react';
import LabelsLocaleKeys from 'Localization/LabelsLocaleKeys';

const StyledPaper = styled(Paper)({
  display: 'flex',
  width: '100%',
  maxWidth: '222px',
});

const Search = styled(Paper)({
  height: 36,
});

const StyledAutoSuggest = styled(StyledPaper)({
  position: 'absolute',
  zIndex: 1,
});
const StyledInput = styled(InputBase)({
  padding: 3,
});

const StyledIconButton = styled(IconButton)({
  height: 34,
  width: 34,
  flex: 1,
  padding: 0,
  top: -3,
});

const StyledAddIcon = styled(IconButton)({
  height: 36,
  width: 36,
  flex: 1,
  padding: 0,
});
const AddButton = styled(Paper)({
  width: 36,
  height: 36,
  float: 'right',
});

const RouteInput = ({ map, toggleInputType, drawnItems, routeMarkers, style }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [openSuggestion, setOpenSuggestion] = useState(-1);
  const [waypoints, setWaypoints] = useState(['', '']);

  const onChange = (event, openSuggestions = true) => {
    const id = event.target.name;

    event.target.value &&
      openSuggestions &&
      getMapSearchSuggestions(event.target.value).then((response) => {
        const suggestions = [];

        response &&
          response.data.results.forEach((result) => {
            suggestions.push(result.address);
          });
        setSuggestions(suggestions);
        setOpenSuggestion(id);
      });
    const Waypoints = [...waypoints];

    Waypoints[id] = event.target.value;
    setWaypoints(Waypoints);
  };

  const addWaypoint = () => {
    const Waypoints = [...waypoints];

    Waypoints.push('');

    setWaypoints(Waypoints);
  };

  const removeWaypoint = (id) => {
    const Waypoints = [...waypoints];

    Waypoints.splice(id, 1);
    setWaypoints(Waypoints);
  };

  const onFocus = (id) => {
    waypoints[id] && setOpenSuggestion(id);
  };

  const handleSuggestClose = () => {
    setOpenSuggestion(-1);
  };

  const suggestionClick = (value, event, id) => {
    event.preventDefault();
    onChange({ target: { name: id, value: value } }, false);
    setSuggestions([]);
    setOpenSuggestion(-1);
  };

  const getRoute = () => {
    const filteredWaypoints = waypoints.filter((waypoint) => !!waypoint);

    Promise.all(
      filteredWaypoints.map((waypoint) => {
        if (waypoint.search(/[A-z]|[-]/) !== -1) {
          return getMapGeocoding(waypoint).then(
            (response) => `${response.data.locations[0].point.latitude}, ${response.data.locations[0].point.longitude}`
          );
        } else {
          return waypoint;
        }
      })
    ).then((ConvertedWaypoints) => {
      ConvertedWaypoints.forEach((point, i) => {
        L.marker(point.split(', ')).bindPopup(waypoints[i]).addTo(routeMarkers);
      });
      !!ConvertedWaypoints.length &&
        getMapRoute(ConvertedWaypoints, 1, 'EN', 'false').then((response) => {
          const latlngs = [];

          response.data.routes[0].coordinates.forEach((coord) => {
            latlngs.push(L.latLng(coord.latitude, coord.longitude));
          });
          const options = {
            corridor: 1000,
            color: '#3388ff',
            className: 'route-corridor',
            opacity: 0.3,
          };
          const corridor = L.corridor(latlngs, options);

          drawnItems.addLayer(corridor);
          map.fitBounds(corridor.getBounds());
        });
    });
  };

  return (
    <div style={style}>
      {waypoints.map((waypoint, id) => (
        <React.Fragment key={id}>
          <Search elevation={1}>
            <StyledInput
              placeholder={
                id === 0
                  ? T.translate(LabelsLocaleKeys.from)
                  : id === waypoints.length - 1
                  ? T.translate(LabelsLocaleKeys.to)
                  : ''
              }
              onChange={onChange}
              value={waypoint}
              name={id.toString()}
              onFocus={() => onFocus(id)}
              onBlur={handleSuggestClose}
              autoComplete="off"
              type="search"
            />
            {id === 0 && (
              <StyledIconButton aria-label="Search" onClick={toggleInputType}>
                <CancelIcon />
              </StyledIconButton>
            )}
            {id !== 0 && id !== waypoints.length - 1 && (
              <StyledIconButton aria-label="Search" onClick={() => removeWaypoint(id)}>
                <DeleteIcon />
              </StyledIconButton>
            )}
            {id === waypoints.length - 1 && (
              <StyledIconButton aria-label="Search" onClick={getRoute}>
                <SearchIcon />
              </StyledIconButton>
            )}
          </Search>
          <StyledAutoSuggest>
            <AutoSuggest
              open={openSuggestion === id.toString()}
              handleClose={handleSuggestClose}
              suggestions={suggestions}
              suggestionClick={(value, event) => suggestionClick(value, event, id)}
            />
          </StyledAutoSuggest>
        </React.Fragment>
      ))}
      <AddButton>
        <StyledAddIcon aria-label="Search" onClick={addWaypoint}>
          <AddIcon />
        </StyledAddIcon>
      </AddButton>
    </div>
  );
};

export default RouteInput;
