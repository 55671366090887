import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { FieldArray, getIn } from 'formik';
import { Box, Fab, Paper, styled, Typography } from '@material-ui/core';
import Button from 'Components/Button';
import { Delete, ErrorOutline } from '@material-ui/icons';
import Formula from './Formula';

const FORMULAS_LIMIT = 1;

const PaperStyled = styled(Paper)({
  marginBottom: 10,
});
const Warning = ({ message }) => (
  <Box display="flex" alignItems="center" margin="20px">
    <ErrorOutline color="error" style={{ fontSize: 30 }} />
    <Typography>{message}</Typography>
  </Box>
);

const FormulaArray = ({ name, dictionaries, inputMappings, formulaType }) => {
  return (
    <FieldArray name={name}>
      {({ form, remove, push }) => {
        const formulas = getIn(form.values, name);

        return (
          <>
            {!form.values.assignedAssets.length && (
              <Warning message={T.translate(LocaleKeys.messages.assignedAssetsAreRequiredToAddFormula)} />
            )}
            {formulas.map((formula, index) => (
              <PaperStyled key={`${name}.${index}`}>
                <Box display="flex" alignItems="center">
                  <Formula name={`${name}.${index}`} dictionaries={dictionaries} inputMappings={inputMappings} />
                  <Fab onClick={() => remove(index)} size="small">
                    <Delete />
                  </Fab>
                </Box>
              </PaperStyled>
            ))}
            {formulas.length < FORMULAS_LIMIT && (
              <Button
                onClick={() =>
                  push({
                    sections: [{ conditions: [{ targetValues: [] }] }],
                    formulaType,
                  })
                }
                disabled={!form.values.assignedAssets.length}
              >
                {T.translate(LocaleKeys.labels.addFormula)}
              </Button>
            )}
          </>
        );
      }}
    </FieldArray>
  );
};

FormulaArray.propTypes = {
  name: PropTypes.string,
  dictionaries: PropTypes.object,
  inputMappings: PropTypes.array,
  formulaType: PropTypes.number,
};

export default FormulaArray;
