import React, { useState } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { compose } from 'redux';
import { styled } from '@material-ui/core/styles';
import { Permissions } from 'Constants/permissions';
import { Formik } from 'formik';
import { copyButtonLibraryFromTemplate } from '../helpers';
import { DevicesConfiguration } from 'routes/Routes';
import {
  postDevice,
  postDeviceLibraryAssignFrom,
  getDriverLibrary,
  deleteButtonLibrary,
  getDriverMappingsInfo,
} from 'Api/devices';
import Tabs from 'Components/display/tabs/Tabs';
import Button from 'Components/Button';
import SaveOnLeave from 'Components/dialogs/SaveOnLeave';
import withPermissions from 'hocs/withPermissions';
import General from './tabs/General';
import OtherConfiguration from './tabs/OtherConfiguration';
import ServiceStatus from './tabs/Service';
import Mappings from './tabs/Mappings';
import Buttons from './tabs/Buttons';
import validationSchema from './validationSchema';
import { VehicleRunningTypes, VehicleRunningDefaultValue } from 'Constants/VehicleRunningTypes';
import { addResource } from 'app/pages/sites/administration/AlarmDefinitions/helpers';
import { getLibraryInputMappingsSorted } from 'app/pages/sites/administration/libraryManagement/helpers';

const ButtonStyled = styled(Button)({
  float: 'right',
  marginLeft: '10px',
});

const New = (props) => {
  const [mappingsInfo, setMappingsInfo] = useState({});
  const device = {
    indicatorAfter: 1440,
    indicatorTo: 1440,
    indicatorBefore: 60,
    useForIndefiniteTime: false,
    frameRelatedPeriod: 3,
    serviceStatus: 1,
    vehicle: {
      vehicleRunningType: VehicleRunningTypes.SPEED_GREATER_OR_EQUAL,
      vehicleRunningValue: VehicleRunningDefaultValue,
      speedIntervalType: 1,
      regardingTachometerType: 1,
      voltageType: 1,
    },
    deviceButtons: null,
    httpFeeds: [],
  };

  const redirect = () => {
    const url = DevicesConfiguration.getUrl();

    props.history.push(url);
  };

  const onCancel = () => {
    redirect();
  };

  const saveData = async (values) => {
    if (values.resourceId === 0 && values.icon) {
      await addResource(values, 'icon', 'resourceId');
    }
    if (values.imageId === 0 && values.image) {
      await addResource(values, 'image', 'imageId');
    }
    if (values.buttonLibraryId) {
      const copyButtonsResponse = await copyButtonLibraryFromTemplate(values.deviceButtons);

      values.buttonLibraryId = copyButtonsResponse.data.id;
    }

    postDevice(values)
      .then(async (response) => {
        const deviceId = response.data.id;

        if (values.mappings?.id) {
          postDeviceLibraryAssignFrom(deviceId, values.mappings.id, {
            ...values.mappings,
            deviceId,
            isCurrentlySelected: true,
            isTemplate: false,
          });
        }
      })
      .catch(() => {
        if (values.buttonLibraryId) {
          deleteButtonLibrary(values.buttonLibraryId);
        }
      });
  };

  const onSubmit = (values, { setSubmitting }) => {
    saveData(values).finally(() => {
      setSubmitting(false);
      redirect();
    });
  };

  const fetchDriverMappingsInfo = (id) => {
    getDriverMappingsInfo(id).then(({ data }) => {
      setMappingsInfo(data);
    });
  };

  const onMappingsLibraryChange = (driverId, newLibraryId, handleChange) => {
    getDriverLibrary(driverId, newLibraryId).then(({ data: library }) => {
      handleChange({ target: { name: 'mappings', value: getLibraryInputMappingsSorted(library) } });
    });
  };

  return (
    <Formik
      initialValues={device}
      enableReinitialize
      validationSchema={validationSchema(device)}
      validateOnMount
      onSubmit={onSubmit}
    >
      {({ values, isValid, isSubmitting, dirty, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Tabs label="AlarmDetails" defaultTab={0}>
            <General label={LocaleKeys.labels.general} />
            <ServiceStatus label={LocaleKeys.labels.service} />
            <OtherConfiguration label={LocaleKeys.labels.otherConfiguration} />
            <Mappings
              label={LocaleKeys.labels.mappings}
              onMappingsLibraryChange={onMappingsLibraryChange}
              mappingsInfo={mappingsInfo}
              fetchDriverMappingsInfo={fetchDriverMappingsInfo}
            />
            <Buttons label={LocaleKeys.labels.libraryButtons} />
          </Tabs>

          <ButtonStyled onClick={onCancel} id="new-devicesConfiguration-cancelButton">
            {T.translate(LocaleKeys.labels.cancel)}
          </ButtonStyled>
          <ButtonStyled
            disabled={!(dirty && isValid) || isSubmitting}
            type="submit"
            id="new-devicesConfiguration-submitButton"
            showProgress={isSubmitting}
          >
            {T.translate(LocaleKeys.labels.save)}
          </ButtonStyled>

          <SaveOnLeave saveData={() => saveData(values)} dataChanged={dirty} validForm={isValid} />
        </form>
      )}
    </Formik>
  );
};

New.propTypes = {
  history: PropTypes.object,
  permissions: PropTypes.object,
};

export default compose(withPermissions([Permissions.CanAccessDevicesPage, Permissions.CanAddDevices]))(New);
