import * as Yup from 'yup';
import { getSiteGroupExistsByName } from 'Api/sites';
import { isAlphanumericOrSpecialSymbol } from 'Validators/SharedValidators';

let lastValue;
let initialValue;

export const siteGroupNameExists = async (value, id) => {
  if (isAlphanumericOrSpecialSymbol([' '])(value)) {
    const result = lastValue?.value === value ? lastValue?.result : await getSiteGroupExistsByName(value);

    if (id) {
      if (!initialValue || id !== initialValue?.id) {
        initialValue = { value, id };
      }
    } else {
      initialValue = undefined;
    }

    lastValue = { value, result };

    return initialValue?.value === value || !result?.data;
  }

  return false;
};
export const siteGroupsValidation = Yup.object().shape({
  name: Yup.string()
    .required('thisFieldIsRequired')
    .max(50, 'nameCannotExceed50Characters')
    .alphanumericOrSpecialSymbol([' '])
    .test('siteGroupNameExists', 'siteGroupWithThisNameAlreadyExists', function (value) {
      return siteGroupNameExists(value, this.parent.groupId);
    }),
  description: Yup.string().max(200, 'nameCannotExceed200Characters'),
  sites: Yup.array().min(1, 'thisFieldIsRequired'),
  users: Yup.array().min(1, 'thisFieldIsRequired'),
});

export default siteGroupsValidation;
