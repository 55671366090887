import { types } from './actions';
import { types as sitesTypes } from 'redux/sites/actions';

const initialState = {
  mapAreas: null,
  mapAreasError: null,
  isLoadingMapAreas: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case sitesTypes.SET_CURRENT_SITE:
      return {
        ...initialState,
      };
    case types.LOAD_MAP_AREAS_SUCCESS:
      return {
        ...state,
        mapAreas: action.payload.mapAreas,
        isLoadingMapAreas: false,
      };
    case types.LOAD_MAP_AREAS_FAIL:
      return {
        ...state,
        mapAreasError: action.payload.error,
        isLoadingMapAreas: false,
      };
    case types.SET_IS_LOADING_MAP_AREAS: {
      return {
        ...state,
        isLoadingMapAreas: action.payload.isLoading,
      };
    }
    default:
      return state;
  }
};
