import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { getVehicleRunningTypes } from 'Api/devices';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import { VehicleRunningTypes } from 'Constants/VehicleRunningTypes';
import { DropdownWithApiCall, TextField } from 'Components/formik/fields';

const Other = (props) => {
  const getDriverVehicleRunningTypes = () => {
    return getVehicleRunningTypes(props.data?.mappings?.driverId || 0);
  };

  return (
    <ColumnDisplayer>
      <DropdownWithApiCall
        name="vehicle.vehicleRunningType"
        apiCall={getDriverVehicleRunningTypes}
        displayField="name"
        label={T.translate(LocaleKeys.labels.vehicleIsRunningWhen)}
        withoutEmpty
        translateLabel
        valueField="value"
      />
      {props.data.vehicle.vehicleRunningType !== VehicleRunningTypes.IGNITION_IS_ON && (
        <TextField label={T.translate(LocaleKeys.labels.value)} name="vehicle.vehicleRunningValue" type="number" />
      )}
    </ColumnDisplayer>
  );
};

Other.propTypes = {
  data: PropTypes.object,
};

export default Other;
