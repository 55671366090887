import React, { useState, useCallback, useMemo } from 'react';
import LeafletMap from 'Components/LeafletMap';
import { withConfigurationStoreLayers } from 'Components/LeafletMap/hocs/withConfigurationStoreLayers';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import Icon from './Icon';
import ApiIconMarker from 'Components/ApiIconMarker';
import TrackingMode from 'Components/LeafletMap/controls/TrackingMode';
import UnselectAssets from 'Components/LeafletMap/controls/UnselectAssets';
import AssetNames from 'Components/LeafletMap/controls/AssetNames';
import { useConfigurationStore } from 'hooks/useConfigurationStore';

const LeafletMapWithConfigurationStore = withConfigurationStoreLayers(LeafletMap);

const getFitBoundsProp = (trackingMode, selectedAssetIds, devices, filteredDevices) => {
  return trackingMode && selectedAssetIds.length > 0 ? JSON.stringify(filteredDevices) : devices.length;
};

const MapWithDragableMarkers = ({
  devices,
  selectedAssetIds,
  setDraggedDevice,
  draggedDevice,
  deviceTrail,
  trackingMode,
  ...props
}) => {
  const [copiedMarkerPosition, setCopiedMarkerPosition] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [icon, setIcon] = useState();
  const [color, setColor] = useState();
  const [showAssetNames, setShowAssetNames] = useConfigurationStore('Dashboard-showAssetNames', false);

  const setSelectability = useCallback((node, mode) => {
    if (node.nodeType === 1) {
      node.setAttribute('unselectable', mode);
    }
    let child = node.firstChild;

    while (child) {
      setSelectability(child, mode);
      child = child.nextSibling;
    }
  }, []);

  const handleMouseMove = useCallback(
    (e) => {
      setCopiedMarkerPosition({ x: e.pageX, y: e.pageY });
    },
    [setCopiedMarkerPosition]
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    setDraggedDevice(undefined);
    document.removeEventListener('mouseup', handleMouseUp);
    document.removeEventListener('mousemove', handleMouseMove);
    setSelectability(document.getElementById('root'), 'off');
  }, [setIsDragging, setDraggedDevice, handleMouseMove, setSelectability]);

  const handleMouseDown = useCallback(
    (e, device) => {
      if (device.iconId) {
        setIcon(e.target._icon.src);
      } else {
        setIcon();
      }
      setDraggedDevice(device);
      setColor(device.color);
      setCopiedMarkerPosition({
        x: e.originalEvent.pageX,
        y: e.originalEvent.pageY,
      });
      setSelectability(document.getElementById('root'), 'on');
      document.addEventListener('mouseup', handleMouseUp);
      document.addEventListener('mousemove', handleMouseMove);
      setIsDragging(true);
    },
    [
      setIcon,
      setDraggedDevice,
      setColor,
      setCopiedMarkerPosition,
      setSelectability,
      handleMouseUp,
      handleMouseMove,
      setIsDragging,
    ]
  );

  const filteredDevices = useMemo(
    () =>
      devices.filter((device) => {
        if (selectedAssetIds.length !== 0) {
          if (!selectedAssetIds.includes(device.externalId)) {
            return false;
          }
        }
        if (!device.position) {
          return false;
        }

        return true;
      }),
    [devices, selectedAssetIds]
  );

  return (
    <>
      <div style={{ display: 'flex', width: '100%', position: 'relative' }}>
        <div style={{ flexBasis: '100%' }}>
          <LeafletMapWithConfigurationStore
            configurationStoreKey={'Dashboard-layers'}
            dragging={!isDragging}
            maxZoom={20}
            disableTrailFocus
            fitBoundsProp={getFitBoundsProp(trackingMode, selectedAssetIds, devices, filteredDevices)}
            deviceTrail={deviceTrail}
            reverseTrail={true}
            controls={[
              {
                element: TrackingMode,
                position: { bottom: 145, right: 10 },
                props: {
                  configurationStoreKey: 'Dashboard-trackingBounds',
                },
              },
              {
                element: UnselectAssets,
                position: { top: 10, right: 50 },
              },
              {
                element: AssetNames,
                position: { bottom: 100, right: 10 },
                props: {
                  value: showAssetNames,
                  onClick: setShowAssetNames,
                },
              },
            ]}
            trackingMode={trackingMode}
            {...props}
          >
            <MarkerClusterGroup>
              {filteredDevices.map((device) => (
                <ApiIconMarker
                  key={device.externalId}
                  position={device.position}
                  tooltipText={device.name}
                  iconId={device.iconId}
                  color={device.color}
                  device={device}
                  onMouseDown={handleMouseDown}
                  isAssetMarker
                />
              ))}
            </MarkerClusterGroup>
          </LeafletMapWithConfigurationStore>
        </div>
      </div>
      {isDragging && (
        <div
          style={{
            zIndex: '1001',
            pointerEvents: 'none',
            position: 'absolute',
            top: copiedMarkerPosition.y,
            left: copiedMarkerPosition.x,
          }}
        >
          {icon ? (
            <img src={icon} alt="marker" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
          ) : (
            <Icon color={color} />
          )}
        </div>
      )}
    </>
  );
};

export default MapWithDragableMarkers;
