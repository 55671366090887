import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import * as configurationStore from 'Api/configurationStore';
import MiniMap from './MiniMap';

const styles = (theme) => ({
  root: {
    backgroundColor: 'white',
    width: '400px',
    height: '100%',
    zIndex: 1000,
    padding: '10px',
    paddingBottom: '15px',
    display: 'flex',
    flexDirection: 'column',
    transitionProperty: 'width padding',
    transitionDuration: '0.8s',
    overflow: 'hidden',
  },
  control: {
    marginLeft: '-40px',
    position: 'absolute',
    backgroundColor: 'white',
    width: '40px',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  arrow: {
    height: '45px',
    width: '45px',
  },
  map: {
    marginBottom: '5px',
    borderRadius: '5px',
  },
  minimap: {
    display: 'flex',
    height: 'inherit',
    flexDirection: 'column',
  },
  mapHeader: {
    backgroundColor: '#3f51b5',
    color: 'white',
    padding: '5px',
    borderRadius: '4px 4px 0 0',
    height: '42px',
    lineHeight: '2',
    display: 'flex',
    justifyContent: 'space-between',
  },
  clear: {
    color: 'white',
  },
  add: {
    width: '30px',
  },
});

class MapSidebar extends React.Component {
  defaultDrawerState = 1;

  maxDrawerState = 2;

  state = {
    maps: [],
    drawerState: +(localStorage.getItem('drawerState') || this.defaultDrawerState),
  };

  componentDidMount = () => {
    configurationStore.getValue(`Minimap-count`).then((response) => {
      if (response.status === 200) {
        this.setState({ maps: response.data });
      }
    });
  };

  removeMap = (id) => {
    this.setState(({ maps: prevMaps }) => {
      const maps = prevMaps.filter((mapId) => mapId !== id);

      configurationStore.deleteValueIfExists(`Minimap-${id}`);
      configurationStore.deleteValueIfExists(`Minimap-${id}-showAssetNames`);
      configurationStore.deleteValueIfExists(`Minimap-${id}-layers`);
      configurationStore.putValueCreateOrUpdate(`Minimap-count`, { value: JSON.stringify(maps) });

      return { maps };
    });
  };

  addMap = () => {
    this.setState(({ maps: prevMaps }) => {
      let maps = [...prevMaps];
      const length = maps.length;

      if (length) {
        maps.push(maps[length - 1] + 1);
      } else {
        maps = [1];
      }

      configurationStore.putValueCreateOrUpdate(`Minimap-count`, { value: JSON.stringify(maps) });

      return { maps };
    });
  };

  drawerClick = () => {
    const drawerState = this.state.drawerState;
    const nextDrawerState = drawerState + 1 > this.maxDrawerState ? 0 : drawerState + 1;

    this.setState({ drawerState: nextDrawerState });
    this.props.setDrawerState(nextDrawerState);
    localStorage.setItem('drawerState', nextDrawerState);
  };

  render() {
    const { classes } = this.props;
    const { drawerState } = this.state;

    return (
      <div
        className={classes.root}
        style={{
          width: `${drawerState * 60}%`,
          paddingLeft: drawerState === 0 ? 0 : 10,
          paddingRight: drawerState === 0 ? 0 : 10,
        }}
      >
        <div className={classes.control} onClick={this.drawerClick}>
          {drawerState < this.maxDrawerState ? (
            <ArrowLeftIcon className={classes.arrow} />
          ) : (
            <ArrowRightIcon className={classes.arrow} />
          )}
        </div>
        {this.state.maps.map((mapId, index) => (
          <div key={mapId} className={classes.minimap}>
            <MiniMap
              id={mapId}
              index={index}
              removeMap={this.removeMap}
              maps={[...this.state.maps]}
              classes={classes}
              allDevices={this.props.allDevices}
            />
          </div>
        ))}
        <div>
          {this.state.maps.length < 3 && (
            <IconButton aria-label="add" className={classes.add} size="small" onClick={this.addMap} id={'minimap-add'}>
              <AddIcon />
            </IconButton>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(MapSidebar);
