import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'Components/display/table/Table';
import { CircularProgress } from '@material-ui/core';
import { deleteButtonLibrary } from 'Api/devices';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CopyIcon from '@material-ui/icons/FileCopy';
import DeleteDialog from 'Components/dialogs/DeleteDialog';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import TableActionButton from 'Components/display/table/TableActionButton';

const formatDataForTableView = (libraries) =>
  Object.values(libraries).map((library) => ({
    id: library.id,
    libraryName: library.name,
    author: library.author,
  }));

const ButtonLibraries = ({ buttonLibraries, setButtonLibraries, permissions, ...props }) => {
  const [buttonLibraryIdToHandle, setButtonLibraryIdToHandle] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const getTableActionButtons = () => {
    const actionButtons = [];

    if (
      permissions[Permissions.CanEditButtonsLibrary] &&
      permissions[Permissions.CanReadSensitiveButtons] &&
      permissions[Permissions.CanReadNonSensitiveButtons]
    ) {
      const editItemButton = (
        <TableActionButton
          key="editItemButton"
          onClick={props.onSelectLibrary}
          clickparam={'id'}
          icon={<EditIcon />}
          tooltipText={T.translate(LocaleKeys.tooltips.editItem)}
        />
      );

      actionButtons.push(editItemButton);
    }
    if (permissions[Permissions.CanCopyButtonsLibrary]) {
      const copyItemButton = (
        <TableActionButton
          key="copyItemButton"
          onClick={(id) => props.onCopyLibrary(id)}
          clickparam={'id'}
          icon={<CopyIcon />}
          tooltipText={T.translate(LocaleKeys.tooltips.copyItem)}
        />
      );

      actionButtons.push(copyItemButton);
    }
    if (permissions[Permissions.CanRemoveButtonsLibrary]) {
      const deleteItemButton = (
        <TableActionButton
          key="deleteItemButton"
          onClick={(id) => {
            setDeleteDialogOpen(true);
            setButtonLibraryIdToHandle(id);
          }}
          clickparam={'id'}
          icon={<DeleteIcon />}
          tooltipText={T.translate(LocaleKeys.tooltips.deleteItem)}
        />
      );

      actionButtons.push(deleteItemButton);
    }

    return actionButtons;
  };

  if (!buttonLibraries) {
    return <CircularProgress />;
  }

  const formattedTableData = formatDataForTableView(buttonLibraries);

  return (
    <>
      <Table
        data={formattedTableData}
        onRowDoubleClick={props.onSelectLibrary}
        onAddClick={permissions[Permissions.CanAddButtonsLibrary] && props.onAddLibrary}
        redirect={'id'}
        hideColumns={['id']}
        actionButtons={getTableActionButtons()}
      />
      <DeleteDialog
        isOpen={deleteDialogOpen}
        onConfirm={() => {
          return deleteButtonLibrary(buttonLibraryIdToHandle).then(() => {
            const updatedLibraries = buttonLibraries.filter((library) => library.id !== buttonLibraryIdToHandle);

            props.onSelectLibrary(null);
            setButtonLibraries(updatedLibraries);
            setDeleteDialogOpen(false);
            setButtonLibraryIdToHandle(null);
          });
        }}
        onCancel={() => {
          setButtonLibraryIdToHandle(null);
          setDeleteDialogOpen(false);
        }}
        disableConfirm={!buttonLibraryIdToHandle}
        title={T.translate(LocaleKeys.messages.deleteButtonsLibraryDialogTitle)}
        message={T.translate(LocaleKeys.messages.deleteButtonsLibraryDialogMessage)}
      />
    </>
  );
};

ButtonLibraries.propTypes = {
  buttonLibraries: PropTypes.array,
  setButtonLibraries: PropTypes.func.isRequired,
  onSelectLibrary: PropTypes.func.isRequired,
  onCopyLibrary: PropTypes.func,
  onAddLibrary: PropTypes.func,
  permissions: PropTypes.object,
};

ButtonLibraries.defaultProps = {
  buttonLibraries: null,
  onCopyLibrary: null,
  onAddLibrary: null,
};

export default withPermissions([])(ButtonLibraries);
