import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import { withRouter } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogActions, Box } from '@material-ui/core';
import Button from 'Components/Button';
import ButtonLibrariesTable from './ButtonLibrariesTable';
import { types } from 'Components/buttonLibrary/reducer/buttonLibraryReducer';
import { ButtonsLibrary } from 'routes/Routes';
import { getButtonLibraries } from 'Api/devices';
import { RequestConfigContext } from '../../requestConfigContext';

const DeviceButtons = (props) => {
  const [buttonLibraries, setButtonLibraries] = useState([]);
  const [open, setOpen] = useState(false);
  const requestConfig = useContext(RequestConfigContext);

  useEffect(() => {
    getButtonLibraries(requestConfig).then((response) => {
      const templateLibraries = response.data.filter(
        (library) => library.isTemplate || library.id === props.selectedLibraryId
      );

      setButtonLibraries(templateLibraries);
    });
  }, [props.selectedLibraryId]);

  const onSelectLibrary = (selectedButtonLibrary) => {
    props.onChange({ target: { name: 'deviceButtons', value: selectedButtonLibrary } });
    props.onChange({ target: { name: 'buttonLibraryId', value: selectedButtonLibrary && selectedButtonLibrary.id } });
    props.dispatch({ type: types.INITIALIZE_STATE, library: selectedButtonLibrary });
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" margin="15px">
        <Button onClick={() => setOpen(true)}>{T.translate(LocaleKeys.labels.loadLibrary)}</Button>
        <Button
          onClick={() => {
            const url = ButtonsLibrary.getUrl();

            props.history.push(url);
          }}
        >
          {T.translate(LocaleKeys.labels.allLibraries)}
        </Button>
      </Box>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">{T.translate(LocaleKeys.labels.selectLibraryTemplate)}</DialogTitle>
        <DialogContent>
          <ButtonLibrariesTable
            buttonLibraries={buttonLibraries}
            setButtonLibraries={setButtonLibraries}
            onSelectLibrary={onSelectLibrary}
            dispatch={props.dispatch}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            color="primary"
            autoFocus
          >
            {T.translate(LocaleKeys.labels.close)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

DeviceButtons.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedLibraryId: PropTypes.number,
  history: PropTypes.object,
};

export default withRouter(DeviceButtons);
