import React, { useState, useCallback } from 'react';
import { IconButton, Badge } from '@material-ui/core';
import { Email } from '@material-ui/icons';
import SiteNotificationsDialog from './SiteNotificationsDialog';
import { compose } from 'redux';
import { connect } from 'react-redux';

const SiteNotifications = ({ currentSiteId }) => {
  const [open, setOpen] = useState(false);
  const [unread, setUnread] = useState(0);

  const setClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const marAsRead = useCallback(() => {
    setUnread((prev) => prev - 1);
  }, [setUnread]);

  return (
    <>
      <IconButton
        onClick={() => {
          setOpen(true);
        }}
        id={'site-notifications'}
      >
        {unread > 0 ? (
          <Badge badgeContent={unread} max={999} color="secondary">
            <Email htmlColor="#fff" />
          </Badge>
        ) : (
          <Email htmlColor="#fff" />
        )}
      </IconButton>
      <SiteNotificationsDialog
        open={open}
        onClose={setClose}
        setUnread={setUnread}
        markAsRead={marAsRead}
        currentSiteId={currentSiteId}
      />
    </>
  );
};

const mapState = (state) => ({
  currentSiteId: state.sites.currentSiteId,
});

export default compose(connect(mapState))(SiteNotifications);
