import { types } from './actions';
import { types as sitesTypes } from 'redux/sites/actions';

const initialState = {
  loadingPage: false,
  error: null,
  pageError: null,
  statusHistory: [],
  statusHistoryQueue: [],
  total: 0,
};

export const calculateIncrementTotalBy = (prevAlarm, newAlarm, newestCreatedOn) => {
  if (prevAlarm && !prevAlarm.isRead && newAlarm.isRead) {
    // unread alarm was read
    return -1;
  } else if (prevAlarm && prevAlarm.isRead && !newAlarm.isRead) {
    // read alarm cames as unread
    return 1;
  } else if (prevAlarm && prevAlarm.isRead === newAlarm.isRead) {
    // counter does not change
    return 0;
  } else if (!prevAlarm && newAlarm.isRead && +new Date(newAlarm.createdOn) > newestCreatedOn) {
    /* if an alarm is auto-read with 'autoReadInMinutes: 0' then there is sent with 'isRead: true'
    and without unread notification triggered before so counter should not be incremented */
    return 0;
  } else if (!prevAlarm && newAlarm.isRead) {
    // if older than last known then assume it is an old alarm being read and should decrement counter
    return -1;
  } else if (!prevAlarm && !newAlarm.isRead && +new Date(newAlarm.createdOn) > newestCreatedOn) {
    // if unread and newer than last known then assume it is newly created
    return 1;
  } else if (!prevAlarm && !newAlarm.isRead) {
    // if unread and older than last known then assume it old alarm and does not increment counter
    return 0;
  } else {
    // default
    return newAlarm.isRead ? 0 : 1;
  }
};

export const getAlarmListsMerged = (prevAlarms, newAlarms) => {
  const alarmsMap = new Map();
  const newestCreatedOn = Math.max(...prevAlarms.map((alarm) => +new Date(alarm.createdOn)));
  let incrementTotalBy = 0;

  prevAlarms.forEach((alarm) => {
    alarmsMap.set(alarm.uniqueId, alarm);
  });
  newAlarms.forEach((newAlarm) => {
    const key = newAlarm.uniqueId;
    const prevAlarm = alarmsMap.get(key);

    incrementTotalBy += calculateIncrementTotalBy(prevAlarm, newAlarm, newestCreatedOn);
    alarmsMap.set(key, newAlarm);
  });

  return {
    incrementTotalBy,
    alarms: Array.from(alarmsMap.values()),
  };
};

const loadFeedSuccess = (state, action) => {
  const { alarms, incrementTotalBy } = getAlarmListsMerged(action.payload.alarms, state.statusHistoryQueue);

  return {
    ...state,
    loadingPage: false,
    statusHistory: alarms,
    statusHistoryQueue: [],
    total: action.payload.total + incrementTotalBy,
  };
};

const loadPageSuccess = (state, action) => {
  const total = action.payload.total !== undefined ? action.payload.total : state.total;
  const { alarms: statusHistory, incrementTotalBy: nextPageIncrementTotalBy } = getAlarmListsMerged(
    state.statusHistory,
    action.payload.alarms
  );
  const { alarms, incrementTotalBy: queueIncrementTotalBy } = getAlarmListsMerged(
    statusHistory,
    state.statusHistoryQueue
  );

  return {
    ...state,
    loadingPage: false,
    statusHistory: alarms,
    statusHistoryQueue: [],
    total: total + nextPageIncrementTotalBy + queueIncrementTotalBy,
  };
};

const addFeedAlarm = (state, action) => {
  const newAlarm = action.payload.alarm;
  const index = state.statusHistory.findIndex((notification) => notification.uniqueId === newAlarm.uniqueId);
  const newestCreatedOn = Math.max(...state.statusHistory.map((notification) => +new Date(notification.createdOn)));
  const prevAlarm = index !== -1 ? state.statusHistory[index] : null;

  if (state.loadingPage) {
    return {
      ...state,
      statusHistoryQueue: [...state.statusHistoryQueue, newAlarm],
    };
  }

  const incrementTotalBy = calculateIncrementTotalBy(prevAlarm, newAlarm, newestCreatedOn);
  const statusHistory = [...state.statusHistory];

  if (!prevAlarm) {
    statusHistory.push(newAlarm);
  } else {
    statusHistory[index] = newAlarm;
  }

  return {
    ...state,
    statusHistory,
    total: state.total + incrementTotalBy,
  };
};

const addFeedAlarms = (state, action) => {
  const newAlarms = action.payload.alarms;
  const { alarms, incrementTotalBy } = getAlarmListsMerged(state.statusHistory, newAlarms);

  return {
    ...state,
    statusHistory: alarms,
    total: state.total + incrementTotalBy,
  };
};

const readAlarms = (state, action) => {
  const { readAlarms, decrementTotalBy } = action.payload;

  return {
    ...state,
    statusHistory: state.statusHistory.map((alarm) => {
      if (readAlarms.includes(alarm.uniqueId)) {
        return {
          ...alarm,
          isRead: true,
        };
      }

      return alarm;
    }),
    total: state.total - decrementTotalBy,
  };
};

const forceSyncTotal = (state, action) => {
  return {
    ...state,
    total: state.statusHistory.filter((alarm) => !alarm.isRead).length,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    // reset state of alarm feed
    case sitesTypes.SET_CURRENT_SITE_ID:
      return {
        ...state,
        loadingPage: false,
        pageError: null,
        statusHistory: [],
        statusHistoryQueue: [],
        total: 0,
      };
    case types.SET_LOADING_PAGE:
      return {
        ...state,
        loadingPage: action.payload.loadingPage,
      };
    case types.LOAD_FEED_SUCCESS:
      return loadFeedSuccess(state, action);
    case types.LOAD_FEED_FAIL:
      return {
        ...state,
        loadingPage: false,
        error: action.payload.error,
      };
    case types.LOAD_PAGE_SUCCESS:
      return loadPageSuccess(state, action);
    case types.LOAD_PAGE_FAIL:
      return {
        ...state,
        loadingPage: false,
        pageError: action.payload.error,
      };
    case types.ADD_FEED_ALARM:
      return addFeedAlarm(state, action);
    case types.ADD_FEED_ALARMS:
      return addFeedAlarms(state, action);
    case types.READ_ALARMS:
      return readAlarms(state, action);
    case types.SYNC_TOTAL:
      return forceSyncTotal(state, action);
    default:
      return state;
  }
};
