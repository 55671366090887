import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CollapseItem from './navigation/CollapseItem';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { NavLink } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Lock from '@material-ui/icons/Lock';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import UserInfoContainer from './navigation/UserInfoContainer';
import classNames from 'classnames';
import { withStyles, styled } from '@material-ui/core/styles';
import Notifications from './navigation/Notifications';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import StarRateIcon from '@material-ui/icons/StarRate';

const drawerWidth = 255;

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: -20,
    marginRight: 20,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `${theme.spacing(7)}px !important`,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(1),
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  margin: {
    marginRight: 10,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  hiddenPosition: {
    display: 'none',
  },
});

const ToolbarStyled = styled(Toolbar)({
  justifyContent: 'space-between',
});
const ToolbarLeft = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const ListItemIconStyled = styled(ListItemIcon)({
  '&& img': {
    width: 20,
  },
});

class Navigation extends React.Component {
  state = {
    open: false,
    hover: false,
    user: null,
  };

  handleDrawerOpen = () => {
    this.setState((prevState) => ({ ...prevState, open: true }));
  };

  handleDrawerClose = () => {
    this.setState((prevState) => ({ ...prevState, open: false, hover: false }));
  };

  handleDrawerOpenTemp = () => {
    this.setState((prevState) => ({ ...prevState, hover: true }));
  };

  handleDrawerCloseTemp = () => {
    this.setState((prevState) => ({ ...prevState, hover: false }));
  };

  clickTab = (tab) => {
    this.setState((prevState) => ({ ...prevState, tab: tab }));
  };

  render() {
    const { currentSite, classes, theme, defaultSite, permissions } = this.props;
    const canAccessAlarmNotificationFeed = permissions[Permissions.CanReadAlarmNotifications];

    return (
      <>
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: this.state.open || this.state.hover,
          })}
        >
          <ToolbarStyled>
            <ToolbarLeft>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(classes.menuButton, {
                  [classes.hide]: this.state.open || this.state.hover,
                })}
                id={'drawer-open'}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" color="inherit">
                {defaultSite ? (
                  <NavLink activeStyle={{ color: 'white' }} to={`/${currentSite.replace(/\s/g, '-')}`}>
                    DNA
                  </NavLink>
                ) : (
                  <NavLink activeStyle={{ color: 'white' }} to={`/${currentSite.replace(/\s/g, '-')}`}>
                    {`DNA (${currentSite})`}
                    <StarRateIcon fontSize="small" color="secondary" />
                  </NavLink>
                )}
              </Typography>
            </ToolbarLeft>
            {canAccessAlarmNotificationFeed && <Notifications />}
            <UserInfoContainer />
          </ToolbarStyled>
        </AppBar>
        <div onMouseEnter={this.handleDrawerOpenTemp} onMouseLeave={this.handleDrawerCloseTemp}>
          <Drawer
            variant="permanent"
            className={classNames(classes.drawer, {
              [classes.drawerOpen]: this.state.open || this.state.hover,
              [classes.drawerClose]: !(this.state.open || this.state.hover),
            })}
            classes={{
              paper: classNames({
                [classes.drawerOpen]: this.state.open || this.state.hover,
                [classes.drawerClose]: !(this.state.open || this.state.hover),
              }),
            }}
            open={this.state.open || this.state.hover}
          >
            <div className={classes.toolbar}>
              <IconButton onClick={this.handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </div>

            <Divider />

            <List>
              {this.props.options.map((option) => {
                const subOptions = option.options;

                return (
                  <CollapseItem
                    key={option.label}
                    icon={option.icon}
                    label={option.label}
                    id={`drawer-option-${option.label}`}
                  >
                    {subOptions.map((subOption) => (
                      <NavLink
                        key={`${subOption.label}-link`}
                        to={subOption.link}
                        className={subOption.disabled ? classes.hiddenPosition : ''}
                        label={`drawer-suboption-${subOption.label}`}
                      >
                        <ListItem button className={classes.nested}>
                          <ListItemIconStyled>
                            {subOption.disabled ? <Lock /> : <img src={subOption.icon} alt="" />}
                          </ListItemIconStyled>
                          <ListItemText insetprimary={subOption.label}>{subOption.label}</ListItemText>
                        </ListItem>
                      </NavLink>
                    ))}
                  </CollapseItem>
                );
              })}
            </List>
          </Drawer>
        </div>
      </>
    );
  }
}

Navigation.propTypes = {
  classes: PropTypes.object,
  content: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  ]),
  theme: PropTypes.object,
  permmissions: PropTypes.object,
};

const mapState = (state) => ({
  currentSite: state.sites.currentSite,
  defaultSite: state.user.data.tenantId === state.sites.currentSiteId,
});

export default compose(connect(mapState), withStyles(styles, { withTheme: true }), withPermissions([]))(Navigation);
