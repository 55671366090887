import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import T from 'i18n-react';

import { withField, withFormik } from 'Components/formik/formikWrappers';
import FormikCollection from 'Components/inputs/CollectionManager/FormikCollection';
import { TextField as TextFieldRaw } from 'Components/formik/fields';
import DropdownRaw from 'Components/inputs/Dropdown';
import { WebhookTypeTranslationKeys, WebhookTypes } from 'Constants/WebhookTypes';
import LocaleKeys from 'Localization/LocaleKeys';

const TextField = styled(TextFieldRaw)({
  minWidth: 300,
  margin: 2,
});
const DropdownStyled = styled(DropdownRaw)({
  minWidth: 300,
  margin: 2,
});
const DropdownWithHiddenOptions = ({ hiddenOptions = [], ...props }) => {
  const data = props.options?.filter((item) => item === props.value || !hiddenOptions.includes(item));

  return <DropdownStyled {...props} data={data} />;
};
const DropdownWithHiddenOptionsField = withField(DropdownWithHiddenOptions);

const WebhooksOptions = Object.values(WebhookTypes).map((type) => ({
  value: type,
  name: WebhookTypeTranslationKeys[type],
}));
const WEBHOOKS_COUNT = WebhooksOptions.length;

const Webhooks = ({ data }) => {
  const usedWebHookTypes = data.webHooks?.map((webHook) => webHook.type) || [];

  return (
    <FormikCollection
      label={T.translate(LocaleKeys.labels.webhooks)}
      name={'webHooks'}
      disableAddItem={usedWebHookTypes.length >= WEBHOOKS_COUNT}
    >
      <DropdownWithHiddenOptionsField
        label={T.translate(LocaleKeys.labels.type)}
        name="type"
        displayField="name"
        valueField="value"
        options={WebhooksOptions}
        hiddenOptions={usedWebHookTypes}
        formValues={data}
        translateLabel
        withoutEmpty
        fullWidth
      />
      <TextField label={T.translate(LocaleKeys.labels.url)} name="url" fullWidth />
      <TextField label={T.translate(LocaleKeys.labels.login)} name="authUser" fullWidth />
      <TextField label={T.translate(LocaleKeys.labels.password)} name="authPassword" fullWidth type="password" />
    </FormikCollection>
  );
};

Webhooks.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
};

export default withFormik(Webhooks);
