import CommonInfo from './general/CommonInfo';
import ExpansionSection from 'Components/display/ExpansionPanel/ExpansionSection';
import LocaleKeys from 'Localization/LocaleKeys';
import React from 'react';

const General = (props) => (
  <ExpansionSection data={props.data} onChange={props.onChange}>
    <CommonInfo label={LocaleKeys.labels.commonInfo} />
  </ExpansionSection>
);

export default General;
