import React, { useState } from 'react';
import RouteInput from './RouteInput';
import SearchInput from './SearchInput';

const CombinedInput = (props) => {
  const [inputExtended, setInputExtended] = useState(false);

  const toggleInputType = () => {
    setInputExtended(!inputExtended);
  };

  return (
    <>
      {inputExtended ? (
        <RouteInput toggleInputType={toggleInputType} {...props} />
      ) : (
        <SearchInput toggleInputType={toggleInputType} {...props} />
      )}
    </>
  );
};

export default CombinedInput;
