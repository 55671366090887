import CommonInfo from './general/CommonInfo';
import CustomerFrom from './general/CustomerFrom';
import ExpansionSection from 'Components/display/ExpansionPanel/ExpansionSection';
import LocaleKeys from 'Localization/LocaleKeys';
import PropTypes from 'prop-types';
import React from 'react';

const General = (props) => (
  <ExpansionSection data={props.data} onChange={props.onChange}>
    <CommonInfo label={LocaleKeys.labels.commonInfo} />
    <CustomerFrom label={LocaleKeys.labels.customerFrom} />
  </ExpansionSection>
);

General.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
};

export default General;
