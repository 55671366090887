import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { Box, styled, Divider, Card, CardHeader, Chip, Typography, CircularProgress } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { getLibraryMappingsProjection, getInputMappingTypes } from 'Api/devices';
import { InputMappingTypes } from 'Constants/InputMappingTypes';
import { withFormik } from 'Components/formik/formikWrappers';
import { TransferList } from 'Components/formik/fields';

const MAPPINGS_PREDICTION_LIMIT = 50;

const MAPPINGS_LABELS = {
  [InputMappingTypes.ANALOG]: LocaleKeys.labels.analogMappings,
  [InputMappingTypes.DIGITAL]: LocaleKeys.labels.digitalMappings,
  [InputMappingTypes.KEYWORD]: LocaleKeys.labels.keywordMappings,
  [InputMappingTypes.TIME]: LocaleKeys.labels.timeMappings,
  [InputMappingTypes.SYSTEM]: LocaleKeys.labels.systemMappings,
};

const groupSystemMappingsProjection = (mappings) => {
  const groups = mappings.reduce(
    (groups, item) => ({
      ...groups,
      [item.inputMappingType]: [...(groups[item.inputMappingType] || []), item],
    }),
    {}
  );

  return Object.entries(groups).map(([key, value]) => ({
    inputMappingType: parseInt(key),
    mappings: value.map(({ name }) => ({ name })),
  }));
};

const getMappingsPreview = (mappingSet, usedInputMappings) => {
  return mappingSet.mappings
    ?.map((item) => {
      const isSelected = !!usedInputMappings.find(
        (mapping) => mapping.inputMappingType === mappingSet.inputMappingType && mapping.name === item.name
      );

      return { ...item, isSelected };
    })
    .sort(
      (a, b) =>
        b.isSelected - a.isSelected ||
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
    );
};

const getFormulasInputs = (formulas, inputMappings) => {
  const arr = [];

  formulas.forEach((formula) =>
    formula.sections.forEach((section) =>
      section.conditions.forEach((condition) => {
        const input = inputMappings.find((item) => item.externalId === condition.inputMappingExternalId);

        if (input) {
          arr.push({
            name: input.name,
            inputMappingType: input.inputMappingType,
          });
        }
      })
    )
  );

  return arr;
};

const Container = styled(Box)({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  maxHeight: 'calc(100vh - 220px)',
});
const Column = styled(Box)(({ maxWidth }) => ({
  display: 'flex',
  padding: 4,
  maxWidth,
  maxHeight: '100%',
}));
const InfoOutlinedStyled = styled(InfoOutlined)({
  marginRight: 4,
});
const CardStyled = styled(Card)({
  padding: 2,
  margin: 2,
});
const ChipStyled = styled(Chip)({
  margin: 4,
});
const PreviewCard = ({ mappingType, mappings }) => (
  <CardStyled>
    <CardHeader subheader={`${T.translate(MAPPINGS_LABELS[mappingType.value])}: (${mappings?.length || 0})`} />
    <Divider />
    {mappings?.map((item, index) => (
      <ChipStyled key={index} label={item.name} color={item.isSelected ? 'primary' : 'default'} />
    ))}
  </CardStyled>
);
const LibraryPreview = ({ library, mappingTypes, isLoadingPredictions, usedInputMappings }) => (
  <Box overflow={'auto'} height={'100%'} width={'100%'}>
    <Box display={'flex'} flexDirection={'column'}>
      {library.map((mappingSet) => {
        if (
          isLoadingPredictions &&
          [InputMappingTypes.ANALOG, InputMappingTypes.DIGITAL, InputMappingTypes.KEYWORD].includes(
            mappingSet.inputMappingType
          )
        ) {
          return <CircularProgress />;
        }

        return (
          <PreviewCard
            key={mappingSet.inputMappingType}
            mappingType={mappingTypes.find((mappingType) => mappingType.value === mappingSet.inputMappingType)}
            mappings={getMappingsPreview(mappingSet, usedInputMappings)}
          />
        );
      })}
    </Box>
  </Box>
);

const AssetAssignment = ({ data, assets, inputMappings, systemInputs, isLoadingAssets }) => {
  const [libraryMappingsProjection, setLibraryMappingsProjection] = useState([]);
  const [mappingTypes, setMappingTypes] = useState();
  const [isLoadingPredictions, setIsLoadingPredictions] = useState(false);
  const { startFormulas, endFormulas } = data;

  useEffect(() => {
    getInputMappingTypes().then((response) => {
      setMappingTypes(response.data);
    });
  }, []);

  useEffect(() => {
    if (data.assignedAssets.length > MAPPINGS_PREDICTION_LIMIT) {
      return;
    }

    if (data.assignedAssets.length) {
      const assetIds = data.assignedAssets.join(',');

      setIsLoadingPredictions(true);
      getLibraryMappingsProjection(assetIds).then(({ data }) => {
        setLibraryMappingsProjection(data);
        setIsLoadingPredictions(false);
      });
    } else {
      setLibraryMappingsProjection([]);
    }
  }, [data.assignedAssets, data.assignedAssets.length]);

  const usedInputMappings = useMemo(() => {
    const startInputs = getFormulasInputs(startFormulas, inputMappings);
    const endInputs = getFormulasInputs(endFormulas, inputMappings);

    return [...startInputs, ...endInputs];
  }, [inputMappings, startFormulas, endFormulas]);

  const mappingsProjection = useMemo(() => {
    const systemMappingsProjection = groupSystemMappingsProjection(systemInputs);

    return [...systemMappingsProjection, ...libraryMappingsProjection].sort(
      (a, b) => a.inputMappingType - b.inputMappingType
    );
  }, [systemInputs, libraryMappingsProjection]);

  if (!data || !mappingTypes) {
    return <CircularProgress />;
  }

  return (
    <Container>
      <Column maxWidth={'69%'}>
        <TransferList
          optionsLabel={T.translate(LocaleKeys.labels.devices)}
          valueLabel={T.translate(LocaleKeys.labels.assignedAssets)}
          options={assets}
          name="assignedAssets"
          displayField="name"
          valueField="externalId"
          disabled={isLoadingAssets}
        />
      </Column>
      <Column width={'30%'}>
        {data.assignedAssets.length >= MAPPINGS_PREDICTION_LIMIT && (
          <Box display={'flex'}>
            <InfoOutlinedStyled />
            <Typography>
              {T.translate(LocaleKeys.messages.exceededSelectedDevicesLimitForMappingsPreview)}:{' '}
              {MAPPINGS_PREDICTION_LIMIT}
            </Typography>
          </Box>
        )}
        <LibraryPreview
          library={mappingsProjection}
          mappingTypes={mappingTypes}
          isLoadingPredictions={isLoadingPredictions}
          usedInputMappings={usedInputMappings}
        />
      </Column>
    </Container>
  );
};

const withAssetsUpdate =
  (Component) =>
  ({ fetchAssets, ...props }) => {
    useEffect(() => {
      fetchAssets(props.data);

      // eslint-disable-next-line
    }, [fetchAssets]);

    return <Component {...props} />;
  };

export const AssetAssignmentNew = compose(withFormik, withAssetsUpdate)(AssetAssignment);

AssetAssignment.propTypes = {
  data: PropTypes.object,
  assets: PropTypes.array,
  inputMappings: PropTypes.array,
  systemInputs: PropTypes.array,
  isLoadingAssets: PropTypes.bool,
};

export default withFormik(AssetAssignment);
