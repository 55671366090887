import React, { memo } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import { Marker, Tooltip, Popup } from 'react-leaflet';
import ReactDOMServer from 'react-dom/server';
import Icon from './../pages/Dashboard/Icon';
import Config from 'Config';

const LeafIcon = L.Icon.extend({
  options: {
    iconSize: [40, 40],
    className: 'leaflet-markerIcon',
  },
});

const ApiIconMarker = ({ position, tooltipText, iconId, color, device, onMouseDown, ...props }) => {
  return (
    <Marker
      position={[position.latitude, position.longitude]}
      icon={
        iconId
          ? new LeafIcon({
              iconUrl: `${Config.apiDefaultURL}/devicestore/api/resources/${iconId}/image`,
            })
          : L.divIcon({
              className: '',
              html: ReactDOMServer.renderToString(<Icon color={color} style={{ pointerEvents: 'none' }} />),
              iconSize: [40, 40],
            })
      }
      onMouseDown={(e) => onMouseDown(e, device)}
      {...props}
    >
      <Popup
        direction={'top'}
        offset={[0, -6]}
        autoClose={false}
        autoPan={false}
        closeButton={false}
        closeOnClick={false}
      >
        {tooltipText}
      </Popup>
      <Tooltip direction={'top'} offset={[0, -20]}>
        {tooltipText}
      </Tooltip>
    </Marker>
  );
};

export const areEqual = (prevProps, nextProps) => {
  if (prevProps.tooltipText !== nextProps.tooltipText) {
    return false;
  }
  if (prevProps.position.latitude !== nextProps.position.latitude) {
    return false;
  }
  if (prevProps.position.longitude !== nextProps.position.longitude) {
    return false;
  }

  return true;
};

const ApiIconMarkerMemoized = memo(ApiIconMarker, areEqual);

ApiIconMarker.propTypes = {
  position: PropTypes.object.isRequired,
  tooltipText: PropTypes.string,
  iconId: PropTypes.string,
  color: PropTypes.string,
  device: PropTypes.object,
  onMouseDown: PropTypes.func,
};

ApiIconMarker.defaultProps = {
  onMouseDown: () => {},
};

export default ApiIconMarkerMemoized;
