import LabelsLocaleKeys from './LabelsLocaleKeys';
import MessagesLocaleKeys from './MessagesLocaleKeys';
import RoutesLocaleKeys from './RoutesLocaleKeys';
import ValidationLocaleKeys from './ValidationLocaleKeys';
import ValuesLocaleKeys from './ValuesLocaleKeys';
import TooltipsLocaleKeys from './TooltipsLocaleKeys';

const LocaleKeys = {
  labels: LabelsLocaleKeys,
  messages: MessagesLocaleKeys,
  validation: ValidationLocaleKeys,
  routes: RoutesLocaleKeys,
  values: ValuesLocaleKeys,
  tooltips: TooltipsLocaleKeys,
};

export default LocaleKeys;
