import CloseIcon from '@material-ui/icons/Close';
import Dropdown from 'Components/inputs/Dropdown';
import { IconButton } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import LocaleKeys from 'Localization/LocaleKeys';
import PropTypes from 'prop-types';
import React from 'react';
import T from 'i18n-react';
import TextInput from 'Components/inputs/TextInput';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dropdown: {
    marginLeft: 10,
    marginTop: 16,
    minWidth: '200px',
  },
});

class SearchBar extends React.Component {
  state = {
    data: '',
    name: '',
    labelWidth: 0,
    value: this.props.groupFilters,
    selectedFilters: [],
  };

  handleChange = (event) => {
    this.props.selectionChange(event.target);
    const selectedFilters = this.state.selectedFilters;

    selectedFilters[event.target.name] = event.target.value;
    this.setState((prevState) => ({
      ...prevState,
      selectedFilters: selectedFilters,
    }));
  };

  selectionChange = (event) => {
    this.props.selectionChange(event);
  };

  render() {
    const { classes } = this.props;
    const value = this.props.generalFilterValue;

    return (
      <div className={classes.root}>
        <TextInput
          label={T.translate(LocaleKeys.labels['filter'])}
          autoFocus
          debounceTimer={0}
          name={'generalFilter'}
          onChange={this.props.setGeneralFilter}
          value={value}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Remove filter"
                  onClick={this.props.removeGeneralFilter}
                  id={`table-general-filter-clear`}
                  disabled={!value}
                >
                  <CloseIcon style={{ visibility: !!value ? 'visible' : 'hidden' }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          id={`table-general-filter-input`}
        />
        {Object.keys(this.props.groupFilters).map((element, i) => {
          return (
            <Dropdown
              key={i}
              data={this.props.groupFilters[element]}
              value={this.state.selectedFilters[element]}
              name={element}
              onChange={this.handleChange}
              className={classes.dropdown}
            />
          );
        })}
      </div>
    );
  }
}

SearchBar.propTypes = {
  groupFilters: PropTypes.array,
  groupFiltersValues: PropTypes.array,
  selectionChange: PropTypes.func,
  generalFilterValue: PropTypes.string,
  setGeneralFilter: PropTypes.func,
  removeGeneralFilter: PropTypes.func,
};

export default withStyles(styles)(SearchBar);
