import { deleteValueIfExists, getValue, putValueCreateOrUpdate } from 'Api/configurationStore';
import { useCallback, useEffect, useState } from 'react';

export const useConfigurationStore = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(initialValue);

  useEffect(() => {
    if (!key || typeof key !== 'string') {
      throw new Error(`Invalid configuration store 'key' param passed to useConfigurationStore() hook`);
    }

    getValue(key).then((response) => {
      if (response.status === 200) {
        setStoredValue(response.data);
      }
    });
  }, [key]);

  const setValue = useCallback(
    (value) => {
      putValueCreateOrUpdate(key, { value: JSON.stringify(value) }).finally(() => {
        setStoredValue(value);
      });
    },
    [key]
  );

  const deleteValue = useCallback(() => {
    deleteValueIfExists(key).finally(() => {
      setStoredValue(initialValue);
    });
  }, [key, initialValue]);

  return [storedValue, setValue, deleteValue];
};
