import React, { useState, useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Tooltip, Badge, TableRow, styled, withStyles } from '@material-ui/core';
import {
  SignalWifi4Bar,
  SignalWifiOff,
  PermScanWifi,
  LocalParking,
  Warning,
  CallMissedOutgoing,
  Grade as GradeIcon,
  Help,
} from '@material-ui/icons';
import T from 'i18n-react';
import { format } from 'date-fns';
import DeviceIcon from 'Components/display/DeviceIcon';
import RelativeTime from 'Components/display/progress/RelativeTime';
import LinesEllipsis from 'Components/display/LinesEllipsis';
import { RefreshAllButton, RefreshButton } from './RefreshButton';
import { TcpIpStatuses } from 'Constants/TcpIpStatuses';

const isMacPatform = navigator.platform.indexOf('Mac') > -1;

const ALARM_TYPE = {
  ALARM: 1,
  STATUS: 2,
};
const ALARM_COLORS = {
  0: '#d9001b',
  [ALARM_TYPE.ALARM]: '#d9001b',
  [ALARM_TYPE.STATUS]: '#F59A22',
};
const MAX_DEVICE_NAME_LENGTH = 18;

const TooltipText = styled('div')({
  minWidth: '100px',
  whiteSpace: 'pre-wrap',
});
const renderDeviceName = (device) => {
  if (!device.name) {
    return 'NULL';
  } else {
    return (
      <Tooltip
        title={<TooltipText>{`${device.name}${device.description ? `\n\n${device.description}` : ''}`}</TooltipText>}
      >
        <strong>
          {device.name.length > MAX_DEVICE_NAME_LENGTH
            ? `${device.name.substr(0, MAX_DEVICE_NAME_LENGTH - 3)}...`
            : device.name}
        </strong>
      </Tooltip>
    );
  }
};

const RowStyled = styled(TableRow)({
  display: 'grid',
  gridTemplateColumns: '125px 50px 50px 110px 200px 200px 50px',
  maxHeight: '100%',
  maxWidth: '100%',
  cursor: 'pointer',
  paddingRight: typeof InstallTrigger !== 'undefined' ? 50 : 0,
});
const RowFull = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

const HeaderRow = styled(RowStyled)({
  backgroundColor: '#f5f5f5',
  zIndex: 1000,
  position: 'relative',
  left: 0,
  width: 890,
  maxWidth: 'fit-content',
  textAlign: 'center',
});

const Cell = styled('div')({
  border: '1px solid #DDEFEF',
  borderWidth: '0 1px 1px 0',
  height: 50,
  fontSize: 12,
  padding: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  wordBreak: 'break-all',
});
const CellSticky = styled(Cell)(({ selected, hovered, theme }) => ({
  position: 'sticky',
  zIndex: 10,
  minWidth: 50,
  backgroundColor: hovered ? theme.palette.grey[100] : selected ? theme.palette.grey[200] : '#fff',
}));
const CellStickyLeft = styled(CellSticky)({
  left: 0,
});
const CellStickyRight = styled(CellSticky)({
  right: 0,
  borderLeftWidth: 1,
});
const HeaderStickyRight = styled(CellSticky)({
  right: isMacPatform ? 0 : 17,
  backgroundColor: '#f5f5f5',
  zIndex: 1001,
  borderLeftWidth: 1,
  '&:after': {
    content: isMacPatform ? 'none' : '""',
    width: 17,
    minHeight: 50,
    backgroundColor: 'white',
    position: 'absolute',
    right: -18,
  },
});
const HeaderStickyLeft = styled(CellSticky)({
  backgroundColor: '#f5f5f5',
  zIndex: 1001,
  maxWidth: 'fit-content',
  textAlign: 'center',
});

export const Header = forwardRef(({ options, devices }, ref) => {
  const COLUMNS = [
    T.translate('name'),
    'COM',
    T.translate('speed'),
    T.translate('relativeTime'),
    T.translate('currentAssetStatus'),
    T.translate('location'),
  ];

  return (
    <RowFull>
      <HeaderStickyLeft>{T.translate('icon').toUpperCase()}</HeaderStickyLeft>
      <HeaderRow ref={ref}>
        {options.trail && COLUMNS.splice(COLUMNS.length, 0, T.translate('trail'))}
        {COLUMNS.map((label) => {
          return <Cell key={label}>{label.toUpperCase()}</Cell>;
        })}
      </HeaderRow>
      <HeaderStickyRight>
        <RefreshAllButton devices={devices} />
      </HeaderStickyRight>
    </RowFull>
  );
});
Header.displayName = 'Header';

const StyledBadge = withStyles({
  badge: {
    padding: 2,
    right: 5,
    bottom: 7,
    minWidth: 15,
    width: 15,
    height: 15,
    backgroundColor: 'black',
    '&:hover': {
      visible: true,
    },
    '& svg': {
      width: 15,
    },
  },
})(Badge);

const WarningStyled = styled(Warning)({
  fill: ({ fill }) => fill,
  height: 15,
  width: 15,
  paddingBottom: 2,
});

const CurrentAssetStatus = ({ createdAt, message, alarmType }) => (
  <>
    <Box display="flex" justifyContent="center" alignItems="center">
      <WarningStyled fill={ALARM_COLORS[alarmType || 0]} />
      {format(new Date(createdAt), 'HH:mm dd-MM-yyyy')}
    </Box>
    <div>{message}</div>
  </>
);

const ProgressOutside = styled(({ color, ...props }) => <div {...props} />)({
  height: 4,
  background: ({ color }) => `${color}75`,
  textAlign: 'left',
});
const ProgressInside = styled(({ color, ...props }) => <div {...props} />)({
  height: '100%',
  width: '0%',
  background: (props) => props.color,
});
const Progress = ({ value, color }) => (
  <ProgressOutside color={color}>
    <ProgressInside
      color={color}
      style={{
        width: `${value}%`,
      }}
    />
  </ProgressOutside>
);

const SpeedMode = ({ isParked, value, unit }) => {
  if (isParked) {
    return <LocalParking style={{ margin: 'auto' }} />;
  } else if (isParked === null || value === null) {
    return <Help style={{ margin: 'auto' }} />;
  }

  return `${Math.round(value)} ${unit}`;
};

const TcpIpStatusIndicator = ({ tcpIpStatusIndicator }) => {
  switch (tcpIpStatusIndicator) {
    case TcpIpStatuses.CONNECTED:
      return <SignalWifi4Bar title="connected" style={{ margin: 'auto' }} />;
    case TcpIpStatuses.UNKNOWN:
      return <PermScanWifi title="unknown" style={{ margin: 'auto' }} />;
    case TcpIpStatuses.DISCONNECTED:
      return <SignalWifiOff title="disconnected" style={{ margin: 'auto' }} />;
    default:
      return null;
  }
};

const DeviceLocation = ({ address, error, latitude, longitude }) => {
  const text = error ? T.translate('errorWhileFetchingData') : address;

  return !!text ? (
    <LinesEllipsis maxLine={2} showTooltip text={text} />
  ) : (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box display="flex" flexDirection="column" alignItems="flex-start" ml={2}>
        <div>{latitude}</div>
        <div>{longitude}</div>
      </Box>
    </Box>
  );
};

const AssetRow = ({
  device,
  selectedAssetIds,
  setSelectedAssetIds,
  options,
  handleRightClick,
  favClick,
  selectDeviceGeoPos,
  deviceTrail,
  setGeoPosMenuActive,
  setMenuPos,
  favDevices,
}) => {
  const [hovered, setHovered] = useState();
  const selected = selectedAssetIds.includes(device.externalId);

  const handleClickBadge = useCallback((e) => favClick(e, device.externalId), [favClick, device.externalId]);
  const handleRowClick = () => {
    if (!device.position && options.multiple) {
      return;
    } else if (selected) {
      setSelectedAssetIds(selectedAssetIds.filter((assetId) => assetId !== device.externalId));
    } else {
      setSelectedAssetIds(options.multiple ? [...selectedAssetIds, device.externalId] : [device.externalId]);
    }
  };

  return (
    <RowFull>
      <CellStickyLeft selected={selected} hovered={hovered}>
        <StyledBadge
          onMouseOver={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={<GradeIcon onClick={handleClickBadge} />}
          color="secondary"
          invisible={!hovered && !favDevices.includes(device.externalId)}
        >
          <DeviceIcon iconId={device.iconId} color={device.color} />
        </StyledBadge>
      </CellStickyLeft>
      <RowStyled
        selected={selected}
        onClick={handleRowClick}
        onMouseOver={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onContextMenu={(e) => {
          handleRightClick(e, device.externalId);
        }}
        hover
      >
        <Cell>{renderDeviceName(device)}</Cell>
        <Cell align="center">
          <TcpIpStatusIndicator tcpIpStatusIndicator={device.tcpIpStatusIndicator} />
        </Cell>
        <Cell align="center">
          {device.speedMode && (
            <SpeedMode
              isParked={device.speedMode.isParked}
              value={device.speedMode.value}
              unit={device.speedMode.unit}
            />
          )}
        </Cell>
        <Cell align="center">
          {device.statusMode && (
            <RelativeTime
              timestamp={device.statusMode.lastAt}
              warning={device.statusMode.warning}
              alarm={device.statusMode.alarm}
              showTimeAgo
            >
              <Progress />
            </RelativeTime>
          )}
        </Cell>
        <Cell align="center">
          {device.lastMessage && (
            <CurrentAssetStatus
              message={device.lastMessage.message}
              createdAt={device.lastMessage.createdAt}
              alarmType={device.lastMessage.alarmType}
            />
          )}
        </Cell>
        <Cell align="center">
          {device.position && (
            <DeviceLocation
              address={device.position.address}
              error={device.position.error}
              latitude={device.position.latitude}
              longitude={device.position.longitude}
            />
          )}
        </Cell>
        {options.trail && (
          <Cell>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                selectDeviceGeoPos(device.externalId);
              }}
              onContextMenu={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setMenuPos({ top: e.pageY, left: e.pageX });
                setGeoPosMenuActive(device.externalId);
              }}
            >
              <CallMissedOutgoing
                color={
                  deviceTrail.filter((d) => d.externalId === device.externalId)[0]?.tracked ? 'primary' : 'inherit'
                }
              />
            </IconButton>
          </Cell>
        )}
      </RowStyled>
      <CellStickyRight align="center" selected={selected} hovered={hovered}>
        {device.tcpIpStatusIndicator === TcpIpStatuses.CONNECTED && device.isRefreshable && (
          <RefreshButton device={device} />
        )}
      </CellStickyRight>
    </RowFull>
  );
};

AssetRow.propTypes = {
  device: PropTypes.object,
  deviceTrail: PropTypes.array,
  favDevices: PropTypes.array,
  favClick: PropTypes.func,
  handleRightClick: PropTypes.func,
  options: PropTypes.object,
  selectDeviceGeoPos: PropTypes.func,
  selectedAssetIds: PropTypes.array,
  setSelectedAssetIds: PropTypes.func,
  setGeoPosMenuActive: PropTypes.func,
  setMenuPos: PropTypes.func,
};

export default AssetRow;
