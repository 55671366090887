import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { Paper, Box, Typography, IconButton, CircularProgress } from '@material-ui/core';
import { LockOpen, Close, Lock } from '@material-ui/icons';
import DeviceIcon from 'Components/display/DeviceIcon';
import { getDevice } from 'redux/assets/selectors';
import RelativeTime from 'Components/display/progress/RelativeTime';

const PaperStyled = styled(Paper)({
  minWidth: 276,
  height: 72,
  display: 'flex',
  marginRight: 20,
  fontSize: 10,
  color: 'rgba(0,0,0,.6)',
  overflow: 'hidden',
  flexWrap: 'wrap',
  position: 'relative',
  cursor: 'pointer',
});
const Title = styled(Typography)({
  fontSize: 14,
  fontWeight: 'bold',
});
const LockWrapper = styled('div')({
  padding: 8,
  display: 'flex',
  alignItems: 'center',
});
const IconButtonStyled = styled(IconButton)({
  position: 'absolute',
  right: '0',
  '& svg': {
    fontSize: '1rem',
  },
});
const Progress = ({ value, color }) => (
  <div
    style={{
      backgroundColor: `${color}33`,
      position: 'absolute',
      bottom: 0,
      width: '100%',
    }}
  >
    <div
      style={{
        width: `${value}%`,
        height: 3,
        backgroundColor: color,
      }}
    />
  </div>
);

const Loading = styled(CircularProgress)({
  margin: 'auto',
});

const Item = ({ color, iconId, name, message, statusMode, isLoading, lock, onClick, onClickClose }) => {
  return (
    <PaperStyled onClick={onClick}>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <Box padding={2}>
            <DeviceIcon iconId={iconId} color={color} />
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center" width={160}>
            <Title variant="h3">{name}</Title>
            {message}
          </Box>
          <LockWrapper>{lock ? <Lock /> : <LockOpen />}</LockWrapper>
          {statusMode && (
            <RelativeTime timestamp={statusMode.lastAt} warning={statusMode.warning} alarm={statusMode.alarm}>
              <Progress />
            </RelativeTime>
          )}
        </>
      )}
      {!lock && (
        <IconButtonStyled
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            onClickClose(e);
          }}
        >
          <Close />
        </IconButtonStyled>
      )}
    </PaperStyled>
  );
};

Item.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string,
  message: PropTypes.string,
  statusMode: PropTypes.shape(),
  lock: PropTypes.bool,
  onClick: PropTypes.func,
  onClickClose: PropTypes.func,
};

const ItemController = ({ alarm, onClick, onClickClose, device }) => {
  let data = {};

  if (device) {
    data = {
      color: device.color,
      name: device.name,
      statusMode: device.statusMode,
      iconId: device.iconId,
    };

    if (alarm) {
      data.name = alarm.assetName;
      data.message = alarm.alarmName;
      data.alarmId = alarm.alarmId;
    }
  }

  return (
    <Item
      {...(data || { isLoading: true })}
      lock={!!alarm}
      onClick={() => {
        onClick(data);
      }}
      onClickClose={onClickClose}
    />
  );
};

const mapState = (state, { assetId, alarm }) => {
  let externalId = assetId;

  if (alarm) {
    externalId = alarm.assetId;
  }

  return {
    device: getDevice(state, externalId),
    alarm,
  };
};

export default compose(connect(mapState))(ItemController);
