import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import { withFormik } from 'Components/formik/formikWrappers';
import { Checkbox, TextField } from 'Components/formik/fields';

const FrameRetention = (props) => {
  return (
    <ColumnDisplayer>
      <Checkbox label={T.translate(LocaleKeys.labels.useForIndefiniteTime)} name="useForIndefiniteTime" />
      {!props.data.useForIndefiniteTime && (
        <TextField label={T.translate(LocaleKeys.labels.months)} type="number" name="frameRelatedPeriod" />
      )}
    </ColumnDisplayer>
  );
};

FrameRetention.propTypes = {
  data: PropTypes.object,
};

export default withFormik(FrameRetention);
