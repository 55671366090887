import { withField } from './formikWrappers';
import TextInputRaw from 'Components/inputs/TextInput';
import DropdownWithApiCallRaw from 'Components/inputs/DropdownWithApiCall';
import CheckboxRaw from 'Components/inputs/Checkbox';
import PhoneInputRaw from 'Components/inputs/PhoneInput';
import MultiSelectWithApiCallRaw from 'Components/inputs/MultiSelectWithApiCall';
import MultiselectWithFilterWithApiCallRaw from 'Components/inputs/MultiselectWithFilterWithApiCall';
import DateTimeInputRaw from 'Components/inputs/DateTime';
import TransferListRaw from 'Components/inputs/TransferList';
import MaskedTextInputRaw from 'Components/inputs/MaskedTextInput';
import AlarmStatusImageRaw from 'Components/inputs/AlarmStatusImage';
import DropdownRaw from 'Components/inputs/Dropdown';
import MultiSelectRaw from 'Components/inputs/MultiSelect';
import RadioInputRaw from 'Components/inputs/RadioInput';
import TextOutputRaw from 'Components/TextOutput';

export const TextField = withField(TextInputRaw);
export const DropdownWithApiCall = withField(DropdownWithApiCallRaw);
export const Checkbox = withField(CheckboxRaw);
export const PhoneInput = withField(PhoneInputRaw);
export const MultiSelectWithApiCall = withField(MultiSelectWithApiCallRaw);
export const MultiSelectWithFilterWithApiCall = withField(MultiselectWithFilterWithApiCallRaw);
export const DateTimeInput = withField(DateTimeInputRaw);
export const TransferList = withField(TransferListRaw);
export const MaskedTextInput = withField(MaskedTextInputRaw);
export const AlarmStatusImage = withField(AlarmStatusImageRaw);
export const Dropdown = withField(DropdownRaw);
export const MultiSelect = withField(MultiSelectRaw);
export const RadioInput = withField(RadioInputRaw);
export const TextOutput = withField(TextOutputRaw);
