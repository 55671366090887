import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import MultiselectWithFilter from './MultiselectWithFilter';

const MultiselectWithFilterWithApiCall = ({ apiCall, getOption, ...props }) => {
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    apiCall &&
      apiCall().then((response) => {
        setData(response.data);
        setIsLoaded(true);
      });
  }, [apiCall]);

  const onChange = (event) => {
    if (event.target.value !== props.value) {
      props.onChange(event);
    }
  };

  return isLoaded && <MultiselectWithFilter {...props} data={data.map(getOption)} onChange={onChange} />;
};

MultiselectWithFilterWithApiCall.propTypes = {
  ...MultiselectWithFilter.propTypes,
  apiCall: PropTypes.func,
  getOption: PropTypes.func,
};

MultiselectWithFilterWithApiCall.defaultProps = {
  translateLabel: false,
  readOnly: false,
  getOption: (option) => option,
};

export default MultiselectWithFilterWithApiCall;
