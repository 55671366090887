export default {
  applicationName: 'Cshark Platform',
  apiDefaultURL: process.env.REACT_APP_API_URL,
  apiTimeout: process.env.REACT_APP_API_TIMEOUT,
  authSettings: {
    authority: process.env.REACT_APP_AUTHORITY,
    clientId: process.env.REACT_APP_CLIENT_ID,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    silentRedirectUri: process.env.REACT_APP_SILENT_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
  },
};
