import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Divider, IconButton } from '@material-ui/core';
import AuthManager from 'Auth/AuthManager';
import Config from 'Config';
import withPermissions from 'hocs/withPermissions';
import SwitchSiteDialog from './SwitchSiteDialog';
import SwitchUserLanguage from './SwitchUserLanguage';
import { Permissions } from 'Constants/permissions';
import { LocalStorageKeys } from 'Constants/LocalStorageKeys';

class UserMenu extends React.Component {
  state = {
    anchorEl: null,
    switchSiteDialogOpen: false,
    switchUserLanguageDialogOpen: false,
    userTenantIds: [],
  };

  componentDidMount() {
    this.getUserTenants();
  }

  getUserTenants = async () => {
    try {
      const user = await AuthManager.getUserAsync();

      this.setState({ userTenantIds: user.profile.Tenants.split('|') });
    } catch (error) {
      Sentry.captureMessage(error);
    }
  };

  handleClick = (event) => {
    event.preventDefault();
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogout = () => {
    AuthManager.logout();
  };

  handleChangePassword = () => {
    const changePasswordUrl =
      `${Config.authSettings.authority}/account/updatePassword?` +
      `returnUrl=${window.location.origin}&` +
      `clientId=${Config.authSettings.clientId}&` +
      `culture=${localStorage.getItem(LocalStorageKeys.USER_LANGUAGE)}`;

    window.location.replace(changePasswordUrl);
  };

  handleSwitchSite = () => {
    this.setState({ switchSiteDialogOpen: true });
    this.handleClose();
  };

  toggleDialog = (dialog) => {
    this.setState({ [dialog]: !this.state[dialog] });
  };

  render() {
    const { anchorEl, userTenantIds, switchSiteDialogOpen, switchUserLanguageDialogOpen } = this.state;

    return (
      <>
        <IconButton
          title={this.props.userInfo && this.props.userInfo.login}
          onClick={this.handleClick}
          id={'usermenu-icon'}
        >
          <AccountCircle htmlColor="#fff" />
        </IconButton>
        <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
          <MenuItem onClick={() => this.toggleDialog('switchUserLanguageDialogOpen')} id={'usermenu-language'}>
            {T.translate(LocaleKeys.labels.language)}
          </MenuItem>
          <MenuItem onClick={this.handleChangePassword} id={'usermenu-changepassword'}>
            {T.translate(LocaleKeys.labels.changePassword)}
          </MenuItem>
          {this.props.permissions[Permissions.CanReadSites] && this.props.userInfo && userTenantIds.length > 1 && (
            <MenuItem onClick={this.handleSwitchSite} id={'usermenu-switchsite'}>
              {T.translate(LocaleKeys.labels.switchSite)}
            </MenuItem>
          )}

          <MenuItem>
            <a
              href="https://vecctor.atlassian.net/servicedesk/customer/portal/1"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'inherit' }}
            >
              {T.translate(LocaleKeys.labels.reportAProblem)}
            </a>
          </MenuItem>
          <Divider />
          <MenuItem onClick={this.handleLogout} id={'usermenu-logout'}>
            {T.translate(LocaleKeys.labels.signout)}
          </MenuItem>
        </Menu>

        <SwitchSiteDialog
          open={switchSiteDialogOpen}
          closeDialog={() => this.toggleDialog('switchSiteDialogOpen')}
          userTenantIds={userTenantIds}
        />
        <SwitchUserLanguage
          open={switchUserLanguageDialogOpen}
          closeDialog={() => this.toggleDialog('switchUserLanguageDialogOpen')}
        />
      </>
    );
  }
}

UserMenu.propTypes = {
  userInfo: PropTypes.object,
  permission: PropTypes.array,
};

export default withPermissions([])(UserMenu);
