import alarmSound from 'Assets/audio/Alien_Siren-KevanGC.mp3';

const assetsPaths = {
  img: {
    logo: require('./img/logo.png'),
  },
  icons: {
    AddPhotoAlternate: require('./icons/add_photo_alternate_black_24dp.svg'),
    TextFields: require('./icons/text_fields_black_24dp.svg'),
    BorderStyle: require('./icons/border_style_black_24dp.svg'),
    Timeline: require('./icons/timeline_black_24dp.svg'),
  },
  audio: {
    alarm: alarmSound,
  },
};

export default assetsPaths;
