import PropTypes from 'prop-types';
import React, { useState } from 'react';
import T from 'i18n-react';
import GenericDialog from 'Components/dialogs/GenericDialog';
import FileUploadWithInfo from './FileUploadWithInfo';
import LocaleKeys from 'Localization/LocaleKeys';

const isFileSizeCorrect = (file, maxSize) => {
  if (file.size <= maxSize) {
    return true;
  }

  return false;
};

const isExtensionSupported = (file, extensions) => {
  const fileExtension = file.name.split('.').pop();

  if (extensions.includes(fileExtension.toLowerCase())) {
    return true;
  }

  return false;
};

const FileUploadWithSizeValidation = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');

  return (
    <>
      <FileUploadWithInfo
        {...props}
        onChange={(e) => {
          const file = e.target.files[0];

          if (!isFileSizeCorrect(file, props.maxSize)) {
            setDialogTitle(LocaleKeys.messages.maximumImageSizeExceeded);
            setDialogMessage(LocaleKeys.messages.maximumImageSizeExceeded);
            setDialogOpen(true);
          } else {
            if (props.accept && !isExtensionSupported(file, props.accept)) {
              setDialogTitle(LocaleKeys.messages.notSupportedFileXtension);
              setDialogMessage(LocaleKeys.messages.thisFileExtensionIsNotSupported);
              setDialogOpen(true);
            } else {
              props.onChange(file);
            }
          }
        }}
      ></FileUploadWithInfo>
      <GenericDialog
        dialogOpen={dialogOpen}
        handleOk={() => {
          setDialogOpen(false);
        }}
        title={T.translate(dialogTitle)}
        message={T.translate(dialogMessage)}
        okLabel={T.translate(LocaleKeys.labels.ok)}
      />
    </>
  );
};

export default FileUploadWithSizeValidation;

FileUploadWithSizeValidation.propTypes = {
  label: PropTypes.string,
  maxSize: PropTypes.number,
  id: PropTypes.string,
  accept: PropTypes.string,
};
