import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React, { Component } from 'react';

import LocaleKeys from 'Localization/LocaleKeys';
import PropTypes from 'prop-types';
import T from 'i18n-react';

export default class GenericDialog extends Component {
  render() {
    return (
      <Dialog
        open={this.props.dialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {T.translate(this.props.title || LocaleKeys.messages.defaultConfirmDialogTitle)}
        </DialogTitle>
        <DialogContent>
          {this.props.content || (
            <DialogContentText id="alert-dialog-description">
              {T.translate(this.props.message || LocaleKeys.messages.defaultConfirmDialogMessage)}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          {this.props.handleCancel && (
            <Button onClick={this.props.handleCancel} color="primary">
              {T.translate(this.props.cancelLabel || LocaleKeys.labels.cancel)}
            </Button>
          )}
          {this.props.handleOk && (
            <Button onClick={this.props.handleOk} color="primary" autoFocus>
              {T.translate(this.props.okLabel || LocaleKeys.labels.confirm)}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

GenericDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  content: PropTypes.element,
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
};
