import React from 'react';
import PropTypes from 'prop-types';
import LocaleKeys from 'Localization/LocaleKeys';
import LocationMode from './location/LocationMode';
import { Paper } from '@material-ui/core';
import { withFormik } from 'Components/formik/formikWrappers';

const DeviceLocation = (props) => (
  <Paper>
    <LocationMode label={LocaleKeys.labels.mode} data={props.data} onChange={props.onChange} />
  </Paper>
);

DeviceLocation.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
};

export default withFormik(DeviceLocation);
