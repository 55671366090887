import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { SitesGroupsNew, SitesGroupsDetails } from 'routes/Routes';
import { getSiteGroups, deleteSiteGroup } from 'Api/sites';
import Table from 'Components/display/table/Table';
import { withRouter } from 'react-router-dom';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import DeleteDialog from 'Components/dialogs/DeleteDialog';
import LocaleKeys from 'Localization/LocaleKeys';
import TableActionButton from 'Components/display/table/TableActionButton';
import { compose } from 'redux';
import { Edit, Delete } from '@material-ui/icons';
import T from 'i18n-react';
import { styled, CircularProgress } from '@material-ui/core';

const TableStyled = styled(Table)({
  padding: 20,
});

const formatDataForTableView = (groups) =>
  Object.values(groups).map((group) => ({
    name: group.name,
    description: group.description,
    sites: group.sites,
    assignedUsers: group.hostedBy,
    createdBy: group.createdBy,
    id: group.id,
  }));

const SiteGroups = (props) => {
  const [sitesGroups, setSitesGroups] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [itemToHandle, setItemToHandle] = useState(null);

  useEffect(() => {
    getSiteGroups().then(({ data }) => {
      setSitesGroups(data);
    });
  }, []);

  const onEditClick = (name) => {
    const url = SitesGroupsDetails.getUrl({ name: name.replace(/\s/g, '-') });

    props.history.push(url);
  };

  const onAddClick = () => {
    const url = SitesGroupsNew.getUrl();

    props.history.push(url);
  };

  const getTableActionButtons = () => {
    const actionButtons = [];

    if (props.permissions[Permissions.CanEditSiteGroups]) {
      const editItemButton = (
        <TableActionButton
          key="editItemButton"
          onClick={onEditClick}
          clickparam={'name'}
          icon={<Edit />}
          tooltipText={T.translate(LocaleKeys.tooltips.editItem)}
        />
      );

      actionButtons.push(editItemButton);
    }
    if (props.permissions[Permissions.CanRemoveSiteGroups]) {
      const deleteItemButton = (
        <TableActionButton
          key="deleteItemButton"
          onClick={(id) => {
            setDialogOpen(true);
            setItemToHandle(id);
          }}
          clickparam={'id'}
          icon={<Delete />}
          tooltipText={T.translate(LocaleKeys.tooltips.deleteItem)}
        />
      );

      actionButtons.push(deleteItemButton);
    }

    return actionButtons;
  };

  if (!sitesGroups) {
    return <CircularProgress />;
  }

  const formattedTableData = formatDataForTableView(sitesGroups);
  const actionButtons = getTableActionButtons();

  return (
    <>
      <TableStyled
        data={formattedTableData}
        onRowDoubleClick={onEditClick}
        onAddClick={props.permissions[Permissions.CanAddSiteGroups] && onAddClick}
        redirect={'name'}
        hideColumns={['id']}
        actionButtons={actionButtons}
        tableName="siteGroups"
      />
      <DeleteDialog
        isOpen={dialogOpen}
        onConfirm={() => {
          return deleteSiteGroup(itemToHandle).then(() => {
            setSitesGroups((prev) => prev.filter((group) => group.id !== itemToHandle));
            setItemToHandle(null);
            setDialogOpen(false);
          });
        }}
        onCancel={() => {
          setItemToHandle(null);
          setDialogOpen(false);
        }}
        disableConfirm={!itemToHandle}
        title={T.translate(LocaleKeys.messages.deleteSiteGroupDialogTitle)}
        message={T.translate(LocaleKeys.messages.deleteSiteGroupDialogMessage)}
      />
    </>
  );
};

SiteGroups.propTypes = {
  history: PropTypes.object,
  permissions: PropTypes.object,
};

export default compose(
  withRouter,
  withPermissions([Permissions.CanAccessSiteGroupsPage, Permissions.CanReadSiteGroups])
)(SiteGroups);
