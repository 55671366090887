import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { TextField } from 'Components/formik/fields';
import Button from 'Components/Button';

const reasonsValidationSchema = Yup.object().shape({
  checkedReasons: Yup.array().optional(),
  otherComment: Yup.string().when('checkedReasons', {
    is: (checkedReasons) => !checkedReasons.length,
    then: Yup.string().required('thisFieldIsRequired').max(50, 'nameCannotExceed50Characters'),
    otherwise: Yup.string().optional().max(50, 'nameCannotExceed50Characters'),
  }),
});

const ReasonsDialog = ({ open, clickReasons, onSubmit, onCancel }) => {
  return (
    <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <Formik
        initialValues={{
          checkedReasons: [],
          otherComment: '',
        }}
        validationSchema={reasonsValidationSchema}
        validateOnMount
        onSubmit={onSubmit}
      >
        {({ values, isValid, isSubmitting, setFieldValue, resetForm }) => {
          return (
            <Form>
              <DialogTitle id="alert-dialog-title">
                {T.translate(LocaleKeys.validation.reasonCommentIsRequired)}
              </DialogTitle>
              <DialogContent>
                {!!clickReasons?.length && (
                  <FormControl>
                    <FormGroup>
                      {clickReasons.map((c, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              checked={values.checkedReasons.includes(c.reason)}
                              onChange={() => {
                                const currentIndex = values.checkedReasons.indexOf(c.reason);
                                const newChecked = [...values.checkedReasons];

                                if (currentIndex === -1) {
                                  newChecked.push(c.reason);
                                } else {
                                  newChecked.splice(currentIndex, 1);
                                }

                                setFieldValue('checkedReasons', newChecked);
                              }}
                              value={c.reason}
                            />
                          }
                          label={c.reason}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                )}
                <TextField
                  label={T.translate(LocaleKeys.labels.otherComment)}
                  name="otherComment"
                  multiline
                  fullWidth
                />
              </DialogContent>

              <DialogActions>
                <Button type="submit" autoFocus disabled={!isValid || isSubmitting} showProgress={isSubmitting}>
                  {T.translate(LocaleKeys.labels.submit)}
                </Button>
                <Button
                  onClick={() => {
                    resetForm();
                    onCancel();
                  }}
                  autoFocus
                >
                  {T.translate(LocaleKeys.labels.cancel)}
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

ReasonsDialog.propTypes = {
  open: PropTypes.bool,
  clickReasons: PropTypes.array,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ReasonsDialog;
