import * as Yup from 'yup';
import { getUserExistsByLogin, getDefaultEmailExists } from 'Api/users';
import { isNotEmpty, phoneNumberVectorRegExp } from 'Validators/SharedValidators';

const loginSchema = Yup.string()
  .required('thisFieldIsRequired')
  .matches(/^[A-Za-z0-9]+$/, 'nameShouldBeAlphanumericNoSpaceAllowed')
  .max(20, 'nameCannotExceed20Characters');

export const userLoginExists = async (value, initialValue, validationCache) => {
  if (!(await loginSchema.isValid(value))) {
    return false;
  }
  const result =
    validationCache.login?.value === value ? validationCache.login?.result : await getUserExistsByLogin(value);

  validationCache.login = { value, result };

  return initialValue === value || !result?.data;
};

export const getDefaultEmail = (values) => {
  return values?.find((e) => e.isDefault === true && isNotEmpty(e.emailAddress))?.emailAddress;
};

const emailAddressSchema = Yup.string().email('incorrectEmailAddress').required('thisFieldIsRequired');

export const userEmailExists = async (values, initialValues, validationCache) => {
  const defaultEmail = getDefaultEmail(values);

  if (!(await emailAddressSchema.isValid(defaultEmail))) {
    return false;
  }
  const result =
    validationCache.defaultEmail?.value === defaultEmail
      ? validationCache.defaultEmail?.result
      : await getDefaultEmailExists(defaultEmail);

  validationCache.defaultEmail = { value: defaultEmail, result };

  return getDefaultEmail(initialValues) === defaultEmail || !result?.data;
};

export const userValidation = (initialValues, validationCache) =>
  Yup.object().shape({
    login: loginSchema.test('userLoginExists', 'userWithThisLoginAlreadyExists', function (value) {
      return userLoginExists(value, initialValues.login, validationCache);
    }),
    emails: Yup.array()
      .of(
        Yup.object()
          .shape({
            emailAddress: emailAddressSchema,
          })
          .uniqueInCollection('valueMustBeUniqueInCollection', 'emailAddress')
      )
      .min(1, 'emailAddressIsRequired')
      .test('userEmailExists', 'emailIsAlreadyInUse', async function (value) {
        const defaultEmailIndex = value?.findIndex((e) => e.isDefault === true);

        if (defaultEmailIndex > -1 && !(await userEmailExists(value, initialValues.emails, validationCache))) {
          return this.createError({
            path: `emails.${defaultEmailIndex}.emailAddress`,
            message: 'emailIsAlreadyInUse',
          });
        }

        return true;
      }),
    phones: Yup.array().of(
      Yup.object()
        .shape({
          phoneNumber: Yup.string()
            .required('thisFieldIsRequired')
            .min(8, 'minPhoneNumberLength')
            .matches(phoneNumberVectorRegExp, 'incorrectPhoneNumber'),
          remark: Yup.string().nullable().optional().max(50, 'nameCannotExceed50Characters'),
        })
        .uniqueInCollection('valueMustBeUniqueInCollection', 'phoneNumber')
    ),
  });
