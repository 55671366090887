import { isDateCorrect } from 'Validators/SharedValidators';

export const addCurrentTimezoneOffset = (datetime) => {
  let value = datetime;

  if (isDateCorrect(datetime)) {
    const dateObj = new Date(datetime);

    value = new Date(dateObj.getTime() + dateObj.getTimezoneOffset() * 60 * 1000).toISOString();
  }

  return value;
};

export const subtractCurrentTimezoneOffset = (datetime) => {
  let value = datetime;

  if (isDateCorrect(datetime)) {
    const dateObj = new Date(datetime);

    value = new Date(dateObj.getTime() - dateObj.getTimezoneOffset() * 60 * 1000).toISOString();
  }

  return value;
};

export const isDate = (date) => {
  return new Date(date).toString() !== 'Invalid Date' && !isNaN(new Date(date));
};

export const getDateFromDateTime = (datetime) => {
  const dateObj = new Date(datetime);

  dateObj.setUTCHours(0);
  dateObj.setUTCSeconds(0);
  dateObj.setMilliseconds(0);
  dateObj.setMinutes(0);

  return dateObj;
};
