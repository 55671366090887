import AutoSuggest from '../../AutoSuggest';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import { getMapReverseGeocoding, getMapSearchSuggestions, getMapGeocoding } from 'Api/maps';
import Paper from '@material-ui/core/Paper';
import L from 'leaflet';
import React, { useEffect, useRef, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import { styled } from '@material-ui/core/styles';
import T from 'i18n-react';

const StyledPaper = styled(Paper)({
  display: 'flex',
  width: '100%',
  maxWidth: '256px',
});

const Search = styled(Paper)({
  height: 36,
});

const StyledInput = styled(InputBase)({
  padding: 3,
});

const StyledIconButton = styled(IconButton)({
  height: 34,
  width: 34,
  flex: 1,
  padding: 0,
  top: -3,
});

const MIN_SUGGESTION_LENGHT = 3;

const SearchInput = ({ map, className, toggleInputType, markers, style }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [openSuggestions, setOpenSuggestions] = useState(false);
  const [value, setValue] = useState('');
  const searchRef = useRef();

  useEffect(() => {
    if (searchRef.current) {
      L.DomEvent.disableClickPropagation(searchRef.current);
    }
  });

  const onChange = (event) => {
    const { value } = event.target;

    value.length >= MIN_SUGGESTION_LENGHT &&
      getMapSearchSuggestions(value).then((response) => {
        const Suggestions = [];

        response.data.results.forEach((result) => {
          Suggestions.push(result.address);
        });
        setSuggestions(Suggestions);
        setOpenSuggestions(true);
      });
    setValue(value);
  };

  const onFocus = (id) => {
    value.length >= MIN_SUGGESTION_LENGHT && setOpenSuggestions(id);
  };

  const handleSuggestionsClose = () => {
    setTimeout(() => {
      setOpenSuggestions(false);
    }, 200);
  };

  const suggestionClick = (value) => {
    setValue(value);
    setSuggestions([]);
    setOpenSuggestions(false);
  };

  const onSearch = () => {
    const icon = L.icon({
      iconUrl: 'https://cdn2.iconfinder.com/data/icons/flat-ui-icons-24-px/24/location-24-512.png',
      iconSize: [38, 38],
    });

    markers.eachLayer((layer) => {
      markers.removeLayer(layer);
    });

    if (value && value.search(/[A-z]|[-]/) !== -1) {
      getMapGeocoding(value).then(({ data }) => {
        if (data.locations?.length) {
          map.setView([data.locations[0].point.latitude, data.locations[0].point.longitude], 16);
          const marker = L.marker([data.locations[0].point.latitude, data.locations[0].point.longitude], {
            icon: icon,
          }).addTo(map);

          marker
            .bindPopup(L.popup({ offset: [0, -5], closeButton: false }).setContent(data.locations[0].address.label))
            .openPopup();
        }
      });
    } else if (value) {
      const pos = value.split(', ');

      getMapReverseGeocoding(pos[0], pos[1]).then(({ data }) => {
        if (data.locations?.length) {
          const marker = L.marker([data.locations[0].point.latitude, data.locations[0].point.longitude], {
            icon: icon,
          }).addTo(map);

          map.setView([pos[0], pos[1]], 16);
          marker.bindPopup(L.popup({ offset: [0, -5] }).setContent(data.locations[0].address.label)).openPopup();
        }
      });
    }
  };

  return (
    <div style={style}>
      <Search ref={searchRef} elevation={1}>
        <StyledInput
          placeholder={T.translate('search')}
          onChange={onChange}
          value={value}
          onFocus={onFocus}
          onBlur={handleSuggestionsClose}
          autoComplete="off"
          type="search"
        />

        <StyledIconButton aria-label="Search" onClick={onSearch}>
          <SearchIcon />
        </StyledIconButton>
        {toggleInputType && (
          <StyledIconButton aria-label={T.translate('search')} onClick={toggleInputType}>
            <DirectionsIcon />
          </StyledIconButton>
        )}
      </Search>
      <StyledPaper>
        <AutoSuggest
          open={openSuggestions}
          handleClose={handleSuggestionsClose}
          suggestions={suggestions}
          suggestionClick={suggestionClick}
        />
      </StyledPaper>
    </div>
  );
};

export default SearchInput;
