import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import { IconButton, styled } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import TooltipsLocaleKeys from 'Localization/TooltipsLocaleKeys';
import L from 'leaflet';

const ControlBar = styled('div')({
  backgroundClip: 'content-box',
  backgroundColor: 'white',
  border: '2px solid rgba(0,0,0,0.2)',
  borderRadius: '4px',
  visibility: ({ visibility }) => visibility,
});
const Control = styled(IconButton)({
  height: '30px',
  width: '30px',
  padding: '0',
  borderRadius: '0',
});
const ErrorOutlineIcon = styled(ErrorOutline)({
  fill: ({ fill }) => fill,
});

const isAssetMarker = (layer) =>
  layer instanceof L.Marker && !(layer instanceof L.MarkerCluster) && layer.options.isAssetMarker;

const AssetNames = ({ map, value, ...props }) => {
  const displayNamesRef = useRef(value);

  const openNonClusteredPopups = useCallback(
    (displayNames) => {
      if (map) {
        map.eachLayer((layer) => {
          if (isAssetMarker(layer)) {
            if (displayNames) {
              layer.openPopup();
            } else {
              layer.closePopup();
            }
          }
        });
      }
    },
    [map]
  );

  useEffect(() => {
    displayNamesRef.current = value;
    openNonClusteredPopups(value);
  }, [value, openNonClusteredPopups]);

  useEffect(() => {
    if (map) {
      map.on('layeradd', (event) => {
        const layer = event.layer;
        const displayNames = displayNamesRef.current;

        if (isAssetMarker(layer)) {
          if (displayNames) {
            layer.openPopup();
          }
        }
      });
    }
  }, [map]);

  return (
    <ControlBar style={props.style}>
      <Control
        onClick={(e) => {
          e.stopPropagation();
          props.onClick(!value);
        }}
        title={T.translate(value ? TooltipsLocaleKeys.disableLabels : TooltipsLocaleKeys.enableLabels)}
      >
        <ErrorOutlineIcon fill={value ? 'black' : 'gray'} />
      </Control>
    </ControlBar>
  );
};

AssetNames.propTypes = {
  map: PropTypes.object,
  style: PropTypes.object,
  value: PropTypes.bool,
  onClick: PropTypes.func,
};

export default AssetNames;
