import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  column: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  fields: {
    width: '25%',
    margin: '10px 4% !important',
    display: 'flex',
  },
});

export class ColumnDisplayer extends React.Component {
  render() {
    const children = this.props.children instanceof Array ? this.props.children : [this.props.children];

    return (
      <div className={this.props.classes.column}>
        {children
          .filter((child) => child !== null)
          .map((child, index) => {
            if (child) {
              return React.cloneElement(child, {
                ...child.props,
                className: `${this.props.classes.fields} ${child.props.className}`,
                key: index,
              });
            }

            return child;
          })}
      </div>
    );
  }
}

ColumnDisplayer.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
};

export default withStyles(styles)(ColumnDisplayer);
