import React, { useState, useEffect, useCallback } from 'react';
import { Divider, Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import DeviceIcon from 'Components/display/DeviceIcon';
import Notification from 'Components/display/Notification';
import { getAlarmLastByAssetId } from 'Api/notifications';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Config from 'Config';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { update } from 'redux/assets/actions';
import { getLoadingAssets } from 'redux/assets/selectors';
import Loader from 'Components/Loader';
import { NotificationStatuses } from 'Constants/NotificationStatuses';
import { CancelToken, isCancel } from 'Api/ApiClient';
import ErrorMessage from 'Components/display/ErrorMessage';
import { AlarmTypes } from 'Constants/AlarmTypes';

const Wrapper = styled('div')({
  height: '100%',
  margin: 10,
  width: '100%',
});

const Margin = styled('div')({
  margin: 10,
});

const ImagePlaceholder = styled('div')({
  backgroundColor: '#f2f2f2',
  color: '#aaaaaa',
  height: '299px',
  lineHeight: '300px',
  textAlign: 'center',
  verticalAlign: 'middle',
  width: '400px',
});

const DeviceImage = styled('img')({
  height: 300,
  width: 400,
});

const AssetStatus = ({ alarm, device, update, loadingAssets }) => {
  const assetId = device?.externalId;
  const alarmObject = alarm || device?.lastAlarm;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [retryFlag, setRetryFlag] = useState(false);

  const fetchData = useCallback(
    (assetId, cancelToken) => {
      setLoading(true);
      setError(null);
      getAlarmLastByAssetId(assetId, null, { cancelToken })
        .then((response) => {
          const lastAlarm = response.data;

          if (lastAlarm) {
            const {
              assetId,
              alarmName,
              createdOn,
              alarmType,
              alarmImageId,
              assetIconId,
              assetColor,
              isAlarmResolved,
              uniqueId,
              isActive,
              alarmStatus,
              isSoundEnabled,
            } = lastAlarm;

            update({
              externalId: assetId,
              device: {
                lastAlarm: {
                  createdOn,
                  alarmName,
                  alarmType,
                  assetIconId,
                  alarmImageId,
                  isAlarmResolved,
                  assetColor,
                  uniqueId,
                  isActive,
                  alarmStatus,
                  isSoundEnabled,
                },
              },
            });
          } else {
            update({
              externalId: assetId,
              device: {
                lastAlarm: null,
              },
            });
          }
          setLoading(false);
        })
        .catch((error) => {
          if (!isCancel(error)) {
            setError(error);
            setLoading(false);
          }
        });
    },
    [update]
  );

  useEffect(() => {
    const source = CancelToken.source();

    if (assetId && !loadingAssets) {
      fetchData(assetId, source.token);
    } else {
      setLoading(false);
    }

    return () => {
      if (assetId) {
        source.cancel('"assetdId" changed or component unmounted');
      }
    };
  }, [assetId, fetchData, retryFlag, loadingAssets]);

  const alarmCreatedOn = alarmObject?.createdOn;
  const imageId = alarmObject ? alarmObject.alarmImageId : device?.imageId;

  if (loading || loadingAssets) {
    return <Loader />;
  }

  if (error) {
    return (
      <ErrorMessage
        primaryMessage={T.translate(LocaleKeys.messages.errorWhileFetchingData, {
          name: T.translate(LocaleKeys.labels.status),
        })}
        secondaryMessage={T.translate(LocaleKeys.messages.clickRefreshToTryAgain)}
        onRetry={() => setRetryFlag((prev) => !prev)}
      />
    );
  }

  return (
    <Wrapper>
      {assetId && (
        <>
          <Margin>
            <Notification
              alarm={!!alarmObject}
              date={(alarmCreatedOn && new Date(alarmCreatedOn).toLocaleString()) || ''}
              deviceName={device.name}
              icon={<DeviceIcon iconId={device.iconId} color={device.color} />}
              oneLineTitle
              type={alarmObject?.alarmType}
              hideIcon={alarmObject && alarmObject.alarmStatus === NotificationStatuses.CLEARED}
              showSoundIcon={alarmObject?.isSoundEnabled}
            >
              {alarmObject ? alarmObject.alarmName : device.name}
            </Notification>
          </Margin>
          <Divider />
          <Box margin={5}>
            {alarmObject && (
              <Box marginBottom={2}>
                {`${T.translate(
                  alarmObject.alarmType === AlarmTypes.ALARM ? LocaleKeys.labels.alarm : LocaleKeys.labels.status
                )}: ${T.translate(alarmObject.isActive || !alarmObject.isAlarmResolved ? 'Start_alarm' : 'End_alarm')}`}
              </Box>
            )}
            <Box width={'100%'} display="flex" justifyContent="center">
              {imageId ? (
                <DeviceImage src={`${Config.apiDefaultURL}/devicestore/api/resources/${imageId}/image`} />
              ) : (
                <ImagePlaceholder />
              )}
            </Box>
          </Box>
        </>
      )}
    </Wrapper>
  );
};

const mapState = (state) => ({
  loadingAssets: getLoadingAssets(state),
});

const mapDispatch = {
  update,
};

export default compose(connect(mapState, mapDispatch))(AssetStatus);
