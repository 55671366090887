import React from 'react';
import { VolumeUp, Warning } from '@material-ui/icons';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { AlarmTypes } from 'Constants/AlarmTypes';

export const getColor = (type) => {
  switch (type) {
    case AlarmTypes.ALARM:
      return '#d9001b';
    case AlarmTypes.STATUS:
      return '#F59A22';
    default:
      return '#FFF';
  }
};

export const Notification = ({
  icon,
  alarm,
  oneLineTitle,
  number,
  children,
  onClick,
  deviceName,
  date,
  type,
  hideIcon,
  showSoundIcon,
}) => {
  return (
    <Box display="flex" fontSize={14} width={'100%'} style={{ cursor: onClick && 'pointer' }} onClick={onClick}>
      <Box>{icon}</Box>
      {alarm && (
        <Box display="flex" alignItems="center" alignSelf="flex-end" mb={-0.5}>
          <Warning
            visibility={hideIcon ? 'hidden' : 'visible'}
            style={{
              fill: getColor(type),
              width: 36,
              height: 36,
            }}
          />
          <VolumeUp
            visibility={showSoundIcon ? 'visible' : 'hidden'}
            style={{
              width: 24,
              height: 24,
              marginLeft: -4,
            }}
          />
        </Box>
      )}
      <Box width={'100%'} ml={1}>
        {!oneLineTitle && number && (
          <div>
            <strong>#{number}</strong>
          </div>
        )}
        <div>
          <strong>
            {oneLineTitle && !!number && `#${number} | `}
            {deviceName}
          </strong>
          <span style={{ float: 'right' }}>{date}</span>
        </div>
        <Box textAlign="justify">{children}</Box>
      </Box>
    </Box>
  );
};

Notification.propTypes = {
  type: PropTypes.oneOf([AlarmTypes.ALARM, AlarmTypes.STATUS]),
  date: PropTypes.string,
  deviceName: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.string,
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  oneLineTitle: PropTypes.bool,
  alarm: PropTypes.bool,
  icon: PropTypes.object,
  hideIcon: PropTypes.bool,
  showSoundIcon: PropTypes.bool,
};

export default Notification;
