import React, { useState, useEffect, useRef } from 'react';
import T from 'i18n-react';
import 'leaflet-draw';
import { DrawLayerTypes } from '../leaflet-extensions/leaflet-draw-extension/index.js';
import '../leaflet-extensions/corridor';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from 'Components/Button';
import ColorSquare from 'Components/inputs/ColorSquare';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import Radio from 'Components/inputs/RadioInput';
import TextInput from 'Components/inputs/TextInput';
import LabelsLocaleKeys from 'Localization/LabelsLocaleKeys';
import L from 'leaflet';
import { styled } from '@material-ui/core/styles';
import { convertType } from '../utils';

const EditPopup = styled(Dialog)({
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      overflow: 'visible',
    },
  },
});
const Icon = styled('img')({
  height: '50px',
  width: '50px',
});

const icon = L.icon({
  iconUrl: 'https://cdn2.iconfinder.com/data/icons/flat-ui-icons-24-px/24/location-24-512.png',
  iconSize: [38, 38],
});
const MapIconsNames = {
  Warning: 'Warning',
  Parking: 'Parking',
};
const MapIconsUrls = {
  Warning: 'https://cdn3.iconfinder.com/data/icons/tango-icon-library/48/dialog-warning-512.png',
  Parking: 'https://cdn0.iconfinder.com/data/icons/road-signs-4/154/parking-road-sign-256.png',
};
const iconsOptions = Object.keys(MapIconsNames).map((key) => ({
  value: MapIconsNames[key],
  label: <Icon src={MapIconsUrls[key]} alt={MapIconsNames[key]} />,
}));

const createTextIcon = (values) =>
  L.divIcon({
    className: 'mapText',
    html:
      '<div style="color:' +
      (values.color || 'black') +
      ';font-size:' +
      (values.fontSize || '12') +
      'px;height:auto;width:auto;">' +
      values.text +
      '</div>',
  });

const initialValues = {
  // icon marker
  selectedIcon: MapIconsNames.Warning,
  // text marker
  fontSize: 12,
  text: '',
  color: '#000',
};

const ExtendedMapDrawing = ({ map, data, onChange, name, drawnItems, routeMarkers, position, value }) => {
  const [popup, setPopup] = useState('');
  const [selectedLayer, setSelectedLayer] = useState();
  const [values, setValues] = useState({ ...initialValues });
  const [, setItemsDrawn] = useState(false);
  const isEditingRef = useRef(false);
  const selectedIconRef = useRef(
    L.icon({
      className: 'mapIcon',
      iconUrl: MapIconsUrls.Warning,
      iconSize: [38, 38],
    })
  );
  const textIconRef = useRef(createTextIcon(values));
  const drawControlRef = useRef();

  useEffect(() => {
    const drawControl = new L.Control.Draw({
      draw: {
        iconMarker: {
          icon: selectedIconRef.current,
        },
        textMarker: {
          icon: textIconRef.current,
        },
        marker: {
          icon: icon,
        },
        circlemarker: false,
      },
      edit: {
        featureGroup: drawnItems,
      },
      position: position,
    });

    drawControlRef.current = drawControl;

    map.addControl(drawControl);
    map.addLayer(drawnItems);

    addEventHandlers();
    drawGeoJson((data || value) && JSON.parse(data?.area || value));

    // eslint-disable-next-line
  }, []);

  const updateGeoJsonValues = () => {
    const geoJson = createGeoJson(drawnItems);

    onChange &&
      onChange({
        target: {
          name: name,
          value: JSON.stringify(geoJson),
        },
      });
    addRouteMarkers(geoJson);
  };

  const addEventHandlers = (_) => {
    drawnItems.on('click', (e) => {
      if (!isEditingRef.current) {
        onItemClick(e);
      }
    });

    map.on('draw:created', (e) => {
      const layer = e.layer;

      if (e.layerType === DrawLayerTypes.Polyline || e.layerType === DrawLayerTypes.HandDrawnPolyline) {
        openPopup('corridor');
        setValues({ width: 1000, color: '#3388ff' });
        setSelectedLayer(layer);
      }
      drawnItems.addLayer(layer);
      updateGeoJsonValues();
    });

    map.on('layeradd', () => {
      drawnItems.bringToFront();
    });

    drawnItems.on('layeradd', (e) => {
      setItemsDrawn((currentItemsDrawn) => {
        currentItemsDrawn && updateGeoJsonValues();

        return currentItemsDrawn;
      });
    });

    map.on('draw:editstart', (e) => {
      isEditingRef.current = true;
      routeMarkers.eachLayer((layer) => {
        routeMarkers.removeLayer(layer);
      });
    });

    map.on('draw:editstop', (e) => {
      isEditingRef.current = false;
      addRouteMarkers((data || value) && JSON.parse(data?.area || value));
      updateGeoJsonValues();
    });

    map.on('draw:edited', (e) => {
      updateGeoJsonValues();
    });

    map.on('draw:deletestart', (e) => {
      routeMarkers.eachLayer((layer) => {
        routeMarkers.removeLayer(layer);
      });
    });

    map.on('draw:deletestop', (e) => {
      updateGeoJsonValues();
    });

    map.on('draw:deleted', (e) => {
      const layer = e.layer;

      drawnItems.removeLayer(layer);
      updateGeoJsonValues();
    });

    map.on('draw:drawstart', (e) => {
      if (e.layerType === DrawLayerTypes.IconMarker) {
        setPopup('icon');
      } else if (e.layerType === DrawLayerTypes.TextMarker) {
        setPopup('text');
      }
    });
  };

  const onItemClick = (e) => {
    const layer = e.layer;

    if (layer?.options?.icon?.options?.className?.includes('mapText')) {
      let html = layer.options.icon.options.html;

      html = html.slice(html.indexOf('"') + 1);
      let style = html.substring(0, html.indexOf('"'));

      style = style.replace('px', '');

      html = html.slice(html.indexOf('"') + 1);
      const text = html.slice(1, -6); // extract string between > and </div>

      const result = {};
      const attributes = style.split(';');

      attributes.forEach((attribute) => {
        const entry = attribute.split(':');

        result[entry.splice(0, 1)[0]] = entry.join(':');
      });
      openPopup('text');
      setSelectedLayer(layer);
      setValues({
        selectedLayer: layer,
        addTextPopup: true,
        text,
        color: result.color,
        fontSize: result['font-size'],
        editPosition: e.latlng,
      });
    } else if (layer?.options?.icon?.options?.className === 'mapIcon') {
      openPopup('icon');
      setSelectedLayer(layer);
      setValues({ editPosition: e.latlng, selectedIcon: 'Warning' });
    } else if (layer?.options?.className === 'route-corridor') {
      openPopup('corridor');
      setSelectedLayer(layer);
      setValues({
        color: layer.options.color,
        width: layer.corridor,
      });
    } else if (!layer?.options?.icon?.options?.iconUrl) {
      openPopup('shapes');
      setSelectedLayer(layer);
      setValues({
        color: layer.options.color || '#3388ff',
        fillColor: layer.options.fillColor || layer.options.color || '#3388ff',
      });
    }
  };

  const drawGeoJson = (items) => {
    if (items) {
      items.forEach((item) => {
        L[convertType(item.type)](item.points || [item.lat, item.lng], {
          ...item.style,
          radius: item.radius || undefined,
          icon: item.icon && item.icon.html ? L.divIcon(item.icon) : L.icon(item.icon),
        }).addTo(drawnItems);
      });

      map.fitBounds(drawnItems.getBounds());
      addRouteMarkers(items);
    }

    setItemsDrawn(true);
  };

  const addRouteMarkers = (items) => {
    items &&
      items.forEach((item) => {
        if (item.type === 'LineString') {
          L.marker(item.points[0]).addTo(routeMarkers);
          L.marker(item.points[item.points.length - 1]).addTo(routeMarkers);
        }
      });
  };

  const openPopup = (popup = '') => {
    setPopup(popup);
  };

  const handleValueChange = (e, limitation) => {
    const target = e.target;

    if (limitation && !limitation(target.value)) {
      return;
    }

    setValues({
      ...values,
      [target.name]: target.value,
    });
  };

  const applyCorridor = () => {
    const latlngs = selectedLayer._latlngs;
    const options = {
      corridor: values.width || selectedLayer.options?.corridor || 1000,
      color: values.color || selectedLayer.options?.color || '#3388ff',
      className: 'route-corridor',
      opacity: 0.3,
    };

    const corridor = L.corridor(latlngs, options);

    drawnItems.removeLayer(selectedLayer);
    drawnItems.addLayer(corridor);

    openPopup();
    updateGeoJsonValues();
  };

  const applyText = () => {
    const icon = createTextIcon(values);

    if (selectedLayer) {
      const layer = selectedLayer;

      drawnItems.removeLayer(layer);
      layer.options.icon = icon;
      drawnItems.addLayer(layer);
      openPopup();
    } else {
      drawControlRef.current.setDrawingOptions({
        textMarker: {
          icon,
        },
      });
      setPopup('');
    }
    updateGeoJsonValues();
  };

  const applyIcon = () => {
    const icon = L.icon({
      className: 'mapIcon',
      iconUrl: MapIconsUrls[values.selectedIcon],
      iconSize: [38, 38],
    });

    if (selectedLayer) {
      const layer = selectedLayer;

      drawnItems.removeLayer(layer);

      L.marker([values.editPosition.lat, values.editPosition.lng], {
        icon,
      }).addTo(drawnItems);
      openPopup();
    } else {
      drawControlRef.current.setDrawingOptions({
        iconMarker: {
          icon,
        },
      });
      setPopup('');
    }
    updateGeoJsonValues();
  };

  const applyChanges = () => {
    const layer = selectedLayer;

    drawnItems.removeLayer(layer);
    layer.options.color = values.color;
    layer.options.fillColor = values.fillColor;
    drawnItems.addLayer(layer);
    openPopup();
    updateGeoJsonValues();
  };

  const createGeoJson = (layers) => {
    const geoJson = [];

    for (const key in layers._layers) {
      if (layers._layers.hasOwnProperty(key)) {
        const l = layers._layers[key];

        const lGeoJson = l.toGeoJSON();

        if (l._mRadius) {
          geoJson.push({
            type: lGeoJson.geometry.type,
            radius: l._mRadius,
            lat: l._latlng.lat,
            lng: l._latlng.lng,
            style: l.options,
          });
        } else if (l.options.icon && (l.options.icon.options.iconSize || l.options.icon.options.html)) {
          geoJson.push({
            type: 'Marker',
            lat: l._latlng.lat,
            lng: l._latlng.lng,
            icon: l.options.icon.options,
          });
        } else if (lGeoJson.geometry.coordinates.length === 1) {
          geoJson.push({
            type: lGeoJson.geometry.type,
            points: lGeoJson.geometry.coordinates[0].map((n) => [n[1], n[0]]),
            style: l.options,
          });
        } else {
          geoJson.push({
            type: lGeoJson.geometry.type,
            points: lGeoJson.geometry.coordinates.map((n) => [n[1], n[0]]),
            style: l.options,
          });
        }
      }
    }

    return geoJson;
  };

  return (
    <>
      <EditPopup open={popup === 'shapes'} onClose={openPopup}>
        <DialogTitle />
        <DialogContent>
          <FormControlLabel
            onChange={handleValueChange}
            control={<ColorSquare color={values.color} format="hex" />}
            label={T.translate(LabelsLocaleKeys.color)}
            name="color"
          />
          <FormControlLabel
            onChange={handleValueChange}
            control={<ColorSquare color={values.fillColor} format="hex" />}
            label={T.translate(LabelsLocaleKeys.fillColor)}
            name="fillColor"
          />
          <DialogActions>
            <Button onClick={applyChanges}>{T.translate(LabelsLocaleKeys.apply)}</Button>
          </DialogActions>
        </DialogContent>
      </EditPopup>
      <EditPopup open={popup === 'corridor'} onClose={applyCorridor}>
        <DialogTitle>{T.translate(LabelsLocaleKeys.corridor)}</DialogTitle>
        <DialogContent>
          <ColumnDisplayer>
            <TextInput
              onChange={(e) => handleValueChange(e, (value) => value > 0 || value === '')}
              value={values.width}
              name="width"
              label={`${T.translate(LabelsLocaleKeys.width)} (m)`}
              type="number"
              inputProps={{ min: '1' }}
              style={{
                width: 200,
              }}
            />
            <FormControlLabel
              onChange={handleValueChange}
              control={<ColorSquare color={values.color} format="hex" />}
              label={T.translate(LabelsLocaleKeys.color)}
              name="color"
            />
          </ColumnDisplayer>
          <DialogActions>
            <Button onClick={applyCorridor}>{T.translate(LabelsLocaleKeys.done)}</Button>
          </DialogActions>
        </DialogContent>
      </EditPopup>
      <EditPopup open={popup === 'text'} onClose={openPopup}>
        <DialogTitle>{T.translate(LabelsLocaleKeys.text)}</DialogTitle>
        <DialogContent>
          <ColumnDisplayer>
            <TextInput
              onChange={handleValueChange}
              value={values.text}
              name="text"
              label={T.translate(LabelsLocaleKeys.content)}
            />
            <TextInput
              onChange={(e) => handleValueChange(e, (value) => (value <= 200 && value > 0) || value === '')}
              value={values.fontSize}
              name="fontSize"
              label={T.translate(LabelsLocaleKeys.fontSize)}
              type="number"
            />
            <FormControlLabel
              onChange={handleValueChange}
              control={<ColorSquare color={values.color} format="hex" />}
              label={T.translate(LabelsLocaleKeys.color)}
              name="color"
            />
          </ColumnDisplayer>
          <DialogActions>
            <Button onClick={applyText}>{T.translate(LabelsLocaleKeys.done)}</Button>
          </DialogActions>
        </DialogContent>
      </EditPopup>
      <EditPopup open={popup === 'icon'} onClose={openPopup}>
        <DialogTitle>{T.translate(LabelsLocaleKeys.chooseAnIcon)}</DialogTitle>
        <DialogContent>
          <div>{`${T.translate(LabelsLocaleKeys.temporaryIcons)}:`}</div>
          <Radio
            name="selectedIcon"
            onChange={(e) => handleValueChange(e)}
            value={values.selectedIcon}
            values={iconsOptions}
          />
          <DialogActions>
            <Button onClick={applyIcon}>{T.translate(LabelsLocaleKeys.done)}</Button>
          </DialogActions>
        </DialogContent>
      </EditPopup>
    </>
  );
};

export default ExtendedMapDrawing;
