import React from 'react';
import PropTypes from 'prop-types';
import CopyUserIcon from '@material-ui/icons/GroupAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DeleteDialog from 'Components/dialogs/DeleteDialog';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import Table from 'Components/display/table/Table';
import { getUsers, deleteUser } from 'Api/users';
import { format } from 'date-fns';
import { withRouter } from 'react-router-dom';
import { UsersDetails, NewUser } from 'routes/Routes';
import Loader from 'Components/Loader';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import TableActionButton from 'Components/display/table/TableActionButton';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isDate } from 'Helpers/DateTimeHelpers';

const formatDataForTableView = (users, login) =>
  Object.values(users).map((user) => ({
    id: user.id,
    login: user.login,
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    roles: user.roles || '',
    enabled: user.enabled,
    blocked: user.blocked,
    lastActive: user.lastActive && convertDate(user.lastActive),
    phones: user.phones.map((phone) => phone.phoneNumber).join(', ') || '',
    emails: user.emails.map((email) => email.emailAddress).join(', ') || '',
    isAdmin: user.isAdmin,
    disableRemoval: user.isAdmin || user.login === login,
  }));

const convertDate = (date) => {
  if (!isDate(date)) {
    return '';
  }
  const dateObj = new Date(date);

  return format(dateObj, 'dd-MM-yyyy HH:mm');
};

class Users extends React.Component {
  state = {
    data: [],
    isLoaded: false,
    deleteUserDialogOpen: false,
    userIdToRemove: null,
  };

  componentDidMount = () => {
    this.setData();
  };

  setData = () => {
    getUsers()
      .then(({ data }) => {
        this.setState((prevState) => ({ ...prevState, data }));
      })
      .then(() => {
        this.setState((prevState) => ({ ...prevState, isLoaded: true }));
      });
  };

  rowDoubleClick = (login, clickedItem) => {
    this.navigateToUserDetails(clickedItem.login);
  };

  navigateToUserDetails = (userLogin) => {
    const url = UsersDetails.getUrl({
      userLogin,
    });

    this.props.history.push(url);
  };

  onAddClick = () => {
    const url = NewUser.getUrl();

    this.props.history.push(url);
  };

  copyUserClick = (id, clickedItem) => {
    const url = NewUser.getUrl();

    this.props.history.push(url, { baseOnUserId: id });
  };

  showDeleteDialog = (id) => {
    this.setState({
      deleteUserDialogOpen: true,
      userIdToRemove: id,
    });
  };

  handleDeleteUserConfirm = () => {
    return deleteUser(this.state.userIdToRemove)
      .then(() => {
        this.setState({
          deleteUserDialogOpen: false,
          userIdToRemove: null,
        });
      })
      .finally(() => {
        this.setData();
      });
  };

  handleDeleteUserCancel = () => {
    this.setState({
      deleteUserDialogOpen: false,
      userIdToRemove: null,
    });
  };

  getTableActionButtons = () => {
    const actionButtons = [];

    if (this.props.permissions[Permissions.CanEditUsers]) {
      const editItemButton = (
        <TableActionButton
          key="editItemButton"
          onClick={this.navigateToUserDetails}
          clickparam={'login'}
          icon={<EditIcon />}
          tooltipText={T.translate(LocaleKeys.tooltips.editItem)}
        />
      );

      actionButtons.push(editItemButton);
    }

    if (this.props.permissions[Permissions.CanCopyUsers]) {
      const copyItemButton = (
        <TableActionButton
          key="copyUserButton"
          onClick={this.copyUserClick}
          clickparam={'id'}
          icon={<CopyUserIcon />}
          tooltipText={T.translate(LocaleKeys.tooltips.copyUser)}
        />
      );

      actionButtons.push(copyItemButton);
    }
    if (this.props.permissions[Permissions.CanRemoveUsers]) {
      const deleteItemButton = (
        <TableActionButton
          key="deleteItemButton"
          onClick={this.showDeleteDialog}
          clickparam={'id'}
          icon={<DeleteIcon />}
          tooltipText={T.translate(LocaleKeys.tooltips.deleteItem)}
          hiddenwhenparam={'disableRemoval'}
        />
      );

      actionButtons.push(deleteItemButton);
    }

    return actionButtons;
  };

  render() {
    if (!this.state.isLoaded) {
      return <Loader />;
    }

    const tableData = formatDataForTableView(this.state.data, this.props.login);

    return (
      <div>
        <DeleteDialog
          isOpen={this.state.deleteUserDialogOpen}
          onConfirm={this.handleDeleteUserConfirm}
          onCancel={this.handleDeleteUserCancel}
          disableConfirm={!this.state.userIdToRemove}
          title={T.translate(LocaleKeys.messages.deleteUserDialogTitle)}
          message={T.translate(LocaleKeys.messages.deleteUserDialogMessage)}
        />
        <Table
          data={tableData}
          onRowDoubleClick={this.rowDoubleClick}
          redirect={'id'}
          onAddClick={this.props.permissions[Permissions.CanAddUsers] && this.onAddClick}
          hideColumns={['id', 'roles', 'disableRemoval']}
          actionButtons={this.getTableActionButtons()}
          tableName="users"
        />
      </div>
    );
  }
}

const mapState = (state) => ({
  login: state.user.data.login,
});

Users.propTypes = {
  history: PropTypes.object,
  login: PropTypes.string,
};

export default compose(
  withRouter,
  withPermissions([Permissions.CanAccessUsersPage, Permissions.CanReadUsers]),
  connect(mapState)
)(Users);
