import AccordionDetails from '@material-ui/core/AccordionDetails';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

export const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(AccordionDetails);

ExpansionPanelDetails.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  data: PropTypes.array,
};

export default ExpansionPanelDetails;
