import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { getTimeZones } from 'Api/sites';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import { withFormik } from 'Components/formik/formikWrappers';
import { DropdownWithApiCall, MultiSelect, TextField } from 'Components/formik/fields';

// Temporary taken not from dictionary, because only Alarm calendar type will be supported for the moment
const CALENDAR_TYPES = [
  {
    value: 1,
    name: 'Alarms',
  },
];

const General = (props) => {
  return (
    <ColumnDisplayer>
      <TextField name="name" label={T.translate(LocaleKeys.labels.name)} showPending />
      <TextField name="description" label={T.translate(LocaleKeys.labels.description)} multiline />
      <DropdownWithApiCall
        name="timeZone"
        apiCall={() => {
          return getTimeZones('en');
        }}
        displayField="displayName"
        valueField="key"
        label={T.translate(LocaleKeys.labels.timezones)}
        withoutEmpty
      />
      <MultiSelect
        items={CALENDAR_TYPES}
        name="types"
        label={T.translate(LocaleKeys.labels.types)}
        valueField="name"
        displayField="name"
        translate={true}
        disabled={props.disableTypes}
      />
    </ColumnDisplayer>
  );
};

General.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.object,
  disableTypes: PropTypes.bool,
};

export default withFormik(General);
