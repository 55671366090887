import * as Yup from 'yup';
import { isAlphanumericOrSpecialSymbol } from './SharedValidators';

Yup.addMethod(Yup.object, 'uniqueInCollection', function (message, name) {
  return this.test('is-row-property-unique-in-collection', message, function (value) {
    const mapper = (a) => (name ? a[name] : a);
    const array = this.parent.map(mapper);

    if (array.filter((a) => a === mapper(value)).length > 1) {
      return this.createError({
        path: `${this.path}.${name}`,
        message,
      });
    }

    return true;
  });
});

Yup.addMethod(
  Yup.string,
  'alphanumericOrSpecialSymbol',
  function (specialSymbols = [], message = 'nameShouldBeAlphanumeric') {
    return this.test('is-alphanumeric-or-special-symbol', message, function (value) {
      return isAlphanumericOrSpecialSymbol(specialSymbols)(value);
    });
  }
);
