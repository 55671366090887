import React from 'react';
import PropTypes, { string } from 'prop-types';
import { compose } from 'redux';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import { CalendarDetails, CalendarNew } from 'routes/Routes';
import CopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LocaleKeys from 'Localization/LocaleKeys';
import { deleteCalendar, getCalendars } from 'Api/sites';
import T from 'i18n-react';
import Table from 'Components/display/table/Table';
import TableActionButton from 'Components/display/table/TableActionButton';
import Loader from 'Components/Loader';
import { getCalendarsMappingsUsedByBRE } from 'Api/devices';
import DeleteAffectingAlarmsDialog from 'Components/dialogs/DeleteAffectingAlarmsDialog';

const formatDataForTableView = (templates) =>
  Object.values(templates).map((template) => ({
    calendarId: template.calendarId,
    name: template.name,
    description: template.description,
    timeZone: template.timeZone,
  }));

class CalendarTemplates extends React.Component {
  state = {
    data: [],
    isLoaded: false,
    deleteCalendarDialogOpen: false,
    calendarTemplateToDeleteId: string,
    affectedAlarms: [],
  };

  componentDidMount = () => {
    this.setData();
  };

  handleCopyClick = (id) => {
    const url = CalendarNew.getUrl();

    this.props.history.push(url, { id });
  };

  handleDeleteCalendarConfirm = () => {
    return deleteCalendar(this.state.calendarTemplateToDeleteId)
      .then(() => {
        this.setState({ deleteCalendarDialogOpen: false, calendarTemplateToDeleteId: null, affectedAlarms: [] });
      })
      .finally(() => {
        this.setData();
      });
  };

  handledeleteCalendarCancel = () => {
    this.setState({
      deleteCalendarDialogOpen: false,
      calendarTemplateToDeleteId: null,
    });
  };

  onDeleteClick = (id) => {
    const calendarToDelete = this.state.data.find((calendar) => calendar.calendarId === id);

    getCalendarsMappingsUsedByBRE(calendarToDelete.externalId).then((response) => {
      this.setState({
        deleteCalendarDialogOpen: true,
        calendarTemplateToDeleteId: id,
        affectedAlarms: response.data,
      });
    });
  };

  onEditClick = (name) => {
    const url = CalendarDetails.getUrl({
      name: name.replace(/\s/g, '-'),
    });

    this.props.history.push(url);
  };

  onAddClick = () => {
    const url = CalendarNew.getUrl();

    this.props.history.push(url);
  };

  setData = () => {
    getCalendars().then(({ data }) => {
      this.setState({
        data,
        isLoaded: true,
      });
    });
  };

  getTableActionButtons = () => {
    const actionButtons = [];

    if (this.props.permissions[Permissions.CanEditCalendarTemplates]) {
      const editItemButton = (
        <TableActionButton
          key="editItemButton"
          onClick={this.onEditClick}
          clickparam={'name'}
          icon={<EditIcon />}
          tooltipText={T.translate(LocaleKeys.tooltips.editItem)}
        />
      );

      actionButtons.push(editItemButton);
    }
    if (this.props.permissions[Permissions.CanCopyCalendarTemplate]) {
      const copyItemButton = (
        <TableActionButton
          key="copyUserButton"
          onClick={this.handleCopyClick}
          clickparam={'calendarId'}
          icon={<CopyIcon />}
          tooltipText={T.translate(LocaleKeys.tooltips.copyItem)}
        />
      );

      actionButtons.push(copyItemButton);
    }
    if (this.props.permissions[Permissions.CanRemoveCalendarTemplates]) {
      const deleteItemButton = (
        <TableActionButton
          key="deleteItemButton"
          onClick={this.onDeleteClick}
          clickparam={'calendarId'}
          icon={<DeleteIcon />}
          tooltipText={T.translate(LocaleKeys.tooltips.deleteItem)}
        />
      );

      actionButtons.push(deleteItemButton);
    }

    return actionButtons;
  };

  render() {
    if (!this.state.isLoaded) {
      return <Loader />;
    }

    const tableData = formatDataForTableView(this.state.data);
    const actionButtons = this.getTableActionButtons();

    return (
      <div>
        <DeleteAffectingAlarmsDialog
          isOpen={this.state.deleteCalendarDialogOpen}
          onConfirm={this.handleDeleteCalendarConfirm}
          onCancel={this.handledeleteCalendarCancel}
          disableConfirm={!this.state.calendarTemplateToDeleteId}
          affectedAlarms={this.state.affectedAlarms}
          title={T.translate(LocaleKeys.messages.deleteCalendarDialogTitle)}
          message={T.translate(LocaleKeys.messages.deleteCalendarDialogMessage)}
        />
        <Table
          data={tableData}
          onAddClick={this.props.permissions[Permissions.CanAddCalendarTemplates] && this.onAddClick}
          onRowDoubleClick={this.onEditClick}
          redirect={'name'}
          hideColumns={['calendarId']}
          actionButtons={actionButtons}
          tableName="calendarTemplates"
        />
      </div>
    );
  }
}

CalendarTemplates.propTypes = {
  history: PropTypes.object,
  permissions: PropTypes.object,
};

export default compose(
  withPermissions([Permissions.CanAccessCalendarTemplatesPage, Permissions.CanReadCalendarTemplates])
)(CalendarTemplates);
