import React from 'react';
import PropTypes from 'prop-types';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import { getDrivers } from 'Api/devices';
import { useFormikContext } from 'formik';
import { withFormik } from 'Components/formik/formikWrappers';
import { DropdownWithApiCall, TextField } from 'Components/formik/fields';

const CommonInfo = (props) => {
  const { values } = useFormikContext();

  return (
    <ColumnDisplayer>
      <DropdownWithApiCall
        name="driverId"
        apiCall={getDrivers}
        onChange={(e) => {
          props.onChange(e);
          props.onDriverChange(e);
        }}
        itemsDisabled={!!values.id}
        displayField="name"
        label={T.translate(LocaleKeys.labels.deviceDriver)}
        withoutEmpty
        translateLabel
      />
      <TextField
        name="name"
        label={T.translate(LocaleKeys.labels.libraryName)}
        disabled={!values.driverId}
        showPending
      />
    </ColumnDisplayer>
  );
};

CommonInfo.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
  onDriverChange: PropTypes.func,
};

export default withFormik(CommonInfo);
