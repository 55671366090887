import { createTypes, async, actionCreator } from 'redux-action-creator';

export const types = createTypes(
  [
    'SET_LOADING_PAGE',
    ...async('LOAD_FEED'),
    ...async('LOAD_PAGE'),
    'ADD_FEED_ALARM',
    'ADD_FEED_ALARMS',
    'READ_ALARMS',
    'SYNC_TOTAL',
  ],
  'ALARMS'
);

export const setLoadingAlarmsPage = actionCreator(types.SET_LOADING_PAGE, 'loadingPage');

export const loadAlarmsFeedSuccess = actionCreator(types.LOAD_FEED_SUCCESS, 'alarms', 'total');

export const loadAlarmsFeedFail = actionCreator(types.LOAD_FEED_FAIL, 'error');

export const loadAlarmsPageSuccess = actionCreator(types.LOAD_PAGE_SUCCESS, 'alarms', 'total');

export const loadAlarmsPageFail = actionCreator(types.LOAD_PAGE_FAIL, 'pageError');

export const addFeedAlarm = actionCreator(types.ADD_FEED_ALARM, 'alarm');

export const addFeedAlarms = actionCreator(types.ADD_FEED_ALARMS, 'alarms');

export const readAlarms = actionCreator(types.READ_ALARMS, 'readAlarms', 'decrementTotalBy');

export const forceSyncTotal = actionCreator(types.SYNC_TOTAL);
