import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import { IconButton, Typography } from '@material-ui/core';
import LocaleKeys from 'Localization/LocaleKeys';
import PropTypes from 'prop-types';
import React from 'react';
import SearchBar from 'Components/display/table/SearchBar';
import T from 'i18n-react';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  fab: {
    marginLeft: '10px',
  },
});

class EnhancedTableToolbar extends React.Component {
  state = { generalFilter: false, groupFilters: [] };

  changeGeneralFilter = (property) => (event) => {
    this.setState((prevState) => ({
      ...prevState,
      generalFilter: !this.state.generalFilter,
    }));
  };

  selectionChange = (event) => {
    this.props.selectionChange(event);
  };

  render() {
    const { selected, classes } = this.props;

    return (
      <Toolbar
        className={classNames(classes.root, {
          [classes.highlight]: selected.length > 0,
        })}
      >
        <div className={classes.actions}>
          {selected.length > 0 ? (
            <Tooltip title="Delete">
              <IconButton
                aria-label="Delete"
                onClick={() => {
                  this.props.bulkDelete(selected);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Filter list">
              <SearchBar
                changeGeneralFilter={this.changeGeneralFilter}
                setGeneralFilter={this.props.setGeneralFilter}
                removeGeneralFilter={this.props.removeGeneralFilter}
                groupFilters={this.props.groupFilters}
                generalFilterValue={this.props.generalFilterValue}
                selectionChange={this.selectionChange}
              />
            </Tooltip>
          )}
        </div>
        <div className={classes.title}>
          {selected.length > 0 ? (
            <Typography color="inherit" variant="subtitle1">
              {selected.length} selected
            </Typography>
          ) : (
            <Typography variant="h6" id="tableTitle">
              {T.translate(LocaleKeys.labels[this.props.label])}
            </Typography>
          )}
        </div>
        <div>
          {selected.length === 0 && this.props.onAddClick && (
            <Tooltip onClick={this.props.onAddClick} title={T.translate(LocaleKeys.tooltips.addNewItem)}>
              <Fab aria-label="Add" className={this.props.classes.fab} id={'table-general-add'}>
                <AddIcon />
              </Fab>
            </Tooltip>
          )}
        </div>
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string,
  bulkDelete: PropTypes.func,
  setGeneralFilter: PropTypes.func,
  removeGeneralFilter: PropTypes.func,
  selectionChange: PropTypes.func,
  selected: PropTypes.array,
  groupFilters: PropTypes.array,
  onAddClick: PropTypes.func,
  generalFilterValue: PropTypes.string,
};

export default withStyles(toolbarStyles)(EnhancedTableToolbar);
