import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const Message = (props) => {
  return (
    <Typography align={'center'} variant="body1" color={props.color || 'textPrimary'} component="div">
      {props.text}
    </Typography>
  );
};

Message.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  color: PropTypes.string,
};

export default Message;
