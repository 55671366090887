import { createMuiTheme } from '@material-ui/core/styles';
import { defaultTheme } from 'Localization';

const theme = createMuiTheme(
  {
    overrides: {
      MuiTableRow: {
        root: {
          '&.MuiTableRow-hover:hover': {
            backgroundColor: defaultTheme.palette.grey[100],
          },
          '&.Mui-selected': {
            backgroundColor: defaultTheme.palette.grey[200],
          },
        },
      },
    },
  },
  defaultTheme
);

export default theme;
