import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import Button from 'Components/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

const FormLeaveDialog = ({ open, onConfirm, onClose, onContinueWithoutSaving, isValid }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {T.translate(
        isValid ? LocaleKeys.messages.onLeaveValidFormMessage : LocaleKeys.messages.onLeaveInvalidFormMessage
      )}
    </DialogTitle>
    <DialogActions>
      {isValid && (
        <Button onClick={onConfirm} color="primary" id="saveOnLeave-save">
          {T.translate(LocaleKeys.labels.save)}
        </Button>
      )}
      <Button onClick={onContinueWithoutSaving} color="primary" autoFocus id="saveOnLeave-continueButton">
        {T.translate(isValid ? LocaleKeys.labels.doNotSave : LocaleKeys.labels.yes)}
      </Button>
      <Button onClick={onClose} color="primary" autoFocus id="saveOnLeave-cancelButton">
        {T.translate(LocaleKeys.labels.cancel)}
      </Button>
    </DialogActions>
  </Dialog>
);

FormLeaveDialog.propTypes = {
  isValid: PropTypes.bool,
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  onContinueWithoutSaving: PropTypes.func,
};

export default FormLeaveDialog;
