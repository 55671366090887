import ApiClient from './ApiClient';
import Config from 'Config';

const apiClient = new ApiClient();
const SERVICE_PATH = `${Config.authSettings.authority}/api/`;

const get = (path, params) => apiClient.get(SERVICE_PATH + path, { params });
const post = (path, data) => apiClient.post(SERVICE_PATH + path, data);

export const getSession = (sessionId) => get(`Session/${sessionId}/details`);
export const postSessionRefresh = (sessionId, data) => post(`Session/${sessionId}`, data);
export const closeWithTimeout = (sessionId) => post(`Session/CloseWithTimeout/${sessionId}`);
export const cancelSessionCloseTimeout = (sessionId) => post(`Session/CancelSessionCloseTimeout/${sessionId}`);
export const getCurrentDateTime = () => get('Session/CurrentDateTime');
