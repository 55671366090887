import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import ErrorPage from 'app/pages/ErrorPage';

const PermissionRoute = ({ permission, permissions, ...props }) => {
  if (permission) {
    const perm = permissions.find((x) => x.code === permission);

    if (!(perm && perm.value === 'true')) {
      return <ErrorPage code={403} message={T.translate(LocaleKeys.messages.youDontHavePermission)} />;
    }
  }

  return <Route {...props} />;
};

const mapState = (state) => ({
  permissions: state.app.permissions,
});

export default connect(mapState)(PermissionRoute);
