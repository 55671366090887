import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';

import SaveOnLeave from 'Components/dialogs/SaveOnLeave';
import Button from 'Components/Button';
import General from './tabs/General';
import LoginSettings from './tabs/LoginSettings';
import Webhooks from './tabs/Webhooks';

import { postSite } from 'Api/sites';
import { withStyles, styled } from '@material-ui/core/styles';
import { compose } from 'redux';
import { Sites } from 'routes/Routes';
import { Permissions } from 'Constants/permissions';
import withPermissions from 'hocs/withPermissions';
import { Formik } from 'formik';
import Tabs from 'Components/display/tabs/Tabs';
import { sitesValidation } from './SitesValidation';
import { prepareSiteData } from './helpers';

const styles = (theme) => ({
  button: {
    float: 'right',
    marginLeft: '10px',
  },
});

const ActionsPanel = styled('div')({
  textAlign: 'right',
  marginBottom: '10px',
  marginTop: '10px',
});

class New extends React.Component {
  state = {
    data: {
      general: {
        name: '',
      },
      securityRule: {
        concurrentLoginLimit: 30,
        sessionDuration: 15,
        passwordChangeDuration: 15,
        reminderDuration: 0,
        minPasswordLength: 8,
        minNumberOfLowercase: 1,
        minNumberOfUppercase: 1,
        minNumberOfDigits: 1,
        minNumberOfSpecialCharacters: 1,
        daysOfNotRepeatPassword: 60,
      },
      description: '',
      webHooks: [],
    },
  };

  saveData = (values) => {
    return postSite(prepareSiteData(values));
  };

  redirect = () => {
    const url = Sites.getUrl();

    this.props.history.push(url);
  };

  onSubmit = (values, { setSubmitting }) => {
    this.saveData(values).finally(() => {
      setSubmitting(false);
      this.redirect();
    });
  };

  onCancel = () => {
    this.redirect();
  };

  render() {
    const { data } = this.state;
    const { classes } = this.props;

    return (
      <>
        <Formik
          initialValues={data}
          enableReinitialize
          validationSchema={sitesValidation}
          validateOnMount
          onSubmit={this.onSubmit}
        >
          {({ values, dirty, isValid, isSubmitting, handleSubmit, setSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <Tabs label="siteDetails" defaultTab={0}>
                <General label={LocaleKeys.labels.general} />
                <LoginSettings label={LocaleKeys.labels.loginSettings} />
                <Webhooks label={LocaleKeys.labels.webhooks} />
              </Tabs>

              <ActionsPanel>
                <Button className={classes.button} onClick={this.onCancel} id="new-sites-cancelButton">
                  {T.translate(LocaleKeys.labels.cancel)}
                </Button>
                <Button
                  disabled={!(dirty && isValid) || isSubmitting}
                  className={classes.button}
                  type="submit"
                  id="new-sites-submitButton"
                >
                  {T.translate(LocaleKeys.labels.save)}
                </Button>
              </ActionsPanel>
              <SaveOnLeave saveData={() => this.saveData(values)} dataChanged={dirty} validForm={isValid} />
            </form>
          )}
        </Formik>
      </>
    );
  }
}

New.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  permissions: PropTypes.object,
};

export default compose(
  withPermissions([Permissions.CanAccessSitesPage, Permissions.CanAddSites]),
  withStyles(styles)
)(New);
