import React, { Component } from 'react';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';
import GenericDialog from 'Components/dialogs/GenericDialog';
import SessionManager from 'Auth/sessionManager/SessionManager';
import { CircularProgress } from '@material-ui/core';
import { getCurrentDateTime } from 'Api/identityServer';
import { ApiHeaders } from 'Api/ApiClient';

let _expireCounterInterval;

const _sessionManager = new SessionManager();

export class RefreshSession extends Component {
  state = {
    showDialog: false,
    dialogMessage: ' ',
    handleOkDialogFunc: null,
    handleCancelDialogFunc: null,
  };

  componentDidMount = () => {
    getCurrentDateTime()
      .then((response) => {
        const serverTimeOffset = +new Date(response.data) - Date.now();

        this.initializeSessionDialogCounter(serverTimeOffset);
      })
      .catch(() => {
        this.initializeSessionDialogCounter();
      })
      .finally(() => {
        this.setState({
          handleOkDialogFunc: this.handleOkDialog,
          handleCancelDialogFunc: this.handleCancelDialog,
        });
      });
  };

  initializeSessionDialogCounter = (serverTimeOffset = 0) => {
    _sessionManager.setServerTimeOffset(serverTimeOffset);
    _sessionManager.updateSessionWhenReady().catch((error) => {
      Sentry.captureMessage('error on updateSessionWhenReady', {
        extra: {
          error,
          message: error.message,
          correlationId: error.config?.headers[ApiHeaders.X_CORRELATION_ID],
        },
      });
    });

    _sessionManager.events.addSessionExpiring(() => {
      this.startSessionExpireTimeCounter();
      this.setDefaultDialogState();
    });

    _sessionManager.events.addSessionExpired(() => {
      this.stopSessionExpireTimeCounter();
    });

    _sessionManager.events.addSessionRefreshed(() => {
      this.setState({
        content: this.getLoadingContent(),
        handleOkDialogFunc: null,
        handleCancelDialogFunc: null,
        showDialog: false,
      });

      this.stopSessionExpireTimeCounter();
    });

    _sessionManager.events.addSessionRefreshing(() => {
      this.setState({ content: this.getLoadingContent(), handleOkDialogFunc: null, handleCancelDialogFunc: null });
    });
  };

  startSessionExpireTimeCounter = () => {
    _expireCounterInterval = setInterval(() => {
      const seccondsToSessionExpired = this.getSessionExpireSeconds();

      if (seccondsToSessionExpired <= 0) {
        this.stopSessionExpireTimeCounter();
        this.setDialogStateForSessionExpired();
      } else {
        this.setDialogStateForCounter(seccondsToSessionExpired);
      }
    }, 1000);
  };

  stopSessionExpireTimeCounter = () => {
    clearInterval(_expireCounterInterval);
  };

  getSessionExpireSeconds = () => {
    return Math.round(_sessionManager.getTimeToSessionExpire() / 1000);
  };

  setDefaultDialogState = () => {
    this.setState({
      showDialog: true,
      dialogMessage: ' ',
      content: null,
      handleOkDialogFunc: this.handleOkDialog,
      handleCancelDialogFunc: this.handleCancelDialog,
    });
  };

  setDialogStateForCounter = (secondsToSessionExpired) => {
    this.setState({
      dialogMessage: T.translate(LocaleKeys.messages.sessionExpiringDialogMessage, {
        sessionExpireSeconds: secondsToSessionExpired,
      }),
    });
  };

  setDialogStateForSessionExpired = () => {
    this.setState({
      dialogMessage: T.translate(LocaleKeys.messages.sessionExpiredDialogMessage),
      handleOkDialogFunc: null,
      handleCancelDialogFunc: null,
    });
  };

  handleOkDialog = async () => {
    this.setState({ content: this.getLoadingContent(), handleOkDialogFunc: null, handleCancelDialogFunc: null });
    await _sessionManager.refreshSessionClick();
  };

  handleCancelDialog = () => {
    _sessionManager.logoutClick();
    this.setState({ showDialog: false });
  };

  getLoadingContent = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <CircularProgress />
      </div>
    );
  };

  render() {
    return (
      <GenericDialog
        dialogOpen={this.state.showDialog}
        handleOk={this.state.handleOkDialogFunc}
        handleCancel={this.state.handleCancelDialogFunc}
        title={T.translate(LocaleKeys.messages.sessionExpiringDialogTitle)}
        message={this.state.dialogMessage}
        content={this.state.content}
        okLabel={T.translate(LocaleKeys.messages.sessionExpiringRefreshSessionButton)}
        cancelLabel={T.translate(LocaleKeys.messages.sessionExpiringLogoutButton)}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  identityUser: state.app.identityUser,
});

export default compose(connect(mapStateToProps))(RefreshSession);
