import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Dialog, DialogTitle, CircularProgress, Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import {
  getSystemNotifications,
  getSystemNotificationsCount,
  putSystemNotificationMarkAsRead,
} from 'Api/notifications';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { withSignalR } from 'context/signalr';
import Notification from './Notification';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ErrorMessage from 'Components/display/ErrorMessage';

const Wrapper = styled('div')({
  height: 400,
  width: 560,
});

const getRow = ({ index, style, data }) => {
  const { isItemLoaded, notifications, setNotifications, markAsRead, setSelectedIndex, selectedIndex } = data;

  if (!isItemLoaded(index)) {
    return (
      <div
        style={{
          ...style,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const notification = notifications[index];

  return (
    <Notification
      key={notification.uniqueId}
      style={{ ...style }}
      notification={notification}
      onClickRead={() => {
        const id = notification.uniqueId;

        putSystemNotificationMarkAsRead(id);

        setNotifications((prevNotifications) =>
          prevNotifications.map((notification) => {
            if (notification.uniqueId === id) {
              return {
                ...notification,
                isRead: true,
              };
            }

            return notification;
          })
        );

        markAsRead();
      }}
      index={index}
      setSelectedIndex={setSelectedIndex}
      selectedIndex={selectedIndex}
    />
  );
};

export const SiteNotificationDialog = ({ open, onClose, setUnread, markAsRead, currentSiteId, hub, login }) => {
  const [notifications, setNotifications] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [loadNextPage, setLoadNextPage] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [error, setError] = useState(true);
  const listRef = useRef();

  const itemCount = hasNextPage ? notifications.length + 1 : notifications.length;
  const pageSize = 20;

  const loadMoreItems = () => {
    !isNextPageLoading && !loadNextPage && setLoadNextPage(true);
  };

  const isItemLoaded = useCallback(
    (index) => {
      return !hasNextPage || index < notifications.length;
    },
    [hasNextPage, notifications.length]
  );

  useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, [selectedIndex, listRef]);

  const itemData = useMemo(
    () => ({
      notifications,
      isItemLoaded,
      setNotifications,
      markAsRead,
      selectedIndex,
      setSelectedIndex,
    }),
    [notifications, isItemLoaded, setNotifications, markAsRead, selectedIndex, setSelectedIndex]
  );

  const fetchData = useCallback(() => {
    setError(null);
    Promise.all([
      getSystemNotifications({
        OffSet: '0',
        PageSize: pageSize,
        ShowNotRead: true,
      }),
      getSystemNotificationsCount({ ShowNotRead: true }),
    ])
      .then(([{ data }, { data: total }]) => {
        setHasNextPage(data.hasNextPage);
        setUnread(total);
        setNotifications(data.feeds);
        setIsNextPageLoading(false);
      })
      .catch((error) => {
        setError(error);
      });
  }, [setUnread]);

  useEffect(() => {
    fetchData();
  }, [currentSiteId, fetchData]);

  useEffect(() => {
    if (loadNextPage && !isNextPageLoading) {
      setIsNextPageLoading(true);
      setLoadNextPage(false);
      getSystemNotifications({
        OffSet: itemCount - 1 > 0 ? itemCount - 1 : '0',
        PageSize: pageSize,
        ShowNotRead: true,
      })
        .then(({ data }) => {
          setNotifications((prev) => [...prev, ...data.feeds]);
          setHasNextPage(data.hasNextPage);
          setIsNextPageLoading(false);
        })
        .catch((error) => {
          setError(error);
        });
    }
  }, [isNextPageLoading, loadNextPage, itemCount, setNotifications, setUnread]);

  const updateNotifications = useCallback(
    (notification) => {
      if (notification) {
        setNotifications((prev) => {
          if (!prev.find((n) => n.uniqueId === notification.uniqueId)) {
            if (notification.onlyForBoundedUsers && !notification.users.includes(login)) {
              return prev;
            }

            return [notification, ...prev];
          }

          return prev;
        });
        setUnread((prev) => prev + 1);
      }
    },
    [setNotifications, login, setUnread]
  );

  useEffect(() => {
    if (hub) {
      hub.on('IdentityEvent', (notification) => {
        updateNotifications(notification);
      });

      hub.on('SiteNotification', (notification) => {
        updateNotifications(notification);
      });
    }
  }, [hub, login, updateNotifications]);

  const getItemSize = (index) => (index === selectedIndex ? 265 : 150);

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <DialogTitle>{T.translate(LocaleKeys.labels.notificationsHistory)}</DialogTitle>
      <Wrapper>
        <AutoSizer>
          {({ width, height }) => {
            if (error) {
              return (
                <Box display="flex" justifyContent="center" width={width}>
                  <ErrorMessage
                    primaryMessage={T.translate(LocaleKeys.messages.errorWhileFetchingNotifications)}
                    secondaryMessage={T.translate(LocaleKeys.messages.clickRefreshToTryAgain)}
                    onRetry={fetchData}
                  />
                </Box>
              );
            }

            return (
              <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems}>
                {({ onItemsRendered }) => (
                  <List
                    ref={listRef}
                    width={width}
                    height={height}
                    itemSize={getItemSize}
                    itemCount={itemCount}
                    itemData={itemData}
                    onItemsRendered={onItemsRendered}
                  >
                    {getRow}
                  </List>
                )}
              </InfiniteLoader>
            );
          }}
        </AutoSizer>
      </Wrapper>
    </Dialog>
  );
};

const mapStateToProps = ({
  user: {
    data: { login },
  },
}) => ({
  login,
});

export default compose(connect(mapStateToProps), withSignalR)(SiteNotificationDialog);
