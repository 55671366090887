import React, { useState, useEffect, useCallback } from 'react';
import { styled } from '@material-ui/core/styles';
import { format } from 'date-fns';
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  Warning,
  Notifications as NotificationsIcon,
  Refresh,
  ErrorOutlineRounded,
} from '@material-ui/icons';
import {
  IconButton,
  CircularProgress,
  Badge,
  List as MuiList,
  ListItemIcon,
  ListItemText,
  ListItem,
} from '@material-ui/core';
import NotificationDialog, { isRetryAllowed } from './notifications/NotificationsDialog';
import { getAlarmsNotRead, getAlarmsNotReadCount } from 'Api/notifications';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { connect } from 'react-redux';
import { setLoadingAlarmsPage, loadAlarmsFeedSuccess, loadAlarmsFeedFail } from 'redux/alarms/actions';
import SoundPlayer from './notifications/SoundPlayer';
import { NotificationStatuses } from 'Constants/NotificationStatuses';
import { CancelToken, isCancel } from 'Api/ApiClient';

export const ALARM_TYPE = {
  ALARM: 1,
  STATUS: 2,
};
export const ALARM_COLORS = {
  0: '#d9001b',
  [ALARM_TYPE.ALARM]: '#d9001b',
  [ALARM_TYPE.STATUS]: '#F59A22',
};

const Wrapper = styled('div')({
  borderLeft: 'solid 1px #eee',
  borderRight: 'solid 1px #eee',
  height: 64,
  width: 870,
  display: 'flex',
  color: '#fff;',
  overflow: 'hidden',
});
const ErrorWrapper = styled(Wrapper)({
  alignItems: 'center',
  justifyContent: 'center',
});
const List = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  flexGrow: 1,
  transition: 'transform .5s',
  alignItems: 'center',
  justifyContent: 'center',
});
const Notification = styled('div')({
  width: '100%',
  cursor: 'pointer',
});
const Arrows = styled('div')({
  width: 50,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});
const IconButtonStyled = styled(IconButton)({
  color: '#fff',
});
const WarningStyled = styled(Warning)({
  color: '#fff',
  margin: 19,
  verticalAlign: 'middle',
  float: 'left',
  fill: ({ fill }) => fill,
});
const Title = styled('h4')({
  margin: 6,
});
const Time = styled('time')({
  float: 'right',
});

const Notifications = ({
  currentSiteId,
  statusHistory,
  setLoadingAlarmsPage,
  loadAlarmsFeedSuccess,
  loadAlarmsFeedFail,
  total,
}) => {
  const [current, setCurrent] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(
    (cancelToken) => {
      setLoadingAlarmsPage({ loadingPage: true });
      setIsLoading(true);
      setError(null);
      const params = {
        OffSet: '0',
        PageSize: 50,
      };

      Promise.all([getAlarmsNotRead(params, { cancelToken }), getAlarmsNotReadCount(params, { cancelToken })])
        .then(([{ data }, { data: total }]) => {
          setHasNextPage(data.hasNextPage);
          loadAlarmsFeedSuccess({
            alarms: data.alarms,
            total,
          });
          setIsLoading(false);
        })
        .catch((error) => {
          if (!isCancel(error)) {
            loadAlarmsFeedFail({ error });
            setError(LocaleKeys.messages.errorWhileFetchingNotifications);
          }
        });
    },
    [setLoadingAlarmsPage, loadAlarmsFeedSuccess, loadAlarmsFeedFail]
  );

  const handleRefresh = useCallback(() => {
    const source = CancelToken.source();

    return fetchData(source.token);
  }, [fetchData]);

  useEffect(() => {
    const source = CancelToken.source();

    fetchData(source.token);

    return () => {
      source.cancel('"currentSiteId" changed or component unmounted');
    };
  }, [currentSiteId, fetchData]);

  if (error) {
    const secondaryMessageKey = isRetryAllowed(error)
      ? LocaleKeys.messages.clickRefreshToTryAgain
      : LocaleKeys.messages.clickRefreshToReload;

    return (
      <ErrorWrapper>
        <MuiList dense>
          <ListItem>
            <ListItemIcon>
              <ErrorOutlineRounded color="error" />
            </ListItemIcon>
            <ListItemText
              primary={T.translate(error)}
              secondary={T.translate(secondaryMessageKey)}
              secondaryTypographyProps={{
                color: 'initial',
              }}
            />
            <ListItemIcon>
              <IconButton style={{ width: 64 }} onClick={handleRefresh}>
                <Refresh htmlColor="#fff" />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        </MuiList>
      </ErrorWrapper>
    );
  }

  if (isLoading) {
    return (
      <Wrapper>
        <List>
          <CircularProgress color="secondary" size={20} />
        </List>
      </Wrapper>
    );
  }

  const sortedNotifications = statusHistory.sort((a, b) => +new Date(b.createdOn) - +new Date(a.createdOn));
  const soundNotifications = sortedNotifications.filter((notification) => notification.isSoundEnabled);
  const notReadNotifications = sortedNotifications.filter((notification) => !notification.isRead);
  const notificationPopup = notReadNotifications?.find((notification) => notification.showAsPopup);
  const last10Notifications = [...notReadNotifications].slice(0, 10);

  return (
    <Wrapper>
      {!!last10Notifications && (
        <>
          {last10Notifications.length === 0 && <List>{T.translate(LocaleKeys.messages.noNotifications)}</List>}
          {!!last10Notifications.length && (
            <List
              style={{
                transform: `translate(0, ${-62 * current}px)`,
              }}
            >
              {last10Notifications.map((notification, index) => (
                <Notification
                  key={notification.uniqueId}
                  onClick={() => {
                    setDialogOpen(notification.uniqueId);
                  }}
                >
                  <WarningStyled
                    fill={ALARM_COLORS[notification.alarmType]}
                    visibility={notification.alarmStatus === NotificationStatuses.CLEARED ? 'hidden' : 'visible'}
                  />
                  <Title>
                    {notification.message}
                    <Time>{format(new Date(notification.createdOn), 'HH:mm dd-MM-yyyy')}</Time>
                  </Title>
                  {T.translate(LocaleKeys.messages.alarmTitle, {
                    alarmStatus: T.translate(notification.alarmStatus),
                    alarmName: notification.alarmName,
                    assetName: notification.assetName,
                  })}
                </Notification>
              ))}
            </List>
          )}
          <IconButton
            style={{ width: 64 }}
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            {!!total ? (
              <Badge badgeContent={total} max={9999999} color="secondary">
                <NotificationsIcon htmlColor="#fff" id={'notifications-icon'} />
              </Badge>
            ) : (
              <NotificationsIcon htmlColor="#fff" id={'notifications-icon'} />
            )}
          </IconButton>
        </>
      )}
      <Arrows>
        <IconButtonStyled
          size="small"
          onClick={() => {
            setCurrent(current - 1);
          }}
          disabled={!last10Notifications || current === 0}
        >
          <KeyboardArrowUp />
        </IconButtonStyled>
        <IconButtonStyled
          size="small"
          onClick={() => {
            setCurrent(current + 1);
          }}
          disabled={!last10Notifications || !last10Notifications.length || current === last10Notifications.length - 1}
        >
          <KeyboardArrowDown />
        </IconButtonStyled>
      </Arrows>
      <NotificationDialog
        open={!!dialogOpen || !!notificationPopup}
        statusHistory={statusHistory}
        hasNextPage={hasNextPage}
        setHasNextPage={setHasNextPage}
        onClose={() => {
          if (!notificationPopup) {
            setDialogOpen(null);
          }
        }}
        notificationPopup={notificationPopup}
        onRefresh={handleRefresh}
        setError={setError}
      />
      <SoundPlayer notifications={soundNotifications} />
    </Wrapper>
  );
};

const mapState = (state) => ({
  currentSiteId: state.sites.currentSiteId,
  total: state.alarms.total,
  statusHistory: state.alarms.statusHistory,
});

const mapDispatchToProps = {
  setLoadingAlarmsPage,
  loadAlarmsFeedSuccess,
  loadAlarmsFeedFail,
};

export default connect(mapState, mapDispatchToProps)(Notifications);
