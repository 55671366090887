// based on: http://api.dna-dev.tru.io/devicestore/api/dictionaries/metricvariable

export const MetricVariables = {
  GREATER_THAN: 1,
  LESS_THAN: 2,
  LESS_OR_EQUAL: 3,
  GREATER_OR_EQUAL: 4,
  BETWEEN: 5,
  OUTSIDE_OF: 6,
  EQUAL_TO: 7,
};
