import React from 'react';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import { useFormikContext } from 'formik';
import { Checkbox } from 'Components/formik/fields';
import { WebhookTypeTranslationKeys } from 'Constants/WebhookTypes';
import T from 'i18n-react';

const Webhooks = (props) => {
  const { values } = useFormikContext();

  return (
    <ColumnDisplayer>
      {values.webhooks?.map(({ type }, index) => (
        <Checkbox
          key={`webhook-type-${type}`}
          label={T.translate(WebhookTypeTranslationKeys[type])}
          name={`webhooks.${index}.isEnabled`}
        />
      ))}
    </ColumnDisplayer>
  );
};

export default Webhooks;
