import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import store from 'redux/store';
import PropTypes from 'prop-types';
import {
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableSortLabel,
} from '@material-ui/core';
import { Search as SearchIcon, Close as CloseIcon } from '@material-ui/icons';
import * as Sentry from '@sentry/react';
import { save } from 'save-file';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import domtoimage from 'dom-to-image';
import Dropdown from 'Components/inputs/Dropdown';
import DateTimeInput from 'Components/inputs/DateTime';
import LeafletMap from 'Components/LeafletMap';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import LabelsLocaleKeys from 'Localization/LabelsLocaleKeys';
import { getSites } from 'Api/sites';
import { getDevicesBasicInformationBySite } from 'Api/devices';
import { getArchiveReport } from 'Api/stateManagement';
import Loader from 'Components/Loader';
import ApiIconMarker from 'Components/ApiIconMarker';
import Button from 'Components/Button';
import Filters from '../../administration/technicalArchive/TechnicalArchiveFilters';
import customFont from './Aller_Rg-normal';
import DropdownWithFilter from 'Components/inputs/DropdownWithFilter';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import { compose } from 'redux';
import TechnicalArchiveValidation from '../../administration/technicalArchive/TechnicalArchiveValidation';
import { Formik, useField, useFormikContext } from 'formik';
import { enqueueSnackbar } from 'redux/notifications/actions';
import { getLogin } from 'redux/user/selectors';
import { getMapToken } from 'redux/app/selectors';
import { getBatchReverseGeocodeHERE } from 'Api/maps';

const PaperStyled = styled(Paper)({
  paddingLeft: '24px',
  paddingRight: '24px',
  paddingTop: '24px',
  paddingBottom: '24px',
  height: 'calc(100vh - 170px)',
  overflow: 'hidden',
});

const StyledCell = styled(TableCell)({
  textTransform: 'uppercase',
});

const StyledCellSortable = styled(StyledCell)({
  cursor: 'pointer',
  userSelect: 'none',
});

const StyledButton = styled(Button)({
  marginTop: '10px',
});

const Label = styled('span')({
  textTransform: 'uppercase',
  fontSize: '12px',
  display: 'block',
  color: '#b9b9b9',
});

const Value = styled('span')({
  fontSize: '16px',
  display: 'block',
});

const StyledGrid = styled(Grid)({
  margin: '20px 0',
});

const closeDialogBtn = {
  cursor: 'pointer',
  margin: 0,
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: 400,
};

const invalidDate = 'Invalid Date';

const reverseGeocodePositions = async (entries, mapToken) => {
  const coords = entries.map((entry, index) => `id=${index}&prox=${entry.fullLatitude},${entry.fullLongitude},50`);

  try {
    const addresses = await getBatchReverseGeocodeHERE(coords, mapToken);
    const locations = addresses.map((en) => en?.Result[0]?.Location?.Address?.Label || '');

    return entries.map((entry, index) => ({
      ...entry,
      address: locations[index],
    }));
  } catch (error) {
    return entries.map((entry) => ({ ...entry, address: '' }));
  }
};

const getDate = (timestamp) => {
  const date = new Date(timestamp);

  return date.toLocaleDateString();
};

const getTime = (timestamp) => {
  const date = new Date(timestamp);

  return date.toLocaleTimeString();
};

export const roundCoordinate = (number) => {
  return Math.round((number + Number.EPSILON) * 10000) / 10000;
};

const formatIgnitionStatus = (status) => {
  switch (status) {
    case '0':
      return T.translate(LabelsLocaleKeys.off);
    case '1':
      return T.translate(LabelsLocaleKeys.on);
    default:
      return '';
  }
};

const messageTypes = {
  SHOW_ALL: 'showAll',
  SHOW_TRANSLATED_MESSAGE: 'showTranslatedMessage',
  SHOW_RAW_MESSAGE: 'showRawMessage',
};

const DatePickerWithField = (props) => {
  const [field, meta] = useField(props.name);

  return (
    <DateTimeInput
      {...props}
      onChange={(event) => {
        field.onChange(event);
        props.onChange(event);
      }}
      value={field.value}
      validationerrorlocalekeys={meta.error}
      valid={meta.error}
      fullWidth
    />
  );
};

const AutoSubmit = () => {
  const [prevValues, setPrevValues] = useState({});
  const { values, submitForm } = useFormikContext();

  useEffect(() => {
    const newValues = { ...values };

    delete newValues.name;
    if (JSON.stringify(newValues) !== JSON.stringify(prevValues)) {
      setPrevValues(newValues);
      submitForm();
    }
  }, [values, submitForm, prevValues]);

  return null;
};

const getFromDate = () => {
  const date = new Date();

  date.setHours(0, 0, 0);

  return date.toISOString();
};

const Archive = ({ mapToken, ...props }) => {
  const [sites, setSites] = useState([]);
  const [devices, setDevices] = useState([]);
  const [entries, setEntries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState({ by: 'timestamp', order: 'asc' });
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [fileName, setFileName] = useState('');
  const [isCSVDialogOpen, setIsCSVDialogOpen] = useState(false);
  const [isPDFDialogOpen, setIsPDFDialogOpen] = useState(false);
  const [screenshot, setScreenshot] = useState(null);
  const [creatingPDF, setCreatingPDF] = useState(false);
  const [template, setTemplate] = useState({
    device: null,
    site: null,
    search: '',
    from: getFromDate(),
    to: new Date().toISOString(),
    name: '',
    messageType: messageTypes.SHOW_TRANSLATED_MESSAGE,
    direction: 0,
  });

  const { login, permissions } = props;

  useEffect(() => {
    if (template.site) {
      getDevicesBasicInformationBySite(template.site)
        .then((response) => {
          if (response?.data) {
            setDevices(response?.data || []);
          }
        })
        .catch((err) => {
          Sentry.captureMessage(err?.response?.data);
        });
    }
  }, [template.site]);

  useEffect(() => {
    const fetchSites = async () => {
      try {
        const response = await getSites();

        if (response.data) {
          setSites(response.data);
        }
      } catch (err) {
        Sentry.captureMessage(err.response.data);
      }
    };

    fetchSites();
  }, []);

  const getArchives = async () => {
    if (template.device && template.from && template.site) {
      setIsLoading(true);
      try {
        const response = await getArchiveReport({
          tenantId: template.site,
          assetId: template.device.assetId,
          startDate: template.from,
          endDate: template.to,
          getAll: true,
        });

        const maxEntriesCount = 2000;

        if (response?.data?.total > maxEntriesCount) {
          store.dispatch(
            enqueueSnackbar({ message: T.translate(LabelsLocaleKeys.only2kEntriesAreDisplayed), variant: 'info' })
          );
        }

        if (response?.data?.entries?.length) {
          const entries = await reverseGeocodePositions(response.data.entries, mapToken);

          setEntries(entries);
        } else {
          setEntries([]);
        }
      } catch (err) {
        Sentry.captureMessage(err);
      } finally {
        setIsLoading(false);
      }
    } else {
      setEntries([]);
    }
    setSelectedEntries([]);
  };

  const toggleMarker = (entry, row) => {
    const entryExists = selectedEntries.find((e) => e.timestamp === entry.timestamp);
    const newEntries = entryExists
      ? selectedEntries.filter((e) => e.timestamp !== entry.timestamp)
      : [...selectedEntries, entry];

    row.currentTarget.style.backgroundColor = row.currentTarget.style.backgroundColor === '' ? '#f2f2f2' : '';
    setSelectedEntries(newEntries);
  };

  const updateSort = (field) => setSortBy({ by: field, order: sortBy.order === 'asc' ? 'desc' : 'asc' });

  const getFilteredAndSortedEntries = () => {
    const filterdEntries =
      template.search !== ''
        ? entries.filter(
            (entry) =>
              entry.keyword?.toLowerCase()?.includes(template.search.toLowerCase()) ||
              entry.address?.toLowerCase()?.includes(template.search.toLowerCase())
          )
        : entries;

    return sortBy.order === 'asc'
      ? filterdEntries.sort((a, b) => (a[sortBy.by] > b[sortBy.by] ? 1 : -1))
      : filterdEntries.sort((a, b) => (a[sortBy.by] < b[sortBy.by] ? 1 : -1));
  };

  const filteredAndSortedEntries = getFilteredAndSortedEntries();
  const entriesExist = !!filteredAndSortedEntries.length;

  const openExportCSVDialog = () => {
    setFileName(template.device.name);
    setIsCSVDialogOpen(true);
  };

  const openExportPDFDialog = async () => {
    setCreatingPDF(true);
    const mapImage = await takeMapScreenshot();

    setScreenshot(mapImage);
    setFileName(template.device.name);
    setIsPDFDialogOpen(true);
    setCreatingPDF(false);
  };

  const closeDialogBox = () => {
    setFileName('');
    setIsCSVDialogOpen(false);
    setIsPDFDialogOpen(false);
  };

  const prepareExportContent = () => {
    const universalBOM = '\uFEFF'; // this is byte order mark, in this case it marks encoding
    const separator = ';';
    const newLine = '\n';
    const headings =
      [
        T.translate(LabelsLocaleKeys.device),
        T.translate(LabelsLocaleKeys.date),
        T.translate(LabelsLocaleKeys.time),
        T.translate(LabelsLocaleKeys.speed),
        `"${T.translate(LabelsLocaleKeys.location)}"`,
        T.translate(LabelsLocaleKeys.eventKeyword),
        T.translate(LabelsLocaleKeys.ignition),
        T.translate(LabelsLocaleKeys.latitude),
        T.translate(LabelsLocaleKeys.longitude),
      ].join(separator) + newLine;

    const data =
      headings +
      filteredAndSortedEntries
        .map((e) =>
          [
            template.device.name,
            getDate(e.timestamp),
            getTime(e.timestamp),
            e.speed !== null ? e.speed.toString().replace('.', ',') : '-',
            `"${e.address}"`,
            e.keyword,
            formatIgnitionStatus(e.ignitionStatus),
            roundCoordinate(e.fullLatitude),
            roundCoordinate(e.fullLongitude),
          ].join(separator)
        )
        .join(newLine);

    return `data:text/csv;charset=utf-8,${encodeURIComponent(universalBOM + data)}`;
  };

  const createAndDownloadFile = async () => {
    await save(prepareExportContent(), `${fileName}.csv`);
    closeDialogBox();
  };

  const takeMapScreenshot = async () =>
    URL.createObjectURL(await domtoimage.toBlob(document.querySelector('#mapContainer')));

  const generatePDF = async () => {
    const doc = new jsPDF('p', 'mm', 'a4'); // eslint-disable-line

    const mapImage = document.getElementById('mapImage');
    const mapImageHeight = (mapImage.height / mapImage.width) * doc.internal.pageSize.getWidth();

    doc.addFileToVFS('Aller_Rg-normal.ttf', customFont);
    doc.addFont('Aller_Rg-normal.ttf', 'Aller_Rg', 'normal');
    doc.setFont('Aller_Rg', 'normal');
    doc.setTextColor(100);
    doc.setFontSize(8);
    doc.text(T.translate(LabelsLocaleKeys.site), 5, 10);
    doc.text(T.translate(LabelsLocaleKeys.device), 55, 10);
    doc.text(T.translate(LabelsLocaleKeys.from), 105, 10);
    doc.text(T.translate(LabelsLocaleKeys.to), 155, 10);

    doc.setFontSize(10);
    doc.setTextColor(0);
    doc.text(sites.find((s) => s.siteId === template.site).name, 5, 14);
    doc.text(template.device.name, 55, 14);
    doc.text(`${getDate(template.from)} ${getTime(template.from)}`, 105, 14);
    doc.text(`${getDate(template.to)} ${getTime(template.to)}`, 155, 14);

    doc.addImage(mapImage, 'JPEG', 5, 20, doc.internal.pageSize.getWidth() - 10, mapImageHeight);

    doc.autoTable({
      html: '#export-table',
      startY: mapImageHeight + 25,
      margin: { left: 5, right: 5 },
      styles: { font: 'Aller_Rg' },
    });
    const pageCount = doc.internal.getNumberOfPages();
    const pageHeight = doc.internal.pageSize.getHeight();
    const exportDate = new Date().toLocaleString();

    for (let i = 0; i < pageCount; i++) {
      doc.setPage(i);
      doc.text(
        5,
        pageHeight - 10,
        `${T.translate(LabelsLocaleKeys.exportedBy)} ${login} ${T.translate(
          LabelsLocaleKeys.on
        ).toLowerCase()} ${exportDate}`
      );
      const pages = `${T.translate(LabelsLocaleKeys.page)} ${
        doc.internal.getCurrentPageInfo().pageNumber
      }/${pageCount}`;

      const xOffset = doc.internal.pageSize.width - (doc.getStringUnitWidth(pages) * doc.internal.getFontSize()) / 2;

      doc.text(pages, xOffset, pageHeight - 10);
    }
    doc.save(`${fileName}.pdf`);
    setIsPDFDialogOpen(false);
  };

  const renderTable = (withStyles = false) => (
    <TableContainer style={withStyles ? { height: 'calc(100% - 70px)' } : null}>
      <Table stickyHeader style={withStyles ? { minWidth: '700px' } : null} id={!withStyles ? 'export-table' : ''}>
        <TableHead>
          <TableRow>
            {!withStyles && <StyledCell>{T.translate(LabelsLocaleKeys.noWithDot)}</StyledCell>}
            <StyledCell>{T.translate(LabelsLocaleKeys.device)}</StyledCell>
            <StyledCellSortable onClick={() => updateSort('timestamp')}>
              <TableSortLabel direction={sortBy.order}>{T.translate(LabelsLocaleKeys.date)}</TableSortLabel>
            </StyledCellSortable>
            <StyledCellSortable onClick={() => updateSort('timestamp')}>
              <TableSortLabel direction={sortBy.order}>{T.translate(LabelsLocaleKeys.time)}</TableSortLabel>
            </StyledCellSortable>
            <StyledCellSortable onClick={() => updateSort('speed')}>
              <TableSortLabel direction={sortBy.order}>{T.translate(LabelsLocaleKeys.speed)}</TableSortLabel>
            </StyledCellSortable>
            <StyledCell>{T.translate(LabelsLocaleKeys.location)}</StyledCell>
            <StyledCell style={{ width: '150px', whiteSpace: 'nowrap' }}>
              {T.translate(LabelsLocaleKeys.eventKeyword)}
            </StyledCell>
            <StyledCell>{T.translate(LabelsLocaleKeys.ignition)}</StyledCell>
            <StyledCell>{T.translate(LabelsLocaleKeys.latitude)}</StyledCell>
            <StyledCell>{T.translate(LabelsLocaleKeys.longitude)}</StyledCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {entriesExist &&
            filteredAndSortedEntries.map((entry, index) => (
              <TableRow key={entry.timestamp} style={{ cursor: 'pointer' }} onClick={(row) => toggleMarker(entry, row)}>
                {!withStyles && <TableCell>{index + 1}</TableCell>}
                <TableCell>{template.device?.name}</TableCell>
                <TableCell>{getDate(entry.timestamp)}</TableCell>
                <TableCell>{getTime(entry.timestamp)}</TableCell>
                <TableCell>{entry.speed !== null ? entry.speed : '-'}</TableCell>
                <TableCell>{entry.address}</TableCell>
                <TableCell>{entry.keyword}</TableCell>
                <TableCell>{formatIgnitionStatus(entry.ignitionStatus)}</TableCell>
                <TableCell>{roundCoordinate(entry.fullLatitude)}</TableCell>
                <TableCell>{roundCoordinate(entry.fullLongitude)}</TableCell>
              </TableRow>
            ))}
          {(!entriesExist || isLoading) && (
            <TableRow>
              <TableCell colSpan={6}>{isLoading ? <Loader /> : T.translate(LabelsLocaleKeys.noResults)}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <PaperStyled>
      <Formik
        initialValues={template}
        enableReinitialize
        validationSchema={TechnicalArchiveValidation}
        validateOnMount
        onSubmit={() => {
          getArchives();
        }}
      >
        {({ errors, handleChange }) => (
          <>
            <AutoSubmit />
            <Grid spacing={3} container>
              <Grid item xs={2}>
                <Dropdown
                  margin={'normal'}
                  label={T.translate(LocaleKeys.labels.sites)}
                  value={template.site}
                  data={sites}
                  onChange={(e) => {
                    setTemplate((prev) => ({
                      ...prev,
                      site: e.target.value,
                      device: null,
                    }));
                    handleChange(e);
                  }}
                  displayField="name"
                  valueField="siteId"
                  name="sites"
                  withoutEmpty
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <DropdownWithFilter
                  margin={'normal'}
                  label={T.translate(LocaleKeys.labels.devices)}
                  value={
                    template.device
                      ? {
                          label: template.device.name,
                          value: template.device.assetId,
                        }
                      : null
                  }
                  onChange={(e) => {
                    const selectedDevice = e.target.value;

                    setTemplate((prev) => ({
                      ...prev,
                      device: selectedDevice
                        ? {
                            name: selectedDevice.label,
                            assetId: selectedDevice.value,
                          }
                        : selectedDevice,
                    }));
                    handleChange(e);
                  }}
                  name="devices"
                  data={
                    (devices &&
                      devices.map((d) => {
                        return { value: d.externalId, label: d.name };
                      })) ||
                    []
                  }
                  fullWidth
                  withoutEmpty
                />
              </Grid>
              <Grid item xs={2}>
                <DatePickerWithField
                  name="from"
                  format="HH:mm dd.MM.yyyy"
                  label={T.translate(LabelsLocaleKeys.from).toUpperCase()}
                  type="datetime"
                  value={template.from}
                  errors={errors?.from}
                  onChange={(e) => {
                    if (String(e.target.value) !== invalidDate) {
                      setTemplate((prev) => ({
                        ...prev,
                        from: e.target.value,
                      }));
                    }
                    handleChange(e);
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <DatePickerWithField
                  name="to"
                  format="HH:mm dd.MM.yyyy"
                  label={T.translate(LabelsLocaleKeys.to).toUpperCase()}
                  type="datetime"
                  value={template.to}
                  errors={errors?.to}
                  onChange={(e) => {
                    if (String(e.target.value) !== invalidDate) {
                      setTemplate((prev) => ({
                        ...prev,
                        to: e.target.value,
                      }));
                    }
                    handleChange(e);
                  }}
                />
              </Grid>
              <Grid item xs={4} align="right">
                <Filters
                  style={{ width: '50%' }}
                  template={template}
                  onChange={(e) => {
                    const text = e.target;

                    if (!text) {
                      return;
                    }
                    setTemplate((prev) => ({
                      ...prev,
                      name: text.value,
                    }));
                    handleChange(e);
                  }}
                  setTemplate={(t) => {
                    setTemplate(t);
                  }}
                  type="TechnicalReport"
                />
              </Grid>
            </Grid>

            <Grid spacing={3} container style={{ height: 'calc(100% - 45px)' }}>
              <Grid item xs={2} style={{ height: 'inherit' }}>
                <TextField
                  name="search"
                  label={T.translate(LabelsLocaleKeys.search)}
                  variant="outlined"
                  placeholder={T.translate(LabelsLocaleKeys.search)}
                  value={template.search}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  onChange={(e) => {
                    const text = e.target;

                    if (!text) {
                      return;
                    }
                    setTemplate((prev) => ({
                      ...prev,
                      search: text.value,
                    }));
                    handleChange(e);
                  }}
                />
                {renderTable(true)}
                <Grid container spacing={3}>
                  {permissions[Permissions.CanExportArchiveReportToCsv] && (
                    <Grid item xs={6}>
                      <StyledButton onClick={openExportCSVDialog} disabled={!entriesExist || isLoading}>
                        {T.translate(LabelsLocaleKeys.exportToCSV)}
                      </StyledButton>
                    </Grid>
                  )}
                  {permissions[Permissions.CanExportArchiveReportToPdf] && (
                    <Grid item xs={6}>
                      <StyledButton
                        onClick={openExportPDFDialog}
                        disabled={!entriesExist || creatingPDF || isLoading}
                        align="right"
                        showProgress={creatingPDF}
                      >
                        {T.translate(LabelsLocaleKeys.exportToPDF)}
                      </StyledButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={10}>
                <div id="mapContainer" style={{ width: '100%', height: '100%' }}>
                  <LeafletMap
                    deviceTrail={
                      entriesExist
                        ? [
                            {
                              pos: filteredAndSortedEntries
                                .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
                                .map((entry) => ({
                                  latitude: entry.fullLatitude,
                                  longitude: entry.fullLongitude,
                                })),
                            },
                          ]
                        : undefined
                    }
                    maxZoom={20}
                    enableFitBounds={false}
                    center={
                      entriesExist
                        ? [filteredAndSortedEntries[0].fullLatitude, filteredAndSortedEntries[0].fullLongitude]
                        : [0, 0]
                    }
                    zoom={2}
                  >
                    {selectedEntries?.map((entry) => (
                      <ApiIconMarker
                        key={entry.timestamp}
                        position={{
                          latitude: entry.fullLatitude,
                          longitude: entry.fullLongitude,
                        }}
                        tooltipText={template.device.name}
                      />
                    ))}
                  </LeafletMap>
                </div>
              </Grid>
            </Grid>
          </>
        )}
      </Formik>
      {isCSVDialogOpen && (
        <Dialog open={isCSVDialogOpen} onClose={closeDialogBox} fullWidth maxWidth={'sm'}>
          <p style={closeDialogBtn}>
            <CloseIcon onClick={() => closeDialogBox()} />
          </p>
          <DialogTitle>{T.translate(LabelsLocaleKeys.exportToCSV)}</DialogTitle>
          <DialogContent>
            <TextField
              label={T.translate(LabelsLocaleKeys.fileName)}
              variant="outlined"
              placeholder={T.translate(LabelsLocaleKeys.fileName)}
              value={fileName}
              onChange={(e) => {
                setFileName(e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialogBox}>{T.translate(LabelsLocaleKeys.cancel)}</Button>
            <Button onClick={createAndDownloadFile} disabled={!fileName.length}>
              {T.translate(LabelsLocaleKeys.exportToCSV)}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {isPDFDialogOpen && (
        <Dialog open={isPDFDialogOpen} onClose={closeDialogBox} fullWidth maxWidth={'lg'}>
          <p style={closeDialogBtn}>
            <CloseIcon onClick={() => closeDialogBox()} />
          </p>
          <DialogTitle>{T.translate(LabelsLocaleKeys.previewPDFFile)}</DialogTitle>
          <DialogContent>
            <TextField
              label={T.translate(LabelsLocaleKeys.fileName)}
              variant="outlined"
              placeholder={T.translate(LabelsLocaleKeys.fileName)}
              value={fileName}
              onChange={(e) => {
                setFileName(e.target.value);
              }}
            />
            <StyledGrid container spacing={3}>
              <Grid item xs={3}>
                <Label>{T.translate(LabelsLocaleKeys.site)}</Label>
                <Value>{sites.find((s) => s.siteId === template.site).name}</Value>
              </Grid>
              <Grid item xs={3}>
                <Label>{T.translate(LabelsLocaleKeys.device)}</Label>
                <Value>{template.device.name}</Value>
              </Grid>
              <Grid item xs={3}>
                <Label>{T.translate(LabelsLocaleKeys.from)}</Label>
                <Value>{`${getDate(template.from)} ${getTime(template.from)}`}</Value>
              </Grid>
              <Grid item xs={3}>
                <Label>{T.translate(LabelsLocaleKeys.to)}</Label>
                <Value>{`${getDate(template.to)} ${getTime(template.to)}`}</Value>
              </Grid>
            </StyledGrid>
            <div>
              <img alt="Map screenshot" src={screenshot} style={{ maxWidth: '100%' }} id="mapImage" />
            </div>
            {renderTable()}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialogBox}>{T.translate(LabelsLocaleKeys.cancel)}</Button>
            <Button onClick={() => generatePDF()} disabled={!fileName.length}>
              {T.translate(LabelsLocaleKeys.exportToPDF)}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </PaperStyled>
  );
};

Archive.propTypes = {
  login: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  login: getLogin(state),
  mapToken: getMapToken(state),
});

export default compose(
  withPermissions([
    Permissions.CanAccessTechnicalArchiveReportPage,
    Permissions.CanReadTechnicalArchiveReport,
    Permissions.CanReadSites,
    Permissions.CanReadDevices,
  ]),
  connect(mapStateToProps)
)(Archive);
