import React from 'react';
import PropTypes from 'prop-types';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import { getResolveAlarmModes } from 'Api/devices';
import { getUsers } from 'Api/users';
import { withFormik } from 'Components/formik/formikWrappers';
import { Checkbox, DropdownWithApiCall, MultiSelectWithFilterWithApiCall } from 'Components/formik/fields';

const getOption = (option) => ({ label: option.login, value: option.login });

const Handling = (props) => (
  <ColumnDisplayer>
    <MultiSelectWithFilterWithApiCall
      name="assignedUsers"
      label={T.translate(LocaleKeys.labels.users)}
      apiCall={getUsers}
      getOption={getOption}
      fullWidth
      sortByDisplayField
    />
    <DropdownWithApiCall
      label={T.translate(LocaleKeys.labels.resolveAlarmWhenReadBy)}
      name="resolveAlarmWhenReadBy"
      apiCall={getResolveAlarmModes}
      displayField="name"
      valueField="value"
      translateLabel
      withoutEmpty
    />
    {!!props.data.resolveAlarmWhenReadBy && (
      <Checkbox label={T.translate(LocaleKeys.labels.sendNotificationWhenOver)} name="sendNotificationWhenOver" />
    )}
    <Checkbox label={T.translate(LocaleKeys.labels.popupMode)} name="showPopup" />
  </ColumnDisplayer>
);

Handling.propTypes = {
  data: PropTypes.object,
};

export default withFormik(Handling);
