import * as Sentry from '@sentry/react';
import AuthManager from 'Auth/AuthManager';
import MasterSessionManager from './MasterSessionManager';
import SlaveSessionManager from './SlaveSessionManager';
import * as datefns from 'date-fns';
import store from 'redux/store';
import * as identityServer from 'Api/identityServer';
import * as SessionMsg from './SessionMessageTypes';
import * as SessionConfig from './SessionConfig';
import Config from 'Config';
import { ApiHeaders } from 'Api/ApiClient';

const _sessionExpiringCallbacks = [];
const _sessionExpiredCallbacks = [];
const _sessionRefreshedCallbacks = [];
const _sessionRefreshingCallbacks = [];

let _sessionSharedWorker = null;
let _lastUserActivityTime = null;
let _currentSessionId = null;
let _currentUser = null;
let _sessionManager = null;
let _currentConnectedTabsCount = 0;
let _serverTimeOffset = 0;

export default class SessionManager {
  constructor() {
    AuthManager.addUserLogoutCallback(() => {
      _sessionSharedWorker.port.postMessage([SessionMsg.BROADCAST, SessionMsg.LOGOUT_CLICK]);
    });

    this.subscribeReduxStore();
    AuthManager.events.addUserLoaded((user) => {
      _currentSessionId = user.profile.sid;
      _currentUser = user;

      this.initSharedWorker();
      identityServer.cancelSessionCloseTimeout(_currentSessionId);
    });
  }

  initSharedWorker = () => {
    if (!!window.SharedWorker) {
      _sessionSharedWorker = new SharedWorker('sessionSharedWorker.js?buster=8', { type: 'module' });
    } else {
      Sentry.captureMessage('browser does not support SharedWorker');
      _sessionSharedWorker = {
        port: {
          addEventListener: () => {},
          removeEventListener: () => {},
          start: () => {},
          postMessage: () => {},
        },
      };
    }
    _sessionSharedWorker.port.addEventListener('message', this.sharedWorkerMessageHandler);
    _sessionSharedWorker.port.start();

    window.addEventListener('beforeunload', (e) => {
      if (_currentConnectedTabsCount === 1) {
        const sessionId = _currentSessionId;
        const accessToken = _currentUser.access_token;
        const correlationId = `F/${Date.now()}`;

        fetch(`${Config.authSettings.authority}/api/Session/CloseWithTimeout/${sessionId}`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            sid: sessionId,
            [ApiHeaders.X_CORRELATION_ID]: correlationId,
          },
          keepalive: true,
        }).catch((error) => {
          Sentry.captureMessage('Error on beforeunload from: "api/Session/CloseWithTimeout"', {
            tags: {
              correlationId,
            },
            extra: {
              method: 'post',
              url: `${Config.authSettings.authority}/api/Session/CloseWithTimeout/${sessionId}`,
              message: error.message,
            },
          });
        });

        const hackedWaitForSendCloseSessionRequest = () => {
          const startDateTime = Date.now();

          while (true) {
            const delay = 500;

            if (startDateTime + delay < Date.now()) {
              break;
            }
          }
        };

        hackedWaitForSendCloseSessionRequest();
      }

      _sessionSharedWorker.port.postMessage([SessionMsg.CLOSE_TAB]);
    });
  };

  subscribeReduxStore = () => {
    store.subscribe(() => {
      const newState = store.getState();
      const identityUser = newState.app.identityUser;
      const lastUserActivityTime = newState.app.lastUserActivityTime;

      if (identityUser && identityUser.profile.sid !== _currentSessionId) {
        _currentUser = identityUser;
        _currentSessionId = identityUser.profile.sid;
        if (_sessionManager instanceof MasterSessionManager) {
          _sessionManager.setCurrentSessionId(_currentSessionId);
          _sessionManager.updateSession();
        }
      }

      if (+new Date(_lastUserActivityTime) !== +new Date(lastUserActivityTime)) {
        _lastUserActivityTime = lastUserActivityTime;
        if (_sessionSharedWorker) {
          _sessionSharedWorker.port.postMessage([SessionMsg.LAST_USER_ACTIVITY_CHANGED, lastUserActivityTime]);
        }
      }
    });
  };

  sharedWorkerMessageHandler = (e) => {
    const receivedMessageType = e.data[0];

    switch (receivedMessageType) {
      case 'onconnect_result':
        if (e.data[1] === SessionMsg.INIT_MASTER) {
          _sessionManager = new MasterSessionManager(_sessionSharedWorker, _serverTimeOffset);
          _sessionManager.setCurrentSessionId(_currentSessionId);
          _sessionManager.events.addSessionExpired(this.sessionExpiredEvent);
          _sessionManager.events.addSessionExpiring(this.sessionExpiringEvent);
        }
        if (e.data[1] === SessionMsg.INIT_SLAVE) {
          _sessionManager = new SlaveSessionManager(_sessionSharedWorker, _serverTimeOffset);
          _sessionManager.events.addSessionExpired(this.sessionExpiredEvent);
          _sessionManager.events.addSessionExpiring(this.sessionExpiringEvent);
        }
        break;

      case SessionMsg.SWITCH_TO_MASTER:
        const sessionDetails = _sessionManager.sessionDetails;

        _sessionManager.events.removeSessionExpired(this.sessionExpiredEvent);
        _sessionManager.events.removeSessionExpiring(this.sessionExpiringEvent);
        _sessionManager.close();

        _sessionManager = new MasterSessionManager(_sessionSharedWorker, _serverTimeOffset);
        _sessionManager.sessionDetails = sessionDetails;
        _sessionManager.setCurrentSessionId(_currentSessionId);
        _sessionManager.updateSession();
        _sessionManager.events.addSessionExpired(this.sessionExpiredEvent);
        _sessionManager.events.addSessionExpiring(this.sessionExpiringEvent);
        break;

      case SessionMsg.LOGOUT_CLICK:
        _sessionSharedWorker.port.removeEventListener('message', this.sharedWorkerMessageHandler);
        _sessionSharedWorker.port.postMessage([SessionMsg.CLOSE_PORT]);
        setTimeout(() => {
          const redirectUrl = window.location;
          const sessionExpiredPageUrl = `/session-expired.html?redirectUrl=${redirectUrl}`;

          window.location.href = sessionExpiredPageUrl;
        }, 1000);
        break;

      case SessionMsg.SESSION_REFRESHING:
        this.sessionRefreshingEvent();
        break;

      case SessionMsg.SESSION_REFRESHED:
        this.sessionRefreshedEvent();
        break;

      case SessionMsg.PING:
        _sessionSharedWorker.port.postMessage([SessionMsg.PONG]);
        break;

      case SessionMsg.TAB_CONNECTED_COUNT:
        _currentConnectedTabsCount = e.data[2];
        break;

      default:
        break;
    }
  };

  setServerTimeOffset = (serverTimeOffset) => {
    _serverTimeOffset = serverTimeOffset;
    if (_sessionManager) {
      _sessionManager.setServerTimeOffset(_serverTimeOffset);
    }
  };

  checkSessionStatus = async () => {
    const sessionDetailsRequest = await identityServer.getSession(_currentSessionId);

    _sessionManager.setSessionInfo(sessionDetailsRequest.data);
    const timeToSessionExpire = this.getTimeToSessionExpire();
    const shouldPublishExpiringEvent = timeToSessionExpire < SessionConfig.EXPIRING_NOTIFICATION_TIME;

    if (shouldPublishExpiringEvent) {
      this.sessionExpiringEvent();
    }
  };

  updateSession = async () => {
    if (_sessionManager instanceof MasterSessionManager) {
      await _sessionManager.updateSession();
    } else {
      await this.checkSessionStatus();
    }
  };

  updateSessionWhenReady = () => {
    return new Promise((resolve, reject) => {
      const promiseStartDate = Date.now();
      const interval = setInterval(async () => {
        if (_sessionManager) {
          clearInterval(interval);
          try {
            await this.updateSession();
            resolve();
          } catch (error) {
            reject(error);
          }
        } else if (Date.now() - promiseStartDate > 30 * 1000) {
          reject(new Error('TIMEOUT - Session manager was not ready in 30 seconds'));
          clearInterval(interval);
        }
      }, 1000);
    });
  };

  refreshSessionClick = async () => {
    _sessionSharedWorker.port.postMessage([SessionMsg.SESSION_REFRESHING]);
    _sessionSharedWorker.port.postMessage([SessionMsg.REFRESH_SESSION_REQUEST]);
  };

  logoutClick = () => {
    AuthManager.logout();
  };

  getTimeToSessionExpire = () => {
    const expireDateTime = datefns.parseJSON(_sessionManager.sessionDetails.expireDateTime);
    const timeToSessionExpire = expireDateTime - (Date.now() + _serverTimeOffset);

    return timeToSessionExpire - SessionConfig.EXPIRED_TIME_OFFSET;
  };

  sessionExpiringEvent = () => {
    _sessionExpiringCallbacks.forEach((callback) => {
      callback();
    });
  };

  sessionExpiredEvent = () => {
    _sessionExpiredCallbacks.forEach((callback) => {
      callback();
    });
  };

  sessionRefreshedEvent = () => {
    _sessionRefreshedCallbacks.forEach((callback) => {
      callback();
    });
  };

  sessionRefreshingEvent = () => {
    _sessionRefreshingCallbacks.forEach((callback) => {
      callback();
    });
  };

  events = {
    addSessionExpiring(callback) {
      _sessionExpiringCallbacks.push(callback);
    },
    addSessionExpired(callback) {
      _sessionExpiredCallbacks.push(callback);
    },
    addSessionRefreshed(callback) {
      _sessionRefreshedCallbacks.push(callback);
    },
    addSessionRefreshing(callback) {
      _sessionRefreshingCallbacks.push(callback);
    },
  };
}
