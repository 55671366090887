import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Paper, styled, Fab } from '@material-ui/core';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import Button from 'Components/Button';
import { CollectionHeader } from 'Components/inputs/CollectionManager/FormikCollection';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import LocaleKeys from 'Localization/LocaleKeys';
import SaveOnLeave from 'Components/dialogs/SaveOnLeave';
import { getHolidayByName, postHoliday, putHoliday } from 'Api/sites';
import T from 'i18n-react';
import { HolidayTemplates } from 'routes/Routes';
import { compose } from 'redux';
import { removeProperty } from 'Helpers/ObjectHelper';
import { FieldArray } from 'formik';
import { FormikEnhanced } from 'Components/formik/formikWrappers';
import HolidayValidation from './HolidayValidation';
import DeleteIcon from '@material-ui/icons/Delete';
import Loader from 'Components/Loader';
import { addCurrentTimezoneOffset, getDateFromDateTime, subtractCurrentTimezoneOffset } from 'Helpers/DateTimeHelpers';
import { isDateCorrect } from 'Validators/SharedValidators';
import { TextField, DateTimeInput } from 'Components/formik/fields';

const DateTimeInputWithField = styled(DateTimeInput)({
  marginLeft: 5,
  marginRight: 5,
  marginTop: 10,
});

const Base = styled('div')({
  padding: '10px',
  minWidth: '25%',
  width: 'auto',
  display: 'block',
});
const Line = styled('div')({
  margin: '10px 0',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});
const FabStyled = styled(Fab)({
  minHeight: '40px',
  height: '40px',
  width: '40px',
  minWidth: '40px',
});

const ButtonStyled = styled(Button)({
  float: 'right',
  marginLeft: '10px',
});

const convertDate = (datetime) => {
  if (isDateCorrect(datetime)) {
    const subtracted = subtractCurrentTimezoneOffset(datetime);

    return getDateFromDateTime(subtracted);
  }

  return datetime;
};

const prepareHolidayEntries = (entries) => {
  return entries.map((e) => ({
    name: e.name,
    holidayId: e.holidayId,
    id: e.id,
    date: convertDate(e.date),
  }));
};

export const HolidayDetails = (props) => {
  const [data, setData] = useState({
    name: '',
    description: '',
    year: new Date().getFullYear(),
    entries: [],
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const name = props.match.params?.name?.replace(/-/g, ' ');
  const locationState = props.history.location.state;

  useEffect(() => {
    if (name || (locationState && locationState.baseOnHolidayTemplate)) {
      getHolidayByName(name || locationState.baseOnHolidayTemplate?.replace(/-/g, ' ')).then(({ data }) => {
        data.entries = prepareHolidayEntries(data.entries);
        if (!name) {
          removeProperty(data, 'holidayId');
          removeProperty(data, 'id');
          data.name = '';
        }
        setData(data);
        setIsLoaded(true);
      });
    } else {
      setIsLoaded(true);
    }
  }, [name, locationState]);

  const isEditMode = () => {
    return !!data.holidayId;
  };

  const onSubmit = (values, { setSubmitting }) => {
    if (values.holidayId) {
      putHoliday(values.holidayId, values).then(() => {
        setSubmitting(false);
        redirectToHolidayList();
      });
    } else {
      postHoliday(values).then(() => {
        setSubmitting(false);
        redirectToHolidayList();
      });
    }
  };

  const redirectToHolidayList = () => {
    const url = HolidayTemplates.getUrl();

    props.history.push(url);
  };

  const canSaveChanges = isEditMode()
    ? !!props.permissions[Permissions.CanEditHolidaysTemplates]
    : !!props.permissions[Permissions.CanAddHolidaysTemplates];
  const isReadOnly = !canSaveChanges;

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <FormikEnhanced
      initialValues={data}
      enableReinitialize
      validationSchema={HolidayValidation}
      validateOnMount
      onSubmit={onSubmit}
      validateOnChange
      canSaveChanges={canSaveChanges}
    >
      {({ values, dirty, isValid, isSubmitting, handleSubmit, handleChange }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Paper>
              <ColumnDisplayer>
                <TextField name="name" label={T.translate(LocaleKeys.labels.name)} showPending />
                <TextField label={T.translate(LocaleKeys.labels.description)} name="description" />
                <TextField label={T.translate(LocaleKeys.labels.year)} name="year" type="number" />
                <Base>
                  <FieldArray
                    name={'entries'}
                    render={({ push, remove }) => (
                      <>
                        <CollectionHeader
                          label={T.translate(LocaleKeys.labels.holidayEntries)}
                          onAddClick={() => {
                            push({
                              isDefault: values.entries?.length === 0 || !values.entries,
                              name: '',
                              date: convertDate(new Date()),
                            });
                          }}
                          readOnly={isReadOnly}
                        />
                        {values.entries?.map((v, index) => (
                          <Line key={index}>
                            <TextField label={T.translate(LocaleKeys.labels.name)} name={`entries.${index}.name`} />
                            <DateTimeInputWithField
                              label={T.translate(LocaleKeys.labels.date)}
                              type="date"
                              name={`entries.${index}.date`}
                              onChange={(e) => {
                                e.target.value = convertDate(e.target.value);
                                handleChange(e);
                              }}
                              convertValue={addCurrentTimezoneOffset}
                              format="dd.MM.yyyy"
                            />
                            <FabStyled
                              onClick={() => {
                                remove(index);
                              }}
                              disabled={isReadOnly}
                            >
                              <DeleteIcon />
                            </FabStyled>
                          </Line>
                        ))}
                      </>
                    )}
                  />
                </Base>
              </ColumnDisplayer>
            </Paper>
            <ButtonStyled onClick={redirectToHolidayList} id="holidayTemplates-cancelButton">
              {T.translate(LocaleKeys.labels.cancel)}
            </ButtonStyled>
            {canSaveChanges && (
              <>
                <ButtonStyled
                  disabled={!(dirty && isValid) || isSubmitting}
                  type="submit"
                  id="holidayTemplates-submitButton"
                >
                  {T.translate(LocaleKeys.labels.save)}
                </ButtonStyled>
                <SaveOnLeave saveData={() => onSubmit(values)} dataChanged={dirty} validForm={isValid} />
              </>
            )}
          </form>
        );
      }}
    </FormikEnhanced>
  );
};

HolidayDetails.propTypes = {
  history: PropTypes.object,
  permissions: PropTypes.object,
};

export default compose(
  withPermissions([Permissions.CanAccessHolidaysTemplatesPage, Permissions.CanReadHolidaysTemplates])
)(HolidayDetails);

export const HolidayNew = compose(
  withPermissions([
    Permissions.CanAccessHolidaysTemplatesPage,
    Permissions.CanReadHolidaysTemplates,
    Permissions.CanAddHolidaysTemplates,
  ])
)(HolidayDetails);
