import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { Box, styled } from '@material-ui/core';
import FormikCollection from 'Components/inputs/CollectionManager/FormikCollection';
import { TextField, TextOutput } from 'Components/formik/fields';

const TextFieldStyled = styled(TextField)({
  minWidth: 300,
});
const TextOutputStyled = styled(TextOutput)({
  minWidth: 300,
});

const AnalogInput = (props) => {
  if (!props.data.mappings?.analogInputMappings) {
    return <div>{T.translate(LocaleKeys.messages.noDeviceLibrarySelected)}</div>;
  }
  if (!props.data.mappings.analogInputMappings?.length) {
    return <div>{T.translate(LocaleKeys.messages.noInputsMappingsForSelectedDriver)}</div>;
  }

  return (
    <Box margin={'10px 3%'}>
      <FormikCollection
        name={'mappings.analogInputMappings'}
        disableAddItem
        disableRemoveItem
        readOnly={props.data.brokenAlarmsLoading}
      >
        <TextFieldStyled
          label={T.translate(LocaleKeys.labels.analogInputName)}
          name={'name'}
          fullWidth
          readOnly={props.data.brokenAlarmsLoading}
        />
        <TextOutputStyled
          label={T.translate(LocaleKeys.labels.analogInputValue)}
          name={'inputValue'}
          fullWidth
          disabled
          translateValue
        />
      </FormikCollection>
    </Box>
  );
};

AnalogInput.propTypes = {
  data: PropTypes.object,
  brokenAlarmsLoading: PropTypes.bool,
};

export default AnalogInput;
