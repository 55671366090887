import { postResource, getResource } from 'Api/devices';
import { clone } from 'Helpers/ObjectHelper';
import { VariableTypes } from 'Constants/VariableTypes';
import { AlarmValidityTypes } from 'Constants/AlarmValidityTypes';
import { AlarmOccurrenceTypes } from 'Constants/AlarmOccurrenceTypes';
import { WebhookTypes } from 'Constants/WebhookTypes';

export const DAYS_TIME_PERIOD_MASK = {
  PATTERN: /[0-9_][0-9_]d [0-9_][0-9_]h [0-5_][0-9_]min [0-5_][0-9_]s/,
  DEFAULT_MASK: '00d 00h 00min 00s',
  DEFAULT_VALUE: 0,
};

export const TIME_PERIOD_MASK = {
  PATTERN: /[0-9_][0-9_]h [0-5_][0-9_]min [0-5_][0-9_]s/,
  DEFAULT_MASK: '00h 00min 00s',
  DEFAULT_VALUE: 0,
};

export const HOURS_MINUTES_MASK = {
  PATTERN: /[0-9_][0-9_]h [0-5_][0-9_]min/,
  DEFAULT_MASK: '00h 00min',
  DEFAULT_VALUE: 0,
};

const zeroPad = (number, places) => String(number).padStart(places, '0');

export const addResource = async (data, fieldName, idFieldName, config = {}) => {
  data[fieldName].value = data[fieldName].value.split('base64,')[1];

  const addResourceResponse = await postResource(data[fieldName], config);

  data[idFieldName] = addResourceResponse.data.id;
  data[fieldName] = null;
};

export const loadResource = async (data, nameField, imageIdField, config = {}) => {
  const getResourceResponse = await getResource(data[imageIdField], config);

  data[nameField] = getResourceResponse.data;
  data[nameField].value = `data:${data[nameField].mimeType};base64,${data[nameField].value}`;
};

export const convertTimePeriodToSeconds = (timePeriod) => {
  if (typeof timePeriod === 'string' && timePeriod.match(TIME_PERIOD_MASK.PATTERN)) {
    let [hours, minutes, seconds] = timePeriod.split(' ');

    hours = parseInt(hours.replace('h', '')) || 0;
    minutes = parseInt(minutes.replace('min', '')) || 0;
    seconds = parseInt(seconds.replace('s', '')) || 0;

    return 3600 * hours + 60 * minutes + seconds;
  }

  const parsedPeriod = parseInt(timePeriod);

  return Number.isInteger(timePeriod) || timePeriod === parsedPeriod.toString()
    ? parsedPeriod
    : TIME_PERIOD_MASK.DEFAULT_VALUE;
};

export const convertHoursMinutesToMinutes = (timePeriod) => {
  if (typeof timePeriod === 'string' && timePeriod.match(HOURS_MINUTES_MASK.PATTERN)) {
    let [hours, minutes] = timePeriod.split(' ');

    hours = parseInt(hours.replace('h', '')) || 0;
    minutes = parseInt(minutes.replace('min', '')) || 0;

    return 60 * hours + minutes;
  }

  const parsedPeriod = parseInt(timePeriod);

  return Number.isInteger(timePeriod) || timePeriod === parsedPeriod.toString()
    ? parsedPeriod
    : HOURS_MINUTES_MASK.DEFAULT_VALUE;
};

export const convertSecondsToTimePeriodMask = (totalSeconds) => {
  if (totalSeconds === undefined || !totalSeconds) {
    return TIME_PERIOD_MASK.DEFAULT_MASK;
  }

  let remindingSeconds = totalSeconds;
  const hours = parseInt(remindingSeconds / 3600);

  remindingSeconds %= 3600;
  const minutes = parseInt(remindingSeconds / 60);

  remindingSeconds %= 60;
  const seconds = parseInt(remindingSeconds);

  return `${zeroPad(hours, 2)}h ${zeroPad(minutes, 2)}min ${zeroPad(seconds, 2)}s`;
};

export const convertMinutesToHoursMinutesMask = (totalMinutes) => {
  if (totalMinutes === undefined || !totalMinutes) {
    return HOURS_MINUTES_MASK.DEFAULT_MASK;
  }

  let remindingMinutes = totalMinutes;
  const hours = parseInt(remindingMinutes / 60);

  remindingMinutes %= 60;
  const minutes = parseInt(remindingMinutes);

  return `${zeroPad(hours, 2)}h ${zeroPad(minutes, 2)}min`;
};

const convertCommandsDelayToSeconds = (commands) => {
  commands.forEach((command) => {
    command.delay = convertTimePeriodToSeconds(command.delay);
  });
};

export const convertCommandsDelayToPeriodMask = (commands) => {
  commands.forEach((command) => {
    command.delay = convertSecondsToTimePeriodMask(command.delay);
  });
};

export const convertSecondsToTimePeriodMaskDays = (totalSeconds) => {
  if (totalSeconds === undefined || !totalSeconds) {
    return DAYS_TIME_PERIOD_MASK.DEFAULT_MASK;
  }

  let remindingSeconds = totalSeconds;
  let days = parseInt(remindingSeconds / (3600 * 24));

  remindingSeconds %= 3600 * 24;
  if (days > 99) {
    const otherDays = days - 99;

    remindingSeconds += otherDays * 3600 * 24;

    days = 99;
  }

  return `${zeroPad(days, 2)}d ${convertSecondsToTimePeriodMask(remindingSeconds)}`;
};

export const convertTimePeriodDaysToSeconds = (timePeriod) => {
  if (typeof timePeriod === 'string' && timePeriod.match(DAYS_TIME_PERIOD_MASK.PATTERN)) {
    let [days, hours, minutes, seconds] = timePeriod.split(' ');

    days = parseInt(days.replace('d', '')) || 0;
    hours = parseInt(hours.replace('h', '')) || 0;
    minutes = parseInt(minutes.replace('min', '')) || 0;
    seconds = parseInt(seconds.replace('s', '')) || 0;

    return 3600 * 24 * days + 3600 * hours + 60 * minutes + seconds;
  }

  const parsedPeriod = parseInt(timePeriod);

  return Number.isInteger(timePeriod) || timePeriod === parsedPeriod.toString()
    ? parsedPeriod
    : DAYS_TIME_PERIOD_MASK.DEFAULT_VALUE;
};

const prepareFormulas = (formulas) => {
  return formulas.map((formula) => {
    formula.sections.map((section) => {
      section.conditions.map((condition) => {
        condition.targetValues = condition.targetValues.filter((value) => value === 0 || value);
        if (condition.operatorType === VariableTypes.TIME_PERIOD) {
          condition.targetValues = condition.targetValues.map((value) => convertTimePeriodToSeconds(value));
        }

        return condition;
      });

      return section;
    });

    return formula;
  });
};

export const convertFormulas = (formulas) => {
  return formulas.map((formula) => {
    formula.sections.map((section) => {
      section.conditions.map((condition) => {
        if (condition.operatorType === VariableTypes.TIME_PERIOD) {
          condition.targetValues = condition.targetValues.map((value) => convertSecondsToTimePeriodMask(value));
        }

        return condition;
      });

      return section;
    });

    return formula;
  });
};

export const convertMinutesToTime = (value) => {
  let time = value;

  if (value || value === 0) {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;

    time = new Date(null, null, null, hours, minutes, 0, 0);
  }

  return time;
};

export const convertTimeToMinutes = (value) => {
  let minutes = value;

  if (value) {
    const dateObj = new Date(value);

    minutes = dateObj.getHours() * 60 + dateObj.getMinutes();
  }

  return minutes;
};

export const prepareAlarmData = (alarm) => {
  const preparedAlarm = clone(alarm);

  if (alarm.validityType === AlarmValidityTypes.CUSTOM) {
    preparedAlarm.calendarTemplateId = null;
  } else if (alarm.validityType === AlarmValidityTypes.CALLENDAR_TEMPLATE) {
    preparedAlarm.validFrom = null;
    preparedAlarm.validTo = null;
    preparedAlarm.timeZone = null;
  } else {
    preparedAlarm.validFrom = null;
    preparedAlarm.validTo = null;
    preparedAlarm.timeZone = null;
    preparedAlarm.calendarTemplateId = null;
  }

  if (alarm.occurrenceType === AlarmOccurrenceTypes.AUTO_HANDLE) {
    preparedAlarm.isAutoRead = false;
    preparedAlarm.autoReadInMinutes = null;
  } else {
    if (!alarm.isAutoRead) {
      preparedAlarm.autoReadInMinutes = null;
    } else {
      preparedAlarm.autoReadInMinutes = convertHoursMinutesToMinutes(preparedAlarm.autoReadInMinutes);
    }
  }

  if (alarm.commands && alarm.commands.length) {
    convertCommandsDelayToSeconds(preparedAlarm.commands);
  }

  if (alarm.oneTimeAlarmTimePeriod) {
    preparedAlarm.oneTimeAlarmTimePeriod = convertTimePeriodDaysToSeconds(alarm.oneTimeAlarmTimePeriod);
  }

  if (alarm.emails.length) {
    preparedAlarm.emails = alarm.emails.map((o) => o.email);
  }

  if (alarm.assignedUsers) {
    preparedAlarm.assignedUsers = alarm.assignedUsers.map((u) => u.label);
  }

  if (alarm.startFormulas.length) {
    preparedAlarm.startFormulas = prepareFormulas(preparedAlarm.startFormulas);
  }
  if (alarm.endFormulas.length) {
    preparedAlarm.endFormulas = prepareFormulas(preparedAlarm.endFormulas);
  }

  if (alarm.imageOffId === 0) {
    preparedAlarm.imageOffId = null;
  }

  if (alarm.imageOnId === 0) {
    preparedAlarm.imageOnId = null;
  }

  return preparedAlarm;
};

export const mapInputsMappings = (data) =>
  data.map((el) => ({
    externalId: el.externalId,
    name: el.name,
    variableType: el.variableType,
    inputMappingType: el.inputMappingType,
  }));

export const getLibraryInputMappings = (library) => {
  if (!library) {
    return [];
  }

  const analog = mapInputsMappings(library.analogInputMappings);
  const digital = mapInputsMappings(library.digitalInputMappings);
  const keywords = mapInputsMappings(library.keywordMappings);

  return [...digital, ...analog, ...keywords];
};

const replaceFormulasInputs = (formulas, prevMappings, mappings) => {
  if (!formulas || !formulas.length) {
    return [];
  }

  return formulas.map((f) => {
    f.sections = f.sections?.map((s) => {
      s.conditions = s.conditions?.map((c) => {
        const mapping = prevMappings.find((m) => m.externalId === c.inputMappingExternalId);

        if (mapping) {
          c.inputMappingExternalId = mappings.find(
            (m) =>
              m.name === mapping.name &&
              m.inputMappingType === mapping.inputMappingType &&
              m.inputValue === mapping.inputValue
          ).externalId;
        }

        return c;
      });

      return s;
    });

    return f;
  });
};

export const replaceAlarmLibrary = (prevLibrary, alarmLibrary, alarm) => {
  if (!prevLibrary || prevLibrary === alarmLibrary) {
    return {
      ...alarm,
      deviceLibraryId: alarmLibrary.id,
    };
  }

  const prevMappings = prevLibrary.analogInputMappings.concat(
    prevLibrary.digitalInputMappings,
    prevLibrary.keywordMappings
  );
  const mappings = alarmLibrary.analogInputMappings.concat(
    alarmLibrary.digitalInputMappings,
    alarmLibrary.keywordMappings
  );

  return {
    ...alarm,
    startFormulas: replaceFormulasInputs(alarm.startFormulas, prevMappings, mappings),
    endFormulas: replaceFormulasInputs(alarm.endFormulas, prevMappings, mappings),
    deviceLibraryId: alarmLibrary.id,
  };
};

export const mapUsedInputs = (alarmLibrary, alarm) => {
  const result = [];

  if (!alarmLibrary) {
    return result;
  }

  const mergedMappings = alarmLibrary.digitalInputMappings.concat(
    alarmLibrary.analogInputMappings,
    alarmLibrary.keywordMappings
  );

  if (alarm.startFormulas.length) {
    mapUsedInputsForLibrary(result, alarm.startFormulas, mergedMappings);
  }
  if (alarm.endFormulas.length) {
    mapUsedInputsForLibrary(result, alarm.endFormulas, mergedMappings);
  }

  return result;
};

const mapUsedInputsForLibrary = (result, formulas, mergedMappings) => {
  formulas.forEach((s) =>
    s.sections.forEach((c) =>
      c.conditions.forEach((b) => {
        const found = result.find((f) => f.inputMappingType === b.inputMappingType);

        const libraryInput = mergedMappings.find((m) => m.externalId === b.inputMappingExternalId);

        if (libraryInput) {
          if (!found) {
            result.push({
              inputMappingType: b.inputMappingType,
              mappings: [
                {
                  name: libraryInput.name,
                  value: libraryInput.inputValue,
                },
              ],
            });
          } else {
            if (!found.mappings.find((m) => m.name === libraryInput.name && m.value === libraryInput.inputValue)) {
              found.mappings.push({
                name: libraryInput.name,
                value: libraryInput.inputValue,
              });
            }
          }
        }

        return b;
      })
    )
  );
};

export const mapWebhooks = (webhooksDto) => {
  const allWebhooksOptions = Object.values(WebhookTypes).map((type) => ({
    type,
    isEnabled: webhooksDto.some((webhook) => webhook.type === type && webhook.isEnabled),
  }));

  return allWebhooksOptions;
};

export const getDefaultWebhooks = () => {
  return Object.values(WebhookTypes).map((type) => ({
    type,
    isEnabled: false,
  }));
};
