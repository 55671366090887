import { types } from './actions';
import { deepMergeObjects } from 'Helpers/ObjectHelper';
import { types as sitesTypes } from 'redux/sites/actions';

const initialState = {
  loading: true,
  error: null,
  devices: [],
  loadingThreatBoardAlarms: true,
  threatBoardAlarmsError: null,
  threatboardAlarms: [],
  threatboardDevices: [],
  totalNotifications: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // reset state of dashboard assets
    case sitesTypes.SET_CURRENT_SITE_ID:
      return {
        ...state,
        loading: false,
        error: null,
        devices: [],
        threatboardDevices: [],
        threatboardAlarms: [],
        loadingThreatBoardAlarms: true,
        threatBoardAlarmsError: null,
      };
    case types.SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case types.ADD_THREATBOARD_ALARM:
      const foundAlarm = state.threatboardAlarms.find(
        (alarm) => alarm.alarmId === action.payload.alarm.alarmId && alarm.assetId === action.payload.alarm.assetId
      );

      if (foundAlarm) {
        if (new Date(foundAlarm.createdOn) > new Date(action.payload.alarm.createdOn)) {
          return state;
        }

        return {
          ...state,
          threatboardAlarms: state.threatboardAlarms.map((alarm) =>
            alarm.uniqueId !== foundAlarm.uniqueId ? alarm : action.payload.alarm
          ),
        };
      }

      return {
        ...state,
        threatboardAlarms: [...state.threatboardAlarms, action.payload.alarm],
      };
    case types.SET_THREATBOARD_ALARMS:
      return {
        ...state,
        threatboardAlarms: action.payload.alarms,
      };
    case types.LOAD_THREATBOARD_ALARMS_SUCCESS:
      const loadedAlarms = action.payload.alarms;
      const newAlarms = loadedAlarms.filter(
        (prevAlarm) =>
          !state.threatboardAlarms.find(
            (alarm) => prevAlarm.alarmId === alarm.alarmId && prevAlarm.assetId === alarm.assetId
          )
      );
      const updatedAlarms = state.threatboardAlarms.map((prevAlarm) => {
        const foundAlarm = loadedAlarms.find(
          (newAlarm) => newAlarm.alarmId === prevAlarm.alarmId && newAlarm.assetId === prevAlarm.assetId
        );

        if (foundAlarm && new Date(foundAlarm.createdOn) > new Date(prevAlarm.createdOn)) {
          return foundAlarm;
        }

        return prevAlarm;
      });

      return {
        ...state,
        threatboardAlarms: updatedAlarms.concat(newAlarms),
        loadingThreatBoardAlarms: false,
      };
    case types.LOAD_THREATBOARD_ALARMS_FAIL:
      return {
        ...state,
        threatBoardError: action.payload.error,
        loadingThreatBoardAlarms: false,
      };
    case types.ADD_THREATBOARD_DEVICE:
      if (state.threatboardDevices.includes(action.assetId)) {
        return state;
      }

      return {
        ...state,
        threatboardDevices: [...state.threatboardDevices, action.assetId],
      };
    case types.DELETE_THREATBOARD_DEVICE:
      return {
        ...state,
        threatboardDevices: state.threatboardDevices.filter((assetId) => assetId !== action.assetId),
      };
    case types.SET_THREATBOARD_DEVICES:
      return {
        ...state,
        threatboardDevices: action.devices,
      };
    case types.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        devices: action.payload.devices,
      };
    case types.LOAD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case types.UPDATE:
      const { externalId, device: deviceUpdate } = action.payload;

      return {
        ...state,
        devices: state.devices.map((device) =>
          device.externalId === externalId ? deepMergeObjects({}, device, deviceUpdate) : device
        ),
      };
    case types.UPDATE_POSITION:
      return {
        ...state,
        devices: state.devices.map((device) => {
          if (device.externalId === action.payload.externalId) {
            const maxFrom = Date.now() - 3600 * 1000;
            const geoPoints = device.geoPoints
              ? [action.payload.position, ...device.geoPoints.filter((geoPoint) => geoPoint.timestamp >= maxFrom)]
              : undefined;

            return {
              ...device,
              position: action.payload.position,
              geoPoints,
            };
          }

          return device;
        }),
      };
    case types.SET_GEO_POINTS:
      return {
        ...state,
        devices: state.devices.map((device) => {
          if (device.externalId === action.payload.externalId) {
            return {
              ...device,
              geoPoints: action.payload.geoPoints,
            };
          }

          return device;
        }),
      };

    case types.SET_COMMAND_BUTTON:
      return {
        ...state,
        devices: state.devices.map((asset) => {
          if (asset.externalId === action.payload.assetId) {
            const commandButtons = asset.commandButtons || {};
            const { buttonId, currentState, lastSentAt } = action.payload;

            return {
              ...asset,
              commandButtons: {
                ...commandButtons,
                [buttonId]: {
                  currentState,
                  lastSentAt,
                },
              },
            };
          }

          return asset;
        }),
      };

    default:
      return state;
  }
};
