import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import TextField from '@material-ui/core/TextField';
import { IMaskMixin, IMask } from 'react-imask';

export const Masks = {
  HoursMinutes: {
    mask: 'DDh MMmin',
    blocks: {
      DD: {
        mask: '00',
      },
      MM: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 59,
      },
    },
    maskDefaultValue: '',
  },
  HoursMinutesSeconds: {
    mask: 'HHh MMmin SSs',
    blocks: {
      HH: {
        mask: '00',
      },
      MM: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 59,
      },
      SS: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 59,
      },
    },
    maskDefaultValue: '',
  },
  DaysHoursMinutesSeconds: {
    mask: 'DDd HHh MMmin SSs',
    blocks: {
      DD: {
        mask: '00',
      },
      HH: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 23,
      },
      MM: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 59,
      },
      SS: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 59,
      },
    },
    maskDefaultValue: '',
  },
};

// eslint-disable-next-line new-cap
const IMaskInput = IMaskMixin(({ inputRef, ...otherProps }) => {
  return <TextField {...otherProps} inputRef={inputRef} />;
});

export const MaskedTextInput = ({
  value,
  convertValue,
  onChange,
  InputLabelProps,
  valid,
  validationerrorlocalekeys,
  maskConfig,
  ...otherProps
}) => {
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.maskRef.value = convertValue(value || maskConfig.maskDefaultValue);
    inputRef.current.maskRef.updateValue();
  }, [value]);

  return (
    <IMaskInput
      ref={inputRef}
      id={`maskedInput-${otherProps.name}`}
      data-testid={`maskedInput-${otherProps.name}`}
      defaultValue={convertValue(value)}
      onAccept={(value) => {
        if (onChange) {
          onChange({ target: { name: otherProps.name, value } });
        }
      }}
      overwrite
      lazy={false} // make placeholder always visible
      variant="outlined"
      error={valid && valid !== 'true'}
      helperText={valid && valid !== 'true' && T.translate(LocaleKeys.validation[validationerrorlocalekeys])}
      InputLabelProps={{ shrink: true, ...InputLabelProps }}
      {...maskConfig}
      {...otherProps}
    />
  );
};

MaskedTextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  maskConfig: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  valid: PropTypes.string,
  readOnly: PropTypes.bool,
  convertValue: PropTypes.func,
  InputLabelProps: PropTypes.object,
};

MaskedTextInput.defaultProps = {
  label: '',
  disabled: false,
  valid: 'true',
  readOnly: false,
  convertValue: (value) => value,
  maskConfig: Masks.HoursMinutes,
};

export default MaskedTextInput;
