import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import DropdownRaw from 'Components/inputs/Dropdown';
import { withField, withFormik } from 'Components/formik/formikWrappers';
import { getIn } from 'formik';
import { styled } from '@material-ui/core';
import FormikCollection from 'Components/inputs/CollectionManager/FormikCollection';
import { DigitalSources } from 'Constants/DigitalSources';
import { TextField as TextFieldRaw } from 'Components/formik/fields';

const TextField = styled(TextFieldRaw)({
  minWidth: 300,
  margin: 2,
});
const DropdownStyled = styled(DropdownRaw)({
  minWidth: 300,
  margin: 2,
});
const DropdownField = withField(DropdownStyled);
const DropdownWithHiddenOptions = ({ digitalInputs, digitalOutputs, hiddenOptions = [], ...props }) => {
  const source = getIn(props.formValues, props.name.replace('inputValue', 'source'));
  const options = source === undefined ? [] : source === DigitalSources.INPUT ? digitalInputs : digitalOutputs;
  const data = options?.filter((item) => item === props.value || !hiddenOptions.includes(item));

  return <DropdownStyled {...props} data={data} />;
};
const DropdownWithHiddenOptionsField = withField(DropdownWithHiddenOptions);

const filterOutDisabledTypes = (items, inputsEnabled, outputsEnabled) => {
  if (!inputsEnabled) {
    items = items.filter((item) => item.value !== DigitalSources.INPUT);
  }
  if (!outputsEnabled) {
    items = items.filter((item) => item.value !== DigitalSources.OUTPUT);
  }

  return items;
};

const DigitalInputMappings = ({ data, dictionaries, digitalInputsEnabled, digitalOutputsEnabled }) => {
  const usedInputValues = data.digitalInputMappings?.map((input) => input.inputValue) || [];
  const digitalInputsCount = dictionaries.digitalInputs.length + dictionaries.digitalOutputs.length;
  const digitalInOutEnabled = digitalInputsEnabled || digitalOutputsEnabled;
  const digitalSources = filterOutDisabledTypes(
    dictionaries.digitalSources,
    digitalInputsEnabled,
    digitalOutputsEnabled
  );

  return (
    <FormikCollection
      label={T.translate(LocaleKeys.labels.digitalInputMapping)}
      name={'digitalInputMappings'}
      disableAddItem={!digitalInOutEnabled || digitalInputsCount <= usedInputValues.length}
    >
      <TextField label={T.translate(LocaleKeys.labels.digitalInputName)} name="name" fullWidth />
      <DropdownField
        name="source"
        label={T.translate(LocaleKeys.labels.source)}
        data={digitalSources}
        displayField="name"
        valueField="value"
        withoutEmpty
        fullWidth
        translateLabel={true}
      />
      <DropdownWithHiddenOptionsField
        label={T.translate(LocaleKeys.labels.digitalInputValue)}
        name="inputValue"
        digitalInputs={dictionaries.digitalInputs}
        digitalOutputs={dictionaries.digitalOutputs}
        hiddenOptions={usedInputValues}
        formValues={data}
        translateLabel
        withoutEmpty
        fullWidth
      />
      <DropdownStyled
        name="variable"
        label={T.translate(LocaleKeys.labels.variablesList)}
        data={dictionaries.eventKeywordsRelatedVariables}
        displayField="name"
        valueField="value"
        withoutEmpty
        fullWidth
        translateLabel={true}
        itemsDisabled
      />
    </FormikCollection>
  );
};

DigitalInputMappings.propTypes = {
  data: PropTypes.object,
  dictionaries: PropTypes.object,
  digitalInputsEnabled: PropTypes.bool,
  digitalOutputsEnabled: PropTypes.bool,
};

export default withFormik(DigitalInputMappings);
