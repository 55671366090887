import Checkbox from 'Components/inputs/Checkbox';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import LocaleKeys from 'Localization/LocaleKeys';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import T from 'i18n-react';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Tables from '@material-ui/core/Table';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  childrenRow: {
    justifyContent: 'center',
    margin: '5px',
    marginRight: '13px',
    paddingRight: '0px !important',
    paddingLeft: '5px !important',
  },
  headerRow: {
    justifyContent: 'center',
    paddingRight: '0px !important',
    paddingLeft: '5px !important',
    height: 'auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '250px',
  },
});

class Table extends React.Component {
  state = {
    selected: [],
    page: 0,
    rowsPerPage: this.props.rowsPerPage || 10,
    orderBy: '',
    order: 'desc',
    filters: [],
    generalFilter: '',
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    this.setState({ selected: newSelected });
  };

  handleSelectAllClick = (event) => {
    if (event.target.value === true) {
      this.setState((prevState) => ({
        selected: this.filterData(prevState.filters, prevState.generalFilter).map(
          (n) => n[this.props.redirect] || n.id
        ),
      }));

      return;
    }
    this.setState({ selected: [] });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  generateHeaders = () => {
    if (this.props.data[0]) {
      return Object.keys(this.props.data[0]);
    }

    return [];
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  setFilters = (property) => (event) => {
    const filters = this.state.filters;

    filters[property] = event.target.value;
    this.setState({ filters: filters });
  };

  removeFilters = (property) => {
    const filters = this.state.filters;

    filters[property] = '';
    this.setState({ filters: filters });
  };

  setGeneralFilter = (event) => {
    this.setState({
      generalFilter: event.target.value,
      page: 0,
    });
    this.filterData(this.state.filters, event.target.value);
  };

  removeGeneralFilter = () => {
    this.setState({
      generalFilter: '',
      page: 0,
    });
  };

  filterSelectionChange = (event) => {
    const filters = this.state.filters;

    filters[event.name] = event.value;
    this.setState({ filters: filters });
  };

  getGroupFiltersData = () => {
    const result = [];
    const filters = this.props.groupFilters;

    if (!(filters instanceof Array) || !(this.props.data instanceof Array)) {
      return result;
    }

    if (this.props.groupFiltersValues) {
      return this.props.groupFiltersValues;
    }

    this.props.data.forEach((item) => {
      for (const k in item) {
        if (filters.includes(k)) {
          if (!result[k]) {
            result[k] = [];
          }
          if (!result[k].includes(item[k])) {
            result[k].push(item[k]);
          }
        }
      }
    });

    return result;
  };

  desc = (a, b, orderBy) => {
    const aToLower = (typeof a[orderBy] === 'string' && a[orderBy].toLowerCase()) || a[orderBy];
    const bToLower = (typeof b[orderBy] === 'string' && b[orderBy].toLowerCase()) || b[orderBy];

    if (bToLower < aToLower) {
      return -1;
    }
    if (bToLower > aToLower) {
      return 1;
    }

    return 0;
  };

  stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);

      if (order !== 0) {
        return order;
      }

      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  };

  getSorting = (order, orderBy) => {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  };

  filterData(filters, generalFilter = '') {
    const result = [];

    if (!(this.props.data instanceof Array)) {
      return [];
    }
    this.props.data.forEach((item) => {
      let match = true;
      let generalMatch = false;

      // hard to convert because of break in middle of loop
      // eslint-disable-next-line
      for (const k in item) {
        if (generalFilter !== '') {
          if (String(this.formatCellValue(item[k])).toUpperCase().includes(String(generalFilter).toUpperCase())) {
            generalMatch = true;
          }
        } else {
          generalMatch = true;
        }
        if (filters.hasOwnProperty(k)) {
          if (!String(this.formatCellValue(item[k])).toUpperCase().includes(String(filters[k]).toUpperCase())) {
            match = false;
            break;
          }
        }
      }
      if (match && generalMatch) {
        result.push(item);
      }
    });

    return result;
  }

  objectToString(object) {
    if (Array.isArray(object)) {
      return object
        .map((elem) => {
          return elem.name;
        })
        .join(', ');
    }

    return object;
  }

  formatCellValue = (cell) => {
    if (typeof cell == 'object') {
      return this.objectToString(cell);
    }

    switch (cell) {
      case true:
      case 'true':
        return T.translate(LocaleKeys.labels.yes);
      case false:
      case 'false':
        return T.translate(LocaleKeys.labels.no);
      case undefined:
        return '';
      default: {
        return cell.toString();
      }
    }
  };

  getSelected = () => {
    const selected = this.state.selected;
    const data = this.props.data.map((n) => n[this.props.redirect] || n.id);

    return selected.filter((el) => data.includes(el));
  };

  render() {
    const { classes } = this.props;
    let { children } = this.props;
    const { rowsPerPage, page, order, orderBy, selected } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, this.props.data.length - page * rowsPerPage);
    let rows = 0;
    let currentRow = -1;

    if (!(children instanceof Array) && children) {
      children = [children];
    }

    const emptyCols = [];

    for (let col = 0; col < this.props.colNum; col++) {
      emptyCols.push(<TableCell />);
    }

    let headerRows = this.generateHeaders();

    if (this.props.hideColumns) {
      headerRows = headerRows.filter((key) => !this.props.hideColumns.includes(key));
    }
    rows = headerRows.length;

    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar
          selected={this.getSelected()}
          generalFilterValue={this.state.generalFilter}
          label={this.props.label}
          generalSearch={this.generalSearch}
          setGeneralFilter={this.setGeneralFilter}
          removeGeneralFilter={this.removeGeneralFilter}
          groupFilters={this.getGroupFiltersData()}
          selectionChange={this.props.filterSelectionChange || this.filterSelectionChange}
          onAddClick={this.props.onAddClick}
          bulkDelete={this.props.bulkDelete}
        />
        <div className={classes.tableWrapper}>
          <Tables className={classes.table} aria-labelledby="tableTitle" options={{ filtering: true }}>
            <EnhancedTableHead
              numSelected={selected.length}
              onRequestSort={this.handleRequestSort}
              order={order}
              orderBy={orderBy}
              rowCount={this.filterData(this.state.filters, this.state.generalFilter).length}
              filterColumn={this.props.filterColumn}
              filterQuery={this.props.filterQuery}
              keys={headerRows}
              selectionColumn={this.props.selectionColumn}
              onSelectAllClick={this.handleSelectAllClick}
              hideColumns={this.props.hideColumns}
              setFilters={this.setFilters}
              removeFilters={this.removeFilters}
              actionButtons={this.props.actionButtons}
              inheritedChildren={children}
              tableName={this.props.tableName}
            />
            <TableBody>
              {this.stableSort(
                this.filterData(this.state.filters, this.state.generalFilter),
                this.getSorting(order, orderBy)
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, rowIndex) => {
                  const isSelected = this.isSelected(row[this.props.redirect] || row.id);

                  currentRow++;

                  return [
                    <TableRow
                      key={`row-${rowIndex}`}
                      hover
                      role="row"
                      tabIndex={-1}
                      onDoubleClick={() => {
                        const { onRowDoubleClick } = this.props;

                        onRowDoubleClick && onRowDoubleClick(row[this.props.redirect] || row, row);
                      }}
                      onClick={this.props.handleRowClick}
                      aria-selected={isSelected}
                      selected={isSelected}
                    >
                      {this.props.selectionColumn ? (
                        <TableCell padding="checkbox">
                          <Checkbox
                            value={isSelected}
                            onChange={(event) => this.handleClick(event, row[this.props.redirect] || row.id)}
                          />
                        </TableCell>
                      ) : null}

                      {headerRows.map((headerRow, columnId) => {
                        return (
                          <TableCell
                            key={`table-${this.props.tableName}-row${currentRow}-column${columnId}`}
                            id={`table-${this.props.tableName}-row${currentRow}-column${columnId}`}
                            className={classes.headerRow}
                          >
                            <>{this.formatCellValue(row[headerRow])}</>
                          </TableCell>
                        );
                      })}

                      {children &&
                        children.map((child, columnId) => (
                          <TableCell
                            key={`table-${this.props.tableName}-row${currentRow}-column${columnId}`}
                            id={`table-${this.props.tableName}-row${currentRow}-column${columnId}`}
                          >
                            {child.props.children != null
                              ? child.props.children[currentRow + page * rowsPerPage]
                              : child}
                          </TableCell>
                        ))}
                      {this.props.actionButtons &&
                        this.props.actionButtons.map((button) => {
                          const buttonDisabled =
                            (this.props.disableCondition && this.props.disableCondition(row)[button.props.name]) ||
                            button.props.disabled;
                          const buttonId = `table-${this.props.tableName}-row${currentRow}-button-${button.key}`;

                          return !(button.props.hiddenwhenparam && row[button.props.hiddenwhenparam]) ? (
                            <TableCell key={buttonId} className={classes.childrenRow} id={buttonId}>
                              {React.cloneElement(button, {
                                ...button.props,
                                disabled: buttonDisabled,
                                tooltipText: !buttonDisabled
                                  ? button.props.tooltipText
                                  : button.props.disabledTooltipText,
                                onClick: () => {
                                  button.props.onClick(row[button.props.clickparam] || row, row);
                                },
                              })}
                            </TableCell>
                          ) : (
                            <TableCell key={buttonId} id={buttonId} />
                          );
                        })}
                    </TableRow>,
                    children && this.props.expandableRows && this.props.expanded === currentRow ? (
                      <TableRow>
                        {emptyCols}
                        <TableCell onChange={this.props.handleTileChange}>
                          {this.props.expandableRows[currentRow]}
                        </TableCell>
                      </TableRow>
                    ) : null,
                  ];
                })}
              {emptyRows > 0 && (
                <TableRow key="empty" style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={rows + (children ? children.length : 0)} />
                </TableRow>
              )}
            </TableBody>
          </Tables>
        </div>
        <TablePagination
          labelRowsPerPage={T.translate(LocaleKeys.labels.rowsPerPage)}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={this.filterData(this.state.filters, this.state.generalFilter).length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  onRowDoubleClick: PropTypes.func,
  classes: PropTypes.object,
  hideColumns: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  expandableRows: PropTypes.array,
  handleTileChange: PropTypes.func,
  expanded: PropTypes.bool,
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      clickparam: PropTypes.string,
      title: PropTypes.string,
      hiddenwhenparam: PropTypes.string,
    })
  ),
  selectionColumn: PropTypes.bool,
  handleRowClick: PropTypes.func,
  redirect: PropTypes.string,
  filterQuery: PropTypes.func,
  filterColumn: PropTypes.string,
  bulkDelete: PropTypes.func,
  onAddClick: PropTypes.func,
  label: PropTypes.string,
  colNum: PropTypes.number,
  emptyCols: PropTypes.number,
  groupFilters: PropTypes.array,
  rowsPerPage: PropTypes.number,
};

export default withRouter(withStyles(styles)(Table));
