import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Map as MapComponent, ZoomControl, ScaleControl, FeatureGroup } from 'react-leaflet';
import L from 'leaflet';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import layers, { LayerTypes } from './Layers';
import 'leaflet-polylinedecorator';
import 'leaflet.control.layers.tree';
import './leaflet-extensions/layers-tree-toggler';
import './leaflet-extensions/area-cluster-group';
import { drawClusterableGeoJson } from './utils';
import { withVisibleMapAreas } from './hocs/withVisibleMapAreas';
import withMapToken from './hocs/withMapToken';
import { withMapResizeObserver } from './hocs/withMapResizeObserver';
import { compose } from 'redux';

const MAX_ZOOM = 17;

const addInitialLayersToMap = (map, { base, overlays }, layers = {}) => {
  if (layers[base]) {
    layers[base].addTo(map);
  }
  [...overlays].forEach((key) => {
    if (layers[key]) {
      layers[key].addTo(map);
    }
  });
};

export const initialLayersConfigDefaultValue = {
  base: 'baseLayer',
  overlays: [],
};

const LeafletMap = ({
  fitBoundsProp,
  controls = [],
  enableFitBounds = true,
  deviceTrail,
  disableTrailFocus,
  reverseTrail,
  collapsedLayers,
  children,
  trackingMode = true,
  mapAreas,
  showCustomOverlays = true,
  initialLayersConfig = initialLayersConfigDefaultValue,
  onWhenReady,
  disableAreaClusteringAtZoom = MAX_ZOOM,
  mapToken,
  ...props
}) => {
  const [map, setMap] = useState();
  const groupRef = useRef();
  const drawnItems = useRef(new L.FeatureGroup());
  const markers = useRef(new L.LayerGroup());
  const routeMarkers = useRef(new L.LayerGroup());
  const trailLayerRef = useRef(new L.LayerGroup());
  const mapAreasLayerRef = useRef(
    L.areaClusterGroup({
      layerKey: 'mapAreasLayer',
      layerType: LayerTypes.OVERLAY,
      animate: false,
      disableClusteringAtZoom: disableAreaClusteringAtZoom,
    })
  );

  const prepareMap = (e) => {
    setMap(e.target);
    onWhenReady && onWhenReady(e);
    addControlPlaceholders(e.target);
    mapToken && addLayers(e.target, mapToken);
    showCustomOverlays && addCustomOverlays(e.target);
    controls.forEach((control) => {
      addControl(e.target, control.element, control.position);
    });
  };

  const addLayers = (mapComponent, token) => {
    const layersArray = layers(token);

    const baseTree = {
      label: T.translate(LocaleKeys.labels.baseLayers),
      children: [
        { label: T.translate(LocaleKeys.labels.normal), layer: layersArray.baseLayer },
        { label: T.translate(LocaleKeys.labels.satelite), layer: layersArray.sateliteLayer },
        { label: T.translate(LocaleKeys.labels.terrain), layer: layersArray.terrainLayer },
      ],
    };

    const overalysTree = {
      label: T.translate(LocaleKeys.labels.overlayLayers),
      children: [
        { label: T.translate(LocaleKeys.labels.traffic), layer: layersArray.traficLayer },
        { label: T.translate(LocaleKeys.labels.truckRestriction), layer: layersArray.truckRestriction },
      ],
    };

    addInitialLayersToMap(mapComponent, initialLayersConfig, layersArray);
    layersArray.labelTile.addTo(mapComponent);
    mapComponent.on('layeradd', (event) => {
      layersArray.labelTile.bringToFront();
    });

    const controlLayers = L.control.layers.tree(baseTree, overalysTree, { position: 'topleft' }).addTo(mapComponent);

    collapsedLayers && controlLayers.collapseTree().collapseTree(true);

    markers.current.addTo(mapComponent);
    routeMarkers.current.addTo(mapComponent);
  };

  const addCustomOverlays = (mapComponent) => {
    const overlaysSet = {
      mapAreasLayer: mapAreasLayerRef.current,
    };
    const overalysTree = {
      label: T.translate(LocaleKeys.labels.interactiveMaps),
      children: [{ label: T.translate(LocaleKeys.labels.mapAreas), layer: overlaysSet.mapAreasLayer }],
    };
    const layers = new L.Control.Layers.Tree.TogglerIcon(null, overalysTree, {
      position: 'topleft',
      togglerClassName: 'layers-custom-overlays',
    });

    addInitialLayersToMap(mapComponent, initialLayersConfig, overlaysSet);
    layers.addTo(mapComponent);
  };

  const addControl = (mapComponent, Control, position) => {
    if (typeof position !== 'object') {
      const newControl = new L.Control({ position });

      newControl.onAdd = () => {
        const root = L.DomUtil.create('div', '');

        ReactDOM.render(
          <Control
            map={mapComponent}
            drawnItems={drawnItems.current}
            markers={markers.current}
            routeMarkers={routeMarkers.current}
            position={position}
            trackingMode={trackingMode}
            {...props}
          />,
          root
        );

        return root;
      };

      mapComponent.addControl(newControl);
    }
  };

  const addControlPlaceholders = (mapComponent) => {
    const corners = mapComponent._controlCorners;
    const l = 'leaflet-';
    const container = mapComponent._controlContainer;

    function createCorner(vSide, hSide) {
      const className = `${l}${vSide} ${l}${hSide}`;

      corners[vSide + hSide] = L.DomUtil.create('div', className, container);
    }

    createCorner('secondtop', 'left');
    createCorner('top', 'secondleft');
    createCorner('thirdtop', 'left');
    createCorner('top', 'thirdleft');
  };

  useEffect(() => {
    mapAreasLayerRef.current.clearLayers();

    if (mapAreas) {
      mapAreas.forEach(({ name, area }) => {
        drawClusterableGeoJson(JSON.parse(area), mapAreasLayerRef.current, name);
      });
    }
  }, [mapAreas]);

  useEffect(() => {
    trailLayerRef.current.clearLayers();
    deviceTrail &&
      deviceTrail.forEach((trail) => {
        const trailArray = trail.pos && trail.pos.map((t) => [t.latitude, t.longitude]);

        if (trailArray && trailArray.length) {
          const arrow = L.polyline(reverseTrail ? trailArray.reverse() : trailArray, {}).addTo(trailLayerRef.current);

          trailArray.forEach((point) => {
            L.circleMarker(point, { radius: 5 }).addTo(trailLayerRef.current);
          });

          const newLine = L.polylineDecorator(arrow, {
            patterns: [
              {
                offset: 25,
                repeat: 150,
                symbol: L.Symbol.arrowHead({ pixelSize: 15, pathOptions: { fillOpacity: 1, weight: 0 } }),
              },
            ],
          });

          newLine.addTo(trailLayerRef.current);
          map && trackingMode && !disableTrailFocus && map.fitBounds(newLine.getBounds());
        }
      });
    map && trailLayerRef.current.addTo(map);
  }, [deviceTrail, map, disableTrailFocus, reverseTrail, trackingMode]);

  const fitBounds = useCallback(() => {
    const group = groupRef?.current?.leafletElement;
    const bounds = group && group.getBounds();

    if (map && trackingMode && bounds && Object.entries(bounds).length !== 0) {
      map.fitBounds(bounds, { maxZoom: 13 });
    }
  }, [map, trackingMode]);

  useEffect(() => {
    if (enableFitBounds || fitBoundsProp) {
      fitBounds();
    }
  }, [fitBoundsProp, enableFitBounds, fitBounds]);

  return (
    <>
      <MapComponent
        style={{
          width: '100%',
          height: '100%',
          float: 'right',
        }}
        zoomControl={false}
        zoom={13}
        minZoom={1}
        maxZoom={MAX_ZOOM}
        center={[51.5, 0]}
        worldCopyJump={true}
        whenReady={(e) => prepareMap(e)}
        {...props}
      >
        <ScaleControl />
        <ZoomControl
          position={'bottomright'}
          zoomInTitle={T.translate(LocaleKeys.labels.zoomIn)}
          zoomOutTitle={T.translate(LocaleKeys.labels.zoomOut)}
        />
        <FeatureGroup ref={groupRef}>{children}</FeatureGroup>
      </MapComponent>
      {controls
        .filter((c) => typeof c.position === 'object')
        .map(({ element: Control, position, props: controlProps }, index) => {
          return (
            <Control
              key={index}
              map={map}
              drawnItems={drawnItems.current}
              markers={markers.current}
              routeMarkers={routeMarkers.current}
              style={{
                position: 'absolute',
                zIndex: 999,
                ...position,
              }}
              trackingMode={trackingMode}
              {...props}
              {...controlProps}
            />
          );
        })}
    </>
  );
};

export default compose(withMapToken, withVisibleMapAreas, withMapResizeObserver)(LeafletMap);
