import * as datefns from 'date-fns';
import * as SessionMsg from './SessionMessageTypes';
import * as SessionConfig from './SessionConfig';

let _sessionSharedWorker;
const _sessionExpiringCallbacks = [];
const _sessionExpiredCallbacks = [];
let _serverTimeOffset = 0;

export default class SlaveSessionManager {
  sessionDetails;

  constructor(sessionSharedWorker, serverTimeOffset = 0) {
    _sessionSharedWorker = sessionSharedWorker;
    _serverTimeOffset = serverTimeOffset;
    _sessionSharedWorker.port.addEventListener('message', this.sharedWorkerMessageHandler);
  }

  sharedWorkerMessageHandler = (e) => {
    const receivedMessageType = e.data[0];

    switch (receivedMessageType) {
      case SessionMsg.UPDATE_SESSION:
        this.setSessionInfo(e.data[1]);
        break;

      case SessionMsg.SESSION_EXPIRING:
        this.publishSessionExpiringCallbacks();
        break;

      case SessionMsg.SESSION_EXPIRED:
        this.publishSessionExpiredCallbacks();
        setTimeout(() => {
          window.location.href = '/';
        }, 2000);
        break;

      default:
        break;
    }
  };

  getTimeToSessionExpire = () => {
    const expireDateTime = datefns.parseJSON(this.sessionDetails.expireDateTime);
    const timeToSessionExpire = expireDateTime - (Date.now() + _serverTimeOffset);

    return timeToSessionExpire - SessionConfig.EXPIRED_TIME_OFFSET;
  };

  setServerTimeOffset = (serverTimeOffset) => {
    _serverTimeOffset = serverTimeOffset;
  };

  setSessionInfo(sessionInfo) {
    this.sessionDetails = sessionInfo;
  }

  close() {
    _sessionSharedWorker.port.removeEventListener('message', this.sharedWorkerMessageHandler);
  }

  publishSessionExpiredCallbacks = () => {
    _sessionExpiredCallbacks.forEach((callback) => {
      callback();
    });
  };

  publishSessionExpiringCallbacks = () => {
    _sessionExpiringCallbacks.forEach((callback) => {
      callback();
    });
  };

  events = {
    addSessionExpiring(callback) {
      _sessionExpiringCallbacks.push(callback);
    },
    removeSessionExpiring(callback) {
      _sessionExpiringCallbacks.filter((c) => c !== callback);
    },

    addSessionExpired(callback) {
      _sessionExpiredCallbacks.push(callback);
    },
    removeSessionExpired(callback) {
      _sessionExpiredCallbacks.filter((c) => c !== callback);
    },
  };
}
