import React from 'react';
import PropTypes from 'prop-types';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import { TextField, PhoneInput } from 'Components/formik/fields';

const CommonInfo = (props) => {
  return (
    <ColumnDisplayer>
      <TextField label={T.translate(LocaleKeys.labels.name)} name="name" showPending />
      <TextField label={T.translate(LocaleKeys.labels.deviceId)} name="deviceCode" showPending />
      <TextField label={T.translate(LocaleKeys.labels.imei)} name="imei" showPending />
      <PhoneInput name={`phone`} label={T.translate(LocaleKeys.labels.phoneNumber)} />
      <TextField label={T.translate(LocaleKeys.labels.customer)} name="customer" />
      <TextField label={T.translate(LocaleKeys.labels.desc)} name="description" />
      <TextField label={T.translate(LocaleKeys.labels.remarks)} name="remark" />
    </ColumnDisplayer>
  );
};

CommonInfo.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
};

export default CommonInfo;
