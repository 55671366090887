import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { InputAdornment, CircularProgress, TextField } from '@material-ui/core';

export const TextInput = ({
  valid,
  onBlur,
  value,
  setValue,
  inputProps,
  InputProps,
  validateInput,
  debounceTimer,
  showPending,
  ...props
}) => {
  const [t, setT] = useState(null);
  const [inputValue, setInputValue] = useState();
  const [pending, setPending] = useState(false);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const debounce = (e) => {
    const val = e.target.value;
    const target = e.target;

    setPending(true);

    setInputValue(val);
    if (t) {
      clearTimeout(t);
    }
    setT(
      setTimeout(() => {
        setValue && setValue(target.value);
        props.onChange && props.onChange({ target });
        setPending(false);
      }, debounceTimer)
    );
  };

  return (
    <TextField
      placeholder={props.placeholder}
      variant="outlined"
      id={`textInput-${props.name}`}
      name={props.name}
      value={inputValue || (props.type === 'number' && inputValue === 0 ? '0' : '')}
      margin={props.margin || 'normal'}
      error={valid && valid !== 'true'}
      onBlur={(e) => {
        e.target.value = e.target.value.trim();
        onBlur && onBlur(e);
        props.onChange && props.onChange(e);
      }}
      helperText={valid && valid !== 'true' && T.translate(LocaleKeys.validation[props.validationerrorlocalekeys])}
      {...props}
      inputProps={inputProps}
      InputProps={{
        ...InputProps,
        readOnly: props.readOnly || InputProps?.readOnly,
        endAdornment:
          InputProps?.endAdornment ||
          (((showPending && pending) || props.forcePending) && (
            <InputAdornment position="end">
              <CircularProgress />
            </InputAdornment>
          )),
      }}
      onChange={debounce}
    />
  );
};

TextInput.defaultProps = {
  debounceTimer: 750,
};

TextInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  margin: PropTypes.string,
  valid: PropTypes.string,
  onBlur: PropTypes.func,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default TextInput;
