import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import Dropdown from './Dropdown';

const DropdownWithApiCall = ({ apiCall, ...props }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    let ignore = false;

    async function fetchData() {
      const result = await apiCall();

      if (!ignore) {
        setData(result.data);
      }
    }

    fetchData();

    return () => {
      ignore = true;
    };
  }, [apiCall]);

  return (
    <>
      {!data && <CircularProgress />}
      <Dropdown {...props} data={data} disabled={props.disabled || !data} />
    </>
  );
};

DropdownWithApiCall.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  translateLabel: PropTypes.bool,
  displayField: PropTypes.string,
  apiCall: PropTypes.func,
  className: PropTypes.string,
  classes: PropTypes.object,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
  none: PropTypes.bool,
  withoutEmpty: PropTypes.bool,
  itemsDisabled: PropTypes.bool,
  valueField: PropTypes.string,
  readOnly: PropTypes.bool,
};

DropdownWithApiCall.defaultProps = {
  translateLabel: false,
  readOnly: false,
};

export default DropdownWithApiCall;
