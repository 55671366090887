import { clone } from 'Helpers/ObjectHelper';

export const prepareSiteData = (siteConfiguration) => {
  const preparedSiteConfiguration = clone(siteConfiguration);

  if (!siteConfiguration.securityRule.isForcePasswordChange) {
    delete preparedSiteConfiguration.securityRule.reminderDuration;
    delete preparedSiteConfiguration.securityRule.passwordChangeDuration;
  }
  if (!siteConfiguration.securityRule.isBlockAccount) {
    delete preparedSiteConfiguration.securityRule.numberOfTrials;
    delete preparedSiteConfiguration.securityRule.policyBreachEmail;
  }

  return preparedSiteConfiguration;
};
