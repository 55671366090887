import PropTypes from 'prop-types';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import T from 'i18n-react';

const TextOutput = ({ value, label, disabled, onChange, ...props }) => (
  <TextField
    id={`outlined-${props.name}`}
    value={(props.translateValue && T.translate(value)) || value || ''}
    label={(props.translateLabel && T.translate(label)) || label}
    variant="outlined"
    margin={props.margin || 'normal'}
    disabled={true}
    helperText={props.helperText}
    className={props.className}
    type={props.type}
    inputProps={{
      readOnly: true,
    }}
    {...props}
  />
);

TextOutput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  type: PropTypes.string,
  helperText: PropTypes.string,
  margin: PropTypes.string,
  translateValue: PropTypes.bool,
  translateLabel: PropTypes.bool,
  name: PropTypes.string,
};

export default TextOutput;
