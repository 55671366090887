/**
 * @class L.Draw.IconMarker
 * @aka Draw.IconMarker
 * @inherits L.Draw.Marker
 */
L.Draw.IconMarker = L.Draw.Marker.extend({
  statics: {
    TYPE: 'iconMarker',
  },

  // @method initialize(): void
  initialize: function (map, options) {
    this.type = L.Draw.IconMarker.TYPE;

    this._initialLabelText = L.drawLocal.draw.handlers.iconMarker.tooltip.start;

    L.Draw.Feature.prototype.initialize.call(this, map, options);
  },
});
