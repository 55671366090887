import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { Permissions } from 'Constants/permissions';
import Button from 'Components/Button';
import DevicesAssignment from './tabs/DevicesAssignment';
import General from './tabs/General';
import LoginSettings from './tabs/LoginSettings';
import Webhooks from './tabs/Webhooks';
import SaveOnLeave from 'Components/dialogs/SaveOnLeave';
import { getSiteByName, getSite, putSite, postSitePages, putSiteMaps } from 'Api/sites';
import { putDevicesSubscribeToSite, putDevicesUnsubscribeFromSite } from 'Api/devices';
import { withStyles, styled } from '@material-ui/core/styles';
import { Sites, SiteDetails } from 'routes/Routes';
import { compose } from 'redux';
import withPermissions from 'hocs/withPermissions';
import IpWhiteListRaw from './tabs/IpWhiteListing/IpWhiteList';
import { connect } from 'react-redux';
import Tabs from 'Components/display/tabs/Tabs';
import { sitesValidation } from './SitesValidation';
import { FormikEnhanced, withField } from 'Components/formik/formikWrappers';
import { prepareSiteData } from './helpers';

const IpWhiteList = withField(IpWhiteListRaw);

const styles = (theme) => ({
  button: {
    float: 'right',
    marginLeft: '10px',
  },
});

const ActionsPanel = styled('div')({
  textAlign: 'right',
  marginBottom: '10px',
  marginTop: '10px',
});

class Details extends React.Component {
  state = {
    data: {},
    dataChanged: false,
    isLoaded: false,
  };

  componentDidMount = () => {
    const { name } = this.props.match.params;

    getSiteByName(name.replace(/-/g, ' '))
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          data: response.data,
          dataChanged: false,
        }));
      })
      .then(() => {
        this.setState({ isLoaded: true });
      });
  };

  fetchData = () => {
    getSite(this.state.data.siteId)
      .then((response) => {
        const updateUrl = this.state.data.general.name !== response.data.general.name;

        this.setState((prevState) => ({
          ...prevState,
          data: response.data,
          dataChanged: false,
        }));

        return updateUrl;
      })
      .then((updateUrl) => {
        if (updateUrl) {
          const url = SiteDetails.getUrl({
            name: this.state.data.general.name.replace(/\s/g, '-'),
          });

          window.history.replaceState(null, null, url);
        }
        this.setState({ isLoaded: true });
      });
  };

  redirect = () => {
    const url = Sites.getUrl();

    this.props.history.push(url);
  };

  onSubmit = (values, { setSubmitting }) => {
    this.saveData(values).then(() => {
      setSubmitting(false);
      this.redirect();
    });
  };

  saveData = (data) => {
    return putSite(prepareSiteData(data))
      .then(() => {
        this.setState((prevState) => ({
          ...prevState,
          dataChanged: false,
        }));
      })
      .then(() => {
        if (data.sitePages) {
          postSitePages(data.siteId, data.sitePages.selectedSites);
        }
      })
      .then(() => {
        if (data.siteMaps) {
          putSiteMaps(data.siteId, data.siteMaps);
        }
      })
      .then(() => {
        if (data.assignedDevices && data.assignedDevices.length > 0) {
          putDevicesSubscribeToSite(
            data.assignedDevices.map((x) => x.externalId),
            data.siteId
          );
        }
      })
      .then(() => {
        if (data.unAssignedDevices && data.unAssignedDevices.length > 0) {
          putDevicesUnsubscribeFromSite(
            data.unAssignedDevices.map((x) => x.externalId),
            data.siteId
          );
        }
      });
  };

  onCancel = () => {
    this.redirect();
  };

  onSwitchTabConfirmSave = (values, setSubmitting) => {
    setSubmitting(true);
    this.saveData(values).then(() => {
      this.fetchData();
      setSubmitting(false);
    });
  };

  onSwitchTabContinueWithoutSave = (dirty) => {
    if (dirty) {
      this.fetchData();
    }
  };

  render() {
    const { data } = this.state;
    const { classes, identityUser, permissions } = this.props;
    const canSaveChanges = !!permissions[Permissions.CanEditSites];
    const hasAccessToWhiteListing = identityUser?.profile?.AllowIpWhiteListModify === 'true' || false;

    return this.state.isLoaded ? (
      <>
        <FormikEnhanced
          initialValues={data}
          enableReinitialize
          validationSchema={() => sitesValidation(data.siteId)}
          validateOnMount
          onSubmit={this.onSubmit}
          canSaveChanges={canSaveChanges}
        >
          {({ values, dirty, isValid, isSubmitting, setSubmitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Tabs
                label="siteDetails"
                saveChange={() => this.onSwitchTabConfirmSave(values, setSubmitting)}
                setData={() => this.onSwitchTabContinueWithoutSave(dirty)}
                showConfirmationDialog={dirty && canSaveChanges}
                validForm={isValid}
              >
                <General label={LocaleKeys.labels.general} />
                <LoginSettings label={LocaleKeys.labels.loginSettings} />
                <DevicesAssignment label={LocaleKeys.labels.devicesAssignment} disabled={!Permissions.CanReadDevices} />
                {hasAccessToWhiteListing && <IpWhiteList label={LocaleKeys.labels.ipWhiteList} />}
                <Webhooks label={LocaleKeys.labels.webhooks} />
              </Tabs>

              <ActionsPanel>
                <Button className={classes.button} onClick={this.onCancel} id="cancelButton">
                  {T.translate(LocaleKeys.labels.cancel)}
                </Button>

                {canSaveChanges && (
                  <>
                    <Button
                      className={classes.button}
                      disabled={!(dirty && isValid) || isSubmitting}
                      type="submit"
                      id="submitButton"
                    >
                      {T.translate(LocaleKeys.labels.save)}
                    </Button>
                    <SaveOnLeave saveData={() => this.saveData(values)} dataChanged={dirty} validForm={isValid} />
                  </>
                )}
              </ActionsPanel>
            </form>
          )}
        </FormikEnhanced>
      </>
    ) : null;
  }
}

Details.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  permissions: PropTypes.object,
};

const mapStateToProps = (state) => ({
  identityUser: state.app.identityUser,
});

export default compose(
  connect(mapStateToProps),
  withPermissions([Permissions.CanAccessSitesPage, Permissions.CanReadSites]),
  withStyles(styles)
)(Details);
