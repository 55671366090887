import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { isDate } from 'Helpers/DateTimeHelpers';
import { VariableTypes } from 'Constants/VariableTypes';

export const Operators = {
  BETWEEN: 'Between',
  DIFFERENT: 'Different',
  EQUAL: 'Equal',
  OUTSIDE_OF: 'OutsideOf',
  EQUAL_TO: 'EqualTo',
  OCCUR: 'Occur',
  DOES_NOT_OCCUR: 'DoesNotOccur',
  INSIDE: 'Inside',
  OUTSIDE: 'Outside',
  GREATER_OR_EQUAL: 'GreaterOrEqual',
  GREATER_THAN: 'GreaterThan',
  LESS_OR_EQUAL: 'LessOrEqual',
  LESS_THAN: 'LessThan',
  LONGER_THAN: 'LongerThan',
  SHORTER_THAN: 'ShorterThan',
};
export const ReasonTypes = {
  SINGLE_VALUE: 'SINGLE_VALUE',
  RANGE_VALUE: 'RANGE_VALUE',
  METHOD: 'METHOD',
};

export const getReasonType = (variableType, operator) => {
  if (variableType === VariableTypes.EVENT_KEYWORD) {
    return ReasonTypes.METHOD;
  } else if ([Operators.BETWEEN, Operators.OUTSIDE_OF].includes(operator)) {
    return ReasonTypes.RANGE_VALUE;
  } else {
    return ReasonTypes.SINGLE_VALUE;
  }
};

const formatValue = (value, variableType) => {
  if (variableType === VariableTypes.TIME && isDate(value)) {
    return new Date(`${value} UTC`).toLocaleTimeString();
  }

  return value;
};

export const translateReason = (reason) => {
  const { mappingName, operator, values, variableType } = reason;
  const reasonType = getReasonType(variableType, operator);

  switch (reasonType) {
    case ReasonTypes.SINGLE_VALUE:
      if (variableType === VariableTypes.MAP_AREA && values[0] === 'True') {
        return T.translate(LocaleKeys.messages.alarmReasonTypeAreaInside, { mappingName });
      } else if (variableType === VariableTypes.MAP_AREA && values[0] === 'False') {
        return T.translate(LocaleKeys.messages.alarmReasonTypeAreaOutside, { mappingName });
      } else {
        return T.translate(LocaleKeys.messages.alarmReasonTypeSingleValue, {
          mappingName,
          operator: T.translate(operator).toLowerCase(),
          value: formatValue(values[0], variableType),
        });
      }

    case ReasonTypes.RANGE_VALUE:
      return T.translate(LocaleKeys.messages.alarmReasonTypeRangeValue, {
        mappingName,
        operator: T.translate(operator).toLowerCase(),
        from: formatValue(values[0], variableType),
        to: formatValue(values[1], variableType),
      });

    case ReasonTypes.METHOD:
      return T.translate(LocaleKeys.messages.alarmReasonTypeMethod, {
        mappingName,
        operator: T.translate(operator).toLowerCase(),
      });

    default:
      return '';
  }
};

export const groupAlarmReasons = (reasons) => {
  const groups = reasons.reduce(
    (groups, item) => ({
      ...groups,
      [item.groupingId]: [...(groups[item.groupingId] || []), translateReason(item)],
    }),
    {}
  );

  return Object.values(groups)
    .map((group) => group.join('\n'))
    .join(' & ');
};

export const isChecked = (notification, checkedNotifications, checkAllAt) => {
  const createdOn = new Date(notification.createdOn);
  let isChecked = checkedNotifications.includes(notification.uniqueId);

  if (checkAllAt && +createdOn <= +checkAllAt) {
    isChecked = !isChecked;
  }

  return isChecked;
};
