import React from 'react';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import ColumnDisplayer from 'Components/display/ColumnDisplayer';
import { Checkbox } from 'Components/formik/fields';

const Sound = (props) => (
  <ColumnDisplayer>
    <Checkbox label={T.translate(LocaleKeys.labels.playSound)} name="isSoundEnabled" />
  </ColumnDisplayer>
);

export default Sound;
