import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import { Box, Fab, styled } from '@material-ui/core';
import { FieldArray, getIn } from 'formik';
import { Delete } from '@material-ui/icons';
import Condition from './Condition';

const FabStyled = styled(Fab)({
  minHeight: '40px',
  height: '40px',
  width: '40px',
  minWidth: '40px',
  margin: '10px 5px 0 5px',
});

const Section = (props) => {
  return (
    <Box display="flex" flexDirection="column">
      <FieldArray name={`${props.name}.conditions`}>
        {({ name, form, push, remove }) => {
          const conditions = getIn(form.values, name);

          return conditions.map((formula, index) => {
            const isLastCondition = conditions.length - 1 === index;

            return (
              <Box key={`${name}.${index}`} display="flex" justifyContent="flex-start">
                <Condition
                  name={`${name}.${index}`}
                  dictionaries={props.dictionaries}
                  inputMappings={props.inputMappings}
                />
                {!(props.isLastSection && props.isFirstSection && isLastCondition && index === 0) && (
                  <FabStyled
                    onClick={() => (index === 0 && conditions.length < 2 ? props.removeSection() : remove(index))}
                    size="small"
                  >
                    <Delete />
                  </FabStyled>
                )}
                {isLastCondition && (
                  <FabStyled size="small" onClick={() => push({ targetValues: [] })}>
                    {T.translate(LocaleKeys.labels.logicalAND)}
                  </FabStyled>
                )}
                {isLastCondition && props.isLastSection && (
                  <FabStyled size="small" onClick={props.addSection}>
                    {T.translate(LocaleKeys.labels.logicalOR)}
                  </FabStyled>
                )}
              </Box>
            );
          });
        }}
      </FieldArray>
    </Box>
  );
};

Section.propTypes = {
  name: PropTypes.string,
  dictionaries: PropTypes.object,
  addSection: PropTypes.func,
  removeSection: PropTypes.func,
  isFirstSection: PropTypes.bool,
  isLastSection: PropTypes.bool,
};

export default Section;
