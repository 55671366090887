import React, { useRef, useEffect } from 'react';

export const withMapResizeObserver =
  (WrappedComponent) =>
  ({ onWhenReady, mapResizeTimeout = 200, ...props }) => {
    const mapContainerRef = useRef();
    const isMountedRef = useRef(false);
    const invalidateSizeTimeoutRef = useRef();
    const containerResizeObserverRef = useRef(null);

    const handleWhenReady = (e) => {
      const map = e.target;
      const mapContainer = map._container;

      mapContainerRef.current = mapContainer;
      containerResizeObserverRef.current = new ResizeObserver(() => {
        clearTimeout(invalidateSizeTimeoutRef.current);
        invalidateSizeTimeoutRef.current = setTimeout(() => {
          if (isMountedRef.current) {
            map.invalidateSize();
          }
        }, mapResizeTimeout);
      });
      containerResizeObserverRef.current.observe(mapContainer);

      if (onWhenReady) {
        onWhenReady(e);
      }
    };

    useEffect(() => {
      isMountedRef.current = true;

      return () => {
        isMountedRef.current = false;
        if (containerResizeObserverRef.current) {
          containerResizeObserverRef.current.unobserve(mapContainerRef.current);
        }
      };
    }, []);

    return <WrappedComponent {...props} onWhenReady={handleWhenReady} />;
  };
