import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import React from 'react';
import Tab from '@material-ui/core/Tab';
import TabBar from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import T from 'i18n-react';
import FormLeaveDialog from 'Components/dialogs/FormLeaveDialog';

const TabContainer = (props) => {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
};

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    float: 'right',
  },
  popUp: {
    background: 'white',
    position: 'fixed',
    left: '50%',
    top: '200px',
  },
  appBar: {
    marginBottom: '15px',
  },
});

const getStoredTab = (label) => {
  return parseInt(localStorage.getItem(`${label}-tab`)) || 0;
};

class Tabs extends React.Component {
  state = {
    value: typeof this.props.defaultTab !== 'undefined' ? this.props.defaultTab : getStoredTab(this.props.label),
    plannedValue: 0,
    showDialog: false,
  };

  tryChangeTab = async (event, value) => {
    try {
      this.setState((prevState) => ({ ...prevState, plannedValue: value }));
      if (this.props.onTryChangeTab) {
        await this.props.onTryChangeTab();
      }
      if (this.props.showConfirmationDialog) {
        this.setState((prevState) => ({ ...prevState, showDialog: true }));
      } else {
        localStorage.setItem(`${this.props.label}-tab`, value);
        this.setState((prevState) => ({
          ...prevState,
          value: value,
        }));
      }
    } catch (error) {
      this.setState((prevState) => ({ ...prevState, showDialog: false }));
    }
  };

  saveData = async () => {
    await this.props.saveChange();
    localStorage.setItem(`${this.props.label}-tab`, this.state.plannedValue);
    this.setState((prevState) => ({
      ...prevState,
      value: this.state.plannedValue,
    }));
    this.setState((prevState) => ({ ...prevState, showDialog: false }));
  };

  continueWithoutSaving = () => {
    localStorage.setItem(`${this.props.label}-tab`, this.state.plannedValue);
    this.setState((prevState) => ({
      ...prevState,
      value: this.state.plannedValue,
    }));
    this.setState((prevState) => ({ ...prevState, showDialog: false }));
    this.props.setData && this.props.setData();
  };

  cancel = () => {
    this.setState((prevState) => ({ ...prevState, showDialog: false }));
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    const dialogProps = {
      open: this.state.showDialog,
      isValid: this.props.validForm,
      onClose: this.cancel,
      onConfirm: this.saveData,
      onContinueWithoutSaving: this.continueWithoutSaving,
    };

    return (
      <div>
        <div className={classes.root}>
          <AppBar position="static" color="default" className={classes.appBar}>
            <TabBar
              value={value}
              onChange={this.tryChangeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {this.props.children.length && this.props.children.length > 1 ? (
                this.props.children.map((child) => {
                  return (
                    child && (
                      <Tab
                        key={child.props.label}
                        label={T.translate(child.props.label)}
                        disabled={child.props.disabled}
                        id={`tab-${child.props.label}`}
                      />
                    )
                  );
                })
              ) : (
                <Tab
                  key={this.props.children.props.label}
                  label={T.translate(this.props.children.props.label)}
                  disabled={this.props.children.props.disabled}
                  id={`tabs-${this.props.label}-${this.props.children.props.label}`}
                />
              )}
            </TabBar>
          </AppBar>

          {this.props.children.length && this.props.children.length > 1
            ? this.props.children.find((child, index) => this.state.value === index)
            : this.props.children}
        </div>
        {this.props.customDialog ? (
          <this.props.customDialog.element {...dialogProps} {...this.props.customDialog.props} />
        ) : (
          <FormLeaveDialog {...dialogProps} />
        )}
      </div>
    );
  }
}

Tabs.defaultProps = {
  validForm: true,
};

Tabs.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setData: PropTypes.func,
  saveChange: PropTypes.func,
  showConfirmationDialog: PropTypes.bool,
  defaultTab: PropTypes.number,
  validForm: PropTypes.bool,
  customDialog: PropTypes.exact({
    element: PropTypes.elementType.isRequired,
    props: PropTypes.object,
  }),
};

export default withStyles(styles)(Tabs);
