import React from 'react';
import CopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { LibraryDetails, LibraryNew } from 'routes/Routes';
import DeleteDialog from 'Components/dialogs/DeleteDialog';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import Table from 'Components/display/table/Table';
import { withRouter } from 'react-router-dom';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import Loader from 'Components/Loader';
import TableActionButton from 'Components/display/table/TableActionButton';
import { deleteDriverLibrary, getLibraries } from 'Api/devices';
import { compose } from 'redux';

export const formatDataForTableView = (response) =>
  Object.values(response)
    .map((library) => ({
      libraryName: library.libraryName,
      deviceDriver: T.translate(library.driverName),
      digitalInputsCount: library.digitalInputsCount,
      analogInputsCount: library.analogInputsCount,
      eventKeywordsCount: library.eventKeywordsCount,
      libraryId: library.libraryId,
      driverId: library.driverId,
      isTemplate: library.isTemplate,
    }))
    .filter((o) => o.isTemplate === true);

class LibraryManagement extends React.Component {
  state = {
    data: [],
    isLoaded: false,
    deleteDialogOpen: false,
    libraryId: null,
    driverId: null,
  };

  componentDidMount = () => {
    this.setData();
  };

  setData = () => {
    this.fetchData();
  };

  fetchData = () => {
    getLibraries()
      .then((response) => {
        this.setState({
          data: response.data,
          libraryId: null,
          driverId: null,
        });
      })
      .then(() => {
        this.setState({ isLoaded: true });
      })
      .catch((error) => {
        alert(error.response.data);
      });
  };

  rowDoubleClick = (params) => {
    const url = LibraryDetails.getUrl({
      name: params.libraryName.replace(/\s/g, '-'),
    });

    this.props.history.push(url);
  };

  onAddClick = () => {
    const url = LibraryNew.getUrl();

    this.props.history.push(url);
  };

  handleCopyLibraryClick = (params) => {
    const url = LibraryNew.getUrl();

    this.props.history.push(url, {
      id: params.libraryId,
      driverId: params.driverId,
    });
  };

  showDeleteDialog = (params) => {
    this.setState({
      deleteDialogOpen: true,
      libraryId: params.libraryId,
      driverId: params.driverId,
    });
  };

  handleDeleteCancel = () => {
    this.setState({
      deleteDialogOpen: false,
      libraryId: null,
      driverId: null,
    });
  };

  handleDeleteConfirm = () => {
    return deleteDriverLibrary(this.state.driverId, this.state.libraryId).then(() => {
      this.setState({ deleteDialogOpen: false, libraryId: null, driverId: null });
      this.fetchData();
    });
  };

  getTableActionButtons = () => {
    const actionButtons = [];

    if (this.props.permissions[Permissions.CanEditLibraries]) {
      const editItemButton = (
        <TableActionButton
          key="editItemButton"
          onClick={this.rowDoubleClick}
          icon={<EditIcon />}
          tooltipText={T.translate(LocaleKeys.tooltips.editItem)}
        />
      );

      actionButtons.push(editItemButton);
    }
    if (this.props.permissions[Permissions.CanCopyLibraries]) {
      const copyItemButton = (
        <TableActionButton
          key="editItemButton"
          onClick={this.handleCopyLibraryClick}
          icon={<CopyIcon />}
          tooltipText={T.translate(LocaleKeys.tooltips.copyItem)}
        />
      );

      actionButtons.push(copyItemButton);
    }
    if (this.props.permissions[Permissions.CanRemoveLibraries]) {
      const deleteItemButton = (
        <TableActionButton
          key="deleteItemButton"
          onClick={this.showDeleteDialog}
          icon={<DeleteIcon />}
          tooltipText={T.translate(LocaleKeys.tooltips.deleteItem)}
        />
      );

      actionButtons.push(deleteItemButton);
    }

    return actionButtons;
  };

  render() {
    if (!this.state.isLoaded || !this.state.data) {
      return <Loader />;
    }

    const data = formatDataForTableView(this.state.data);
    const actionButtons = this.getTableActionButtons();

    return (
      <>
        <DeleteDialog
          isOpen={this.state.deleteDialogOpen}
          onConfirm={this.handleDeleteConfirm}
          onCancel={this.handleDeleteCancel}
          disableConfirm={!this.state.libraryId || !this.state.driverId}
          title={T.translate(LocaleKeys.messages.deleteDriverDialogTitle)}
          message={T.translate(LocaleKeys.messages.deleteDriverDialogMessage)}
        />
        <Table
          data={data}
          onRowDoubleClick={this.rowDoubleClick}
          onAddClick={this.props.permissions[Permissions.CanAddLibraries] && this.onAddClick}
          hideColumns={['libraryId', 'driverId', 'driverName', 'isTemplate']}
          actionButtons={actionButtons}
          tableName="LibraryManagement"
        />
      </>
    );
  }
}

export default compose(
  withRouter,
  withPermissions([Permissions.CanAccessLibraryManagementPage, Permissions.CanReadLibraries])
)(LibraryManagement);
