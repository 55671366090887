export const types = {
  SET_PERMISSIONS: 'SET_PERMISSIONS',
  LOAD_MAP_TOKEN_SUCCESS: 'LOAD_MAP_TOKEN_SUCCESS',
  LOAD_MAP_TOKEN_ERROR: 'LOAD_MAP_TOKEN_ERROR',
  LOADING_MAP_TOKEN: 'LOADING_MAP_TOKEN',
  SET_CURRENT_IDENTITY_USER: 'SET_CURRENT_IDENTITY_USER',
  SET_LAST_USER_ACTIVITY_TIME: 'SET_LAST_USER_ACTIVITY_TIME',
};

export const setPermissions = (permissions) => ({
  type: types.SET_PERMISSIONS,
  permissions,
});

export const loadMapTokenSuccess = (data) => ({
  type: types.LOAD_MAP_TOKEN_SUCCESS,
  data,
});

export const loadMapTokenError = (error) => ({
  type: types.LOAD_MAP_TOKEN_ERROR,
  error,
});

export const setLoadingMapToken = (loading) => ({
  type: types.LOADING_MAP_TOKEN,
  loading,
});

export const setCurrentIdentityUser = (identityUser) => ({
  type: types.SET_CURRENT_IDENTITY_USER,
  identityUser,
});

export const setLastUserActivity = (lastActivityTime) => ({
  type: types.SET_LAST_USER_ACTIVITY_TIME,
  lastActivityTime,
});
