import CommonInfo from './libraryDefinition/CommonInfo';
import AnalogInputMapping from './libraryDefinition/AnalogInputMapping';
import ExpansionSection from 'Components/display/ExpansionPanel/ExpansionSection';
import DigitalInputMapping from './libraryDefinition/DigitalInputMapping';
import LocaleKeys from 'Localization/LocaleKeys';
import PropTypes from 'prop-types';
import React from 'react';
import EventKeywordMapping from './libraryDefinition/EventKeywordMapping';
import { withFormik } from 'Components/formik/formikWrappers';

const General = ({ data, onChange, onDriverChange, dictionaries, mappingsInfo }) => {
  const digitalInOutEnabled = mappingsInfo.digitalInputsEnabled || mappingsInfo.digitalOutputsEnabled;
  const showDigitalMappings = data.digitalInputMappings.length || digitalInOutEnabled;
  const showAnalogMappings = data.analogInputMappings.length || mappingsInfo.analogInputsEnabled;
  const showKeywordMappings = data.keywordMappings.length || mappingsInfo.keywordsEnabled;

  return (
    <ExpansionSection data={data} onChange={onChange}>
      <CommonInfo label={LocaleKeys.labels.commonInfo} onDriverChange={onDriverChange} />
      {showDigitalMappings && (
        <DigitalInputMapping
          label={LocaleKeys.labels.digitalInputMapping}
          dictionaries={dictionaries}
          digitalInputsEnabled={mappingsInfo.digitalInputsEnabled}
          digitalOutputsEnabled={mappingsInfo.digitalOutputsEnabled}
        />
      )}
      {showAnalogMappings && (
        <AnalogInputMapping
          label={LocaleKeys.labels.analogInputMapping}
          dictionaries={dictionaries}
          analogInputsEnabled={mappingsInfo.analogInputsEnabled}
        />
      )}
      {showKeywordMappings && (
        <EventKeywordMapping
          label={LocaleKeys.labels.eventKeywordMapping}
          dictionaries={dictionaries}
          keywordsEnabled={mappingsInfo.keywordsEnabled}
        />
      )}
    </ExpansionSection>
  );
};

General.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
  dictionaries: PropTypes.object,
  onDriverChange: PropTypes.func,
  mappingsInfo: PropTypes.objectOf({
    analogInputsEnabled: PropTypes.bool,
    digitalInputsEnabled: PropTypes.bool,
    digitalOutputsEnabled: PropTypes.bool,
    keywordsEnabled: PropTypes.bool,
  }),
};

export default withFormik(General);
