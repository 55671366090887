import React from 'react';
import PropTypes from 'prop-types';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import FormikCollection from 'Components/inputs/CollectionManager/FormikCollection';
import { Box, styled } from '@material-ui/core';
import { TextField } from 'Components/formik/fields';

const inputStyles = {
  minWidth: '25%',
  margin: '10px 4%',
};
const TextFieldStyled = styled(TextField)(inputStyles);

const HttpFeeds = (props) => (
  <Box width={'100%'}>
    <FormikCollection label={' '} name={'httpFeeds'}>
      <TextFieldStyled label={T.translate(LocaleKeys.labels.name)} name={'name'} />
      <TextFieldStyled label={T.translate(LocaleKeys.labels.description)} name={'description'} />
      <TextFieldStyled label={T.translate(LocaleKeys.labels.url)} name={'url'} />
    </FormikCollection>
  </Box>
);

HttpFeeds.propTypes = {
  data: PropTypes.object,
};

export default HttpFeeds;
