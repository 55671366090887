import React from 'react';
import PropTypes from 'prop-types';
import MuiPhoneNumber from 'material-ui-phone-number';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';

const PhoneInput = ({
  name,
  onChange,
  readOnly,
  disableDropdown,
  inputProps,
  valid,
  validationerrorlocalekeys,
  ...props
}) => (
  <MuiPhoneNumber
    onChange={(number) => {
      const value = number === '+' ? '' : number.replace(/-| /g, '');

      onChange({
        target: { value, name },
      });
    }}
    name={name}
    disableDropdown={readOnly || disableDropdown}
    inputProps={{
      ...inputProps,
      readOnly,
    }}
    id={`phoneInput-${props.name}`}
    error={valid && valid !== 'true'}
    helperText={valid && valid !== 'true' && T.translate(LocaleKeys.validation[validationerrorlocalekeys])}
    {...props}
  />
);

PhoneInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  regions: PropTypes.arrayOf(PropTypes.string),
  defaultCountry: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  inputClass: PropTypes.string,
  disableDropdown: PropTypes.bool,
  readOnly: PropTypes.bool,
};

PhoneInput.defaultProps = {
  regions: ['europe', 'middle-east'],
  defaultCountry: 'de',
  disabled: false,
  variant: 'outlined',
  inputClass: '',
  disableDropdown: false,
  readOnly: false,
};

export default PhoneInput;
