export const UserStatuses = {
  NEW: 'New',
  WAIT_FOR_ACTIVATION: 'WaitForActivation',
  ACTIVATION_FAILED: 'ActivationFailed',
  NO_ACTIVATED: 'NoActivated',
  ACTIVE: 'Active',
  LOCKED: 'Locked',
  BLOCKED: 'Blocked',
  REMOVED: 'Removed',
  RESEND_PASSWORD: 'ResendPassword',
  RESET_PASSWORD: 'ResetPassword',
};
