import React, { useState, useEffect, forwardRef, useCallback } from 'react';
import * as configurationStore from 'Api/configurationStore';
import { getGeoPoints } from 'Api/stateManagement';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setGeoPoints } from 'redux/assets/actions';
import Sidebar from './Sidebar';

const DeviceSidebarView = (
  {
    selectedAssetIds,
    setSelectedAssetIds,
    devices,
    loading,
    error,
    children,
    options,
    displayBackToDashboardButton,
    setGeoPoints,
    trackingMode,
  },
  ref
) => {
  const [deviceTrail, setDeviceTrail] = useState([]);

  // Get values for selected devices from Configuration Store.
  useEffect(() => {
    if (options.sidebarStateName) {
      configurationStore.getValue(`${options.sidebarStateName}-SelectedDevices`).then((response) => {
        // prevent to overwrite empty array from initial value
        if (response.data && response.data.length) {
          setSelectedAssetIds(response.data);
        }
      });
    }
  }, [options.sidebarStateName, setSelectedAssetIds]);

  useEffect(() => {
    if (options.sidebarStateName && !loading) {
      configurationStore.getValue(`${options.sidebarStateName}-DeviceTrail`).then(({ data }) => {
        // prevent to overwrite empty array from initial value
        if (data && data.length) {
          setDeviceTrail(data);
          data.forEach((device) => {
            device.tracked &&
              getGeoPoints({
                assetId: device.externalId,
                timePeriodInMinutes: device.selectedTimePeriod,
              }).then((response) => {
                setGeoPoints({
                  externalId: device.externalId,
                  geoPoints: response.data || [],
                });
              });
          });
        }
      });
    }
  }, [options.sidebarStateName, setGeoPoints, loading]);

  const updateConfigurationStoreSelectedAssets = (selectedAssetIds) => {
    if (options.sidebarStateName) {
      // filter out not available devices on site
      const selectedAssetIdsFiltered = selectedAssetIds.filter((externalId) =>
        devices.find((d) => d.externalId === externalId)
      );

      configurationStore.putValueCreateOrUpdate(`${options.sidebarStateName}-SelectedDevices`, {
        value: JSON.stringify(selectedAssetIdsFiltered),
      });
    }
  };

  const updateSelectedAssetIds = (selectedAssetIds) => {
    updateConfigurationStoreSelectedAssets(selectedAssetIds);
    setSelectedAssetIds(selectedAssetIds);
  };

  const updateConfigurationStoreDeviceTrail = useCallback(
    (deviceTrail) => {
      if (options.sidebarStateName) {
        // filter out not available devices on site from deviceTrail
        const deviceTrailFiltered = deviceTrail
          .filter((dt) => devices.find((d) => d.externalId === dt.externalId))
          .filter((trail) => trail.tracked || trail.selectedTimePeriod !== '15');

        configurationStore.putValueCreateOrUpdate(`${options.sidebarStateName}-DeviceTrail`, {
          value: JSON.stringify(
            deviceTrailFiltered.map(({ externalId, selectedTimePeriod, tracked }) => ({
              externalId,
              selectedTimePeriod,
              tracked,
            }))
          ),
        });
      }
    },
    [options.sidebarStateName, devices]
  );

  const selectDeviceGeoPos = useCallback(
    (assetId, timePeriodInMinutes) => {
      const currentDevice = deviceTrail.filter((device) => device.externalId === assetId)[0];
      const selectedTimePeriod = timePeriodInMinutes || currentDevice?.selectedTimePeriod || '15';

      if (!currentDevice?.tracked || timePeriodInMinutes) {
        getGeoPoints({ assetId, timePeriodInMinutes: selectedTimePeriod }).then((response) => {
          const newDeviceTrail = deviceTrail
            .filter((device) => device.externalId !== assetId)
            .concat([
              {
                externalId: assetId,
                selectedTimePeriod,
                tracked: true,
              },
            ]);

          updateConfigurationStoreDeviceTrail(newDeviceTrail);
          setDeviceTrail(newDeviceTrail);
          setGeoPoints({
            externalId: assetId,
            geoPoints: response.data || [],
          });
        });
      } else {
        const newDeviceTrail = deviceTrail
          .filter((device) => device.externalId !== assetId)
          .concat([{ externalId: assetId, selectedTimePeriod }]);

        updateConfigurationStoreDeviceTrail(newDeviceTrail);
        setDeviceTrail(newDeviceTrail);
        setGeoPoints({
          externalId: assetId,
          geoPoints: [],
        });
      }
    },
    [deviceTrail, setGeoPoints, updateConfigurationStoreDeviceTrail]
  );

  const deviceTrailGeoPoints = deviceTrail
    .filter((trail) => trail.tracked)
    .filter((trail) => (options.multiple && !selectedAssetIds.length) || selectedAssetIds.includes(trail.externalId))
    .map((trail) => {
      const from = Date.now() - 60 * 1000 * trail.selectedTimePeriod;
      const pos = devices
        .find((device) => device.externalId === trail.externalId)
        ?.geoPoints?.filter((g) => g.timestamp >= from);

      return {
        ...trail,
        pos,
      };
    });

  return (
    <>
      <Sidebar
        devices={devices}
        displayBackToDashboardButton={displayBackToDashboardButton}
        loading={loading}
        error={error}
        selectedAssetIds={selectedAssetIds}
        setSelectedAssetIds={updateSelectedAssetIds}
        selectDeviceGeoPos={selectDeviceGeoPos}
        deviceTrail={deviceTrail}
        options={options}
      />
      {children(devices, selectedAssetIds, deviceTrailGeoPoints, trackingMode)}
    </>
  );
};

const DeviceSidebarViewWithForwardRef = forwardRef(DeviceSidebarView);

const mapState = (state) => ({
  devices: state.assets.devices,
  loading: state.assets.loading,
  error: state.assets.error,
});
const mapDispatch = {
  setGeoPoints,
};

export default compose(connect(mapState, mapDispatch, null, { forwardRef: true }))(DeviceSidebarViewWithForwardRef);
