import * as Yup from 'yup';
import { getDriverLibraryExistsByName } from 'Api/devices';
import { isAlphanumericOrSpecialSymbol } from 'Validators/SharedValidators';

let lastValue;
let initialValue;

export const libraryNameExists = async (value, driverId, props) => {
  if (isAlphanumericOrSpecialSymbol([' '])(value)) {
    const result = lastValue?.value === value ? lastValue?.result : await getDriverLibraryExistsByName(driverId, value);

    if (props) {
      if (!initialValue || props !== initialValue?.id) {
        initialValue = { value, id: props };
      }
    } else {
      initialValue = undefined;
    }

    lastValue = { value, result };

    return initialValue?.value === value || !result?.data;
  }

  return false;
};

export const validationSchema = (props) =>
  Yup.object().shape({
    name: Yup.string()
      .required('thisFieldIsRequired')
      .max(50, 'nameCannotExceed50Characters')
      .alphanumericOrSpecialSymbol([' '])
      .test('libraryNameExists', 'libraryWithThisNameAlreadyExists', function (value) {
        return libraryNameExists(value, this.parent.driverId, this.parent.id);
      }),
    driverId: Yup.number().required('pleaseSelectDriver'),
    digitalInputMappings: Yup.array().of(
      Yup.object()
        .shape({
          name: Yup.string().required('thisFieldIsRequired').max(50, 'nameCannotExceed50Characters'),
          inputValue: Yup.string().required('thisFieldIsRequired').max(50, 'nameCannotExceed50Characters'),
          source: Yup.number().required('thisFieldIsRequired').oneOf([1, 2]),
        })
        .uniqueInCollection('valueMustBeUniqueInCollection', 'name')
    ),
    analogInputMappings: Yup.array().of(
      Yup.object()
        .shape({
          name: Yup.string().required('thisFieldIsRequired').max(50, 'nameCannotExceed50Characters'),
          inputValue: Yup.string().required('thisFieldIsRequired').max(50, 'nameCannotExceed50Characters'),
        })
        .uniqueInCollection('valueMustBeUniqueInCollection', 'name')
    ),
    keywordMappings: Yup.array().of(
      Yup.object()
        .shape({
          name: Yup.string().required('thisFieldIsRequired').max(50, 'nameCannotExceed50Characters'),
          inputValue: Yup.string().required('thisFieldIsRequired').max(50, 'nameCannotExceed50Characters'),
        })
        .uniqueInCollection('valueMustBeUniqueInCollection', 'name')
    ),
  });

export default validationSchema;
