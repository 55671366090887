import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from './Button';

const styles = (theme) => ({
  input: {
    display: 'none',
  },
});

class FileUploadButton extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <>
        <input
          accept={this.props.accept}
          className={classes.input}
          id={this.props.id}
          variant={this.props.filled ? 'contained' : 'outlined'}
          multiple={this.props.multiple ? this.props.multiple : false}
          type="file"
          onChange={this.props.onChange}
          name={this.props.name}
          readOnly={this.props.readOnly}
          disabled={this.props.disabled || this.props.readOnly}
        />
        <label htmlFor={this.props.id} className={this.props.className}>
          <Button variant="contained" component="span" disabled={this.props.disabled || this.props.readOnly}>
            {this.props.label}
          </Button>
          {this.props.children}
        </label>
      </>
    );
  }
}

FileUploadButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  accept: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  filled: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  id: PropTypes.string,
};

export default withStyles(styles)(FileUploadButton);
