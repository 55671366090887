import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import { styled } from '@material-ui/core/styles';
import { Button, Typography, Box } from '@material-ui/core';
import ButtonLibraryCategories from './ButtonLibraryCategories';
import { types } from './reducer/buttonLibraryReducer';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import RenameCategoryDialog from './RenameCategoryDialog';
import { TextField } from 'Components/formik/fields';

const addNamespace = (namespace) => (fieldName) => namespace ? `${namespace}.${fieldName}` : fieldName;

const CommandsRow = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '5px',
});

const TypographyWrapper = styled(Typography)({
  margin: '20px',
  height: '100%',
});

const ButtonLibraryPreview = ({ buttonLibrary, onSelectButton, dispatch, validateForm, isReadOnly, ...props }) => {
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const withNamespace = addNamespace(props.namespace);

  useEffect(() => {
    buttonLibrary && validateForm();
  }, [buttonLibrary, validateForm]);

  if (!buttonLibrary) {
    return <TypographyWrapper>{T.translate(LocaleKeys.labels.noButtonLibrarySelected)}</TypographyWrapper>;
  }

  const onAddCategory = (categoryName) => {
    dispatch({ type: types.CREATE_CATEGORY, categoryName });
    setRenameDialogOpen(false);
  };

  const categoryList = buttonLibrary?.categories.map((category) => ({
    tempId: category.tempId,
    name: category.name,
  }));

  return (
    <DndProvider backend={HTML5Backend}>
      <Box display="flex" flexDirection="column" flexGrow="1" minHeight="0">
        <Box display="flex" margin="5px">
          <TextField
            name={withNamespace('name')}
            label={T.translate(LocaleKeys.labels.libraryName)}
            onBlur={(event) => {
              dispatch({
                type: types.CHANGE_LIBRARY,
                key: 'name',
                value: event.target.value,
              });
            }}
            showPending
          />
        </Box>
        <Box display="flex" flexDirection="column" flexGrow="1">
          <ButtonLibraryCategories
            buttonLibrary={buttonLibrary}
            onSelectButton={onSelectButton}
            dispatch={dispatch}
            containerHeight={'calc(100vh - 440px)'}
            isReadOnly={isReadOnly}
          />
          <CommandsRow>
            {!isReadOnly && (
              <Button size="large" onClick={() => setRenameDialogOpen(true)}>
                {T.translate(LocaleKeys.labels.addCategory)}
              </Button>
            )}
          </CommandsRow>
        </Box>
      </Box>
      <RenameCategoryDialog
        open={renameDialogOpen}
        onCancel={() => {
          setRenameDialogOpen(false);
        }}
        onRenameCategory={onAddCategory}
        categories={categoryList}
      />
    </DndProvider>
  );
};

ButtonLibraryPreview.propTypes = {
  buttonLibrary: PropTypes.object,
  onSelectButton: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  namespace: PropTypes.string,
  validateForm: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

ButtonLibraryPreview.defaultProps = {
  buttonLibrary: null,
  namespace: null,
  validateForm: () => {},
  isReadOnly: false,
};

export default ButtonLibraryPreview;
