import React, { useState, useEffect } from 'react';
import { getCalendarByName, getCalendar, getHoliday, putCalendar } from 'Api/sites';
import SpecialActivity from './tabs/SpecialActivity';
import T from 'i18n-react';
import Vacation from './tabs/Vacation';
import WeeklyActivitySchedule from './tabs/WeeklyActivitySchedule';
import General from './tabs/General';
import Holiday from './tabs/Holiday';
import { compose } from 'redux';
import SaveOnLeave from 'Components/dialogs/SaveOnLeave';
import LocaleKeys from 'Localization/LocaleKeys';
import Button from 'Components/Button';
import withPermissions from 'hocs/withPermissions';
import { Permissions } from 'Constants/permissions';
import { styled } from '@material-ui/core/styles';
import { CalendarTemplates, CalendarDetails } from 'routes/Routes';
import { CircularProgress } from '@material-ui/core';
import Tabs from 'Components/display/tabs/Tabs';
import CalendarTemplatesValidation, { calendarNameExist } from './CalendarTemplatesValidation';
import { convertWorkdays, convertVacations, convertSpecialEntries } from './helpers';
import { getCalendarsMappingsUsedByBRE } from 'Api/devices';
import { FormikEnhanced } from 'Components/formik/formikWrappers';

const ButtonStyled = styled(Button)({
  float: 'right',
  marginLeft: '10px',
});

const Details = ({ history, match, permissions }) => {
  const [calendarTemplate, setCalendarTemplate] = useState(null);
  const [holidayTemplate, setHolidayTemplate] = useState(null);
  const [linkedToAlarm, setLinkedToAlarm] = useState(false);

  useEffect(() => {
    if (calendarTemplate?.externalId) {
      getCalendarsMappingsUsedByBRE(calendarTemplate.externalId).then((response) => {
        if (response?.data?.length) {
          setLinkedToAlarm(true);
        }
      });
    }
  }, [calendarTemplate]);

  useEffect(() => {
    const name = match.params?.name.replace(/-/g, ' ');

    getCalendarByName(name).then(({ data }) => {
      data.workdays = convertWorkdays(data.workdays);
      data.vacations = convertVacations(data.vacations);
      data.specialEntries = convertSpecialEntries(data.specialEntries);
      setCalendarTemplate(data);
      const holidayId = data.holidayTemplateId;

      if (!!holidayId) {
        getHolidayTemplate(holidayId);
      }
    });
  }, [match]);

  const getData = () => {
    getCalendar(calendarTemplate.calendarId).then(({ data }) => {
      if (calendarTemplate.name !== data.name) {
        const url = CalendarDetails.getUrl({
          name: data.name.replace(/\s/g, '-'),
        });

        window.history.replaceState(null, null, url);
      }

      data.workdays = convertWorkdays(data.workdays);
      data.vacations = convertVacations(data.vacations);
      data.specialEntries = convertSpecialEntries(data.specialEntries);
      setCalendarTemplate(data);

      const holidayId = data.holidayTemplateId;

      if (!!holidayId) {
        getHolidayTemplate(holidayId);
      }
    });
  };

  const getHolidayTemplate = (id) => {
    getHoliday(id).then((holidayTemplateResponse) => {
      setHolidayTemplate(holidayTemplateResponse.data);
    });
  };

  const saveData = (values) => {
    const calendar = { ...values };

    calendar.workdays = Object.values(calendar.workdays);

    return putCalendar(calendar.calendarId, calendar);
  };

  const redirect = () => {
    const url = CalendarTemplates.getUrl();

    history.push(url);
  };

  const onCancel = () => {
    redirect();
  };

  const onSubmit = (values, { setSubmitting }) => {
    saveData(values).then(() => {
      redirect();
    });
  };

  const onSwitchTabConfirmSave = async (values, setSubmitting) => {
    if (await calendarNameExist(values.name, calendarTemplate.name)) {
      setSubmitting(false);
    } else {
      setSubmitting(true);
      saveData(values).then(() => {
        getData();
        setSubmitting(false);
      });
    }
  };

  const onSwitchTabContinueWithoutSave = (dirty) => {
    if (dirty) {
      getData();
    }
  };

  if (!calendarTemplate) {
    return <CircularProgress />;
  }

  const canSaveChanges = !!permissions[Permissions.CanEditCalendarTemplates];

  return (
    <FormikEnhanced
      initialValues={calendarTemplate}
      enableReinitialize
      validationSchema={CalendarTemplatesValidation}
      validateOnMount
      onSubmit={onSubmit}
      canSaveChanges={canSaveChanges}
    >
      {({ values, dirty, isValid, isSubmitting, setSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Tabs
            label="calendarDetails"
            saveChange={() => onSwitchTabConfirmSave(values, setSubmitting)}
            setData={() => onSwitchTabContinueWithoutSave(dirty)}
            showConfirmationDialog={dirty && canSaveChanges}
            validForm={isValid}
          >
            <General label={LocaleKeys.labels.general} disableTypes={linkedToAlarm} />
            <WeeklyActivitySchedule label={LocaleKeys.labels.weeklyActivitySchedule} />
            <SpecialActivity label={LocaleKeys.labels.specialActivity} />
            <Vacation label={LocaleKeys.labels.vacation} />
            <Holiday
              label={LocaleKeys.labels.holidayTemplate}
              holidayTemplateData={holidayTemplate}
              getHolidayTemplate={getHolidayTemplate}
            />
          </Tabs>
          <ButtonStyled onClick={onCancel}>{T.translate(LocaleKeys.labels.cancel)}</ButtonStyled>
          {canSaveChanges && (
            <>
              <ButtonStyled disabled={!(dirty && isValid) || isSubmitting} type="submit">
                {T.translate(LocaleKeys.labels.save)}
              </ButtonStyled>
              <SaveOnLeave saveData={() => saveData(values)} dataChanged={dirty} validForm={isValid} />
            </>
          )}
        </form>
      )}
    </FormikEnhanced>
  );
};

export default compose(
  withPermissions([
    Permissions.CanAccessCalendarTemplatesPage,
    Permissions.CanReadCalendarTemplates,
    Permissions.CanReadHolidaysTemplates,
  ])
)(Details);
