import React from 'react';
import { useField, useFormikContext, Formik } from 'formik';

const EnhancedFormContext = React.createContext({
  canSaveChanges: true,
});

export const useEnhancedFormContext = () => {
  return React.useContext(EnhancedFormContext);
};

export const withFormik = (WrappedComponent) => {
  const WithFormik = (props) => {
    const { values, handleChange, setFieldValue } = useFormikContext();

    return <WrappedComponent {...props} data={values} onChange={handleChange} setFieldValue={setFieldValue} />;
  };

  return (props) => <WithFormik {...props} />; // eslint-disable-line
};

export const withField = (WrappedComponent) => {
  const WithField = (props) => {
    const [field, meta, helpers] = useField(props.name);
    const { canSaveChanges } = React.useContext(EnhancedFormContext);
    const isReadOnly = props.readOnly || !canSaveChanges;

    return (
      <WrappedComponent
        {...props}
        onChange={props.onChange || field.onChange}
        value={field.value}
        setValue={helpers.setValue}
        validationerrorlocalekeys={meta.error}
        valid={meta.error}
        readOnly={isReadOnly}
      />
    );
  };

  return (props) => <WithField {...props} />;
};

const withEnhancedProps = (WrappedComponent) => {
  const WithEnhancedProps = ({ canSaveChanges = true, ...otherProps }) => {
    return (
      <EnhancedFormContext.Provider value={{ canSaveChanges }}>
        <WrappedComponent {...otherProps} />
      </EnhancedFormContext.Provider>
    );
  };

  return (props) => <WithEnhancedProps {...props} />;
};

export const FormikEnhanced = withEnhancedProps(Formik);
