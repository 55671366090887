import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import Paper from '@material-ui/core/Paper';
import Button from 'Components/Button';
import LeafletMapRaw from 'Components/LeafletMap';
import General from './details/General';
import { getMapAreaByName, putMapArea } from 'Api/maps';
import { getMapAreasMappingsUsedByBRE } from 'Api/devices';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { MapAreas } from 'routes/Routes';
import { Permissions } from 'Constants/permissions';
import ExtendedMapDrawing from 'Components/LeafletMap/controls/ExtendedMapDrawing';
import CombinedInput from 'Components/LeafletMap/controls/CombinedInput';
import withPermissions from 'hocs/withPermissions';
import Tabs from 'Components/display/tabs/Tabs';
import { FormikEnhanced, withField } from 'Components/formik/formikWrappers';
import MapAreasValidation from './MapAreasValidation';
import SaveOnLeave from 'Components/dialogs/SaveOnLeave';
import DeleteAffectingAlarmsDialog from 'Components/dialogs/DeleteAffectingAlarmsDialog';
import ErrorPage from 'app/pages/ErrorPage';

const LeafletMap = withField(LeafletMapRaw);

const styles = (theme) => ({
  button: {
    float: 'right',
    marginLeft: '10px',
    marginTop: '5px',
  },
  paper: {
    padding: '15px',
    display: 'flex',
  },
  tabWrapper: {
    minWidth: '100%',
  },
  columnLeft: {
    flexBasis: '62%',
  },
  columnRight: {
    flexBasis: '100%',
    paddingLeft: '10px',
    height: 600,
  },
  drawing: {
    marginLeft: '10px',
    paddingLeft: '10px',
    float: 'right',
  },
});

const MapDetails = (props) => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [isDrawingEnabled, setIsDrawingEnabled] = useState(false);
  const [affectedAlarms, setAffectedAlarms] = useState([]);
  const [affectedAlarmsDialogOpen, setAffectedAlarmsDialogOpen] = useState(false);
  const [hiddenAreas, setHiddenAreas] = useState([]);

  useEffect(() => {
    const { name } = props.match.params;

    getMapAreaByName(name.replace(/-/g, ' ')).then(({ data }) => {
      setData(data);
      setHiddenAreas([data.id]);
    });
  }, [props.match]);

  const redirect = () => {
    const url = MapAreas.getUrl();

    props.history.push(url);
  };

  const saveData = async (values, setSubmitting) => {
    setSubmitting(true);
    try {
      await putMapArea(values.id, values);
      setSubmitting(false);
      redirect();
    } catch (error) {
      setError(error);
      setSubmitting(false);
    }
  };

  const handleSubmit = (values, setSubmitting) => {
    return new Promise(async (resolve, reject) => {
      if (data.isEnabled && !values.isEnabled) {
        const { data: affectedAlarms } = await getMapAreasMappingsUsedByBRE(values.id);

        if (affectedAlarms.length) {
          setAffectedAlarms(affectedAlarms);
          setAffectedAlarmsDialogOpen(true);
          setSubmitting(false);
          reject(new Error(LocaleKeys.messages.affectedAlarmsRequireUserAction));
        } else {
          resolve(saveData(values, setSubmitting));
        }
      } else {
        resolve(saveData(values, setSubmitting));
      }
    });
  };

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      await handleSubmit(values, setSubmitting);
    } catch (error) {
      if (error.isAxiosError) {
        setError(error);
      }
    }
  };

  const handleAffectedAlarmsDialogCancel = () => {
    setAffectedAlarmsDialogOpen(false);
  };

  const handleAffectedAlarmsDialogConfirm = (values, setSubmitting) => {
    saveData(values, setSubmitting);
    setAffectedAlarmsDialogOpen(false);
  };

  const handleCancel = () => {
    redirect();
  };

  const { classes, permissions } = props;
  const canSaveChanges = !!permissions[Permissions.CanEditMapArea];

  if (error) {
    return <ErrorPage code={error.response.data.status} message={error.response.data.title} />;
  }

  if (!data) {
    return null;
  }

  return (
    <FormikEnhanced
      initialValues={data}
      enableReinitialize
      validationSchema={MapAreasValidation}
      validateOnMount
      onSubmit={onSubmit}
      canSaveChanges={canSaveChanges}
    >
      {({ values, dirty, isValid, isSubmitting, handleSubmit, setSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Paper className={classes.paper}>
            <div className={classes.columnLeft}>
              <Tabs
                label="mapAreaDetails"
                saveChange={handleSubmit}
                showConfirmationDialog={dirty && canSaveChanges}
                validForm={isValid && !isDrawingEnabled}
              >
                <General label={LocaleKeys.labels.description} />
              </Tabs>
            </div>
            <div className={classes.columnRight}>
              <LeafletMap
                maxZoom={20}
                className={classes.drawing}
                name={'area'}
                data={values}
                setSaveButtonState={setIsDrawingEnabled}
                controls={[
                  {
                    element: CombinedInput,
                    position: { marginTop: 10, marginLeft: 65 },
                  },
                  {
                    element: ExtendedMapDrawing,
                    position: 'thirdtopleft',
                  },
                ]}
                hiddenAreas={hiddenAreas}
                disableAreaClusteringAtZoom={0}
              />
            </div>
          </Paper>
          <Button className={classes.button} onClick={handleCancel} id="mapAreas-cancelButton">
            {T.translate(LocaleKeys.labels.cancel)}
          </Button>

          {canSaveChanges && (
            <>
              <Button
                className={classes.button}
                type="submit"
                disabled={!(dirty && isValid) || isSubmitting || isDrawingEnabled}
                id="mapAreas-submitButton"
              >
                {T.translate(LocaleKeys.labels.save)}
              </Button>
              <SaveOnLeave
                saveData={() => handleSubmit(values, setSubmitting)}
                dataChanged={dirty}
                validForm={isValid && !isDrawingEnabled}
              />
              <DeleteAffectingAlarmsDialog
                dialogOpen={affectedAlarmsDialogOpen}
                handleOk={() => handleAffectedAlarmsDialogConfirm(values, setSubmitting)}
                handleCancel={handleAffectedAlarmsDialogCancel}
                title={LocaleKeys.messages.deactivateMapAreaDialogTitle}
                affectedAlarms={affectedAlarms}
              />
            </>
          )}
        </form>
      )}
    </FormikEnhanced>
  );
};

MapDetails.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  permissions: PropTypes.object,
};

export default compose(
  withPermissions([Permissions.CanAccessMapAreasPage, Permissions.CanReadMapAreas]),
  withStyles(styles)
)(MapDetails);
