import { types } from './actions';
import { types as sitesTypes } from 'redux/sites/actions';

const initialState = {
  permissions: null,
  mapToken: {
    loading: false,
    data: null,
    error: null,
  },
  identityUser: null,
  lastUserActivityTime: new Date(),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case sitesTypes.SET_CURRENT_SITE:
      return {
        ...state,
        mapToken: {
          ...initialState.mapToken,
        },
      };
    case types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.permissions,
      };
    case types.LOAD_MAP_TOKEN_SUCCESS:
      return {
        ...state,
        mapToken: {
          data: action.data,
          error: null,
          loading: false,
        },
      };
    case types.LOAD_MAP_TOKEN_ERROR:
      return {
        ...state,
        mapToken: {
          error: action.error,
          data: null,
          loading: false,
        },
      };
    case types.LOADING_MAP_TOKEN:
      return {
        ...state,
        mapToken: {
          ...state.mapToken,
          loading: action.loading,
        },
      };
    case types.SET_CURRENT_IDENTITY_USER:
      return {
        ...state,
        identityUser: action.identityUser,
      };
    case types.SET_LAST_USER_ACTIVITY_TIME:
      return {
        ...state,
        lastUserActivityTime: action.lastActivityTime,
      };
    default:
      return state;
  }
};
