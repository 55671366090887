import React, { useState } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';
import LocaleKeys from 'Localization/LocaleKeys';
import Button from 'Components/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, Typography } from '@material-ui/core';

const ConfirmDialog = ({ open, error, handleReadPromise, handleCancel, onRefresh, allowTryAgain, bulkReadLimit }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleConfirm = () => {
    setIsSubmitting(true);
    handleReadPromise().finally(() => {
      setIsSubmitting(false);
    });
  };

  const errorMessage = allowTryAgain
    ? T.translate(error)
    : `${T.translate(error)} ${T.translate(LocaleKeys.messages.pleaseReloadAndTryAgain)}`;

  return (
    <Dialog open={open || error} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {error ? (
            errorMessage
          ) : (
            <div>
              <Typography>{T.translate(LocaleKeys.messages.areYouSureToReadThoseMessages).toUpperCase()}</Typography>
              {bulkReadLimit && (
                <Typography>
                  *
                  {T.translate(LocaleKeys.messages.messagesWillBeReadUpToSetLimitForOperation, {
                    limit: bulkReadLimit,
                  })}
                </Typography>
              )}
            </div>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} disabled={isSubmitting}>
          {T.translate(LocaleKeys.labels.cancel)}
        </Button>
        {(!error || allowTryAgain) && (
          <Button onClick={handleConfirm} autoFocus showProgress={isSubmitting} disabled={isSubmitting}>
            {error ? T.translate(LocaleKeys.labels.tryAgain) : T.translate(LocaleKeys.labels.confirm)}
          </Button>
        )}
        {error && (
          <Button onClick={onRefresh} disabled={isSubmitting}>
            {T.translate(LocaleKeys.labels.reload)}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  handleReadPromise: PropTypes.func,
  handleCancel: PropTypes.func,
  onRefresh: PropTypes.func,
  allowTryAgain: PropTypes.bool,
  bulkReadLimit: PropTypes.number,
};
export default ConfirmDialog;
