import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import FormLeaveDialog from './FormLeaveDialog';

class SaveOnLeave extends React.Component {
  state = {
    showDialog: false,
    isDataSaved: false,
  };

  componentDidMount = () => {
    this.unblock = this.props.history.block((targetLocation) => {
      if (this.props.onTryChangeLocation && !this.state.isDataSaved) {
        this.props.onTryChangeLocation();
      }
      this.setState({
        showDialog: this.props.dataChanged,
        targetLocation: targetLocation,
      });

      return !this.props.dataChanged || this.state.showDialog;
    });
  };

  saveData = async () => {
    try {
      this.setState({ isDataSaved: false });
      await this.props.saveData();
      this.setState({ isDataSaved: true }, () => {
        this.props.history.push(this.state.targetLocation);
      });
    } catch (error) {
      this.cancel();
    }
  };

  continueWithoutSaving = async () => {
    if (this.props.withoutSaving) {
      await this.props.withoutSaving();
    }
    this.props.history.push(this.state.targetLocation);
  };

  cancel = () => {
    this.setState({ showDialog: false });
  };

  render() {
    const dialogProps = {
      open: this.state.showDialog,
      isValid: this.props.validForm,
      onClose: this.cancel,
      onConfirm: this.saveData,
      onContinueWithoutSaving: this.continueWithoutSaving,
    };

    if (this.props.customDialog) {
      return <this.props.customDialog.element {...dialogProps} {...this.props.customDialog.props} />;
    }

    return <FormLeaveDialog {...dialogProps} />;
  }
}

SaveOnLeave.defaultProps = {
  validForm: true,
};

SaveOnLeave.propTypes = {
  dataChanged: PropTypes.bool.isRequired,
  saveData: PropTypes.func.isRequired,
  history: PropTypes.object,
  validForm: PropTypes.bool,
  withoutSaving: PropTypes.func,
  onTryChangeLocation: PropTypes.func,
  customDialog: PropTypes.exact({
    element: PropTypes.elementType.isRequired,
    props: PropTypes.object,
  }),
};

export default withRouter(SaveOnLeave);
