import React from 'react';
import { styled } from '@material-ui/core/styles';
import LocaleKeys from 'Localization/LocaleKeys';
import T from 'i18n-react';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  TableHead,
  TableSortLabel,
  IconButton,
  TextField,
  Box,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';
import MultiselectWithFilter from 'Components/inputs/MultiselectWithFilter';

const IconButtonStyled = styled(IconButton)({
  opacity: 0,
});

const TableCellStyled = styled(TableCell)({
  '&:hover button': {
    opacity: 1,
  },
});

const MultiselectWithFilterStyled = styled(MultiselectWithFilter)({
  minWidth: '300px',
  maxWidth: '400px',
});

const FilterInput = ({ clearFilter, ...props }) => {
  if (props.options) {
    return (
      <MultiselectWithFilterStyled
        {...props}
        data={props.options}
        fullWidth
        showHelperText={false}
        displayField="label"
        valueField="value"
        onChange={(e, reason) => {
          if (reason === 'clear') {
            clearFilter();
          } else {
            const { name } = e.target;
            const value = e.target.value?.map((item) => item.value);

            props.onChange({ target: { name, value } });
          }
        }}
        value={props.options.filter((item) => props.value?.includes(item.value))}
      />
    );
  }

  return (
    <TextField
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={clearFilter}>
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

const TableView = ({
  columns,
  rows,
  total,
  rowsPerPage,
  page,
  order: { key, direction },
  filters,
  setPage,
  setRowsPerPage,
  setOrder,
  setFilters,
  handleDoubleClick,
}) => (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          {Object.entries(columns).map(([columnName, columnValue]) => (
            <TableCellStyled key={columnName}>
              {typeof filters[columnName] !== 'undefined' ? (
                <FilterInput
                  label={columnValue.name || T.translate(LocaleKeys.labels[columnName] || columnName)}
                  onChange={(e) => {
                    const value = e.target.value;

                    setFilters((prev) => ({
                      ...prev,
                      [columnName]: {
                        value,
                        operator: columns[columnName].filterOperator,
                      },
                    }));
                  }}
                  variant="outlined"
                  value={filters[columnName]?.value}
                  clearFilter={() => {
                    setFilters((prev) => {
                      const filters = { ...prev };

                      delete filters[columnName];

                      return filters;
                    });
                  }}
                  options={columns[columnName].filterOptions}
                />
              ) : (
                <Box display="flex">
                  <TableSortLabel
                    active={key === columnName}
                    direction={direction}
                    hideSortIcon={columns[columnName].disableSort}
                    onClick={() => {
                      if (columns[columnName].disableSort) {
                        return;
                      }
                      if (key === columnName) {
                        setOrder((prev) => ({
                          ...prev,
                          direction: prev.direction === 'asc' ? 'desc' : 'asc',
                        }));
                      } else {
                        setOrder({
                          key: columnName,
                          direction: 'asc',
                        });
                      }
                    }}
                  >
                    {columnValue.name || T.translate(LocaleKeys.labels[columnName] || columnName)}
                  </TableSortLabel>
                  {!columns[columnName].disableFilter && (
                    <IconButtonStyled
                      onClick={() => {
                        setFilters((prev) => ({
                          ...prev,
                          [columnName]: {
                            value: columns[columnName].filterOptions ? [] : '',
                            operator: columns[columnName].filterOperator,
                          },
                        }));
                      }}
                    >
                      <Search />
                    </IconButtonStyled>
                  )}
                </Box>
              )}
            </TableCellStyled>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow key={row.id} hover onDoubleClick={handleDoubleClick(row.id, row?.name?.replace(/\s/g, '-'))}>
            {Object.keys(columns).map((columnName) => {
              const value = row[columnName];
              const format = columns[columnName].format;

              return <TableCell key={columnName}>{format ? format(row) : value}</TableCell>;
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <TablePagination
      component="div"
      labelRowsPerPage={T.translate(LocaleKeys.labels.rowsPerPage)}
      rowsPerPageOptions={[5, 10, 25]}
      count={total}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={(e, page) => setPage(page)}
      onChangeRowsPerPage={(e) => setRowsPerPage(e.target.value)}
    />
  </TableContainer>
);

export default TableView;
